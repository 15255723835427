import React from 'react';
import { ITenantSharedProps } from '../TenantSharedProps';
import { TenantModal } from './TenantModal';

export const DeclineTenantInvitation = (props: ITenantSharedProps) => {
  const title = 'Are you sure you want to decline?';

  const message = `Unless you don't know ${props.proUserName} and this request was sent by mistake, we'd suggest you to accept. Having a wellness partner has proved to accelerate the journey for almost all our members.`;

  return (
    <TenantModal
      visible={props.visible}
      title={title}
      message={message}
      icon={'alert-triangle-outline'}
      primaryButtonText={'Decline'}
      onPrimaryButton={props.onFinalButtonClick}
      visibleSecondaryButton={true}
      secondaryButtonText={'Go Back'}
      onSecondaryButton={props.onSecondaryButton}
      testingAttributes={props.testingAttributes}
    />
  );
};
