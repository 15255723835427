import { useState } from 'react';
import { useAnalytics } from './useAnalytics';
import { ApiError } from '../types/ApiResponse';
import ActionItemService from 'src/api/actionItem';

export const useAddAction = () => {
  const { addAnalyticsLog } = useAnalytics('useAddAction.ts');
  const [isAddingAction, setIsAddingAction] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  const addAction = async (actionId: number) => {
    setIsAddingAction(true);
    await ActionItemService.addAction(actionId)
      .catch((err: ApiError) => {
        setError(err.error);
        addAnalyticsLog({ function: 'addAction', data: err, logType: 'error' });
      })
      .finally(() => {
        setIsAddingAction(false);
      });
  };

  return [addAction, isAddingAction, error];
};
