import { Circle, Path, Svg } from 'react-native-svg';

import React from 'react';
import { SvgIconProp } from 'src/core/types/ScanIcons';

export const FaceScanIcon = ({
  color = '#46D7CB',
  backgroundColor = 'transparent',
  height = 64,
  width = 64
}: SvgIconProp) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 64 64" fill={backgroundColor}>
      <Circle cx="32" cy="32" r="22.6" stroke={color} strokeWidth="2.8" />
      <Circle cx="26" cy="26" r="2" fill={color} />
      <Circle cx="38" cy="26" r="2" fill={color} />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.7288 36.2314C44.2729 36.6256 44.3945 37.3862 44.0003 37.9302C42.6402 39.8078 40.8548 41.3366 38.7903 42.3916C36.7259 43.4466 34.4409 43.9977 32.1225 44C29.8041 44.0023 27.518 43.4555 25.4515 42.4046C23.385 41.3536 21.5966 39.8283 20.2328 37.9534C19.8376 37.4101 19.9577 36.6493 20.501 36.2541C21.0443 35.8589 21.8051 35.9789 22.2003 36.5222C23.3382 38.0865 24.8302 39.3591 26.5544 40.2359C28.2785 41.1128 30.1858 41.5689 32.1201 41.567C34.0544 41.5651 35.9608 41.1053 37.6832 40.2251C39.4057 39.3449 40.8952 38.0694 42.03 36.5029C42.4242 35.9588 43.1848 35.8373 43.7288 36.2314Z"
        fill={color}
      />
    </Svg>
  );
};
