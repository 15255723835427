import * as MyMarkersScreens from '../../screens/markers/index';
import { Divider, Tab, TabBar, Text, useTheme } from '@ui-kitten/components';
import React from 'react';
import { StyleSheet } from 'react-native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import AllMarkersIcon from './components/icons/AllMarkersIcon';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';
import FavoriteIcon from './components/icons/FavoriteIcon';
import { MyMarkersTabParamList } from 'src/core/types/RootNavigationParamList';

// styles
const styleContainer = StyleSheet.create({
  tabBarContainer: { height: 48 },
  indicator: { position: 'absolute', marginTop: 46, height: 2 }
});

const MyMarkersNavigation = () => {
  const th = useTheme();
  const transMessage = {
    favorite: 'Favorite',
    allMarkers: 'All Markers'
  };
  const t = useTranslationFunc(transMessage);

  const TabTop = createMaterialTopTabNavigator<MyMarkersTabParamList>();
  const TopTabBar = ({ navigation, state }: any) => {
    const selectedColor = th['color-primary-500'];
    const defaultColor = th['color-basic-600'];
    return (
      <>
        <Divider />
        <TabBar
          style={styleContainer.tabBarContainer}
          appearance="default"
          indicatorStyle={styleContainer.indicator}
          selectedIndex={state.index}
          onSelect={(index) => navigation.navigate(state.routeNames[index])}>
          {state.routeNames.map((routeName: string, index: number) => {
            if (routeName === 'Favorite') {
              return (
                <Tab
                  title={() => (
                    <Text
                      category={state.index === 0 ? 's2' : 'p2'}
                      style={{ color: state.index === 0 ? selectedColor : defaultColor }}>
                      {t('favorite')}
                    </Text>
                  )}
                  key={index}
                  icon={<FavoriteIcon color={state.index === 0 ? selectedColor : defaultColor} />}
                  style={{ flexDirection: 'row', columnGap: 8 }}
                />
              );
            } else if (routeName === 'AllMarkers') {
              return (
                <Tab
                  title={() => (
                    <Text
                      category={state.index === 1 ? 's2' : 'p2'}
                      style={{ color: state.index === 1 ? selectedColor : defaultColor }}>
                      {t('allMarkers')}
                    </Text>
                  )}
                  key={index}
                  icon={<AllMarkersIcon color={state.index === 1 ? selectedColor : defaultColor} />}
                  style={{ flexDirection: 'row', columnGap: 8 }}
                />
              );
            }
          })}
        </TabBar>
      </>
    );
  };

  // view
  return (
    <TabTop.Navigator tabBar={(props) => <TopTabBar {...props} />}>
      <TabTop.Screen
        options={{
          lazy: true
        }}
        name="Favorite"
        component={MyMarkersScreens.FavoriteScreen}
      />
      <TabTop.Screen options={{ lazy: true }} name="AllMarkers" component={MyMarkersScreens.AllMarkersScreen} />
    </TabTop.Navigator>
  );
};

export default MyMarkersNavigation;
