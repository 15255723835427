import React from 'react';
import { StyleSheet, ViewProps } from 'react-native';
import { Layout, Text } from '@ui-kitten/components';
import { ScoreDriver, UIHelper as uh } from '../../core';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';
import ScoreDriverItem from './conponents/ScoreDriverItem';

//props
interface ScoreDriversProps extends ViewProps {
  pillar: string;
  scoreDrivers: ScoreDriver[];
}

const styleContainer = StyleSheet.create({
  container: {
    height: 'auto'
  }
});

const ScoreDrivers = ({ scoreDrivers, pillar }: ScoreDriversProps) => {
  const transMessage = {
    scoreDrivers: 'Score Drivers'
  };
  const t = useTranslationFunc(transMessage);
  if (scoreDrivers.length == 0) {
    return <></>;
  }

  return (
    <Layout level="2" style={styleContainer.container}>
      <Text category="c2" style={{ marginBottom: uh.h2DP(8) }}>
        {t('scoreDrivers')}
      </Text>
      {scoreDrivers.map((scoreDriver) => (
        <ScoreDriverItem scoreDriver={scoreDriver} pillar={pillar} key={scoreDriver.displayName} />
      ))}
    </Layout>
  );
};

export default ScoreDrivers;
