import { useCallback, useEffect, useState } from 'react';
import ScoreService from 'src/api/scores';
import { useAnalytics } from './useAnalytics';
import { useAppStore } from '../store';
import { useIsUserLogged } from './useUser';
import { ApiError } from '../types/ApiResponse';
import { HealthMarkerReportListItem } from '../types/HealthMarkerReportList';
import { HealthMarkerHistoryResponse } from '../types/HealthMarkerHistory';

interface UseMarkerrScoreHistoriesProps {
  key: string;
  startDate: string;
  endDate: string;
}

export const useMarkerScoreHistoriesEffect = ({ key, startDate, endDate }: UseMarkerrScoreHistoriesProps) => {
  const setMarkerScoreHistories = useAppStore((state) => state.setMarkerScoreHistories);
  const markerScoreHistories = useAppStore((state) => state.markerScoreHistories);
  const [isScoreHistoriesLoading, setIsScoreHistoriesLoading] = useState<boolean>(true);
  const { addAnalyticsLog } = useAnalytics('useMarkerScoreHistories.ts');
  const [assessments, setAssessments] = useState<HealthMarkerReportListItem[]>([]);
  const [error, setError] = useState<any>();
  const isUserLogged = useIsUserLogged();

  const loadMarkerScoreHistories = useCallback(
    (promise: Promise<HealthMarkerHistoryResponse>) => {
      promise
        .then((data) => {
          if (data.found.length > 0) {
            setMarkerScoreHistories(data.found[0].values);
            setAssessments(data.found[0].assessments);
          }
        })
        .catch((err: ApiError) => {
          if (!err.isAborted) {
            setError(err.error);
          }
          addAnalyticsLog({ function: 'loadMarkerScoreHistories', data: err, logType: 'error' });
        })
        .finally(() => {
          setIsScoreHistoriesLoading(false);
        });
    },
    [addAnalyticsLog, setMarkerScoreHistories]
  );

  useEffect(() => {
    if (isUserLogged) {
      const { promise, abort } = ScoreService.getMarkerScoreHistories(key, startDate, endDate);
      loadMarkerScoreHistories(promise);
      return abort;
    }
  }, [isUserLogged, loadMarkerScoreHistories, key, endDate, startDate]);

  return [assessments, markerScoreHistories, isScoreHistoriesLoading, error];
};
