import UserService from 'src/api/user/';
import { useAnalytics } from './useAnalytics';

export type AhiScanType = 'faceScan' | 'bodyScan';

export const useAhiSubscriptions = () => {
  const { addAnalyticsLog } = useAnalytics('useAhiSubscriptions.ts');

  const requestAhiScan = (scanType: AhiScanType): Promise<boolean> => {
    return UserService.requestAhiScan(scanType).catch((err) => {
      addAnalyticsLog({ function: 'requestAhiScan', data: err, logType: 'error' });
      return false;
    });
  };

  const requestPayment = (): Promise<boolean> => {
    return UserService.requestScanPayment().catch((err) => {
      addAnalyticsLog({ function: 'requestAhiScan', data: err, logType: 'error' });
      return false;
    });
  };

  return { requestAhiScan, requestPayment };
};
