import { StyleSheet, TouchableOpacity, View, ViewProps } from 'react-native';

import { PillarContainer } from 'src/components/home';
import React from 'react';
import RoundButton from './PillarButton';
import { useTheme } from '@ui-kitten/components';

//props
interface PillarButtonListItemProps extends ViewProps {
  style?: any;
  onBtnClickHandler: (type: string) => void;
  type: string;
  name: string;
  isSelected: boolean;
}

// styles
const styleContainer = StyleSheet.create({
  selectedContainer: {
    borderRadius: 25,
    alignItems: 'center',
    flexWrap: 'wrap',
    flexDirection: 'column'
  }
});

const PillarButtonListItem = ({ style, onBtnClickHandler, type, name, isSelected }: PillarButtonListItemProps) => {
  const th = useTheme();
  return (
    <TouchableOpacity
      key={name}
      testID={`pillar_${type}_button`}
      onPress={() => {
        onBtnClickHandler(type);
      }}>
      <View
        style={[
          {
            padding: 4,
            backgroundColor: isSelected
              ? type === 'all'
                ? th['color-primary-transparent-500']
                : th[`color-${type}-200-transparent`]
              : 'transparent'
          },
          isSelected ? styleContainer.selectedContainer : undefined,
          style
        ]}>
        {type === 'all' ? <RoundButton name={name} /> : <PillarContainer type={type} name={name} size="large" />}
      </View>
    </TouchableOpacity>
  );
};

export default PillarButtonListItem;
