import { BiomarkerItem, HealthMarker } from 'src/core/types/Bloodwork';
import { Button, Card, Text } from '@ui-kitten/components';
import React, { useState } from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';

import BackendApi from 'src/api/shared/BackendApi';
import EditHealthMakerListItem from './EditHealthMakerListItem';
import { ErrorMessage } from 'src/components/shared/ErrorMessage';
import Spinner from 'react-native-loading-spinner-overlay';
import { UIHelper as uh } from '../../../../core';
import { useAnalytics } from 'src/core/hooks/useAnalytics';

interface IAddHealthMarkerModalProps extends ViewProps {
  documentId: string;
  btnCancelClick: () => void;
  addMoreHealthMarker: (healthMarkerId: number, unitId: number, value: string) => void;
  allHealthMarkers: Array<HealthMarker>;
  dropdownBGColor: string;
  intputColor: string;
}

const AddHealthMarkerModal = (props: IAddHealthMarkerModalProps) => {
  const { addAnalyticsLog } = useAnalytics('AddHealthMarkerModal.tsx');

  const styles = StyleSheet.create({
    container: {
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'center',
      borderRadius: 16,
      padding: 16,
      maxWidth: uh.currentViewPort() - 32
    },
    btnAdd: { width: 80, marginTop: 40 },
    btnCancel: { width: 80, marginTop: 8 },
    backdrop: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    }
  });

  const [value, setValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [unitId, setUnitId] = React.useState(0);
  const [healthMarkerId, setHealthMarkerId] = React.useState(0);
  const [error, setError] = React.useState<string>('');

  const biomarkerItem: BiomarkerItem = {
    bloodworkDataId: '',
    value: '',
    unitId: props.allHealthMarkers[0].healthMarkerUnitId,
    unit: props.allHealthMarkers[0].healthMarkerUnit,
    healthmarker: props.allHealthMarkers[0].healthMarkerName,
    healthMarkerId: props.allHealthMarkers[0].healthMarkerId,
    unit_types: props.allHealthMarkers[0].healthMarkerUnitTypes,
    status: 0,
    pageNumber: 0
  };

  const updateValue = (newId: string, newValue: string, newUnitId: number, newHealthMarkerId?: number): void => {
    setValue(newValue);
    setUnitId(newUnitId);
    setHealthMarkerId(newHealthMarkerId as number);
  };

  const addHealthMarker = async (): Promise<any> => {
    try {
      const parameters = {
        documentId: props.documentId,
        healthMarkerId: healthMarkerId,
        value: Number(value),
        unitId: unitId
      };

      const response = await BackendApi.post('/health-markers/bloodwork-add-new-marker', parameters);
      if (response.status >= 200 && response.status <= 399) {
        if (response.data !== undefined) {
          return {};
        }
      } else {
        addAnalyticsLog({
          function: 'addHealthMarker',
          data: { response: response, inputData: parameters },
          logType: 'error'
        });
        return { errorMessage: 'There was a problem processing the request. Please try again later.' };
      }
    } catch (err) {
      addAnalyticsLog({ function: 'addHealthMarker', data: error, logType: 'error' });
      return { errorMessage: 'There was a problem processing the request. Please try again later.' };
    }
  };

  const btnAddClick = async () => {
    try {
      setIsLoading(true);
      const result = await addHealthMarker();
      if (result?.errorMessage != undefined) {
        setError(result?.errorMessage);
      } else {
        props.addMoreHealthMarker(healthMarkerId, unitId, value);
        props.btnCancelClick();
      }
      setIsLoading(false);
    } catch (err) {
      setError((err as Error).message);
      addAnalyticsLog({ function: 'btnAddClick', data: error, logType: 'error' });
      setIsLoading(false);
    }
  };

  const healthMarker = props.allHealthMarkers.find((hm) => hm.healthMarkerId == healthMarkerId);
  const unitType = healthMarker?.healthMarkerUnitTypes.find((item) => item.unitId == unitId);
  const disableButton =
    unitType == undefined ||
    Number.isNaN(Number(value)) ||
    value.trim().length == 0 ||
    Number(value) > Number(unitType?.rangeMax) ||
    Number(value) < Number(unitType?.rangeMin);

  return (
    <View
      style={{
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
        height: 500,
        padding: 16,
        marginTop: 50
      }}>
      {isLoading && <Spinner visible={true}></Spinner>}
      <Card style={[styles.container]}>
        <ErrorMessage message={error} />
        <Text style={{ textAlign: 'center', marginBottom: 40 }}>Add Marker</Text>
        <EditHealthMakerListItem
          style={{ marginTop: 8 }}
          item={biomarkerItem}
          updateValue={updateValue}
          editable={true}
          allHealthMarkers={props.allHealthMarkers}
          inputColor={props.intputColor}
          dropdownBGColor={props.dropdownBGColor}
        />
        <View
          style={{
            flexDirection: 'column',
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'center'
          }}>
          <Button
            size="small"
            style={styles.btnAdd}
            appearance="filled"
            status="primary"
            disabled={disableButton}
            onPress={btnAddClick}>
            Add
          </Button>
          <Button
            size="small"
            style={styles.btnCancel}
            appearance="outline"
            status="primary"
            onPress={props.btnCancelClick}>
            Cancel
          </Button>
        </View>
      </Card>
    </View>
  );
};

export default AddHealthMarkerModal;
