import { Path, Svg } from 'react-native-svg';

import React from 'react';
import { SvgIconProp } from 'src/core/types/ScanIcons';

export const NourishIcon = ({ color = '#A83440', height = 20, width = 20, strokeWidth = 0 }: SvgIconProp) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 20 20" stroke={color} strokeWidth={strokeWidth} fill={color}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.09461 5.00123C2.15715 6.12252 2.47629 6.81665 2.94367 7.2444C2.71547 7.41436 2.58317 7.69965 2.63174 8.00811C2.87023 9.52253 3.46646 12.7259 4.68869 16.3367C4.9316 17.0543 5.90181 17.0543 6.14473 16.3367C7.36694 12.7259 7.96319 9.52253 8.20169 8.00811C8.25023 7.69965 8.11794 7.41436 7.88973 7.24436C8.3571 6.81665 8.67623 6.12252 8.73877 5.00123C8.7516 4.77148 8.56285 4.58223 8.33277 4.58553C7.65439 4.59528 7.1156 4.63648 6.69527 4.77778C6.52177 4.32011 6.17148 3.88801 5.64431 3.49355C5.50964 3.39277 5.32373 3.39277 5.18906 3.49355C4.66619 3.8848 4.31727 4.31307 4.14236 4.76661C3.73417 4.64623 3.19966 4.59848 2.50169 4.58627C2.27161 4.58223 2.08179 4.77148 2.09461 5.00123ZM6.20039 5.82328L5.91606 5.07315C5.83731 4.86548 5.68344 4.63194 5.41669 4.38561C5.15273 4.62936 4.99931 4.86057 4.91985 5.06652L4.64344 5.78319L3.90663 5.5659C3.68927 5.50182 3.39248 5.45903 2.97551 5.43623C3.09939 6.23961 3.39716 6.57915 3.66861 6.75473C4.05674 7.00573 4.62227 7.08211 5.40423 7.0829H5.42914C6.21114 7.08211 6.77664 7.00573 7.16477 6.75473C7.43648 6.57898 7.73456 6.23898 7.85823 5.43394C7.45414 5.45511 7.17048 5.49719 6.96081 5.56765L6.20039 5.82328ZM3.4609 7.91623H7.37252C7.25314 8.66932 7.0459 9.83257 6.71844 11.2496H4.99998V12.0829H6.51873C6.23094 13.2422 5.86852 14.5339 5.41669 15.8871C5.2691 15.445 5.13102 15.0094 5.00198 14.5829H5.41664V13.7496H4.75773C4.35877 12.3437 4.06028 11.0612 3.84209 9.99957H4.99998V9.16623H3.67809C3.58597 8.67615 3.51455 8.25478 3.4609 7.91623Z"
        fill={color}
      />
      <Path
        d="M15.9344 11.6795C16.1577 11.7354 16.2934 11.9616 16.2376 12.1848C15.9919 13.1674 15.2504 13.909 14.2677 14.1546C14.0445 14.2105 13.8183 14.0747 13.7625 13.8515C13.7067 13.6282 13.8424 13.402 14.0657 13.3462C14.7497 13.1752 15.2581 12.6667 15.4292 11.9827C15.4849 11.7594 15.7112 11.6237 15.9344 11.6795Z"
        fill={color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6045 8.76144C13.1425 7.68107 13.2226 6.53336 14.0446 5.71132L13.4554 5.12207C12.4848 6.09261 12.3162 7.36265 12.6418 8.52953C10.7352 7.66695 8.75 9.25324 8.75 11.6667C8.75 13.9679 10.802 15.8334 13.3333 15.8334C15.8646 15.8334 17.9167 13.9679 17.9167 11.6667C17.9167 9.07495 15.6273 7.43711 13.6045 8.76144ZM13.3333 10.0255L12.8127 9.60907C12.0386 8.98974 11.2759 9.02415 10.6983 9.3809C10.0877 9.75807 9.58333 10.5657 9.58333 11.6667C9.58333 13.4347 11.1857 15 13.3333 15C15.4809 15 17.0833 13.4347 17.0833 11.6667C17.0833 10.5657 16.579 9.75807 15.9683 9.3809C15.3907 9.02415 14.628 8.98974 13.8539 9.60907L13.3333 10.0255Z"
        fill={color}
      />
      <Path
        d="M14.1667 7.50033C15.4167 7.50033 16.25 6.66699 16.25 5.41699C15 5.41699 14.1667 6.25033 14.1667 7.50033Z"
        fill={color}
      />
    </Svg>
  );
};
