import Svg, { G, Path, Rect } from 'react-native-svg';

import React from 'react';
import { SimpleIconProps } from 'src/core';

const ForwardIcon = ({ size = 24, color = '#FFFFFF' }: SimpleIconProps) => (
  <Svg width={`${size}px`} height={`${size}px`} viewBox="0 0 24 24">
    <G data-name="Layer 2">
      <G data-name="arrow-ios-forward">
        <Rect width={`${size}px`} height={`${size}px`} transform="rotate(-90 12 12)" opacity="0" />
        <Path
          fill={color}
          d="M10 19a1 1 0 0 1-.64-.23 1 1 0 0 1-.13-1.41L13.71 12 9.39 6.63a1 1 0 0 1 .15-1.41 1 1 0 0 1 1.46.15l4.83 6a1 1 0 0 1 0 1.27l-5 6A1 1 0 0 1 10 19z"
        />
      </G>
    </G>
  </Svg>
);

export default ForwardIcon;
