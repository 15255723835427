import { SafeAreaView, StyleSheet } from 'react-native';

import { Layout } from '@ui-kitten/components';
import { Profile } from '../../components/settings';
import React from 'react';
import { RootStackScreenProps } from 'src/core/types/RootNavigationParamList';
import { settingStyles } from './_settingStyles';

const SettingsScreen = ({ route }: RootStackScreenProps<'ProfileSettings' | 'ProfileSignUp'>) => {
  //styles
  const styleContainer = StyleSheet.create({
    screenContainer: { flex: 1 },
    safeAreaView: { flex: 1 }
  });

  //view
  return (
    <Layout level="2" style={[styleContainer.screenContainer, settingStyles.settingScreenContainer]}>
      <SafeAreaView style={styleContainer.screenContainer}>
        <Profile shouldRedirect={route.params?.shouldRedirect} />
      </SafeAreaView>
    </Layout>
  );
};

export default SettingsScreen;
