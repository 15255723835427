import moment from 'moment';

export interface BloodPressure {
  systolic: number;
  diastolic: number;
}
export type Sex = 'M' | 'F';
export type ScanType = 'face' | 'body' | 'HRV' | 'bloodwork';
export interface FaceScanData {
  scanID: string;
  scanDate: string;
  heartRate: number;
  weightKg: number;
  heightCm: number;
  systolic: number;
  diastolic: number;
  breathingRate: number;
  hrVariability: number;
  irregularHeartBeats: number;
  cvdRiskPercent: number;
  heartAttackRiskPercent: number;
  strokeRiskPercent: number;
  smoker: boolean;
  bloodpressureMedication: boolean;
  hypertension: boolean;
  diabetic: number;
  cardiacWorkload: number;
  vascularCapacity: number;
  breathingRateFrequency: number;
}

export interface HRVScanData {
  biomarkers: any;
  insights: any;
  signalQuality: any;
}

// export enum Ethnicity {
//   ETH_1,
//   ETH_2,
//   ETH_3,
// }

export interface BodyScanData {
  scanID: string;
  scanDate: string;
  sex: Sex;
  weightKg: number;
  heightCm: number;
  age: number;
  bodyFatPercent: number;
  waistCircumference: number;
  chestMeasurement: number;
  hipMeasurement: number;
  thighMeasurement: number;
  //objFilePath: string;
  ahiSdkVersion: string;
}

export const toFaceScanData = (faceScanID: string, sdkResult: any, userScanInput: any): FaceScanData => {
  const diabetics = ['none', 'type1', 'type2'];
  return {
    scanDate: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSSSZ'),
    scanID: faceScanID,
    // sex: inputs.enum_ent_sex == 'male' ? 'M' : 'F',
    weightKg: userScanInput.kg_ent_weight,
    heightCm: userScanInput.cm_ent_height,
    // age: inputs.yr_ent_age,
    // userHasHypertension: inputs.bool_ent_hypertension,
    // userIsDiabetic: inputs.enum_ent_diabetic,
    // userIsSmoker: inputs.bool_ent_smoker,
    // userTakesBloodPressureMedication: inputs.bool_ent_bloodPressureMedication,
    heartRate:
      sdkResult.bpm_raw_heartRate != undefined
        ? Number.parseInt(sdkResult.bpm_raw_heartRate)
        : Math.round(Number.parseFloat(sdkResult.hz_raw_heartRate) * 60),
    systolic: Number.parseInt(sdkResult.mmHg_raw_bloodPressureSystolic),
    diastolic: Number.parseInt(sdkResult.mmHg_raw_bloodPressureDiastolic),
    breathingRate: Number.parseFloat(sdkResult.bpm_raw_breathingRate),
    hrVariability: Number.parseFloat(sdkResult.sdnn_raw_heartRateVariability),
    irregularHeartBeats: Number.parseFloat(sdkResult.bpm_raw_heartRateIrregular),
    heartAttackRiskPercent: Number.parseFloat(sdkResult.percent_raw_heartAttackRisk),
    cvdRiskPercent: Number.parseFloat(sdkResult.percent_raw_cardiovascularDiseaseRisk),
    strokeRiskPercent: Number.parseFloat(sdkResult.percent_raw_strokeRisk),
    smoker: userScanInput.bool_ent_smoker,
    bloodpressureMedication: userScanInput.bool_ent_bloodPressureMedication,
    hypertension: userScanInput.bool_ent_hypertension,
    diabetic: diabetics.indexOf(userScanInput.enum_ent_diabetic),
    cardiacWorkload: Number.parseFloat(sdkResult.db_raw_cardiacWorkload),
    vascularCapacity: Number.parseFloat(sdkResult.secs_raw_vascularCapacity),
    breathingRateFrequency: Number.parseFloat(sdkResult.hz_raw_breathingRate)
  };
};

export const toBodyScanData = (sdkResult: any, inputs: any): BodyScanData => {
  return {
    scanDate: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSSSZ'),
    scanID: sdkResult.id,
    sex: inputs.enum_ent_sex == 'male' ? 'M' : 'F',
    weightKg: inputs.kg_ent_weight,
    heightCm: inputs.cm_ent_height,
    age: inputs.yr_ent_age,
    bodyFatPercent: Number.parseFloat(sdkResult.percent_raw_bodyFat),
    waistCircumference: Number.parseFloat(sdkResult.cm_raw_waist),
    chestMeasurement: Number.parseFloat(sdkResult.cm_raw_chest),
    hipMeasurement: Number.parseFloat(sdkResult.cm_raw_hips),
    thighMeasurement: Number.parseFloat(sdkResult.cm_raw_thigh),
    //objFilePath: sdkResult.objFileUrl,
    ahiSdkVersion: sdkResult.ver
  };
};
