import { useGetUserEmail, useGetUserId } from 'src/core/hooks/useUser';
import { useHasMarkersInitError, useIsMarkersInitLoading } from 'src/core/hooks/useMarkers';
import {
  useHasMultiScanInitError,
  useHasMultiScanUserError,
  useIsMultiScanInitLoading,
  useIsMultiScanUserLoading,
  useMultiScanAuthorize,
  useMultiScanSetup
} from 'src/core/hooks/useMultiScan';

import React from 'react';
import ScanLoading from '../component';

interface ScanLoadingContainerProps {
  scanType: 'face' | 'body';
  onCancel: () => void;
}

const ScanLoadingContainer = ({ scanType, onCancel }: ScanLoadingContainerProps) => {
  const isInitLoading = useIsMultiScanInitLoading();
  const hasInitError = useHasMultiScanInitError();
  const setupMultiScan = useMultiScanSetup();
  const isUserLoading = useIsMultiScanUserLoading();
  const isMarkersInitLoading = useIsMarkersInitLoading();
  const hasUserError = useHasMultiScanUserError();
  const hasMarkerError = useHasMarkersInitError();
  const authorizeUser = useMultiScanAuthorize();
  const userEmail = useGetUserEmail() ?? '';
  const userId = useGetUserId() ?? '';

  const isLoading = isInitLoading || isUserLoading || isMarkersInitLoading;
  const hasError = hasInitError || hasUserError || hasMarkerError;

  const handleOnRetry = () => {
    setupMultiScan().then((isInit) => {
      if (isInit) {
        return authorizeUser(userId, userEmail);
      }
    });
  };

  return (
    <ScanLoading
      scanType={scanType}
      isLoading={isLoading}
      hasError={hasError}
      onRetry={handleOnRetry}
      onCancel={onCancel}
    />
  );
};

export default ScanLoadingContainer;
