import { useCallback, useEffect, useState } from 'react';
import { useAnalytics } from './useAnalytics';
import { useAppStore } from '../store';
import { useIsUserLogged } from './useUser';
import { ApiError } from '../types/ApiResponse';
import SleepStatService from 'src/api/sleepStat';
import { SleepStat } from '../types/SleepStat';

export const useGetSleepStat = () => {
  const pillarScoreDrivers = useAppStore((state) => state.sleepStat);
  return pillarScoreDrivers;
};

interface UseSleepStatProps {
  refreshing: boolean;
  sleepId: string;
  setRefreshing: (refreshing: boolean) => void;
}

export const useSleepStatEffect = ({ refreshing, setRefreshing, sleepId }: UseSleepStatProps) => {
  const setSleepStat = useAppStore((state) => state.setSleepStat);
  const sleepStat = useAppStore((state) => state.sleepStat);
  const { addAnalyticsLog } = useAnalytics('useSleepStat.ts');
  const [isScoreDriversLoading, setIsScoreDriversLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>();
  const isUserLogged = useIsUserLogged();

  const loadSleepStat = useCallback(
    (promise: Promise<SleepStat>) => {
      promise
        .then((sleepStatData) => {
          setSleepStat(sleepStatData);
        })
        .catch((err: ApiError) => {
          if (!err.isAborted) {
            setError(err.error);
          }
          addAnalyticsLog({ function: 'loadSleepStat', data: err, logType: 'error' });
        })
        .finally(() => {
          setIsScoreDriversLoading(false);
          setRefreshing(false);
        });
    },
    [addAnalyticsLog, setRefreshing, setSleepStat]
  );

  useEffect(() => {
    if (isUserLogged) {
      const { promise, abort } = SleepStatService.getSleepStat(sleepId);
      loadSleepStat(promise);
      return abort;
    }
  }, [isUserLogged, loadSleepStat, sleepId]);

  useEffect(() => {
    if (refreshing) {
      const { promise, abort } = SleepStatService.getSleepStat(sleepId);
      loadSleepStat(promise);
      return abort;
    }
  }, [refreshing, loadSleepStat, sleepId]);

  return [sleepStat, isScoreDriversLoading, error];
};
