import { StyleSheet, View, ViewProps } from 'react-native';
import { Text, useTheme } from '@ui-kitten/components';

import React from 'react';
import { UIHelper as uh } from '../../../core';

//props
interface PillarButtonProps extends ViewProps {
  name: string;
}

// styles
const styleContainer = StyleSheet.create({
  container: {
    borderRadius: 25,
    paddingLeft: uh.w2DP(12),
    paddingRight: uh.w2DP(12),
    paddingTop: uh.h2DP(6),
    paddingBottom: uh.h2DP(6)
  }
});

const PillarButton = ({ name }: PillarButtonProps) => {
  const th = useTheme();

  // view
  return (
    <View style={[styleContainer.container, { backgroundColor: th['color-primary-500'] }]}>
      <Text category="p2" style={{ color: th['color-basic-100'] }}>
        {name}
      </Text>
    </View>
  );
};

export default PillarButton;
