import * as DocumentPicker from 'expo-document-picker';
import * as Progress from 'react-native-progress';

import { Animated, StyleSheet, View, ViewProps } from 'react-native';
import { Button, Divider, Layout, ModalService, Text } from '@ui-kitten/components';
import React, { useState } from 'react';

import DoneModal from './review/DoneModal';
import { ErrorMessage } from 'src/components/shared/ErrorMessage';
import ModalHeader from 'src/components/profileMeMarker/ModalHeader';
import { NavigationProp } from '@react-navigation/native';
import { UIHelper as uh } from '../../../core';
import { uploadUserFile } from 'src/api/storage/S3Storage';
import { useAnalytics } from 'src/core/hooks/useAnalytics';

interface UploadBloodworkModalProps extends ViewProps {
  viewAssessmentHandler: (assessmentId: string) => void;
  btnBackHandler: () => void;
  navigation: NavigationProp<ReactNavigation.RootParamList>;
}

const UploadBloodworkModal = (props: UploadBloodworkModalProps) => {
  const { addAnalyticsLog } = useAnalytics('UploadBloodworkModal.tsx');

  let modalID = '';
  const [error, setError] = React.useState<string>('');
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [uploading, setUploading] = useState<boolean>(false);
  const [tooLargeError, setTooLargeError] = useState<boolean>(false);
  const moveAnim = React.useRef(new Animated.Value(uh.height())).current;

  const requiredSize = 5; // max pdf file size in MB

  const tooLargeMessage = `This file is too large. Please pick a file with the size less than ${requiredSize}MB`;
  const doneDes =
    "We've received your document, and we have already started working on it.\n\nIn a minute or two, it will appear for validation at the top of your Assessments screen.\n";

  const styles = StyleSheet.create({
    screenContainer: {
      padding: uh.h2DP(16),
      paddingTop: 0,
      minHeight: uh.height() - uh.topPos()
    },
    animatedViewContainer: {
      height: uh.height() - uh.topPos(),
      position: 'absolute',
      alignSelf: 'center',
      width: uh.currentViewPort(),
      transform: [
        {
          translateY: moveAnim as any
        }
      ]
    },
    container: {
      flex: 1,
      padding: uh.h2DP(16)
    },
    progressBar: {
      marginBottom: uh.h2DP(20),
      width: uh.currentViewPort() - uh.w2DP(64),
      marginLeft: uh.w2DP(16)
    },
    progressBarTitle: {
      margin: uh.h2DP(20)
    },
    button: {
      alignItems: 'center',
      padding: uh.h2DP(10)
    },
    item: {
      marginTop: uh.w2DP(8)
    },
    divider: {
      marginTop: uh.w2DP(6)
    },
    backdropStyle: { backgroundColor: 'rgba(0, 0, 0, 0.5)' }
  });

  const moveDown = (callBackFnc: () => void) => {
    // Will increase value to windowHeight in 500 milliseconds
    Animated.timing(moveAnim, {
      toValue: uh.height(),
      duration: 500,
      useNativeDriver: true
    }).start(() => {
      callBackFnc();
    });
  };

  const renderDoneModalContentElement = () => {
    return (
      <DoneModal
        caption="Bloodwork successfully uploaded"
        description={doneDes}
        btnDoneClick={() => {
          ModalService.hide(modalID);
          moveDown(props.btnBackHandler);
        }}
        btnHyperlinkText={'Go to assessments'}
        btnHyperlinkClick={() => {
          ModalService.hide(modalID);
          moveDown(props.btnBackHandler);
          props.navigation.navigate('Profile', { screen: 'Assessments' });
        }}
      />
    );
  };

  const showDoneModal = () => {
    const contentElement = renderDoneModalContentElement();
    modalID = ModalService.show(contentElement, {
      backdropStyle: styles.backdropStyle
    });
  };

  // select Bloodwork document
  const pickPdfDocument = async () => {
    const result: DocumentPicker.DocumentResult = await DocumentPicker.getDocumentAsync({
      type: 'application/pdf'
    });

    if (result.type !== 'cancel') {
      // calculate file size in MB

      if (result.size && Number(Math.floor(result.size / Math.pow(1024, 2)).toFixed(2)) > requiredSize) {
        setTooLargeError(true);
        return;
      }

      setTooLargeError(false);
      setUploadProgress(0);
      setUploading(true);
      try {
        const response = await uploadUserFile(result.name, result.uri);
        if (response.status >= 200 && response.status <= 399) {
          setUploadProgress(100);
          setTimeout(() => {
            setUploading(false);
          }, 300);
        } else {
          setError('Can\t upload Bloodwork document.');
          addAnalyticsLog({ function: 'pickPdfDocument', data: response, logType: 'error' });
          setUploading(false);
        }
        setTimeout(() => {
          showDoneModal();
        }, 1000);
      } catch (err) {
        addAnalyticsLog({ function: 'pickPdfDocument', data: err, logType: 'error' });
      }
    }
  };

  const moveUp = React.useCallback(() => {
    // Will decrease value to 30 in 500 milliseconds
    Animated.timing(moveAnim, {
      toValue: uh.topPos(),
      duration: 500,
      useNativeDriver: true
    }).start();
  }, [moveAnim]);

  React.useEffect(() => {
    moveUp();
  }, [moveUp]);

  const UploadButton = () => {
    return (
      <View style={styles.button}>
        <Button onPress={() => pickPdfDocument()}>Select File</Button>
      </View>
    );
  };

  if (uploading) {
    return (
      <Animated.View style={styles.animatedViewContainer}>
        <ModalHeader
          caption="Details"
          btnClickHandler={() => {
            moveDown(props.btnBackHandler);
          }}
        />
        <Layout style={styles.container}>
          <ErrorMessage message={error} />
          <UploadButton />
          <Text style={styles.progressBarTitle}>Uploading ...</Text>
          <Progress.Bar progress={uploadProgress} style={styles.progressBar} width={uh.currentViewPort()} />
          <Divider />
        </Layout>
      </Animated.View>
    );
  }
  return (
    <Animated.View style={styles.animatedViewContainer}>
      <ModalHeader
        caption="Upload Bloodwork Document"
        btnClickHandler={() => {
          moveDown(props.btnBackHandler);
        }}
        showFileSizeLimit={true}
      />
      <Layout style={styles.container}>
        {tooLargeError && <ErrorMessage message={tooLargeMessage} />}
        <UploadButton />
        <Divider style={styles.divider} />
      </Layout>
    </Animated.View>
  );
};

export default UploadBloodworkModal;
