import { UIHelper as uh } from '../../../core';
import { Spinner, Text, useTheme } from '@ui-kitten/components';
import { StyleSheet, View, ViewProps, processColor, Platform } from 'react-native';
import { LineData, LineDataset, LineDatasetConfig } from 'react-native-charts-wrapper';
import React, { lazy, Suspense, useState } from 'react';
import moment from 'moment';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';
import { FrequencyType, SleepDurationHistoriesItem, SleepHistoriesItem, SleepType } from 'src/core/types/SleepStat';
import Frequency from './Frequency';
import { ChartHelper } from 'src/core/helpers/ChartHelper';
import NoGraphData from './NoGraphData';

//props
interface SleepLineChartProps extends ViewProps {
  graphData: Array<SleepHistoriesItem>;
  graphHistoriesData: Array<SleepDurationHistoriesItem>;
  isLoading: boolean;
}

// styles
const graphHeight = 180;
const styles = StyleSheet.create({
  container: {
    height: graphHeight
  },
  noGraphContainer: {
    height: 60
  },
  loading: {
    height: graphHeight,
    alignContent: 'center',
    alignSelf: 'center',
    padding: uh.h2DP(16)
  },
  areaChart: { height: 98 },
  webText: {
    textAlign: 'center',
    fontSize: 20
  }
});

const SleepLineChart = ({ graphData, graphHistoriesData, isLoading }: SleepLineChartProps) => {
  const th = useTheme();
  const ONE_HOUR = 3600.0;
  const transMessage = {
    noGraphData: 'No graph data',
    totalDurationChartsAreDisabled: 'Total Duration charts are disabled on the web app'
  };

  const t = useTranslationFunc(transMessage);

  const [frequencyType, setFrequencyType] = useState<FrequencyType>(FrequencyType.Week);

  const remColor = th['color-rest-700'];
  const deepColor = th['color-rest-600'];
  const lightColor = th['color-rest-500'];
  const outOfBedColor = th['color-rest-400'];
  const sleepingColor = th['color-rest-300'];
  const awakeColor = th['color-rest-100'];

  const getLineColor = (item: SleepHistoriesItem, index: number): string => {
    const color =
      item.value >= 6
        ? remColor
        : item.value >= 5
        ? deepColor
        : item.value >= 4
        ? lightColor
        : item.value >= 3
        ? outOfBedColor
        : item.value >= 2
        ? sleepingColor
        : awakeColor;
    if (index % 2 === 0) {
      return String(processColor(color));
    } else {
      return String(processColor('transparent'));
    }
  };

  if (isLoading) {
    return (
      <View style={styles.loading}>
        <Spinner status="primary" />
      </View>
    );
  }

  if (graphData.length == 0) {
    return <NoGraphData />;
  }

  let chartHistoriesData = ChartHelper.getHistoriesData(graphHistoriesData, frequencyType);
  chartHistoriesData = (chartHistoriesData as SleepDurationHistoriesItem[]).map((item) => {
    return {
      date: moment(item.date).toDate(),
      rem: item.rem / ONE_HOUR,
      deep: item.deep / ONE_HOUR,
      light: item.light / ONE_HOUR
    };
  });
  const { chartData, range } = ChartHelper.getChartDataAndRange(graphData);
  const formatedData = chartData.slice(0, -1).flatMap((item, index) => [
    { value: item.value, date: item.date },
    { value: item.value, date: chartData[index + 1].date }
  ]);
  const lastElement = chartData.slice(-1).map((item) => {
    return { value: item.value, date: item.date };
  });
  const newChartData = [...formatedData, ...lastElement];
  // setup line colors
  const lineColors = chartData.map(getLineColor);

  const createConfig = (mode: string, lineWidth: number, color: string, colors?: string[]) => {
    return {
      mode: mode,
      drawValues: false,
      lineWidth: lineWidth,
      color: processColor(color),
      drawCircles: false,
      highlightEnabled: Platform.OS !== 'android',
      drawVerticalHighlightIndicator: range !== 0,
      fillAlpha: 1000,
      colors: colors
    } as LineDatasetConfig;
  };

  const createHistoriesConfig = (mode: string, lineWidth: number, color: string, colors?: string[]) => {
    return {
      mode: mode,
      drawValues: false,
      lineWidth: lineWidth,
      color: processColor(color),
      drawCircles: false,
      highlightEnabled: Platform.OS !== 'android',
      drawVerticalHighlightIndicator: range !== 0,
      fillAlpha: 1000,
      drawFilled: true,
      colors: colors,
      fillGradient: {
        orientation: 'BOTTOM_TOP',
        colors: [processColor(color)],
        positions: [0, 0.7]
      }
    } as LineDatasetConfig;
  };

  const createHistoriesValues = (sleepType: SleepType) => {
    return (chartHistoriesData as SleepDurationHistoriesItem[]).map((item) => {
      return {
        x: moment(item.date).toDate().getTime(),
        y: sleepType === SleepType.Rem ? item.rem : sleepType === SleepType.Deep ? item.deep : item.light
      };
    });
  };

  const createDatasets = () => {
    const dataSets: LineDataset[] = [];
    if (frequencyType === FrequencyType.Day) {
      const values = newChartData.map((item) => {
        return {
          x: moment(item.date).toDate().getTime(),
          y: item.value
        };
      });
      dataSets.push({
        values: values,
        label: '',
        config: createConfig('STEPPED', 1, th['color-rest-300'] as string)
      });
      dataSets.push({
        values: values,
        label: '',
        config: createConfig('STEPPED', 20, '', lineColors)
      });
    } else if (frequencyType === FrequencyType.Month || frequencyType === FrequencyType.Week) {
      dataSets.push({
        values: createHistoriesValues(SleepType.Light),
        label: 'Light',
        config: createHistoriesConfig('LINEAR', 1, lightColor)
      });

      dataSets.push({
        values: createHistoriesValues(SleepType.Deep),
        label: 'Deep',
        config: createHistoriesConfig('LINEAR', 1, deepColor)
      });

      dataSets.push({
        values: createHistoriesValues(SleepType.Rem),
        label: 'Rem',
        config: createHistoriesConfig('LINEAR', 1, remColor)
      });
    }
    return dataSets;
  };

  const lineData: LineData = {
    dataSets: createDatasets()
  };

  if (Platform.OS === 'web') {
    return (
      <View>
        <View style={styles.container}>
          <Text style={{ ...styles.webText, ...{ color: th['text-basic-color'] } }}>
            {t('totalDurationChartsAreDisabled')}
          </Text>
        </View>
      </View>
    );
  } else {
    const PillarLineChart = lazy(() => import('../../../components/shared/PillarLineChart'));
    return (
      <View style={{}}>
        <Frequency
          onChangeFrequency={(type) => {
            setFrequencyType(type);
          }}
        />
        <View style={styles.container}>
          <Suspense fallback={<Spinner status="primary" />}>
            <PillarLineChart
              style={{
                flex: 1,
                height: 98
              }}
              data={lineData}
              animation={{
                durationX: 500,
                easingX: 'Linear'
              }}
              legend={frequencyType !== FrequencyType.Day}
              marker={{
                enabled: false
              }}
              pinchZoom={false}
              highlights={range === 0 ? [{ x: 0 }] : undefined}
              touchEnabled={false}
              xAxis={{
                enabled: true,
                drawLabels: true,
                position: 'BOTTOM',
                drawGridLines: false,
                textSize: 10,
                textColor: processColor(th['text-hint-color']),
                valueFormatter: 'date',
                timeUnit: 'MILLISECONDS',
                valueFormatterPattern: frequencyType === FrequencyType.Day ? 'HH a' : 'dd',
                labelCount: chartData.length <= 5 ? chartData.length : 5
              }}
              yAxis={{
                left: {
                  enabled: frequencyType !== FrequencyType.Day,
                  axisMinimum: 0,
                  drawGridLines: false,
                  position: 'OUTSIDE_CHART',
                  textSize: 10,
                  textColor: processColor(th['text-hint-color'])
                },
                right: { enabled: false }
              }}
            />
          </Suspense>
        </View>
      </View>
    );
  }
};

export default SleepLineChart;
