import { Button, Text } from '@ui-kitten/components';
import React, { RefObject } from 'react';
import { BottomSheetModalMethods } from '@gorhom/bottom-sheet/lib/typescript/types';
import { StyleSheet } from 'react-native';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';
import ActionBottomSheet from 'src/features/actions/components/ActionBottomSheet';

const styles = StyleSheet.create({
  content: {
    textAlign: 'center'
  },
  saveBtn: {
    marginTop: 5
  }
});

interface DeleteFavoriteBottomSheetProps {
  sheetRef: RefObject<BottomSheetModalMethods>;
  biomarker: any;
  onBtnClick: (key: string) => void;
}

const DeleteFavoriteBottomSheet = ({ sheetRef, biomarker, onBtnClick }: DeleteFavoriteBottomSheetProps) => {
  const transMessage = {
    deleteFavorite: 'Remove from Favorites',
    description: 'Are you sure you want to remove this marker from favorites?',
    remove: 'Remove'
  };

  const t = useTranslationFunc(transMessage);
  const handleBtnClick = () => {
    onBtnClick(biomarker.externalKey);
    sheetRef.current?.dismiss();
  };

  return (
    <ActionBottomSheet sheetRef={sheetRef} title={t('deleteFavorite')}>
      <Text style={styles.content}>{t('description')}</Text>
      <Button
        style={styles.saveBtn}
        status="primary"
        size="large"
        accessible={true}
        accessibilityLabel="remove"
        testID="button_remove"
        onPress={handleBtnClick}>
        {t('remove')}
      </Button>
    </ActionBottomSheet>
  );
};

export default DeleteFavoriteBottomSheet;
