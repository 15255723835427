import React from 'react';
import { View, StyleSheet, ViewProps } from 'react-native';
import { Text } from '@ui-kitten/components';
import { ScoreDriver, UIHelper as uh } from '../../../core';
import ProgressBar from './ProgressBar';

//props
interface ScoreDriverItemProps extends ViewProps {
  pillar: string;
  scoreDriver: ScoreDriver;
}

const styleContainer = StyleSheet.create({
  progressItem: {
    display: 'flex',
    paddingBottom: uh.h2DP(10),
    columnGap: uh.w2DP(16),
    marginTop: uh.h2DP(8),
    flexDirection: 'row',
    alignItems: 'center'
  },
  subcategory: { width: '30%' }
});

const ScoreDriverItem = ({ scoreDriver, pillar }: ScoreDriverItemProps) => {
  const done = Math.floor(Math.max(scoreDriver.score * 100, 0));
  return (
    <View style={styleContainer.progressItem}>
      <Text category="label" style={styleContainer.subcategory}>
        {scoreDriver.displayName}
      </Text>
      <ProgressBar done={done} pillar={pillar} />
    </View>
  );
};

export default ScoreDriverItem;
