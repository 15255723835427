import { Config, ConfigType } from 'src/core/constants/Config';

import { Buffer } from 'buffer';
import DeviceInfo from 'react-native-device-info';
import { Platform } from 'react-native';
import axios from 'axios';
import { getValue } from '../../api/storage';
import moment from 'moment-timezone';
import platform from 'platform';
import { useCallback } from 'react';
import { useGetAppName } from './useBrands';

export enum HandshakeVariant {
  PRE_AUTH = 'pre-auth',
  POST_AUTH = 'after-auth'
}

export interface UseHandshake {
  preAuth: () => Promise<any>;
  postAuth: (userToken: string) => Promise<any>;
}

export function useHandshake(): UseHandshake {
  const appName = useGetAppName().toLowerCase();
  const createHandshake = useCallback(
    async (variant: HandshakeVariant, userToken?: string) => {
      const clientId = `${appName}-${Platform.OS}-${
        Platform.OS !== 'web' ? DeviceInfo.getSystemVersion() : platform.name
      }-${Config.APP_BRANCH}`.slice(0, 50);
      const appCommitId = Config.GIT_COMMIT;

      const clientDeviceId =
        Platform.OS !== 'web'
          ? {
              deviceId: await DeviceInfo.getUniqueId(),
              deviceManufacturer: await DeviceInfo.getManufacturer(),
              deviceModel: DeviceInfo.getModel(),
              deviceSystemVersion: DeviceInfo.getSystemVersion(),
              deviceBuildNumber: DeviceInfo.getBuildNumber(),
              version: DeviceInfo.getVersion(),
              deviceName: await DeviceInfo.getDeviceName()
            }
          : {
              device: platform.name,
              deviceVersion: platform.version,
              os: {
                architecture: platform.os?.architecture,
                family: platform.os?.family,
                version: platform.os?.version
              }
            };

      const clientPlatform = {
        os: Platform.OS,
        version: Platform.OS !== 'web' ? Platform.Version : null
      };

      const clientTime = moment().format();

      const postData = {
        clientId: clientId,
        appCommitId: appCommitId,
        clientDeviceId: clientDeviceId,
        clientPlatform: clientPlatform,
        clientTime: clientTime,
        apiKey: Config.HANDSHAKE_API_KEY
      };

      const username = 'maxb';
      const password = 'nM3sBPb553bFJMcDISmz';
      const hash = Buffer.from(`${username}:${password}`).toString('base64');

      const tenant = await getValue('tenantKey');
      // return Promise<any>;
      return axios
        .post(`${Config.BACKEND_API_ENDPOINT}/handshake/${variant}`, postData, {
          headers: {
            'Content-Type': 'application/json',
            'The-Timezone-IANA': moment.tz.guess(),
            'X-Extra-Authorization': `Basic ${hash}`,
            Tenant: tenant,
            Authorization: `Bearer ${userToken}`
          }
        })
        .then((response) => {
          const newEnv = response.data.envVars.reduce((prev: any, curr: any) => {
            prev[curr.name] = curr.value ? curr.value : Config[curr.name as keyof ConfigType];

            return prev;
          }, {});

          let configKey: keyof ConfigType;

          for (configKey in Config) {
            if (newEnv[configKey]) {
              (Config as any)[configKey] = newEnv[configKey];
            }
          }

          // return the users subscription plan to pass it later to the app context

          if (variant === HandshakeVariant.POST_AUTH) {
            return {
              subscription: response.data.user_subscription_plan,
              tenant: response.data.tenant
            };
          } else {
            return axios
              .post(`${Config.BACKEND_API_ENDPOINT}/handshake/white-label/mobile`, postData, {
                headers: {
                  'Content-Type': 'application/json',
                  'The-Timezone-IANA': moment.tz.guess(),
                  'X-Extra-Authorization': `Basic ${hash}`,
                  Tenant: tenant,
                  Authorization: `Bearer ${userToken}`
                }
              })
              .then((brands) => brands.data);
          }
        })
        .catch((err) => console.error(err));
    },
    [appName]
  );

  const preAuth = useCallback(() => {
    return createHandshake(HandshakeVariant.PRE_AUTH);
  }, [createHandshake]);

  const postAuth = useCallback(
    async (userToken: string) => {
      const userSubscriptionPlan = await createHandshake(HandshakeVariant.POST_AUTH, userToken);
      return userSubscriptionPlan;
    },
    [createHandshake]
  );

  return { preAuth, postAuth };
}
