import Brands from 'brands.json';

const getTrackerId = (response: any): string => {
  try {
    return response.headers[Brands.tracker_id_header] == undefined ? '' : response.headers[Brands.tracker_id_header];
  } catch (error) {
    return '';
  }
};

const getUrl = (response: any): string => {
  try {
    return response.config.url == undefined ? '' : response.config.url;
  } catch (error) {
    return '';
  }
};

const getErrorCode = (errorCode: any, response: any): string => {
  try {
    if (errorCode != undefined && errorCode.code) {
      return errorCode.code;
    } else if (response != undefined) {
      return response.status;
    }
    return '';
  } catch (error) {
    return '';
  }
};

//Request Helper export functions
export const RequestHelper = {
  getTrackerId: (response: any): string => {
    return getTrackerId(response);
  },
  getUrl: (response: any): string => {
    return getUrl(response);
  },
  getErrorCode: (error: any, response: any): string => {
    return getErrorCode(error, response);
  }
};
