import { StateCreator } from 'zustand';

export interface BrandsSlice {
  app_name: string;
  chatbot_name: string;
  app_website: string;
  terms_of_service: string;
  privacy_policy: string;
  data_handling_website: string;
  default_subscription: string;
  support_website: string;
  chat_websocket_url: string;
  setBrands: (data: any) => void;
}

export const createBrandsSlice: StateCreator<BrandsSlice, [], [], BrandsSlice> = (set) => ({
  app_name: '',
  chatbot_name: '',
  app_website: '',
  terms_of_service: '',
  privacy_policy: '',
  data_handling_website: '',
  default_subscription: '',
  support_website: '',
  chat_websocket_url: '',
  setBrands: (data: any) => set(() => ({ ...data }))
});
