import { ApplicationState, useAppStore } from '../store';

import { AnalyticsLogsData } from '../store/slices/analytics';
import { addRawAnalyticsLog } from 'src/core/helpers/AddRawAnalyticsLog';
import { setValue } from 'src/api/storage';
import { useCallback } from 'react';

interface UseAnalytics {
  addAnalyticsLog: (log: AnalyticsLogsData) => void;
}

export function useAnalytics(filename: string): UseAnalytics {
  const addAnalyticsData = useAppStore((state: ApplicationState) => state.addAnalyticsLog);

  const addAnalyticsLog = useCallback(
    (log: AnalyticsLogsData) => {
      addAnalyticsData({ ...log, filename: filename }, (analytics, analyticsLogs) => {
        if (!analytics) {
          // !analytics means amplitude is initialized so the logs can go straight to amplitude
          if (log.logType === 'error') {
            addRawAnalyticsLog('Error', { file: filename, function: log.function, data: JSON.stringify(log.data) });
          } else {
            addRawAnalyticsLog(log.logType, log.data);
          }
        } else {
          // Save logs to local storage in case of app crash or closing the app pre login
          setValue('logs', JSON.stringify(analyticsLogs));
        }
      });
    },
    [addAnalyticsData, filename]
  );

  return { addAnalyticsLog };
}
