import React, { useState } from 'react';

import { AcceptedTenant } from './AcceptedTenant';
import { AxiosError } from 'axios';
import { DeclineTenantInvitation } from './DeclineTenantInvitation';
import { ErrorModal } from 'src/components/shared/ErrorModal';
import { ITenantSharedProps } from '../TenantSharedProps';
import Spinner from 'react-native-loading-spinner-overlay';
import { TenantModal } from './TenantModal';
import UserService from 'src/api/user';
import { useAnalytics } from 'src/core/hooks/useAnalytics';
import { useAppStore } from 'src/core/store';
import { useDataSharing } from 'src/core/hooks/useDataSharing';
import { useSetDataSharingList } from 'src/core/hooks/useUser';

export const InvitationTenant = (props: ITenantSharedProps) => {
  const setDataSharing = useAppStore((state) => state.setDataSharing);
  const dataSharingModal = useAppStore((state) => state.dataSharingModal);

  const setDataSharingList = useSetDataSharingList();

  const { addAnalyticsLog } = useAnalytics('InvitationTenant.tsx');
  const { loadTenantFeatures } = useDataSharing();

  const sharingData = dataSharingModal;

  const [accepted, setAccepted] = useState<boolean>(false);
  const [declined, setDeclined] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<AxiosError>({} as AxiosError);
  const [visibleErrorModal, setVisibleErrorModal] = useState<boolean>(false);

  const title = 'Accept connection request?';

  const message = `You have a new connection request from ${props.proUserName} at ${props.tenantName}.
    Accepting this request will let ${props.proUserName} view your data and help you in your wellness journey. Please note that you can stop sharing your data anytime.`;

  const handleButtonClick = async (isAllowed: boolean) => {
    setLoading(true);
    UserService.acceptDataSharing(sharingData.proUserId, sharingData.tenantKey, isAllowed)
      .then(() => {
        setDataSharing(false);
        setAccepted(isAllowed);
        if (!isAllowed) {
          setDeclined(false);
          props.onFinalButtonClick();
        } else {
          return loadTenantFeatures();
        }
      })
      .catch((err) => {
        addAnalyticsLog({ function: 'handleButtonClick', data: err, logType: 'error' });
        props.onFinalButtonClick();
        setError(err);
        setVisibleErrorModal(true);
      })
      .finally(() => setLoading(false));
  };

  const renderInvitation = () => {
    return (
      <TenantModal
        visible={props.visible}
        title={title}
        message={message}
        icon={'person-add-outline'}
        primaryButtonText={'Accept'}
        onPrimaryButton={() => handleButtonClick(true)}
        visibleSecondaryButton={true}
        secondaryButtonText={'Decline'}
        onSecondaryButton={() => setDeclined(true)}
        testingAttributes={props.testingAttributes}
      />
    );
  };

  const renderAccept = () => {
    return (
      <AcceptedTenant
        visible={true}
        onFinalButtonClick={() => {
          UserService.getDataSharingList()
            .then((res) => setDataSharingList(res))
            .catch((err) => {
              addAnalyticsLog({ function: 'renderAccept', data: err, logType: 'error' });
              setError(err);
              setVisibleErrorModal(true);
            });
          setAccepted(false);
          props.onFinalButtonClick();
        }}
        proUserName={props.proUserName}
      />
    );
  };

  const renderDecline = () => {
    return (
      <DeclineTenantInvitation
        visible={true}
        onFinalButtonClick={() => handleButtonClick(false)}
        onSecondaryButton={() => {
          setDeclined(false);
        }}
        proUserName={props.proUserName}
      />
    );
  };

  return (
    <>
      {loading && <Spinner visible={true} />}
      {!accepted && !declined && renderInvitation()}
      {accepted && !declined && renderAccept()}
      {!accepted && declined && renderDecline()}
      <ErrorModal
        visible={visibleErrorModal}
        closeBtnClick={() => {
          setVisibleErrorModal(false);
        }}
        buttonTitle={'OK'}
        message={error}
      />
    </>
  );
};
