import { Platform, StyleSheet, View } from 'react-native';
import React, { useEffect, useRef, useState } from 'react';
import { ResizeMode, Video } from 'expo-av';
import { Spinner, Text } from '@ui-kitten/components';

import { ChatMessageProps } from 'src/core/types/ChatMessage';
import { calculateScale } from './utils/calculateScale';
import { UIHelper as uh } from 'src/core';
import { useAnalytics } from 'src/core/hooks/useAnalytics';
import { useChat } from 'src/core/hooks/useChat';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';

const ChatVideoMessage = ({ message }: ChatMessageProps) => {
  const [isVideoLoaded, setIsVideoLoaded] = useState<boolean>(false);
  const [videoSize, setVideoSize] = useState({ height: 0, width: 0 });
  const [naturalVideoSize, setNaturalVideoSize] = useState({ height: 0, width: 0 });
  const [video, setVideo] = useState<string>('');
  const [hasError, setHasError] = useState<boolean>(false);

  const videoRef = useRef<Video>(null);

  const { addAnalyticsLog } = useAnalytics('ChatVideoMessage.tsx');
  const { getMedia } = useChat();

  const transMessages = {
    errorMessage: "Couldn't load video"
  };

  const t = useTranslationFunc(transMessages);

  const styleContainer = StyleSheet.create({
    videoContainer: {
      display: !isVideoLoaded ? 'none' : 'flex',
      height: videoSize.height,
      width: videoSize.width,
      borderRadius: 8
    },
    videoPlaceholder: {
      height: uh.h2DP(96),
      width: uh.h2DP(96),
      justifyContent: 'center',
      alignItems: 'center'
    }
  });

  useEffect(() => {
    if (video && Platform.OS !== 'web') {
      const { width, height } = naturalVideoSize;
      if (height > 0 && width > 0) {
        const scale = calculateScale(width, height);
        setVideoSize({ width: width * scale, height: height * scale });
      }
    }
  }, [video, naturalVideoSize]);

  useEffect(() => {
    if (message.isSender) {
      setVideo(message.video ?? '');
    } else {
      if (message.video) {
        getMedia(message.video)
          .then((videoSrc) => setVideo(videoSrc))
          .catch((err) => {
            addAnalyticsLog({ function: 'getMedia()', data: err, logType: 'error' });
            setHasError(true);
          });
      }
    }
  }, [addAnalyticsLog, getMedia, message.image, message.isSender, message.video]);

  if (hasError) {
    return (
      <View
        style={{
          height: uh.h2DP(128),
          width: uh.h2DP(128),
          borderWidth: 1,
          borderRadius: 8,
          alignContent: 'center',
          justifyContent: 'center',
          borderColor: 'red'
        }}>
        <Text style={{ textAlign: 'center' }} status="danger">
          {t('errorMessage')}
        </Text>
      </View>
    );
  }

  return (
    <>
      <Video
        source={{ uri: video, overrideFileExtensionAndroid: 'm3u8' }}
        style={styleContainer.videoContainer}
        useNativeControls
        resizeMode={ResizeMode.CONTAIN}
        ref={videoRef}
        onReadyForDisplay={(event) => {
          setNaturalVideoSize(event.naturalSize);
          setIsVideoLoaded(true);
        }}
      />

      {!isVideoLoaded && (
        <View style={styleContainer.videoPlaceholder}>
          <Spinner size="giant" />
        </View>
      )}
    </>
  );
};

export default ChatVideoMessage;
