import { Button, ButtonGroup, Text, useTheme } from '@ui-kitten/components';
import { StyleSheet, View, ViewProps } from 'react-native';

import { NotiSegmentItem } from 'src/core/types/Notification';
import React from 'react';

//props
interface SegmentProps extends ViewProps {
  segments: NotiSegmentItem[];
  onSelect: (index: number) => void;
  selectedValue: number;
  disabled?: boolean;
}

//styles
const styleContainer = StyleSheet.create({
  buttonGroup: {
    margin: 2,
    borderWidth: 0,
    columnGap: -1
  },
  buttonTipGroup: {
    marginTop: 4,
    flexDirection: 'row',
    flex: 3,
    borderWidth: 0
  }
});

const Segment = ({ segments, onSelect, selectedValue, disabled }: SegmentProps) => {
  const th = useTheme();
  const infos: string[] = segments.filter((segment) => segment?.info)?.map((segment) => String(segment?.info));

  //view
  return (
    <View>
      <ButtonGroup style={styleContainer.buttonGroup} appearance="ghost">
        {segments.map((segment, index) => (
          <Button
            disabled={segment.disabled}
            onPress={() => {
              onSelect(segment.value);
            }}
            key={index}
            style={{
              flex: 1,
              backgroundColor:
                selectedValue === segment.value
                  ? disabled
                    ? th['color-primary-transparent-400']
                    : th['color-primary-500']
                  : th['color-basic-300']
            }}>
            {(evaProps) => (
              <Text
                {...evaProps}
                category={selectedValue === segment.value ? 's2' : 'p2'}
                style={{
                  color: selectedValue === segment.value ? th['color-basic-100'] : th['color-basic-600'],
                  fontSize: 14
                }}>
                {segment.name}
              </Text>
            )}
          </Button>
        ))}
      </ButtonGroup>
      <View style={styleContainer.buttonTipGroup}>
        {infos.map((info) => (
          <View style={{ flex: 1 }} key={info}>
            <Text category="c1" style={{ textAlign: 'center' }}>
              {info}
            </Text>
          </View>
        ))}
      </View>
    </View>
  );
};

export default Segment;
