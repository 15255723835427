import {
  BottomSheetBackdrop,
  BottomSheetModal,
  BottomSheetModalProvider,
  BottomSheetView,
  useBottomSheetDynamicSnapPoints
} from '@gorhom/bottom-sheet';
import { Layout, Text, useTheme } from '@ui-kitten/components';
import React, { PropsWithChildren, RefObject, useMemo } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import { BottomSheetModalMethods } from '@gorhom/bottom-sheet/lib/typescript/types';
import DownwardIcon from './DownwardIcon';

const styles = StyleSheet.create({
  container: {
    borderTopWidth: 10
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 10,
    paddingBottom: 10
  },
  body: {
    padding: 10,
    display: 'flex',
    gap: 15
  },
  saveBtn: {
    marginTop: 5
  },
  contentContainerStyle: {
    paddingBottom: 20
  }
});

export interface ActionBottomSheetProps {
  sheetRef: RefObject<BottomSheetModalMethods>;
  title: string;
}

const ActionBottomSheet = ({ sheetRef, children, title }: PropsWithChildren<ActionBottomSheetProps>) => {
  const snapPoints = useMemo(() => ['CONTENT_HEIGHT', 'CONTENT_HEIGHT'], []);

  const { animatedHandleHeight, animatedSnapPoints, animatedContentHeight, handleContentLayout } =
    useBottomSheetDynamicSnapPoints(snapPoints);

  const th = useTheme();

  const handleClose = () => {
    sheetRef.current?.dismiss();
  };

  return (
    <BottomSheetModalProvider>
      <BottomSheetModal
        ref={sheetRef}
        snapPoints={animatedSnapPoints}
        handleHeight={animatedHandleHeight}
        contentHeight={animatedContentHeight}
        index={0}
        enablePanDownToClose={false}
        backdropComponent={(backdropProps) => (
          <BottomSheetBackdrop {...backdropProps} enableTouchThrough={false} appearsOnIndex={1} />
        )}
        handleIndicatorStyle={{ height: 0 }}
        backgroundStyle={{ backgroundColor: th['background-basic-color-1'] }}>
        <BottomSheetView onLayout={handleContentLayout}>
          <Layout level="1" style={styles.contentContainerStyle}>
            <View style={styles.header}>
              <View style={{ width: 24 }} />
              <Text category="h6">{title}</Text>
              <TouchableOpacity onPress={handleClose}>
                <DownwardIcon size={24} color={th['text-basic-color']} />
              </TouchableOpacity>
            </View>
            <View style={{ height: 1, marginVertical: 5, backgroundColor: th['text-basic-color'] }} />
            <View style={styles.body}>{children}</View>
          </Layout>
        </BottomSheetView>
      </BottomSheetModal>
    </BottomSheetModalProvider>
  );
};

export default ActionBottomSheet;
