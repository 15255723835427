import { useCallback, useEffect, useState } from 'react';

import { ApiError } from '../types/ApiResponse';
import { ScoreCategory } from '../types/Scores';
import ScoreService from 'src/api/scores';
import { useAnalytics } from './useAnalytics';
import { useAppStore } from '../store';
import { useIsUserLogged } from './useUser';

type UseScoreCategoriesEffect = [ScoreCategory[], boolean, any];

interface UseScoreCategoriesEffectProps {
  refreshing: boolean;
  setRefreshing: (refreshing: boolean) => void;
}

export const useScoreCategoriesEffect = ({
  refreshing,
  setRefreshing
}: UseScoreCategoriesEffectProps): UseScoreCategoriesEffect => {
  const [isScoreCategoryLoading, setIsScoreCategoryLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>();

  const { addAnalyticsLog } = useAnalytics('usePillarScore.ts');
  const scoreCategories = useAppStore((state) => state.scoreCategories);
  const setScoreGategories = useAppStore((state) => state.setScoreGategories);
  const isUserLogged = useIsUserLogged();

  const loadScoreCategories = useCallback(
    (promise: Promise<ScoreCategory[]>) => {
      promise
        .then((scoreCategoriesDto: ScoreCategory[]) => {
          const result = scoreCategoriesDto.map((scoreCategory: ScoreCategory) => ({
            ...scoreCategory,
            externalKey: scoreCategory.externalKey.toLowerCase()
          }));
          setScoreGategories(result);
        })
        .catch((err: ApiError) => {
          if (!err.isAborted) {
            setError(err.error);
          }
          addAnalyticsLog({ function: 'loadScoreCategories', data: err, logType: 'error' });
        })
        .finally(() => {
          setIsScoreCategoryLoading(false);
          setRefreshing(false);
        });
    },
    [addAnalyticsLog, setRefreshing, setScoreGategories]
  );

  useEffect(() => {
    if (isUserLogged) {
      const { promise, abort } = ScoreService.getScoreSummary();
      loadScoreCategories(promise);
      return abort;
    }
  }, [isUserLogged, loadScoreCategories]);

  useEffect(() => {
    if (refreshing) {
      const { promise, abort } = ScoreService.getScoreSummary();
      loadScoreCategories(promise);
      return abort;
    }
  }, [loadScoreCategories, refreshing]);
  return [scoreCategories, isScoreCategoryLoading, error];
};

export const useGetScoreCategories = (): ScoreCategory[] => {
  const scoreCategories = useAppStore((state) => state.scoreCategories);
  return scoreCategories;
};
