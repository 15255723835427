import Svg, { Circle, G, Path } from 'react-native-svg';

import React from 'react';

const NotificationIcon = ({ size = 24, color = '#2E3A59', useBadge = false }) => {
  return (
    <Svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 24 24"
      accessible={true}
      accessibilityLabel="notifications button"
      testID="notifications-button">
      <G data-name="Layer 2">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.51436 16.0001L6.69436 14.8181C7.07236 14.4401 7.28036 13.9381 7.28036 13.4041V8.72708C7.28036 7.37008 7.87036 6.07308 8.90036 5.17108C9.93836 4.26108 11.2604 3.86108 12.6374 4.04208C14.9644 4.35108 16.7194 6.45508 16.7194 8.93708V13.4041C16.7194 13.9381 16.9274 14.4401 17.3044 14.8171L18.4854 16.0001H5.51436ZM13.9994 18.3411C13.9994 19.2401 13.0834 20.0001 11.9994 20.0001C10.9154 20.0001 9.99936 19.2401 9.99936 18.3411V18.0001H13.9994V18.3411ZM20.5204 15.2081L18.7194 13.4041V8.93708C18.7194 5.45608 16.2174 2.49908 12.8994 2.06008C10.9774 1.80408 9.03736 2.39108 7.58236 3.66708C6.11836 4.94908 5.28036 6.79308 5.28036 8.72708L5.27936 13.4041L3.47836 15.2081C3.00936 15.6781 2.87036 16.3771 3.12436 16.9901C3.37936 17.6041 3.97236 18.0001 4.63636 18.0001H7.99936V18.3411C7.99936 20.3591 9.79336 22.0001 11.9994 22.0001C14.2054 22.0001 15.9994 20.3591 15.9994 18.3411V18.0001H19.3624C20.0264 18.0001 20.6184 17.6041 20.8724 16.9911C21.1274 16.3771 20.9894 15.6771 20.5204 15.2081Z"
          fill={color}
        />
        {useBadge && <Circle cx="19" cy="5" r="4.5" fill="#FF3D71" stroke="white" />}
      </G>
    </Svg>
  );
};

export default NotificationIcon;
