import { G, Path, Svg } from 'react-native-svg';

import React from 'react';
import { SvgIconProp } from 'src/core/types/ScanIcons';

export const FaceScanAvatar = ({ color, height = 200, width = 150 }: SvgIconProp) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 265 382" fill="none">
      <G opacity="0.5">
        <Path
          d="M249.61 96.87C241.69 2.65 146.66 1 146.66 1H118.24C118.24 1 23.2001 2.65 15.2801 96.87C13.9701 112.49 15.4001 128.06 18.7101 143.16C19.7801 148.04 20.5801 152.98 21.0101 157.96C23.6601 188.83 28.9301 256.2 37.4301 274.55C76.8901 359.67 124.39 357.61 124.39 357.61H140.49C140.49 357.61 188 359.68 227.45 274.55C235.96 256.2 241.23 188.82 243.87 157.96C244.3 152.97 245.1 148.04 246.17 143.16C249.47 128.06 250.91 112.5 249.6 96.87H249.61Z"
          stroke={color}
          strokeMiterlimit="10"
        />
        <Path
          d="M41.6801 283.44C41.6801 283.44 41.6801 330.75 39.6201 343.14C37.5501 355.54 17.3101 380.74 17.3101 380.74"
          stroke={color}
          strokeMiterlimit="10"
        />
        <Path
          d="M223.21 283.44C223.21 283.44 223.21 330.75 225.27 343.14C227.34 355.54 247.58 380.74 247.58 380.74"
          stroke={color}
          strokeMiterlimit="10"
        />
        <Path
          d="M21.37 162.19C19.23 159.43 16.17 156.76 12.03 156.08C4.38002 154.82 -2.85998 166.29 3.34002 193.98C9.38002 220.95 9.15002 238.5 29.38 239.01C25.7 213.73 23.07 182.24 21.37 162.2V162.19Z"
          stroke={color}
          strokeMiterlimit="10"
        />
        <Path
          d="M252.86 156.08C248.72 156.76 245.66 159.44 243.52 162.19C241.82 182.23 239.19 213.72 235.51 239C255.74 238.49 255.51 220.94 261.55 193.97C267.75 166.28 260.51 154.81 252.86 156.07V156.08Z"
          stroke={color}
          strokeMiterlimit="10"
        />
        <Path d="M132.45 293.43V380.75" stroke={color} strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
        <Path d="M132.45 158.44V242.94" stroke={color} strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
        <Path d="M132.45 1.4V45.06" stroke={color} strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
        <Path
          d="M87.8201 278.47H177.07"
          stroke={color}
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M108.48 171.25L91.96 177.55H75.2201L59.3 169.39L74.8001 160.29H92.1501L92.6601 160.62L108.48 171.24V171.25Z"
          stroke={color}
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M108.48 171.25L118.19 192.11L29.3801 239L75.2201 177.54"
          stroke={color}
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M63.2301 132.61L74.8001 160.29"
          stroke={color}
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M59.3101 169.39L21.3701 162.19L63.2301 132.61L106.62 137.15L119.22 153.27L118.19 192.11L101.87 228.06V237.15L120.05 246.35V267.73"
          stroke={color}
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M120.88 235.29H109.72L115.3 231.37L132.45 242.94"
          stroke={color}
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M132.45 270.83L120.05 267.73L87.2001 278.47L119.22 292.31C119.22 292.31 133.27 293.76 132.44 293.76"
          stroke={color}
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M101.87 228.06C101.87 228.06 81.21 250.99 80.59 250.99C79.97 250.99 61.39 315.55 61.39 315.55L119.23 292.32"
          stroke={color}
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M120.05 246.35L132.45 249.45"
          stroke={color}
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M132.48 158.44L119.22 153.27L132.44 124.86L109.71 78.68L59.31 19.47M59.31 19.47L106.62 137.16L109.72 78.69C109.72 78.69 132.45 45.53 132.48 45.53C132.51 45.53 59.31 19.47 59.31 19.47ZM59.31 19.47L42.78 80.63"
          stroke={color}
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M63.23 132.61L83.89 80.62H17.66L63.23 132.61Z"
          stroke={color}
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path d="M132.45 1L95.89 32.49" stroke={color} strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
        <Path
          d="M93.6 164.77C93.6 170.03 89.34 174.29 84.07 174.29C78.8 174.29 74.54 170.03 74.54 164.77C74.54 163.16 74.94 161.63 75.66 160.29H92.16L92.67 160.62C93.28 161.88 93.61 163.29 93.61 164.76L93.6 164.77Z"
          stroke={color}
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M84.0701 380.75L39.1901 345.02C37.8201 345.52 61.3901 315.55 61.3901 315.55L84.0801 380.74L132.49 357.6L93.6101 302.6"
          stroke={color}
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M39.1901 345.02L45.8801 380.75"
          stroke={color}
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M61.38 315.55L41.41 309.27"
          stroke={color}
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M2.07007 165.66L8.48007 164.63L23.8501 190.47L14.8001 202.65L26.5401 216.74"
          stroke={color}
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M8.48005 164.63L5.05005 202.09"
          stroke={color}
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M14.8 202.65L6.77002 210.66C6.77002 210.66 30.42 239.05 29.38 239L80.59 251L87.2 278.48L101.87 237.16"
          stroke={color}
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M156.48 171.25L173 177.55H189.74L205.66 169.39L190.16 160.29H172.8L172.29 160.62L156.47 171.24L156.48 171.25Z"
          stroke={color}
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M156.48 171.25L146.77 192.11L235.59 239L189.75 177.54"
          stroke={color}
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M201.73 132.61L190.16 160.29"
          stroke={color}
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M205.66 169.39L243.59 162.19L201.73 132.61L158.34 137.15L145.74 153.27L146.77 192.11L163.09 228.06V237.15L144.91 246.35V267.73"
          stroke={color}
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M144.09 235.29H155.24L149.67 231.37L132.52 242.94"
          stroke={color}
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M132.52 270.83L144.92 267.73L177.77 278.47L145.75 292.31C145.75 292.31 131.7 293.76 132.53 293.76"
          stroke={color}
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M163.09 228.06C163.09 228.06 183.75 250.99 184.37 250.99C184.99 250.99 203.57 315.55 203.57 315.55L145.73 292.32"
          stroke={color}
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M144.91 246.35L132.52 249.45"
          stroke={color}
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M132.48 158.44L145.74 153.27L132.52 124.86L155.25 78.68M155.25 78.68L205.66 19.46M155.25 78.68L158.35 137.15L205.66 19.46M155.25 78.68C155.25 78.68 132.52 45.52 132.49 45.52C132.46 45.52 205.66 19.46 205.66 19.46M205.66 19.46L222.19 80.62"
          stroke={color}
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M201.73 132.61L181.07 80.62H247.3L201.73 132.61Z"
          stroke={color}
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M132.52 1L169.07 32.49"
          stroke={color}
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M171.36 164.77C171.36 170.03 175.62 174.29 180.89 174.29C186.16 174.29 190.42 170.03 190.42 164.77C190.42 163.16 190.02 161.63 189.3 160.29H172.8L172.29 160.62C171.68 161.88 171.35 163.29 171.35 164.76L171.36 164.77Z"
          stroke={color}
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M180.89 380.75L225.77 345.02C227.14 345.52 203.57 315.55 203.57 315.55L180.88 380.74L132.47 357.6L171.35 302.6"
          stroke={color}
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M225.78 345.02L219.09 380.75"
          stroke={color}
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M203.58 315.55L223.55 309.27"
          stroke={color}
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M262.9 165.66L256.48 164.63L241.11 190.47L250.17 202.65L238.43 216.74"
          stroke={color}
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M256.48 164.63L259.91 202.09"
          stroke={color}
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M250.17 202.65L258.2 210.66C258.2 210.66 234.55 239.05 235.59 239L184.38 251L177.77 278.48L163.1 237.16"
          stroke={color}
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path d="M118.16 192.11H146.74" stroke={color} strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
      </G>
      <Path
        d="M225.5 347.5C227.157 347.5 228.5 346.157 228.5 344.5C228.5 342.843 227.157 341.5 225.5 341.5C223.843 341.5 222.5 342.843 222.5 344.5C222.5 346.157 223.843 347.5 225.5 347.5Z"
        fill="#46D7CB"
      />
      <Path
        d="M132.5 360.5C134.157 360.5 135.5 359.157 135.5 357.5C135.5 355.843 134.157 354.5 132.5 354.5C130.843 354.5 129.5 355.843 129.5 357.5C129.5 359.157 130.843 360.5 132.5 360.5Z"
        fill="#46D7CB"
      />
      <Path
        d="M93.5 305.5C95.1569 305.5 96.5 304.157 96.5 302.5C96.5 300.843 95.1569 299.5 93.5 299.5C91.8431 299.5 90.5 300.843 90.5 302.5C90.5 304.157 91.8431 305.5 93.5 305.5Z"
        fill="#46D7CB"
      />
      <Path
        d="M101.5 231.5C103.157 231.5 104.5 230.157 104.5 228.5C104.5 226.843 103.157 225.5 101.5 225.5C99.8431 225.5 98.5 226.843 98.5 228.5C98.5 230.157 99.8431 231.5 101.5 231.5Z"
        fill="#46D7CB"
      />
      <Path
        d="M117.5 195.5C119.157 195.5 120.5 194.157 120.5 192.5C120.5 190.843 119.157 189.5 117.5 189.5C115.843 189.5 114.5 190.843 114.5 192.5C114.5 194.157 115.843 195.5 117.5 195.5Z"
        fill="#46D7CB"
      />
      <Path
        d="M146.5 195.5C148.157 195.5 149.5 194.157 149.5 192.5C149.5 190.843 148.157 189.5 146.5 189.5C144.843 189.5 143.5 190.843 143.5 192.5C143.5 194.157 144.843 195.5 146.5 195.5Z"
        fill="#46D7CB"
      />
      <Path
        d="M163.5 231.5C165.157 231.5 166.5 230.157 166.5 228.5C166.5 226.843 165.157 225.5 163.5 225.5C161.843 225.5 160.5 226.843 160.5 228.5C160.5 230.157 161.843 231.5 163.5 231.5Z"
        fill="#46D7CB"
      />
      <Path
        d="M235.5 241.5C237.157 241.5 238.5 240.157 238.5 238.5C238.5 236.843 237.157 235.5 235.5 235.5C233.843 235.5 232.5 236.843 232.5 238.5C232.5 240.157 233.843 241.5 235.5 241.5Z"
        fill="#46D7CB"
      />
      <Path
        d="M29.5 241.5C31.1569 241.5 32.5 240.157 32.5 238.5C32.5 236.843 31.1569 235.5 29.5 235.5C27.8431 235.5 26.5 236.843 26.5 238.5C26.5 240.157 27.8431 241.5 29.5 241.5Z"
        fill="#46D7CB"
      />
      <Path
        d="M14.5 204.5C16.1569 204.5 17.5 203.157 17.5 201.5C17.5 199.843 16.1569 198.5 14.5 198.5C12.8431 198.5 11.5 199.843 11.5 201.5C11.5 203.157 12.8431 204.5 14.5 204.5Z"
        fill="#46D7CB"
      />
      <Path
        d="M250.5 204.5C252.157 204.5 253.5 203.157 253.5 201.5C253.5 199.843 252.157 198.5 250.5 198.5C248.843 198.5 247.5 199.843 247.5 201.5C247.5 203.157 248.843 204.5 250.5 204.5Z"
        fill="#46D7CB"
      />
      <Path
        d="M21.5 164.5C23.1569 164.5 24.5 163.157 24.5 161.5C24.5 159.843 23.1569 158.5 21.5 158.5C19.8431 158.5 18.5 159.843 18.5 161.5C18.5 163.157 19.8431 164.5 21.5 164.5Z"
        fill="#46D7CB"
      />
      <Path
        d="M62.5 135.5C64.1569 135.5 65.5 134.157 65.5 132.5C65.5 130.843 64.1569 129.5 62.5 129.5C60.8431 129.5 59.5 130.843 59.5 132.5C59.5 134.157 60.8431 135.5 62.5 135.5Z"
        fill="#46D7CB"
      />
      <Path
        d="M201.5 135.5C203.157 135.5 204.5 134.157 204.5 132.5C204.5 130.843 203.157 129.5 201.5 129.5C199.843 129.5 198.5 130.843 198.5 132.5C198.5 134.157 199.843 135.5 201.5 135.5Z"
        fill="#46D7CB"
      />
      <Path
        d="M17.5 83.5C19.1569 83.5 20.5 82.1569 20.5 80.5C20.5 78.8431 19.1569 77.5 17.5 77.5C15.8431 77.5 14.5 78.8431 14.5 80.5C14.5 82.1569 15.8431 83.5 17.5 83.5Z"
        fill="#46D7CB"
      />
      <Path
        d="M247.5 83.5C249.157 83.5 250.5 82.1569 250.5 80.5C250.5 78.8431 249.157 77.5 247.5 77.5C245.843 77.5 244.5 78.8431 244.5 80.5C244.5 82.1569 245.843 83.5 247.5 83.5Z"
        fill="#46D7CB"
      />
      <Path
        d="M243.5 164.5C245.157 164.5 246.5 163.157 246.5 161.5C246.5 159.843 245.157 158.5 243.5 158.5C241.843 158.5 240.5 159.843 240.5 161.5C240.5 163.157 241.843 164.5 243.5 164.5Z"
        fill="#46D7CB"
      />
      <Path
        d="M171.5 305.5C173.157 305.5 174.5 304.157 174.5 302.5C174.5 300.843 173.157 299.5 171.5 299.5C169.843 299.5 168.5 300.843 168.5 302.5C168.5 304.157 169.843 305.5 171.5 305.5Z"
        fill="#46D7CB"
      />
      <Path
        d="M39.5 347.5C41.1569 347.5 42.5 346.157 42.5 344.5C42.5 342.843 41.1569 341.5 39.5 341.5C37.8431 341.5 36.5 342.843 36.5 344.5C36.5 346.157 37.8431 347.5 39.5 347.5Z"
        fill="#46D7CB"
      />
      <Path
        d="M132.5 48.5C134.157 48.5 135.5 47.1569 135.5 45.5C135.5 43.8431 134.157 42.5 132.5 42.5C130.843 42.5 129.5 43.8431 129.5 45.5C129.5 47.1569 130.843 48.5 132.5 48.5Z"
        fill="#46D7CB"
      />
      <Path
        d="M95.5 35.5C97.1569 35.5 98.5 34.1569 98.5 32.5C98.5 30.8431 97.1569 29.5 95.5 29.5C93.8431 29.5 92.5 30.8431 92.5 32.5C92.5 34.1569 93.8431 35.5 95.5 35.5Z"
        fill="#46D7CB"
      />
      <Path
        d="M83.5 83.5C85.1569 83.5 86.5 82.1569 86.5 80.5C86.5 78.8431 85.1569 77.5 83.5 77.5C81.8431 77.5 80.5 78.8431 80.5 80.5C80.5 82.1569 81.8431 83.5 83.5 83.5Z"
        fill="#46D7CB"
      />
      <Path
        d="M109.5 81.51C111.157 81.51 112.5 80.1669 112.5 78.51C112.5 76.8532 111.157 75.51 109.5 75.51C107.843 75.51 106.5 76.8532 106.5 78.51C106.5 80.1669 107.843 81.51 109.5 81.51Z"
        fill="#46D7CB"
      />
      <Path
        d="M155.5 81.51C157.157 81.51 158.5 80.1669 158.5 78.51C158.5 76.8532 157.157 75.51 155.5 75.51C153.843 75.51 152.5 76.8532 152.5 78.51C152.5 80.1669 153.843 81.51 155.5 81.51Z"
        fill="#46D7CB"
      />
      <Path
        d="M181.5 83.5C183.157 83.5 184.5 82.1569 184.5 80.5C184.5 78.8431 183.157 77.5 181.5 77.5C179.843 77.5 178.5 78.8431 178.5 80.5C178.5 82.1569 179.843 83.5 181.5 83.5Z"
        fill="#46D7CB"
      />
      <Path
        d="M168.5 35.5C170.157 35.5 171.5 34.1569 171.5 32.5C171.5 30.8431 170.157 29.5 168.5 29.5C166.843 29.5 165.5 30.8431 165.5 32.5C165.5 34.1569 166.843 35.5 168.5 35.5Z"
        fill="#46D7CB"
      />
    </Svg>
  );
};
