import { Button, Icon, Layout, Text, useTheme } from '@ui-kitten/components';
import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { RootStackScreenProps } from 'src/core';
import Spinner from 'react-native-loading-spinner-overlay/lib';
import { mainStyles } from 'src/screens/main/_mainStyles';

const NotConnected = ({ navigation }: RootStackScreenProps<'NotConnected'>) => {
  const styleContainer = StyleSheet.create({
    notEnabled: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center'
    },
    container: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      margin: 16
      // maxWidth: uh.h2DP(256)
    },
    button: {
      marginTop: 24,
      minWidth: 150
    },
    text: {
      textAlign: 'center',
      paddingVertical: 8
    }
  });

  const [loading, setLoading] = useState<boolean>(false);
  const th = useTheme();

  const message = 'No internet connection found. Please check Your connection.';

  const handleClick = () => {
    setLoading(true);
    setTimeout(() => {
      navigation.navigate('NotConnected');
      setLoading(false);
    }, 250);
  };

  return (
    <Layout level="2" style={[styleContainer.notEnabled, mainStyles.mainScreenContainer]}>
      {loading && <Spinner visible={true} />}
      <View style={styleContainer.container}>
        <Icon
          style={{ width: 128, height: 128, paddingBottom: 24 }}
          fill={th['color-primary-500']}
          name={'wifi-off-outline'}
        />
        <View>
          <Text category="h5" style={styleContainer.text}>
            Not connected!
          </Text>
          <Text category="p1" style={styleContainer.text}>
            {message}
          </Text>
        </View>
        <Button style={styleContainer.button} size="medium" onPress={() => handleClick()}>
          <Text>Try again</Text>
        </Button>
      </View>
    </Layout>
  );
};

export default NotConnected;
