import { Level } from './Level';
import { Units } from './Units';

export type UserAttributes = {
  cognitoId?: string;
  userId?: string;
  sub?: string;
  name?: string;
  nickname?: string;
  email?: string;
  gender?: string;
  birthdate?: string;
  picture?: string;
  height?: number;
  weight?: number;
  smoker?: number | boolean;
  bloodpressureMedication?: number | boolean;
  hypertension?: number | boolean;
  diabetic?: number;
  activityLevel?: number;
  createdOn?: Date;
  jobTitle?: string;
  roles?: string[];
};

export type UserSettings = {
  enabledActionNotifications?: boolean;
  enabledBiomarkerOfTheDayNotifications?: boolean;
  enabledSleepStatNotifications?: boolean;
  units?: Units;
  level?: Level;
};

export type LatestDataCount = {
  unreadMessages: number;
  newActions: number;
  newAssessments: number;
};

export enum LatestDataCountType {
  ACTION = 'action',
  MARKER = 'marker',
  ASSESSMENT = 'assessment',
  KALI = 'kali'
}
