import { Icon, useTheme } from '@ui-kitten/components';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import NotificationIcon from './icons/NotificationIcon';
import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { useGetNottificationMessages } from 'src/core/hooks/useNotificationMessage';

// styles
const styleContainer = StyleSheet.create({
  icon: { height: 22, width: 22 },
  iconContainer: { flexDirection: 'row', alignItems: 'center', columnGap: 14, marginRight: 18 },
  backNavContainer: { flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginLeft: 16 }
});

const HeaderRight = () => {
  const th = useTheme();
  const navigation = useNavigation();

  const { routes } = navigation.getState();
  const lastRoute = routes[routes.length - 1];
  const isMenuScreen = lastRoute.name === 'Settings';
  const isChatScreen = (lastRoute as any).name === 'ChatScreen' || (lastRoute as any).name === 'Conversations';
  const isNotificationScreen = lastRoute.name === 'Notifications';
  const notifcationMessages = useGetNottificationMessages();
  const haveUnnreadMesseges = notifcationMessages.some((notiMessage) => !notiMessage.mark);

  return (
    <View style={styleContainer.iconContainer}>
      {!isNotificationScreen && (
        <TouchableOpacity
          onPress={() => {
            navigation.navigate('Notifications');
          }}>
          <NotificationIcon color={th['text-basic-color']} useBadge={haveUnnreadMesseges} />
        </TouchableOpacity>
      )}
      {!isChatScreen && (
        <TouchableOpacity
          onPress={() => {
            navigation.navigate('Chat', { screen: 'Conversations' });
          }}>
          <Icon
            name="message-circle-outline"
            fill={th['text-basic-color']}
            style={styleContainer.icon}
            accessible={true}
            accessibilityLabel="message"
            testID="icon_message"
          />
        </TouchableOpacity>
      )}
      {!isMenuScreen && (
        <TouchableOpacity
          onPress={() => {
            navigation.navigate('Settings');
          }}>
          <Icon
            name="menu-outline"
            fill={th['text-basic-color']}
            style={styleContainer.icon}
            accessible={true}
            accessibilityLabel="menu"
            testID="icon_menu"
          />
        </TouchableOpacity>
      )}
    </View>
  );
};

export default HeaderRight;
