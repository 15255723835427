import { Dimensions, Linking, Platform, StyleSheet, View } from 'react-native';
import React, { useState } from 'react';
import { Text, useTheme } from '@ui-kitten/components';
import {
  useDeleteNotificationMessage,
  useMarkAsReadNotification,
  useNotitficationMessageEffect
} from 'src/core/hooks/useNotificationMessage';

import { ErrorModal } from 'src/components/shared/ErrorModal';
import NotificationHiddenItem from './conponents/NotificationHiddenItem';
import NotificationItem from './conponents/NotificationItem';
import { NotificationMessage } from 'src/core/types/Notification';
import RefreshControl from 'src/components/shared/RefreshControl';
import Spinner from 'react-native-loading-spinner-overlay/lib';
import { SwipeListView } from 'react-native-swipe-list-view';
import { getWebPlatformUrl } from 'src/core/helpers/URLHelper';
import moment from 'moment';
import { UIHelper as uh } from '../../core';
import { useAppStore } from 'src/core/store';
import { useLinkTo } from '@react-navigation/native';

// styles
const styleContainer = StyleSheet.create({
  mainScreenContainer: {
    paddingLeft: uh.w2DP(16),
    paddingRight: uh.w2DP(16),
    paddingTop: uh.h2DP(8),
    paddingBottom: uh.h2DP(20)
  },
  rowBack: {
    marginTop: uh.h2DP(12),
    flexDirection: 'row',
    flex: 1,
    backgroundColor: '#FF3D71',
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'flex-end'
  }
});

const NOTIFICATIONS_PER_PAGE = 20;

const NotificationContainer = () => {
  const [notificationMessageRefreshing, setNotificationMessageRefreshing] = useState<boolean>(false);
  const [isDeletingNotification, setIsDeletingNotification] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);

  const th = useTheme();
  const linkTo = useLinkTo();
  const [isNotificationMessageLoading, NotificationMessageError] = useNotitficationMessageEffect({
    refreshing: notificationMessageRefreshing,
    setRefreshing: setNotificationMessageRefreshing
  });

  const [markAsRead, isMarkAsReadLoading, MarkAsReadError] = useMarkAsReadNotification();
  const [deleteNotification, isDeleteLoading, deleteError] = useDeleteNotificationMessage();

  const notitficationMessages = useAppStore((state) => state.notitficationMessages);
  const setNotitficationMessages = useAppStore((state) => state.setNotitficationMessages);

  const readNotification = async (notiMessage: NotificationMessage) => {
    if (!notiMessage.mark) {
      await markAsRead(notiMessage.id);
      setNotitficationMessages(
        notitficationMessages.map((item) => {
          if (item.id == notiMessage.id) {
            item.mark = true;
          }
          return item;
        })
      );
    }
    if (Platform.OS === 'web') {
      linkTo(getWebPlatformUrl(notiMessage.url));
    } else {
      Linking.openURL(notiMessage.url);
    }
  };

  const deleteNotificationMessssage = async (notificationId: string) => {
    await deleteNotification(notificationId);
    setNotitficationMessages(notitficationMessages.filter((item) => item.id !== notificationId));
    setIsDeletingNotification(false);
  };

  const onSwipeValueChange = (swipeData: any) => {
    const { key, value } = swipeData;
    if (value < -Dimensions.get('window').width && !isDeletingNotification && notificationMessageRefreshing === false) {
      setIsDeletingNotification(true);
      deleteNotificationMessssage(key);
    }
  };

  if (isNotificationMessageLoading) {
    return <Spinner visible={true} />;
  }

  return (
    <View style={[{ flexGrow: 1 }, styleContainer.mainScreenContainer]}>
      <Spinner visible={isMarkAsReadLoading || isDeleteLoading} />
      <SwipeListView
        alwaysBounceVertical={true}
        onEndReached={() => setPage((prev) => prev + 1)}
        refreshControl={
          <RefreshControl
            refreshing={notificationMessageRefreshing}
            onRefresh={() => {
              setNotificationMessageRefreshing(true);
            }}
          />
        }
        data={notitficationMessages
          .map((item) => {
            return { ...item, key: item.id };
          })
          .sort((n1, n2) =>
            n1.mark === n2.mark ? moment(n2.createdOn).diff(moment(n1.createdOn)) : !n1.mark && n2.mark ? -1 : 1
          )
          .slice(0, NOTIFICATIONS_PER_PAGE * page)}
        renderItem={(data) => (
          <NotificationItem
            notiMessage={data.item}
            onReadNotification={async () => {
              readNotification(data.item);
            }}
          />
        )}
        ListEmptyComponent={() => (
          <View style={{ flex: 1, alignItems: 'center' }}>
            <Text
              style={{
                textAlign: 'center',
                borderBottomColor: th['color-primary-500'],
                borderBottomWidth: 1,
                width: '75%',
                padding: uh.h2DP(8),
                color: th['color-primary-500']
              }}
              category="s1"
              appearance="ghost">
              No notifications
            </Text>
          </View>
        )}
        renderHiddenItem={() => <NotificationHiddenItem />}
        onSwipeValueChange={onSwipeValueChange}
        rightOpenValue={-Dimensions.get('window').width}
      />
      <ErrorModal
        visible={NotificationMessageError || MarkAsReadError || deleteError}
        message={{ ...NotificationMessageError, ...MarkAsReadError, ...deleteError }}
      />
    </View>
  );
};

export default NotificationContainer;
