import React from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';
import { Button, Card, Modal, Text } from '@ui-kitten/components';
import { UIHelper as uh } from '../../core';

interface IInfoModalProps extends ViewProps {
  visible: boolean;
  message: string | JSX.Element;
  buttonTitle?: string;
  closeBtnClick: () => void;
  noBtnClick?: () => void;
  secondaryButtonClick?: () => void;
  secondaryButtonTitle?: string;
  testingAttributes?: {
    accessible: boolean;
    accessibilityLabel: string;
    testID: string;
  };
}

export const InfoModal = (props: IInfoModalProps) => {
  const styles = StyleSheet.create({
    backdrop: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    button: { width: 80, alignSelf: 'center', marginTop: 20, marginLeft: 80, marginRight: 60 },
    buttonStyle: {
      width: 140,
      marginHorizontal: 10
    },
    message: {
      maxWidth: uh.currentViewPort() - 32,
      textAlign: 'center'
    },
    card: { maxWidth: uh.currentViewPort() - 32 },
    buttonArea: { justifyContent: 'center', alignItems: 'center', flexDirection: 'row', marginTop: 20 }
  });

  return (
    <Modal
      visible={props.visible}
      backdropStyle={styles.backdrop}
      onBackdropPress={() => props.noBtnClick && props.noBtnClick()}
      accessible={props.testingAttributes?.accessible || undefined}
      accessibilityLabel={props.testingAttributes?.accessibilityLabel || undefined}
      testID={props.testingAttributes?.testID || undefined}>
      <Card style={styles.card}>
        {typeof props.message === 'string' ? <Text style={styles.message}>{props.message}</Text> : props.message}
        <View style={styles.buttonArea}>
          {props.secondaryButtonClick && (
            <Button
              style={styles.buttonStyle}
              appearance="ghost"
              size="medium"
              onPress={() => props.secondaryButtonClick && props.secondaryButtonClick()}>
              {props.secondaryButtonTitle}
            </Button>
          )}
          <Button style={styles.buttonStyle} size="medium" onPress={() => props.closeBtnClick()}>
            {props.buttonTitle == undefined ? 'OK' : props.buttonTitle}
          </Button>
        </View>
      </Card>
    </Modal>
  );
};
