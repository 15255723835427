import * as FileSystem from 'expo-file-system';
import * as ImagePicker from 'expo-image-picker';

import { IMessage, User } from 'react-native-gifted-chat';
import { Icon, Input, useTheme } from '@ui-kitten/components';
import { KeyboardAvoidingView, Platform, StyleSheet, TouchableOpacity } from 'react-native';
import React, { Dispatch, SetStateAction, useState } from 'react';

import { Buffer } from 'buffer';
import ChatAudioInput from './ChatAudioInput';
import EmojiPicker from 'rn-emoji-keyboard';
import { FileInfo } from 'expo-file-system';
import { SmileIcon } from 'src/core/icons/smileIcon';
import { UIHelper as uh } from 'src/core';
import { useAnalytics } from 'src/core/hooks/useAnalytics';
import { v4 as uuidv4 } from 'uuid';

interface ChatInputProps {
  sendMessage: (message: IMessage[]) => void;
  user: User;
  setShowCamera: (showCamera: boolean) => void;
  setInputHeight: (inputHeight: number) => void;
  isAudioVisible: boolean;
  setIsAudioVisible: Dispatch<SetStateAction<boolean>>;
}

const REQUIRED_SIZE = 5;
const iconSize = uh.h2DP(24);

const ChatInput = ({ sendMessage, user, setShowCamera, isAudioVisible, setIsAudioVisible }: ChatInputProps) => {
  const [message, setMessage] = useState<string>('');
  const [isMediaVisible, setIsMediaVisible] = useState<boolean>(false);
  const [visibleEmojiPicker, setVisibleEmojiPicker] = useState<boolean>(false);

  const th = useTheme();
  const { addAnalyticsLog } = useAnalytics('ChatInput.tsx');

  const styleContainer = StyleSheet.create({
    inputContainer: {
      marginHorizontal: uh.h2DP(16),
      transform: [{ translateY: isAudioVisible ? -uh.h2DP(64) : 0 }]
    },
    input: {
      width: '100%',
      borderWidth: 0,
      backgroundColor: 'transparent'
    },
    icon: {
      paddingHorizontal: uh.h2DP(4)
    }
  });

  const handleSend = () => {
    const userMessage: IMessage = {
      _id: uuidv4(),
      text: message,
      createdAt: new Date(),
      user: user
    };
    sendMessage([userMessage]);
    setMessage('');
  };

  const handleMedia = async () => {
    try {
      const { status } = await ImagePicker.getMediaLibraryPermissionsAsync();

      if (!status) {
        return;
      }
    } catch (err) {
      addAnalyticsLog({ function: 'handleMedia', data: err, logType: 'error' });
      return;
    }

    const userMedia = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      quality: 1,
      base64: true,
      selectionLimit: 1
    });

    if (!userMedia.assets?.length) {
      return;
    }

    const media = userMedia.assets[0];

    if (!userMedia.canceled) {
      // Check if image size is <= 5MB

      if (Platform.OS !== 'web') {
        const fileInfo: FileInfo = await FileSystem.getInfoAsync(media.uri);

        if (
          ('size' in fileInfo &&
            fileInfo.size &&
            Number(Math.floor(fileInfo.size / Math.pow(1024, 2)).toFixed(2)) > REQUIRED_SIZE) ||
          !fileInfo
        ) {
          return;
        }
      } else {
        const buffer = Buffer.from(media.uri.substring(media.uri.indexOf(',') + 1));

        if (buffer.length / 1e6 > REQUIRED_SIZE) {
          return;
        }
      }

      if (media.type === 'image' || media.uri.match(/^data:image\/.*/)) {
        sendMessage([
          {
            _id: uuidv4(),
            text: '',
            image: media.uri,
            createdAt: new Date(),
            user: user
          }
        ]);
      } else {
        sendMessage([
          {
            _id: uuidv4(),
            text: '',
            video: media.uri,
            createdAt: new Date(),
            user: user
          }
        ]);
      }
    }
  };

  const handleSendAudio = (url: string) => {
    sendMessage([
      {
        _id: uuidv4(),
        text: '',
        audio: url,
        createdAt: new Date(),
        user: user
      }
    ]);
  };

  return (
    <KeyboardAvoidingView style={styleContainer.inputContainer}>
      {isAudioVisible && <ChatAudioInput setIsAudioVisible={setIsAudioVisible} onSend={handleSendAudio} />}
      <Input
        placeholder="Message ..."
        style={styleContainer.input}
        status="basic"
        size="large"
        value={message}
        multiline
        textStyle={{ paddingTop: 0, paddingBottom: 0, maxHeight: uh.h2DP(48) }}
        editable={false}
        onChangeText={(text: string) => setMessage(text)}
        accessoryLeft={() => (
          <TouchableOpacity onPress={() => setVisibleEmojiPicker(true)}>
            <SmileIcon height={iconSize} width={iconSize} color={th['color-basic-600']} />
          </TouchableOpacity>
        )}
        accessoryRight={() => (
          <>
            <TouchableOpacity onPress={() => setIsMediaVisible((active) => !active)} style={styleContainer.icon}>
              <Icon
                height={iconSize}
                width={iconSize}
                fill={th['color-basic-600']}
                name={isMediaVisible ? 'arrow-ios-forward-outline' : 'plus-circle-outline'}
              />
            </TouchableOpacity>
            {isMediaVisible && (
              <>
                <TouchableOpacity style={styleContainer.icon} onPress={() => setShowCamera(true)}>
                  <Icon height={iconSize} width={iconSize} fill={th['color-basic-600']} name="camera-outline" />
                </TouchableOpacity>
                <TouchableOpacity style={styleContainer.icon} onPress={handleMedia}>
                  <Icon height={iconSize} width={iconSize} fill={th['color-basic-600']} name="image-outline" />
                </TouchableOpacity>
                <TouchableOpacity
                  style={styleContainer.icon}
                  onPress={() => setIsAudioVisible((isVisible) => !isVisible)}>
                  <Icon
                    height={iconSize}
                    width={iconSize}
                    fill={isAudioVisible ? th['color-primary-500'] : th['color-basic-600']}
                    name="mic-outline"
                  />
                </TouchableOpacity>
              </>
            )}
            {message !== '' && (
              <TouchableOpacity style={styleContainer.icon} onPress={handleSend}>
                <Icon height={iconSize} width={iconSize} fill={th['color-primary-500']} name="paper-plane-outline" />
              </TouchableOpacity>
            )}
          </>
        )}
      />
      <EmojiPicker
        onEmojiSelected={(emoji) => setMessage((prev) => prev + emoji.emoji)}
        expandable={false}
        allowMultipleSelections
        open={visibleEmojiPicker}
        onClose={() => setVisibleEmojiPicker(false)}
        theme={{
          container: th['color-basic-100'],
          category: {
            container: th['color-basic-100'],
            icon: th['color-basic-500'],
            iconActive: th['color-primary-500']
          }
        }}
        styles={{
          category: {
            container: {
              borderWidth: 1,
              borderColor: th['border-basic-color-4']
            }
          }
        }}
      />
    </KeyboardAvoidingView>
  );
};

export default ChatInput;
