import { ApplicationState, useAppStore } from '../store';
import { Level } from '../types/Level';
import { Units } from '../types/Units';

export const useIsFirstRun = (): boolean => {
  return useAppStore((state: ApplicationState) => state.isFirstRun);
};

export const useSetFirstRun = (): ((firstRun: boolean) => void) => {
  return useAppStore((state: ApplicationState) => state.setFirstRun);
};

export const useIsAppInit = (): boolean => {
  return useAppStore((state: ApplicationState) => state.isAppPreInit);
};

export const useSetRefreshMarkers = (): (() => void) => {
  return useAppStore((state: ApplicationState) => state.setRefreshMarkers);
};

export const useRefreshMarkersCounter = (): number => {
  return useAppStore((state: ApplicationState) => state.needRefreshMarkers);
};

export const useUnits = (): Units => {
  return useAppStore((state: ApplicationState) => state.units);
};

export const useSetUnits = (): ((units: Units) => void) => {
  return useAppStore((state: ApplicationState) => state.setUnits);
};

export const useLevel = (): Level => {
  return useAppStore((state: ApplicationState) => state.level);
};

export const useSetLevel = (): ((level: Level) => void) => {
  return useAppStore((state: ApplicationState) => state.setLevel);
};
