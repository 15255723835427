import Svg, { G, Path } from 'react-native-svg';
import React from 'react';

const FavoriteIcon = ({ size = 24, color = '#8F9BB3' }) => (
  <Svg width={`${size}px`} height={`${size}px`} viewBox="0 0 24 24">
    <G data-name="Layer 2">
      <G data-name="arrow-ios-forward">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.21956 6C6.35556 6 5.54556 6.334 4.93956 6.941C3.68156 8.201 3.68156 10.252 4.94056 11.514L11.9996 18.585L19.0596 11.514C20.3186 10.252 20.3186 8.201 19.0596 6.941C17.8476 5.726 15.7116 5.728 14.4996 6.941L12.7076 8.736C12.3316 9.113 11.6676 9.113 11.2916 8.736L9.49956 6.94C8.89356 6.334 8.08456 6 7.21956 6ZM11.9996 21C11.7346 21 11.4796 20.895 11.2926 20.706L3.52456 12.926C1.48856 10.886 1.48856 7.567 3.52456 5.527C4.50856 4.543 5.82056 4 7.21956 4C8.61856 4 9.93156 4.543 10.9146 5.527L11.9996 6.614L13.0846 5.528C14.0686 4.543 15.3806 4 16.7806 4C18.1786 4 19.4916 4.543 20.4746 5.527C22.5116 7.567 22.5116 10.886 20.4756 12.926L12.7076 20.707C12.5196 20.895 12.2656 21 11.9996 21Z"
          fill={color}
        />
      </G>
    </G>
  </Svg>
);

export default FavoriteIcon;
