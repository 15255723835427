import Svg, { G, Path } from 'react-native-svg';
import React from 'react';
import { SimpleIconProps } from 'src/core';

const SearchIcon = ({ size = 24, color = '#8F9BB3' }: SimpleIconProps) => (
  <Svg width={`${size}px`} height={`${size}px`} viewBox={'0 0 24 24'}>
    <G data-name="Layer 2">
      <G data-name="search-icon">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 11C5 7.691 7.691 5 11 5C14.309 5 17 7.691 17 11C17 14.309 14.309 17 11 17C7.691 17 5 14.309 5 11ZM20.707 19.293L17.312 15.897C18.365 14.543 19 12.846 19 11C19 6.589 15.411 3 11 3C6.589 3 3 6.589 3 11C3 15.411 6.589 19 11 19C12.846 19 14.543 18.365 15.897 17.312L19.293 20.707C19.488 20.902 19.744 21 20 21C20.256 21 20.512 20.902 20.707 20.707C21.098 20.316 21.098 19.684 20.707 19.293Z"
          fill={color}
        />
      </G>
    </G>
  </Svg>
);

export default SearchIcon;
