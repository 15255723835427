import * as FileSystem from 'expo-file-system';
import * as ImagePicker from 'expo-image-picker';

import { Button, Icon, Text, useTheme } from '@ui-kitten/components';
import { Image, Platform, StyleSheet, TouchableOpacity, View } from 'react-native';
import React, { useState } from 'react';

import { AxiosError } from 'axios';
import { Buffer } from 'buffer';
import { ErrorModal } from '../shared/ErrorModal';
import { FileInfo } from 'expo-file-system';
import { InfoModal } from '../shared/InfoModal';
import Spinner from 'react-native-loading-spinner-overlay/lib';
import { UIHelper as uh } from 'src/core';
import { useAppStore } from 'src/core/store';
import { useGetProfilePhotoEffect } from 'src/core/hooks/useUser';
import UserService from '../../api/user';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';

const styleContainer = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: uh.h2DP(8),
    marginBottom: uh.h2DP(16)
  },
  imageContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: uh.h2DP(4),
    borderRadius: 999,
    borderWidth: 3,
    shadowRadius: 5,
    overflow: 'hidden',
    backgroundColor: 'transparent'
  },
  image: {
    width: uh.height() * 0.15,
    height: uh.height() * 0.15,
    borderRadius: 999,
    top: 24,
    overflow: 'hidden'
  }
});

const ProfilePicture = () => {
  const transMessage = {
    removeProfilePicture: 'Remove profile picture',
    addProfilePicture: 'Add profile picture',
    grantPhotoPermission: 'Please grant permission for the app to access Your photos',
    imageIsTooLarge: 'This image is too large. Please pick an image with the size less than {{maxFileSize}}MB',
    btnOK: 'OK'
  };
  const t = useTranslationFunc(transMessage);
  const profilePhoto = useAppStore((state) => state.profilePhoto);
  const setProfilePhoto = useAppStore((state) => state.setProfilePhoto);
  const [invalidAccessModal, setInvalidAccessModal] = useState<boolean>(false);
  const [error, setError] = useState<AxiosError>({} as AxiosError);
  const [errorModal, setErrorModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [tooLargeModal, setTooLargeModal] = useState<boolean>(false);

  const th = useTheme();
  const MAXIMUM_FILE_SIZE = 5; // in MB

  const [getPhotoError] = useGetProfilePhotoEffect();

  const handlePress = async () => {
    // Check for gallery permission

    const { status } = await ImagePicker.getMediaLibraryPermissionsAsync();

    if (!status) {
      setInvalidAccessModal(true);
      return;
    }

    const userImages = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
      base64: true,
      selectionLimit: 1
    });

    if (userImages.assets == null || userImages.assets?.length === 0) {
      return;
    }

    const userImage = userImages.assets[0];

    if (!userImages.canceled) {
      setLoading(true);
      // Check if image size is <= 5MB

      if (Platform.OS !== 'web') {
        const imageInfo: FileInfo = await FileSystem.getInfoAsync(userImage.uri); //TODO what if file not exists

        if (
          'size' in imageInfo &&
          imageInfo.size &&
          Number(Math.floor(imageInfo.size / Math.pow(1024, 2)).toFixed(2)) > MAXIMUM_FILE_SIZE
        ) {
          setTooLargeModal(true);
          setLoading(false);
          return;
        }
      } else {
        const buffer = Buffer.from(userImage.uri.substring(userImage.uri.indexOf(',') + 1));

        if (buffer.length / 1e6 > MAXIMUM_FILE_SIZE) {
          setTooLargeModal(true);
          setLoading(false);
          return;
        }
      }

      const extension =
        Platform.OS === 'web'
          ? userImage.uri.substring('data:image/'.length, userImage.uri.indexOf(';base64')).split('.').pop()
          : userImage.uri.split('.').pop();

      await UserService.uploadProfilePhoto(
        `user_profile_photo.${extension?.split('/').pop()}`,
        Platform.OS === 'web' ? userImage.uri : `data:image/${extension};base64,${userImage.base64}`,
        `image/${extension}`
      ).catch((err) => {
        setError(err);
        setErrorModal(true);
      });

      setProfilePhoto(userImage.uri);
      setLoading(false);
    }
  };

  const handleRemove = async () => {
    setLoading(true);
    await UserService.removeProfilePhoto()
      .then(() => setProfilePhoto(''))
      .catch((err) => {
        setError(err);
        setErrorModal(true);
      });
    setLoading(false);
  };

  return (
    <>
      {loading && <Spinner visible={true} />}
      <View style={styleContainer.container}>
        <TouchableOpacity
          style={[
            styleContainer.imageContainer,
            {
              borderColor: th['color-basic-500'],
              shadowColor: th['color-primary-500']
            }
          ]}
          onPress={handlePress}>
          {profilePhoto?.length ? (
            <Image source={{ uri: profilePhoto }} style={[styleContainer.image, { top: 0 }]} />
          ) : (
            <View style={styleContainer.image}>
              <Icon fill={th['color-basic-500']} name="person" />
            </View>
          )}
        </TouchableOpacity>
        {profilePhoto?.length > 0 ? (
          <Button status={'danger'} size={'small'} appearance={'ghost'} onPress={handleRemove}>
            <Text>{t('removeProfilePicture')}</Text>
          </Button>
        ) : (
          <Button status={'primary'} size={'small'} appearance={'ghost'} onPress={handlePress}>
            <Text>{t('addProfilePicture')}</Text>
          </Button>
        )}
        <InfoModal
          visible={invalidAccessModal}
          message={t('grantPhotoPermission')}
          closeBtnClick={() => setInvalidAccessModal(false)}
          buttonTitle={t('btnOK')}
        />
        <ErrorModal
          visible={errorModal || getPhotoError}
          message={{ ...error, ...getPhotoError }}
          closeBtnClick={() => setErrorModal(false)}
        />
        <InfoModal
          visible={tooLargeModal}
          message={t('imageIsTooLarge', { maxFileSize: MAXIMUM_FILE_SIZE })}
          closeBtnClick={() => setTooLargeModal(false)}
          buttonTitle={t('btnOK')}
        />
      </View>
    </>
  );
};

export default ProfilePicture;
