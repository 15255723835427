import { AssessmentListSection, AssessmentSummary } from '../../components/profileMeAssessment';
import { Divider, Layout } from '@ui-kitten/components';
import { HealthMarkerGroup, HealthMarkerReport } from 'src/core/types/HealthMarkerReport';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { RefreshControl, ScrollView, StyleSheet } from 'react-native';
import { RootStackScreenProps, UIHelper as uh } from '../../core';

import { AxiosError } from 'axios';
import { BackendApi } from 'src/api/shared';
import { ErrorModal } from 'src/components/shared/ErrorModal';
import Spinner from 'react-native-loading-spinner-overlay/lib';
import { useAnalytics } from 'src/core/hooks/useAnalytics';

const AssessmentDetailScreen = ({ route, navigation }: RootStackScreenProps<'AssessmentDetail'>) => {
  const assessmentIdParam = (route?.params as any).assessmentId || '';

  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [groups, setGroups] = useState<HealthMarkerGroup[]>([]);
  const [assessmentDate, setAssessmentDate] = useState<Date>();
  const [assessorName, setAssessorName] = useState<string>('');
  const [assesssmentType, setAssessmentType] = useState<string>('');
  const [assessmentName, setAssessmentName] = useState<string>('');
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [error, setError] = useState<AxiosError>({} as AxiosError);
  const [visibleErrorModal, setVisibleErrorModal] = useState<boolean>(false);

  const scrollRef = useRef<ScrollView>(null);
  const { addAnalyticsLog } = useAnalytics('AssessmentDetailScreen.tsx');

  const styleContainer = StyleSheet.create({
    screenContainer: {
      flex: 1
    },
    scroll: { height: uh.height() - uh.h2DP(60) }
  });

  const onRefresh = useCallback(() => {
    setRefreshing(true);
  }, []);

  const getData = useCallback(
    async (assessmentId: string) => {
      if (!assessmentIdParam) {
        navigation.navigate('Profile', { screen: 'Assessments' });
        return;
      }

      setIsLoading(true);
      BackendApi.get(`/health-markers/report-new/id/${assessmentId}`)
        .then((res) => {
          const report: HealthMarkerReport = res.data;
          addAnalyticsLog({
            data: {
              AssessmentID: assessmentId,
              AssessmentType: report.reportType
            },
            logType: 'AssessmentType'
          });
          setGroups(report.groups);
          setAssessmentDate(report.assessmentName == 'Wearables' ? report.updatedOn : report.measuredDate);
          setAssessorName(report.assessorName);
          setAssessmentName(report.assessmentName);
          setAssessmentType(report.reportType);
          setIsLoading(false);
          setRefreshing(false);
          setIsLoaded(true);
        })
        .catch((err) => {
          setError(err);
          setVisibleErrorModal(true);
          addAnalyticsLog({ function: 'getData', data: err, logType: 'error' });
          setIsLoading(false);
          setRefreshing(false);
          setIsLoaded(true);
        });
    },
    [addAnalyticsLog, assessmentIdParam, navigation]
  );

  useEffect(() => {
    getData(assessmentIdParam);
  }, [getData, assessmentIdParam]);

  useEffect(() => {
    if (refreshing == true) {
      getData(assessmentIdParam);
    }
  }, [refreshing, getData, assessmentIdParam]);

  if (isLoading && refreshing == false) {
    return (
      <Layout level="2" style={[styleContainer.screenContainer]}>
        <Spinner visible={true} />
      </Layout>
    );
  }

  return (
    <Layout level="2" style={[styleContainer.screenContainer]}>
      <ScrollView
        contentContainerStyle={{
          flexGrow: 1,
          padding: uh.h2DP(16),
          paddingBottom: uh.h2DP(24),
          paddingTop: 0
        }}
        style={styleContainer.scroll}
        ref={scrollRef}
        refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}>
        <Divider />
        {isLoaded == true && (
          <AssessmentSummary
            name={assessmentName}
            type={assesssmentType}
            date={assessmentDate as Date}
            coach={{ name: assessorName, permission: 'Access Everything', coachPicture: undefined }}
          />
        )}
        <AssessmentListSection
          sections={groups}
          btnDetailAssessmentClick={(assessmentId) => {
            navigation.setParams({ assessmentId: assessmentId } as any);
            getData(assessmentId);
          }}
          scrollRef={scrollRef}
          coach={{ name: assessorName, permission: 'Access Everything', coachPicture: undefined }}
        />
      </ScrollView>
      <ErrorModal
        visible={visibleErrorModal}
        message={error}
        closeBtnClick={() => {
          if (navigation.canGoBack()) navigation.goBack();
          else navigation.navigate('Profile', { screen: 'Assessments' });

          setVisibleErrorModal(false);
        }}
      />
    </Layout>
  );
};

export default AssessmentDetailScreen;
