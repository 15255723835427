import { Button, Spinner, Text } from '@ui-kitten/components';
import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';

import { UIHelper as uh } from '../../../../../core';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';

interface ScanLoadingProps {
  scanType: 'face' | 'body';
  isLoading: boolean;
  hasError: boolean;
  onRetry: () => void;
  onCancel: () => void;
}

const ScanLoading = ({ scanType, isLoading, hasError, onRetry, onCancel }: ScanLoadingProps) => {
  const styles = StyleSheet.create({
    btn: {
      marginTop: uh.w2DP(14),
      flex: 1
    },
    container: { padding: uh.w2DP(24), flex: 1, justifyContent: 'center', alignItems: 'center' },
    btnContainer: { display: 'flex', flexDirection: 'row', gap: uh.w2DP(15) },
    text: { marginVertical: uh.w2DP(24), textAlign: 'center' }
  });

  const transMessage = useMemo(
    () => ({
      scanLoadingFaceScanText: 'Initializing face scan',
      scanLoadingBodyScanText: 'Initializing body scan',
      scanLoadingError:
        'We could not initialise the scan. Please check your internet or mobile connection and try again.',
      scanLoadingRetryBtn: 'Try again',
      scanLoadingRCancelBtn: 'Cancel'
    }),
    []
  );

  const t = useTranslationFunc(transMessage);

  if (isLoading) {
    return (
      <View style={styles.container}>
        <Spinner size="giant" />
        <Text style={styles.text} status="primary" category="s1">
          {t(scanType == 'face' ? 'scanLoadingFaceScanText' : 'scanLoadingBodyScanText')}
        </Text>
      </View>
    );
  }

  if (hasError) {
    return (
      <View style={styles.container}>
        <Text style={styles.text} status="primary" category="s1">
          {t('scanLoadingError')}
        </Text>
        <View style={styles.btnContainer}>
          <Button
            style={styles.btn}
            size="giant"
            status="primary"
            appearance="ghost"
            onPress={onCancel}
            accessible={true}
            accessibilityLabel={t('scanLoadingRCancelBtn')}
            testID="Cancel">
            <Text status="primary" category="s2">
              {t('scanLoadingRCancelBtn')}
            </Text>
          </Button>
          <Button
            style={styles.btn}
            size="giant"
            status="primary"
            onPress={onRetry}
            accessible={true}
            accessibilityLabel={t('scanLoadingRetryBtn')}
            testID="Retry">
            <Text status="primary" category="s2">
              {t('scanLoadingRetryBtn')}
            </Text>
          </Button>
        </View>
      </View>
    );
  }

  return <></>;
};

export default ScanLoading;
