import { ApplicationState, useAppStore } from '../store';
import {
  getBrand,
  getDeviceId,
  getFreeDiskStorage,
  getManufacturer,
  getMaxMemory,
  getPowerState,
  getSystemName,
  getSystemVersion,
  getTotalDiskCapacity
} from 'react-native-device-info';
import { useCallback, useEffect } from 'react';

import { EnableTelemetryFunc } from '../store/slices/telemetry';
import { SimpleTelemetryData } from 'src/api/telemetry/model';
import TelemetryService from '../../api/telemetry';
import { useIsUserLogged } from './useUser';

export type AddTelemetryLogFunc = (log: string) => void;

interface UseTelemetry {
  addTelemetryLog: AddTelemetryLogFunc;
  setTelemetryEnabled: EnableTelemetryFunc;
}

export function useTelemetry(): UseTelemetry {
  const addTelemetryData = useAppStore((state: ApplicationState) => state.addTelemetryLog);
  const setTelemetryEnabled = useAppStore((state: ApplicationState) => state.setTelemetryEnabled);
  const isTelemetryEnabled = useAppStore((state: ApplicationState) => state.telemetry);

  const getDeviceInfo = async () => {
    const result = {};

    const funcDev: Map<string, () => any> = new Map<string, () => Promise<any>>();
    funcDev.set('OS', getSystemName);
    funcDev.set('systemVersion', getSystemVersion);
    funcDev.set('brand', getBrand);
    funcDev.set('deviceId', getDeviceId);

    // TODO use Promise.allSettled() on RN 0.71
    // funcDev.set('powerState', getPowerState);
    // funcDev.set('freeDiskStorage', getFreeDiskStorage);
    // funcDev.set('manufacturer', getManufacturer);
    // funcDev.set('maxMemory', getMaxMemory);
    // funcDev.set('totalDiskCapacity', getTotalDiskCapacity);

    funcDev.forEach((value, key) => {
      try {
        const data = value();
        Object.assign(result, { [key]: data });
      } catch (err) {
        //ignore
      }
    });

    try {
      const value = await getFreeDiskStorage();
      Object.assign(result, { freeDiskStorage: value });
    } catch (err) {
      //ignore
    }

    try {
      const value = await getManufacturer();
      Object.assign(result, { manufacturer: value });
    } catch (err) {
      //ignore
    }

    try {
      const value = await getPowerState();
      Object.assign(result, { powerState: value });
    } catch (err) {
      //ignore
    }

    try {
      const value = await getMaxMemory();
      Object.assign(result, { MaxMemory: value });
    } catch (err) {
      //ignore
    }

    try {
      const value = await getTotalDiskCapacity();
      Object.assign(result, { totalDiskCapacity: value });
    } catch (err) {
      //ignore
    }

    return result;
  };

  const addTelemetryLog: AddTelemetryLogFunc = useCallback(
    async (log: string) => {
      const deviceInfo = await getDeviceInfo();

      const telemetryData: SimpleTelemetryData = {
        deviceInfo,
        content: log,
        created: new Date().toISOString()
      };

      if (isTelemetryEnabled) {
        addTelemetryData(telemetryData);
        TelemetryService.sendTelemetry('Terra', telemetryData);
      }
    },
    [addTelemetryData, isTelemetryEnabled]
  );

  return { addTelemetryLog, setTelemetryEnabled };
}

export function useFlushTelemetry(): () => Promise<void> {
  const clear = useAppStore((state: ApplicationState) => state.clearTelemetry);
  const isTelemetryEnabled = useAppStore((state: ApplicationState) => state.telemetry);

  const flush = () => {
    if (isTelemetryEnabled) {
      return new Promise<void>((resolve, reject) => {
        clear((logs) => {
          TelemetryService.sendMultiTelemetry('Terra', logs).then(
            (data) => {
              resolve(data);
            },
            (error) => {
              reject(error);
            }
          );
        });
      });
    }
    return Promise.resolve();
  };

  return flush;
}

export const useInitTelemetry = () => {
  const isTelemetryEnabled = useAppStore((state: ApplicationState) => state.isTelemetryEnabled);
  const setTelemetryEnabled = useAppStore((state: ApplicationState) => state.setTelemetryEnabled);
  const isUserLogged = useIsUserLogged();

  useEffect(() => {
    setTelemetryEnabled(isUserLogged && isTelemetryEnabled);
  }, [isUserLogged, setTelemetryEnabled, isTelemetryEnabled]);
};
