import { ApplicationState, useAppStore } from '../../store';
import { BodyScanData, FaceScanData } from 'src/core/types/Scan';
import React, { useCallback } from 'react';
import { ahiStartBodyScan, ahiStartFaceScan } from './helper';
import { useGetUserEmail, useGetUserId, useIsUserLogged } from '../useUser';

import { Config } from 'src/core/constants/Config';
import MultiScanModule from '../../modules/MultiScanModule';
import { Platform } from 'react-native';
import { promiseTimeout } from '../../helpers/PromiseTimeout';
import { useAnalytics } from '../useAnalytics';

export enum MultiScanResultStatus {
  SUCCESS,
  CANCEL,
  USER_OUT_OF_CREDIT,
  UNCAUGHT_ERROR
}

export type MultiScanResultData = FaceScanData | BodyScanData | undefined;

export interface MultiScanResult {
  status: MultiScanResultStatus;
  data: MultiScanResultData;
}

interface UseMultiScan {
  startFaceScan: (userFaceScanInputData: any) => Promise<MultiScanResult>;
  startBodyScan: (userBodyScanInputData: any) => Promise<MultiScanResult>;
  initMultiScan: () => Promise<boolean>;
  authorizeUser: (userId: string, userEmail: string) => Promise<boolean>;
}

const FACE_CANCEL_CODES = ['3000', '3003', '3010'];
const BODY_CANCEL_CODES = ['2000', '2011', '2047'];
const AHI_SETUP_ALREADY_COMPLETE_CODE = '1003';
// const AHI_SETUP_ERROR_CODE = '3013';
const AHI_ERROR_USER_OUT_OF_CREDIT = ['1053', '1032'];

export const useGetIsMultiScanInit = (): boolean => {
  return useAppStore((state: ApplicationState) => state.initMultiScan.data);
};

const useSetMultiScanInit = (): ((isInit: boolean) => void) => {
  return useAppStore((state: ApplicationState) => state.setMultiScanInitComplete);
};

export const useIsMultiScanInitLoading = (): boolean => {
  return useAppStore((state: ApplicationState) => state.initMultiScan.isLoading);
};

const useSetMultiScanInitLoading = (): ((isInit: boolean) => void) => {
  return useAppStore((state: ApplicationState) => state.setMultiScanInitLoading);
};

export const useHasMultiScanInitError = (): boolean => {
  return useAppStore((state: ApplicationState) => state.initMultiScan.hasError);
};

const useSetMultiScanInitError = (): ((isInit: boolean) => void) => {
  return useAppStore((state: ApplicationState) => state.setMultiScanInitError);
};

export const useIsMultiScanUserLoading = (): boolean => {
  return useAppStore((state: ApplicationState) => state.multiScanUser.isLoading);
};

const useSetMultiScanUserLoading = (): ((isInit: boolean) => void) => {
  return useAppStore((state: ApplicationState) => state.setMultiScanUserLoading);
};

export const useHasMultiScanUserError = (): boolean => {
  return useAppStore((state: ApplicationState) => state.multiScanUser.hasError);
};

const useSetMultiScanUserError = (): ((isInit: boolean) => void) => {
  return useAppStore((state: ApplicationState) => state.setMultiScanUserError);
};

export const useGetIsMultiScanUserInit = (): boolean => {
  return useAppStore((state: ApplicationState) => state.multiScanUser.data);
};

const useSetMultiScanUserInit = (): ((isInit: boolean) => void) => {
  return useAppStore((state: ApplicationState) => state.setMultiScanUser);
};

export const useMultiScan = (): UseMultiScan => {
  const { addAnalyticsLog } = useAnalytics('useMultiScan/index.ts');

  const startFaceScan = (userFaceScanInputData: any): Promise<MultiScanResult> => {
    return ahiStartFaceScan(userFaceScanInputData)
      .then((data) => {
        return {
          status: MultiScanResultStatus.SUCCESS,
          data: data
        };
      })
      .catch((error) => {
        if (FACE_CANCEL_CODES.includes(error?.code)) {
          return {
            status: MultiScanResultStatus.CANCEL,
            data: undefined
          };
        }
        if (AHI_ERROR_USER_OUT_OF_CREDIT.includes(error?.code)) {
          return {
            status: MultiScanResultStatus.USER_OUT_OF_CREDIT,
            data: undefined
          };
        }

        return Promise.reject({
          status: MultiScanResultStatus.UNCAUGHT_ERROR,
          data: error
        });
      });
  };

  const startBodyScan = (userBodyScanInputData: any): Promise<MultiScanResult> => {
    return ahiStartBodyScan(userBodyScanInputData)
      .then((data) => {
        return {
          status: MultiScanResultStatus.SUCCESS,
          data: data
        };
      })
      .catch((error) => {
        if (BODY_CANCEL_CODES.includes(error?.code)) {
          return {
            status: MultiScanResultStatus.CANCEL,
            data: undefined
          };
        }
        if (AHI_ERROR_USER_OUT_OF_CREDIT.includes(error?.code)) {
          return {
            status: MultiScanResultStatus.USER_OUT_OF_CREDIT,
            data: undefined
          };
        }
        return Promise.reject({
          status: MultiScanResultStatus.UNCAUGHT_ERROR,
          data: error
        });
      });
  };

  const initMultiScan = (): Promise<boolean> => {
    return MultiScanModule.setupMultiScanSDK(Config.AHI_DEV_MULTISCAN_TOKEN)
      .then(() => MultiScanModule.areAHIResourcesAvailable())
      .then((areAvailable) => {
        if (!areAvailable) {
          MultiScanModule.downloadAHIResources();
        }
        return true;
      })
      .catch((error: any) => {
        if (error.code === AHI_SETUP_ALREADY_COMPLETE_CODE) {
          return Promise.resolve(true);
        }
        addAnalyticsLog({ logType: 'error', function: 'useMultiScanInitializer', data: error });
        return Promise.resolve(false);
      });
  };

  const authorizeUser = (userId: string, userEmail: string): Promise<boolean> => {
    return MultiScanModule.authorizeUser(userId, Config.AHI_DEV_MULTISCAN_AUTHZ_SALT, [userEmail])
      .then(() => {
        return true;
      })
      .catch((error: any) => {
        addAnalyticsLog({ logType: 'error', function: 'useMultiScanInitializer', data: error });
        return Promise.resolve(false);
      });
  };

  return { startFaceScan, startBodyScan, initMultiScan, authorizeUser };
};

export const useMultiScanSetup = (): (() => Promise<boolean>) => {
  const setInit = useSetMultiScanInit();
  const setLoading = useSetMultiScanInitLoading();
  const setError = useSetMultiScanInitError();

  const { initMultiScan } = useMultiScan();

  const setupMultiScan = useCallback((): Promise<boolean> => {
    setLoading(true);
    setError(false);
    return initMultiScan().then((result) => {
      setError(!result);
      setLoading(false);
      setInit(result);
      return result;
    });
  }, [setInit, setLoading, setError, initMultiScan]);
  return setupMultiScan;
};

type UseMultiScanUser = (userId: string, userEmail: string) => Promise<boolean>;

export const useMultiScanAuthorize = (): UseMultiScanUser => {
  const { authorizeUser } = useMultiScan();
  const setInit = useSetMultiScanUserInit();
  const setLoading = useSetMultiScanUserLoading();
  const setError = useSetMultiScanUserError();

  const authorize = useCallback(
    (userId: string, userEmail: string): Promise<boolean> => {
      setLoading(true);
      setError(false);
      return authorizeUser(userId, userEmail).then((result) => {
        setError(!result);
        setLoading(false);
        setInit(result);
        return result;
      });
    },
    [setInit, setLoading, setError, authorizeUser]
  );

  return authorize;
};

export const useMultiScanInitializer = (): void => {
  const isUserLogged = useIsUserLogged();
  const userEmail = useGetUserEmail() ?? '';
  const userId = useGetUserId() ?? '';
  const isMultiScanInit = useGetIsMultiScanInit();
  const setupMultiScan = useMultiScanSetup();
  const authorizeUser = useMultiScanAuthorize();

  const { addAnalyticsLog } = useAnalytics('useMultiScan/index.ts');

  const isAppInit = useAppStore((state: ApplicationState) => state.isAppPreInit);
  const isAppInitComplete = useAppStore((state: ApplicationState) => state.isAppInitComplete);

  React.useEffect(() => {
    if (!isAppInit) {
      return;
    }

    if (Platform.OS != 'web' && !isMultiScanInit && isAppInitComplete) {
      setupMultiScan();
    }
  }, [isAppInit, isMultiScanInit, isAppInitComplete, setupMultiScan]);

  React.useEffect(() => {
    if (Platform.OS != 'web' && isUserLogged && isMultiScanInit) {
      authorizeUser(userId, userEmail);
      return () => {
        promiseTimeout(MultiScanModule.deauthorizeUser(), 5000).then((error) => {
          addAnalyticsLog({ logType: 'error', function: 'useMultiScanInitializer', data: error });
        });
      };
    }
  }, [isUserLogged, userId, userEmail, isMultiScanInit, addAnalyticsLog, authorizeUser]);
};
