import 'react-native-get-random-values';

import * as Animatable from 'react-native-animatable';

import { Bubble, utils } from 'react-native-gifted-chat';
import {
  FlexAlignType,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
  ViewProps
} from 'react-native';
import React, { memo, useState } from 'react';

// import Clipboard from '@react-native-clipboard/clipboard';
// import { chatBubbleWithAnimation } from 'src/core/brands';
import { reactions } from './reactions';
import { UIHelper as uh } from '../../core';
import { useAppStore } from 'src/core/store';
import { useTheme } from '@ui-kitten/components';

// import { useGetChatbotName } from 'src/core/hooks/useBrands';
// import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';

//props
interface IChatBubbleWithAnimationProps extends ViewProps {
  bubbleWidth: number;
  bubbleHeight: number;
  message: any;
  reactionId: number;
  pressedMessageId: string;
  isShowAllMsgsReaction: boolean;
  pressEmoji: (i: number) => void;
  resetPressedMessageId: () => void;
  enableAnimation: boolean;
}

const ChatBubbleWithAnimation = (props: IChatBubbleWithAnimationProps) => {
  const th = useTheme();
  // const t = useTranslationFunc(chatBubbleWithAnimation);
  // const chatbotName = useGetChatbotName();

  const theme = useAppStore((state) => state.theme);
  const condColors = {
    bubbleBg: uh.getHex(th, theme, 'color-basic-400', 'color-basic-800'),
    reactionList: uh.getHex(th, theme, 'color-basic-100', 'color-basic-900'),
    backgroundColor: uh.getHex(th, theme, 'color-primary-100', 'color-basic-800')
  };
  // const reactionBgColor = '#D2D5D9';
  const reactionIconColor = '#4C4F56';
  const styles = StyleSheet.create({
    container: {
      flex: 1
    },
    iconContainer: {
      height: uh.h2DP(40),
      width: uh.w2DP(40)
    },

    smallCircle1: {
      position: 'absolute',
      width: 10,
      height: 10,
      top: uh.h2DP(35),
      left: uh.w2DP(27),
      borderRadius: 10 / 2
    },
    smallCircle2: {
      position: 'absolute',
      width: 4,
      height: 4,
      top: uh.h2DP(47),
      left: uh.w2DP(35),
      borderRadius: 4 / 2
    },
    reactionContainer: {
      flexDirection: 'row',
      top: 0,
      zIndex: -1,
      borderRadius: 30,
      paddingHorizontal: 0,
      height: 50,
      marginVertical: 4
    },
    listIconsContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '90%',
      marginLeft: uh.w2DP(10),
      marginRight: uh.w2DP(20)
    },
    smallIconContainer: {
      alignItems: 'center',
      justifyContent: 'center',
      width: 35,
      height: 35,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: condColors.backgroundColor
    },
    url: {
      color: 'blue',
      textDecorationLine: 'underline'
    },
    utilityContainer: {
      flex: 1,
      justifyContent: 'space-between',
      height: 110,
      backgroundColor: condColors.reactionList,
      shadowColor: 'black',
      shadowOffset: {
        width: 0,
        height: 2
      },
      shadowOpacity: 0.5,
      shadowRadius: 3
    },
    utilityButton: {
      margin: 5
    }
  });

  const [enableAnimation] = useState<boolean>(props.enableAnimation);

  const renderEmojiIcon = (iconId: number, iconColor: string, isLargeIcon = true) => {
    let iconSize = 30;
    if (isLargeIcon === false) {
      iconSize = 20;
    }

    switch (iconId) {
      case 1:
        return <Text style={{ fontSize: iconSize }}>{reactions[0]}</Text>;
      case 2:
        // return <AntDesign name="like1" size={iconSize} color={iconColor} />;
        return <Text style={{ fontSize: iconSize }}>{reactions[1]}</Text>;
      case 3:
        return <Text style={{ fontSize: iconSize }}>{reactions[2]}</Text>;

      case 4:
        return <Text style={{ fontSize: iconSize }}>{reactions[3]}</Text>;

      case 5:
        return <Text style={{ fontSize: iconSize }}>{reactions[4]}</Text>;
      case 6:
        return <Text style={{ fontSize: iconSize }}>{reactions[5]}</Text>;
    }
  };

  const renderListOfEmojiIcons = (iconColor: string) => {
    const emojiIcons: Array<number> = [];
    for (let i = 1; i <= 6; i++) {
      emojiIcons.push(i);
    }

    return emojiIcons.map((i) => {
      return (
        <TouchableOpacity
          accessible={true}
          accessibilityLabel={`Emoji ${i}`}
          testID={`emoji-${i}`}
          key={`key ${i}`}
          onPress={() => props.pressEmoji(i)}>
          {renderEmojiIcon(i, iconColor, true)}
        </TouchableOpacity>
      );
    });
  };

  const flexStyle: FlexAlignType = props.message.position === 'left' ? 'flex-start' : 'flex-end';
  const { currentMessage, previousMessage, nextMessage } = props.message;
  const isSameNextUser = currentMessage && nextMessage && utils.isSameUser(currentMessage, nextMessage);
  const isSameNextDay = currentMessage && nextMessage && utils.isSameDay(currentMessage, nextMessage);
  const isSamePreviousUser = currentMessage && previousMessage && utils.isSameUser(currentMessage, previousMessage);
  const isSamePreviousDay = currentMessage && previousMessage && utils.isSameDay(currentMessage, previousMessage);
  const hasReaction =
    props.reactionId > 0 &&
    props.bubbleWidth != undefined &&
    props.bubbleHeight != undefined &&
    props.isShowAllMsgsReaction === true;
  const isPressed = props.pressedMessageId === props.message.currentMessage._id;

  const reactionStyle = StyleSheet.create({
    common: {
      width: 35,
      height: 35,
      zIndex: 999,
      top: isPressed
        ? Platform.OS === 'web'
          ? props.bubbleHeight + 34.5
          : props.bubbleHeight + 45
        : props.bubbleHeight - 5,
      position: 'absolute',
      backgroundColor: th['color-primary-500']
    },
    right: {
      right: props.bubbleWidth - 40
    },
    left: {
      left: props.bubbleWidth - 40
    }
  });

  // const handleCopy = (message: string) => {
  //   Clipboard.setString(message);
  // };

  // const handleShare = (message: string) => {
  //   Share.share({ message: message });
  // };

  const contentView = () => {
    return (
      <View
        style={{
          alignSelf: flexStyle,
          flex: 1,
          alignItems: props.message.position === 'right' ? 'flex-end' : 'flex-start',
          marginBottom: hasReaction ? 25 : 0
        }}>
        {isPressed && (
          <View
            style={[
              styles.reactionContainer,
              {
                width: 225,
                zIndex: 9999,
                flex: 1,
                justifyContent: 'space-between',

                backgroundColor: condColors.reactionList,
                shadowColor: 'black',
                shadowOffset: {
                  width: 0,
                  height: 2
                },
                shadowOpacity: 0.5,
                shadowRadius: 3
              }
            ]}>
            <View style={styles.listIconsContainer}>{renderListOfEmojiIcons(reactionIconColor)}</View>
          </View>
        )}

        {hasReaction && (
          <View
            style={[
              styles.reactionContainer,
              reactionStyle.common,
              props.message.position === 'right' ? reactionStyle.right : reactionStyle.left
            ]}>
            <View>
              <View style={styles.smallIconContainer}>{renderEmojiIcon(props.reactionId, 'white', false)}</View>
            </View>
          </View>
        )}

        <View style={{ height: 5 }} />
        <Bubble
          {...props.message}
          wrapperStyle={{
            left: {
              backgroundColor: condColors.bubbleBg,
              borderRadius: 0,
              borderTopLeftRadius: !isSamePreviousDay || !isSamePreviousUser ? 16 : 0,
              borderTopRightRadius: 16,
              borderBottomRightRadius: 16,
              borderBottomLeftRadius: !isSameNextUser || !isSameNextDay ? 16 : 0
            },
            right: {
              backgroundColor: th['color-primary-500'],
              borderRadius: 0,
              borderTopLeftRadius: 16,
              borderTopRightRadius: 16,
              borderBottomLeftRadius: 16
            }
          }}></Bubble>
        {/* {isPressed && (
          <View style={styles.utilityContainer}>
            <Button
              accessible={true}
              accessibilityLabel={t('copyAccessibilityLabel', { chatbot_name: chatbotName })}
              testID={t('copyTestId', { chatbot_name: chatbotName })}
              style={styles.utilityButton}
              onPress={() => handleCopy(props.message.currentMessage.text)}>
              Copy
            </Button>
            <Button
              accessible={true}
              accessibilityLabel={t('shareAccessibilityLabel', { chatbot_name: chatbotName })}
              testID={t('shareTestId', { chatbot_name: chatbotName })}
              style={styles.utilityButton}
              onPress={() => handleShare(props.message.currentMessage.text)}>
              Share
            </Button>
          </View>
        )} */}
      </View>
    );
  };

  return (
    <View style={[styles.container, { marginRight: 12 }]}>
      <TouchableWithoutFeedback onPressOut={props.resetPressedMessageId}>
        {enableAnimation == true ? (
          <Animatable.View animation={'fadeIn'} duration={250} style={styles.container}>
            {contentView()}
          </Animatable.View>
        ) : (
          contentView()
        )}
      </TouchableWithoutFeedback>
    </View>
  );
};

export default memo(ChatBubbleWithAnimation);
