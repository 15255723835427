import { StyleSheet, View, ViewProps } from 'react-native';

import ForwardIcon from './components/icons/ForwardIcon';
import { GroupMenuItem } from './MainMenuContainer';
import MenuSpecialItem from './components/MenuSpecialItem';
import React from 'react';
import { useTheme } from '@ui-kitten/components';

//props
interface SpecialGroupMenuProps extends ViewProps {
  items: GroupMenuItem[];
}

//styles
const styleContainer = StyleSheet.create({
  groupTitle: { marginBottom: 4, marginTop: 16, fontWeight: '400' },
  itemsContainer: { rowGap: 0 },
  itemName: { fontWeight: '400', marginLeft: -8, textAlign: 'left', alignSelf: 'flex-start' },
  itemsSpecialContainer: { rowGap: 16 }
});

const SpecialGroupMenu = ({ style, items }: SpecialGroupMenuProps) => {
  const th = useTheme();
  //view
  return (
    <View style={style}>
      <View style={styleContainer.itemsSpecialContainer}>
        {items
          .filter((item) => !item.hidden)
          .map((item) => (
            <MenuSpecialItem
              key={item.id}
              title={item.name}
              rightIcon={<ForwardIcon color={th['color-basic-500']} />}
              leftIcon={item.leftIcon}
              count={item.count ?? 0}
              onPress={item.action}
              id={item.id}
            />
          ))}
      </View>
    </View>
  );
};

export default SpecialGroupMenu;
