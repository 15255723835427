import { Icon, Text, useTheme } from '@ui-kitten/components';
import { PillarIcon, PillarNames } from 'src/core/icons/pillars';
import { StyleSheet, View, ViewProps, ViewStyle } from 'react-native';

import Brands from 'brands.json';
import React from 'react';
import { UIHelper as uh } from '../../core';
import { useIsLightColorScheme } from 'src/core/hooks/useIsLightColorScheme';

//props
interface PillarIconProps extends ViewProps {
  name?: string;
  type: string;
  size: 'small' | 'large';
  style?: ViewStyle | undefined;
}

// styles
const styles = StyleSheet.create({
  icon: {
    width: uh.h2DP(20),
    height: uh.h2DP(20)
  },
  smallIcon: {
    width: uh.h2DP(16),
    height: uh.h2DP(16)
  },

  container: {
    padding: uh.h2DP(6),
    borderRadius: 25,
    alignItems: 'center',
    height: uh.h2DP(32),
    width: uh.h2DP(32)
  },

  smallContainer: {
    padding: uh.h2DP(4),
    borderRadius: 25,
    alignItems: 'center',
    height: uh.h2DP(24),
    width: uh.h2DP(24)
  },

  containerWithName: {
    flexDirection: 'row',
    paddingTop: uh.h2DP(4),
    paddingBottom: uh.h2DP(4),
    paddingStart: uh.h2DP(10),
    paddingEnd: uh.h2DP(12),
    borderRadius: 25,
    height: uh.h2DP(32),
    alignItems: 'center'
  },
  smallContainerWithName: {
    flexDirection: 'row',
    paddingVertical: uh.h2DP(4),
    paddingHorizontal: uh.h2DP(8),
    borderRadius: 24,
    height: uh.h2DP(24),
    alignItems: 'center'
  },
  pillarName: {
    marginLeft: uh.h2DP(4)
  }
});

const PillarContainer = ({ name, type, size, style }: PillarIconProps) => {
  const isLightTheme = useIsLightColorScheme();
  const th = useTheme();
  const nameCategory = size === 'small' ? 'c1' : 'p2';
  const ICON_TYPES = [
    'apple-light',
    'nourish',
    'move',
    'connect',
    'reflect',
    'grow',
    'rest',
    'diet',
    'supplement',
    'lifestyle',
    'loading',
    'apple-watch',
    'headspace'
  ];

  if (ICON_TYPES.includes(type) == false) {
    return <></>;
  }

  // icon along with name
  if (name != undefined) {
    return (
      <View
        style={[
          size == 'small' ? styles.smallContainerWithName : styles.containerWithName,
          style,
          {
            backgroundColor: isLightTheme ? th['color-' + type + '-200-transparent'] : th['color-' + type + '-400']
          }
        ]}>
        <PillarIcon
          name={type.toLowerCase() as PillarNames}
          color={isLightTheme ? th['color-' + type + '-500'] : th['color-' + type + '-700']}
        />
        <Text
          category={nameCategory}
          style={[
            styles.pillarName,
            { color: isLightTheme ? th['color-' + type + '-500'] : th['color-' + type + '-700'] }
          ]}>
          {name}
        </Text>
      </View>
    );
  } else {
    // icon only
    return (
      <View
        style={[
          size == 'small' ? styles.smallContainer : styles.container,
          style,
          { backgroundColor: th['color-' + type + '-200'] }
        ]}>
        <Icon name={type} style={size == 'small' ? styles.smallIcon : styles.icon} pack={Brands.icon_pack}></Icon>
      </View>
    );
  }
};

export default PillarContainer;
