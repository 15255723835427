import { Button, Card, Modal, Text, useTheme } from '@ui-kitten/components';
import { Linking, StyleSheet, View, ViewProps } from 'react-native';

import React from 'react';
import { terraInfoModal } from 'src/core/brands';
import { UIHelper as uh } from '../../../core';
import { useGetAppName } from 'src/core/hooks/useBrands';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';

interface ITerraInfoModalProps extends ViewProps {
  visible: boolean;
  primaryButtonClick: () => void;
  secondaryButtonClick: () => void;
  testingAttributes?: {
    accessible: boolean;
    accessibilityLabel: string;
    testID: string;
  };
}

export const TerraInfoModal = (props: ITerraInfoModalProps) => {
  const styles = StyleSheet.create({
    backdrop: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    buttonStyle: {
      width: '50%',
      minWidth: 100,
      marginHorizontal: 10
    },
    messageContainer: {
      flex: 1
    },
    message: {
      flex: 1,
      textAlign: 'center',
      alignContent: 'center'
    },
    card: { maxWidth: uh.currentViewPort() - 64, paddingHorizontal: uh.h2DP(8) },
    buttonArea: { justifyContent: 'center', alignItems: 'center', flexDirection: 'row', marginTop: 20 }
  });

  const th = useTheme();
  const t = useTranslationFunc(terraInfoModal);
  const appName = useGetAppName();

  return (
    <Modal
      visible={props.visible}
      backdropStyle={styles.backdrop}
      onBackdropPress={() => props.secondaryButtonClick()}
      accessible={props.testingAttributes?.accessible || undefined}
      accessibilityLabel={props.testingAttributes?.accessibilityLabel || undefined}
      testID={props.testingAttributes?.testID || undefined}>
      <Card style={styles.card}>
        <View style={styles.messageContainer}>
          <Text style={styles.message}>{t('terraInfoMessage', { app_name: appName })}</Text>
          <Text
            category="s1"
            style={[styles.message, { color: th['color-primary-500'], paddingTop: 5 }]}
            onPress={() => Linking.openURL('https://tryterra.co/privacy')}>
            https://tryterra.co/privacy
          </Text>
        </View>
        <View style={styles.buttonArea}>
          <Button
            style={styles.buttonStyle}
            appearance="ghost"
            size="small"
            onPress={() => props.secondaryButtonClick()}>
            Go Back
          </Button>
          <Button style={styles.buttonStyle} size="small" onPress={() => props.primaryButtonClick()}>
            OK
          </Button>
        </View>
      </Card>
    </Modal>
  );
};
