import { ModalService } from '@ui-kitten/components';
import { NavigationProp } from '@react-navigation/native';
import React from 'react';
import { UploadBloodworkModal } from 'src/components/profileMeAssessment';

let modalID = '';

export const showUploadBloodworkModal = (
  navigation: NavigationProp<ReactNavigation.RootParamList>,
  checkBox: boolean
) => {
  const contentElement = (
    <UploadBloodworkModal
      viewAssessmentHandler={() => navigation.navigate('Main', { screen: 'Kali' })}
      btnBackHandler={() => {
        if (checkBox) {
          navigation.navigate('Main', { screen: 'ScanGettingStarted' });
        }
        modalID = ModalService.hide(modalID);
      }}
      navigation={navigation}
    />
  );

  modalID = ModalService.show(contentElement, {
    backdropStyle: { backgroundColor: 'rgba(0, 0, 0, 0.5)' }
  });
};
