import { ChatMessage, UIHelper as uh } from 'src/core';
import { GiftedAvatar, Time, isSameDay, isSameUser } from 'react-native-gifted-chat';
import { Icon, Text, useTheme } from '@ui-kitten/components';
import React, { Fragment, useState } from 'react';
import { StyleSheet, TouchableOpacity, TouchableWithoutFeedback, View } from 'react-native';

import ChatAudioMessage from './ChatAudioMessage';
import ChatImageMessage from './ChatImageMessage';
import ChatVideoMessage from './ChatVideoMessage';
import EmojiPicker from 'rn-emoji-keyboard';
import { useIsLightColorScheme } from 'src/core/hooks/useIsLightColorScheme';

interface ChatBubbleProps {
  message: ChatMessage;
  position: 'left' | 'right';
  nextMessage?: ChatMessage;
  previousMessage?: ChatMessage;
  showAvatar?: boolean;
  pressedMessageId: string;
  setPressedMessageId: (messageId: string) => void;
}

const BASE_EMOJIS = ['👍', '❤️', '😂', '😮', '😢', '🙏'];

const ChatBubble = ({
  message,
  position,
  nextMessage,
  previousMessage,
  showAvatar,
  pressedMessageId,
  setPressedMessageId
}: ChatBubbleProps) => {
  const isSameNextUser = nextMessage && isSameUser(message, nextMessage);
  const isSameNextDay = nextMessage && isSameDay(message, nextMessage);
  const isSamePreviousUser = previousMessage && isSameUser(message, previousMessage);
  const isSamePreviousDay = previousMessage && isSameDay(message, previousMessage);

  const showTime = !(message && nextMessage && isSameUser(message, nextMessage) && isSameDay(message, nextMessage));
  const isPressed = pressedMessageId === message._id;

  const [visibleEmojiPicker, setVisibleEmojiPicker] = useState<boolean>(false);

  const th = useTheme();
  const isLightTheme = useIsLightColorScheme();

  const styleContainer = StyleSheet.create({
    container: {
      flex: 1,
      alignItems: position === 'left' ? 'flex-start' : 'flex-end',
      marginHorizontal: uh.h2DP(8),
      alignSelf: 'flex-end'
    },
    bubbleContainer: {
      flexDirection: 'row',
      alignItems: 'flex-end',
      maxWidth: '70%'
    },
    textBubble: {
      paddingHorizontal: message.image || message.video || message.audio ? 0 : uh.h2DP(16),
      paddingVertical: message.image || message.video || message.audio ? 0 : uh.h2DP(9)
    },
    leftBubble: {
      backgroundColor:
        message.image || message.video || message.audio
          ? 'transparent'
          : isLightTheme
          ? th['color-basic-300']
          : th['color-basic-100'],
      borderTopLeftRadius: !isSamePreviousDay || !isSamePreviousUser ? 16 : 4,
      borderTopRightRadius: 16,
      borderBottomRightRadius: 16,
      borderBottomLeftRadius: !isSameNextUser || !isSameNextDay ? 16 : 4
    },
    leftBubbleText: {
      color: isLightTheme ? th['text-basic-color'] : th['text-alternate-color']
    },
    rightBubble: {
      backgroundColor: message.image || message.video || message.audio ? 'transparent' : th['color-primary-500'],
      borderTopLeftRadius: 16,
      borderTopRightRadius: !isSamePreviousDay || !isSamePreviousUser ? 16 : 4,
      borderBottomRightRadius: !isSameNextUser || !isSameNextDay ? 16 : 4,
      borderBottomLeftRadius: 16
    },
    rightBubbleText: {
      color: isLightTheme ? th['text-alternate-color'] : th['text-basic-color']
    },
    leftAvatarContainer: {
      marginRight: uh.h2DP(8)
    },
    rightAvatarContainer: {
      marginLeft: uh.h2DP(8)
    },
    avatar: {
      height: uh.h2DP(32),
      width: uh.h2DP(32)
    },
    timeContainer: { marginTop: uh.h2DP(4), paddingLeft: position === 'left' ? uh.h2DP(36) : undefined },
    videoPlaceholder: {
      height: uh.h2DP(96),
      width: uh.h2DP(96),
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: th['color-basic-transparent-900']
    },
    reactionContainer: {
      position: 'absolute',
      bottom: 0,
      transform: [{ translateY: 12 }],
      left: position === 'left' ? undefined : uh.h2DP(12),
      right: position === 'right' ? undefined : uh.h2DP(12),
      height: uh.h2DP(24),
      width: uh.h2DP(24),
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: th['color-primary-500'],
      borderRadius: uh.height()
    },
    reactionSelectContainer: {
      position: 'absolute',
      flexDirection: 'row',
      backgroundColor: th['color-basic-100'],
      borderRadius: uh.height(),
      paddingVertical: uh.h2DP(8),
      paddingHorizontal: uh.h2DP(12),
      marginBottom: uh.h2DP(4),
      right: position === 'right' ? 0 : undefined,
      alignSelf: 'center',
      alignItems: 'center',
      gap: uh.h2DP(12),
      borderWidth: 1,
      borderColor: th['border-basic-color-4']
    }
  });

  const handleEmojiPress = (emoji: string) => {
    message.reaction = emoji;
    setVisibleEmojiPicker(false);
    setPressedMessageId('');
  };

  return (
    <Fragment key={message._id}>
      <TouchableWithoutFeedback onPress={() => setPressedMessageId('')}>
        <View style={styleContainer.container}>
          <View style={styleContainer.bubbleContainer}>
            {showAvatar && (
              <View style={styleContainer[`${position}AvatarContainer`]}>
                <GiftedAvatar user={message.user} avatarStyle={styleContainer.avatar} />
              </View>
            )}
            <View>
              <TouchableWithoutFeedback onLongPress={() => setPressedMessageId(message._id.toString())}>
                <View style={[styleContainer.textBubble, styleContainer[`${position}Bubble`]]}>
                  {message.image ? (
                    <ChatImageMessage onLongPress={setPressedMessageId} message={message} />
                  ) : message.audio ? (
                    <ChatAudioMessage message={message} />
                  ) : message.video ? (
                    <ChatVideoMessage message={message} />
                  ) : (
                    <Text style={styleContainer[`${position}BubbleText`]}>{message.text}</Text>
                  )}
                </View>
              </TouchableWithoutFeedback>
              {message.reaction && (
                <View style={styleContainer.reactionContainer}>
                  <Text>{message.reaction}</Text>
                </View>
              )}
            </View>
            {isPressed && (
              <View style={styleContainer.reactionSelectContainer}>
                {BASE_EMOJIS.map((emoji: string, index: number) => (
                  <TouchableOpacity onPress={() => handleEmojiPress(emoji)} key={index}>
                    <Text style={{ fontSize: uh.h2DP(30) }}>{emoji}</Text>
                  </TouchableOpacity>
                ))}
                <TouchableOpacity onPress={() => setVisibleEmojiPicker(true)}>
                  <Icon
                    height={uh.h2DP(30)}
                    width={uh.h2DP(30)}
                    fill={th['color-basic-600']}
                    name="plus-circle-outline"
                  />
                </TouchableOpacity>
              </View>
            )}
          </View>
          {showTime && <Time currentMessage={message} containerStyle={{ left: styleContainer.timeContainer }} />}
        </View>
      </TouchableWithoutFeedback>
      {visibleEmojiPicker && (
        <EmojiPicker
          onEmojiSelected={({ emoji }) => handleEmojiPress(emoji)}
          expandable={false}
          open={visibleEmojiPicker}
          onClose={() => setVisibleEmojiPicker(false)}
          theme={{
            container: th['color-basic-100'],
            category: {
              container: th['color-basic-100'],
              icon: th['color-basic-500'],
              iconActive: th['color-primary-500']
            }
          }}
          styles={{
            category: {
              container: {
                borderWidth: 1,
                borderColor: th['border-basic-color-4']
              }
            }
          }}
        />
      )}
    </Fragment>
  );
};

export default ChatBubble;
