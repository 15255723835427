import { ActionScreenNames, UIHelper as uh } from '../../../core';
import { Icon, Text, useTheme } from '@ui-kitten/components';
import { StyleSheet, TouchableOpacity } from 'react-native';

import React from 'react';
import { useAppStore } from 'src/core/store';
import { useNavigation } from '@react-navigation/native';

interface ActionHeaderLeftProps {
  backScreenName: ActionScreenNames;
}

export const ActionScreenBackName = new Map<ActionScreenNames, string>([
  ['TodayActions', 'Today'],
  ['ActionsScheduleActive', 'Active'],
  ['ActionsScheduleInactive', 'Inactive']
]);

// styles
const styleContainer = StyleSheet.create({
  backNavContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginLeft: 16
  },
  backIcon: { width: 24, height: 24 },
  backScreenName: { paddingTop: 2 }
});

const ActionHeaderLeft = ({ backScreenName }: ActionHeaderLeftProps) => {
  const navigation = useNavigation();
  const th = useTheme();
  const theme = useAppStore((state) => state.theme);

  const condColors = {
    header: uh.getHex(th, theme, 'color-basic-100', 'color-basic-1100'),
    headerLeftTint: uh.getHex(th, theme, 'color-basic-900', 'color-basic-100'),
    iconTint: th['text-basic-color']
  };

  return (
    <TouchableOpacity
      style={styleContainer.backNavContainer}
      onPress={() => {
        if (!backScreenName) {
          navigation.navigate('Home');
        } else {
          navigation.navigate('Actions', { screen: backScreenName });
        }
      }}
      accessible={true}
      accessibilityLabel={'left_header_button'}
      testID={'left_header_return_btn'}>
      <Icon name="arrow-ios-back-outline" fill={condColors.iconTint} style={styleContainer.backIcon} />
      <Text status="primary" category="s2" style={styleContainer.backScreenName}>
        {ActionScreenBackName.get(backScreenName)}
      </Text>
    </TouchableOpacity>
  );
};

export default ActionHeaderLeft;
