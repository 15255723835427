import { Text, useTheme } from '@ui-kitten/components';
import React from 'react';
import { ViewProps, StyleSheet, View } from 'react-native';
import { ActionTip, UIHelper as uh } from '../../core';
import ActionTipItem from './conponents/ActionTipItem';

//props
interface ScoreTipsProps extends ViewProps {
  actionTips: Array<ActionTip>;
  caption: string;
  pillar: string;
  onCreateAction: (actionId: number) => void;
}

// styles
const styleContainer = StyleSheet.create({
  container: {
    marginTop: uh.h2DP(8)
  },
  list: {
    flexDirection: 'column',
    marginTop: 8,
    rowGap: 12,
    flex: 1
  }
});

const ScoreTips = ({ actionTips, caption, pillar, onCreateAction }: ScoreTipsProps) => {
  const th = useTheme();

  if (actionTips.length == 0) {
    return <></>;
  }
  // view
  return (
    <View style={styleContainer.container}>
      <Text category="label" style={{ color: th['text-hint-color'] }}>
        {caption}
      </Text>
      <View style={styleContainer.list}>
        {actionTips.map((actionTip) => (
          <ActionTipItem actionTip={actionTip} pillar={pillar} onCreateAction={onCreateAction} key={actionTip.id} />
        ))}
      </View>
    </View>
  );
};

export default ScoreTips;
