import { FlatList, StyleSheet, View, ViewProps } from 'react-native';
import React, { useMemo } from 'react';

import { AssessmentBiomarker } from 'src/components/profileMeAssessment';
import { Dropdown } from 'src/components/profileMeMarker';
import { Input } from '@ui-kitten/components';
import { NewHealthMarker } from 'src/core/types/HealthMarkerReport';
import PillarButtonList from '../../components/shared/PillarButtonList';
import RightIcon from './components/RightIcon';
import { SortedType } from 'src/core/types/Marker';
import { useFilterMarkers } from 'src/core/hooks/useFilterMarkers';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';

//props
interface MarkerListProps extends ViewProps {
  markers: NewHealthMarker[];
  currentPageNumber: number;
  style?: any;
}

const styleContainer = StyleSheet.create({
  searchText: { height: 40, flex: 1 },
  searchArea: {
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 8,
    marginLeft: 16,
    marginRight: 16,
    marginTop: 16
  },
  markerItem: { borderRadius: 8, marginTop: 16 }
});

const MarkerList = ({ markers, currentPageNumber, style }: MarkerListProps) => {
  const { filterData } = useFilterMarkers();
  const transMessage = {
    searchPlaceHorder: 'Search'
  };
  const t = useTranslationFunc(transMessage);
  const [pageNumber, setPageNumber] = React.useState(currentPageNumber);
  const [selectedPillar, setSelectedPillar] = React.useState('');
  const [searchText, setSearchText] = React.useState('');
  const [sortedType, setSortedType] = React.useState(SortedType.MOST_RECENT);
  const RECORDS_PER_PAGE = 10;
  const pillars = Array.from(new Set(markers.map((marker) => marker.category)));

  const numberOfItems = (pageNumber + 1) * RECORDS_PER_PAGE;
  let filteredMarkers = filterData(
    markers,
    searchText,
    sortedType,
    selectedPillar !== '' && selectedPillar !== 'all' ? [selectedPillar] : []
  );
  filteredMarkers = filteredMarkers.slice(0, numberOfItems);

  const pillarBtnClick = (pillarName: string) => {
    setSelectedPillar(pillarName.trim());
  };

  const changeSortedType = (type: number) => {
    setSortedType(type);
  };

  const listHeader = (
    <>
      <PillarButtonList onBtnClickHandler={pillarBtnClick} style={{ marginTop: 16 }} shownPillars={pillars} />
      <View style={styleContainer.searchArea}>
        <Dropdown changeSortedType={changeSortedType} />
        <Input
          style={styleContainer.searchText}
          value={searchText}
          placeholder={t('searchPlaceHorder')}
          accessoryRight={
            <RightIcon
              isSearching={searchText.length > 0 ? true : false}
              onClick={() => {
                setSearchText('');
              }}
            />
          }
          onChangeText={(nextValue) => setSearchText(nextValue)}
          accessible={true}
          accessibilityLabel={'my markers searchbar'}
          testID={'my_markers_searchbar'}
        />
      </View>
    </>
  );

  const renderBiomarker = ({ item, index }: any) => {
    return (
      <AssessmentBiomarker
        key={`biomarker-item-${index}`}
        biomarker={item}
        style={styleContainer.markerItem}
        accessibilityLabel={`Biomarker ${index}`}
        testID={`biomarker-${index}`}
      />
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedBiomarker = useMemo(() => renderBiomarker, [markers]);

  // view
  return (
    <>
      {listHeader}
      <FlatList
        style={style}
        data={filteredMarkers}
        renderItem={memoizedBiomarker}
        onEndReached={() => {
          setPageNumber((page) => page + 1);
        }}
        onEndReachedThreshold={0}></FlatList>
    </>
  );
};

export default React.memo(MarkerList);
