import { ScoreItem } from 'src/core';
import { ActionTip } from 'src/core/types/Action';
import { HealthMarkerHistoryValue } from 'src/core/types/HealthMarkerHistory';
import { ScoreDriver } from 'src/core/types/Pillar';
import { ScoreCategory } from 'src/core/types/Scores';
import { StateCreator } from 'zustand';

export interface ScoreSlice {
  scoreCategories: ScoreCategory[];
  pillarScoreHistories: ScoreItem[];
  pillarScoreDrivers: ScoreDriver[];
  pillarActionTips: ActionTip[];
  markerScoreHistories: HealthMarkerHistoryValue[];
  setScoreGategories: (scoreCategories: ScoreCategory[]) => void;
  setPillarScoreHistories: (pillarScoreHistories: ScoreItem[]) => void;
  setPillarScoreDrivers: (pillarScoreDrivers: ScoreDriver[]) => void;
  setPillarActionTips: (pillarActionTips: ActionTip[]) => void;
  setMarkerScoreHistories: (markerScoreHistories: HealthMarkerHistoryValue[]) => void;
}

export const createScoreSlice: StateCreator<ScoreSlice, [], [], ScoreSlice> = (set) => ({
  scoreCategories: [],
  pillarScoreHistories: [],
  pillarScoreDrivers: [],
  pillarActionTips: [],
  markerScoreHistories: [],
  setScoreGategories: (scoreCategories: ScoreCategory[]) => set(() => ({ scoreCategories })),
  setPillarScoreHistories: (pillarScoreHistories: ScoreItem[]) => set(() => ({ pillarScoreHistories })),
  setPillarScoreDrivers: (pillarScoreDrivers: ScoreDriver[]) => set(() => ({ pillarScoreDrivers })),
  setPillarActionTips: (pillarActionTips: ActionTip[]) => set(() => ({ pillarActionTips })),
  setMarkerScoreHistories: (markerScoreHistories: HealthMarkerHistoryValue[]) => set(() => ({ markerScoreHistories }))
});
