import Svg, { G, Path, Rect } from 'react-native-svg';

import React from 'react';

const DownwardIcon = ({ size = 24, color = '#FFFFFF' }) => (
  <Svg width={`${size}px`} height={`${size}px`} viewBox="0 0 24 24">
    <G data-name="Layer 2">
      <G data-name="arrow-downward">
        <Rect width={size} height={size} opacity="0" />
        <Path
          fill={color}
          d="M12 16a1 1 0 0 1-.64-.23l-6-5a1 1 0 1 1 1.28-1.54L12 13.71l5.36-4.32a1 1 0 0 1 1.41.15 1 1 0 0 1-.14 1.46l-6 4.83A1 1 0 0 1 12 16z"
        />
      </G>
    </G>
  </Svg>
);

export default DownwardIcon;
