import { CheckBox, Icon, Layout, Text, useTheme } from '@ui-kitten/components';
import React, { useState } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import { ActionItem } from 'src/core/types/ActionItem';
import AlarmIcon from './components/AlarmIcon';
import { AnimatedCircularProgress } from 'react-native-circular-progress';
import DownwardIcon from './components/DownwardIcon';
import EditIcon from './components/EditIcon';
import { MarkdownText } from 'src/components/kalichat';
import { PillarContainer } from 'src/components/home';
import UpwardIcon from './components/UpwardIcon';
import moment from 'moment';
import { UIHelper as uh } from 'src/core';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';

export interface BasicActionProps {
  isFinished: boolean;
  isEditable: boolean;
  showButtonsWhenCollapsed?: boolean;
  onChange?: (id: number, checked: boolean) => void;
  onActivate?: (action: ActionItem) => void;
  onDeactivate?: (action: ActionItem) => void;
  onRecurrence?: (action: ActionItem) => void;
}

export interface ActionProps extends BasicActionProps {
  data: ActionItem[];
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: 15,
    width: '100%'
  },
  pillarContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  icon: { height: 22, width: 22 },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 5
  },
  card: {
    borderRadius: 16,
    padding: 15,
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  details: {
    flex: 1,
    display: 'flex',
    gap: 10
  },
  completedContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    marginTop: 5
  },
  singleCompletedWrapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    alignSelf: 'flex-start',
    padding: 8,
    borderRadius: 16,
    marginVertical: -4
  },
  singleCompletedChecked: {
    backgroundColor: 'rgb(226, 250, 238)'
  },
  singleCompletedUnchecked: {
    backgroundColor: 'rgb(238, 241, 246)'
  },
  singleCompletedText: {
    color: 'rgb(36, 43, 67)'
  },
  lineContainer: {
    flex: 1,
    display: 'flex',
    gap: uh.h2DP(6)
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 15
  },
  btn: {
    display: 'flex',
    flexDirection: 'row',
    gap: 5
  },
  recurrenceContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
    alignItems: 'center'
  },
  recurrenceItem: { flexDirection: 'row', gap: uh.h2DP(4) }
});

const Action = ({
  data,
  isFinished,
  isEditable,
  showButtonsWhenCollapsed = false,
  onChange,
  onActivate,
  onDeactivate,
  onRecurrence
}: ActionProps) => {
  const [extended, setExtended] = useState<boolean>(false);
  const th = useTheme();

  const markdownStyles = StyleSheet.create({
    // The main container
    view: {
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'center',
      fontFamily: 'Poppins-Regular',
      color: th['text-basic-color'],
      fontSize: 14
    },
    // Links
    autolink: {
      fontSize: 14
    },
    // Text Output
    text: {
      color: th['text-basic-color'],
      fontSize: 14
    }
  });

  const transMessage = {
    actionsAssignedBy: 'ASSIGNED BY',
    actionsRecurrence: 'RECURRENCE',
    actionsTodayProgress: "TODAY'S PROGRESS",
    actionsEndingOn: 'Ending on',
    actionsCompletedOf: '{{done}} of {{total}} Actions completed since {{date}}',
    actionsCompletedActions: '{{done}} of {{total}} Completed',
    actionsCheckMarkCompleted: 'Mark as Complete',
    actionsCheckCompleted: 'Completed',
    actionsDeactivateBtn: 'Deactivate',
    actionsActivateBtn: 'Activate',
    actionsRecurrenceBtn: 'Edit',
    actionsProtocol: 'PROTOCOL',
    actionsStartingOn: 'Starting on',
    actionsEndDay: 'End day',
    actionsFrequency: 'Frequency',
    actionsDuration: 'Duration',
    frequency0: 'One-time',
    frequency1: 'Daily',
    frequency7: 'Weekly',
    frequency30: 'Monthly',
    frequency90: 'Quarterly',
    frequency180: 'Half Yearly',
    frequency365: 'Yearly',
    frequencyCustom: 'Every {{frequency}} days'
  };

  const t = useTranslationFunc(transMessage);

  if (data.length === 0) {
    return <></>;
  }

  const actionItem: ActionItem = data[0];
  const pillarType = actionItem.pillar.toLowerCase();
  const percentage = Math.floor((actionItem.doneActions / actionItem.totalActions) * 100);
  const ExpandIcon = extended ? UpwardIcon : DownwardIcon;
  const actionDuration = moment(actionItem.endDate).diff(moment(actionItem.startDate), 'days');
  const frequencies = new Map([
    [0, 'frequency0'],
    [1, 'frequency1'],
    [7, 'frequency7'],
    [30, 'frequency30'],
    [90, 'frequency90'],
    [180, 'frequency180'],
    [365, 'frequency365']
  ]);

  const toggleExtended = () => {
    setExtended((prev) => !prev);
  };

  const now = moment(new Date());

  const isCompletedCheckboxEnable = (dt: string): boolean => {
    return now.isAfter(moment(dt).subtract(2, 'hours'));
  };

  return (
    <Layout
      style={[styles.card, actionItem.active && { borderColor: th['color-primary-500'], borderWidth: 1 }]}
      level="1">
      <View style={styles.container}>
        {!isFinished && (
          <AnimatedCircularProgress
            size={48}
            width={4}
            backgroundWidth={1}
            fill={percentage}
            rotation={0}
            lineCap="round"
            tintColor={th['color-' + pillarType + '-500']}
            backgroundColor={th['color-basic-default-border']}>
            {() => <Text style={{ fontSize: 14, color: th['text-basic-color'] }}>{`${percentage}%`}</Text>}
          </AnimatedCircularProgress>
        )}
        <View style={styles.details}>
          <View style={styles.header}>
            <Text category="h6" style={{ flexShrink: 1 }}>
              {actionItem.name}
            </Text>
            <TouchableOpacity onPress={toggleExtended}>
              <ExpandIcon size={24} color={th['text-basic-color']} />
            </TouchableOpacity>
          </View>
          {extended && <MarkdownText styles={markdownStyles}>{actionItem.text}</MarkdownText>}
          <View style={styles.pillarContainer}>
            <PillarContainer name={actionItem.pillar} type={pillarType} size="small" />
          </View>
          {extended && (
            <>
              <View style={styles.lineContainer}>
                <Text category="p2" appearance="hint" style={{ fontSize: 14 }}>
                  {t('actionsAssignedBy')}
                </Text>
                <Text category="p2" style={{ fontSize: 14, fontFamily: 'Poppins-SemiBold' }}>
                  {actionItem.author}
                </Text>
              </View>
              {actionItem.protocolName && (
                <View style={styles.lineContainer}>
                  <Text category="p2" appearance="hint" style={{ fontSize: 14 }}>
                    {t('actionsProtocol')}
                  </Text>
                  <Text category="p2" style={{ fontSize: 14, fontFamily: 'Poppins-SemiBold' }}>
                    {actionItem.protocolName}
                  </Text>
                </View>
              )}
              <View style={styles.lineContainer}>
                <View style={styles.recurrenceContainer}>
                  <Text category="p2" appearance="hint">
                    {t('actionsRecurrence')}
                  </Text>
                  {onRecurrence && !actionItem.protocolId && (
                    <TouchableOpacity onPress={() => onRecurrence(actionItem)}>
                      <Text category="s2" appearance="hint">
                        (edit)
                      </Text>
                    </TouchableOpacity>
                  )}
                </View>
                <View style={styles.recurrenceItem}>
                  <Text style={{ width: '40%' }} category="s2">
                    {t('actionsFrequency')}
                  </Text>
                  <Text category="p2">
                    {t(frequencies.get(actionItem.repeatFrequencyDays) || 'frequencyCustom', {
                      frequency: actionItem.repeatFrequencyDays
                    })}
                  </Text>
                </View>
                <View style={styles.recurrenceItem}>
                  <Text style={{ width: '40%' }} category="s2">
                    {t('actionsDuration')}
                  </Text>
                  <Text category="p2">{`${actionDuration} ${actionDuration === 1 ? 'Day' : 'Days'}`}</Text>
                </View>
                <View style={styles.recurrenceItem}>
                  <Text style={{ width: '40%' }} category="s2">
                    {t('actionsStartingOn')}
                  </Text>
                  <Text category="p2">{moment(actionItem.startDate).format('DD/MM/YYYY')}</Text>
                </View>
                <View style={styles.recurrenceItem}>
                  <Text style={{ width: '40%' }} category="s2">
                    {t('actionsEndingOn')}
                  </Text>
                  <Text category="p2">{moment(actionItem.endDate).format('DD/MM/YYYY')}</Text>
                </View>
                <Text category="p2">
                  {t('actionsCompletedOf', {
                    date: moment(actionItem.startDate).format('DD/MM/YYYY'),
                    done: actionItem.doneActions.toString(),
                    total: actionItem.totalActions.toString()
                  })}
                </Text>
              </View>
            </>
          )}
          <View style={styles.lineContainer}>
            {extended && !isFinished && data.length > 1 && (
              <Text category="p2" appearance="hint" style={{ fontSize: 14 }}>
                {t('actionsTodayProgress')}
              </Text>
            )}
            {!isFinished && isEditable && onChange && (
              <>
                {data.length > 1 ? (
                  <>
                    <Text category="p2" style={{ fontFamily: 'Poppins-SemiBold' }}>
                      {t('actionsCompletedActions', {
                        done: data.reduce((prev, partialAction) => prev + (partialAction.completed ? 1 : 0), 0),
                        total: data.length
                      })}
                    </Text>
                    <View style={styles.completedContainer}>
                      {data.map((partialAction) => (
                        <CheckBox
                          disabled={!isCompletedCheckboxEnable(partialAction.when)}
                          key={partialAction.id}
                          checked={partialAction.completed}
                          onChange={(nextChecked) => onChange(partialAction.id, nextChecked)}
                        />
                      ))}
                    </View>
                  </>
                ) : (
                  <View
                    style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <View
                      style={[
                        styles.singleCompletedWrapper,
                        actionItem.completed ? styles.singleCompletedChecked : styles.singleCompletedUnchecked
                      ]}>
                      <CheckBox
                        disabled={!isCompletedCheckboxEnable(actionItem.when)}
                        checked={actionItem.completed}
                        onChange={(nextChecked) => onChange(actionItem.id, nextChecked)}
                      />
                      <Text category="p2" style={styles.singleCompletedText}>
                        {t(actionItem.completed ? 'actionsCheckCompleted' : 'actionsCheckMarkCompleted')}
                      </Text>
                    </View>
                    {onDeactivate && (showButtonsWhenCollapsed || extended) && actionItem.protocolId && (
                      <TouchableOpacity onPress={() => onDeactivate(actionItem)}>
                        <View style={[styles.btn, { marginHorizontal: 16 }]}>
                          <Icon name={'trash-2-outline'} width={24} height={24} fill={th['color-danger-500']} />
                        </View>
                      </TouchableOpacity>
                    )}
                  </View>
                )}
              </>
            )}
          </View>
          {(showButtonsWhenCollapsed || extended) && (
            <View style={styles.btnContainer}>
              {onActivate && (
                <TouchableOpacity onPress={() => onActivate(actionItem)}>
                  <View style={styles.btn}>
                    <AlarmIcon size={24} color={th['color-primary-500']} />
                    <Text style={{ color: th['color-primary-500'] }}>{t('actionsActivateBtn')}</Text>
                  </View>
                </TouchableOpacity>
              )}
              {!extended && !actionItem.protocolId && onRecurrence && (
                <TouchableOpacity onPress={() => onRecurrence(actionItem)}>
                  <View style={styles.btn}>
                    <EditIcon size={24} color={th['color-info-500']} />
                    <Text style={{ color: th['color-info-500'] }}>{t('actionsRecurrenceBtn')}</Text>
                  </View>
                </TouchableOpacity>
              )}
            </View>
          )}
        </View>
      </View>
    </Layout>
  );
};

export default Action;
