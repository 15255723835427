import React from 'react';
import { ITenantSharedProps } from '../TenantSharedProps';
import { TenantModal } from './TenantModal';

export const AcceptedTenant = (props: ITenantSharedProps) => {
  const title = 'Yay! You are now connected.';

  const message = `We're glad you accepted ${props.proUserName}'s connection request. They can now view your wellness data in real-time and guide you better`;
  return (
    <TenantModal
      visible={props.visible}
      title={title}
      message={message}
      icon={'checkmark-circle-2-outline'}
      primaryButtonText={'Close'}
      onPrimaryButton={props.onFinalButtonClick}
      visibleSecondaryButton={false}
      testingAttributes={props.testingAttributes}
    />
  );
};
