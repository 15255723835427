import React, { RefObject } from 'react';
import { ScrollView, StyleSheet, View, ViewProps } from 'react-native';

import AssessmentListSectionItem from './AssessmentListSectionItem';
import { CoachInfo } from 'src/core/types/CoachInfo';
import { HealthMarkerGroup } from 'src/core/types/HealthMarkerReport';

//props
interface AssessmentListSectionProps extends ViewProps {
  btnDetailAssessmentClick: (assessmentId: string) => void;
  sections: HealthMarkerGroup[];
  coach: CoachInfo;
  scrollRef: RefObject<ScrollView>;
}

const AssessmentListSection = ({
  btnDetailAssessmentClick,
  sections,
  coach,
  scrollRef
}: AssessmentListSectionProps) => {
  const styleContainer = StyleSheet.create({
    container: {
      borderRadius: 8
    }
  });

  const renderSections = () => {
    return sections.map((section, index) => {
      return (
        <AssessmentListSectionItem
          btnDetailAssessmentClick={btnDetailAssessmentClick}
          key={`ListSectionItem-${index}`}
          section={section}
          coach={coach}
          scrollRef={scrollRef}
        />
      );
    });
  };

  return <View style={styleContainer.container}>{renderSections()}</View>;
};

export default AssessmentListSection;
