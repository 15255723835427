import { Icon, Text, useTheme } from '@ui-kitten/components';
import { StyleSheet, View, ViewProps } from 'react-native';

import Brands from 'brands.json';
import DonutChart from 'src/components/shared/DonutChart';
import { PillarDynamicCard } from 'src/screens/main/HomeScreen';
import React from 'react';
import { ScoreCategory } from 'src/core/types/Scores';
import { UIHelper as uh } from '../../../core';

//props
interface ScoreDialProps extends ViewProps {
  pillarScores: ScoreCategory[];
  btnClickHandler: (item: ScoreCategory) => void;
  circumference?: number;
  baseRadius?: number;
  chartHeight?: number;
  chartWidth?: number;
  positionX?: number;
  positionY?: number;
  selectedPillar: PillarDynamicCard;
  setSelectedPillar: (selectedPillar: PillarDynamicCard) => void;
}

const ScoreDial = ({
  pillarScores,
  circumference = 60,
  baseRadius = 50,
  chartHeight = 250,
  chartWidth = 250,
  positionX = chartHeight / 2,
  positionY = chartWidth / 2,
  selectedPillar,
  setSelectedPillar
}: ScoreDialProps) => {
  const th = useTheme();
  const stylesContainer = StyleSheet.create({
    container: {
      alignItems: 'center'
    },
    behind: {
      position: 'absolute',
      left: 0,
      top: 0,
      zIndex: 99
    },
    chart: {
      height: chartHeight,
      width: chartWidth
    },
    kalibraScore: { position: 'absolute', alignSelf: 'center', justifyContent: 'center', top: '42%' },
    kalibraScoreValue: { paddingLeft: uh.h2DP(6), top: '48%' },
    accuracy: { marginTop: uh.h2DP(14) },
    accuracyIcon: { width: uh.h2DP(16), height: uh.h2DP(16), marginRight: uh.w2DP(4) },
    centerLabelContainer: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center'
    }
  });

  const defaultPillar = pillarScores[0];
  const order = ['connect', 'reflect', 'rest', 'grow', 'move', 'nourish'];
  const sixPillars = pillarScores
    .slice(1)
    .sort((a, b) => (order.indexOf(a.externalKey) > order.indexOf(b.externalKey) ? 1 : -1));

  // Sets the base value for the
  const baseData = sixPillars.map((item: ScoreCategory) => {
    return {
      key: item.externalKey,
      fill: th[
        'color-' +
          item.externalKey +
          (item.externalKey === selectedPillar.externalKey ? '-300' : '-200') +
          '-transparent'
      ],
      stroke:
        item.externalKey === selectedPillar.externalKey ? th['color-' + item.externalKey + '-500'] : 'transparent',
      innerRadius: baseRadius,
      outerRadius: baseRadius + circumference,
      onPress: () => {
        if (item.externalKey === selectedPillar.externalKey) {
          setSelectedPillar({
            displayName: defaultPillar.displayName,
            externalKey: defaultPillar.externalKey,
            score: defaultPillar.score,
            accuracy: defaultPillar.nominalTotalAccuracy,
            default: true
          });
        } else {
          setSelectedPillar({
            displayName: item.displayName,
            externalKey: item.externalKey,
            score: item.score,
            accuracy: item.nominalTotalAccuracy
          });
        }
      }
    };
  });

  // defines the user data
  const userScoreData = sixPillars.map((item: ScoreCategory) => {
    return {
      key: item.externalKey,
      fill: th['color-' + item.externalKey + '-500'],
      innerRadius: baseRadius,
      outerRadius: baseRadius + item.score * (circumference / 100)
    };
  });

  return (
    <View style={[stylesContainer.container]}>
      <View>
        <View accessible={true} accessibilityLabel="score radial" testID="score-radial">
          <DonutChart
            height={chartHeight}
            width={chartWidth}
            xPosition={positionX}
            yPosition={positionY}
            baseData={baseData}
            data={userScoreData}
            circumference={circumference}>
            <View style={stylesContainer.centerLabelContainer}>
              <Text
                appearance="hint"
                category="label"
                style={{
                  textTransform: 'capitalize'
                }}>
                {selectedPillar.displayName} Score
              </Text>
              <Text
                category="h4"
                style={[
                  {
                    color:
                      selectedPillar.displayName === defaultPillar.displayName
                        ? th['text-basic-color']
                        : th['color-' + selectedPillar.externalKey + '-600']
                  }
                ]}>
                {selectedPillar.score || 0}
                <Text style={{ color: th['color-' + selectedPillar.externalKey + '-600'] }} category="h6">
                  %
                </Text>
              </Text>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <Icon name="loading" pack={Brands.icon_pack} style={stylesContainer.accuracyIcon}></Icon>
                <Text category="label" appearance="hint">
                  {Math.floor(selectedPillar.accuracy) || 0}%
                </Text>
              </View>
            </View>
          </DonutChart>
        </View>
      </View>
    </View>
  );
};

export default ScoreDial;
