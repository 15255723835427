import { Text } from '@ui-kitten/components';
import React from 'react';
import { ViewProps, StyleSheet, View } from 'react-native';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';

//props
interface TotalDurationProps extends ViewProps {
  time: string;
}

// styles
const styleContainer = StyleSheet.create({
  container: {
    justifyContent: 'flex-start'
  },
  time: {
    color: '#5F93F9'
  }
});

const TotalDuration = ({ time }: TotalDurationProps) => {
  const transMessage = {
    totalDuration: 'Total Duration'
  };
  const t = useTranslationFunc(transMessage);

  // view
  return (
    <View>
      <View style={styleContainer.container}>
        <Text category="s2">{t('totalDuration')}</Text>
        <Text category="h4" style={styleContainer.time}>
          {time}
        </Text>
      </View>
    </View>
  );
};

export default TotalDuration;
