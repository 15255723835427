import { ApplicationState, useAppStore } from '../store';
import { useCallback, useEffect } from 'react';

import { Config } from '../constants/Config';
import { Platform } from 'react-native';
import ReactNativeOneSignal from 'react-native-onesignal';
import { useAnalytics } from './useAnalytics';
import { useGetUserId } from './useUser';

declare global {
  interface Window {
    OneSignal: any;
  }
}

if (Platform.OS === 'web') {
  window.OneSignal = window.OneSignal || [];
}

interface UseNotifications {
  isWebPushNotificationsEnabled: () => Promise<boolean>;
  isPushNotificationsEnabled: () => Promise<boolean>;
  setWebPushExternalUserId: () => Promise<void>;
  setExternalUserId: () => Promise<void>;
  removeWebPushExternalUserId: () => Promise<void>;
  removeExternalUserId: () => Promise<void>;
  isWebPushNotificationsSupported: () => Promise<boolean>;
  isPushNotificationsSupported: () => Promise<boolean>;
  setWebPushSubscription: (unmute: boolean) => Promise<void>;
  setSubscription: (unmute: boolean) => Promise<void>;
  initializeOneSignalWeb: () => Promise<void>;
  initializeOneSignal: () => Promise<void>;
}

export const useNotifications = (): UseNotifications => {
  const userId = useGetUserId();
  const { addAnalyticsLog } = useAnalytics('useOneSignal.ts');

  const isWebPushNotificationsEnabled = useCallback(async (): Promise<boolean> => {
    try {
      if (Platform.OS === 'web') {
        return false;
      }

      return window.OneSignal.isPushNotificationsEnabled().then((isEnabled: boolean) => {
        return isEnabled;
      });
    } catch (error) {
      addAnalyticsLog({ function: 'isWebPushNotificationsEnabled', data: error, logType: 'error ' });
    }
    return false;
  }, [addAnalyticsLog]);

  const isPushNotificationsEnabled = async (): Promise<boolean> => {
    if (Platform.OS === 'web') {
      const enabled: boolean = await isWebPushNotificationsEnabled();
      return enabled;
    } else {
      try {
        const deviceState = await ReactNativeOneSignal.getDeviceState();
        return !(deviceState != null && deviceState.isPushDisabled);
      } catch (error) {
        addAnalyticsLog({ function: 'isPushNotificationsEnabled', data: error, logType: 'error ' });
      }
    }

    return false;
  };
  const setWebPushExternalUserId = useCallback(async (): Promise<void> => {
    try {
      const enabled: boolean = await isWebPushNotificationsEnabled();
      if (enabled) {
        if (userId) {
          window.OneSignal.push(() => {
            window.OneSignal.setExternalUserId(userId);
          });
        }
      }
    } catch (error) {
      addAnalyticsLog({ function: 'setWebPushExternalUserId', data: error, logType: 'error ' });
    }
  }, [addAnalyticsLog, isWebPushNotificationsEnabled, userId]);

  const setExternalUserId = async (): Promise<void> => {
    if (Platform.OS === 'web') {
      await setWebPushExternalUserId();
    } else {
      try {
        const enabled: boolean = await isPushNotificationsEnabled();
        if (enabled) {
          if (userId) {
            ReactNativeOneSignal.setExternalUserId(userId);
          }
        }
      } catch (error) {
        addAnalyticsLog({ function: 'setExternalUserId', data: error, logType: 'error ' });
      }
    }
  };

  const removeWebPushExternalUserId = useCallback(async (): Promise<void> => {
    try {
      window.OneSignal.push(() => {
        window.OneSignal.removeExternalUserId();
      });
    } catch (error) {
      addAnalyticsLog({ function: 'removeWebPushExternalUserId', data: error, logType: 'error ' });
    }
  }, [addAnalyticsLog]);

  const removeExternalUserId = useCallback(async (): Promise<void> => {
    if (Platform.OS === 'web') {
      await removeWebPushExternalUserId();
    } else {
      try {
        ReactNativeOneSignal.removeExternalUserId();
      } catch (error) {
        addAnalyticsLog({ function: 'removeExternalUserId', data: error, logType: 'error ' });
      }
    }
  }, [addAnalyticsLog, removeWebPushExternalUserId]);

  const isWebPushNotificationsSupported = async (): Promise<boolean> => {
    try {
      if (Platform.OS === 'web') {
        return window.OneSignal.isPushNotificationsSupported();
      }
    } catch (error) {
      addAnalyticsLog({ function: 'isWebPushNotificationsSupported', data: error, logType: 'error ' });
    }

    return false;
  };

  const isPushNotificationsSupported = async (): Promise<boolean> => {
    if (Platform.OS === 'web') {
      const supported: boolean = await isWebPushNotificationsSupported();
      return supported;
    } else {
      try {
        const deviceState = await ReactNativeOneSignal.getDeviceState();
        if (deviceState != null && deviceState.hasNotificationPermission === undefined) {
          return false;
        }
        return deviceState != null && deviceState.hasNotificationPermission == true;
      } catch (error) {
        addAnalyticsLog({ function: 'isPushNotificationsSupported', data: error, logType: 'error ' });
      }
    }

    return false;
  };

  const setWebPushSubscription = async (unmute: boolean): Promise<void> => {
    try {
      await window.OneSignal.setSubscription(unmute);
    } catch (error) {
      addAnalyticsLog({ function: 'setWebPushSubscription', data: error, logType: 'error ' });
    }
  };

  const setSubscription = async (unmute: boolean): Promise<void> => {
    if (Platform.OS === 'web') {
      await setWebPushSubscription(unmute);
    } else {
      try {
        ReactNativeOneSignal.disablePush(!unmute);
      } catch (error) {
        addAnalyticsLog({ function: 'setSubscription', data: error, logType: 'error ' });
      }
    }
  };

  const initializeOneSignalWeb = useCallback(async (): Promise<void> => {
    if (Platform.OS === 'web') {
      const OneSignal = window.OneSignal;
      OneSignal.push(() => {
        OneSignal.init({ appId: Config.ONESIGNAL_APP_ID });
        OneSignal.on('subscriptionChange', async (isSubscribed: boolean) => {
          console.debug("The user's subscription state is now:", isSubscribed);
          if (isSubscribed) {
            await setWebPushExternalUserId();
          } else {
            await removeWebPushExternalUserId();
          }
        });
      });
    }
  }, [removeWebPushExternalUserId, setWebPushExternalUserId]);

  const initializeOneSignal = useCallback(async (): Promise<void> => {
    if (Platform.OS === 'web') {
      await initializeOneSignalWeb();
    } else {
      try {
        ReactNativeOneSignal.setLogLevel(6, 0);
        ReactNativeOneSignal.setAppId(Config.ONESIGNAL_APP_ID);
        if (userId) {
          ReactNativeOneSignal.setExternalUserId(userId);
        }
        ReactNativeOneSignal.promptForPushNotificationsWithUserResponse();
      } catch (error) {
        addAnalyticsLog({ function: 'initializeOneSignal', data: error, logType: 'error ' });
      }
    }
  }, [addAnalyticsLog, initializeOneSignalWeb, userId]);

  return {
    isWebPushNotificationsEnabled,
    isPushNotificationsEnabled,
    setWebPushExternalUserId,
    setExternalUserId,
    removeWebPushExternalUserId,
    removeExternalUserId,
    isWebPushNotificationsSupported,
    isPushNotificationsSupported,
    setWebPushSubscription,
    setSubscription,
    initializeOneSignalWeb,
    initializeOneSignal
  };
};

export const useInitNotifications = () => {
  const { initializeOneSignal, removeExternalUserId } = useNotifications();
  const isAppInitComplete = useAppStore((state: ApplicationState) => state.isAppInitComplete);

  useEffect(() => {
    if (isAppInitComplete) {
      initializeOneSignal();
    }

    return () => {
      removeExternalUserId();
    };
  }, [initializeOneSignal, isAppInitComplete, removeExternalUserId]);
};
