import Svg, { G, Path, Rect } from 'react-native-svg';
import React from 'react';
import { SimpleIconProps } from 'src/core';

const SupplementIcon = ({ size = 32, color = '#C94C4E' }: SimpleIconProps) => (
  <Svg width={`${size}px`} height={`${size}px`} viewBox="0 0 32 32" fill="none">
    <G data-name="Layer 2">
      <G data-name="warning">
        <Rect width={`${size}px`} height={`${size}px`} transform="rotate(-90 12 12)" opacity="0" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.87373 6.6755C9.87274 6.6767 9.87073 6.67923 9.86807 6.68363L5.34 14.0938L5.34104 14.0945C5.34234 14.0954 5.34439 14.0965 5.34795 14.0977C5.3513 14.0989 5.35885 14.101 5.37216 14.102L13.2831 14.6664C13.2964 14.6674 13.3045 14.6664 13.3087 14.6656C13.3131 14.6648 13.3161 14.6636 13.3186 14.6624C13.3218 14.661 13.325 14.6588 13.3279 14.6562L9.9574 6.68276C9.95613 6.67976 9.95507 6.67776 9.9544 6.6765C9.95047 6.67423 9.93847 6.66856 9.9186 6.6671C9.8966 6.66556 9.88187 6.67043 9.87707 6.67276L9.87547 6.6737C9.87547 6.6737 9.87473 6.6743 9.87373 6.6755ZM11.1855 6.1636C10.7538 5.1424 9.3102 5.03939 8.73033 5.98842L4.19521 13.41C3.67206 14.2662 4.25698 15.3592 5.27727 15.432L13.1882 15.9964C14.2085 16.0692 14.9507 15.071 14.5613 14.1497L11.1855 6.1636Z"
          fill={color}
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.6667 13.3333C24.8758 13.3333 26.6667 11.5425 26.6667 9.33333C26.6667 7.1242 24.8758 5.33333 22.6667 5.33333C20.4576 5.33333 18.6667 7.1242 18.6667 9.33333C18.6667 11.5425 20.4576 13.3333 22.6667 13.3333ZM22.6667 14.6667C25.6122 14.6667 28 12.2789 28 9.33333C28 6.38781 25.6122 4 22.6667 4C19.7212 4 17.3334 6.38781 17.3334 9.33333C17.3334 12.2789 19.7212 14.6667 22.6667 14.6667Z"
          fill={color}
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.5215 9.98978C25.439 10.3486 25.0812 10.5726 24.7224 10.4901L20.312 9.47618C19.9532 9.39371 19.7292 9.03591 19.8116 8.67711C19.8942 8.31825 20.2519 8.09425 20.6107 8.17678L25.0212 9.19071C25.38 9.27318 25.604 9.63098 25.5215 9.98978Z"
          fill={color}
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.7725 18.1974L13.5221 21.5783C12.1873 22.2008 11.6098 23.7874 12.2322 25.1221C12.8547 26.4569 14.4413 27.0344 15.7761 26.412L23.0265 23.0311C24.3612 22.4087 24.9387 20.822 24.3163 19.4873C23.6939 18.1525 22.1073 17.575 20.7725 18.1974ZM12.9586 20.3699C10.9564 21.3036 10.0902 23.6835 11.0238 25.6857C11.9575 27.6878 14.3373 28.5541 16.3395 27.6204L23.59 24.2395C25.5921 23.3059 26.4584 20.9259 25.5247 18.9237C24.5911 16.9216 22.2112 16.0554 20.2091 16.989L12.9586 20.3699Z"
          fill={color}
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.4997 20.1904L13.5221 21.5782C12.1873 22.2007 11.6098 23.7873 12.2322 25.122C12.8547 26.4568 14.4413 27.0343 15.7761 26.4119L18.7537 25.024L16.4997 20.1904ZM17.1446 18.4185L20.5255 25.6689L16.3395 27.6203C14.3373 28.5539 11.9575 27.6877 11.0238 25.6855C10.0902 23.6833 10.9564 21.3035 12.9586 20.3698L17.1446 18.4185Z"
          fill={color}
        />
      </G>
    </G>
  </Svg>
);

export default SupplementIcon;
