import { Text, useTheme } from '@ui-kitten/components';

import AddAction from 'src/features/actions/addAction/AddAction';
import { AddActionsTabParamList } from 'src/core/types/RootNavigationParamList';
import AddProtocol from 'src/features/actions/addProtocol/AddProtocol';
import React from 'react';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { useIsLightColorScheme } from 'src/core/hooks/useIsLightColorScheme';

const ActionsAdd = () => {
  const th = useTheme();
  const TabTop = createMaterialTopTabNavigator<AddActionsTabParamList>();

  const isLightTheme = useIsLightColorScheme();

  return (
    <TabTop.Navigator
      screenOptions={{
        tabBarLabel: ({ focused, children }) => (
          <Text
            category="p2"
            status={focused ? 'primary' : 'basic'}
            style={{ color: th[`color-${focused ? 'primary-500' : 'basic-600'}`] }}>
            {children}
          </Text>
        ),
        tabBarContentContainerStyle: {
          backgroundColor: th[`color-basic-${isLightTheme ? 100 : 1000}`],
          marginBottom: 1
        },
        tabBarIndicatorStyle: { backgroundColor: th['color-primary-500'] },
        tabBarIndicatorContainerStyle: { backgroundColor: th[`color-basic-${isLightTheme ? 100 : 1000}`] }
      }}>
      <TabTop.Screen
        options={{
          lazy: true
        }}
        name="Protocols"
        component={AddProtocol}
      />
      <TabTop.Screen options={{ lazy: true }} name="Actions" component={AddAction} />
    </TabTop.Navigator>
  );
};

export default ActionsAdd;
