import { StyleSheet, View, ViewProps } from 'react-native';

import PillarContainer from 'src/components/home/PillarIcon';
import React from 'react';
import { Text } from '@ui-kitten/components';
import { UIHelper as uh } from '../../../core';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';

//props
interface YourScoreNameProps extends ViewProps {
  pillarType: string;
  displayName: string;
}

// styles
const styleContainer = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  icon: {
    marginRight: uh.w2DP(4)
  }
});

const YourScoreName = ({ pillarType, displayName }: YourScoreNameProps) => {
  const transMessage = {
    pillarScore: 'Your {{displayName}} score'
  };
  const t = useTranslationFunc(transMessage);

  // view
  return (
    <View>
      <View style={styleContainer.container}>
        {pillarType != 'kalibra' && <PillarContainer type={pillarType} style={styleContainer.icon} size="small" />}
        <Text category="s1">{t('pillarScore', { displayName: displayName })}</Text>
      </View>
    </View>
  );
};

export default YourScoreName;
