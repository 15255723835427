import React, { useCallback, useEffect, useRef, useState } from 'react';

import { ActionItem } from 'src/core/types/ActionItem';
import ActionItemService from '../../../api/actionItem/index';
import ActionLayout from '../../../features/actions/ActionLayout';
import { ActionScheduleList } from 'src/features/actions/ActionScheduleList';
import DeactivateBottomSheet from 'src/features/actions/DeactivateBottomSheet';
import { ErrorModal } from 'src/components/shared/ErrorModal';
import RecurrenceBottomSheet from 'src/features/actions/RecurrenceBottomSheet';
import RefreshControl from 'src/components/shared/RefreshControl';
import { RootStackActionProps } from 'src/core/types/RootNavStackParamList';
import { ScrollView } from 'react-native';
import Spinner from 'react-native-loading-spinner-overlay';
import { mainStyles } from '../_mainStyles';
import { useIsFocused } from '@react-navigation/native';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';

export interface ActiveActionsSchedule {
  daily: ActionItem[];
  weekly: ActionItem[];
  monthly: ActionItem[];
  quarterly: ActionItem[];
}

const EMPTY_SCHEDULE = {
  daily: [],
  weekly: [],
  monthly: [],
  quarterly: []
};

const extractSchedule = (actions: ActionItem[]): ActiveActionsSchedule => {
  const result: ActiveActionsSchedule = {
    daily: [],
    weekly: [],
    monthly: [],
    quarterly: []
  };

  actions.forEach((action) => {
    if (action.repeatFrequencyDays < 7) {
      result.daily.push(action);
    } else if (action.repeatFrequencyDays < 30) {
      result.weekly.push(action);
    } else if (action.repeatFrequencyDays < 90) {
      result.monthly.push(action);
    } else {
      result.quarterly.push(action);
    }
  });

  return result;
};

const ScheduleActiveActionsScreen = ({ navigation }: RootStackActionProps<'ActionsScheduleActive'>) => {
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [actions, setActions] = useState<ActiveActionsSchedule>(EMPTY_SCHEDULE);
  const isFocused = useIsFocused();
  const [isLoading, setIsLoading] = useState(false);
  const [currentAction, setCurrentAction] = useState<ActionItem | undefined>(undefined);
  const recurrenceSheetRef = useRef<any>(null);
  const deactivateSheetRef = useRef<any>(null);
  const [errorMessage, setErrorMessage] = useState<any>(null);

  const handleGoToSchedule = (): void => {
    navigation.navigate('ActionsScheduleInactive');
  };

  const transMessage = {
    actionsActive: 'Active',
    actionsActiveInactive: 'Inactive',
    actionsActiveDaily: 'Daily',
    actionsActiveWeekly: 'Weekly',
    actionsActiveMonthly: 'Monthly',
    actionsActiveQuarterly: 'Quarterly',
    actionsActiveHalfYearly: 'Half Yearly',
    actionsActiveYearly: 'Yearly',
    actionsActiveEmpty: 'No actions for now',
    actionsAllDay: 'All Day',
    actionsMorning: 'Morning',
    actionsAfternoon: 'Afternoon',
    actionsEvening: 'Evening'
  };

  const t = useTranslationFunc(transMessage);

  const fetchActionData = useCallback(() => {
    setIsLoading(true);
    ActionItemService.getScheduledActiveActions()
      .then((data) => {
        setActions(extractSchedule(data));
      })
      .catch((error) => {
        setActions(EMPTY_SCHEDULE);
        setErrorMessage(error);
      })
      .finally(() => {
        setIsLoading(false);
        setRefreshing(false);
      });
  }, []);

  useEffect(() => {
    if (refreshing) {
      fetchActionData();
    }
  }, [refreshing, fetchActionData]);

  useEffect(() => {
    if (isFocused) {
      fetchActionData();
    }
  }, [isFocused, fetchActionData]);

  const onRefresh = useCallback(() => {
    setRefreshing(true);
  }, []);

  const handleUpdateAction = (id: number, frequency: number, duration: number): void => {
    setIsLoading(true);
    ActionItemService.updateScheduledActiveAction(id, frequency, duration)
      .then(() => ActionItemService.getScheduledActiveActions())
      .then((data) => {
        setActions(extractSchedule(data));
      })
      .catch((error) => {
        setErrorMessage(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDeactivate = (action: ActionItem): void => {
    setCurrentAction(action);
    if (!deactivateSheetRef.current) {
      return;
    }
    deactivateSheetRef.current.snapToIndex(0);
    deactivateSheetRef.current.present();
  };

  const handleRecurrence = (action: ActionItem): void => {
    if (!recurrenceSheetRef.current) {
      return;
    }
    setCurrentAction(action);
    recurrenceSheetRef.current.snapToIndex(0);
    recurrenceSheetRef.current.present();
  };

  const handleDeactivateAction = (id: number): void => {
    if (!currentAction) {
      return;
    }
    setIsLoading(true);
    ActionItemService.deleteScheduledActiveAction(id)
      .catch((error) => {
        setErrorMessage(error);
      })
      .finally(() => {
        setIsLoading(false);
        setRefreshing(true);
      });
  };

  return (
    <>
      <ActionLayout>
        <Spinner visible={isLoading && !refreshing} />
        <ScrollView
          contentContainerStyle={[{ flexGrow: 1 }, mainStyles.mainScreenContainer]}
          refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}>
          <ActionScheduleList
            headerText={t('actionsActive')}
            linkLabel={t('actionsActiveInactive')}
            onLinkLabelClick={handleGoToSchedule}
            commonProps={{
              isFinished: false,
              isEditable: false,
              showButtonsWhenCollapsed: true,
              onDeactivate: handleDeactivate,
              onRecurrence: handleRecurrence
            }}
            sections={[
              { headerText: t('actionsActiveDaily'), actions: actions.daily },
              { headerText: t('actionsActiveWeekly'), actions: actions.weekly },
              { headerText: t('actionsActiveMonthly'), actions: actions.monthly },
              { headerText: t('actionsActiveQuarterly'), actions: actions.quarterly }
            ]}
          />
        </ScrollView>
      </ActionLayout>
      <RecurrenceBottomSheet
        sheetRef={recurrenceSheetRef}
        action={currentAction}
        isEditing={true}
        onChange={handleUpdateAction}
      />
      <DeactivateBottomSheet sheetRef={deactivateSheetRef} action={currentAction} onChange={handleDeactivateAction} />
      <ErrorModal
        visible={!!errorMessage}
        message={errorMessage}
        closeBtnClick={() => {
          setErrorMessage(null);
        }}
      />
    </>
  );
};

export default ScheduleActiveActionsScreen;
