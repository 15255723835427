import { Text, Toggle, Layout, Divider } from '@ui-kitten/components';
import React from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';
import { NotiSettingItem } from 'src/core/types/Notification';

//props
interface NotificationsMenuProps extends ViewProps {
  name: string;
  description: string;
  primary: NotiSettingItem;
  items: NotiSettingItem[];
  pushNotificationsSupported: boolean;
  enableNotifications: boolean;
}

//styles
const styleContainer = StyleSheet.create({
  container: { flex: 1, padding: 16, borderRadius: 8 },
  name: { marginBottom: 4 },
  itemsContainer: { flex: 1, rowGap: 16 },
  item: { justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' },
  itemName: { fontSize: 12, fontWeight: '400', fontFamily: 'Poppins-Regular' },
  description: { marginTop: 8, marginBottom: 16 }
});

const NotificationsMenu = ({
  name,
  description,
  primary,
  items,
  enableNotifications,
  pushNotificationsSupported
}: NotificationsMenuProps) => {
  const renderPrimary = () => {
    return (
      <>
        <View style={styleContainer.item}>
          <>
            <Text category="s2">{primary.name}</Text>
            <Toggle disabled={!pushNotificationsSupported} checked={enableNotifications} onChange={primary.onChange} />
          </>
        </View>
        <Divider />
      </>
    );
  };

  //view
  return (
    <Layout level="1" style={styleContainer.container}>
      <Text category="s1" style={styleContainer.name}>
        {name}
      </Text>
      <Text category="c1" style={styleContainer.description}>
        {description}
      </Text>
      <View style={styleContainer.itemsContainer}>
        {renderPrimary()}
        {items.map((item, index) => (
          <View style={styleContainer.item} key={index}>
            <>
              <Text style={styleContainer.itemName}>{item.name}</Text>
              <Toggle
                disabled={!enableNotifications || !pushNotificationsSupported}
                checked={item.checked}
                onChange={item.onChange}
              />
            </>
          </View>
        ))}
      </View>
    </Layout>
  );
};

export default NotificationsMenu;
