import { Alert, Platform } from 'react-native';
import { getValue, setValue } from 'src/api/storage';

import { BackendApi } from 'src/api/shared';
import { Config } from '../constants/Config';
import { useEffect } from 'react';

export const useSetDefaultServer = () => {
  useEffect(() => {
    if (Config.DEVELOPMENT_MODE) {
      const setDefault = () => {
        setValue('backend_server_idx', '');
        setValue('backend_custom_url', '');
        setValue('backend_url', '');
      };

      getValue('backend_url')
        .then((res) => {
          if (res) {
            if (Platform.OS === 'web') {
              if (confirm('Set backend url to default?')) {
                setDefault();
              } else BackendApi.defaults.baseURL = res;
            } else
              Alert.alert('Set backend url to default?', '', [
                {
                  text: 'Yes',
                  onPress: () => {
                    setDefault();
                  }
                },
                {
                  text: 'No',
                  onPress: () => (BackendApi.defaults.baseURL = res)
                }
              ]);
          } else {
            setDefault();
          }
        })
        .catch((err) => {
          console.error(err);
          setDefault();
        });
    }
  }, []);
};
