import * as amplitude from '@amplitude/analytics-react-native';

import { ApplicationState, useAppStore } from '../store';
import { getValue, setValue } from 'src/api/storage';
import { useCallback, useEffect } from 'react';
import { useGetExternalUserId, useIsUserLogged } from './useUser';

import { AnalyticsLogsData } from '../store/slices/analytics';
import { Config } from '../constants/Config';
import { addRawAnalyticsLog } from 'src/core/helpers/AddRawAnalyticsLog';
import { getCurrentUser } from 'src/api/auth';
import { useAnalytics } from './useAnalytics';

export const useAnalyticsInitializer = () => {
  const isUserLogged = useIsUserLogged();
  const { addAnalyticsLog } = useAnalytics('useAnalyticsInitializer.ts');

  const setAnalytics = useAppStore((state: ApplicationState) => state.setAnalytics);
  const resetAnalytics = useAppStore((state: ApplicationState) => state.resetAnalytics);
  const isAppInitComplete = useAppStore((state: ApplicationState) => state.isAppInitComplete);

  const userId = useGetExternalUserId() ?? '';

  const initAnalytics = useCallback(async () => {
    addAnalyticsLog({
      function: 'initAnalytics',
      data: { message: 'Initializing Amplitude Analytics' },
      logType: 'AmplitudeInit'
    });

    try {
      amplitude.init(Config.AMPLITUDE_API_KEY, userId);
      const currentUser = await getCurrentUser();
      if (currentUser.attributes['custom:excludeAnalytics']) {
        const identify = new amplitude.Identify();
        identify.set('excludeAnalytics', true);
        amplitude.identify(identify);
      }
    } catch (err) {
      addAnalyticsLog({ function: 'initAnalytics', data: err, logType: 'error' });
    }

    setAnalytics(false);

    // Log all previous logs from pre auth
    resetAnalytics((analyticsLogs) => {
      analyticsLogs.map((x) => {
        if (x.logType === 'error') {
          addRawAnalyticsLog('Error', { file: x.filename, function: x.function, ...x.data });
        } else {
          addRawAnalyticsLog(x.logType, x.data);
        }
      });
      setValue('logs', '');
    });

    return new Promise<boolean>((resolve) => resolve(true));
  }, [addAnalyticsLog, setAnalytics, resetAnalytics, userId]);

  const deinit = useCallback(async () => {
    setAnalytics(true);
    try {
      amplitude.reset();
    } catch (error) {
      console.error(`DeInit amplitude error: ${JSON.stringify(error)}`);
    }
  }, [setAnalytics]);

  useEffect(() => {
    // Get previous logs stored in localStorage in case of app crash or quitting pre login
    getValue('logs')
      .then((logs) => {
        if (!logs) {
          return;
        }
        console.error(`logs: ${logs}`);
        const parsedLogs = JSON.parse(logs);
        parsedLogs.map((log: AnalyticsLogsData) => {
          addAnalyticsLog({ ...log });
        });
      })
      .catch((err) => {
        console.error(`err: ${JSON.stringify(err)} => ${err}`);
        addAnalyticsLog({
          function: 'initializeResources',
          data: { message: 'No previous local storage logs', ...err },
          logType: 'error'
        });
      });
  }, [addAnalyticsLog]);

  useEffect(() => {
    if (isUserLogged && isAppInitComplete) {
      initAnalytics();
      return () => {
        deinit();
      };
    }
  }, [isUserLogged, deinit, initAnalytics, isAppInitComplete]);
};
