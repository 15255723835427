import { BackendApi } from 'src/api/shared';
import { NotificationMessageService } from '../model';

export const WebNotificationMessageService: NotificationMessageService = {
  getNotificationMessages: () => {
    const abortController = new AbortController();
    return {
      promise: BackendApi.get('/users/notification', {
        signal: abortController.signal
      })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          if (error.isAborted) {
            return Promise.reject({ error: error, isAborted: abortController.signal.aborted });
          }
        }),
      abort: () => {
        abortController.abort();
      }
    };
  },
  markAsRead: (id: string): Promise<boolean> => {
    return BackendApi.post(`/users/notification/${id}/mark-as-read/`).then((response) => response.data);
  },
  deleteNotification: (id: string): Promise<boolean> => {
    return BackendApi.delete(`/users/notification/${id}`).then((response) => response.data);
  }
};
