import * as Linking from 'expo-linking';

import { LinkingOptions } from '@react-navigation/native';
import { RootNavigationParamList } from 'src/core/types/RootNavigationParamList';
import { navConfigForLinking } from 'src/core/brands';

export const NavigationLinking: LinkingOptions<RootNavigationParamList> = {
  prefixes: [Linking.createURL('/'), navConfigForLinking.appPrefix],
  config: {
    screens: {
      PreRegister: { path: 'PreRegister' },
      // Register: { path: 'Register' },
      // ConfirmationRegister: { path: 'Confirmation Register' },
      Login: { path: 'Login' },
      // ResetPassword: { path: 'Reset Password' },
      // PasswordOTP: { path: 'Reset Password One Time Code' },
      // ConfirmationRegisterOTP: { path: 'Register One Time Code' },
      // ConfirmationReset: { path: 'Confirmation Reset' },
      SharingApprove: { path: 'app-sharing' },
      Home: { path: 'Home' },
      Kali: { path: navConfigForLinking.chatbotPath },
      Actions: {
        path: 'Actions',
        screens: {
          TodayActions: { path: 'TodayActions/:actionId?' }
        }
      },
      ProfileSettings: { path: 'Profile' },
      Assessments: { path: 'Assessments/:pendingDocId?' },
      MyMarkers: {
        path: 'Marker',
        screens: {
          Favorite: { path: 'Favorite' },
          AllMarkers: { path: 'AllMarkers' }
        }
      },
      ScanGettingStarted: { path: 'Scan' },
      AssessmentDetail: {
        path: 'AssessmentDetail/:assessmentId'
      },
      Notifications: {
        path: 'Notifications'
      },
      SleepStat: {
        path: 'SleepStat/:sleepId'
      },
      Pillar: {
        path: 'Pillar/:pillarId'
      },
      Customisation: {
        path: 'Customisation'
      },
      FaceScanInput: { path: 'FaceScanInput' },
      FaceScanGuide: { path: 'FaceScanGuide' },
      BodyScanInput: { path: 'BodyScanInput' },
      BodyScanGuide: { path: 'BodyScanGuide' },
      BloodworkGuide: { path: 'BloodworkGuide' },
      HRVScanGuide: { path: 'HRVScanGuide' },
      HRVScan: { path: 'HRVScan' },
      HRVProcessing: { path: 'HRVProcessing' },
      Settings: { path: 'Settings' },
      Feedback: { path: 'Feedback' },
      ConnectedDevices: { path: 'ConnectedDevices' },
      Subscriptions: { path: 'Subscriptions' },
      AfterPurchaseSubscriptionScreen: { path: 'AfterPurchaseSubscriptionScreen' },
      AboutUs: { path: 'About Us' },
      DeveloperSettings: { path: 'DeveloperSettings' },
      DataSharing: { path: 'DataSharing' }
    }
  }
};
