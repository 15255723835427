import { StyleSheet } from 'react-native';
import { Layout } from '@ui-kitten/components';
import MainMenuContainer from 'src/features/menu/MainMenuContainer';
import React from 'react';

//styles
const styleContainer = StyleSheet.create({
  screenContainer: {
    flex: 1
  },
  safeAreaView: { flex: 1 }
});

const MainMenuScreen = () => {
  //view

  return (
    <Layout level="2" style={styleContainer.screenContainer}>
      <MainMenuContainer />
    </Layout>
  );
};

export default MainMenuScreen;
