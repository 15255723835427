import { PillarScore, UIHelper as uh } from '../../core';
import { StyleSheet, View, ViewProps } from 'react-native';
import { Text, useTheme } from '@ui-kitten/components';

import AccuracyText from './conponents/AccuracyText';
import React from 'react';
import YourScoreName from './conponents/YourScoreName';
import { scoreSummary } from 'src/core/brands';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';

//props
interface ScoreSummaryProps extends ViewProps {
  pillarScore: PillarScore;
}

// styles
const styleContainer = StyleSheet.create({
  container: {
    borderRadius: 8
  },
  accuracy: { justifyContent: 'flex-start' },
  scoreDescription: { marginTop: uh.h2DP(8) }
});

const ScoreSummary = ({ pillarScore }: ScoreSummaryProps) => {
  const th = useTheme();

  const t = useTranslationFunc(scoreSummary);

  const color = pillarScore.id == '0' ? th['color-primary-500'] : th['color-' + pillarScore.type + '-500'];
  // view
  return (
    <View style={styleContainer.container}>
      <YourScoreName pillarType={pillarScore.type} displayName={pillarScore.displayName} />
      <Text category="h1" style={{ color: color }}>
        {pillarScore.score}
        <Text style={{ color: color }} category="h4">
          %
        </Text>
      </Text>
      <AccuracyText accuracy={Math.floor(pillarScore.accuracy)} style={styleContainer.accuracy} />
      <Text category="p2" style={styleContainer.scoreDescription}>
        {t(pillarScore.id == '0' ? 'learnMoreAboutDefault' : `learnMoreAbout${pillarScore.name}`)}
      </Text>
    </View>
  );
};

export default ScoreSummary;
