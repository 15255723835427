import { getValue, setValue } from 'src/api/storage';

import { ScanType } from '../types/Scan';
import { useAppStore } from '../store';

interface UseScanGuideSkip {
  setScanGuideSkip: (isSkipped: boolean, scanType: ScanType) => void;
  getScanGuideSkip: () => Promise<void>;
}

export const useScanGuideSkip = (): UseScanGuideSkip => {
  const scanGuideSkip = useAppStore((state) => state.scanGuideSkip);
  const initScanGuideSkip = useAppStore((state) => state.setScanGuideSkip);

  const setScanGuideSkip = (isSkipped: boolean, scanType: ScanType) => {
    scanGuideSkip.set(scanType, isSkipped);
    setValue('scanGuideSkip', JSON.stringify(Array.from(scanGuideSkip.entries())));
  };

  const getScanGuideSkip = async () => {
    const isScanGuideSkip = await getValue('scanGuideSkip');
    if (JSON.parse(isScanGuideSkip).length > 0) {
      initScanGuideSkip(new Map(JSON.parse(isScanGuideSkip)));
    }
  };

  return { setScanGuideSkip, getScanGuideSkip };
};
