import { useCallback, useEffect, useState } from 'react';
import { ApiError } from '../types/ApiResponse';
import { useAnalytics } from './useAnalytics';
import { useAppStore } from '../store';
import { useIsUserLogged } from './useUser';
import { Level, Units, UserSettings } from '..';
import UserService from '../../api/user';

type UseUserSettingEffect = [boolean, any];
interface UseUserSettingEffectProps {
  refreshing: boolean;
  setRefreshing: (refreshing: boolean) => void;
}

export const useUserSettingsEffect = ({
  refreshing,
  setRefreshing
}: UseUserSettingEffectProps): UseUserSettingEffect => {
  const [isUserSettingsLoading, setIsUserSettingsLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>();

  const { addAnalyticsLog } = useAnalytics('useUpdateUserSettings.ts');
  const isUserLogged = useIsUserLogged();
  const setEnabledActionNotifications = useAppStore((state) => state.setEnabledActionNotifications);
  const setEnabledBiomarkerOfTheDayNotifications = useAppStore(
    (state) => state.setEnabledBiomarkerOfTheDayNotifications
  );
  const setEnabledSleepStatNotifications = useAppStore((state) => state.setEnabledSleepStatNotifications);
  const setUnits = useAppStore((state) => state.setUnits);
  const setLevel = useAppStore((state) => state.setLevel);

  const loadUserSettings = useCallback(
    (promise: Promise<UserSettings>) => {
      promise
        .then((settings) => {
          setEnabledActionNotifications(settings.enabledActionNotifications as boolean);
          setEnabledBiomarkerOfTheDayNotifications(settings.enabledBiomarkerOfTheDayNotifications as boolean);
          setEnabledSleepStatNotifications(settings.enabledSleepStatNotifications as boolean);
          setLevel(settings.level as Level);
          setUnits(settings.units as Units);
        })
        .catch((err: ApiError) => {
          if (!err.isAborted) {
            setError(err.error);
          }
          addAnalyticsLog({ function: 'loadUserSettings', data: err, logType: 'error' });
        })
        .finally(() => {
          setIsUserSettingsLoading(false);
          setRefreshing(false);
        });
    },
    [
      addAnalyticsLog,
      setRefreshing,
      setEnabledActionNotifications,
      setEnabledBiomarkerOfTheDayNotifications,
      setEnabledSleepStatNotifications,
      setUnits,
      setLevel
    ]
  );

  useEffect(() => {
    if (isUserLogged) {
      const promise = UserService.getUserSettings();
      loadUserSettings(promise);
    }
  }, [isUserLogged, loadUserSettings]);

  useEffect(() => {
    if (refreshing) {
      const promise = UserService.getUserSettings();
      loadUserSettings(promise);
    }
  }, [loadUserSettings, refreshing]);

  return [isUserSettingsLoading, error];
};

export const useUpdateUserSettings = () => {
  const { addAnalyticsLog } = useAnalytics('useUpdateUserSettings.ts');
  const [isUpdatingUserSettings, setIsUpdatingUserSettings] = useState<boolean>(false);
  const units = useAppStore((state) => state.units);
  const level = useAppStore((state) => state.level);
  const enabledActionNotifications = useAppStore((state) => state.enabledActionNotifications);
  const enabledBiomarkerOfTheDayNotifications = useAppStore((state) => state.enabledBiomarkerOfTheDayNotifications);
  const enabledSleepStatNotifications = useAppStore((state) => state.enabledSleepStatNotifications);

  const [error, setError] = useState<any>();
  const updateUserSettings = (settings: UserSettings) => {
    const finalSettings = {
      units: units,
      level: level,
      enabledActionNotifications: enabledActionNotifications,
      enabledBiomarkerOfTheDayNotifications: enabledBiomarkerOfTheDayNotifications,
      enabledSleepStatNotifications: enabledSleepStatNotifications,
      enabledNotifications: true
    };
    setIsUpdatingUserSettings(true);
    UserService.setUserSettings({ ...finalSettings, ...settings })
      .catch((err: ApiError) => {
        setError(err.error);
        addAnalyticsLog({ function: 'updateUserSettings', data: err, logType: 'error' });
      })
      .finally(() => {
        setIsUpdatingUserSettings(false);
      });
  };

  return [updateUserSettings, isUpdatingUserSettings, error];
};
