const getBarColor = (color: string, pillar: string): string => {
  switch (color) {
    case 'red':
      return `color-${pillar}-100`;
    case 'orange':
      return `color-${pillar}-200`;
    case 'yellow':
      return `color-${pillar}-300`;
    case 'green':
      return `color-${pillar}-400`;
    case 'teal':
      return `color-${pillar}-500`;
    default:
      return color;
  }
};

//Color Helper export functions
export const ColorHelper = {
  getBarColor: (color: string, pillar: string): string => {
    return getBarColor(color, pillar);
  }
};
