import { FlatList, StyleSheet, TouchableOpacity, View, ViewProps } from 'react-native';
import { Icon, Text, useTheme } from '@ui-kitten/components';
import React, { useCallback, useMemo, useState } from 'react';

import { HealthMarkerReportListItem } from 'src/core/types/HealthMarkerReportList';
import IndividualAssementListItem from './IndividualAssessmentListItem';
import { UIHelper as uh } from 'src/core';

//props
interface IIndividualAssessmentListProps extends ViewProps {
  btnClickHandler: (assessmnetId: string) => void;
  assessments: HealthMarkerReportListItem[];
  caption: string;
}

const IndividualAssessmentList = (props: IIndividualAssessmentListProps) => {
  const th = useTheme();

  const [page, setPage] = useState<number>(0);
  const pageSize = useMemo(() => 8, []);
  const allPages = useMemo(
    () => Array.from({ length: Math.ceil(props.assessments.length / pageSize) }, (_, i) => i + 1),
    [pageSize, props.assessments.length]
  );

  const styleContainer = StyleSheet.create({
    pageContainer: {
      flexDirection: 'row',
      height: uh.h2DP(48),
      marginTop: uh.h2DP(8),
      borderRadius: 8,
      borderWidth: 1,
      borderColor: th['color-basic-400']
    },
    pageItem: {
      flex: 1,
      justifyContent: 'center',
      borderRadius: 8
    },
    activePageItem: {
      backgroundColor: th['color-primary-500']
    }
  });

  const renderItems = () => {
    return (
      <FlatList
        data={props.assessments.slice(page * pageSize, (page + 1) * pageSize)}
        scrollEnabled={false}
        renderItem={({ item, index }) => (
          <IndividualAssementListItem
            key={`item-index-${index}`}
            name={item.assessmentName}
            date={item.assessmentName == 'Wearables' ? item.updatedOn : item.measuredDate}
            btnClickHandler={() => {
              props.btnClickHandler(item.id);
            }}
          />
        )}
      />
    );
  };

  const renderPages = useCallback(() => {
    const pageNumber = 4;
    const offset = Math.max(0, Math.min(allPages.length - pageNumber, Math.max(0, page + 2 - pageNumber)));
    const leftBoundary = Math.max(0, Math.min(offset, allPages.length - pageNumber));
    const rightBoundary = pageNumber + offset;
    const pagesToShow = allPages.slice(leftBoundary, rightBoundary);

    if (allPages.length <= 1) {
      return;
    }
    return (
      <View style={styleContainer.pageContainer}>
        <TouchableOpacity
          style={styleContainer.pageItem}
          onPress={() => {
            setPage((prev) => Math.max(0, prev - 1));
          }}>
          <View style={{ height: uh.h2DP(24), opacity: page === 0 ? 0.2 : 1 }}>
            <Icon name={'chevron-left-outline'} fill={th['color-primary-500']} />
          </View>
        </TouchableOpacity>
        {pagesToShow.map((item, index) => (
          <TouchableOpacity
            key={index}
            style={[styleContainer.pageItem, index === page - offset && styleContainer.activePageItem]}
            onPress={() => {
              setPage(Math.min(index + offset, allPages.length - 1));
            }}>
            <Text style={{ textAlign: 'center' }}>{item}</Text>
          </TouchableOpacity>
        ))}
        {allPages.length > pageNumber && page < allPages.length - 2 && (
          <>
            <View style={styleContainer.pageItem}>
              <Text style={{ textAlign: 'center' }}>...</Text>
            </View>
            <TouchableOpacity
              style={styleContainer.pageItem}
              onPress={() => {
                setPage(allPages.length - 1);
              }}>
              <Text style={{ textAlign: 'center' }}>{allPages.length}</Text>
            </TouchableOpacity>
          </>
        )}
        <TouchableOpacity
          style={styleContainer.pageItem}
          onPress={() => {
            setPage((prev) => Math.min(allPages.length - 1, prev + 1));
          }}>
          <View style={{ height: uh.h2DP(24), opacity: page === allPages.length - 1 ? 0.2 : 1 }}>
            <Icon name={'chevron-right-outline'} fill={th['color-primary-500']} />
          </View>
        </TouchableOpacity>
      </View>
    );
  }, [allPages, page, styleContainer, th]);

  // view
  return (
    <View style={[props.style]}>
      <Text category="c2" appearance="hint">
        {props.caption}
      </Text>
      {renderPages()}
      {renderItems()}
    </View>
  );
};

export default IndividualAssessmentList;
