import { Icon, Text, ViewPager, useTheme } from '@ui-kitten/components';
import React, { useState } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { TouchableOpacity } from 'react-native-gesture-handler';
import { UIHelper as uh } from '../../core';

export interface GuideScreen {
  renderGuideScreen: () => JSX.Element;
}

export interface GuidePagerProps {
  style?: StyleProp<ViewStyle>;
  guides: ReadonlyArray<GuideScreen>;
  onLastNextPress: () => void;
  onLastBackPress: () => void;
  lastButtonTitle: string;
}

export const GuidePager = (props: GuidePagerProps) => {
  const th = useTheme();
  const styles = StyleSheet.create({
    dot: {
      width: 8,
      height: 8,
      borderRadius: 4,
      margin: 4
    },
    container: {
      flex: 1,
      height: uh.height()
    },
    pager: {
      flex: 1,
      alignSelf: 'stretch'
    },
    bottomNavContainer: {
      height: 70,
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: th['color-primary-500']
    },
    dotSelected: {
      width: 8,
      height: 8,
      borderRadius: 4,
      margin: 4,
      backgroundColor: th['color-basic-100']
    },
    dotUnselected: {
      width: 8,
      height: 8,
      borderRadius: 4,
      margin: 4,
      backgroundColor: th['color-primary-300']
    },
    cardContainer: {
      alignSelf: 'stretch'
    },
    bottomButtonContainer: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      paddingHorizontal: 12
    },
    bottomButtonIcon: { height: 26, width: 26 },
    bottomButtonText: {
      color: th['color-basic-100']
    }
  });

  const { style, guides, onLastBackPress, onLastNextPress, lastButtonTitle } = props;
  const [currentPage, setCurrentPage] = useState(0);
  const numberOfPages = guides.length;

  const onPageSelected = (index: number) => {
    if (Number.isNaN(index)) {
      setCurrentPage(0);
    } else {
      setCurrentPage(index);
    }
  };

  const dotsArray = () => {
    const dots = [];
    for (let i = 0; i < numberOfPages; i++) {
      const view = <View key={`dot_${i}`} style={currentPage === i ? styles.dotSelected : styles.dotUnselected} />;
      dots.push(view);
    }
    return dots;
  };

  const onNextPress = () => {
    if (currentPage < numberOfPages - 1) {
      setCurrentPage(currentPage + 1);
    } else {
      onLastNextPress();
    }
  };

  const onBackPress = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    } else {
      onLastBackPress();
    }
  };

  return (
    <View style={styles.container}>
      <ViewPager style={[styles.pager, style]} selectedIndex={currentPage} onSelect={onPageSelected}>
        {guides.map((guideScreen, i) => {
          return (
            <View style={{ height: '100%' }} key={`guide_${i}`}>
              {guideScreen.renderGuideScreen()}
            </View>
          );
        })}
      </ViewPager>
      <View style={styles.bottomNavContainer}>
        {currentPage > 0 && (
          <TouchableOpacity style={styles.bottomButtonContainer} onPress={onBackPress}>
            <Icon fill={th['color-basic-100']} style={styles.bottomButtonIcon} name={'chevron-left'} />
            <Text category="s1" style={styles.bottomButtonText}>
              {'Back'}
            </Text>
          </TouchableOpacity>
        )}
        <View
          style={{
            flex: 2,
            marginRight: currentPage > 0 ? 16 : 16
          }}
        />
        <View
          style={{
            flexDirection: 'row',
            position: 'absolute',
            justifyContent: 'center',
            alignItems: 'center',
            left: 0,
            right: 0
          }}
          pointerEvents="none">
          {dotsArray()}
        </View>
        <TouchableOpacity style={[styles.bottomButtonContainer, { alignSelf: 'flex-end' }]} onPress={onNextPress}>
          <Text category="s1" style={styles.bottomButtonText}>
            {currentPage === 1 ? lastButtonTitle : 'Next'}
          </Text>
          <Icon fill={th['color-basic-100']} style={styles.bottomButtonIcon} name={'chevron-right'} />
        </TouchableOpacity>
      </View>
    </View>
  );
};
