import { useCallback, useEffect, useState } from 'react';

import { ActionItem } from '../types/ActionItem';
import ActionItemService from 'src/api/actionItem';
import { ProtocolItem } from '../types/Protocols';
import { useAppStore } from '../store';

interface AssignAction {
  id: number;
  durationDays: number;
  alertTime: string;
  frequency: number;
}

export const useGetActions = () => {
  return useAppStore((state) => state.actions);
};
export const useGetProtocols = () => {
  return useAppStore((state) => state.protocols);
};

export const useActionEffect = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  const [actions, setActions] = useAppStore((state) => [state.actions, state.setActions]);

  const getActions = useCallback(
    (promise: Promise<ActionItem[]>) => {
      setLoading(true);
      promise
        .then((actionResponse) => {
          setActions(actionResponse.sort((a, b) => (a.name > b.name ? 1 : -1)));
        })
        .catch((err) => setError(err))
        .finally(() => {
          setLoading(false);
        });
    },
    [setActions]
  );

  useEffect(() => {
    if (!actions.length) {
      const { promise, abort } = ActionItemService.getAllActions();
      getActions(promise);
      return abort;
    }
  }, [actions.length, getActions]);

  return [loading, error];
};

export const useProtocolEffect = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  const [protocols, setProtocols] = useAppStore((state) => [state.protocols, state.setProtocols]);

  const getProtocols = useCallback(
    (promise: Promise<ProtocolItem[]>) => {
      setLoading(true);
      promise
        .then((protocolResponse) => {
          setProtocols(protocolResponse);
        })
        .catch((err) => setError(err))
        .finally(() => {
          setLoading(false);
        });
    },
    [setProtocols]
  );

  useEffect(() => {
    if (!protocols.length) {
      const { promise, abort } = ActionItemService.getAllProtocols();
      getProtocols(promise);
      return abort;
    }
  }, [getProtocols, protocols.length]);

  return [loading, error];
};

export const useAssignAction = (
  callback?: () => void
): [({ id, durationDays, alertTime, frequency }: AssignAction) => void, boolean, any] => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  const assignAction = useCallback(
    ({ id, durationDays, alertTime, frequency }: AssignAction) => {
      setLoading(true);
      ActionItemService.assignAction({ id, durationDays, alertTime, frequency })
        .promise.catch((err) => {
          setError(err);
        })
        .finally(() => {
          setLoading(false);
          callback?.();
        });
    },
    [callback]
  );

  return [assignAction, loading, error];
};

export const useAssignProtocol = (callback?: () => void): [(protocolIds: number[]) => void, boolean, any] => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  const assignProtocol = useCallback(
    (protocolIds: number[]) => {
      setLoading(true);
      ActionItemService.assignProtocol(protocolIds)
        .promise.catch((err) => {
          setError(err);
        })
        .finally(() => {
          setLoading(false);
          callback?.();
        });
    },
    [callback]
  );

  return [assignProtocol, loading, error];
};

export const useGetProtocolDetails = (id: string): [ProtocolItem, boolean, any] => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>();
  const [protocolDetails, setProtocolDetails] = useState<ProtocolItem>({
    actions: [],
    authorId: '',
    authorName: '',
    cycles: 0,
    description: '',
    duration: 0,
    id: 0,
    name: '',
    share: 0
  });

  const getProtocolDetails = useCallback((promise: Promise<ProtocolItem>) => {
    promise
      .then((details) => {
        setProtocolDetails(details);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (!protocolDetails.name) {
      const { promise, abort } = ActionItemService.getProtocolDetails(id);

      getProtocolDetails(promise);
      return () => {
        abort();
      };
    }
  }, [getProtocolDetails, id, protocolDetails]);

  return [protocolDetails, loading, error];
};
