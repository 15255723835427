import { FlatList } from 'react-native';
import { ProtocolItem } from 'src/core/types/Protocols';
import ProtocolListItem from './ProtocolListItem';
import React from 'react';
import { UIHelper as uh } from 'src/core';

interface AddProtocolListProps {
  protocols: ProtocolItem[];
  setProtocolIds: (prev: (prev: number[]) => number[]) => void;
  handleEndReached?: () => void;
}

const AddProtocolList = ({ protocols, setProtocolIds, handleEndReached }: AddProtocolListProps) => {
  const renderListItem = ({ item }: { item: ProtocolItem }) => {
    return <ProtocolListItem protocol={item} setProtocolIds={setProtocolIds} />;
  };
  return (
    <FlatList
      style={{ marginVertical: uh.h2DP(8) }}
      onEndReached={handleEndReached}
      renderItem={renderListItem}
      data={protocols}
    />
  );
};

export default AddProtocolList;
