import { Layout, Text } from '@ui-kitten/components';
import { StyleSheet, View } from 'react-native';

import React from 'react';
import { UIHelper as uh } from '../../core';

const DisableTenantFeature = () => {
  const styleContainer = StyleSheet.create({
    notEnabled: {
      marginTop: uh.h2DP(16),
      borderRadius: 8,
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center'
    }
  });

  return (
    <Layout style={styleContainer.notEnabled}>
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', width: uh.h2DP(256) }}>
        <Text style={{ textAlign: 'center' }}>Unlock this feature with one of our membership plans!</Text>
      </View>
    </Layout>
  );
};

export default DisableTenantFeature;
