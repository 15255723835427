import { useCallback, useEffect, useState } from 'react';

import { ApiError } from '../types/ApiResponse';
import { DynamicCardBody } from '../types/DynamicCard';
import DynamicCardsService from 'src/api/dynamicCards';
import { useAnalytics } from './useAnalytics';
import { useAppStore } from '../store';
import { useIsUserLogged } from './useUser';

type UseDynamicCardsEffect = [DynamicCardBody[], boolean, any];
interface UseDynamicCardsEffectProps {
  refreshing: boolean;
  setRefreshing: (refreshing: boolean) => void;
}

export const useDynamicCardsEffect = ({
  refreshing,
  setRefreshing
}: UseDynamicCardsEffectProps): UseDynamicCardsEffect => {
  const [isDynamicCardLoading, setIsDynamicCardLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>();

  const { addAnalyticsLog } = useAnalytics('useDynamicCardsEffect.ts');
  const dynamicCards = useAppStore((state) => state.dynamicCards);
  const setDynamicCards = useAppStore((state) => state.setDynamicCards);
  const isUserLogged = useIsUserLogged();

  const loadDynamicCards = useCallback(
    (promise: Promise<DynamicCardBody[]>) => {
      promise
        .then((cards) => {
          setDynamicCards(cards);
        })
        .catch((err: ApiError) => {
          if (!err.isAborted) {
            setError(err.error);
          }
          addAnalyticsLog({ function: 'loadDynamicCards', data: err, logType: 'error' });
        })
        .finally(() => {
          setIsDynamicCardLoading(false);
          setRefreshing(false);
        });
    },
    [addAnalyticsLog, setDynamicCards, setRefreshing]
  );

  useEffect(() => {
    if (isUserLogged) {
      const { promise, abort } = DynamicCardsService.getDynamicCards();
      loadDynamicCards(promise);
      return abort;
    }
  }, [isUserLogged, loadDynamicCards]);

  useEffect(() => {
    if (refreshing) {
      const { promise, abort } = DynamicCardsService.getDynamicCards();
      loadDynamicCards(promise);
      return abort;
    }
  }, [loadDynamicCards, refreshing]);

  return [dynamicCards, isDynamicCardLoading, error];
};
