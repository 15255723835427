import { BackHandler, Modal, Platform, SafeAreaView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { Button, Divider, Icon, Layout, Spinner, Text, useTheme } from '@ui-kitten/components';
import React, { useCallback, useEffect, useState } from 'react';

import { ErrorModal } from 'src/components/shared/ErrorModal';
import { InfoModal } from 'src/components/shared/InfoModal';
import ProviderItem from 'src/components/settings/connectedDevice/ProviderItem';
import RefreshControl from 'src/components/shared/RefreshControl';
import { ScrollView } from 'react-native-gesture-handler';
import { TerraInfoModal } from 'src/components/settings/connectedDevice/TerraInfoModal';
import { TerraProvider } from 'src/core/types/TerraProvider';
import WearableService from 'src/api/wearable';
import { WebView } from 'react-native-webview';
import moment from 'moment';
import { settingStyles } from './_settingStyles';
import { UIHelper as uh } from 'src/core';
import { useAnalytics } from 'src/core/hooks/useAnalytics';
import { useFocusEffect } from '@react-navigation/native';
import { useTelemetry } from 'src/core/hooks/useTelemetry';
import { useTerraSyncDataApi } from 'src/core/hooks/useTerraSyncDataApi';
import { useWearableSdk } from 'src/core/hooks/useWearableSdk';

const ConnectedDevicesScreen = () => {
  const { addAnalyticsLog } = useAnalytics('ConnectedDevicesScreen.tsx');

  const th = useTheme();
  //styles
  const styleContainer = StyleSheet.create({
    screenContainer: { flex: 1 },
    safeAreaView: { flex: 1 },
    infoIcon: {
      width: 20,
      height: 20
    }
  });

  const [btnTitle, setBtnTitle] = useState('Cancel');
  const [isLoading, setIsLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [visibleInfoModal, setVisibleInfoModal] = useState(false);
  const [info, setInfo] = useState('');
  const [authUrl, setAuthUrl] = useState<string>('');
  const [providers, setProviders] = useState<Array<TerraProvider>>([]);
  const [checkedProvider, setCheckedProvider] = useState<{ checked: boolean; item: TerraProvider }>({} as any);
  const [visibleConfirmModal, setVisibleConfirmModal] = useState<boolean>(false);
  const [canUseSync, setCanUseSync] = useState<boolean>(false);
  const { addTelemetryLog } = useTelemetry();
  const [syncTerraData, isSyncTerraDataLoading] = useTerraSyncDataApi();
  const { isUserAuth, initConnection } = useWearableSdk();
  const [error, setError] = useState<any>();
  const [appleDisconnectedDate, setAppleDisconnectedDate] = useState<Date>();

  const filterProviders = (subscriptions: TerraProvider[]) => {
    const prs = subscriptions
      .filter((item: TerraProvider) => {
        if (Platform.OS === 'ios') {
          return item.onApple === 1;
        } else if (Platform.OS === 'android') {
          return item.onAndroid === 1;
        } else if (Platform.OS === 'web') {
          return item.onWeb === 1;
        }
      })
      .sort((a, b) => a.provider_name.localeCompare(b.provider_name));
    setProviders(prs);
  };

  const getSubscriptions = useCallback(async () => {
    setIsLoading(true);
    WearableService.getSubscriptions()
      .then((subscriptions) => {
        filterProviders(subscriptions);
      })
      .catch((err) => {
        setError(err);
        addAnalyticsLog({ function: 'getSubscriptions', data: err, logType: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
        setRefreshing(false);
      });
  }, [addAnalyticsLog]);

  useFocusEffect(
    useCallback(() => {
      getSubscriptions();
    }, [getSubscriptions])
  );

  useEffect(() => {
    if (refreshing == true) {
      getSubscriptions();
    }
  }, [refreshing, getSubscriptions]);

  useEffect(() => {
    if (providers.find((x) => x.provider_name === 'Apple' && x.disconnectedDate == undefined)) {
      isUserAuth().then(setCanUseSync);
    } else {
      setCanUseSync(false);
    }
  }, [isUserAuth, providers]);

  const connect = async (data: TerraProvider) => {
    WearableService.connect(data.providerId, data.resource)
      .then((response) => {
        if (Platform.OS === 'web') {
          const win = window.open(response.auth_url, '_blank', 'height=600, width=500');
          const timer = setInterval(function () {
            if (win?.closed) {
              clearInterval(timer);
              setTimeout(() => {
                getSubscriptions();
              }, 1000);
            }
          }, 200);
        } else {
          setAuthUrl(response.auth_url);
        }
      })
      .catch((err) => {
        setError(err);
        addAnalyticsLog({ function: 'connect', data: err, logType: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const disconnect = async (data: TerraProvider) => {
    WearableService.disconnect(data.terra_user_id)
      .then(() => {
        setTimeout(getSubscriptions, 2000);
      })
      .catch((err) => {
        addTelemetryLog(`Disconnect device fail: ${JSON.stringify(err)}`);
        addAnalyticsLog({ function: 'disconnect', data: err, logType: 'error' });
        setIsLoading(false);
      });
  };

  const connectApple = () => {
    setProviders((prv) =>
      prv.map((p) => {
        if (p.provider_name === 'Apple') {
          setAppleDisconnectedDate(p.disconnectedDate);
          p.disconnectedDate = undefined;
        }
        return p;
      })
    );
  };

  const disconnectApple = () => {
    setProviders((prv) =>
      prv.map((p) => {
        if (p.provider_name === 'Apple') {
          p.disconnectedDate = appleDisconnectedDate;
          setAppleDisconnectedDate(undefined);
        }
        return p;
      })
    );
  };

  const btnToggleClick = async (checked: boolean, item: TerraProvider) => {
    try {
      if (item.provider_name == 'Apple') {
        if (!checked) {
          setIsLoading(true);
          disconnectApple();
          await disconnect(item);
        } else {
          connectApple();
          initConnection(item)
            .then((success: boolean) => {
              if (!success) {
                disconnectApple();
              }
            })
            .catch((err) => {
              disconnectApple();
              setError(err);
            });
        }
      } else {
        if (!checked) {
          await disconnect(item);
        } else {
          await connect(item);
        }
      }
    } catch (err) {
      setIsLoading(false);
      addTelemetryLog(`${checked ? 'Disconnect' : 'Connect'} device fail: ${JSON.stringify(err)}`);
      addAnalyticsLog({
        function: 'btnToggleClick',
        data: { error: err, inputData: { checked: checked, item: item } },
        logType: 'error'
      });
    }
  };

  const showHint = (item?: TerraProvider) => {
    if (item == undefined) {
      setInfo(
        "You can connect more than one wearable. But don't connect wearables that are integrated with each other. \n\nApple Health, for instance, collects the data from all wearables with native iOS integration. So you only need the connection to Apple to send data from all your wearables."
      );
      setVisibleInfoModal(true);
    } else {
      if (item.disconnectedDate != null) {
        setInfo(
          `Connected on ${moment(item.connectedDate).format('YYYY-MM-DD')} \n Disconnected on ${moment(
            item.disconnectedDate
          ).format('YYYY-MM-DD')}`
        );
      } else {
        let strReceivedTime = '';
        if (item.terra_last_webhook_update != null) {
          const duration = moment.duration(moment(Date()).diff(moment(item.terra_last_webhook_update)));
          if (duration.asDays() > 0) {
            strReceivedTime = `Data last received :  ${moment(item.terra_last_webhook_update).format(
              'YYYY-MM-DD hh:mm'
            )}`;
          } else {
            if (duration.asHours() > 0) {
              strReceivedTime = `Data last received :  ${duration.asHours()} hours ago`;
            } else {
              strReceivedTime = `Data last received :  ${duration.asMinutes} minutes ago`;
            }
          }
        }
        setInfo(`Connected on ${moment(item.connectedDate).format('YYYY-MM-DD')} \n ${strReceivedTime}`);
      }
      setVisibleInfoModal(true);
    }
  };

  const renderProviders = () => {
    return providers
      .sort((a, b) =>
        (a.connectedDate !== null && b.connectedDate === null) ||
        a.provider_name.toLowerCase() < b.provider_name.toLowerCase()
          ? -1
          : 1
      )
      .map((item) => {
        return (
          <ProviderItem
            key={item.provider_name}
            data={item}
            btnToggleClick={(checked) => {
              setCheckedProvider({ checked: checked, item: item });
              setVisibleConfirmModal(true);
            }}
            showHint={() => showHint(item)}
            disabled={(isLoading || isSyncTerraDataLoading) && !refreshing}
          />
        );
      });
  };

  const handleWebViewNavigationStateChange = (newNavState: any) => {
    const { url } = newNavState;
    if (!url) return;

    //enable done button
    if (url.includes('auth-success') || url.includes('auth-failure')) {
      setTimeout(() => {
        setBtnTitle('Done');
      }, 1150);
    }
  };

  const handleSyncData = () => {
    syncTerraData();
  };

  const onRefresh = useCallback(() => {
    setRefreshing(true);
  }, []);

  const renderWebView = () => {
    return (
      <>
        <WebView
          userAgent="Mozilla/5.0 (Linux; Android 10; Redmi Note 8 Pro) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.88 Mobile Safari/537.36"
          source={{ uri: authUrl }}
          onNavigationStateChange={handleWebViewNavigationStateChange}
        />
        <View style={{ alignItems: 'center', justifyContent: 'center', marginBottom: 20, marginTop: 10 }}>
          <Button
            status="primary"
            disabled={false}
            size="small"
            style={{ marginLeft: 40, marginRight: 50 }}
            onPress={() => {
              setAuthUrl('');
              setBtnTitle('Cancel');
              getSubscriptions();
            }}>
            {btnTitle}
          </Button>
        </View>
      </>
    );
  };

  useEffect(() => {
    const backAction = () => {
      if (authUrl != undefined && authUrl.length > 0) {
        setAuthUrl('');
        return true;
      }

      if (visibleInfoModal == true) {
        setVisibleInfoModal(false);
        return true;
      }
      return false;
    };

    const backHandler = BackHandler.addEventListener('hardwareBackPress', backAction);

    return () => backHandler.remove();
  }, [visibleInfoModal, authUrl]);

  const renderList = () => {
    return (
      <Layout level="2" style={[styleContainer.screenContainer]}>
        {(isLoading || isSyncTerraDataLoading) && !refreshing && (
          <Modal transparent={true}>
            <View
              style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                height: '100%',
                zIndex: 999,
                top: 0,
                left: 0,
                width: uh.currentViewPort(),
                backgroundColor: 'rgba(0, 0, 0, 0.5)'
              }}>
              <Spinner size="giant" />
            </View>
          </Modal>
        )}
        <SafeAreaView style={{ flex: 1 }}>
          <ScrollView
            contentContainerStyle={{ flexGrow: 1 }}
            alwaysBounceVertical={true}
            refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}>
            <View style={[styleContainer.screenContainer, settingStyles.settingScreenContainer]}>
              <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Text category="s1">Connected Wearables </Text>
                <TouchableOpacity onPress={() => showHint()}>
                  <Icon name="info-outline" fill={th['color-info-500']} style={styleContainer.infoIcon}></Icon>
                </TouchableOpacity>
              </View>
              <Divider
                appearance="default"
                style={{ marginTop: 5, marginBottom: 15, backgroundColor: th['color-primary-500'] }}
              />

              {renderProviders()}
              {canUseSync && (
                <Button
                  size="giant"
                  style={{ marginTop: 10 }}
                  onPress={handleSyncData}
                  accessible={true}
                  accessibilityLabel="Sync Wearables"
                  testID="button_sync_wearables">
                  <Text status="primary" category="s2">
                    Sync Wearables
                  </Text>
                </Button>
              )}
              <InfoModal
                message={info}
                visible={visibleInfoModal}
                closeBtnClick={() => {
                  setVisibleInfoModal(false);
                }}
              />
              <TerraInfoModal
                visible={visibleConfirmModal}
                primaryButtonClick={() => {
                  btnToggleClick(checkedProvider.checked, checkedProvider.item);
                  setVisibleConfirmModal(false);
                }}
                secondaryButtonClick={() => {
                  setVisibleConfirmModal(false);
                }}
              />
              <ErrorModal visible={error} message={error} />
            </View>
          </ScrollView>
        </SafeAreaView>
      </Layout>
    );
  };

  //view
  if (authUrl && authUrl.length > 0) {
    return renderWebView();
  } else {
    return renderList();
  }
};

export default ConnectedDevicesScreen;
