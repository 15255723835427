import { Text } from '@ui-kitten/components';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { UIHelper as uh } from '../../../core';

// styles
const styleContainer = StyleSheet.create({
  container: {
    marginTop: uh.h2DP(12),
    flexDirection: 'row',
    flex: 1,
    backgroundColor: 'red',
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  text: {
    marginRight: 16,
    color: 'white'
  }
});

const NotificationHiddenItem = () => {
  return (
    <View style={styleContainer.container}>
      <Text category="s1" style={styleContainer.text}>
        Delete
      </Text>
    </View>
  );
};

export default NotificationHiddenItem;
