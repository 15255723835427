import { Button, Card, IndexPath, Input, Layout, Radio, RadioGroup, Text } from '@ui-kitten/components';
import { Keyboard, StyleSheet } from 'react-native';
import React, { useState } from 'react';
import { RootStackScreenProps, ValidateError, NumberHelper as nh } from 'src/core';
import { heightValidator, weightValidator } from 'src/core/validators/InputValidator';
import { useChangeAttributes, useGetUserAttributes } from 'src/core/hooks/useUser';

import ActivityLevelSelect from 'src/components/shared/ActivityLevelSelect';
import { BackendApi } from 'src/api/shared';
import { ErrorMessage } from 'src/components/shared';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import Spinner from 'react-native-loading-spinner-overlay';
import { StackActions } from '@react-navigation/native';
import { useAnalytics } from 'src/core/hooks/useAnalytics';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';

const FaceScanInputScreen = ({ navigation }: RootStackScreenProps<'FaceScanInput'>) => {
  const { addAnalyticsLog } = useAnalytics('FaceScanInputScreen.tsx');

  const transMessage = {
    messageDetails: 'Complete the following details',
    labelHeight: 'Height (cm)',
    labelWeight: 'Weight (kg)',
    labelActivityLevel: 'Activity Level',
    labelSmoking: 'Smoking',
    labelBloodPressure: 'Are you taking blood pressure medication?',
    labelHypertension: 'Do you have Hypertension?',
    labelDiabetic: 'Are you diabetic?',
    diabeticNone: 'None',
    diabeticType1: 'Type 1',
    diabeticType2: 'Type 2',
    yes: 'Yes',
    no: 'No',
    buttonContinue: 'Continue'
  };

  const t = useTranslationFunc(transMessage);

  const styles = StyleSheet.create({
    container: {
      flexDirection: 'column',
      flex: 1,
      padding: 16
    },
    summaryTop: {
      //marginTop: 16
    },
    fieldLabel: {
      marginTop: 16
    },
    textInput: {
      alignSelf: 'stretch',
      marginHorizontal: 22,
      marginBottom: 11
    },
    button: {
      marginTop: 16,
      marginBottom: 8
    },
    inputField: {
      marginTop: 6
    }
  });

  const diabetics = ['none', 'type1', 'type2'];

  const userAttributes = useGetUserAttributes();
  const [error, setError] = useState<ValidateError>({ name: '', message: '' });
  const [heightInput, setHeight] = useState(userAttributes.height ?? '');
  const [weightInput, setWeight] = useState(userAttributes.weight ?? '');
  const [smokeInput, setSmoke] = useState(Number(userAttributes.smoker == 0));
  const [diabeticInput, setDiabetic] = useState(userAttributes.diabetic ?? 0);
  const [hypertensionInput, setHypertension] = useState(Number(userAttributes.hypertension == 0));
  const [bloodPressureInput, setBloodPressureInput] = useState(Number(userAttributes.bloodpressureMedication == 0));
  const [selectedActivityLevel, setSelectedActivityLevel] = useState<IndexPath>(
    new IndexPath(userAttributes.activityLevel ?? 3)
  );

  const [isLoading, setIsLoading] = useState(false);
  const changeAttributes = useChangeAttributes();

  const onContinuePress = async () => {
    Keyboard.dismiss();
    const heightError = heightValidator(String(heightInput));
    if (heightError) {
      setError({ name: 'height', message: heightError });
      return;
    }

    const weightError = weightValidator(String(weightInput));
    if (weightError) {
      setError({ name: 'weight', message: weightError });
      return;
    }

    setError({ name: '', message: '' });
    const userFaceScanInput = {
      enum_ent_sex: userAttributes.gender,
      cm_ent_height: Number(heightInput),
      kg_ent_weight: Number(weightInput),
      yr_ent_age: nh.getAge(String(userAttributes.birthdate)),
      bool_ent_smoker: smokeInput === 0,
      bool_ent_hypertension: hypertensionInput === 0,
      bool_ent_bloodPressureMedication: bloodPressureInput === 0,
      enum_ent_diabetic: diabetics[diabeticInput]
    };

    const updatedValues = {
      name: userAttributes.name,
      nickname: userAttributes.nickname,
      gender: userAttributes.gender,
      birthdate: userAttributes.birthdate,
      email: userAttributes.email,
      height: Number(heightInput),
      weight: Number(weightInput),
      cognitoId: userAttributes.sub,
      smoker: smokeInput === 0,
      bloodpressureMedication: bloodPressureInput === 0,
      hypertension: hypertensionInput === 0,
      diabetic: diabeticInput,
      activityLevel: selectedActivityLevel.row
    };

    // update user info
    try {
      setIsLoading(true);
      await BackendApi.patch('/users', updatedValues);
      changeAttributes({ ...updatedValues, sub: userAttributes.sub });
    } catch (err) {
      addAnalyticsLog({
        function: 'onContinuePress',
        data: { error: err, inputData: updatedValues },
        logType: 'error'
      });
      setError({ name: '', message: "Can't update user!" });
      setIsLoading(false);
      return;
    }

    setIsLoading(false);
    navigation.dispatch(
      StackActions.replace('FaceScanGuide', {
        faceScanInputs: userFaceScanInput
      })
    );
  };

  return (
    <KeyboardAwareScrollView keyboardShouldPersistTaps={'handled'} bounces={false}>
      {isLoading && <Spinner visible={true} />}
      <Layout style={styles.container} level="2">
        <Card>
          <ErrorMessage message={error.message} />
          <Text style={styles.summaryTop} category="h6">
            {t('messageDetails')}
          </Text>
          <Text category="s1" style={styles.fieldLabel}>
            {t('labelHeight')}
          </Text>
          <Input
            placeholder=""
            status={error.name == 'height' ? 'danger' : 'basic'}
            style={styles.inputField}
            keyboardType="numeric"
            value={String(heightInput)}
            onChangeText={(text) => setHeight(text)}></Input>

          <Text category="s1" style={styles.fieldLabel}>
            {t('labelWeight')}
          </Text>
          <Input
            placeholder=""
            status={error.name == 'weight' ? 'danger' : 'basic'}
            style={styles.inputField}
            keyboardType="numeric"
            value={String(weightInput)}
            onChangeText={(text) => setWeight(text)}></Input>
          <Text category="s1" style={styles.fieldLabel}>
            {t('labelActivityLevel')}
          </Text>
          <ActivityLevelSelect
            selectedActivityLevel={selectedActivityLevel}
            setSelectedActivityLevel={setSelectedActivityLevel}
          />
          <Text category="s1" style={styles.fieldLabel}>
            {t('labelSmoking')}
          </Text>
          <RadioGroup selectedIndex={smokeInput} onChange={(index) => setSmoke(index)}>
            <Radio>{t('yes')}</Radio>
            <Radio>{t('no')}</Radio>
          </RadioGroup>

          <Text category="s1" style={styles.fieldLabel}>
            {t('labelBloodPressure')}
          </Text>
          <RadioGroup selectedIndex={bloodPressureInput} onChange={(index) => setBloodPressureInput(index)}>
            <Radio>{t('yes')}</Radio>
            <Radio>{t('no')}</Radio>
          </RadioGroup>

          <Text category="s1" style={styles.fieldLabel}>
            {t('labelHypertension')}
          </Text>
          <RadioGroup selectedIndex={hypertensionInput} onChange={(index) => setHypertension(index)}>
            <Radio>{t('yes')}</Radio>
            <Radio>{t('no')}</Radio>
          </RadioGroup>

          <Text category="s1" style={styles.fieldLabel}>
            {t('labelDiabetic')}
          </Text>
          <RadioGroup selectedIndex={diabeticInput} onChange={(index) => setDiabetic(index)}>
            <Radio>{t('diabeticNone')}</Radio>
            <Radio>{t('diabeticType1')}</Radio>
            <Radio>{t('diabeticType2')}</Radio>
          </RadioGroup>
          <Button style={styles.button} status="primary" onPress={onContinuePress}>
            {t('buttonContinue')}
          </Button>
        </Card>
      </Layout>
    </KeyboardAwareScrollView>
  );
};

export default FaceScanInputScreen;
