import { Divider, Layout, Text, useTheme } from '@ui-kitten/components';
import { PillarScore, ScoreItem, UIHelper as uh } from '../../core';
import { StyleSheet, ViewProps } from 'react-native';

import LineChart from './conponents/LineChart';
import React from 'react';
import moment from 'moment';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';

//props
interface ScoreHistoryProps extends ViewProps {
  scoreItems: Array<ScoreItem>;
  pillarScore: PillarScore;
  isLoading: boolean;
}

// styles
const styleContainer = StyleSheet.create({
  container: {
    flex: 1
  },
  score: {
    marginTop: uh.h2DP(4)
  },
  percentage: {},
  divider: { marginTop: uh.h2DP(16) }
});

const ScoreHistory = ({ scoreItems, pillarScore, isLoading }: ScoreHistoryProps) => {
  const th = useTheme();

  const pillarColor = pillarScore.id == '0' ? th['color-primary-500'] : th['color-' + pillarScore.type + '-500'];

  let strWeek = '';
  if (scoreItems.length > 0) {
    const fromDate = moment(scoreItems[scoreItems.length - 1].date);
    const toDate = moment(scoreItems[0].date);
    strWeek = `${fromDate.format('D MMM')} - ${toDate.format('D MMM')}`;
  }

  const transMessage = {
    avgWeeklyScore: 'Avg Weekly Score',
    pillarScorePercent: '{{pillarScore}}%'
  };
  const t = useTranslationFunc(transMessage);

  // view
  return (
    <Layout level="2" style={styleContainer.container}>
      <Text category="c2">{t('avgWeeklyScore')}</Text>
      <Text category="h5" style={{ ...styleContainer.score, ...{ color: pillarColor } }}>
        {t('pillarScorePercent', { pillarScore: pillarScore.score })}
      </Text>
      <Text category="c1">{strWeek}</Text>
      <LineChart
        type={pillarScore.type}
        score={pillarScore.score}
        color={pillarColor}
        graphData={scoreItems}
        isLoading={isLoading}
      />
      <Divider style={styleContainer.divider} />
    </Layout>
  );
};

export default ScoreHistory;
