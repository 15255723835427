import { ApplicationState, useAppStore } from '../store';
import { useCallback, useEffect } from 'react';

import { UserAttributes } from '../types/UserAttributes';
import UserService from 'src/api/user';
import { useAnalytics } from './useAnalytics';
import { useIsUserLogged } from './useUser';

const useSetMarkersInitLoading = (): ((loading: boolean) => void) => {
  return useAppStore((state: ApplicationState) => state.setExtraUserAttributesLoading);
};

const useSetMarkersInitError = (): ((hasError: boolean) => void) => {
  return useAppStore((state: ApplicationState) => state.setExtraUserAttributesError);
};

const useChangeUserAttributes = (): ((attributes: Partial<UserAttributes>) => void) => {
  return useAppStore((state: ApplicationState) => state.changeUserAttributes);
};

export const useIsMarkersInitLoading = (): boolean => {
  return useAppStore((state: ApplicationState) => state.initExtraUserAttributes.isLoading);
};
export const useHasMarkersInitError = (): boolean => {
  return useAppStore((state: ApplicationState) => state.initExtraUserAttributes.hasError);
};

export const useMarkersInitializer = () => {
  const setLoading = useSetMarkersInitLoading();
  const setError = useSetMarkersInitError();
  const changeUserAttributes = useChangeUserAttributes();
  const isUserLogged = useIsUserLogged();

  const { addAnalyticsLog } = useAnalytics('useMarkers.ts');

  const loadMarkers = useCallback(() => {
    setLoading(true);
    UserService.getExtraUserAttributes()
      .then((data) => {
        changeUserAttributes(data);
      })
      .catch((error) => {
        addAnalyticsLog({ logType: 'error', function: 'useInitMarkers', data: JSON.stringify(error) });
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [addAnalyticsLog, changeUserAttributes, setError, setLoading]);

  useEffect(() => {
    if (isUserLogged) {
      loadMarkers();
    }
  }, [isUserLogged, loadMarkers]);
};
