import { SafeAreaView, StyleSheet } from 'react-native';

import { Layout } from '@ui-kitten/components';
import PillarDetailContainer from 'src/features/pillars/PillarDetailContainer';
import { PillarScore } from 'src/core';
import React from 'react';
import { RootStackScreenProps } from 'src/core/types/RootNavigationParamList';

//styles
const styleContainer = StyleSheet.create({
  screenContainer: { flex: 1 },
  safeAreaView: { flex: 1 }
});

const PillarScreen = ({ navigation, route }: RootStackScreenProps<'Pillar'>) => {
  const [pillarData, setPillarData] = React.useState<PillarScore>();
  React.useEffect(() => {
    const headerTitle = (route as any).params.name;
    setPillarData({ ...(route as any).params } as any);
    navigation.setOptions({
      headerTitle: headerTitle
    });
  }, [route, navigation]);

  //view
  return (
    <Layout level="2" style={[styleContainer.screenContainer]}>
      <SafeAreaView style={styleContainer.screenContainer}>
        {pillarData && <PillarDetailContainer pillarSummaryData={pillarData} />}
      </SafeAreaView>
    </Layout>
  );
};

export default PillarScreen;
