import Svg, { Circle, G, Path } from 'react-native-svg';

import React from 'react';
import { SimpleIconProps } from 'src/core';

const TalkToKaliIcon = ({ size = 40, color = '#46D7CB', backgroundColor = '#DCFBED' }: SimpleIconProps) => (
  <Svg width={`${size}px`} height={`${size}px`} viewBox="0 0 40 40">
    <G data-name="Layer 2">
      <G data-name="arrow-ios-forward">
        <Circle cx="20" cy="20" r="20" fill={backgroundColor} />
        <Path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M28 17H12V28H28V17ZM12 15C10.8954 15 10 15.8954 10 17V28C10 29.1046 10.8954 30 12 30H28C29.1046 30 30 29.1046 30 28V17C30 15.8954 29.1046 15 28 15H12Z"
          fill={color}
        />
        <Circle cx="17" cy="20" r="1" fill={color} />
        <Circle cx="23" cy="20" r="1" fill={color} />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.5911 23.0193C22.8827 23.1023 23.0579 23.4296 22.9825 23.7504C22.826 24.4155 22.4301 24.9858 21.8916 25.384C21.3541 25.7815 20.6957 25.9935 20.0253 25.9999C19.3549 26.0062 18.6925 25.8067 18.147 25.4203C17.6007 25.0333 17.1926 24.472 17.0211 23.8113C16.9384 23.4927 17.1061 23.1606 17.3958 23.0697C17.6855 22.9787 17.9873 23.1632 18.07 23.4818C18.1596 23.8269 18.3841 24.161 18.7376 24.4115C19.092 24.6624 19.5429 24.8044 20.0159 24.8C20.4889 24.7955 20.9357 24.645 21.283 24.3882C21.6294 24.132 21.8451 23.7952 21.9264 23.4498C22.0019 23.129 22.2995 22.9363 22.5911 23.0193Z"
          fill={color}
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.6667 12C20.6667 12.3682 20.3682 12.6667 20 12.6667C19.6318 12.6667 19.3333 12.3682 19.3333 12C19.3333 11.6318 19.6318 11.3333 20 11.3333C20.3682 11.3333 20.6667 11.6318 20.6667 12ZM21 13.7324C21.5978 13.3866 22 12.7403 22 12C22 10.8954 21.1046 10 20 10C18.8954 10 18 10.8954 18 12C18 12.7403 18.4022 13.3866 19 13.7324V15H21V13.7324Z"
          fill={color}
        />
      </G>
    </G>
  </Svg>
);

export default TalkToKaliIcon;
