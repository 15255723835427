import ActionListItem from '../components/AddActionListItem';
import { FlatList } from 'react-native';
import { ProtocolDetailsAction } from 'src/core/types/Protocols';
import React from 'react';
import { UIHelper as uh } from 'src/core';

interface ProtocolActionListProps {
  actions: ProtocolDetailsAction[];
}
const ProtocolActionList = ({ actions }: ProtocolActionListProps) => {
  const renderListItem = ({ item }: { item: ProtocolDetailsAction }) => {
    return (
      <ActionListItem
        expandable={true}
        action={item.action}
        duration={item.endDay - item.startDay + 1}
        alertTime={item.alertTime}
        startDay={item.startDay}
        endDay={item.endDay}
        frequency={item.action.repeatFrequencyDays}
      />
    );
  };
  return (
    <FlatList scrollEnabled={false} style={{ marginVertical: uh.h2DP(8) }} renderItem={renderListItem} data={actions} />
  );
};

export default ProtocolActionList;
