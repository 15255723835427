
       const __ReactNativeSvgLoader = require('svgLoader').default({
  "memo":"true"
}
);
        import { CheckBox, Layout, Text } from '@ui-kitten/components';
import { GuidePager, GuideScreen } from 'src/components/scan/GuidePager';
import { Platform, StyleSheet, TouchableOpacity, View } from 'react-native';
import React, { useState } from 'react';
import { RootStackScreenProps, UIHelper as uh } from 'src/core';

import AHILoadingScreen from 'src/components/scan/components/ScanLoading/container';
import { FaceScanAvatar } from 'src/core/icons/scan/FaceScanAvatar';
import { FaceScanAvatarBackground } from 'src/core/icons/scan/FaceScanAvatarBackground';

  const FaceScanStars = __ReactNativeSvgLoader(import('../../../assets/images/face_scan/face_scan_stars.svg'));
  ;
import { FlexSpacer } from 'src/core/helpers/LayoutHelper';
import { ScanGuideDescription } from 'src/components/scan/ScanGuideDescription';
import { useGetIsMultiScanUserInit } from 'src/core/hooks/useMultiScan';
import { useIsLightColorScheme } from 'src/core/hooks/useIsLightColorScheme';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useScanGuideSkip } from 'src/core/hooks/useScanGuideSkip';
import uuid from 'react-native-uuid';

uuid.v4();

const SCAN_ICON_SIZE = Platform.OS === 'ios' ? uh.currentViewPort() - 50 : uh.currentViewPort() - 100;

const FaceScanGuideScreen = ({ route, navigation }: RootStackScreenProps<'FaceScanGuide'>) => {
  const { setScanGuideSkip } = useScanGuideSkip();

  const isLightTheme = useIsLightColorScheme();
  const [checkBox, setCheckBox] = useState(false);

  const insets = useSafeAreaInsets();
  const isMultiScanInit = useGetIsMultiScanUserInit();

  const styles = StyleSheet.create({
    safeAreaView: { flex: 1 },
    titleText: {
      textAlign: 'center',
      marginHorizontal: 44,
      marginVertical: 11
    },
    tickTitleText: {},
    container: {
      flex: 1,
      marginTop: insets.top
    },
    guideScreenContainer: {
      height: '100%',
      justifyContent: 'space-between',
      paddingTop: 24
    },
    guideScreenContainer2: {
      height: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: 24
    },
    guideScreenText: {
      paddingVertical: Platform.OS === 'ios' ? 6 : 2,
      textAlign: 'center'
    },
    imageStyle: {
      position: 'absolute',
      height: uh.height() - 160,
      width: uh.currentViewPort(),
      resizeMode: 'cover'
    },
    imageStyle2: { alignItems: 'center' },
    guide1HeaderContainer: {
      paddingTop: 10,
      width: '100%',
      height: 300
    },
    guide2HeaderContainer: {
      paddingTop: 10,
      height: uh.height() - 170
    },
    titleText1: {
      textAlign: 'center',
      marginHorizontal: 44,
      marginVertical: 11,
      color: 'black'
    },
    titleText2: {
      textAlign: 'center',
      marginHorizontal: 44,
      marginVertical: 11,
      color: 'white'
    },
    tickTitleContainer: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 6
    },
    blueTick: {
      height: 16,
      width: 16,
      marginHorizontal: 11
    },
    tickTitleText1: {
      color: 'black'
    },
    tickTitleText2: {
      color: 'white'
    },
    star: {
      height: 26,
      width: 26,
      marginHorizontal: 11
    },
    subtitle: {
      fontSize: 20,
      textAlign: 'center',
      width: '60%',
      alignSelf: 'center',
      marginTop: 11,
      color: 'white'
    },
    bottomSectionContainer: {
      marginTop: 30,
      height: '17%'
    },
    loading: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      height: '100%',
      zIndex: 999,
      top: 0,
      left: 0,
      width: uh.currentViewPort(),
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    backdropStyle: { backgroundColor: 'rgba(0, 0, 0, 0.5)' }
  });

  const faceScanGuidePage1: GuideScreen = {
    renderGuideScreen: () => {
      return (
        <Layout level="2" style={styles.guideScreenContainer}>
          <ScanGuideDescription scanType={'Face'} />
          <View style={styles.imageStyle2}>
            <FlexSpacer />
            <FaceScanAvatar height={SCAN_ICON_SIZE} width={SCAN_ICON_SIZE} color={isLightTheme ? '#2E3A59' : 'white'} />
          </View>
        </Layout>
      );
    }
  };
  const faceScanGuidePage2: GuideScreen = {
    renderGuideScreen: () => {
      return (
        <Layout level="2" style={styles.guideScreenContainer2}>
          <View>
            <Text style={styles.guideScreenText} category="h5">
              Taking the perfect Face Scan
            </Text>
            <Text style={styles.guideScreenText} category="p1">
              Even lighting to face
            </Text>
            <Text style={styles.guideScreenText} category="p1">
              Remove eyewear and masks
            </Text>
            <Text style={styles.guideScreenText} category="p1">
              Position face in outline to start
            </Text>
          </View>
          <View>
            <FaceScanAvatarBackground
              height={SCAN_ICON_SIZE}
              width={SCAN_ICON_SIZE}
              color={isLightTheme ? 'white' : '#2E3A59'}
              backgroundColor={isLightTheme ? '#535967' : '#EDF1F7'}
            />
          </View>
          <View>
            <View style={{ alignItems: 'center', marginVertical: 8 }}>
              <FaceScanStars />
            </View>
            <Text category="p1">Aim for five starts for great results</Text>
          </View>
          <TouchableOpacity
            onPress={() => setCheckBox((checked) => !checked)}
            activeOpacity={1}
            style={[{ flexDirection: 'row', alignSelf: 'flex-start', marginLeft: 24, marginBottom: 16 }]}>
            <CheckBox checked={checkBox} onChange={(checked) => setCheckBox(checked)} style={{ marginRight: 8 }} />
            <Text>{"Don't show again"}</Text>
          </TouchableOpacity>
        </Layout>
      );
    }
  };

  const scan = async () => {
    navigation.navigate('ScanLoadingScreen', {
      scanInputs: (route.params as any).faceScanInputs,
      scanType: 'face',
      callback: () => {
        setScanGuideSkip(checkBox, 'face');
        if (checkBox) {
          navigation.navigate('ScanGettingStarted');
        }
      }
    });
  };

  const guidePages = [faceScanGuidePage1, faceScanGuidePage2];
  return (
    <View style={{ flex: 1 }}>
      {isMultiScanInit ? (
        <GuidePager
          guides={guidePages}
          onLastBackPress={() => {
            navigation.goBack();
          }}
          onLastNextPress={async () => {
            scan();
          }}
          lastButtonTitle="Start Scan"
        />
      ) : (
        <AHILoadingScreen scanType="face" onCancel={() => navigation.goBack()} />
      )}
    </View>
  );
};

export default FaceScanGuideScreen;

        