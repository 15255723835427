import { StyleSheet, View } from 'react-native';
import { useGetSocket, useWebsocketsInit } from 'src/core/hooks/useWebsockets';

import ChatLayout from './ChatLayout';
import ConversationsEmptyList from './components/ConversationsEmptyList';
import ConversationsList from './components/ConversationsList';
import { ErrorModal } from 'src/components/shared/ErrorModal';
import React from 'react';
import { RootStackChatProps } from 'src/core/types/ChatNavigationParamList';
import { Spinner } from '@ui-kitten/components';
import { useConversations } from 'src/core/hooks/useConverstations';

const styleContainer = StyleSheet.create({
  loading: {
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    transform: [{ translateY: -50 }]
  }
});

const ChatConversations = ({ navigation }: RootStackChatProps<'Conversations'>): JSX.Element => {
  const { loading, conversations, error } = useConversations();
  const socket = useGetSocket();
  const { socketError } = useWebsocketsInit();

  if (error || socketError) {
    return <ErrorModal visible={true} message={error ?? socketError} closeBtnClick={() => navigation.goBack()} />;
  }

  if (loading || !socket) {
    return (
      <ChatLayout>
        <View style={styleContainer.loading}>
          <Spinner size="giant" />
        </View>
      </ChatLayout>
    );
  }

  return (
    <ChatLayout>
      {conversations.length ? <ConversationsList conversations={conversations} /> : <ConversationsEmptyList />}
    </ChatLayout>
  );
};

export default ChatConversations;
