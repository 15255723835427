import { ActionTip } from 'src/core';
import { SleepStat, SleepStatInfo } from 'src/core/types/SleepStat';
import { StateCreator } from 'zustand';

export interface SleepStatSlice {
  sleepStat: SleepStat;
  sleepActionTips: ActionTip[];
  setSleepStat: (sleepStat: SleepStat) => void;
  setSleepActionTips: (sleepActionTips: ActionTip[]) => void;
}

export const createSleepStatSlice: StateCreator<SleepStatSlice, [], [], SleepStatSlice> = (set) => ({
  sleepStat: {
    stats: {} as SleepStatInfo,
    heartRateHrSamples: [],
    heartRateHistory: [],
    sleepHypnogramSamples: [],
    sleepHypnogramHistory: []
  } as SleepStat,
  sleepActionTips: [],
  setSleepStat: (sleepStat: SleepStat) => set(() => ({ sleepStat })),
  setSleepActionTips: (sleepActionTips: ActionTip[]) => set(() => ({ sleepActionTips }))
});
