import { Button, Input, Modal, Spinner } from '@ui-kitten/components';
import React, { useEffect, useState } from 'react';
import { useAssignProtocol, useGetProtocols, useProtocolEffect } from 'src/core/hooks/useActions';

import AddActionLayout from '../AddActionLayout';
import AddProtocolList from './AddProtocolList';
import { ErrorModal } from 'src/components/shared/ErrorModal';
import { Loading } from 'src/components/kalichat';
import PillarButtonList from 'src/components/shared/PillarButtonList';
import { ProtocolItem } from 'src/core/types/Protocols';
import RightIcon from '../../markers/components/RightIcon';
import { StyleSheet } from 'react-native';
import { UIHelper as uh } from 'src/core';
import { useNavigation } from '@react-navigation/native';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';

const PROTOCOLS_PER_PAGE = 20;

const AddProtocol = () => {
  const styleContainer = StyleSheet.create({
    loading: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      height: '100%',
      width: '100%'
    }
  });
  const transMessage = {
    addProtocolBtn: 'Add to my Schedule',
    allProtocolsLabel: 'All protocols',
    searchPlaceholder: 'Search...'
  };
  const t = useTranslationFunc(transMessage);
  const navigation = useNavigation();

  const [loading, error] = useProtocolEffect();
  const [assignProtocol, assignLoading, assignError] = useAssignProtocol(navigation.goBack);

  const protocols = useGetProtocols();
  const pillars = Array.from(
    new Set(
      protocols.reduce((prev: string[], curr: ProtocolItem) => {
        const p = curr.actions.map((action) => action.action.pillar);
        prev.push(...p);
        return prev;
      }, [])
    )
  );

  const [protocolIds, setProtocolIds] = useState<number[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [selectedPillar, setSelectedPillar] = useState<string>('all');
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    return () => {
      setPage(1);
    };
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return (
      <ErrorModal
        visible={true}
        message={error}
        closeBtnClick={() => {
          navigation.goBack();
        }}
      />
    );
  }

  return (
    <AddActionLayout>
      <Modal visible={assignLoading} style={styleContainer.loading}>
        <Spinner size="giant" />
      </Modal>
      <PillarButtonList
        defaultName={t('allProtocolsLabel')}
        onBtnClickHandler={(pillarName: string) => setSelectedPillar(pillarName)}
        shownPillars={pillars}
      />
      <Input
        style={{ marginTop: uh.h2DP(8) }}
        textStyle={{ paddingHorizontal: uh.h2DP(2), paddingVertical: uh.h2DP(8) }}
        placeholder={t('searchPlaceholder')}
        onChangeText={(text) => setSearchText(text)}
        value={searchText}
        accessoryRight={
          <RightIcon
            isSearching={searchText !== ''}
            onClick={() => {
              setSearchText('');
            }}
          />
        }
        accessible={true}
        accessibilityLabel={'add protocol searchbar'}
        testID={'add-protocol-searchbar'}
      />
      <AddProtocolList
        protocols={protocols
          .filter(
            (protocol) =>
              protocol.name.toLocaleLowerCase().match(searchText.toLocaleLowerCase()) &&
              (selectedPillar === 'all'
                ? true
                : protocol.actions.map((action: any) => action.action.pillar.toLowerCase()).includes(selectedPillar))
          )
          .slice(0, page * PROTOCOLS_PER_PAGE)}
        setProtocolIds={setProtocolIds}
        handleEndReached={() => setPage((prev) => prev + 1)}
      />
      <Button
        disabled={protocolIds.length === 0}
        onPress={() => assignProtocol(protocolIds)}
        size="large"
        style={{ width: '95%', alignSelf: 'center', marginBottom: uh.h2DP(8) }}>
        {t('addProtocolBtn')}
      </Button>
      <ErrorModal visible={assignError} message={assignError} />
    </AddActionLayout>
  );
};

export default AddProtocol;
