import { Linking, Platform, StyleSheet, Text, TextStyle, ViewStyle } from 'react-native';
import React, { memo } from 'react';

import Markdown from 'react-native-markdown-package';
import { UIHelper as uh } from 'src/core';
import { useTheme } from '@ui-kitten/components';

interface MarkdownStyles {
  view?: ViewStyle;
  text?: TextStyle;
  strong?: TextStyle;
  em?: TextStyle;
  autolink?: TextStyle;
  web?: TextStyle;
}

declare type MarkdownProps = React.ComponentProps<typeof Markdown> & {
  children: string;
  styles: MarkdownStyles;
};

const MarkdownText = ({ children, styles }: MarkdownProps) => {
  const th = useTheme();

  // Don't display empty (including only whitespace) paragraphs
  if (!children || children?.toString()?.trim()?.length === 0) {
    return null;
  }

  const markdownBaseStyles = StyleSheet.create({
    view: {
      ...styles.view
    },
    text: {
      fontSize: 14,
      ...styles.text
    },
    strong: {
      fontSize: uh.h2DP(14),
      ...styles.strong
    },
    em: {
      fontSize: uh.h2DP(14),
      ...styles.em
    },
    autolink: {
      textDecorationLine: 'underline',
      fontFamily: 'Poppins-SemiBold',
      color: th['text-basic-color'],
      ...styles.autolink
    },
    web: {
      color: th['text-basic-color'],
      fontSize: uh.h2DP(14),
      fontWeight: '400',
      fontFamily: 'Poppins-regular',
      ...styles.view,
      ...styles.text,
      ...styles.web
    }
  });

  return (
    <>
      {Platform.OS === 'web' ? (
        <Text style={markdownBaseStyles.web}>{children}</Text>
      ) : (
        <Markdown onLink={(url) => Linking.openURL(url)} styles={markdownBaseStyles}>
          {children.replaceAll('---', '\u2014')}
        </Markdown>
      )}
    </>
  );
};

export default memo(MarkdownText);
