import React, { useState } from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import { useGetDataSharingList, useSetDataSharingList } from 'src/core/hooks/useUser';

import { AxiosError } from 'axios';
import { CancelTenant } from 'src/components/tenants/modals/CancelTenant';
import { ErrorModal } from 'src/components/shared/ErrorModal';
import { Layout } from '@ui-kitten/components';
import Spinner from 'react-native-loading-spinner-overlay/lib';
import TenantItem from 'src/components/settings/dataSharing/TenantItem';
import UserService from 'src/api/user';
import { settingStyles } from './_settingStyles';
import { useAnalytics } from 'src/core/hooks/useAnalytics';
import { useDataSharing } from 'src/core/hooks/useDataSharing';

const DataSharingScreen = () => {
  const styleContainer = StyleSheet.create({
    screenContainer: {
      flex: 1
    }
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [cancelModal, setCancelModal] = useState<boolean>(false);
  const [cancelTenantKey, setCancelTenantKey] = useState<string>('');
  const [cancelTenantName, setCancelTenantName] = useState<string>('');
  const [error, setError] = useState<AxiosError>();
  const [visibleErrorModal, setVisibleErrorModal] = useState<boolean>(false);

  const { loadTenantFeatures } = useDataSharing();

  const { addAnalyticsLog } = useAnalytics('DataSharingScreen.tsx');

  const dataSharingList = useGetDataSharingList();
  const setDataSharingList = useSetDataSharingList();

  const renderTenants = () => {
    return dataSharingList.map((x) => {
      return (
        <TenantItem
          tenantName={x.tenantName}
          isActive={x.isActive}
          deactivatedOn={x.deactivatedOn}
          tenantKey={x.tenantKey}
          proUserName={x.proUserName}
          handleToggle={() => {
            setCancelModal(true);
            setCancelTenantKey(x.tenantKey);
            setCancelTenantName(x.tenantName);
          }}
          key={x.tenantId}
        />
      );
    });
  };

  const cancelTenant = () => {
    setLoading(true);
    UserService.cancelDataSharing(cancelTenantKey)
      .then(async () => {
        setError({} as AxiosError);
        return loadTenantFeatures();
      })
      .catch((err) => {
        setError(err);
        setVisibleErrorModal(true);
      })
      .finally(() => {
        UserService.getDataSharingList()
          .then((res) => setDataSharingList(res))
          .catch((err) => addAnalyticsLog({ function: 'cancelTenant', data: err, logType: 'error' }))
          .finally(() => {
            setCancelModal(false);
            setLoading(false);
          });
      });
  };

  return (
    <Layout level="2" style={[styleContainer.screenContainer, settingStyles.settingScreenContainer]}>
      <ScrollView>
        {loading && <Spinner visible={true} />}
        {renderTenants()}
        <CancelTenant
          visible={cancelModal}
          onFinalButtonClick={() => cancelTenant()}
          onSecondaryButton={() => setCancelModal(false)}
          proUserName={cancelTenantName}
        />
        <ErrorModal visible={visibleErrorModal} message={error} closeBtnClick={() => setVisibleErrorModal(false)} />
      </ScrollView>
    </Layout>
  );
};

export default DataSharingScreen;
