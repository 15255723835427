import { Socket } from 'socket.io-client';
import { StateCreator } from 'zustand';

export interface SocketsSlice {
  socket: Socket | undefined;
  setSocket: (socket: Socket | undefined) => void;
}

export const createSocketSlice: StateCreator<SocketsSlice, [], [], SocketsSlice> = (set) => ({
  socket: undefined,
  setSocket: (socket: Socket | undefined) => set(() => ({ socket }))
});
