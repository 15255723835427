import { Button, Divider, Text, useTheme } from '@ui-kitten/components';
import { StyleSheet, View } from 'react-native';

import React from 'react';
import { UIHelper as uh } from '../../../core';
import { useAppStore } from 'src/core/store';

const ResetScanGuideSkip = () => {
  const th = useTheme();

  const setScanGuideSkip = useAppStore((state) => state.setScanGuideSkip);

  const styleContainer = StyleSheet.create({
    text: {
      maxHeight: '80%',
      textAlignVertical: 'top',
      marginLeft: uh.w2DP(14)
    },
    caption: { marginBottom: uh.h2DP(4) },
    internals: { minHeight: 50 },
    refreshButton: {
      marginTop: uh.h2DP(10),
      marginBottom: uh.h2DP(10)
    }
  });

  return (
    <>
      <View style={{ marginTop: 32 }}>
        <Text
          category={'s1'}
          accessible={true}
          accessibilityLabel="Reset scan guide skip label"
          testID="reset_scan_guide_skip">
          Reset scan guide skip
        </Text>
        <Divider
          appearance="default"
          style={{ marginTop: 5, marginBottom: 15, backgroundColor: th['color-primary-500'] }}
        />
      </View>
      <Button
        status="primary"
        onPress={() => {
          setScanGuideSkip(
            new Map<string, boolean>([
              ['body', false],
              ['face', false],
              ['hrv', false],
              ['bloodwork', false]
            ])
          );
        }}
        style={styleContainer.refreshButton}
        accessible={true}
        accessibilityLabel="Reset scan guide skip button"
        testID="reset_scan_guide_skip_btn">
        Reset Scan Guide Skip
      </Button>
    </>
  );
};

export default ResetScanGuideSkip;
