import React, { useCallback, useEffect, useRef, useState } from 'react';

import { ActionItem } from 'src/core/types/ActionItem';
import ActionItemService from '../../../api/actionItem/index';
import ActionLayout from '../../../features/actions/ActionLayout';
import { ActionScheduleList } from 'src/features/actions/ActionScheduleList';
import { ErrorModal } from 'src/components/shared/ErrorModal';
import RecurrenceBottomSheet from 'src/features/actions/RecurrenceBottomSheet';
import RefreshControl from 'src/components/shared/RefreshControl';
import { ScrollView } from 'react-native';
import Spinner from 'react-native-loading-spinner-overlay';
import { mainStyles } from '../_mainStyles';
import { useIsFocused } from '@react-navigation/native';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';

const ScheduleInactiveActionsScreen = () => {
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [actions, setActions] = useState<ActionItem[]>([]);
  const isFocused = useIsFocused();
  const [isLoading, setIsLoading] = useState(false);
  const sheetRef = useRef<any>(null);
  const [currentAction, setCurrentAction] = useState<ActionItem | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<any>(null);

  const transMessage = {
    actionsInactiveTitle: 'Inactive',
    actionsEmpty: 'No actions for now',
    actionsAllDay: 'All Day',
    actionsMorning: 'Morning',
    actionsAfternoon: 'Afternoon',
    actionsEvening: 'Evening'
  };

  const t = useTranslationFunc(transMessage);

  const fetchActionData = useCallback(() => {
    setIsLoading(true);
    ActionItemService.getScheduledInactiveActions()
      .then((data) => {
        setActions(data);
      })
      .catch((error) => {
        setActions([]);
        setErrorMessage(error);
      })
      .finally(() => {
        setIsLoading(false);
        setRefreshing(false);
      });
  }, []);

  useEffect(() => {
    if (refreshing) {
      fetchActionData();
    }
  }, [refreshing, fetchActionData]);

  useEffect(() => {
    if (isFocused) {
      fetchActionData();
    }
  }, [isFocused, fetchActionData]);

  const onRefresh = useCallback(() => {
    setRefreshing(true);
  }, []);

  const handleActivateAction = (id: number, frequency: number, duration: number): void => {
    setIsLoading(true);
    ActionItemService.activateInactiveActiveAction(id, frequency, duration)
      .then(() => ActionItemService.getScheduledInactiveActions())
      .then((data) => {
        setActions(data);
      })
      .catch((error) => {
        setErrorMessage(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleActivate = (action: ActionItem): void => {
    if (!sheetRef.current) {
      return;
    }
    setCurrentAction(action);
    sheetRef.current.snapToIndex(0);
    sheetRef.current.present();
  };

  return (
    <>
      <ActionLayout>
        <Spinner visible={isLoading && !refreshing} />
        <ScrollView
          contentContainerStyle={[{ flexGrow: 1 }, mainStyles.mainScreenContainer]}
          refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}>
          <ActionScheduleList
            headerText={t('actionsInactiveTitle')}
            commonProps={{
              isFinished: true,
              isEditable: false,
              showButtonsWhenCollapsed: true,
              onActivate: handleActivate
            }}
            sections={[
              {
                actions: actions
              }
            ]}
          />
        </ScrollView>
      </ActionLayout>
      <RecurrenceBottomSheet
        sheetRef={sheetRef}
        isEditing={false}
        action={currentAction}
        onChange={handleActivateAction}
      />
      <ErrorModal
        visible={!!errorMessage}
        message={errorMessage}
        closeBtnClick={() => {
          setErrorMessage(null);
        }}
      />
    </>
  );
};

export default ScheduleInactiveActionsScreen;
