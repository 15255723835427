import { Animated, StyleSheet, View, ViewProps } from 'react-native';
import { Button, Spinner, Text, useTheme } from '@ui-kitten/components';
import React, { useState } from 'react';
import { useGetAppName, useGetAppWebsite, useGetChatbotName } from 'src/core/hooks/useBrands';

import Logo from 'src/core/constants/Logo';
import { MarkdownText } from '../kalichat';
import { preSignUpText } from 'src/core/brands';
import { UIHelper as uh } from '../../core';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';

interface PreSignUpAltProps extends ViewProps {
  finalActionHandler: () => void;
}

const PreSignUpAlt = ({ finalActionHandler }: PreSignUpAltProps) => {
  const th = useTheme();
  const t = useTranslationFunc(preSignUpText);
  const appWebsite = useGetAppWebsite();
  const appName = useGetAppName();
  const chatbotName = useGetChatbotName();

  const kalibraCollection = [
    {
      id: 1,
      title: t('collection1Title'),
      testID: 'preregister-first-title',
      description: t('collection1Description', { app_name: appName, chatbot_name: chatbotName }),
      buttonTitle: t('collection1Button')
    },
    {
      id: 2,
      title: t('collection2Title', { chatbot_name: chatbotName }),
      testID: 'preregister-second-title',
      description: t('collection2Description', {
        app_name: appName,
        app_website: appWebsite,
        chatbot_name: chatbotName
      }),
      buttonTitle: t('collection2Button', { chatbot_name: chatbotName })
    }
  ];
  //styles
  const styleContainer = StyleSheet.create({
    imageContainer: { maxWidth: '100%', alignItems: 'center', alignContent: 'center', justifyContent: 'center' },
    imageLoading: { position: 'absolute', zIndex: -1 },
    spinnerContainer: {
      flex: 1,
      maxWidth: '100%',
      alignItems: 'center',
      justifyContent: 'center'
    },
    image: { width: '50%', minHeight: uh.h2DP(100), maxHeight: uh.h2DP(200) },
    imageOpacity: { opacity: 1 },
    imageTitle: {
      textAlign: 'center',
      paddingTop: uh.h2DP(32),
      paddingBottom: uh.h2DP(24)
    },
    imageDescription: { textAlign: 'center', paddingBottom: uh.h2DP(32) },
    button: { width: '100%' },
    markdownContainer: {
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'center',
      textAlign: 'center'
    }
  });

  const markdownStyles = StyleSheet.create({
    // The main container
    view: {
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'center',
      fontFamily: 'Poppins-Regular',
      color: th['text-basic-color'],
      fontSize: 14,
      flexDireaction: 'center',
      marginBottom: 10
    },
    // Links
    autolink: {
      textDecorationLine: 'underline',
      fontSize: 14
    },
    // Text Output
    text: {
      fontFamily: 'Poppins-Regular',
      color: th['text-basic-color'],
      fontSize: 14
    }
  });

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const opacity = React.useRef(new Animated.Value(0)).current;

  const getNextItemHandler = () => {
    if (currentPage === 1) {
      finalActionHandler();
      return;
    }
    setIsLoading(true);
    setCurrentPage((prev) => prev + 1);
    opacity.setValue(0);
  };
  const onLoad = () => {
    Animated.timing(opacity, {
      toValue: 1,
      duration: 350,
      useNativeDriver: false
    }).start();
    setIsLoading(false);
  };

  return (
    <>
      <Animated.View key={kalibraCollection[currentPage].id} style={[styleContainer.imageContainer]}>
        {isLoading && (
          <View style={[styleContainer.spinnerContainer]}>
            <Spinner status="primary" size="giant" />
          </View>
        )}
        <Logo
          animated={true}
          animatedProps={{
            style: [{ opacity: opacity }, isLoading && styleContainer.imageLoading, styleContainer.image],
            onLoadEnd: onLoad,
            resizeMode: 'contain'
          }}
        />
      </Animated.View>
      <View>
        <Text category="h6" style={[styleContainer.imageTitle]} testID={kalibraCollection[currentPage].testID}>
          {kalibraCollection[currentPage].title}
        </Text>
        <View style={styleContainer.markdownContainer}>
          <MarkdownText styles={markdownStyles}>{kalibraCollection[currentPage].description}</MarkdownText>
        </View>
      </View>
      <Button
        style={styleContainer.button}
        size="giant"
        status="primary"
        onPress={getNextItemHandler}
        disabled={isLoading}
        accessible={true}
        accessibilityLabel={'next_btn'}
        testID={'next_btn'}>
        <Text status="primary" category="s2">
          {kalibraCollection[currentPage].buttonTitle}
        </Text>
      </Button>
    </>
  );
};

export default PreSignUpAlt;
