import { Animated, Keyboard, ScrollView, StyleSheet, View, ViewProps } from 'react-native';
import { BiomarkerItem, Bloowork } from 'src/core/types/Bloodwork';
import { Button, Datepicker, Divider, Icon, IconProps, Input, Layout, ModalService, Text } from '@ui-kitten/components';
import React, { useState } from 'react';

import AddHealthMarkerModal from './review/AddHealthMakerModal';
import { AxiosResponse } from 'axios';
import { BackendApi } from 'src/api/shared';
import { DeleteHealthMarkerConfirmationModal } from './review/DeleteHealthMarkerConfirmationModal';
import DoneModal from './review/DoneModal';
import EditHealthMakerListItem from './review/EditHealthMakerListItem';
import { ErrorMessage } from 'src/components/shared/ErrorMessage';
import { KeyboardAvoidingView } from '../../shared';
import ModalHeader from 'src/components/profileMeMarker/ModalHeader';
import Spinner from 'react-native-loading-spinner-overlay';
import moment from 'moment-timezone';
import { reviewBloodworkModal } from 'src/core/brands';
import { UIHelper as uh } from '../../../core';
import { useAnalytics } from 'src/core/hooks/useAnalytics';
import { useAppStore } from 'src/core/store';
import { useGetAppName } from 'src/core/hooks/useBrands';
import { useSetRefreshMarkers } from 'src/core/hooks/useGlobalVar';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';

const infos = [
  'Good news! We have processed your bloodwork. We now need you to review and confirm the information is correct.',
  'Please review all values and units, and correct markers highlighted in red.',
  'The following markers need your review. Please review them carefully and update any values or units before confirming.',
  'Thank you for your help :)',
  ''
];

const buttonNames = ['Continue', 'Confirm', 'Confirm', 'Confirm and Commit Bloodwork', 'Confirm and Commit Bloodwork'];
const CalendarIcon = (props: IconProps) => <Icon {...props} name="calendar" />;

interface ReviewBloodworkModalProps extends ViewProps {
  viewAssessmentHandler: (assessmentId: string) => void;
  btnBackHandler: () => void;
  bloodworkId: string;
  dropdownBGColor: string;
  inputColor: string;
}

const ReviewBloodworkModal = (props: ReviewBloodworkModalProps) => {
  const { addAnalyticsLog } = useAnalytics('ReviewBloodworkModal.tsx');
  const t = useTranslationFunc(reviewBloodworkModal);
  const appName = useGetAppName();

  const allHealthMarkers = useAppStore((state) => state.healthMarkers);

  let modalID = '';
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [bloodworkData, setBloodworkData] = useState<Bloowork>();
  const [step, setStep] = useState<number>(0);
  const [update, setUpdate] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [referralAuthority, setReferralAuthority] = useState<string>('');
  const [measuredDate, setMeasuredDate] = React.useState(new Date());
  const [error, setError] = React.useState<string>('');
  const moveAnim = React.useRef(new Animated.Value(uh.height())).current;
  const setRefreshMarkers = useSetRefreshMarkers();

  const styles = StyleSheet.create({
    screenContainer: {
      padding: uh.h2DP(16),
      paddingTop: 0,
      minHeight: uh.height() - uh.topPos()
    },
    animatedViewContainer: {
      height: uh.height() - uh.topPos(),
      position: 'absolute',
      alignSelf: 'center',
      width: uh.currentViewPort(),
      transform: [
        {
          translateY: moveAnim as any
        }
      ]
    },
    container: {
      flex: 1,
      padding: uh.h2DP(16)
    },
    progressBar: {
      marginBottom: uh.h2DP(20),
      width: uh.currentViewPort() - uh.w2DP(64),
      marginLeft: uh.w2DP(16)
    },
    progressBarTitle: {
      margin: uh.h2DP(20)
    },
    button: {
      alignItems: 'center',
      padding: uh.h2DP(10)
    },
    item: {
      marginTop: uh.w2DP(8)
    },
    divider: {
      marginTop: uh.w2DP(16)
    },
    backdropStyle: { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
    input: { backgroundColor: props.inputColor, marginTop: 10 },
    select: { flex: 1, marginTop: uh.h2DP(16) },
    confirmButton: { marginTop: uh.h2DP(24), marginBottom: uh.h2DP(24) },
    yesButton: { marginRight: uh.h2DP(20), marginLeft: 20 }
  });

  const moveUp = React.useCallback(() => {
    // Will decrease value to 30 in 500 milliseconds
    Animated.timing(moveAnim, {
      toValue: uh.topPos(),
      duration: 500,
      useNativeDriver: true
    }).start();
  }, [moveAnim]);

  React.useEffect(() => {
    moveUp();
    // refreshData();
  }, [moveUp]);

  const moveDown = (callBackFnc: () => void) => {
    // Will increase value to windowHeight in 500 milliseconds
    Animated.timing(moveAnim, {
      toValue: uh.height(),
      duration: 500,
      useNativeDriver: true
    }).start(() => {
      callBackFnc();
    });
  };

  const updateBloodwork = async (): Promise<any> => {
    try {
      setIsLoading(true);
      let parameters: any[] = [];
      if (step == 0) {
        parameters = [
          {
            documentId: props.bloodworkId,
            referralAuthority: referralAuthority,
            measuredDate: moment(measuredDate).utc(true)
          }
        ];
      } else if (step == 1 || step == 2) {
        bloodworkData?.healthMarker.forEach((item) => {
          parameters.push({
            bloodworkDataId: item.bloodworkDataId,
            healthMarkerId: item.healthMarkerId,
            value: Number(item.value),
            unitId: item.unitId
          });
        });
      }
      const response = await BackendApi.post('/health-markers/bloodwork-update-review-data', parameters);
      if (response.status >= 200 && response.status <= 399) {
        if (response.data !== undefined) {
          setIsLoading(false);
          return {};
        }
      } else {
        addAnalyticsLog({ function: 'updateBloodwork', data: response, logType: 'error' });
        setIsLoading(false);
        return { errorMessage: 'There was an unexpected error.' };
      }
    } catch (err) {
      addAnalyticsLog({ function: 'updateBloodwork', data: err, logType: 'error' });
      setIsLoading(false);
      return { errorMessage: 'There was an unexpected error.' };
    }
  };

  const renderDoneModalContentElement = (id: string) => {
    return (
      <DoneModal
        caption="Bloodwork successfully added"
        description={t('doneDescription', { app_name: appName })}
        btnDoneClick={() => {
          ModalService.hide(modalID);
          props.btnBackHandler();
        }}
        btnHyperlinkText={'View assessment'}
        btnHyperlinkClick={() => {
          ModalService.hide(modalID);
          props.viewAssessmentHandler(id);
        }}
      />
    );
  };

  const showDoneModal = (id: string) => {
    const contentElement = renderDoneModalContentElement(id);
    modalID = ModalService.show(contentElement, {
      backdropStyle: styles.backdropStyle
    });
  };

  const commitBloodwork = async (): Promise<any> => {
    try {
      setIsLoading(true);
      const response = await BackendApi.get(`/health-markers/bloodwork-process-health-markers/${props.bloodworkId}`);
      if (response.status >= 200 && response.status <= 399) {
        if (response.data !== undefined) {
          setIsLoading(false);
          return response.data;
        }
      } else {
        setIsLoading(false);
        addAnalyticsLog({ function: 'commitBloodwork', data: response, logType: 'error' });
        return { errorMessage: 'There was an unexpected error.' };
      }
    } catch (err) {
      setIsLoading(false);
      addAnalyticsLog({ function: 'commitBloodwork', data: err, logType: 'error' });
      return { errorMessage: 'There was an unexpected error.' };
    }
  };

  const btnConfirmClick = async () => {
    if (step == 0) {
      const result = await updateBloodwork();
      if (result?.errorMessage) {
        setError(result?.errorMessage);
      } else {
        setStep(1);
      }
    } else if (step == 1 || step == 2) {
      const result = await updateBloodwork();
      if (result?.errorMessage) {
        setError(result?.errorMessage);
      } else {
        setStep(3);
      }
    } else if (step == 4) {
      const result = await commitBloodwork();
      setRefreshMarkers();
      if (result?.errorMessage) {
        setError(result?.errorMessage);
      } else {
        showDoneModal(result.id);
      }
    }
  };

  const renderStep0 = () => {
    return (
      <>
        <Text category="p2" style={{ marginTop: 16 }}>
          When did you take this blood test?
        </Text>
        <Datepicker
          size="large"
          backdropStyle={styles.backdropStyle}
          status={'basic'}
          controlStyle={[styles.input]}
          label=""
          min={new Date('1970-1-1')}
          placement="bottom"
          max={new Date()}
          date={measuredDate}
          onFocus={() => {
            Keyboard.dismiss();
          }}
          onSelect={(nextDate) => setMeasuredDate(nextDate)}
          accessoryRight={CalendarIcon}
        />
        <Text category="p2" style={{ marginTop: 16 }}>
          Referral authority (practitioner or clinic)
        </Text>
        <Input
          style={[styles.input]}
          size="large"
          status={'basic'}
          value={referralAuthority}
          label=""
          onChangeText={(text: string) => setReferralAuthority(text)}
        />
      </>
    );
  };

  const deleteBiomarkerItem = (id: string) => {
    (bloodworkData as any).healthMarker = bloodworkData?.healthMarker.filter((item) => item.bloodworkDataId != id);
    setUpdate(!update);
  };

  const deleteHealthMarker = async (bloodworkDataId: string) => {
    try {
      const response: AxiosResponse = await BackendApi.delete(
        `/health-markers/bloodwork-delete-marker-data/${bloodworkDataId}`
      );
      // Deleted all the users data successfully
      if (response.status === 200) {
        deleteBiomarkerItem(bloodworkDataId);
        ModalService.hide(modalID);
      }
    } catch (err) {
      addAnalyticsLog({ function: 'deleteHealthMarker', data: err, logType: 'error' });
    }
  };

  const addMoreHealthMarker = (healthMarkerId: number, unitId: number, value: string) => {
    bloodworkData?.healthMarker.push({ healthMarkerId: healthMarkerId, value: value, unitId: unitId } as BiomarkerItem);
    setUpdate(!update);
  };

  const renderConfirmModalContentElement = (bloodworkDataId: string) => {
    return (
      <DeleteHealthMarkerConfirmationModal
        message="Are you sure you want to remove this marker?"
        yesBtnClick={() => deleteHealthMarker(bloodworkDataId)}
        noBtnClick={() => {
          ModalService.hide(modalID);
        }}
      />
    );
  };

  const showConfirmModal = (bloodworkDataId: string) => {
    const contentElement = renderConfirmModalContentElement(bloodworkDataId);
    modalID = ModalService.show(contentElement, {
      backdropStyle: styles.backdropStyle
    });
  };

  const renderAddModalContentElement = () => {
    return (
      <AddHealthMarkerModal
        btnCancelClick={() => {
          ModalService.hide(modalID);
        }}
        allHealthMarkers={allHealthMarkers}
        documentId={props.bloodworkId}
        addMoreHealthMarker={addMoreHealthMarker}
        dropdownBGColor={props.dropdownBGColor}
        intputColor={props.inputColor}
      />
    );
  };

  const showAddModal = () => {
    const contentElement = renderAddModalContentElement();
    modalID = ModalService.show(contentElement, {
      backdropStyle: styles.backdropStyle
    });
  };

  const updateValue = (newId: string, newValue: string, newUnitId: number): void => {
    const index = bloodworkData?.healthMarker.findIndex((item) => item.bloodworkDataId == newId);
    if (index != undefined && index >= 0) {
      (bloodworkData as any).healthMarker[index].value = newValue;
      (bloodworkData as any).healthMarker[index].unitId = newUnitId;
      setIsEditing(!isEditing);
    }
  };

  const renderStep1 = () => {
    let newList: BiomarkerItem[] = [];
    if (step == 1) {
      newList = bloodworkData?.healthMarker as BiomarkerItem[];
    } else {
      newList = bloodworkData?.healthMarker.filter(
        (item) => Number.isNaN(Number(item.value)) == true || item.value.trim().length == 0
      ) as BiomarkerItem[];
    }

    return (
      <ScrollView style={{ flex: 1 }}>
        {newList.map((item, index) => {
          if (update == true) {
            return (
              <>
                <EditHealthMakerListItem
                  key={index}
                  item={item}
                  allHealthMarkers={allHealthMarkers}
                  deleteButtonClick={() => {
                    showConfirmModal(item.bloodworkDataId);
                  }}
                  updateValue={updateValue}
                  editable={false}
                  inputColor={props.inputColor}
                  dropdownBGColor={props.dropdownBGColor}
                />
              </>
            );
          } else {
            return (
              <EditHealthMakerListItem
                key={index}
                item={item}
                allHealthMarkers={allHealthMarkers}
                deleteButtonClick={() => {
                  showConfirmModal(item.bloodworkDataId);
                }}
                updateValue={updateValue}
                editable={false}
                inputColor={props.inputColor}
                dropdownBGColor={props.dropdownBGColor}
              />
            );
          }
        })}
      </ScrollView>
    );
  };

  const renderStep2 = () => {
    return (
      <>
        <Text category="p2" style={{ marginTop: 16 }}>
          Would you like to add any other markers?
        </Text>
        <View
          style={{
            marginTop: 40,
            flexDirection: 'row',
            marginVertical: 50,
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <Button
            style={styles.yesButton}
            size="small"
            status="primary"
            disabled={false}
            accessible={true}
            accessibilityLabel="Yes"
            testID="button_yes"
            onPress={() => {
              showAddModal();
            }}>
            Yes
          </Button>
          <Button
            style={styles.yesButton}
            size="small"
            status="primary"
            disabled={false}
            accessible={true}
            accessibilityLabel="No"
            testID="button_no"
            onPress={() => {
              setStep(4);
            }}>
            No
          </Button>
        </View>
      </>
    );
  };

  const renderStep = () => {
    if (step == 0) {
      return renderStep0();
    } else if (step == 1 || step == 2) {
      return renderStep1();
    } else if (step == 3) {
      return renderStep2();
    }
  };

  const getBloodworkDetail = React.useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await BackendApi.get(`/health-markers/get-bloodwork-detail/${props.bloodworkId}`);
      if (response.status >= 200 && response.status <= 399) {
        if (response.data !== undefined && response.data.length > 0) {
          setBloodworkData(response.data[0]);
          const date = moment(response.data[0].measuredDate);
          if (date.isValid()) {
            setMeasuredDate(date.toDate());
          }
          setReferralAuthority(response.data[0].referralAuthority);
        }
        setError('');
      } else {
        addAnalyticsLog({ function: 'getBloodworkDetail', data: response, logType: 'error' });
        setError('Something went wrong.');
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      addAnalyticsLog({ function: 'getBloodworkDetail', data: err, logType: 'error' });
      setError('Something went wrong.');
    }
  }, [props.bloodworkId, addAnalyticsLog]);

  const getInfoText = () => {
    if (step == 3 || step == 4) {
      return `Thanks! ${
        (bloodworkData as any).healthMarker.length
      } markers confirmed! \n\n We will now analyse the results and prepare the Biomarkers Assessment.`;
    }
    return infos[step];
  };

  React.useEffect(() => {
    getBloodworkDetail();
  }, [getBloodworkDetail]);

  const validateData = () => {
    if (step == 0) {
      if (measuredDate == undefined || referralAuthority.length == 0) {
        return false;
      }
    } else if (step == 1) {
      const errorBiomarkers = bloodworkData?.healthMarker.filter((item) => {
        const healthMarker = allHealthMarkers.find((hm) => hm.healthMarkerId == item.healthMarkerId);
        const unitType = healthMarker?.healthMarkerUnitTypes.find((it) => it.unitId == item.unitId);
        return (
          unitType == undefined ||
          item.value == null ||
          Number.isNaN(Number(item.value)) == true ||
          String(item.value).trim().length == 0 ||
          Number(item.value) > unitType.rangeMax ||
          Number(item.value) < unitType.rangeMin
        );
      });

      if (errorBiomarkers != undefined && errorBiomarkers?.length > 0) {
        return false;
      }
    } else if (step == 3) {
      return false;
    }
    return true;
  };

  const disableButton = !validateData();
  return (
    <Animated.View style={styles.animatedViewContainer}>
      {isLoading && <Spinner visible={true}></Spinner>}
      <ModalHeader
        caption="Review Your Bloodwork"
        btnClickHandler={() => {
          moveDown(props.btnBackHandler);
        }}
      />
      <KeyboardAvoidingView>
        <Layout level="2" style={styles.container}>
          <ErrorMessage message={error} />
          <Text category="p2">{getInfoText()}</Text>
          <Divider style={styles.divider} />
          {renderStep()}

          {step < 5 && (
            <Button
              style={styles.confirmButton}
              size="medium"
              status="primary"
              disabled={disableButton}
              accessible={true}
              accessibilityLabel="Confirm"
              testID="button_confirm"
              onPress={() => {
                btnConfirmClick();
              }}>
              {buttonNames[step]}
            </Button>
          )}
        </Layout>
      </KeyboardAvoidingView>
    </Animated.View>
  );
};

export default ReviewBloodworkModal;
