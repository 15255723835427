import { Button, Divider, Layout, ModalService, Text, useTheme } from '@ui-kitten/components';
import { Platform, SafeAreaView, ScrollView, StyleSheet } from 'react-native';
import React, { useCallback, useEffect, useState } from 'react';
import { ReviewBloodworkModal, YourAssessments } from '../../components/profileMeAssessment';
import { RootTabScreenProps, TenantFeature, UIHelper as uh } from '../../core';
import { useGetPendingBloodwork, useLoadHealthMarkerUnits } from 'src/core/hooks/useAssessments';

import DisableTenantFeature from 'src/components/shared/DisableTenantFeature';
import { ErrorModal } from 'src/components/shared/ErrorModal';
// import { InformationButton } from '../../components/profileMe';
import { KaliChatQuote } from '../../components/home';
import RefreshControl from 'src/components/shared/RefreshControl';
import { StackActions } from '@react-navigation/native';
import { mainStyles } from './_mainStyles';
import { useAppStore } from 'src/core/store';
import { useGetTenantFeatures } from 'src/core/hooks/useUser';

// const learnMoreBtnTitle = 'Learn more';
// const learnMoreBtn2Title = 'Get an assessment';
// const learnMoreMsg =
//   'There are another 42 markers that you haven’t had assessed yet. Let me know if you’d like to find out what all of the markers are that you can potentially have assessed.';
const bloodworkAssessmentMsg =
  'Your bloodwork document has been processed. Click the button below to review and validate the results.';

let notiDocId = '';
const ProfileMeAssessmentScreen = ({ route, navigation }: RootTabScreenProps<any>) => {
  const th = useTheme();

  const theme = useAppStore((state) => state.theme);
  const tenantFeatures: TenantFeature[] = useGetTenantFeatures();

  useLoadHealthMarkerUnits();
  let modalID = '';

  const styleContainer = StyleSheet.create({
    screenContainer: { flex: 1 },
    safeAreaView: { flex: 1 },
    container: {
      flex: 1
    },
    learnMore: { marginTop: uh.h2DP(40) },
    divider: { marginTop: uh.h2DP(16), marginBottom: uh.h2DP(16) },
    chatQuote: { marginTop: uh.h2DP(16) },
    btnUpload: { marginTop: uh.h2DP(16) },
    btnInfo: { marginTop: uh.h2DP(18) },
    backdrop: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    }
  });

  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [isShowUploadBloodwork, setIsShowUploadBloodwork] = useState<boolean>(false);
  const [isMeAssessmentScreenEnable, setIsMeAssessmentScreenEnable] = useState(true);

  const [documentId, , bloodworkError] = useGetPendingBloodwork(refreshing);
  const finishRefreshing = useCallback(() => {
    setRefreshing(false);
    setTimeout(() => {
      setIsShowUploadBloodwork(true);
    }, 500);
  }, []);

  const btnDetailAssessmentClick = (assessmentId: string) => {
    if (Platform.OS === 'android')
      navigation.dispatch(
        StackActions.replace('AssessmentDetail', { backToScreen: 'Profile', assessmentId: assessmentId })
      );
    else
      navigation.navigate('AssessmentDetail' as any, {
        assessmentId: assessmentId
      });
  };

  const onRefresh = useCallback(() => {
    setRefreshing(true);
  }, []);

  //properties and handlers
  const viewAssessmentDetail = (assessmentId: string) => {
    ModalService.hide(modalID);
    setTimeout(() => {
      navigation.navigate('AssessmentDetail' as any, { assessmentId: assessmentId });
    }, 100);
  };

  const renderReviewModalContentElement = (id: string) => {
    return (
      <ReviewBloodworkModal
        bloodworkId={id}
        viewAssessmentHandler={viewAssessmentDetail}
        btnBackHandler={() => {
          modalID = ModalService.hide(modalID);
          navigation.setParams({ pendingDocId: '' } as any);
        }}
        inputColor={uh.getHex(th, theme, 'color-basic-100', 'color-basic-1100')}
        dropdownBGColor={uh.getHex(th, theme, 'color-basic-100', 'color-basic-900')}
      />
    );
  };

  const showReviewModal = useCallback((id: string) => {
    const contentElement = renderReviewModalContentElement(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    modalID = ModalService.show(contentElement, {
      backdropStyle: styleContainer.backdrop
    });
  }, []);

  const btnReviewClick = async () => {
    showReviewModal(documentId);
  };

  useEffect(() => {
    const isEnableAssessment =
      tenantFeatures.find((item: TenantFeature) => item.key == 'DailyMetricsReportFeature') != undefined;
    setIsMeAssessmentScreenEnable(isEnableAssessment);
  }, [tenantFeatures]);

  useEffect(() => {
    const id = (route?.params as any)?.pendingDocId;
    if (id != undefined && id.length > 0 && id != notiDocId) {
      notiDocId = id;
      showReviewModal(notiDocId);
    }
  }, [route?.params, showReviewModal]);

  return (
    <Layout level="2" style={[styleContainer.screenContainer]}>
      <ScrollView
        contentContainerStyle={[{ flexGrow: 1 }, mainStyles.mainScreenContainer]}
        refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}>
        {isMeAssessmentScreenEnable ? (
          <SafeAreaView style={styleContainer.screenContainer}>
            {isShowUploadBloodwork && documentId && (
              <>
                <Text category="s1">New assessment</Text>
                <KaliChatQuote style={styleContainer.chatQuote} messages={[bloodworkAssessmentMsg]} />
                <Button onPress={btnReviewClick} size="medium" style={styleContainer.btnUpload}>
                  <Text>Review pending bloodwork</Text>
                </Button>
                <Divider style={styleContainer.divider} />
              </>
            )}

            <YourAssessments
              btnDetailAssessmentClick={btnDetailAssessmentClick}
              refreshing={refreshing}
              finishRefreshing={finishRefreshing}
            />
            {/* <LearnMore
            style={styleContainer.learnMore}
            btnMessage={learnMoreBtnTitle}
            btnHandler={btnTellMoreClick}
            btn2Message={learnMoreBtn2Title}
            btn2Handler={btnTellMoreClick}
            messages={[learnMoreMsg]}
          />*/}
          </SafeAreaView>
        ) : (
          <DisableTenantFeature />
        )}
      </ScrollView>
      <ErrorModal visible={bloodworkError} message={bloodworkError} />
    </Layout>
  );
};

export default ProfileMeAssessmentScreen;
