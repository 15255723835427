import React from 'react';
import { View, StyleSheet, TextProps } from 'react-native';
import { UIHelper as uh } from '../../core';
import { Text } from '@ui-kitten/components';

//props
interface IWelcomeAuthProps extends TextProps {
  title: string;
  welcomeCaption: string;
}

const WelcomeAuth = (props: IWelcomeAuthProps) => {
  //styles
  const styleContainer = StyleSheet.create({
    textCenter: { textAlign: 'center' },
    welcomeCaption: { marginTop: uh.h2DP(8) }
  });

  // view
  return (
    <View style={{ marginTop: uh.h2DP(12) }}>
      <Text status="basic" category="h2" style={[props.style, styleContainer.textCenter]}>
        {props.title}
      </Text>
      <Text status="basic" category="p1" style={[styleContainer.textCenter, styleContainer.welcomeCaption]}>
        {props.welcomeCaption}
      </Text>
    </View>
  );
};

export default WelcomeAuth;
