import { Icon, Text } from '@ui-kitten/components';
import { StyleSheet, View, ViewProps } from 'react-native';

import Brands from 'brands.json';
import React from 'react';
import { UIHelper as uh } from '../../../core';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';
//props
interface AccuracyTextProps extends ViewProps {
  accuracy: number;
}

// styles
const styleContainer = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  icon: { width: 16, height: 16, marginRight: uh.w2DP(4) }
});

const AccuracyText = ({ accuracy }: AccuracyTextProps) => {
  const transMessage = {
    accuracy: 'Accuracy: {{num}}%'
  };
  const t = useTranslationFunc(transMessage);

  // view
  return (
    <View style={styleContainer.container}>
      <Icon name="loading" pack={Brands.icon_pack} style={styleContainer.icon}></Icon>
      <Text category="p2">{t('accuracy', { num: Math.floor(accuracy) || 0 })}</Text>
    </View>
  );
};

export default AccuracyText;
