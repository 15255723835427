
       const __ReactNativeSvgLoader = require('svgLoader').default({
  "memo":"true"
}
);
        import { BloodworkScanIcon } from './BloodworkScanIcon';

  const BodyIcon = __ReactNativeSvgLoader(import('../../../../assets/images/body_scan/body_scan_icon.svg'));
  ;
import { BodyScanIcon as BodyScanIconSvg } from './BodyScanIcon';

  const DistanceIcon = __ReactNativeSvgLoader(import('../../../../assets/images/body_scan/body_scan_distance.svg'));
  ;
import { FaceScanIcon } from './FaceScanIcon';
import { HRVScanIcon } from './HRVScanIcon';

  const PhoneIcon = __ReactNativeSvgLoader(import('../../../../assets/images/body_scan/body_scan_phone.svg'));
  ;
import React from 'react';

  const StarIcon = __ReactNativeSvgLoader(import('../../../../assets/images/body_scan/body_scan_star.svg'));
  ;

export const ScanIcon = (color: string) => ({
  FaceScan: () => {
    return <FaceScanIcon color={color} />;
  },
  BodyScan: () => {
    return <BodyScanIconSvg color={color} />;
  },
  HRVScan: () => {
    return <HRVScanIcon color={color} />;
  },
  BloodworkScan: () => {
    return <BloodworkScanIcon color={color} />;
  }
});

export const BodyScanIcon = {
  StarIcon: () => {
    return <StarIcon />;
  },
  BodyIcon: () => {
    return <BodyIcon />;
  },
  PhoneIcon: () => {
    return <PhoneIcon />;
  },
  DistanceIcon: () => {
    return <DistanceIcon />;
  }
};

        