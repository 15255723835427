import { UIHelper as uh } from '../../../core';
import { Spinner, Text, useTheme } from '@ui-kitten/components';
import { StyleSheet, View, ViewProps, processColor, Platform } from 'react-native';
import { BarData } from 'react-native-charts-wrapper';
import React, { Suspense, useState, lazy } from 'react';
import moment from 'moment';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';
import { FrequencyType, SleepHistoriesItem } from 'src/core/types/SleepStat';
import Frequency from './Frequency';
import { ChartHelper } from 'src/core/helpers/ChartHelper';
import NoGraphData from './NoGraphData';

//props
interface SleepBarChartProps extends ViewProps {
  graphData: Array<SleepHistoriesItem>;
  graphHistoriesData: Array<SleepHistoriesItem>;
  isLoading: boolean;
}

// styles
const GRAPH_HEIGHT = 280;
const styles = StyleSheet.create({
  container: {
    height: GRAPH_HEIGHT,
    marginTop: uh.h2DP(4)
  },
  noGraphContainer: {
    height: 60
  },
  loading: {
    height: GRAPH_HEIGHT,
    alignContent: 'center',
    alignSelf: 'center'
  },
  areaChart: {
    height: 198
  },
  xAxis: { marginHorizontal: -15, height: 20 },
  score: {
    marginRight: uh.w2DP(16)
  },
  scoreName: {
    marginRight: uh.w2DP(16),
    marginTop: uh.h2DP(4)
  },
  webText: {
    textAlign: 'center',
    fontSize: 20
  }
});

const SleepBarChart = ({ graphData, graphHistoriesData, isLoading }: SleepBarChartProps) => {
  const th = useTheme();
  const transMessage = {
    noGraphData: 'No graph data',
    heartChartsAreDisabled: 'Heart charts are disabled on the web app'
  };
  const t = useTranslationFunc(transMessage);
  const [frequencyType, setFrequencyType] = useState<FrequencyType>(FrequencyType.Week);

  if (isLoading) {
    return (
      <View style={styles.loading}>
        <Spinner status="primary" />
      </View>
    );
  }

  if (graphData.length == 0) {
    return <NoGraphData />;
  }

  const chartHistoriesData = ChartHelper.getHistoriesData(graphHistoriesData, frequencyType);
  const { chartData, range } = ChartHelper.getChartDataAndRange(graphData);

  const createValues = () => {
    if (frequencyType === FrequencyType.Day) {
      return chartData.map((item) => {
        return {
          x:
            frequencyType === FrequencyType.Day
              ? moment(item.date).toDate().getHours()
              : moment(item.date).toDate().getDate(),
          y: item.value
        };
      });
    } else {
      return chartHistoriesData.map((item) => {
        return {
          x: moment(item.date).toDate().getDate(),
          y: (item as SleepHistoriesItem).value
        };
      });
    }
  };

  const barData: BarData = {
    dataSets: [
      {
        values: createValues(),
        label: '',
        config: {
          drawValues: false,
          color: processColor(th['color-rest-700']),
          highlightEnabled: Platform.OS !== 'android'
        }
      }
    ],
    config: {
      barWidth: 0.7
    }
  };

  if (Platform.OS === 'web') {
    return (
      <View>
        <View style={styles.container}>
          <Text style={{ ...styles.webText, ...{ color: th['text-basic-color'] } }}>{t('heartChartsAreDisabled')}</Text>
        </View>
      </View>
    );
  } else {
    const BarChart = lazy(() => import('./BarChart'));
    return (
      <View style={styles.container}>
        <Frequency
          onChangeFrequency={(type) => {
            setFrequencyType(type);
          }}
        />
        <Suspense fallback={<Spinner status="primary" />}>
          <BarChart
            style={{ flex: 1, height: 98 }}
            data={barData}
            animation={{
              durationX: 500,
              easingX: 'Linear'
            }}
            marker={{
              enabled: false
            }}
            legend={{ enabled: false }}
            drawBarShadow={false}
            drawValueAboveBar={true}
            pinchZoom={false}
            highlights={range === 0 ? [{ x: 0 }] : undefined}
            touchEnabled={false}
            xAxis={{
              enabled: true,
              drawLabels: true,
              position: 'BOTTOM',
              drawGridLines: false,
              textSize: 10,
              textColor: processColor(th['text-hint-color']),
              valueFormatter: 'date',
              timeUnit: frequencyType === FrequencyType.Day ? 'HOURS' : 'DAYS',
              valueFormatterPattern: frequencyType === FrequencyType.Day ? 'HH a' : 'dd',
              labelCount: chartData.length <= 5 ? chartData.length : 5
            }}
            yAxis={{
              left: {
                enabled: true,
                axisMaximum: 100,
                axisMinimum: 0,
                drawGridLines: false,
                textSize: 10,
                textColor: processColor(th['text-hint-color'])
              },
              right: { enabled: false }
            }}
          />
        </Suspense>
      </View>
    );
  }
};

export default SleepBarChart;
