import * as amplitude from '@amplitude/analytics-react-native';

export const addRawAnalyticsLog = (logType: string, data: any): Promise<boolean> => {
  if (process.env.NODE_ENV === 'development' && logType === 'Error') {
    console.warn(`[${logType}]: ${JSON.stringify(data)}`);
  }
  try {
    return amplitude.logEvent(logType, data).promise.then(() => {
      //TODO check code
      return true;
    });
  } catch (e) {
    console.error(e);
    return Promise.resolve(false);
  }
};
