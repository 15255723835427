import { Level } from 'src/core/types/Level';
import { StateCreator } from 'zustand';
import { Units } from 'src/core/types/Units';

export interface SettingsSlice {
  newMessageCount: number;
  newAssessmentCount: number;
  newActionCount: number;
  units: Units;
  level: Level;
  enabledActionNotifications: boolean;
  enabledBiomarkerOfTheDayNotifications: boolean;
  enabledSleepStatNotifications: boolean;
  setNewMessageCount: (newMessageCount: number) => void;
  setNewAssessmentCount: (newAssessmentCount: number) => void;
  setNewActionCount: (newActionCount: number) => void;
  setUnits: (units: Units) => void;
  setLevel: (level: Level) => void;
  setEnabledActionNotifications: (enabledActionNotifications: boolean) => void;
  setEnabledBiomarkerOfTheDayNotifications: (enabledBiomarkerOfTheDayNotifications: boolean) => void;
  setEnabledSleepStatNotifications: (enabledSleepStatNotifications: boolean) => void;
}

export const createSettingsSlice: StateCreator<SettingsSlice, [], [], SettingsSlice> = (set) => ({
  newMessageCount: 0,
  newAssessmentCount: 0,
  newActionCount: 0,
  units: Units.Metric,
  level: Level.Lenient,
  enabledActionNotifications: false,
  enabledBiomarkerOfTheDayNotifications: false,
  enabledSleepStatNotifications: false,
  setNewMessageCount: (newMessageCount: number) => set(() => ({ newMessageCount })),
  setNewAssessmentCount: (newAssessmentCount: number) => set(() => ({ newAssessmentCount })),
  setNewActionCount: (newActionCount: number) => set(() => ({ newActionCount })),
  setUnits: (units: Units) => set(() => ({ units })),
  setLevel: (level: Level) => set(() => ({ level })),
  setEnabledActionNotifications: (enabledActionNotifications: boolean) => set(() => ({ enabledActionNotifications })),
  setEnabledBiomarkerOfTheDayNotifications: (enabledBiomarkerOfTheDayNotifications: boolean) =>
    set(() => ({ enabledBiomarkerOfTheDayNotifications })),
  setEnabledSleepStatNotifications: (enabledSleepStatNotifications: boolean) =>
    set(() => ({ enabledSleepStatNotifications }))
});
