export const sleep = (timeout: number) =>
  new Promise<number>((resolve) => {
    setTimeout(() => {
      resolve(timeout);
    }, timeout);
  });

const timeoutFunc = (timeout: number) =>
  new Promise<any>((_, reject) =>
    setTimeout(() => {
      reject(new Error('timeout'));
    }, timeout)
  );

export const promiseTimeout = <T>(promise: Promise<T>, timeout: number): Promise<T> => {
  return Promise.race([promise, timeoutFunc(timeout)]);
};

export const simulateResponseWithDelay = <T>(response: T, timeout = 1000) => {
  return sleep(timeout).then(() => response);
};
