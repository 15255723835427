import moment from 'moment';
import { SleepStatInfo } from '../types/SleepStat';

export const SLEEP_KEY_MAP = {
  heartRateUserHrMaxBpm: { name: 'User Max HR', unit: 'bpm' }, // User Max HR [value] (bpm)
  heartRateAvgHrVariabilityRmssd: { name: 'HRV (rmssd)', unit: 'ms' }, // Avg HRV [value] (rmssd)
  heartRateAvgHrVariabilitySsdn: { name: 'HRV (ssdn)', unit: 'ms' }, // Avg HRV[value] [ssdn]
  heartRateMaxHrBpm: { name: 'Max HR', unit: 'bpm' }, //Max HR [value] (bpm)
  heartRateMinHrBpm: { name: 'Min HR', unit: 'bpm' }, //Min HR [value] (bpm)
  heartRateAvgHrBpm: { name: 'Avg HR', unit: 'bpm' }, //Avg HR [value] (bpm)
  readiness: { name: 'Readiness', unit: '%' }, // Readiness [value]
  readinessRecoveryLevel: { name: 'Recovery Level', unit: '' }, //Recovery Level [value]
  breathsStartTime: { name: 'Breaths start', unit: 'time', tooltip: true }, // Breaths start [value] as hh:mm
  breathsEndTime: { name: 'Breaths end', unit: 'time', tooltip: true }, // Breaths end [value] as hh:mm
  breathsMaxBreathsPerMin: { name: 'Max Breaths', unit: 'bpm' }, // Max Breaths [value] (per/m)
  breathsMinBreathsPerMin: { name: 'Min Breaths', unit: 'bpm' }, // Min Breaths [value] (per/m)
  breathsAvgBreathsPerMin: { name: 'Avg Breaths', unit: 'bpm' }, // Avg Breaths [value] (per/m)
  breathsOnDemandReading: { name: 'On Demand', unit: '' }, // Breaths [value]
  oxygenSaturationOnDemandReading: { name: 'Oxygen Saturation', unit: '' }, // Oxygen Saturation [value]
  oxygenSaturationStartTime: { name: 'SpO2 Sat Start', unit: 'time', tooltip: true }, // Oxygen Saturation start [value] as hh:mm
  oxygenSaturationEndTime: { name: 'SpO2 Sat End', unit: 'time', tooltip: true }, // Oxygen Saturation start [value] as hh:mm
  snoringDataNumSnoringEvents: { name: 'Snoring Events', unit: '' }, // Snoring Events [value]
  snoringDataTotalSnoringDuration: { name: 'Snoring Duration', unit: '' }, // Snoring Duration [value]
  snoringDataStartTime: { name: 'Snoring start', unit: 'hh:mm', tooltip: true }, // Snoring start [value] as hh:mm
  snoringDataEndTime: { name: 'Snoring end', unit: 'hh:mm', tooltip: true }, // Snoring end [value] as hh:mm
  sleepEfficiency: { name: 'Sleep efficiency', unit: '' }, // Sleep efficiency [value]
  sleepDurationPlanned: { name: 'Planned', unit: 'hh:mm' }, // Sleep efficiency [value] (planned) as hh:mm
  sleepAwakeNumOutOfBedEvents: { name: 'Out of Bed Events', unit: '' }, // Out of Bed Events [value]
  sleepAwakeNumWakeupEvents: { name: 'Wake up Events', unit: '' }, // Wake up Events [value]
  sleepAwakeDurationAwakeState: { name: 'Awake', unit: 'hh:mm' }, // Awake [value] as hh:mm
  sleepAwakeDurationBeforeSleeping: { name: 'Before sleep', unit: 'hh:mm' }, // Before sleep [value] as hh:mm
  sleepAwakeDurationAfterWakeup: { name: 'After wakeup', unit: 'hh:mm' }, // After wakeup [value] as hh:mm
  sleepAwakeDurationLongInterruption: { name: 'Long interuption', unit: 'hh:mm' }, // Long interuption [value] as hh:mm
  sleepAwakeDurationShortInterruption: { name: 'Short interuptions', unit: 'hh:mm' }, // Short interuptions [value] as hh:mm
  sleepAwakeWaso: { name: 'WASO', unit: 'hh:mm' }, // Wakefulness After Sleep Onset [value] as hh:mm
  sleepDurationInBed: { name: 'In bed', unit: 'hh:mm' }, // In bed [value] as hh:mm
  sleepDurationUnmeasurableSleep: { name: 'Unmeasurable Sleep ', unit: 'hh:mm' }, // Unmeasurable Sleep [value] as hh:mm
  sleepAsleepNumRemEvents: { name: 'REM Events', unit: '' }, // REM Events [value]
  sleepAsleepDurationAsleepState: { name: 'Duration Asleep', unit: 'hh:mm' }, // Asleep [value] as hh:mm
  sleepAsleepDurationDeepSleepState: { name: 'Deep Sleep', unit: 'hh:mm' }, // Deep Sleep [value] as hh:mm
  sleepAsleepDurationRemSleepState: { name: 'REM Sleep', unit: 'hh:mm' }, // REM Sleep [value] as hh:mm
  sleepAsleepDurationLightSleepState: { name: 'Light Sleep', unit: 'hh:mm' }, // Light Sleep [value] as hh:mm
  temperatureDelta: { name: 'Temp delta', unit: '(c)' } //Temp delta [value] (c)
};

const converToNumber = (str: string) => {
  const pieces = str.split(',');
  const resultingString = pieces.join('');
  return Number(resultingString);
};

const showValueAndUnit = (value: string | null, unit: string) => {
  if (value == null) {
    return 'N/A';
  }

  if (unit === 'hh:mm') {
    return moment.utc(converToNumber(value) * 1000).format('HH:mm');
  } else if (unit === 'time') {
    return moment(value).format('hh:mm A');
  } else if (unit === 'km') {
    return `${converToNumber(value) / 1000.0} km`;
  } else if (unit === 'm') {
    return `${converToNumber(value).toFixed(0)} m`;
  }

  return `${Number(value).toFixed(0)} ${unit}`;
};

const isNullGroup = (groupKeys: string[], data: SleepStatInfo) => {
  const key = groupKeys.find((item) => Object(data)[item] !== null && Object(data)[item] !== '0');
  return key == undefined;
};

export const SleepStatHelper = {
  showValueAndUnit: (value: string | null, unit: string) => {
    return showValueAndUnit(value, unit);
  },
  isNullGroup: (groupKeys: string[], data: SleepStatInfo) => {
    return isNullGroup(groupKeys, data);
  }
};
