import UserService from 'src/api/user';
import { useAnalytics } from '../useAnalytics';
import { useAppStore } from 'src/core/store';
import { useEffect } from 'react';
import { useSetDataSharingList } from '../useUser';

export const useInitDataSharingList = () => {
  const setDatasharingList = useSetDataSharingList();
  const { addAnalyticsLog } = useAnalytics('useInitDataSharingList');
  const isAppInit = useAppStore((state) => state.isAppInitComplete);

  useEffect(() => {
    if (isAppInit) {
      UserService.getDataSharingList()
        .then((res) => {
          setDatasharingList(res);
        })
        .catch((err) => addAnalyticsLog({ function: 'useInitDataSharingList', data: err, logType: 'error' }));
    }
  }, [addAnalyticsLog, isAppInit, setDatasharingList]);
};
