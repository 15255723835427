import Svg, { G, Path, Rect } from 'react-native-svg';
import React from 'react';
import { SimpleIconProps } from 'src/core';

const AddIcon = ({ size = 24, color = '#46D7CB' }: SimpleIconProps) => (
  <Svg width={`${size}px`} height={`${size}px`} viewBox="0 0 24 24">
    <G data-name="Layer 2">
      <G data-name="warning">
        <Rect width={`${size}px`} height={`${size}px`} transform="rotate(-90 12 12)" opacity="0" />
        <Path
          fill={color}
          d="M15 11H13V9C13 8.45 12.55 8 12 8C11.45 8 11 8.45 11 9V11H9C8.45 11 8 11.45 8 12C8 12.55 8.45 13 9 13H11V15C11 15.55 11.45 16 12 16C12.55 16 13 15.55 13 15V13H15C15.55 13 16 12.55 16 12C16 11.45 15.55 11 15 11ZM12 20C7.589 20 4 16.411 4 12C4 7.589 7.589 4 12 4C16.411 4 20 7.589 20 12C20 16.411 16.411 20 12 20ZM12 2C6.486 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12C22 6.486 17.514 2 12 2Z"
        />
      </G>
    </G>
  </Svg>
);

export default AddIcon;
