import { StyleSheet, View, ViewProps } from 'react-native';

import AssessmentCoach from './AssessmentCoach';
import { CoachInfo } from 'src/core/types/CoachInfo';
import React from 'react';
import { Text } from '@ui-kitten/components';
import moment from 'moment';
import { UIHelper as uh } from '../../../core';

//props
interface IAssessmentSummaryProps extends ViewProps {
  name: string;
  type: string;
  date: Date;
  coach: CoachInfo;
}

const AssessmentSummary = (props: IAssessmentSummaryProps) => {
  // styles
  const styleContainer = StyleSheet.create({
    container: {
      marginTop: uh.h2DP(28)
    },
    assessmentType: {
      marginTop: uh.h2DP(8)
    },
    date: {
      marginTop: uh.h2DP(4)
    },
    coach: {
      marginTop: uh.h2DP(24)
    },
    dateContainer: {
      flex: 1,
      flexDirection: 'row'
    }
  });

  // view
  return (
    <View style={styleContainer.container}>
      <Text category="h4">{props.name}</Text>
      <Text category="p2" style={styleContainer.assessmentType}>
        Type: {props.type}
      </Text>
      <View style={styleContainer.dateContainer}>
        <Text category="c1" appearance="hint" style={{ paddingRight: 5 }}>
          {moment(props.date).format('DD MMM YYYY')}
        </Text>
        <Text category="c1" appearance="hint">
          {moment(props.date).format('HH:mm')}
        </Text>
      </View>
      {props.coach.name != undefined && props.coach.name.length > 0 && (
        <AssessmentCoach caption="Practitioner" coach={props.coach} btnMessage="Manage" style={styleContainer.coach} />
      )}
    </View>
  );
};

export default AssessmentSummary;
