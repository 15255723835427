import { Button, Layout, Modal, Spinner, Text, useTheme } from '@ui-kitten/components';
import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';

import Counter from 'src/features/actions/components/Counter';
import { ErrorModal } from 'src/components/shared/ErrorModal';
import { PillarContainer } from 'src/components/home';
import { RootStackActionProps } from 'src/core/types/RootNavStackParamList';
import { mainStyles } from '../_mainStyles';
import { UIHelper as uh } from 'src/core';
import { useAssignAction } from 'src/core/hooks/useActions';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';

const NewAction = ({
  route: {
    params: { action }
  },
  navigation
}: RootStackActionProps<'NewAction'>) => {
  const styleContainer = StyleSheet.create({
    container: {
      flex: 1,
      gap: uh.h2DP(12)
    },
    pillarContainer: {
      alignItems: 'flex-start',
      gap: uh.h2DP(4)
    },
    loading: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      height: '100%',
      width: '100%'
    }
  });

  const transMessage = {
    pillarsLabel: 'PILLAR',
    durationLabel: 'DURATION (DAYS)',
    alertTimeLabel: 'START TIME',
    frequencyLabel: 'FREQUENCY',
    addToScheduleButton: 'Add to my schedule'
  };

  const t = useTranslationFunc(transMessage);

  const [assignAction, loading, error] = useAssignAction(navigation.goBack);

  const th = useTheme();
  const [days, setDays] = useState<number>(action.durationDays ?? 0);
  const [initHours, initMinutes] = action.startTimes?.[0].split(':') as [string, string];
  const [alertTime, setAlertTime] = useState<number>(Number(initMinutes) + Number(initHours) * 60 ?? 0);
  const [frequency, setFrequency] = useState<number>(days);

  const timeFormat = () => {
    let minutes = String(alertTime % 60);
    if (Number(minutes) < 10) {
      minutes = `0${minutes}`;
    }

    let hours = String(Math.floor(alertTime / 60));

    if (Number(hours) < 10) {
      hours = `0${hours}`;
    }
    return `${hours}:${minutes}`;
  };

  return (
    <Layout level="3" style={[styleContainer.container, mainStyles.mainScreenContainer]}>
      <Modal visible={loading} style={styleContainer.loading}>
        <Spinner size="giant" />
      </Modal>
      <Text category="h5">{action.name}</Text>
      <Text category="p2">{action.text}</Text>
      <View style={styleContainer.pillarContainer}>
        <Text category="p2" style={{ color: th['color-basic-600'] }}>
          {t('pillarsLabel')}
        </Text>
        <PillarContainer type={action.pillar.toLowerCase()} size={'large'} />
      </View>
      <View>
        <Text category="p2" style={{ color: th['color-basic-600'], marginBottom: uh.h2DP(8) }}>
          {t('durationLabel')}
        </Text>
        <Counter counter={days} setCounter={setDays} changeBy={1} maxCounter={366} minCounter={0} />
      </View>
      <View>
        <Text category="p2" style={{ color: th['color-basic-600'], marginBottom: uh.h2DP(8) }}>
          {t('alertTimeLabel')}
        </Text>
        <Counter
          counter={alertTime}
          setCounter={setAlertTime}
          changeBy={5}
          maxCounter={1435}
          minCounter={0}
          format={timeFormat}
        />
      </View>
      <View style={{ flex: 1 }}>
        <Text category="p2" style={{ color: th['color-basic-600'], marginBottom: uh.h2DP(8) }}>
          {t('frequencyLabel')}
        </Text>
        <Counter counter={frequency} setCounter={setFrequency} changeBy={1} maxCounter={days} minCounter={0} />
      </View>
      <Button
        onPress={() => assignAction({ id: action.id, durationDays: days, alertTime: timeFormat(), frequency })}
        size={'large'}>
        {t('addToScheduleButton')}
      </Button>
      <ErrorModal visible={error} message={error} closeBtnClick={() => navigation.goBack()} />
    </Layout>
  );
};

export default NewAction;
