import { ConversationsDto } from 'src/core/types/Chat';
import ConversationsListItem from './ConversationsListItem';
import React from 'react';
import { ScrollView } from 'react-native';

interface ConversationsListProps {
  conversations: ConversationsDto[];
}

const ConversationsList = ({ conversations }: ConversationsListProps): JSX.Element => {
  if (conversations.length === 0) {
    return <></>;
  }

  return (
    <ScrollView>
      {conversations.map((conversation: ConversationsDto, ind: number) => (
        <ConversationsListItem key={ind} conversation={conversation} isLastItem={ind + 1 === conversations.length} />
      ))}
    </ScrollView>
  );
};

export default ConversationsList;
