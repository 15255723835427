import { Camera, CameraCapturedPicture, CameraType } from 'expo-camera';
import { IMessage, User } from 'react-native-gifted-chat';
import { Icon, Modal, Spinner, useTheme } from '@ui-kitten/components';
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native';
import React, { useRef, useState } from 'react';

import { UIHelper as uh } from 'src/core';
import { v4 as uuidv4 } from 'uuid';

interface ChatTakePictureProps {
  showCamera: boolean;
  setShowCamera: (showCamera: boolean) => void;
  sendMessage: (message: IMessage[]) => void;
  user: User;
}

const ChatTakePicture = ({ showCamera, setShowCamera, sendMessage, user }: ChatTakePictureProps) => {
  const [cameraType, setCameraType] = useState<CameraType>(CameraType.back);
  const [picture, setPicture] = useState<CameraCapturedPicture>();

  const [loading, setIsLoading] = useState<boolean>(false);

  const cameraRef = useRef<Camera>(null);

  const th = useTheme();

  const styleContainer = StyleSheet.create({
    camera: { flex: 1, width: '100%', alignSelf: 'center' },
    cameraView: {
      flex: 1,
      width: '100%'
    },
    topButtons: {
      position: 'absolute',
      top: uh.h2DP(42)
    },
    flipButton: {
      left: uh.h2DP(24)
    },
    closeButton: {
      right: uh.h2DP(24)
    },
    previewButtons: {
      height: uh.h2DP(42),
      width: uh.h2DP(42),
      backgroundColor: 'white',
      borderRadius: uh.currentViewPort(),
      alignItems: 'center',
      justifyContent: 'center'
    },
    takePicture: {
      borderWidth: 1,
      borderColor: th['color-basic-100'],
      borderRadius: uh.currentViewPort(),
      position: 'absolute',
      bottom: uh.h2DP(48),
      height: uh.h2DP(64),
      width: uh.h2DP(64),
      alignSelf: 'center'
    },
    imagePreview: { height: '100%', width: '100%' },
    loading: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      height: '100%',
      zIndex: 999,
      top: 0,
      left: 0,
      width: uh.currentViewPort(),
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    }
  });

  const takePicture = async () => {
    setIsLoading(true);
    const takenPicture = await cameraRef.current?.takePictureAsync();
    setPicture(takenPicture);
    setIsLoading(false);
  };

  const submitPicture = () => {
    if (picture) {
      sendMessage([
        {
          _id: uuidv4(),
          text: '',
          image: picture.uri,
          createdAt: new Date(),
          user: user
        }
      ]);
      setShowCamera(false);
      setPicture(undefined);
    }
  };

  if (showCamera) {
    return (
      <Modal visible={true} style={{ height: '100%', width: '100%' }}>
        {loading && (
          <View style={styleContainer.loading}>
            <Spinner size="giant" />
          </View>
        )}
        {picture ? (
          <View>
            <Image style={styleContainer.imagePreview} source={{ uri: picture.uri }} />
            <TouchableOpacity
              onPress={() => setPicture(undefined)}
              style={[styleContainer.topButtons, styleContainer.flipButton]}>
              <Icon height={uh.h2DP(24)} width={uh.h2DP(24)} fill={th['color-basic-100']} name="close-outline" />
            </TouchableOpacity>
            <TouchableOpacity onPress={submitPicture} style={[styleContainer.topButtons, styleContainer.closeButton]}>
              <Icon
                height={uh.h2DP(24)}
                width={uh.h2DP(24)}
                fill={th['color-primary-500']}
                name="paper-plane-outline"
              />
            </TouchableOpacity>
          </View>
        ) : (
          <Camera ref={cameraRef} style={styleContainer.camera} type={cameraType}>
            <View style={styleContainer.cameraView}>
              <TouchableOpacity
                onPress={() => setCameraType(cameraType === CameraType.back ? CameraType.front : CameraType.back)}
                style={[styleContainer.topButtons, styleContainer.flipButton]}>
                <Icon height={uh.h2DP(24)} width={uh.h2DP(24)} fill={th['color-basic-100']} name="flip-2-outline" />
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => setShowCamera(false)}
                style={[styleContainer.topButtons, styleContainer.closeButton]}>
                <Icon height={uh.h2DP(24)} width={uh.h2DP(24)} fill={th['color-basic-100']} name="close-outline" />
              </TouchableOpacity>
              <TouchableOpacity onPress={takePicture} style={styleContainer.takePicture} />
            </View>
          </Camera>
        )}
      </Modal>
    );
  }
  return <></>;
};

export default ChatTakePicture;
