import { BackendApi } from '../../shared';
import { SimpleTelemetryData, TelemetryService } from '../model';

export const WebTelemetryService: TelemetryService = {
  sendTelemetry: (type: string, data: SimpleTelemetryData): Promise<any> => {
    return BackendApi.post('/telemetry', {
      type,
      ...data
    });
  },
  sendMultiTelemetry: (type: string, data: SimpleTelemetryData[]): Promise<any> => {
    return BackendApi.put('/telemetry', {
      type,
      content: data
    });
  }
};
