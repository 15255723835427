import { CheckBox, Text, useTheme } from '@ui-kitten/components';
import React from 'react';
import { ViewProps, StyleSheet, View } from 'react-native';
import { UIHelper as uh } from '../../../core';

//props
interface PillarCheckBoxProps extends ViewProps {
  name: string;
  type: string;
  checked: boolean;
  disabled: boolean;
  onChange: (checked: boolean, indeterminate: boolean) => void;
}

// styles
const styleContainer = StyleSheet.create({
  container: {
    borderRadius: 4,
    paddingLeft: uh.w2DP(6),
    paddingRight: uh.w2DP(8),
    paddingTop: uh.h2DP(6),
    paddingBottom: uh.h2DP(6),
    columnGap: 4
  },
  checkbox: {
    margin: 2,
    borderColor: '#8F9BB3'
  }
});

const PillarCheckBox = ({ name, type, checked, disabled, onChange }: PillarCheckBoxProps) => {
  const th = useTheme();

  // view
  return (
    <View
      style={[
        styleContainer.container,
        {
          backgroundColor: disabled === true ? th['color-basic-200'] : th['color-' + type + '-200']
        }
      ]}>
      <CheckBox
        checked={checked}
        style={styleContainer.checkbox}
        testID={`pillar_${type}_checkbox`}
        status="basic"
        disabled={disabled}
        onChange={onChange}>
        {(evaProps) => (
          <Text
            {...evaProps}
            style={{ color: disabled === true ? th['color-basic-500'] : th['color-' + type + '-700'], marginLeft: 8 }}>
            {name}
          </Text>
        )}
      </CheckBox>
    </View>
  );
};

export default PillarCheckBox;
