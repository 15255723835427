import { CheckBox, Layout, Text } from '@ui-kitten/components';
import { GuidePager, GuideScreen } from 'src/components/scan/GuidePager';
import React, { useState } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import { BloodworkAvatar } from 'src/core/icons/scan/BloodworkAvatar';
import { BloodworkGlassAvatar } from 'src/core/icons/scan/BloodworkGlassAvatar';
import { ScanGuideDescription } from 'src/components/scan/ScanGuideDescription';
import { showUploadBloodworkModal } from 'src/core/hooks/useScan/helper';
import { UIHelper as uh } from 'src/core';
import { useIsLightColorScheme } from 'src/core/hooks/useIsLightColorScheme';
import { useNavigation } from '@react-navigation/native';
import { useScanGuideSkip } from 'src/core/hooks/useScanGuideSkip';

const BloodworkGuideScreen = () => {
  const { setScanGuideSkip } = useScanGuideSkip();
  const navigation = useNavigation();

  const isLightTheme = useIsLightColorScheme();

  const [checkBox, setCheckBox] = useState(false);

  const styles = StyleSheet.create({
    safeAreaView: { flex: 1 },
    titleText: {
      textAlign: 'center',
      marginHorizontal: 44,
      marginVertical: 11
    },
    tickTitleText: {},
    container: {
      flex: 1
    },
    guideScreenContainer: {
      height: '100%',
      justifyContent: 'space-between',
      paddingTop: 24
    },
    guideScreenContainer2: {
      height: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: 24
    },
    guideScreenText: {
      paddingVertical: 6,
      textAlign: 'center'
    },
    imageStyle: {
      position: 'absolute',
      height: uh.height() - 160,
      width: uh.currentViewPort(),
      resizeMode: 'cover'
    },
    imageStyle2: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '25%'
    },
    guide1HeaderContainer: {
      paddingTop: 10,
      width: '100%',
      height: 300
    },
    guide2HeaderContainer: {
      paddingTop: 10,
      height: uh.height() - 170
    },
    titleText1: {
      textAlign: 'center',
      marginHorizontal: 44,
      marginVertical: 11,
      color: 'black'
    },
    titleText2: {
      textAlign: 'center',
      marginHorizontal: 44,
      marginVertical: 11,
      color: 'white'
    },
    tickTitleContainer: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 6
    },
    blueTick: {
      height: 16,
      width: 16,
      marginHorizontal: 11
    },
    tickTitleText1: {
      color: 'black'
    },
    tickTitleText2: {
      color: 'white'
    },
    star: {
      height: 26,
      width: 26,
      marginHorizontal: 11
    },
    subtitle: {
      fontSize: 20,
      textAlign: 'center',
      width: '60%',
      alignSelf: 'center',
      marginTop: 11,
      color: 'white'
    },
    bottomSectionContainer: {
      marginTop: 30,
      height: '17%'
    },
    loading: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      height: uh.height(),
      zIndex: 999,
      top: 0,
      left: 0,
      width: uh.currentViewPort(),
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    backdropStyle: { backgroundColor: 'rgba(0, 0, 0, 0.5)' }
  });

  const BloodworkGuidePage1: GuideScreen = {
    renderGuideScreen: () => {
      return (
        <Layout level="2" style={styles.guideScreenContainer}>
          <View>
            <ScanGuideDescription scanType={'Bloodwork'} />
          </View>
          <View style={styles.imageStyle2}>
            <BloodworkAvatar color={isLightTheme ? '#2E3A59' : 'white'} />
          </View>
        </Layout>
      );
    }
  };
  const BloodworkGuidePage2: GuideScreen = {
    renderGuideScreen: () => {
      return (
        <Layout level="2" style={styles.guideScreenContainer2}>
          <View>
            <Text style={styles.guideScreenText} category="h5">
              Upload Bloodwork file
            </Text>
            <Text style={styles.guideScreenText} category="p1">
              PDF documents only
            </Text>
            <Text style={styles.guideScreenText} category="p1">
              Ensure that the file size is up to 5 MB
            </Text>
          </View>
          <View style={{ marginLeft: 24 }}>
            <BloodworkGlassAvatar color={isLightTheme ? '#2E3A59' : 'white'} />
          </View>
          <TouchableOpacity
            onPress={() => setCheckBox((checked) => !checked)}
            activeOpacity={1}
            style={[{ flexDirection: 'row', alignSelf: 'flex-start', marginLeft: 24, marginBottom: 16 }]}>
            <CheckBox checked={checkBox} onChange={(checked) => setCheckBox(checked)} style={{ marginRight: 8 }} />
            <Text>{"Don't show again"}</Text>
          </TouchableOpacity>
        </Layout>
      );
    }
  };

  const scan = async () => {
    setScanGuideSkip(checkBox, 'bloodwork');
    showUploadBloodworkModal(navigation, checkBox);
  };

  const guidePages = [BloodworkGuidePage1, BloodworkGuidePage2];
  return (
    <Layout level="1" style={{ flex: 1 }}>
      <View style={styles.container}>
        <GuidePager
          guides={guidePages}
          onLastBackPress={() => {
            navigation.goBack();
          }}
          onLastNextPress={async () => {
            scan();
          }}
          lastButtonTitle="Upload File"
        />
      </View>
    </Layout>
  );
};

export default BloodworkGuideScreen;
