import { Text, Icon, Layout, useTheme } from '@ui-kitten/components';
import React from 'react';
import { View, ViewProps, StyleSheet, TouchableOpacity } from 'react-native';
import { UIHelper as uh } from '../../core';

//props
interface ModalHeaderProps extends ViewProps {
  caption: string;
  btnClickHandler: () => void;
  showFileSizeLimit?: boolean;
}

const ModalHeader = ({ style, caption, btnClickHandler, showFileSizeLimit = false }: ModalHeaderProps) => {
  const th = useTheme();
  // styles
  const styleContainer = StyleSheet.create({
    container: {
      zIndex: 999,
      borderTopLeftRadius: 16,
      borderTopRightRadius: 16
    },
    header: {
      marginTop: uh.h2DP(12),
      height: 48,
      alignItems: 'center',
      flexDirection: 'row'
    },
    icon: {
      width: 20,
      height: 20,
      marginLeft: -16
    },
    captionContainer: {
      flex: 1,
      marginRight: uh.w2DP(50),
      alignContent: 'center'
    },
    caption: {
      textAlign: 'center'
    }
  });

  // view
  return (
    <Layout level="1" style={[styleContainer.container, style]}>
      <View style={styleContainer.header}>
        <TouchableOpacity onPress={btnClickHandler}>
          <View
            style={{ height: 40, width: 60, alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}>
            <Icon name="arrow-ios-back-outline" style={styleContainer.icon} fill={th['color-basic-600']} />
          </View>
        </TouchableOpacity>
        <View style={styleContainer.captionContainer}>
          <Text category="s1" style={styleContainer.caption}>
            {caption}
          </Text>
          {showFileSizeLimit && (
            <Text category="c1" style={[styleContainer.caption, { paddingTop: 2 }]}>
              Total file size should be up to 5 MB
            </Text>
          )}
        </View>
      </View>
    </Layout>
  );
};

export default ModalHeader;
