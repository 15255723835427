import React from 'react';
import { AppTheme, useAppTheme } from 'src/core/hooks/useAppTheme';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';
import { NotiSegmentItem } from 'src/core/types/Notification';
import SegmentMenu from './components/SegmentMenu';
import Brands from 'brands.json';

const ThemeMenuContainer = () => {
  const { getTheme, changeTheme } = useAppTheme();
  const transMessage = {
    name: 'Apperance',
    description: 'Choose your theme preference.',
    themeLight: 'Light',
    themeDark: 'Dark',
    themeSystem: 'System'
  };
  const t = useTranslationFunc(transMessage);
  const segments: NotiSegmentItem[] = [
    {
      name: t('themeLight'),
      value: AppTheme.LIGHT
    },
    {
      name: t('themeDark'),
      value: AppTheme.DARK,
      disabled: Brands.disabled_dark_mode
    },
    {
      name: t('themeSystem'),
      value: AppTheme.SYSTEM,
      disabled: Brands.disabled_dark_mode
    }
  ];

  const [seletedTheme, setSelectedTheme] = React.useState(AppTheme.SYSTEM);
  const loadData = React.useCallback(async () => {
    const theme = await getTheme();
    setSelectedTheme(theme);
  }, [getTheme]);

  React.useEffect(() => {
    loadData();
  }, [loadData]);

  //view
  return (
    <SegmentMenu
      name={t('name')}
      description={t('description')}
      segments={segments}
      onSelect={(value) => {
        setSelectedTheme(value as AppTheme);
        changeTheme(value as AppTheme);
      }}
      selectedValue={seletedTheme}
    />
  );
};

export default ThemeMenuContainer;
