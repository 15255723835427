import { Socket, io } from 'socket.io-client';
import { useCallback, useEffect, useState } from 'react';

import { useAnalytics } from './useAnalytics';
import { useAppStore } from '../store';
import { useChat } from './useChat';
import { useGetWebSocketUrl } from './useBrands';

interface UseWebsockets {
  emitMessage: (message: any, http?: boolean, onError?: (error: any) => any) => void;
  emitTyping: (isTyping: boolean) => void;
  emitSeen: (seenMessage: string) => void;
}
export const useGetSocket = (): Socket | undefined => {
  return useAppStore((state) => state.socket);
};
export const useSetSocket = (): ((socket: Socket | undefined) => void) => {
  return useAppStore((state) => state.setSocket);
};

export const useWebSockets = (): UseWebsockets => {
  const socket = useGetSocket();
  const { uploadMedia } = useChat();

  const emitMessage = useCallback(
    (message: any, http?: boolean, onError?: (response?: any) => any) => {
      if (socket) {
        if (http) {
          return uploadMedia(message).catch((e) => onError?.(e));
        } else {
          socket.emit('chat_message', message, (response?: any) => onError?.(response));
        }
      }
    },
    [socket, uploadMedia]
  );

  const emitTyping = (isTyping: boolean) => {
    if (socket) {
      socket.emit('', isTyping);
    }
  };

  const emitSeen = (seenMessage: string) => {
    if (socket) {
      socket.emit('', seenMessage);
    }
  };

  return { emitMessage, emitTyping, emitSeen };
};

export const useWebsocketsInit = (): { socketError: any } => {
  const [socketError, setSocketError] = useState<any>();

  const setSocket = useSetSocket();
  const websocketUrl = useGetWebSocketUrl();
  const { addAnalyticsLog } = useAnalytics('useWebsockets.ts');
  useEffect(() => {
    try {
      const socket = io(`wss://${websocketUrl}`, { path: '/ws-chat' });
      setSocket(socket);
      return () => {
        socket.disconnect();
        setSocket(undefined);
      };
    } catch (e) {
      addAnalyticsLog({ function: 'useWebsocketsInit', data: e, logType: 'error' });
      setSocketError(e);
    }
  }, [addAnalyticsLog, setSocket, websocketUrl]);

  return { socketError: socketError };
};
