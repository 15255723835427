import React from 'react';
import { ITenantSharedProps } from '../TenantSharedProps';
import { TenantModal } from './TenantModal';

export const CancelTenant = (props: ITenantSharedProps) => {
  const title = `Are you sure you want to stop collaborating with ${props.proUserName}`;

  const message =
    "Your wellness partner will not be able to guide you without access to your data. Please confirm if you'd like to stop sharing you data with them.";

  return (
    <TenantModal
      visible={props.visible}
      title={title}
      message={message}
      icon={'alert-triangle-outline'}
      primaryButtonText={'Stop Sharing Data'}
      onPrimaryButton={props.onFinalButtonClick}
      visibleSecondaryButton={true}
      secondaryButtonText={'Cancel'}
      onSecondaryButton={props.onSecondaryButton}
      testingAttributes={props.testingAttributes}
    />
  );
};
