import { useState } from 'react';
import WearableService from '../../api/wearable';
import { useFlushTelemetry } from './useTelemetry';

import { useWearableSdk } from './useWearableSdk';

export const useTerraSyncDataApi = (): [() => void, boolean] => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const flushTelemetry = useFlushTelemetry();
  const { requestSyncTerraData } = useWearableSdk();

  const syncData = () => {
    setIsLoading(true);
    requestSyncTerraData(false)
      .then(() => flushTelemetry())
      .finally(() => {
        setIsLoading(false);
      });
  };

  return [syncData, isLoading];
};

export const useFixTerraSubscriptionApi = (): [() => void, boolean] => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { getUserAuth } = useWearableSdk();

  const fixSubscription = () => {
    setIsLoading(true);
    getUserAuth()
      .then((terraId: string) => WearableService.fixSubscription(terraId))
      .finally(() => {
        setIsLoading(false);
      });
  };

  return [fixSubscription, isLoading];
};
