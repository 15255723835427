import { ScrollView, StyleSheet, View } from 'react-native';

import AppInternals from 'src/components/settings/developerSettings/AppInternals';
import ConnectedBackend from 'src/components/settings/developerSettings/ConnectedBackend';
import { Layout } from '@ui-kitten/components';
import React from 'react';
import ResetScanGuideSkip from 'src/components/settings/developerSettings/ResetScanGuideSkip';
import { settingStyles } from './_settingStyles';

const DeveloperSettingsScreen = () => {
  const styleContainer = StyleSheet.create({
    screenContainer: { flex: 1, justifyContent: 'space-between' },
    safeAreaView: { flex: 1 }
  });

  return (
    <Layout level="2" style={[styleContainer.screenContainer, settingStyles.settingScreenContainer]}>
      <View style={styleContainer.safeAreaView}>
        <ScrollView>
          <ConnectedBackend />
          <AppInternals />
          <ResetScanGuideSkip />
        </ScrollView>
      </View>
    </Layout>
  );
};

export default DeveloperSettingsScreen;
