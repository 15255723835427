import { useCallback, useEffect, useState } from 'react';
import { useAnalytics } from './useAnalytics';
import { useAppStore } from '../store';
import { useIsUserLogged } from './useUser';
import { ApiError } from '../types/ApiResponse';
import { ActionTip } from '../types/Action';
import SleepStatService from 'src/api/sleepStat';

export const useGetPillarActionTips = () => {
  const sleepActionTips = useAppStore((state) => state.sleepActionTips);
  return sleepActionTips;
};

interface UseSleepActionTipsProps {
  refreshing: boolean;
  setRefreshing: (refreshing: boolean) => void;
}

export const useSleepActionTipsEffect = ({ refreshing, setRefreshing }: UseSleepActionTipsProps) => {
  const setSleepActionTips = useAppStore((state) => state.setSleepActionTips);
  const sleepActionTips = useAppStore((state) => state.sleepActionTips);
  const { addAnalyticsLog } = useAnalytics('usePillarActionTips.ts');
  const [isActionTipsLoading, setIsActionTipsLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>();
  const isUserLogged = useIsUserLogged();

  const loadSleepActionTips = useCallback(
    (promise: Promise<ActionTip[]>) => {
      promise
        .then((actionItems) => {
          setSleepActionTips(actionItems);
        })
        .catch((err: ApiError) => {
          if (!err.isAborted) {
            setError(err.error);
          }
          addAnalyticsLog({ function: 'loadSleepActionTips', data: err, logType: 'error' });
        })
        .finally(() => {
          setRefreshing(false);
          setIsActionTipsLoading(false);
        });
    },
    [addAnalyticsLog, setRefreshing, setSleepActionTips]
  );

  useEffect(() => {
    if (isUserLogged) {
      const { promise, abort } = SleepStatService.getSleepActionTips();
      loadSleepActionTips(promise);
      return abort;
    }
  }, [isUserLogged, loadSleepActionTips]);

  useEffect(() => {
    if (refreshing) {
      const { promise, abort } = SleepStatService.getSleepActionTips();
      loadSleepActionTips(promise);
      return abort;
    }
  }, [refreshing, loadSleepActionTips]);

  return [sleepActionTips, isActionTipsLoading, error];
};
