import { ActionTip, UIHelper as uh } from '../../../core';
import { Layout, Text, useTheme } from '@ui-kitten/components';
import React, { useCallback, useState } from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';

import ActionTipIcon from './ActionTipIcon';
import CreateActionButton from './CreateActionButton';
import { MarkdownText } from 'src/components/kalichat';
import { useFocusEffect } from '@react-navigation/native';
import { useIsLightColorScheme } from 'src/core/hooks/useIsLightColorScheme';

//props
interface ActionTipProps extends ViewProps {
  actionTip: ActionTip;
  pillar: string;
  onCreateAction: (actionId: number) => void;
}

// styles
const styleContainer = StyleSheet.create({
  container: {
    flex: 1
  },
  tip: {
    flexDirection: 'row',
    flexGrow: 1,
    flexShrink: 1,
    borderWidth: uh.w2DP(0.3),
    borderRadius: 8,
    padding: 16,
    columnGap: uh.w2DP(8)
  },
  name: {
    marginTop: uh.h2DP(2)
  },
  description: {
    flexGrow: 1,
    flexShrink: 1,
    alignItems: 'center',
    height: 'auto',
    marginTop: uh.h2DP(8)
  },
  leftContent: {
    width: 32
  },
  rightContent: {
    flexShrink: 1
  }
});

const ActionTipItem = ({ actionTip, pillar, onCreateAction }: ActionTipProps) => {
  const th = useTheme();

  const isLightTheme = useIsLightColorScheme();
  const [rerenderKey, setRerenderKey] = useState<number>(0);

  const markdownStyles = StyleSheet.create({
    view: {
      flexGrow: 1,
      flexShrink: 1,
      height: 'auto'
    },
    text: {
      color: isLightTheme ? th['color-basic-900'] : th['color-basic-100']
    }
  });

  useFocusEffect(
    useCallback(() => {
      setRerenderKey((prev) => prev + 1);
    }, [])
  );
  return (
    <View style={styleContainer.container} key={actionTip.id}>
      <Layout level="3">
        <View style={styleContainer.tip}>
          <View style={styleContainer.leftContent}>
            <ActionTipIcon pillar={pillar} actionType={actionTip.scope} />
          </View>
          <View style={styleContainer.rightContent} key={rerenderKey}>
            <Text category="s2" style={styleContainer.name}>
              {actionTip.name}
            </Text>
            <MarkdownText styles={markdownStyles}>{actionTip.text}</MarkdownText>
            <CreateActionButton
              onClick={() => {
                onCreateAction(actionTip.id);
              }}
            />
          </View>
        </View>
      </Layout>
    </View>
  );
};

export default ActionTipItem;
