import { Button, Icon, Layout, Spinner, Text, useTheme } from '@ui-kitten/components';
import { Platform, StyleSheet, View } from 'react-native';

import { FlexSpacer } from 'src/core/helpers/LayoutHelper';
import React from 'react';

export interface ScanTypeCardProps {
  CardImage: () => JSX.Element;
  scanDescription: string;
  scanTime: string;
  buttonText: string;
  onPressButton?: () => void;
  membership: boolean;
  onInvalidButton?: () => void;
  hasError?: boolean;
  isLoading?: boolean;
}

export const ScanTypeCard = (props: ScanTypeCardProps) => {
  const {
    CardImage,
    scanDescription,
    scanTime,
    buttonText,
    onPressButton,
    membership,
    onInvalidButton,
    hasError,
    isLoading
  } = props;

  const th = useTheme();
  const styles = StyleSheet.create({
    card: {
      flex: 1,
      borderRadius: 16,
      marginHorizontal: Platform.OS == 'web' ? 16 : 4,
      marginTop: Platform.OS == 'web' ? 16 : 6,
      marginBottom: 4,
      alignItems: 'center',
      justifyContent: 'flex-start'
    },
    cardImage: {
      height: Platform.OS == 'web' ? 160 : 64,
      width: Platform.OS == 'web' ? 160 : 64,
      resizeMode: 'contain',
      alignItems: 'center',
      justifyContent: 'center'
    },
    scanDescription: {
      textAlign: 'center',
      marginHorizontal: 4,
      marginTop: 16,
      maxWidth: 128
    },
    scanTimeContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      marginVertical: 12,
      alignSelf: 'flex-end',
      marginRight: 8
    },
    timerText: {
      color: th['color-basic-500']
    },
    timerIcon: {
      height: 25,
      width: 25,
      resizeMode: 'contain',
      tintColor: th['color-basic-500'],
      marginHorizontal: 4
    },
    button: {
      marginTop: 16,
      marginBottom: 22,
      alignSelf: 'stretch',
      marginLeft: 10,
      marginRight: 10
    },
    buttonTitle: {
      color: 'white',
      fontSize: 12
    }
  });

  return (
    <Layout style={styles.card} level="1">
      <View style={styles.scanTimeContainer}>
        <Icon name="clock-outline" style={styles.timerIcon} />
        <Text category="c1" style={styles.timerText}>
          {scanTime}
        </Text>
      </View>
      <View style={styles.cardImage}>
        <CardImage />
      </View>
      <Text category="p2" style={styles.scanDescription}>
        {scanDescription}
      </Text>
      <FlexSpacer />
      <Button
        size="small"
        appearance={isLoading ? 'outline' : 'filled'}
        disabled={hasError || isLoading}
        style={[
          styles.button,
          !membership && {
            borderColor: th['color-primary-transparent-200'],
            backgroundColor: th['color-primary-transparent-100']
          }
        ]}
        onPress={membership ? (onPressButton ? onPressButton : undefined) : onInvalidButton}>
        <View>
          {isLoading ? (
            <Spinner size={'small'} />
          ) : (
            <Text
              category="c2"
              style={[styles.buttonTitle, !membership && { color: th['color-primary-transparent-300'] }]}>
              {buttonText}
            </Text>
          )}
        </View>
      </Button>
    </Layout>
  );
};
