import { Button, Input, Layout } from '@ui-kitten/components';
import { Keyboard, StyleSheet } from 'react-native';
import React, { useState } from 'react';

import { AxiosError } from 'axios';
import { BackendApi } from 'src/api/shared';
import { ErrorModal } from 'src/components/shared/ErrorModal';
import { InfoModal } from 'src/components/shared/InfoModal';
import { KeyboardAvoidingView } from 'src/components/shared';
import Spinner from 'react-native-loading-spinner-overlay';
import TenantDropdownList from '../../components/settings/TenantDropdownList';
import { feedbackScreen } from 'src/core/brands';
import { settingStyles } from './_settingStyles';
import { UIHelper as uh } from 'src/core';
import { useAnalytics } from 'src/core/hooks/useAnalytics';
import { useFocusEffect } from '@react-navigation/native';
import { useGetAppName } from 'src/core/hooks/useBrands';
import { useGetUserAttributes } from 'src/core/hooks/useUser';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';

const FeedbackScreen = () => {
  //styles
  const { addAnalyticsLog } = useAnalytics('FeedBackScreen.tsx');
  const t = useTranslationFunc(feedbackScreen);
  const appName = useGetAppName();

  const styleContainer = StyleSheet.create({
    screenContainer: { flex: 1 },
    safeAreaView: { flex: 1 },
    input: { height: '80%', marginTop: uh.h2DP(16) },
    text: { minHeight: 300, maxHeight: '80%', textAlignVertical: 'top' },
    sendButton: {
      marginBottom: uh.h2DP(10)
    }
  });

  const [loading, setLoading] = useState<boolean>(true);
  const [fullname, setFullname] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [feedback, setFeedback] = useState<string>('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [selectedReasonIndex, setSelectedReasonIndex] = useState(0);

  const [visibleModal, setVisibleModal] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>('');

  const [visibleErrorModal, setVisibleErrorModal] = useState<boolean>(false);
  const [error, setError] = useState<AxiosError>({} as AxiosError);
  const userAttributes = useGetUserAttributes();

  const reasons = ['Bug Report', 'App Feedback'];

  const loadUserData = React.useCallback(async () => {
    try {
      setLoading(true);
      setFullname(String(userAttributes.name));
      setEmail(String(userAttributes.email));
    } catch (err) {
      addAnalyticsLog({ function: 'loadUserData', data: err, logType: 'error' });
    } finally {
      setLoading(false);
    }
  }, [addAnalyticsLog, userAttributes.name, userAttributes.email]);

  useFocusEffect(
    React.useCallback(() => {
      loadUserData();
    }, [loadUserData])
  );

  const changeReason = (index: number) => {
    setSelectedReasonIndex(index);
  };

  interface FeedbackPayload {
    name: string;
    email: string;
    reason: string;
    feedback: string;
  }

  const send = async () => {
    setIsSubmitted(true);
    setLoading(true);

    const payload: FeedbackPayload = {
      name: fullname,
      email: email,
      reason: reasons[selectedReasonIndex],
      feedback: feedback
    };

    await BackendApi.post('/user-feedback', payload)
      .then((response) => {
        setModalMessage(response.data);
        setVisibleModal(true);
        addAnalyticsLog({ logType: 'FeedbackSent' });
      })
      .catch((err) => {
        setError(err);
        setVisibleErrorModal(true);
        addAnalyticsLog({ function: 'send', data: err, logType: 'error' });
      });

    setFeedback('');
    setLoading(false);
    setIsSubmitted(false);
  };

  //view
  return (
    <Layout level="2" style={[styleContainer.screenContainer, settingStyles.settingScreenContainer]}>
      {loading && <Spinner />}
      <KeyboardAvoidingView showsVerticalScrollIndicator={false} extraHeight={0} style={styleContainer.screenContainer}>
        <TenantDropdownList
          caption="Reason"
          data={reasons}
          selectedIndex={selectedReasonIndex}
          selectItem={changeReason}
          accessible={true}
          accessibilityLabel="Reason dropdown list"
          testID="reason_dropdown_list"></TenantDropdownList>
        <Input
          multiline={true}
          size="large"
          style={styleContainer.input}
          textStyle={styleContainer.text}
          label="Comment"
          placeholder={t('feedbackPlaceholder', { app_name: appName })}
          value={feedback}
          onSubmitEditing={Keyboard.dismiss}
          keyboardType="default"
          returnKeyType="next"
          onChangeText={(text: string) => setFeedback(text)}
          accessible={true}
          accessibilityLabel="Feedback input field"
          testID="feedback_input"
        />
        <Button
          status="primary"
          disabled={feedback.length <= 0 || isSubmitted === true ? true : false}
          onPress={() => send()}
          style={styleContainer.sendButton}
          accessible={true}
          accessibilityLabel="Send feedback button"
          testID="send_feedback">
          Send Feedback
        </Button>
      </KeyboardAvoidingView>
      <InfoModal
        visible={visibleModal}
        message={modalMessage}
        closeBtnClick={() => {
          setVisibleModal(false);
        }}
        testingAttributes={{
          accessible: true,
          accessibilityLabel: 'Successful feedback info modal',
          testID: 'feedback_info_modal'
        }}
      />
      <ErrorModal
        visible={visibleErrorModal}
        closeBtnClick={() => setVisibleErrorModal(false)}
        buttonTitle={'OK'}
        message={error}
      />
    </Layout>
  );
};

export default FeedbackScreen;
