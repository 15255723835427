import Svg, { Circle, G, Path } from 'react-native-svg';

import React from 'react';
import { SimpleIconProps } from 'src/core';

const MarkerIcon = ({ size = 40, color = '#46D7CB', backgroundColor = '#DCFBED' }: SimpleIconProps) => (
  <Svg width={`${size}px`} height={`${size}px`} viewBox="0 0 40 40">
    <G data-name="Layer 2">
      <G data-name="arrow-ios-forward">
        <Circle cx="20" cy="20" r="20" fill={backgroundColor} />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.329 28.0007C22.262 28.0007 22.193 27.9977 22.124 27.9907C21.244 27.9037 20.554 27.2827 20.364 26.4077L17.681 14.0157L14.918 20.3977C14.759 20.7637 14.398 21.0007 14 21.0007H11C10.447 21.0007 10 20.5527 10 20.0007C10 19.4477 10.447 19.0007 11 19.0007H13.344L15.85 13.2117C16.205 12.3957 16.987 11.9207 17.876 12.0097C18.756 12.0967 19.446 12.7177 19.636 13.5927L22.319 25.9847L25.082 19.6027C25.241 19.2367 25.601 19.0007 26 19.0007H29C29.553 19.0007 30 19.4477 30 20.0007C30 20.5527 29.553 21.0007 29 21.0007H26.656L24.15 26.7887C23.823 27.5417 23.122 28.0007 22.329 28.0007Z"
          fill={color}
        />
      </G>
    </G>
  </Svg>
);

export default MarkerIcon;
