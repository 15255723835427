import React, { useEffect, useState } from 'react';
import { RootStackScreenProps, UIHelper as uh } from 'src/core';
import { StyleSheet, View } from 'react-native';

import { DeviceModal } from 'src/screens/scan/BodyScanGuideScreen';
import { ErrorModal } from '../shared/ErrorModal';
import { InfoModal } from '../shared/InfoModal';
import { MultiScanResultStatus } from 'src/core/hooks/useMultiScan';
import { Spinner } from '@ui-kitten/components';
import { useScan } from 'src/core/hooks/useScan';

const ScanLoadingScreen = ({
  navigation,
  route: {
    params: { scanInputs, scanType, callback }
  }
}: RootStackScreenProps<'ScanLoadingScreen'>) => {
  const styles = StyleSheet.create({
    loading: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      height: '100%',
      zIndex: 999,
      top: 0,
      left: 0,
      width: uh.currentViewPort(),
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    }
  });

  const [error, setError] = useState<any>();
  const [deviceModalData, setDeviceModalData] = useState<DeviceModal>({
    type: 'permission',
    message: ''
  });
  const [visibleErrorModal, setVisibleErrorModal] = useState<boolean>(false);
  const [visibleDeviceModal, setVisibleDeviceModal] = useState<boolean>(false);
  const { bodyScan, faceScan } = useScan();

  useEffect(() => {
    if (scanType === 'face') {
      faceScan(scanInputs)
        .then((res: any) => {
          if (res?.status === MultiScanResultStatus.CANCEL) {
            navigation.goBack();
          }
          callback?.();
        })
        .catch((err) => {
          if (err.type === 'permission') {
            setDeviceModalData(err);
            setVisibleDeviceModal(true);
          } else {
            setError(err);
            setVisibleErrorModal(true);
          }
        });
    } else if (scanType === 'body') {
      bodyScan(scanInputs)
        .then((res: any) => {
          if (res?.status === MultiScanResultStatus.CANCEL) {
            navigation.goBack();
          }
          callback?.();
        })
        .catch((err) => {
          if (err.type === 'permission') {
            setDeviceModalData(err);
            setVisibleDeviceModal(true);
          } else {
            setError(err);
            setVisibleErrorModal(true);
          }
        });
    }
  }, [bodyScan, callback, faceScan, navigation, scanInputs, scanType]);

  return (
    <View style={styles.loading}>
      <Spinner size="giant" />
      <InfoModal
        visible={visibleDeviceModal}
        message={deviceModalData.message}
        closeBtnClick={() => navigation.goBack()}
      />
      <ErrorModal
        visible={visibleErrorModal}
        closeBtnClick={() => navigation.goBack()}
        buttonTitle={'OK'}
        message={error}
      />
    </View>
  );
};

export default ScanLoadingScreen;
