import React, { useCallback } from 'react';

import { ErrorModal } from 'src/components/shared/ErrorModal';
import { NotiSettingItem } from 'src/core/types/Notification';
import NotificationsMenu from './components/NotificationsMenu';
import Spinner from 'react-native-loading-spinner-overlay/lib';
import { useAppStore } from 'src/core/store';
import { useNotifications } from 'src/core/hooks/useNotifications';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';
import { useUpdateUserSettings } from 'src/core/hooks/useUserSettings';

const NotificationsMenuContainer = () => {
  const enabledActionNotifications = useAppStore((state) => state.enabledActionNotifications);
  const setEnabledActionNotifications = useAppStore((state) => state.setEnabledActionNotifications);
  const enabledBiomarkerOfTheDayNotifications = useAppStore((state) => state.enabledBiomarkerOfTheDayNotifications);
  const setEnabledBiomarkerOfTheDayNotifications = useAppStore(
    (state) => state.setEnabledBiomarkerOfTheDayNotifications
  );
  const enabledSleepStatNotifications = useAppStore((state) => state.enabledSleepStatNotifications);
  const setEnabledSleepStatNotifications = useAppStore((state) => state.setEnabledSleepStatNotifications);
  const transMessage = {
    name: 'Notifications',
    description: 'Would you like to receive notifications for your actions and chat messages among other activities?',
    allowNotifications: 'Allow notifications',
    nofiticationActionReminders: 'Action reminders',
    notificationBiomarkerOfTheDay: 'Biomarker of the day',
    notificationSleepStats: 'Sleep stats'
  };
  const t = useTranslationFunc(transMessage);
  const [updateUserSettings, isUpdatingUserSettings, error] = useUpdateUserSettings();
  const { isPushNotificationsEnabled, isPushNotificationsSupported, setSubscription } = useNotifications();
  const [enableNotifications, setEnableNotifications] = React.useState<boolean>(true);
  const [pushNotificationsSupported, setPushNotificationsSupported] = React.useState<boolean>(false);

  const onPrimaryNotificationChange = async (isChecked: boolean) => {
    setEnableNotifications(isChecked);
    setSubscription(isChecked);
  };

  const primary: NotiSettingItem = {
    name: t('allowNotifications'),
    checked: true,
    onChange: onPrimaryNotificationChange
  };
  const items: NotiSettingItem[] = [
    {
      name: t('nofiticationActionReminders'),
      checked: enabledActionNotifications,
      onChange: (isChecked: boolean) => {
        setEnabledActionNotifications(isChecked);
        updateUserSettings({ enabledActionNotifications: isChecked });
      }
    },
    {
      name: t('notificationBiomarkerOfTheDay'),
      checked: enabledBiomarkerOfTheDayNotifications,
      onChange: (isChecked: boolean) => {
        setEnabledBiomarkerOfTheDayNotifications(isChecked);
        updateUserSettings({ enabledBiomarkerOfTheDayNotifications: isChecked });
      }
    },
    {
      name: t('notificationSleepStats'),
      checked: enabledSleepStatNotifications,
      onChange: (isChecked: boolean) => {
        setEnabledSleepStatNotifications(isChecked);
        updateUserSettings({ enabledSleepStatNotifications: isChecked });
      }
    }
  ];

  const getPushNotificationSettings = useCallback(async () => {
    const pushSupported: boolean = await isPushNotificationsSupported();
    setPushNotificationsSupported(pushSupported);

    if (pushSupported) {
      const pushEnabled: boolean = await isPushNotificationsEnabled();
      setEnableNotifications(pushEnabled);
    }
  }, [isPushNotificationsEnabled, isPushNotificationsSupported]);

  React.useEffect(() => {
    getPushNotificationSettings();
  }, [getPushNotificationSettings]);

  //view
  return (
    <>
      <NotificationsMenu
        name={t('name')}
        description={t('description')}
        primary={primary}
        items={items}
        enableNotifications={enableNotifications}
        pushNotificationsSupported={pushNotificationsSupported}
      />
      <ErrorModal visible={error != undefined} message={error} />
      <Spinner visible={isUpdatingUserSettings} />
    </>
  );
};

export default NotificationsMenuContainer;
