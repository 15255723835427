import { ApiResponse } from 'src/core/types/ApiResponse';
import { BackendApi } from 'src/api/shared';
import { HealthMarker } from 'src/core/types/Bloodwork';
import { HealthMarkerReportListItem } from 'src/core/types/HealthMarkerReportList';
import { HealthMarkerResponse } from 'src/core/types/HealthMarkerReport';
import { HealthMarkerService } from '../model';
import { MultiScanResultData } from 'src/core/hooks/useMultiScan';

export const WebHealthMarkerService: HealthMarkerService = {
  sendBodyScan: (data: MultiScanResultData): Promise<HealthMarkerResponse> => {
    return BackendApi.post('/health-markers/integrations/ahi-body-scan', data).then((response) => response.data);
  },
  sendFaceScan: (data: MultiScanResultData): Promise<HealthMarkerResponse> => {
    return BackendApi.post('/health-markers/integrations/ahi-face-scan', data).then((response) => response.data);
  },
  getUserAssessments: (): ApiResponse<HealthMarkerReportListItem[]> => {
    const abortController = new AbortController();
    return {
      promise: BackendApi.get('/health-markers/data/user-assessments', { signal: abortController.signal })
        .then((response) => {
          return response.data;
        })
        .catch((error) => Promise.reject({ error: error, isAborted: abortController.signal.aborted })),
      abort: () => {
        abortController.abort();
      }
    };
  },
  getPendingBloodwork: (): ApiResponse<string> => {
    const abortController = new AbortController();
    return {
      promise: BackendApi.get('/health-markers/get-pending-bloodwork', { signal: abortController.signal })
        .then((response) => {
          return response.data.documentId ?? '';
        })
        .catch((error) => Promise.reject({ error: error, isAborted: abortController.signal.aborted })),
      abort: () => {
        abortController.abort();
      }
    };
  },
  getUnitDetails: (): ApiResponse<HealthMarker[]> => {
    const abortController = new AbortController();
    return {
      promise: BackendApi.get('/health-markers/health-markers-unit-details', { signal: abortController.signal })
        .then((response) => {
          return response.data;
        })
        .catch((error) => Promise.reject({ error: error, isAborted: abortController.signal.aborted })),
      abort: () => {
        abortController.abort();
      }
    };
  }
};
