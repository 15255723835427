import React from 'react';
import { StyleSheet, ViewProps, View } from 'react-native';
import { Layout, Text } from '@ui-kitten/components';
import { ActionTip, UIHelper as uh } from 'src/core';
import { SleepStatHelper } from 'src/core/helpers/SleepStatHelper';
import ScoreTips from '../pillars/ScoreTips';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';
import { SleepStat, SleepStatGroup } from 'src/core/types/SleepStat';
import TotalDuration from './conponents/TotalDuration';
import moment from 'moment';
import SleepGroup from './SleepGroup';
import Spinner from 'react-native-loading-spinner-overlay/lib';

//props
interface PillarDetailProps extends ViewProps {
  sleepStat: SleepStat;
  actionTips: ActionTip[];
  isLoading: boolean;
  onCreateAction: (actionId: number) => void;
}

//styles
const styleContainer = StyleSheet.create({
  contentContainer: {
    rowGap: uh.h2DP(16)
  },
  loadingContainer: {
    padding: uh.h2DP(16),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});

const PillarDetail = ({ sleepStat, actionTips, isLoading, onCreateAction }: PillarDetailProps) => {
  const transMessage = {
    pillarInsights: 'INSIGHTS',
    restPillar: 'Rest'
  };
  const t = useTranslationFunc(transMessage);

  if (isLoading) {
    return (
      <View style={styleContainer.loadingContainer}>
        <Spinner visible={true} />
      </View>
    );
  }

  const groups: SleepStatGroup[] = [];
  groups.push({
    chartType: 'Line',
    chartData: sleepStat.sleepHypnogramSamples,
    chartHistoriesData: sleepStat.sleepHypnogramHistory,
    keys: [
      'sleepAsleepDurationAsleepState',
      'sleepAsleepDurationDeepSleepState',
      'sleepAsleepDurationRemSleepState',
      'sleepAsleepDurationLightSleepState',
      'temperatureDelta'
    ]
  });

  groups.push({
    name: 'Breaths',
    chartType: 'Bar',
    keys: ['breathsAvgBreathsPerMin', 'breathsMaxBreathsPerMin', 'breathsMinBreathsPerMin']
  });

  groups.push({
    name: 'Heart Rate',
    chartType: 'Bar',
    chartData: sleepStat.heartRateHrSamples,
    chartHistoriesData: sleepStat.heartRateHistory,
    keys: [
      'heartRateAvgHrBpm',
      'heartRateMaxHrBpm',
      'heartRateMinHrBpm',
      'heartRateAvgHrVariabilityRmssd',
      'heartRateAvgHrVariabilitySsdn'
    ]
  });

  return (
    <Layout level="2" style={[styleContainer.contentContainer]}>
      <Text category="p2">{moment(sleepStat.stats.startTime).format('DD/MMM/YY')}</Text>
      <TotalDuration time={SleepStatHelper.showValueAndUnit(sleepStat.stats.sleepDurationInBed, 'hh:mm')} />
      {groups.map((group, index) => (
        <SleepGroup group={group} key={index} sleepStatInfo={sleepStat.stats} />
      ))}
      <ScoreTips
        caption={t('pillarInsights')}
        actionTips={actionTips}
        pillar={t('restPillar')}
        onCreateAction={onCreateAction}
      />
    </Layout>
  );
};

export default PillarDetail;
