import React from 'react';
import { TouchableOpacity } from 'react-native';
import SearchIcon from './icons/SearchIcon';
import CloseSearchIcon from './icons/CloseSearchIcon';

interface RightIconProps {
  onClick: () => void;
  isSearching: boolean;
}

const RightIcon = ({ onClick, isSearching }: RightIconProps) => (
  <TouchableOpacity onPress={onClick}>{isSearching ? <CloseSearchIcon /> : <SearchIcon />}</TouchableOpacity>
);

export default RightIcon;
