import { StyleSheet, View } from 'react-native';
import { Text, useTheme } from '@ui-kitten/components';
import { useGetAppName, useGetDataHandlingWebsite } from 'src/core/hooks/useBrands';

import { MarkdownText } from '../kalichat';
import React from 'react';
import { scanGuideDescription } from 'src/core/brands';
import { useIsLightColorScheme } from 'src/core/hooks/useIsLightColorScheme';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';

export type ScanType = 'Face' | 'Body' | 'HRV' | 'Bloodwork';

interface ScanGuideDescriptionProps {
  scanType: ScanType;
}

export const ScanGuideDescription = ({ scanType }: ScanGuideDescriptionProps) => {
  const HEADER_TEXT =
    scanType !== 'Bloodwork'
      ? 'No images ever leave your phone.'
      : 'Your data is secure. We process it on your phone using machine learning models.';
  const DESCRIPTION_TEXT =
    scanType !== 'Bloodwork'
      ? "We require access to the phone's camera for the scan, but all images are immediately deleted after scan completion."
      : 'We require access to your blood test results, but all files are immediately deleted after completion of the analysis.';

  const th = useTheme();
  const isLightTheme = useIsLightColorScheme();

  const t = useTranslationFunc(scanGuideDescription);
  const dataHandlingWebsite = useGetDataHandlingWebsite();
  const appName = useGetAppName();

  const MARKDOWN_TEXT = t('dataHandlingInfoMessage', { data_handling_website: dataHandlingWebsite, app_name: appName });
  const styles = StyleSheet.create({
    title: {
      textAlign: 'center',
      alignSelf: 'center',
      color: isLightTheme ? th['color-basic-800'] : th['color-basic-100'],
      marginHorizontal: 16,
      marginTop: 12
    },
    description: {
      textAlign: 'center',
      alignSelf: 'center',
      color: isLightTheme ? th['color-basic-900'] : th['color-basic-200'],
      marginHorizontal: 16,
      marginTop: 12
    }
  });

  const markdownStyles = StyleSheet.create({
    view: {
      fontFamily: 'Poppins-Regular',
      color: th['text-basic-color'],
      fontSize: 14,
      textAlign: 'center',
      marginBottom: 10,
      alignItems: 'center'
    },
    strong: {
      fontFamily: 'Poppins-Bold'
    },
    autolink: {
      color: th['color-primary-500']
    },
    text: {
      textAlign: 'center',
      fontFamily: 'Poppins-Regular',
      color: th['text-basic-color'],
      fontSize: 14
    }
  });

  return (
    <View>
      <Text category="h6" style={styles.title}>
        {HEADER_TEXT}
      </Text>
      <Text style={styles.description}>{DESCRIPTION_TEXT}</Text>
      <MarkdownText styles={markdownStyles}>{MARKDOWN_TEXT}</MarkdownText>
    </View>
  );
};
