import { useCallback, useEffect, useState } from 'react';
import ScoreService from 'src/api/scores';
import { useAnalytics } from './useAnalytics';
import { useAppStore } from '../store';
import { useIsUserLogged } from './useUser';
import { ScoreItemDto, ScoreItem } from '../types/Pillar';
import { ApiError } from '../types/ApiResponse';
import moment from 'moment';

export const useGetPillarScoreHistories = () => {
  const pillarScoreHistories = useAppStore((state) => state.pillarScoreHistories);
  return pillarScoreHistories;
};

interface UsePillarScoreHistoriesProps {
  id: string;
  refreshing: boolean;
  setRefreshing: (refreshing: boolean) => void;
}

export const usePillarScoreHistoriesEffect = ({ id, refreshing, setRefreshing }: UsePillarScoreHistoriesProps) => {
  const setPillarScoreHistories = useAppStore((state) => state.setPillarScoreHistories);
  const pillarScoreHistories = useAppStore((state) => state.pillarScoreHistories);
  const { addAnalyticsLog } = useAnalytics('usePillarScoreHistories.ts');
  const [isScoreHistoriesLoading, setIsScoreHistoriesLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>();
  const isUserLogged = useIsUserLogged();

  const loadPillarScoreHistories = useCallback(
    (promise: Promise<ScoreItemDto[]>) => {
      promise
        .then((scoreHistoriesDto) => {
          setPillarScoreHistories(
            scoreHistoriesDto.map((item) => {
              return { value: Number(item.score), date: moment(item.createdOn).toDate() } as ScoreItem;
            })
          );
        })
        .catch((err: ApiError) => {
          if (!err.isAborted) {
            setError(err.error);
          }
          addAnalyticsLog({ function: 'loadPillarScoreHistories', data: err, logType: 'error' });
        })
        .finally(() => {
          setIsScoreHistoriesLoading(false);
          setRefreshing(false);
        });
    },
    [addAnalyticsLog, setRefreshing, setPillarScoreHistories]
  );

  useEffect(() => {
    if (isUserLogged) {
      const { promise, abort } = ScoreService.getPillarScoreHistories(id);
      loadPillarScoreHistories(promise);
      return abort;
    }
  }, [isUserLogged, loadPillarScoreHistories, id]);

  useEffect(() => {
    if (refreshing) {
      const { promise, abort } = ScoreService.getPillarScoreHistories(id);
      loadPillarScoreHistories(promise);
      return abort;
    }
  }, [refreshing, loadPillarScoreHistories, id]);

  return [pillarScoreHistories, isScoreHistoriesLoading, error];
};
