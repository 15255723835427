import React, { PropsWithChildren } from 'react';

import { Layout } from '@ui-kitten/components';
import { SafeAreaView } from 'react-native';
import { UIHelper as uh } from 'src/core';

const AddActionLayout = ({ children }: PropsWithChildren) => {
  return (
    <Layout level="3" style={[{ flex: 1, padding: uh.h2DP(12) }]}>
      <SafeAreaView style={{ flex: 1 }}>{children}</SafeAreaView>
    </Layout>
  );
};

export default AddActionLayout;
