
       const __ReactNativeSvgLoader = require('svgLoader').default({
  "memo":"true"
}
);
        import { CheckBox, Divider, Layout, Text, useTheme } from '@ui-kitten/components';
import { GuidePager, GuideScreen } from 'src/components/scan/GuidePager';
import React, { useState } from 'react';
import { RootStackScreenProps, UIHelper as uh } from 'src/core';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import AHILoadingScreen from 'src/components/scan/components/ScanLoading/container';

  const BodyScanAvatar = __ReactNativeSvgLoader(import('../../../assets/images/body_scan/body_scan_avatar.svg'));
  ;
import { BodyScanIcon } from 'src/core/icons/scan/ScanIcons';
import { ScanGuideDescription } from 'src/components/scan/ScanGuideDescription';
import { mainStyles } from '../main/_mainStyles';
import { useGetIsMultiScanInit } from 'src/core/hooks/useMultiScan';
import { useScanGuideSkip } from 'src/core/hooks/useScanGuideSkip';

export interface DeviceModal {
  type: 'permission' | 'osVersion';
  message: string;
}

const BodyScanGuideScreen = ({ route, navigation }: RootStackScreenProps<'BodyScanGuide'>) => {
  const { setScanGuideSkip } = useScanGuideSkip();

  const [checkBox, setCheckBox] = useState<boolean>(false);

  const isMultiScanInit = useGetIsMultiScanInit();

  const th = useTheme();

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      marginTop: 24
    },
    guideScreenContainer: {
      flex: 1,
      height: uh.height() - 170,
      width: uh.currentViewPort()
    },
    imageStyle: {
      position: 'absolute',
      height: uh.height() - 70,
      width: uh.currentViewPort(),
      resizeMode: 'cover'
    },
    imageStyle2: { alignItems: 'center', aspectRatio: 1 },
    guideContentContainer: { height: '100%', justifyContent: 'space-between', paddingTop: 24 },
    subtitle: {
      fontSize: 22,
      textAlign: 'center',
      width: '60%',
      alignSelf: 'center',
      marginTop: 11
    },
    loading: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      height: uh.height(),
      zIndex: 999,
      top: 0,
      left: 0,
      width: uh.currentViewPort(),
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    firstScreenContainer: {
      flex: 1
    },

    titleTextNoTopLabel: {
      textAlign: 'center',
      alignSelf: 'center',
      color: 'black',
      marginHorizontal: 16,
      marginTop: 11
    },
    titleTextNoTopMargin: {
      textAlign: 'center',
      alignSelf: 'center',
      color: 'black',
      marginHorizontal: 16,
      marginTop: 12
    },
    blueCapsuleText: {
      color: 'white',
      paddingHorizontal: 11
    },
    blueCapsuleContainer: {
      backgroundColor: '#007AFF',
      borderRadius: 10,
      height: 20,
      marginTop: 11 + 40,
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
      marginBottom: 5
    },
    bottomButton: {
      bottom: 30,
      marginBottom: 11,
      marginLeft: 16,
      marginRight: 16
    },
    backdropStyle: { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
    guidePage2: {
      height: '100%',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    guideList: {
      flex: 1,
      borderWidth: 1,
      borderRadius: 12,
      borderColor: th['border-basic-color-4'],
      marginHorizontal: 36,
      maxHeight: 350,
      justifyContent: 'space-between',
      alignItems: 'flex-start'
    },
    guideListItem: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center'
    }
  });

  const bodyScanGuidePage1: GuideScreen = {
    renderGuideScreen: () => {
      return (
        <Layout level="2" style={styles.guideContentContainer}>
          <ScanGuideDescription scanType={'Body'} />
          <View style={styles.imageStyle2}>
            <BodyScanAvatar />
          </View>
        </Layout>
      );
    }
  };

  const guideItems = [
    { IconImage: BodyScanIcon.StarIcon, text: 'Have a clean background', divider: true },
    { IconImage: BodyScanIcon.BodyIcon, text: 'Wear form fitting clothing', divider: true },
    { IconImage: BodyScanIcon.PhoneIcon, text: 'Place phone upright and position at hip level', divider: true },
    {
      IconImage: BodyScanIcon.DistanceIcon,
      text: "Leave a distance of 8'-13' (244 cm - 396 cm) between you and camera",
      divider: false
    }
  ];

  const bodyScanGuidePage2: GuideScreen = {
    renderGuideScreen: () => {
      return (
        <Layout level="2" style={styles.guidePage2}>
          <View style={{ alignItems: 'center' }}>
            <Text style={{ marginVertical: 36 }} category="h6">
              Before starting, make sure you
            </Text>
            <Layout level="1" style={[styles.guideList, mainStyles.mainScreenContainer]}>
              {guideItems.map((item, index): any => {
                const { IconImage } = item;
                return (
                  <React.Fragment key={`guide-list-item-${index}`}>
                    <View style={styles.guideListItem}>
                      <View
                        style={{
                          height: 24,
                          width: 24,
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginRight: 16
                        }}>
                        <IconImage />
                      </View>
                      <Text>{item.text}</Text>
                      <Divider style={{ backgroundColor: 'black' }} />
                    </View>
                    {item.divider && (
                      <Divider style={{ backgroundColor: th['border-basic-color-4'], alignSelf: 'stretch' }} />
                    )}
                  </React.Fragment>
                );
              })}
            </Layout>
          </View>
          <TouchableOpacity
            onPress={() => setCheckBox((checked) => !checked)}
            activeOpacity={1}
            style={[{ flexDirection: 'row', alignSelf: 'flex-start', marginLeft: 24, marginBottom: 16 }]}>
            <CheckBox checked={checkBox} onChange={(checked) => setCheckBox(checked)} style={{ marginRight: 8 }} />
            <Text>{"Don't show again"}</Text>
          </TouchableOpacity>
        </Layout>
      );
    }
  };

  const scan = async () => {
    navigation.navigate('ScanLoadingScreen', {
      scanInputs: (route.params as any).bodyScanInputs,
      scanType: 'body',
      callback: () => {
        setScanGuideSkip(checkBox, 'body');
        if (checkBox) {
          navigation.navigate('ScanGettingStarted');
        }
      }
    });
  };

  const guidePages = [bodyScanGuidePage1, bodyScanGuidePage2];
  return (
    <View style={{ flex: 1 }}>
      {isMultiScanInit ? (
        <GuidePager
          guides={guidePages}
          onLastBackPress={() => {
            navigation.goBack();
          }}
          onLastNextPress={async () => {
            scan();
          }}
          lastButtonTitle="Start Scan"
        />
      ) : (
        <AHILoadingScreen scanType="body" onCancel={() => navigation.goBack()} />
      )}
    </View>
  );
};

export default BodyScanGuideScreen;

        