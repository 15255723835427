import React, { ReactNode } from 'react';
import { TouchableOpacity, StyleSheet, View } from 'react-native';

interface HeaderLeftWrapperProps {
  onPress: () => void;
  children?: ReactNode | undefined;
}

// styles
const styleContainer = StyleSheet.create({
  iconContainer: { flexDirection: 'row', alignItems: 'center' },
  homeBackNavContainer: { flexDirection: 'row', alignItems: 'center', rowGap: 8, marginLeft: 16 },
  backNavContainer: { flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginLeft: 16 },
  backIcon: { width: 24, height: 24 },
  name: { marginLeft: 8 }
});

const HeaderLeftWrapper = ({ onPress, children }: HeaderLeftWrapperProps) => {
  return (
    <View style={styleContainer.iconContainer}>
      <TouchableOpacity
        style={styleContainer.backNavContainer}
        onPress={onPress}
        accessible={true}
        accessibilityLabel={'left_header_button'}
        testID={'left_header_return_btn'}>
        {children}
      </TouchableOpacity>
    </View>
  );
};

export default HeaderLeftWrapper;
