import { UIHelper as uh } from '../../core';
import { StyleSheet } from 'react-native';
import React from 'react';
import FavoriteListContainer from 'src/features/markers/FavoriteListContainer';
import { Layout } from '@ui-kitten/components';

//styles
const styleContainer = StyleSheet.create({
  screenContainer: {
    flex: 1,
    paddingBottom: uh.h2DP(20)
  }
});

const FavoriteScreen = () => {
  //view
  return (
    <Layout level="2" style={styleContainer.screenContainer}>
      <FavoriteListContainer />
    </Layout>
  );
};

export default FavoriteScreen;
