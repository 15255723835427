import React, { useEffect } from 'react';

import { Auth } from 'aws-amplify';
import { BackendApi } from 'src/api/shared';
import { RootStackScreenProps } from 'src/core/types/RootNavigationParamList';
import { Spinner } from '@ui-kitten/components';
import { View } from 'react-native';
import { useAppStore } from 'src/core/store';
import { useIsFocused } from '@react-navigation/native';

const TenantDataSharingScreen = ({ navigation, route }: RootStackScreenProps<'SharingApprove'>) => {
  const setDataSharing = useAppStore((state) => state.setDataSharing);
  const setDataSharingModal = useAppStore((state) => state.setDataSharingModal);
  const setPendingDataSharing = useAppStore((state) => state.setPendingDataSharing);
  const session = Auth.currentSession();
  const isFocused = useIsFocused();
  const { t, u } = route.params as any;

  useEffect(() => {
    if (isFocused) {
      session
        .then(() => {
          setPendingDataSharing('');
          BackendApi.post('/tenants/check-user-sharing-request-status', {
            proUserId: u,
            tenantKey: t
          })
            .then((response) => {
              setDataSharing(true);
              setDataSharingModal({
                ...response.data,
                proUserId: u,
                tenantKey: t
              });
              navigation.navigate('Home');
            })
            .catch(() => null);
        })
        .catch(() => {
          setPendingDataSharing(route.path ? route.path : '');
          navigation.navigate('Login');
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused]);

  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <Spinner />
    </View>
  );
};

export default TenantDataSharingScreen;
