import { useCallback, useEffect, useState } from 'react';
import { useAnalytics } from './useAnalytics';
import { useAppStore } from '../store';
import { useIsUserLogged } from './useUser';
import { ScoreDriver } from '../types/Pillar';
import { ApiError } from '../types/ApiResponse';
import ScoreService from 'src/api/scores';

export const useGetPillarScoreDrivers = () => {
  const pillarScoreDrivers = useAppStore((state) => state.pillarScoreDrivers);
  return pillarScoreDrivers;
};

interface UsePillarScoreDriversProps {
  categoryKey: string;
  refreshing: boolean;
  setRefreshing: (refreshing: boolean) => void;
}

export const usePillarScoreDriversEffect = ({ categoryKey, refreshing, setRefreshing }: UsePillarScoreDriversProps) => {
  const setPillarScoreDrivers = useAppStore((state) => state.setPillarScoreDrivers);
  const pillarScoreDrivers = useAppStore((state) => state.pillarScoreDrivers);
  const { addAnalyticsLog } = useAnalytics('usePillarScoreDrivers.ts');
  const [isScoreDriversLoading, setIsScoreDriversLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>();
  const isUserLogged = useIsUserLogged();

  const loadPillarScoreDrivers = useCallback(
    (promise: Promise<ScoreDriver[]>) => {
      promise
        .then((scoreDrivers) => {
          setPillarScoreDrivers(scoreDrivers);
        })
        .catch((err: ApiError) => {
          if (!err.isAborted) {
            setError(err.error);
          }
          addAnalyticsLog({ function: 'loadPillarScoreDrivers', data: err, logType: 'error' });
        })
        .finally(() => {
          setIsScoreDriversLoading(false);
          setRefreshing(false);
        });
    },
    [addAnalyticsLog, setRefreshing, setPillarScoreDrivers]
  );

  useEffect(() => {
    if (isUserLogged) {
      const { promise, abort } = ScoreService.getPillarScoreDrivers(categoryKey);
      loadPillarScoreDrivers(promise);
      return abort;
    }
  }, [isUserLogged, loadPillarScoreDrivers, categoryKey]);

  useEffect(() => {
    if (refreshing) {
      const { promise, abort } = ScoreService.getPillarScoreDrivers(categoryKey);
      loadPillarScoreDrivers(promise);
      return abort;
    }
  }, [refreshing, loadPillarScoreDrivers, categoryKey]);

  return [pillarScoreDrivers, isScoreDriversLoading, error];
};
