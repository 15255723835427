import { ConversationMessage, ConversationsDto } from 'src/core/types/Chat';

import { ApiResponse } from 'src/core/types/ApiResponse';
import { BackendApi } from 'src/api/shared';
import { ChatService } from '../model';

export const WebChatService: ChatService = {
  getConversations: (): ApiResponse<ConversationsDto[]> => {
    const abortController = new AbortController();
    return {
      promise: BackendApi.get('chat/conversations', { signal: abortController.signal })
        .then((response) => {
          return response.data;
        })
        .catch((error) => Promise.reject({ error: error, isAborted: abortController.signal.aborted })),
      abort: () => {
        abortController.abort();
      }
    };
  },
  getMessageHistory: (conversationId: string, page = 1): ApiResponse<ConversationMessage[]> => {
    const abortController = new AbortController();
    return {
      promise: BackendApi.get(`chat/history/${conversationId}/page/${page}`, { signal: abortController.signal })
        .then((response) => {
          return response.data;
        })
        .catch((error) => Promise.reject({ error: error, isAborted: abortController.signal.aborted })),
      abort: () => {
        abortController.abort();
      }
    };
  },
  getMedia: (mediaId: string): ApiResponse<string> => {
    const abortController = new AbortController();
    return {
      promise: BackendApi.get(`/chat/media/${mediaId}`, { signal: abortController.signal })
        .then((response) => {
          return response.data;
        })
        .catch((error) => Promise.reject({ error: error, isAborted: abortController.signal.aborted })),
      abort: () => {
        abortController.abort();
      }
    };
  },
  postMedia: (file: string): ApiResponse<void> => {
    const abortController = new AbortController();
    return {
      promise: BackendApi.post('/chat/media', file, { signal: abortController.signal })
        .then((response) => {
          return response.data;
        })
        .catch((error) => Promise.reject({ error: error, isAborted: abortController.signal.aborted })),
      abort: () => {
        abortController.abort();
      }
    };
  }
};
