import { Divider, Icon, Text, useTheme } from '@ui-kitten/components';
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native';

import { ConversationsDto } from 'src/core/types/Chat';
import DefaultAvatar from 'assets/images/avatar.png';
import React from 'react';
import { UIHelper as uh } from 'src/core';
import { useGetAppName } from 'src/core/hooks/useBrands';
import { useNavigation } from '@react-navigation/native';

interface ConversationsListItemProps {
  conversation: ConversationsDto;
  isLastItem?: boolean;
}

const ConversationsListItem = ({ conversation, isLastItem = false }: ConversationsListItemProps): JSX.Element => {
  const th = useTheme();
  const navigation = useNavigation();

  const appName = useGetAppName();

  const styleContainer = StyleSheet.create({
    container: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingHorizontal: uh.h2DP(18),
      margin: uh.h2DP(6)
    },
    iconContainer: {
      flex: 1,
      backgroundColor: 'blue'
    },
    icon: {
      height: uh.h2DP(24),
      width: uh.h2DP(24)
    },
    imageContainer: {
      borderRadius: 999,
      overflow: 'hidden',
      backgroundColor: 'blue',
      marginRight: uh.h2DP(8)
    },
    image: {
      height: uh.h2DP(42),
      width: uh.h2DP(42)
    },
    informations: {
      alignSelf: 'flex-start',
      flex: 1,
      padding: uh.h2DP(8)
    },
    name: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      gap: uh.h2DP(8)
    },
    unreadMessages: {
      backgroundColor: th['color-danger-500'],
      borderRadius: 999,
      width: uh.h2DP(18),
      height: uh.h2DP(18),
      alignItems: 'center',
      justifyContent: 'center',
      color: 'transparent'
    },
    divider: {
      width: '90%',
      alignSelf: 'center',
      color: th['color-basic-300']
    },
    text: {
      fontSize: uh.h2DP(14),
      lineHeight: uh.h2DP(24)
    }
  });

  const { conversationId, users } = conversation;
  const { name, image, title, unreadMessages } = users[0];

  const userName = name ?? `${appName} User`;

  const handleNavigate = () => {
    navigation.navigate('Chat', {
      screen: 'ChatScreen',
      params: { id: conversationId, name: userName, avatar: image ? { uri: image } : DefaultAvatar }
    });
  };

  return (
    <>
      <View style={styleContainer.container}>
        <View style={styleContainer.imageContainer}>
          <Image style={styleContainer.image} source={image ? { uri: image } : DefaultAvatar} />
        </View>
        <View style={styleContainer.informations}>
          <View style={styleContainer.name}>
            <Text style={styleContainer.text} category={unreadMessages > 0 ? 's1' : 'p2'}>
              {userName}
            </Text>
            {unreadMessages > 0 && (
              <View style={styleContainer.unreadMessages}>
                <Text category="s2" style={{ color: 'white' }}>
                  {unreadMessages}
                </Text>
              </View>
            )}
          </View>
          <Text style={styleContainer.text} category="c1" appearance="hint">
            {title}
          </Text>
        </View>
        <TouchableOpacity onPress={handleNavigate}>
          <Icon style={styleContainer.icon} name="arrow-ios-forward-outline" />
        </TouchableOpacity>
      </View>
      {!isLastItem && <Divider style={styleContainer.divider} />}
    </>
  );
};

export default ConversationsListItem;
