import { NewHealthMarker } from '../types/HealthMarkerReport';
import { SortedType } from '../types/Marker';
import moment from 'moment';

interface UseFilterMarkers {
  filterData: (
    data: NewHealthMarker[],
    searchText: string,
    sortedType: SortedType,
    pillars?: string[]
  ) => NewHealthMarker[];
  filterPillar: (data: NewHealthMarker[], pillars?: string[]) => NewHealthMarker[];
}

export const useFilterMarkers = (): UseFilterMarkers => {
  // filter data
  const filterData = (
    data: NewHealthMarker[],
    searchText: string,
    sortedType: number,
    pillars: string[] = []
  ): NewHealthMarker[] => {
    let displayData = data;

    displayData = displayData.map((element) => {
      return {
        ...element,
        pillar: element.category,
        displayValue: String(element.latestValue),
        id: element.healthMarkerId,
        key: element.externalKey
      };
    });

    if (pillars.length > 0) {
      displayData = displayData.filter((element) => pillars.includes(element.pillar.toLowerCase()));
    }

    // filter by searchText
    if (searchText.length > 0) {
      displayData = displayData.filter((item: NewHealthMarker) =>
        item.name.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    // filter by sortedType
    if (sortedType == SortedType.MOST_RECENT) {
      // most recent
      displayData = displayData.sort((a, b) => {
        return moment(b.measuredDate).valueOf() - moment(a.measuredDate).valueOf();
      });
    } else if (sortedType == SortedType.ALPHABETICAL) {
      // alphabetical
      displayData = displayData.sort((a, b) => {
        return a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? 1 : -1;
      });
    } else if (sortedType == SortedType.OLDEST) {
      // oldest
      displayData = displayData.sort((a, b) => {
        return moment(a.measuredDate).valueOf() - moment(b.measuredDate).valueOf();
      });
    }

    return displayData;
  };

  const filterPillar = (data: NewHealthMarker[], pillars: string[] = []): NewHealthMarker[] => {
    let displayData = data;
    if (pillars.length > 0) {
      displayData = displayData.filter((element) => pillars.includes(element.pillar));
    }
    return displayData;
  };

  return { filterData, filterPillar };
};
