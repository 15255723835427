import { Button, Text, useTheme } from '@ui-kitten/components';
import React, { RefObject, useEffect, useMemo, useState } from 'react';

import ActionBottomSheet from './components/ActionBottomSheet';
import { ActionItem } from 'src/core/types/ActionItem';
import { BottomSheetModalMethods } from '@gorhom/bottom-sheet/lib/typescript/types';
import { StyleSheet } from 'react-native';
import { TenantDropdownList } from 'src/components/settings';
import moment from 'moment';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';

const recurrenceBottomSheetStyles = (errorColor: string) =>
  StyleSheet.create({
    saveBtn: {
      marginTop: 5
    },
    error: {
      color: errorColor
    }
  });

interface RecurrenceBottomSheetProps {
  sheetRef: RefObject<BottomSheetModalMethods>;
  isEditing: boolean;
  action?: ActionItem;
  onChange: (id: number, frequency: number, duration: number) => void;
}

interface DropdownData {
  value: number;
  name: string;
}

const RecurrenceBottomSheet = ({ sheetRef, isEditing, onChange, action }: RecurrenceBottomSheetProps) => {
  const th = useTheme();
  const [selectedDurationIndex, setSelectedDurationIndex] = useState<number>(0);
  const [selectedFrequencyIndex, setSelectedFrequencyIndex] = useState<number>(0);
  const [hasError, setHasError] = useState<boolean>(false);

  const styles = recurrenceBottomSheetStyles(th['color-danger-500']);

  const transMessage = useMemo(
    () => ({
      recurrenceBottomSheetEditRecurrence: 'Edit Recurrence',
      recurrenceBottomSheetSelectRecurrence: 'Select Action Recurrence',
      recurrenceBottomSheetFrequency: 'FREQUENCY',
      recurrenceBottomSheetFrequency0: 'One-time',
      recurrenceBottomSheetFrequency1: 'Daily',
      recurrenceBottomSheetFrequency7: 'Weekly',
      recurrenceBottomSheetFrequency30: 'Monthly',
      recurrenceBottomSheetFrequency90: 'Quarterly',
      recurrenceBottomSheetFrequency180: 'Half Yearly',
      recurrenceBottomSheetFrequency365: 'Yearly',
      recurrenceBottomSheetDuration: 'DURATION',
      recurrenceBottomSheetDuration180: '180 Days',
      recurrenceBottomSheetDuration90: '90 Days',
      recurrenceBottomSheetDuration60: '60 Days',
      recurrenceBottomSheetDuration30: '30 Days',
      recurrenceBottomSheetDuration7: '7 Days',
      recurrenceBottomSheetCustomDays: '{{num}} Days',
      recurrenceBottomSheetUpdateBtn: 'Update',
      recurrenceBottomSheetActivateBtn: 'Activate',
      recurrenceBottomSheetFrequencyError: 'Frequency must be less than duration'
    }),
    []
  );

  const t = useTranslationFunc(transMessage);

  const durations: DropdownData[] = useMemo(() => {
    const custom = action ? moment(action.endDate).diff(action.startDate, 'days') : 30;
    const daysTillNow = action ? moment(action.startDate).diff(new Date(), 'days') : custom;
    const regular = [
      {
        value: 7,
        name: t('recurrenceBottomSheetDuration7')
      },
      {
        value: 30,
        name: t('recurrenceBottomSheetDuration30')
      },
      {
        value: 60,
        name: t('recurrenceBottomSheetDuration60')
      },
      {
        value: 90,
        name: t('recurrenceBottomSheetDuration90')
      },
      {
        value: 180,
        name: t('recurrenceBottomSheetDuration180')
      }
    ].filter((freq) => freq.value >= daysTillNow);
    if (regular.find((duration) => duration.value === custom)) {
      return regular;
    }
    return [
      {
        value: custom,
        name: t('recurrenceBottomSheetCustomDays', { num: custom })
      },
      ...regular
    ].sort((a, b) => a.value - b.value);
  }, [t, action]);

  const frequencies: DropdownData[] = useMemo(() => {
    const custom = action?.originalRepeatFrequencyDays ?? 0;
    const regular = [
      {
        value: 0,
        name: t('recurrenceBottomSheetFrequency0')
      },
      {
        value: 1,
        name: t('recurrenceBottomSheetFrequency1')
      },
      {
        value: 2,
        name: t('recurrenceBottomSheetCustomDays', { num: 2 })
      },
      {
        value: 3,
        name: t('recurrenceBottomSheetCustomDays', { num: 3 })
      },
      {
        value: 7,
        name: t('recurrenceBottomSheetFrequency7')
      },
      {
        value: 30,
        name: t('recurrenceBottomSheetFrequency30')
      },
      {
        value: 90,
        name: t('recurrenceBottomSheetFrequency90')
      },
      {
        value: 180,
        name: t('recurrenceBottomSheetFrequency180')
      },
      {
        value: 365,
        name: t('recurrenceBottomSheetFrequency365')
      }
    ];

    if (!regular.find((req) => req.value === custom)) {
      regular.push({
        value: custom,
        name: t('recurrenceBottomSheetCustomDays', { num: custom })
      });
    }

    return regular.sort((a, b) => a.value - b.value);
  }, [t, action]);

  useEffect(() => {
    if (action) {
      const duration = moment(action.endDate).diff(action.startDate, 'days');
      setSelectedDurationIndex(durations.findIndex((data) => data.value === duration) ?? 0);
      setSelectedFrequencyIndex(frequencies.findIndex((freq) => freq.value === action.repeatFrequencyDays) ?? 0);
    } else {
      setSelectedDurationIndex(0);
      setSelectedFrequencyIndex(0);
    }
  }, [action, frequencies, durations]);

  const changeDuration = (index: number) => {
    setSelectedDurationIndex(index);
    setHasError(frequencies[selectedFrequencyIndex].value > durations[index].value);
  };

  const changeFrequency = (index: number) => {
    setSelectedFrequencyIndex(index);
    setHasError(frequencies[index].value > durations[selectedDurationIndex].value);
  };

  const handleSave = () => {
    if (action) {
      onChange(action.id, frequencies[selectedFrequencyIndex].value, durations[selectedDurationIndex].value);
    }
    sheetRef.current?.dismiss();
  };

  return (
    <ActionBottomSheet
      sheetRef={sheetRef}
      title={t(isEditing ? 'recurrenceBottomSheetEditRecurrence' : 'recurrenceBottomSheetSelectRecurrence')}>
      <TenantDropdownList
        caption={t('recurrenceBottomSheetDuration')}
        data={durations.map((duration) => duration.name)}
        selectedIndex={selectedDurationIndex}
        selectItem={changeDuration}
      />
      <TenantDropdownList
        caption={t('recurrenceBottomSheetFrequency')}
        data={frequencies.map((frequency) => frequency.name)}
        selectedIndex={selectedFrequencyIndex}
        selectItem={changeFrequency}
      />
      {hasError && (
        <Text category="label" appearance="hint" style={styles.error}>
          {t('recurrenceBottomSheetFrequencyError')}{' '}
        </Text>
      )}
      <Button
        style={styles.saveBtn}
        disabled={hasError}
        status="primary"
        size="large"
        accessible={true}
        accessibilityLabel="Next action"
        testID="button_save_recurrence"
        onPress={handleSave}>
        {t(isEditing ? 'recurrenceBottomSheetUpdateBtn' : 'recurrenceBottomSheetActivateBtn')}
      </Button>
    </ActionBottomSheet>
  );
};

export default RecurrenceBottomSheet;
