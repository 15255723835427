import { useCallback, useEffect, useState } from 'react';

import { ApiError } from '../types/ApiResponse';
import MarkerService from 'src/api/marker';
import { NewHealthMarker } from '../types/HealthMarkerReport';
import { useAnalytics } from './useAnalytics';
import { useAppStore } from '../store';
import { useIsUserLogged } from './useUser';

type UseMarkerEffect = [NewHealthMarker[], boolean, any];

export const useGetFavoriteMarkers = () => {
  return useAppStore((state) => state.favoriteMarkers);
};

export const useSetFavoriteMarkers = () => {
  return useAppStore((state) => state.setFavoriteMarkers);
};

interface UseMarkerEffectProps {
  refreshing: boolean;
  setRefreshing: (refreshing: boolean) => void;
}

export const useFavoriteMarkersEffect = ({ refreshing, setRefreshing }: UseMarkerEffectProps): UseMarkerEffect => {
  const [isFavoriteMarkersLoading, setIsFavoriteMarkersLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>();

  const { addAnalyticsLog } = useAnalytics('useMyMarkers.ts');
  const favoriteMarkers = useAppStore((state) => state.favoriteMarkers);
  const setFavoriteMarkers = useAppStore((state) => state.setFavoriteMarkers);
  const isUserLogged = useIsUserLogged();

  const loadFavoriteMarkers = useCallback(
    (promise: Promise<NewHealthMarker[]>) => {
      promise
        .then((markers: NewHealthMarker[]) => {
          const newMarkers = markers.filter(
            (item: NewHealthMarker) =>
              item.categories != undefined && item.categories.length > 0 && item.category != null
          );
          setFavoriteMarkers(newMarkers);
        })
        .catch((err: ApiError) => {
          if (!err.isAborted) {
            setError(err.error);
          }
          addAnalyticsLog({ function: 'loadFavoriteMarkers', data: err, logType: 'error' });
        })
        .finally(() => {
          setIsFavoriteMarkersLoading(false);
          setRefreshing(false);
        });
    },
    [addAnalyticsLog, setRefreshing, setFavoriteMarkers]
  );

  useEffect(() => {
    if (isUserLogged) {
      const { promise, abort } = MarkerService.getFavoriteMarkers();
      loadFavoriteMarkers(promise);
      return abort;
    }
  }, [isUserLogged, loadFavoriteMarkers]);

  useEffect(() => {
    if (refreshing) {
      const { promise, abort } = MarkerService.getFavoriteMarkers();
      loadFavoriteMarkers(promise);
      return abort;
    }
  }, [loadFavoriteMarkers, refreshing]);
  return [favoriteMarkers, isFavoriteMarkersLoading, error];
};

export const useLatestMarkersEffect = ({ refreshing, setRefreshing }: UseMarkerEffectProps): UseMarkerEffect => {
  const [isLatestMarkersLoading, setIsLastestMarkersLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>();

  const { addAnalyticsLog } = useAnalytics('useLatestMarkers.ts');
  const latestMarkers = useAppStore((state) => state.latestMarkers);
  const setLatestMarkers = useAppStore((state) => state.setLatestMarkers);
  const isUserLogged = useIsUserLogged();

  const loadLatestMarkers = useCallback(
    (promise: Promise<NewHealthMarker[]>) => {
      promise
        .then((markers: NewHealthMarker[]) => {
          const newMarkers = markers.filter(
            (item: NewHealthMarker) =>
              item.categories != undefined && item.categories.length > 0 && item.category != null
          );
          setLatestMarkers(newMarkers);
        })
        .catch((err: ApiError) => {
          if (!err.isAborted) {
            setError(err.error);
          }
          addAnalyticsLog({ function: 'loadLatestMarkers', data: err, logType: 'error' });
        })
        .finally(() => {
          setIsLastestMarkersLoading(false);
          setRefreshing(false);
        });
    },
    [addAnalyticsLog, setRefreshing, setLatestMarkers]
  );

  useEffect(() => {
    if (isUserLogged) {
      const { promise, abort } = MarkerService.getLatestMarkers();
      loadLatestMarkers(promise);
      return abort;
    }
  }, [isUserLogged, loadLatestMarkers]);

  useEffect(() => {
    if (refreshing) {
      const { promise, abort } = MarkerService.getLatestMarkers();
      loadLatestMarkers(promise);
      return abort;
    }
  }, [loadLatestMarkers, refreshing]);
  return [latestMarkers, isLatestMarkersLoading, error];
};

export const useAddFavoriteMarkers = () => {
  const { addAnalyticsLog } = useAnalytics('useMyMarkers.ts');
  const [isAddingFavoriteMarkers, setIsAddingFavoriteMarkers] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  const addFavoriteMarkers = async (extenalKeys: string[]) => {
    setIsAddingFavoriteMarkers(true);
    await MarkerService.addFavoriteMarkers(extenalKeys)
      .catch((err: ApiError) => {
        setError(err.error);
        addAnalyticsLog({ function: 'addFavoriteMarkers', data: err, logType: 'error' });
      })
      .finally(() => {
        setIsAddingFavoriteMarkers(false);
      });
  };

  return [addFavoriteMarkers, isAddingFavoriteMarkers, error];
};

export const useRemoveFvoriteMarker = () => {
  const { addAnalyticsLog } = useAnalytics('useMyMarkers.ts');
  const [isRemovingMarker, setIsRemovingMarker] = useState<boolean>(false);

  const [error, setError] = useState<any>();
  const removeFavoriteMarker = async (externalKeys: string[]) => {
    setIsRemovingMarker(true);
    await MarkerService.removeFavoriteMarker(externalKeys)
      .catch((err: ApiError) => {
        setError(err.error);
        addAnalyticsLog({ function: 'removeFavoriteMarker', data: err, logType: 'error' });
      })
      .finally(() => {
        setIsRemovingMarker(false);
      });
  };

  return [removeFavoriteMarker, isRemovingMarker, error];
};
