import Svg, { G, Path, Rect } from 'react-native-svg';
import React from 'react';
import { SimpleIconProps } from 'src/core';

const WarningIcon = ({ size = 20, color = '#DB8B00' }: SimpleIconProps) => (
  <Svg width={`${size}px`} height={`${size}px`} viewBox="0 0 20 20">
    <G data-name="Layer 2">
      <G data-name="warning">
        <Rect width={`${size}px`} height={`${size}px`} transform="rotate(-90 12 12)" opacity="0" />
        <Path
          fill={color}
          d="M9.16667 7.50008C9.16667 7.04008 9.54 6.66675 10 6.66675C10.46 6.66675 10.8333 7.04008 10.8333 7.50008V10.8334C10.8333 11.2934 10.46 11.6667 10 11.6667C9.54 11.6667 9.16667 11.2934 9.16667 10.8334V7.50008ZM9.16667 13.3334C9.16667 12.8734 9.54 12.5001 10 12.5001C10.46 12.5001 10.8333 12.8734 10.8333 13.3334C10.8333 13.7934 10.46 14.1667 10 14.1667C9.54 14.1667 9.16667 13.7934 9.16667 13.3334ZM17.3873 15.2942C17.1964 15.6317 16.8247 15.8334 16.3931 15.8334H3.60642C3.17475 15.8334 2.80308 15.6317 2.61308 15.2942C2.52308 15.1359 2.40475 14.8134 2.62725 14.4467L9.01975 3.84841C9.40892 3.20341 10.5906 3.20341 10.9797 3.84841L17.3731 14.4467C17.5947 14.8134 17.4773 15.1359 17.3873 15.2942ZM18.8006 13.5859L12.4072 2.98675C11.9081 2.16008 11.0081 1.66675 9.99975 1.66675C8.99142 1.66675 8.09142 2.16008 7.59308 2.98675L1.19975 13.5859C0.725582 14.3717 0.711416 15.3167 1.16142 16.1134C1.64392 16.9692 2.58142 17.5001 3.60642 17.5001H16.3931C17.4189 17.5001 18.3556 16.9692 18.8389 16.1134C19.2889 15.3167 19.2747 14.3717 18.8006 13.5859Z"
        />
      </G>
    </G>
  </Svg>
);

export default WarningIcon;
