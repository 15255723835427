import { BackendApi } from 'src/api/shared';
import { SleepStatService } from '../model';

export const WebSleepStatService: SleepStatService = {
  getSleepStat: (sleepId: string) => {
    const abortController = new AbortController();
    return {
      promise: BackendApi.get(`/terra/sleep-samples/${sleepId}`, {
        signal: abortController.signal
      })
        .then((response) => {
          return response.data;
        })
        .catch((error) => Promise.reject({ error: error, isAborted: abortController.signal.aborted })),
      abort: () => {
        abortController.abort();
      }
    };
  },
  getSleepActionTips: () => {
    const abortController = new AbortController();
    return {
      promise: BackendApi.get('/action-item/recommended/sleep', { signal: abortController.signal })
        .then((response) => {
          return response.data;
        })
        .catch((error) => Promise.reject({ error: error, isAborted: abortController.signal.aborted })),
      abort: () => {
        abortController.abort();
      }
    };
  }
};
