import { Icon, useTheme } from '@ui-kitten/components';
import { Image, StyleSheet, View } from 'react-native';
import React from 'react';
import { useAppStore } from 'src/core/store';
import { useGetProfilePhotoEffect } from 'src/core/hooks/useUser';

const styleContainer = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  imageContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    overflow: 'hidden',
    width: 32,
    height: 32,
    borderRadius: 16
  },
  image: {
    width: 32,
    height: 32,
    borderRadius: 16,
    overflow: 'hidden'
  }
});

const Avatar = () => {
  const profilePhoto = useAppStore((state) => state.profilePhoto);
  const th = useTheme();
  useGetProfilePhotoEffect();
  return (
    <View style={styleContainer.container}>
      <View style={[styleContainer.imageContainer, { borderColor: th['color-basic-500'] }]}>
        {profilePhoto?.length > 0 ? (
          <Image source={{ uri: profilePhoto }} style={[styleContainer.image, { top: 0 }]} />
        ) : (
          <View style={styleContainer.image}>
            <Icon fill={th['color-basic-500']} name="person" />
          </View>
        )}
      </View>
    </View>
  );
};

export default Avatar;
