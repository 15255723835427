import { StyleSheet, TouchableOpacity, View, ViewProps, ViewStyle } from 'react-native';

import { PillarContainer } from '../home';
import React from 'react';
import RoundButton from './RoundButton';
import { useTheme } from '@ui-kitten/components';

//props
interface IMarkerListItemProps extends ViewProps {
  btnClickHandler: (type: string) => void;
  type: string;
  name: string;
  isSelected: boolean;
}

const MarkerListItem = (props: IMarkerListItemProps) => {
  const th = useTheme();
  // styles
  const styleContainer = StyleSheet.create({
    selectedContainer: {
      backgroundColor:
        props.type == 'all' ? th['color-primary-transparent-400'] : th[`color-${props.type}-200-transparent`],
      borderRadius: 25,
      alignItems: 'center',
      padding: 4,
      flexWrap: 'wrap',
      flexDirection: 'column'
    },
    selectedPillar: {
      backgroundColor: th[`color-${props.type}-500`]
    }
  });

  return (
    <TouchableOpacity
      onPress={() => {
        props.btnClickHandler(props.type);
      }}>
      <View style={[props.isSelected == true ? styleContainer.selectedContainer : undefined, props.style]}>
        {props.type == 'all' ? (
          <RoundButton name={props.name} />
        ) : (
          <PillarContainer
            type={props.type}
            style={props.isSelected ? (styleContainer.selectedPillar as ViewStyle) : undefined}
            name={props.name}
            size="large"
          />
        )}
      </View>
    </TouchableOpacity>
  );
};

export default MarkerListItem;
