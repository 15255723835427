import Svg, { G, Path } from 'react-native-svg';
import React from 'react';
import { SimpleIconProps } from 'src/core';

const CloseSearchIcon = ({ size = 20, color = '#8F9BB3' }: SimpleIconProps) => (
  <Svg width={`${size}px`} height={`${size}px`} viewBox={'0 0 20 20'}>
    <G data-name="Layer 2">
      <G data-name="close-search-icon">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.2557 7.74425C11.9298 7.41841 11.4032 7.41841 11.0773 7.74425L9.99984 8.82175L8.92234 7.74425C8.5965 7.41841 8.06984 7.41841 7.744 7.74425C7.41817 8.07008 7.41817 8.59675 7.744 8.92258L8.8215 10.0001L7.744 11.0776C7.41817 11.4034 7.41817 11.9301 7.744 12.2559C7.9065 12.4184 8.11984 12.5001 8.33317 12.5001C8.5465 12.5001 8.75984 12.4184 8.92234 12.2559L9.99984 11.1784L11.0773 12.2559C11.2398 12.4184 11.4532 12.5001 11.6665 12.5001C11.8798 12.5001 12.0932 12.4184 12.2557 12.2559C12.5815 11.9301 12.5815 11.4034 12.2557 11.0776L11.1782 10.0001L12.2557 8.92258C12.5815 8.59675 12.5815 8.07008 12.2557 7.74425ZM9.99984 16.6667C6.324 16.6667 3.33317 13.6759 3.33317 10.0001C3.33317 6.32425 6.324 3.33341 9.99984 3.33341C13.6757 3.33341 16.6665 6.32425 16.6665 10.0001C16.6665 13.6759 13.6757 16.6667 9.99984 16.6667ZM9.99984 1.66675C5.40484 1.66675 1.6665 5.40508 1.6665 10.0001C1.6665 14.5951 5.40484 18.3334 9.99984 18.3334C14.5948 18.3334 18.3332 14.5951 18.3332 10.0001C18.3332 5.40508 14.5948 1.66675 9.99984 1.66675Z"
          fill={color}
        />
      </G>
    </G>
  </Svg>
);

export default CloseSearchIcon;
