import React, { useState } from 'react';
import { ViewProps, ScrollView } from 'react-native';
import { ErrorModal } from 'src/components/shared/ErrorModal';
import { PillarScore } from 'src/core';
import RefreshControl from 'src/components/shared/RefreshControl';
import { mainStyles } from 'src/screens/main/_mainStyles';
import PillarDetail from './PillarDetail';
import { usePillarScoreHistoriesEffect } from 'src/core/hooks/usePillarScoreHistories';
import { usePillarActionTipsEffect } from 'src/core/hooks/usePillarActionTips';
import { usePillarScoreDriversEffect } from 'src/core/hooks/usePillarScoreDrivers';
import { useAddAction } from 'src/core/hooks/useAddAction';
import Spinner from 'react-native-loading-spinner-overlay/lib';

//props
interface PillarDetailContainerProps extends ViewProps {
  pillarSummaryData: PillarScore;
}

const PillarDetailContainer = ({ pillarSummaryData }: PillarDetailContainerProps) => {
  const [scoreHistoriesRefreshing, setScoreHistoriesRefreshing] = useState<boolean>(false);
  const [actionTipsRefreshing, setActionTipsRefreshing] = useState<boolean>(false);
  const [scoreDriversRefreshing, setScoreDriversRefreshing] = useState<boolean>(false);
  const [scoreHistories, isScoreHistoriesLoading, scoreHistoriesError] = usePillarScoreHistoriesEffect({
    id: pillarSummaryData.id,
    refreshing: scoreHistoriesRefreshing,
    setRefreshing: setScoreHistoriesRefreshing
  });

  const [actionTips, isActionTipsLoading, actionTipsError] = usePillarActionTipsEffect({
    key: pillarSummaryData.type,
    refreshing: actionTipsRefreshing,
    isInsight: pillarSummaryData.alertText?.length > 0 ? true : false,
    setRefreshing: setActionTipsRefreshing
  });

  const [scoreDrivers, isScoreDriversLoading, scoreDriversError] = usePillarScoreDriversEffect({
    categoryKey: pillarSummaryData.type,
    refreshing: scoreDriversRefreshing,
    setRefreshing: setScoreDriversRefreshing
  });

  const [addAction, isAddingAction, errorAddingAction] = useAddAction();

  const createAction = async (actionId: number) => {
    await addAction(actionId);
    setActionTipsRefreshing(true);
  };

  return (
    <ScrollView
      contentContainerStyle={[{ flexGrow: 1 }, mainStyles.mainScreenContainer]}
      alwaysBounceVertical={true}
      refreshControl={
        <RefreshControl
          refreshing={scoreHistoriesRefreshing}
          onRefresh={() => {
            setScoreHistoriesRefreshing(true);
          }}
        />
      }>
      <PillarDetail
        pillarScore={pillarSummaryData as PillarScore}
        scoreHistories={scoreHistories}
        scoreDrivers={scoreDrivers}
        actionTips={actionTips}
        isLoading={isScoreHistoriesLoading || isActionTipsLoading || isScoreDriversLoading}
        onCreateAction={(actionId) => {
          createAction(actionId);
        }}
      />
      <Spinner visible={isAddingAction} />
      <ErrorModal
        visible={
          scoreHistoriesError != undefined ||
          actionTipsError != undefined ||
          scoreDriversError != undefined ||
          errorAddingAction != undefined
        }
        message={{ ...scoreHistoriesError, ...actionTipsError, ...scoreDriversError, ...errorAddingAction }}
      />
    </ScrollView>
  );
};

export default PillarDetailContainer;
