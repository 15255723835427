import { Platform, SafeAreaView, StyleSheet } from 'react-native';
import { PreSignUp, PreSignUpAlt, TextAuthLink } from '../../components/auth';
import { RootStackScreenProps, UIHelper as uh } from '../../core';

import { KeyboardAvoidingView } from '../../components/shared';
import { Layout } from '@ui-kitten/components';
import React from 'react';
import { authStyles } from './_authStyles';
import { setValue } from 'src/api/storage';
import { useSetFirstRun } from 'src/core/hooks/useGlobalVar';

const PreRegisterScreen = ({ navigation }: RootStackScreenProps<'PreRegister'>) => {
  const setFirstLoad = useSetFirstRun();

  // styles
  const styleContainer = StyleSheet.create({
    screenContainer: { flex: 1 },
    safeAreaView: { flex: 1 }
  });

  // handlers and conditionals
  const goToRegisterHandler = () => {
    setValue('isFirstLoad', 'true')
      .then(() => {
        setFirstLoad(false);
      })
      .finally(() => {
        navigation.navigate('Login');
      });
  };

  // view
  return (
    <KeyboardAvoidingView>
      <Layout level="2" style={[styleContainer.screenContainer, authStyles.authScreenContainer]}>
        <SafeAreaView style={styleContainer.screenContainer}>
          {Platform.OS === 'web' ? (
            <PreSignUpAlt finalActionHandler={goToRegisterHandler} />
          ) : (
            <PreSignUp finalActionHandler={goToRegisterHandler} />
          )}
          <TextAuthLink
            message="Skip"
            clickHandler={goToRegisterHandler}
            style={{ marginTop: uh.h2DP(28) }}
            accessible={true}
            accessibilityLabel={'skip_btn'}
            testID={'skip_btn'}
          />
        </SafeAreaView>
      </Layout>
    </KeyboardAvoidingView>
  );
};

export default PreRegisterScreen;
