import { Button, Card, Icon, Modal, useTheme, Text } from '@ui-kitten/components';
import React from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';
import { UIHelper as uh } from '../../../core';

interface ITenantModalProps extends ViewProps {
  visible: boolean;
  title: string;
  message: string;
  icon: string;
  noBtnClick?: () => void;
  primaryButtonText: string;
  onPrimaryButton: () => void;
  visibleSecondaryButton: boolean;
  secondaryButtonText?: string;
  onSecondaryButton?: () => void;
  testingAttributes?: {
    accessible: boolean;
    accessibilityLabel: string;
    testID: string;
  };
}

export const TenantModal = (props: ITenantModalProps) => {
  const th = useTheme();

  const styles = StyleSheet.create({
    backdrop: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    container: {
      flex: 1,
      justifyContent: 'space-between',
      alignSelf: 'center',
      width: '90%'
    },
    card: {
      maxWidth: uh.currentViewPort() - 8
    },
    iconContainer: {
      height: 100,
      width: 100,
      alignSelf: 'center',
      marginVertical: 15
    },
    buttonArea: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      marginTop: 20,
      marginBottom: 30
    },
    buttonStyle: {
      width: '100%',
      marginVertical: 5,
      marginHorizontal: 10
    },
    message: {
      textAlign: 'center',
      marginVertical: 4
    }
  });

  return (
    <Modal
      visible={props.visible}
      backdropStyle={styles.backdrop}
      onBackdropPress={() => props.noBtnClick && props.noBtnClick()}
      accessible={props.testingAttributes?.accessible || undefined}
      accessibilityLabel={props.testingAttributes?.accessibilityLabel || undefined}
      testID={props.testingAttributes?.testID || undefined}>
      <Card style={styles.card}>
        <View style={styles.container}>
          <View style={styles.iconContainer}>
            <Icon status="primary" fill={th['color-primary-500']} name={props.icon} />
          </View>
          <View>
            <Text category="h5" style={styles.message}>
              {props.title}
            </Text>
            <Text category="p1" appearance="hint" style={styles.message}>
              {props.message}
            </Text>
          </View>
          <View style={styles.buttonArea}>
            <Button style={styles.buttonStyle} size="large" onPress={() => props.onPrimaryButton()}>
              <Text>{props.primaryButtonText}</Text>
            </Button>
            {props.visibleSecondaryButton && (
              <Button
                style={styles.buttonStyle}
                appearance="ghost"
                size="large"
                onPress={() => props.onSecondaryButton && props.onSecondaryButton()}>
                <Text>{props.secondaryButtonText}</Text>
              </Button>
            )}
          </View>
        </View>
      </Card>
    </Modal>
  );
};
