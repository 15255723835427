import { useCallback, useEffect } from 'react';

import SurveysService from 'src/api/surveys';
import { useAnalytics } from './useAnalytics';
import { useAppStore } from '../store';
import { useIsUserLogged } from './useUser';

export const useGetPillarCategories = () => {
  const pillarCategories = useAppStore((state) => state.pillarCategories);
  return pillarCategories;
};

export const usePillarCategories = () => {
  const setPillarCategories = useAppStore((state) => state.setPillarCategories);
  const { addAnalyticsLog } = useAnalytics('usePillarCategories.ts');

  const isUserLogged = useIsUserLogged();

  const loadPillarCategories = useCallback(() => {
    SurveysService.getPillarCategories()
      .then((response) => {
        setPillarCategories(response);
      })
      .catch((error) => {
        addAnalyticsLog({ logType: 'error', function: 'loadPillarCategories', data: JSON.stringify(error) });
      });
  }, [addAnalyticsLog, setPillarCategories]);

  useEffect(() => {
    if (isUserLogged) {
      loadPillarCategories();
    }
  }, [isUserLogged, loadPillarCategories]);
};
