import React from 'react';
import { SafeAreaView, StyleSheet } from 'react-native';
import { Layout } from '@ui-kitten/components';
import SleepStatDetailContainer from 'src/features/sleepStat/SleepStatDetailContainer';
import { RootStackScreenProps } from 'src/core/types/RootNavigationParamList';

//styles
const styleContainer = StyleSheet.create({
  screenContainer: { flex: 1 },
  safeAreaView: { flex: 1 }
});

const SleepStatScreen = ({ route }: RootStackScreenProps<'SleepStat'>) => {
  //view
  return (
    <Layout level="2" style={[styleContainer.screenContainer]}>
      <SafeAreaView style={styleContainer.screenContainer}>
        <SleepStatDetailContainer sleepId={(route as any)?.params?.sleepId} />
      </SafeAreaView>
    </Layout>
  );
};

export default SleepStatScreen;
