import { NotificationMessage } from 'src/core/types/Notification';
import { StateCreator } from 'zustand';

export interface NotificationMessageSlice {
  notitficationMessages: NotificationMessage[];
  setNotitficationMessages: (notitficationMessages: NotificationMessage[]) => void;
}

export const createNotificationMessageSlice: StateCreator<
  NotificationMessageSlice,
  [],
  [],
  NotificationMessageSlice
> = (set) => ({
  notitficationMessages: [],
  setNotitficationMessages: (notitficationMessages: NotificationMessage[]) => set(() => ({ notitficationMessages }))
});
