import { TenantFeature, TenantTuple } from 'src/core/types/AuthTypes';

import Brands from 'brands.json';
import { DataSharingTenant } from 'src/core/types/DataSharingTenant';
import { StateCreator } from 'zustand';
import { StateWithoutData } from 'src/core/types/SimpleState';
import { SubscriptionInfo } from 'src/core/types/SubscriptionInfo';
import { UserAttributes } from 'src/core/types/UserAttributes';
import { ValidateError } from 'src/core/types/AuthError';

export interface UserSlice {
  internalUserId?: string;
  externalUserId?: string;
  token?: string;
  email?: string;
  isLogged: boolean;
  isAuthLoading: boolean;
  isAutoLogin: boolean;
  authError: ValidateError;
  isTelemetryEnabled: boolean;
  userAttributes: UserAttributes;
  tenantKey: string;
  tenants: TenantTuple[];
  tenantFeatures: TenantFeature[];
  userSubscriptionPlan: string;
  registerEmail: string;
  dataSharingList: DataSharingTenant[];
  profilePhoto: string;
  initExtraUserAttributes: StateWithoutData;
  userSubscription: SubscriptionInfo;
  setUser: (id: string, externalId: string, token: string, email: string) => void;
  clearUser: () => void;
  changeUserAttributes: (attributes: Partial<UserAttributes>) => void;
  setTenant: (tenantKey: string, tenants: TenantTuple[], tenantFeatures: TenantFeature[]) => void;
  setAuthError: (error: ValidateError) => void;
  setAuthLoading: (loading: boolean) => void;
  setUserLogged: (isLogged: boolean) => void;
  setAutoLogin: (isAutoLogin: boolean) => void;
  setUserSubscriptionPlan: (subscription: string) => void;
  setRegisterEmail: (registerEmail: string) => void;
  setTenantKey: (tenantKey: string) => void;
  setTenantFeatures: (tenantFeature: TenantFeature[]) => void;
  setDataSharingList: (dataSharingList: DataSharingTenant[]) => void;
  setProfilePhoto: (profilePhoto: string) => void;
  setExtraUserAttributesLoading: (loading: boolean) => void;
  setExtraUserAttributesError: (hasError: boolean) => void;
}

export const createUserSlice: StateCreator<UserSlice, [], [], UserSlice> = (set) => ({
  internalUserId: undefined,
  externalUserId: undefined,
  token: undefined,
  email: undefined,
  isLogged: false,
  isAuthLoading: false,
  authError: { name: '', message: '' },
  isTelemetryEnabled: false,
  isAutoLogin: false,
  tenants: [],
  tenantFeatures: [],
  tenantKey: '',
  userAttributes: {
    name: undefined,
    nickname: undefined,
    email: undefined,
    gender: undefined,
    birthdate: undefined,
    picture: undefined,
    sub: undefined,
    height: undefined,
    weight: undefined,
    smoker: undefined,
    bloodpressureMedication: undefined,
    hypertension: undefined,
    diabetic: undefined,
    activityLevel: undefined
  },
  userSubscriptionPlan: Brands.default_subscription,
  registerEmail: '',
  dataSharingList: [],
  profilePhoto: '',
  initExtraUserAttributes: {
    isLoading: true,
    hasError: false
  },
  userSubscription: {
    platform: '',
    notificationType: '',
    data: undefined
  },
  setUser: (userId: string, externalUserId: string, token: string, email: string) =>
    set(() => ({ internalUserId: userId, externalUserId, token, email })),
  clearUser: () => set(() => ({ id: undefined, token: undefined, email: undefined, isLogged: false })),
  changeUserAttributes: (attributes: Partial<UserAttributes>) =>
    set((state) => ({ userAttributes: { ...state.userAttributes, ...attributes } })),
  setTenant: (tenantKey: string, tenants: TenantTuple[], tenantFeatures: TenantFeature[]) =>
    set(() => ({ tenantKey, tenants, tenantFeatures })),
  setTenantKey: (tenantKey: string) => set(() => ({ tenantKey })),
  setTenantFeatures: (tenantFeatures: TenantFeature[]) => set(() => ({ tenantFeatures })),
  setAuthError: (error: ValidateError) => set(() => ({ authError: error })),
  setAuthLoading: (loading: boolean) => set(() => ({ isAuthLoading: loading })),
  setUserLogged: (isLogged: boolean) => set(() => ({ isLogged })),
  setAutoLogin: (isAutoLogin: boolean) => set(() => ({ isAutoLogin })),
  setUserSubscriptionPlan: (userSubscriptionPlan: string) => set(() => ({ userSubscriptionPlan })),
  setRegisterEmail: (registerEmail: string) => set(() => ({ registerEmail })),
  setDataSharingList: (dataSharingList: DataSharingTenant[]) => set(() => ({ dataSharingList })),
  setProfilePhoto: (profilePhoto: string) => set(() => ({ profilePhoto })),
  setExtraUserAttributesLoading: (loading: boolean) =>
    set((state) => ({ initExtraUserAttributes: { ...state.initExtraUserAttributes, isLoading: loading } })),
  setExtraUserAttributesError: (hasError: boolean) =>
    set((state) => ({ initExtraUserAttributes: { ...state.initExtraUserAttributes, hasError: hasError } }))
});
