import React, { useState } from 'react';
import { ScrollView, StyleSheet, View, ViewProps } from 'react-native';

import { PILLARS } from 'src/core/constants/Pillars';
import PillarButtonListItem from '../../features/markers/components/PillarButtonListItem';
import { useGetPillarCategories } from 'src/core/hooks/usePillarCategories';

//props
interface PillarButtonListProps extends ViewProps {
  defaultName?: string;
  style?: any;
  onBtnClickHandler: (name: string) => void;
  shownPillars?: string[];
}

// styles
const styleContainer = StyleSheet.create({
  container: { flexDirection: 'row', alignItems: 'center' }
});

const PillarButtonList = ({ defaultName = 'All', style, onBtnClickHandler, shownPillars }: PillarButtonListProps) => {
  const DEFAULT_PILLAR = { type: 'all', name: defaultName, checked: false, disabled: false };

  const activePillars = useGetPillarCategories().map((pillar) => pillar.name);
  const [selectedMarker, setSelectedMarker] = useState('all');

  const selectMarker = (name: string) => {
    setSelectedMarker(name);
    onBtnClickHandler(name);
  };

  const renderItems = () => {
    return [DEFAULT_PILLAR, ...PILLARS].map((item: any, index) => {
      if ((shownPillars?.includes(item.name) && activePillars.includes(item.name)) || item.type === 'all') {
        return (
          <PillarButtonListItem
            key={`item-index-${index}`}
            name={item.name}
            type={item.type}
            isSelected={item.type === selectedMarker}
            onBtnClickHandler={() => {
              selectMarker(item.type);
            }}
          />
        );
      }
    });
  };

  // view
  return (
    <View style={[styleContainer.container, style]}>
      <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
        <View style={styleContainer.container}>{renderItems()}</View>
      </ScrollView>
    </View>
  );
};

export default PillarButtonList;
