export const InputValidatorMessages = {
  emailEmpty: 'Email cannot be empty.',
  emailNotValid: 'Please enter a valid email address.',
  passwordEmpty: 'Password cannot be empty.',
  passwordLength: 'Password must be a minimum of 8 characters long.',
  passwordValidity: 'Password requires at least 1 lowercase character, 1 uppercase character and 1 digit.',
  stringEmpty: ' cannot be empty.',
  genderEmpty: 'Please select a sex.',
  dobEmpty: 'Birth Date cannot be empty.',
  dobValidity: 'Not a valid Birth Date.',
  dobUnderAge: 'Sorry about that. You must be 18 years or older.',
  authCodeEmpty: 'Code cannot be empty.',
  nameIsEmpty: 'Name cannot be empty.',
  heightIsEmpty: 'Height cannot be empty.',
  invalidHeight: 'Height is invalid. The value must be between 50cm (1.64ft) and 255cm (8.36ft).',
  weightIsEmpty: 'Weight cannot be empty.',
  invalidWeight: 'Weight is invalid. The value must be between 25kg (55.1 lbs) and 300kg (661.3 lbs).',
  ageIsEmpty: 'Age cannot be empty.',
  invalidAge: 'Age is invalid. The value must be between 13 and 120.'
};

export const AuthApiErrorMessages = {
  tenantListError: "Can't get tenants",
  tenantFeaturesError: "Can't get tenant's features"
};
