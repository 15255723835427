import { Text, useTheme } from '@ui-kitten/components';
import React, { useState } from 'react';
import { ViewProps, View, StyleSheet } from 'react-native';
import { FrequencyType } from 'src/core/types/SleepStat';
import { UIHelper as uh } from '../../../core';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';

//props
interface FrequencyProps extends ViewProps {
  onChangeFrequency: (type: FrequencyType) => void;
}

const styleContainer = StyleSheet.create({
  container: {
    flexDirection: 'row',
    columnGap: uh.w2DP(8),
    alignItems: 'center'
  }
});

const Frequency = ({ onChangeFrequency }: FrequencyProps) => {
  const th = useTheme();
  const transMessage = {
    view: 'View',
    day: 'Day',
    week: 'Week',
    month: 'Month'
  };
  const t = useTranslationFunc(transMessage);
  const [sleletedType, setSelelectedType] = useState<FrequencyType>(FrequencyType.Week);
  const selectFrequencyType = (type: FrequencyType) => {
    setSelelectedType(type);
    onChangeFrequency(type);
  };
  // view
  return (
    <View style={styleContainer.container}>
      <Text category="c1" style={{ color: th['text-hint-color'] }}>
        {t('view')}:
      </Text>
      <Text
        onPress={() => {
          selectFrequencyType(FrequencyType.Day);
        }}
        style={{ color: sleletedType === FrequencyType.Day ? th['text-basic-color'] : th['text-hint-color'] }}
        category={sleletedType === FrequencyType.Day ? 'c2' : 'c1'}>
        {t('day')}
      </Text>
      <Text category="c1">|</Text>
      <Text
        onPress={() => {
          selectFrequencyType(FrequencyType.Week);
        }}
        style={{ color: sleletedType === FrequencyType.Week ? th['text-basic-color'] : th['text-hint-color'] }}
        category={sleletedType === FrequencyType.Week ? 'c2' : 'c1'}>
        {t('week')}
      </Text>
      <Text category="c1">|</Text>
      <Text
        onPress={() => {
          selectFrequencyType(FrequencyType.Month);
        }}
        style={{ color: sleletedType === FrequencyType.Month ? th['text-basic-color'] : th['text-hint-color'] }}
        category={sleletedType === FrequencyType.Month ? 'c2' : 'c1'}>
        {t('month')}
      </Text>
    </View>
  );
};

export default Frequency;
