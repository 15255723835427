import { IndexPath, Select, SelectItem } from '@ui-kitten/components';

import React from 'react';

const ACTIVITY_LEVELS = [
  'Sedentary (very little activity)',
  'Some walking',
  'Daily walks',
  'Walking plus training 1-2/week',
  'Walking plus training 3-5/week',
  'Athlete level training'
];

interface ActivityLevelSelectProps {
  selectedActivityLevel: IndexPath;
  setSelectedActivityLevel: (level: IndexPath) => void;
}
const ActivityLevelSelect = ({ selectedActivityLevel, setSelectedActivityLevel }: ActivityLevelSelectProps) => {
  return (
    <Select
      status="basic"
      size={'large'}
      value={ACTIVITY_LEVELS[selectedActivityLevel.row]}
      selectedIndex={selectedActivityLevel}
      multiSelect={false}
      onSelect={(index: IndexPath | IndexPath[]) => setSelectedActivityLevel(Array.isArray(index) ? index[2] : index)}
      accessible={true}
      accessibilityLabel={'Activity Level Select'}
      testID={'activity_level'}>
      {ACTIVITY_LEVELS.map((activity: string, index: number) => (
        <SelectItem
          style={{ flexWrap: 'wrap' }}
          title={activity}
          key={index}
          accessible={true}
          accessibilityLabel={activity}
          testID={activity}
        />
      ))}
    </Select>
  );
};

export default ActivityLevelSelect;
