import { Level, UserSettings } from 'src/core';

import { ErrorModal } from 'src/components/shared/ErrorModal';
import { NotiSegmentItem } from 'src/core/types/Notification';
import React from 'react';
import SegmentMenu from './components/SegmentMenu';
import Spinner from 'react-native-loading-spinner-overlay/lib';
import { useAppStore } from 'src/core/store';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';
import { useUpdateUserSettings } from 'src/core/hooks/useUserSettings';

const LevelMenuContainer = () => {
  const level = useAppStore((state) => state.level);
  const setLevel = useAppStore((state) => state.setLevel);
  const [updateUserSettings, isUpdatingUserSettings, error] = useUpdateUserSettings();
  const transMessage = {
    name: 'Level (coming soon)',
    description:
      'How strict do you want the scores and grades in the app? Select based on your personal goals and comfort.',
    levelLenient: 'Lenient',
    levelStrict: 'Strict'
  };
  const t = useTranslationFunc(transMessage);
  const segments: NotiSegmentItem[] = [
    {
      name: t('levelLenient'),
      value: Level.Lenient,
      disabled: true
    },
    {
      name: t('levelStrict'),
      value: Level.Strict,
      disabled: true
    }
  ];

  //view
  return (
    <>
      <SegmentMenu
        name={t('name')}
        description={t('description')}
        segments={segments}
        onSelect={(value) => {
          setLevel(value as Level);
          updateUserSettings({ level: value } as UserSettings);
        }}
        selectedValue={level}
      />
      <ErrorModal visible={error != undefined} message={error} />

      <Spinner visible={isUpdatingUserSettings} />
    </>
  );
};

export default LevelMenuContainer;
