import React from 'react';
import { ITenantSharedProps } from '../TenantSharedProps';
import { TenantModal } from './TenantModal';

export const OutdatedTenant = (props: ITenantSharedProps) => {
  const title = 'You have already accepted the request';

  const message = `You have already accepted ${props.proUserName}'s connection request. You can change your permission settings anytime.`;

  return (
    <TenantModal
      visible={props.visible}
      title={title}
      message={message}
      icon={'alert-triangle-outline'}
      primaryButtonText={'Close'}
      onPrimaryButton={props.onFinalButtonClick}
      visibleSecondaryButton={true}
      secondaryButtonText={'Change Permission Settings'}
      onSecondaryButton={props.onSecondaryButton}
      testingAttributes={props.testingAttributes}
    />
  );
};
