import * as moment from 'moment-timezone';

import { Alert, Platform } from 'react-native';
import { Config, RequestHelper } from '../../core';
import { getTokenRaw, signOutUser } from 'src/core/helpers/AuthHelper';

import { Buffer } from 'buffer';
import { addRawAnalyticsLog } from '../../core/helpers/AddRawAnalyticsLog';
import axios from 'axios';
import { backendApi } from 'src/core/brands';
import { getValue } from '../storage';

// this is a temporary authorization header to allow access to the backend api on the stage
// without additional authentication
// it does not have to be particularly secure
const username = 'maxb';
const password = 'nM3sBPb553bFJMcDISmz';
const hash = Buffer.from(`${username}:${password}`).toString('base64');

// this is a temporary authorization header to allow access to the backend api on the stage
// without additional authentication
// it does not have to be particularly secure
// const username = 'maxb'
// const password = 'nM3sBPb553bFJMcDISmz'
// const hash = new Buffer(`${username}:${password}`).toString('base64')

// Create axios client, pre-configured with baseURL
const BackendApi = axios.create({
  baseURL: Config.BACKEND_API_ENDPOINT,
  timeout: 100000,
  headers: {
    'Content-Type': 'application/json',
    'The-Timezone-IANA': moment.tz.guess(),
    'X-Extra-Authorization': `Basic ${hash}`
  }
});
// Set JSON Web Token in Client to be included in all calls
BackendApi.interceptors.request.use(async (config: any) => {
  try {
    config.headers.Tenant = await getValue('tenantKey');
    config.telemetry = { startTime: new Date(), logged: false };
    const token = await getTokenRaw();
    config.headers.Authorization = `Bearer ${token}`;
  } catch (error) {
    console.error(`interceptors: ${JSON.stringify(error)}`);
  }
  return config;
});

// Add a response interceptor
BackendApi.interceptors.response.use(
  function (response) {
    (response.config as any).telemetry.endTime = new Date();
    // Do something with response data

    if ((response.config as any).telemetry.startTime != undefined) {
      const api = response != undefined ? RequestHelper.getUrl(response) : '';
      const kaliTrack = response != undefined ? RequestHelper.getTrackerId(response) : '';
      const responseTime =
        (response.config as any).telemetry.endTime.getTime() - (response.config as any).telemetry.startTime.getTime();
      if ((response.config as any).telemetry.logged === false && responseTime >= 5000) {
        addRawAnalyticsLog('Telemetry', {
          api: api,
          kaliTrack: kaliTrack,
          responseTime: responseTime
        });
      }
      (response.config as any).telemetry.logged = true;
    }
    return response;
  },
  function (error) {
    // log ApiError event
    addRawAnalyticsLog('ApiError', {
      errorCode: error.code,
      errorMessage: error.message,
      api: RequestHelper.getUrl(error.response),
      kaliTrack: RequestHelper.getTrackerId(error.response)
    });

    if (error.response?.status === 401) {
      if (Platform.OS === 'web') {
        alert(backendApi.webAuthErrorMessage);
        signOutUser();
      } else {
        Alert.alert(backendApi.authErrorTitle, backendApi.authErrorMessage, [
          {
            text: 'OK',
            onPress: () => {
              signOutUser();
            }
          }
        ]);
      }
    }
    // Do something with response error
    return Promise.reject(error);
  }
);

export default BackendApi;
