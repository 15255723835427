import { SafeAreaView, StyleSheet, View, Text } from 'react-native';
import React from 'react';
import AlertIcon from 'src/core/icons/alert';

interface ErrorScreenProps {
  code: number;
}

const ErrorScreen = ({ code = 3 }: ErrorScreenProps) => {
  // styles
  const styleContainer = StyleSheet.create({
    screenContainer: {
      flex: 1,
      backgroundColor: '#fff',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center'
    },
    img: { color: '#F00', width: 250, height: 250 },
    desc: { marginBottom: 5, fontSize: 24, fontWeight: '700', color: '#000' },
    error: { color: '#C6C6C6', fontSize: 20, marginBottom: 16 }
  });

  // view
  return (
    <SafeAreaView style={styleContainer.screenContainer}>
      <View>
        <AlertIcon style={styleContainer.img} color="#46D7CB" />
      </View>
      <Text style={styleContainer.error}>{`Error! (Code ${code})`}</Text>
      <Text style={styleContainer.desc}>Something went wrong.</Text>
      <Text style={styleContainer.desc}>Please quit and restart the app</Text>
    </SafeAreaView>
  );
};

export default ErrorScreen;
