import * as Application from 'expo-application';

import { Layout, Text } from '@ui-kitten/components';
import { SafeAreaView, StyleSheet, View } from 'react-native';

import Logo from 'src/core/constants/Logo';
import React from 'react';
import { ScrollView } from 'react-native-gesture-handler';
import { settingStyles } from './_settingStyles';
import { useGetAppName } from 'src/core/hooks/useBrands';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';

const styleContainer = StyleSheet.create({
  screenContainer: {
    flex: 1
  },
  logoContainer: {
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    marginTop: -16
  },
  logo: { height: 200, maxWidth: 200, minWidth: 150 },
  text: { marginTop: 16 },
  version: {
    marginTop: 15,
    flexDirection: 'row',
    gap: 5
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  about: {
    flex: 1
  }
});

const AboutScreen = () => {
  // Current app version taken from app.json file
  const appVersion = Application.nativeApplicationVersion;
  const buildVersion = Application.nativeBuildVersion;

  const appName = useGetAppName();

  const transMessage = {
    kalibraVersion: 'Version',
    appVersion: '{{version}} build {{build}}',
    poweredBy: ' (Powered by Kalibra)',
    aboutHeader: "It's your health. Be intentional.",
    aboutFooter: 'We are delighted to have you with us!',
    aboutContent:
      'The journey to optimal health has to combine motivation and consistency with knowledge and personalised guidance. We need to walk the walk in the right direction.\n\n{{app_name}} connects all of your health needs, and delivers to you personalised insights, recommendations, and actions to help create positive habits.'
  };

  const t = useTranslationFunc(transMessage);

  // view
  return (
    <Layout level="2" style={[styleContainer.screenContainer, settingStyles.settingScreenContainer]}>
      <SafeAreaView style={styleContainer.screenContainer}>
        <View style={styleContainer.content}>
          <View style={styleContainer.logoContainer}>
            <Logo style={styleContainer.logo} resizeMode="contain" />
          </View>
          <ScrollView style={styleContainer.about}>
            <Text category="h5">{t('aboutHeader')}</Text>

            <Text category="p2" style={styleContainer.text}>
              {t('aboutContent', { app_name: appName })}
            </Text>

            <Text category="s1" style={styleContainer.text}>
              {t('aboutFooter')}
            </Text>
          </ScrollView>
          <View style={styleContainer.version}>
            <Text category="p2">{t('kalibraVersion')}</Text>
            <Text category="s1" appearance="hint" status="primary">
              {t('appVersion', { version: appVersion, build: buildVersion })}
            </Text>
            <Text category="p2" appearance="hint" status="basic">
              {t('poweredBy')}
            </Text>
          </View>
        </View>
      </SafeAreaView>
    </Layout>
  );
};

export default AboutScreen;
