import { Button, Card, Input, Layout, Text } from '@ui-kitten/components';
import { Keyboard, StyleSheet } from 'react-native';
import React, { useState } from 'react';
import { RootStackScreenProps, ValidateError, NumberHelper as nh, UIHelper as uh } from 'src/core';
import { heightValidator, weightValidator } from 'src/core/validators/InputValidator';
import { useChangeAttributes, useGetUserAttributes } from 'src/core/hooks/useUser';

import { BackendApi } from 'src/api/shared';
import { ErrorMessage } from 'src/components/shared';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import Spinner from 'react-native-loading-spinner-overlay';
import { StackActions } from '@react-navigation/native';
import { useAnalytics } from 'src/core/hooks/useAnalytics';

const BodyScanInputScreen = ({ navigation }: RootStackScreenProps<'BodyScanInput'>) => {
  // App Context
  const { addAnalyticsLog } = useAnalytics('BodyScanInputScreen.tsx');

  const changeAttributes = useChangeAttributes();
  const userAttributes = useGetUserAttributes();

  const styles = StyleSheet.create({
    container: {
      flexDirection: 'column',
      flex: 1,
      flexGrow: 1,
      height: uh.height() - 80,
      padding: 16
    },
    summaryTop: {
      //marginTop: 16,
    },
    fieldLabel: {
      marginTop: 16
    },
    textInput: {
      alignSelf: 'stretch',
      marginHorizontal: 22,
      marginBottom: 11
    },
    button: {
      marginTop: 26,
      marginBottom: 8,
      alignSelf: 'stretch'
    },
    inputField: {
      marginTop: 4
    }
  });

  const [error, setError] = React.useState<ValidateError>({ name: '', message: '' });
  const [heightInput, setHeight] = useState('');
  const [weightInput, setWeight] = useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  const onContinuePress = async () => {
    Keyboard.dismiss();
    const heightError = heightValidator(heightInput);
    if (heightError) {
      setError({ name: 'height', message: heightError });
      return;
    }

    const weightError = weightValidator(weightInput);
    if (weightError) {
      setError({ name: 'weight', message: weightError });
      return;
    }

    setError({ name: '', message: '' });
    const userBodyScanInput = {
      enum_ent_sex: userAttributes.gender,
      cm_ent_height: Number(heightInput),
      kg_ent_weight: Number(weightInput),
      yr_ent_age: nh.getAge(String(userAttributes.birthdate))
    };

    const updatedValues = {
      name: userAttributes.name,
      nickname: userAttributes.nickname,
      gender: userAttributes.gender,
      birthdate: userAttributes.birthdate,
      email: userAttributes.email,
      height: Number(heightInput),
      weight: Number(weightInput),
      cognitoId: userAttributes.sub,
      smoker: userAttributes.smoker,
      bloodpressureMedication: userAttributes.bloodpressureMedication,
      hypertension: userAttributes.hypertension,
      diabetic: userAttributes.diabetic,
      activityLevel: userAttributes.activityLevel
    };

    // update user info
    try {
      setIsLoading(true);
      await BackendApi.patch('/users', updatedValues);
      changeAttributes({ ...updatedValues, sub: userAttributes.sub });
      setIsLoading(false);
    } catch (err) {
      addAnalyticsLog({ function: 'onContinuePress', data: err, logType: 'error' });
      setError({ name: '', message: "Can't update user!" });
      setIsLoading(false);
      return;
    }

    navigation.dispatch(
      StackActions.replace('BodyScanGuide', {
        bodyScanInputs: userBodyScanInput
      })
    );
  };

  return (
    <KeyboardAwareScrollView keyboardShouldPersistTaps={'handled'} bounces={false}>
      {isLoading && <Spinner visible={true} />}
      <Layout style={styles.container} level="2">
        <Card>
          <ErrorMessage message={error.message} />
          <Text style={styles.summaryTop} category="h6">
            Complete the following details
          </Text>
          <Text category="s1" style={styles.fieldLabel}>
            {'Height'}
          </Text>
          <Input
            placeholder=""
            status={error.name == 'height' ? 'danger' : 'basic'}
            style={styles.inputField}
            keyboardType="numeric"
            onChangeText={(text) => setHeight(text)}></Input>
          <Text category="s1" style={styles.fieldLabel}>
            {'Weight'}
          </Text>
          <Input
            placeholder=""
            status={error.name == 'weight' ? 'danger' : 'basic'}
            style={styles.inputField}
            keyboardType="numeric"
            onChangeText={(text) => setWeight(text)}></Input>
          <Button style={styles.button} status="primary" onPress={onContinuePress}>
            Continue
          </Button>
        </Card>
      </Layout>
    </KeyboardAwareScrollView>
  );
};

export default BodyScanInputScreen;
