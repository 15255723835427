import { Path, Svg } from 'react-native-svg';

import React from 'react';
import { SvgIconProp } from 'src/core/types/ScanIcons';

export const BodyScanIcon = ({
  color = '#46D7CB',
  backgroundColor = 'transparent',
  height = 64,
  width = 64
}: SvgIconProp) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 64 64" fill={backgroundColor}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.5009 18.3333C33.4339 18.3333 35.0009 16.7664 35.0009 14.8333C35.0009 12.9003 33.4339 11.3333 31.5009 11.3333C29.5678 11.3333 28.0009 12.9003 28.0009 14.8333C28.0009 16.7664 29.5678 18.3333 31.5009 18.3333ZM31.5009 20.6667C34.7225 20.6667 37.3342 18.055 37.3342 14.8333C37.3342 11.6117 34.7225 9 31.5009 9C28.2792 9 25.6675 11.6117 25.6675 14.8333C25.6675 18.055 28.2792 20.6667 31.5009 20.6667Z"
        fill={color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.7705 25.7001C24.8712 25.9097 25.6675 26.8719 25.6675 27.9924V52.1667C25.6675 52.811 26.1898 53.3333 26.8342 53.3333H26.9355C27.5389 53.3333 28.0427 52.8732 28.0973 52.2722L29.1675 40.5C29.1709 39.2137 30.2146 38.1727 31.5009 38.1727C32.7871 38.1727 33.8308 39.2136 33.8342 40.4999L34.9044 52.2722C34.959 52.8732 35.4629 53.3333 36.0663 53.3333H36.1675C36.8119 53.3333 37.3342 52.811 37.3342 52.1667V28.0364C37.3342 26.9158 38.1308 25.9535 39.2317 25.7441C41.2803 25.3546 43.4466 24.8139 45.8273 24.1201C46.446 23.9399 46.8012 23.2922 46.621 22.6736C46.4407 22.055 45.7931 21.6998 45.1744 21.88C39.768 23.4557 35.62 24.1849 31.5059 24.1664C27.3875 24.1479 23.2346 23.3802 17.8128 21.8759C17.1919 21.7036 16.549 22.0673 16.3767 22.6882C16.2045 23.3091 16.5681 23.952 17.189 24.1242C19.561 24.7824 21.7226 25.3103 23.7705 25.7001ZM30.3084 53.1029C29.8961 54.5931 28.5319 55.6667 26.9355 55.6667H26.8342C24.9011 55.6667 23.3342 54.0997 23.3342 52.1667V27.9924C21.2041 27.587 18.977 27.0419 16.5651 26.3726C14.7025 25.8558 13.6115 23.9269 14.1283 22.0642C14.6451 20.2016 16.5741 19.1107 18.4366 19.6275C23.7676 21.1067 27.6941 21.8159 31.5163 21.8331C35.3261 21.8501 39.2375 21.1799 44.5216 19.6399C46.3774 19.099 48.3202 20.165 48.8611 22.0207C49.402 23.8765 48.336 25.8194 46.4802 26.3602C44.0486 27.0689 41.8078 27.6295 39.6675 28.0364V52.1667C39.6675 54.0997 38.1006 55.6667 36.1675 55.6667H36.0663C34.4698 55.6667 33.1056 54.5931 32.6933 53.1029C32.6555 52.966 32.6256 52.8255 32.6043 52.682C32.5946 52.6165 32.5867 52.5503 32.5806 52.4835L31.5104 40.7113C31.5042 40.6427 31.5011 40.5743 31.5009 40.5061C31.5006 40.5743 31.4975 40.6427 31.4913 40.7113L30.4211 52.4835C30.415 52.5503 30.4071 52.6165 30.3974 52.682C30.3761 52.8255 30.3462 52.966 30.3084 53.1029Z"
        fill={color}
      />
    </Svg>
  );
};
