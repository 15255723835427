import { Divider, Icon, Layout, MenuItem, Text, useTheme } from '@ui-kitten/components';
import React, { Fragment } from 'react';

import ForwardIcon from './components/icons/ForwardIcon';
import { GroupMenuItem } from './MainMenuContainer';

interface GroupMenuProps {
  items: GroupMenuItem[];
}
const GroupMenu = ({ items }: GroupMenuProps) => {
  const th = useTheme();
  return (
    <Layout level="1" style={{ borderRadius: 8, marginTop: 24 }}>
      {items
        .filter((item) => !item.hidden)
        .map((item, index) => {
          return (
            <Fragment key={item.id}>
              {index !== 0 && (
                <Divider
                  style={{ backgroundColor: th['color-basic-transparent-300'], width: '95%', alignSelf: 'center' }}
                />
              )}
              <MenuItem
                testID={item.id}
                title={(evaProps) => (
                  <Text {...evaProps} category="p2">
                    {item.name}
                  </Text>
                )}
                style={{ backgroundColor: 'transparent' }}
                id={item.id}
                onPress={item.action}
                accessible={true}
                accessoryRight={<ForwardIcon color={th['color-basic-500']} />}
                accessoryLeft={
                  item.accessoryLeft ? <Icon name={item.accessoryLeft} fill={th['color-primary-500']} /> : <></>
                }
              />
            </Fragment>
          );
        })}
    </Layout>
  );
};

export default GroupMenu;
