import Svg, { Circle, G, Rect } from 'react-native-svg';

import React from 'react';
import { SimpleIconProps } from 'src/core';

const ScanIcon = ({ size = 40, color = '#46D7CB', backgroundColor = '#DCFBED' }: SimpleIconProps) => (
  <Svg width={`${size}px`} height={`${size}px`} viewBox="0 0 40 40">
    <G data-name="Layer 2">
      <G data-name="arrow-ios-forward">
        <Circle cx="20" cy="20" r="20" fill={backgroundColor} />
        <Rect
          x="11"
          y="11"
          width="18"
          height="18"
          rx="3"
          fill="none"
          stroke={color}
          strokeWidth={2}
          strokeDasharray={[8, 6]}
        />
      </G>
    </G>
  </Svg>
);

export default ScanIcon;
