import { Alert, BackHandler, Linking } from 'react-native';
import { useCallback, useEffect } from 'react';

import VersionCheck from 'react-native-version-check';
import { needUpdateApp } from '../brands';
import { useAnalytics } from 'src/core/hooks/useAnalytics';
import { useAppStore } from '../store';
import { useGetAppName } from './useBrands';
import { useTranslationFunc } from './useTranslationFunc';

export const useNeedUpdateApp = () => {
  const setVersionChecked = useAppStore((state) => state.setVersionChecked);
  const { addAnalyticsLog } = useAnalytics('AppRootView.tsx');

  const t = useTranslationFunc(needUpdateApp);
  const appName = useGetAppName();

  const checkVersion = useCallback(async () => {
    if (!appName) {
      return;
    }

    const updateNeeded = await VersionCheck.needUpdate();

    if (updateNeeded?.isNeeded) {
      Alert.alert(t('needUpdateAlertTitle'), t('needUpdateAlertContent', { app_name: appName }), [
        {
          text: t('needUpdateAlertBtn'),
          onPress: () => {
            BackHandler.exitApp();
            if (updateNeeded.storeUrl) {
              Linking.openURL(updateNeeded.storeUrl);
            }
          }
        }
      ]);
    } else {
      setVersionChecked();
    }
  }, [appName, setVersionChecked, t]);

  useEffect(() => {
    try {
      checkVersion();
    } catch (error) {
      addAnalyticsLog({
        function: 'checkVersion',
        data: { error },
        logType: 'error'
      });
      setVersionChecked();
    }
  }, [addAnalyticsLog, checkVersion, setVersionChecked]);
};
