import { ApplicationState, useAppStore } from '../store';
import {
  Connections,
  GetUserId,
  SuccessMessage,
  getActivity,
  getAthlete,
  getBody,
  getDaily,
  getNutrition,
  getSleep,
  getUserId,
  initTerra,
  initConnection as initTerraConnection
} from 'terra-react';
import { useCallback, useEffect } from 'react';

import { Config } from '../constants/Config';
import { Platform } from 'react-native';
import { TerraProvider } from '../types/TerraProvider';
import WearableService from '../../api/wearable';
import moment from 'moment';
import { useGetExternalUserId } from './useUser';
import { useTelemetry } from './useTelemetry';

interface UseWearableSdk {
  isUserAuth: () => Promise<boolean>;
  getUserAuth: () => Promise<string>;
  requestSyncTerraData: (forceSync: boolean, connection?: Connections) => Promise<boolean>;
  initConnection: (appleProvider: TerraProvider) => Promise<boolean>;
  initWearableSdk: (ignoreCheckingProviderConnection?: boolean) => Promise<boolean>;
}

export const useWearableSdk = (): UseWearableSdk => {
  const { addTelemetryLog } = useTelemetry();
  const initialized = useAppStore((state: ApplicationState) => state.wearableSdkInitialized);
  const setWearableSdkInitialized = useAppStore((state: ApplicationState) => state.setWearableSdkInitialized);
  const externalUserId = useGetExternalUserId();

  const getUserAuth = () => {
    return getUserId(Connections.APPLE_HEALTH).then((data: GetUserId) => {
      if (data.success && data.userId) return Promise.resolve(data.userId.toString());
      return Promise.reject();
    });
  };

  const isUserAuth = useCallback(() => {
    // TODO change to checkAuth, now checkAuth crash app
    return getUserAuth().then(
      () => true,
      () => false
    );
  }, []);

  const requestSyncTerraData = useCallback(
    async (forceSync: boolean, connection: Connections = Connections.APPLE_HEALTH) => {
      const days = 90; //TODO read value from env

      const neededSync: Promise<boolean> = forceSync ? Promise.resolve(true) : WearableService.checkIfSyncNeeded();

      const startDate = moment().subtract(days, 'days').toDate();

      // TODO use on ReactNative version 0.71
      // const sendLog = (name: string, result: PromiseSettledResult<any>) => {
      //     if (result.status === 'rejected') {
      //         addTelemetryLog(`${name} fail ${JSON.stringify(result.reason)}`);
      //     } else {
      //         addTelemetryLog(`${name} success ${JSON.stringify(result.value)}`);
      //     }

      // };

      const sendLogLegacy = async (name: string, callback: () => Promise<any>) => {
        try {
          const data = await callback();
          addTelemetryLog(`${name}: ${JSON.stringify(data)}`);
        } catch (err) {
          addTelemetryLog(`${name} - fail ${JSON.stringify(err)}`);
        }
      };

      // TODO use on ReactNative version 0.71
      // const requestData = () => {

      //     const getUserIdPromise = getUserId(connection);

      //     const getActivityPromise = getActivity(connection, startDate, new Date());

      //     const getBodyPromise = getBody(connection, startDate, new Date());

      //     const getDailyPromise = getDaily(connection, startDate, new Date());

      //     const getSleepPromise = getSleep(connection, startDate, new Date());

      //     const getNutritionPromise = getNutrition(connection, startDate, new Date());

      //     getAthlete(connection);

      //     return Promise.allSettled([getUserIdPromise, getActivityPromise, getBodyPromise, getDailyPromise, getSleepPromise, getNutritionPromise])
      //             .then((values) => {
      //                 sendLog('getUserId', values[0]);
      //                 sendLog('getActivity', values[1]);
      //                 sendLog('getBody', values[2]);
      //                 sendLog('getDaily', values[3]);
      //                 sendLog('getSleep', values[4]);
      //                 sendLog('getNutrition', values[5]);
      //             return true;
      //           });

      // };

      const requestDataLegacy = async () => {
        await sendLogLegacy('getUserId', async () => getUserId(connection));
        await sendLogLegacy('getActivity', async () => getActivity(connection, startDate, new Date(), true));
        await sendLogLegacy('getBody', async () => getBody(connection, startDate, new Date(), true));
        await sendLogLegacy('getDaily', async () => getDaily(connection, startDate, new Date(), true));
        await sendLogLegacy('getSleep', async () => getSleep(connection, startDate, new Date(), true));
        await sendLogLegacy('getNutrition', async () => getNutrition(connection, startDate, new Date(), true));

        getAthlete(connection, true);

        return true;
      };

      return neededSync.then((sync) => {
        if (sync) {
          return requestDataLegacy();
        }
        return true;
      });
    },
    [addTelemetryLog]
  );

  const initConnectionWhitLib = useCallback(
    async (appleProvider: TerraProvider, checkIfSyncNeeded: boolean): Promise<boolean> => {
      const token = await WearableService.getToken(appleProvider.providerId, appleProvider?.resource);
      addTelemetryLog(`Auth token ${token}`);
      return initTerraConnection(Connections.APPLE_HEALTH, token, true)
        .then((data) => {
          addTelemetryLog(`initConnection: ${JSON.stringify(data)}`);
          if (data.success) {
            requestSyncTerraData(!checkIfSyncNeeded);
          }
          return data.success.valueOf();
        })
        .catch((error) => {
          addTelemetryLog(`initConnection - fail: ${JSON.stringify(error)}`);
          return false;
        });
    },
    [addTelemetryLog, requestSyncTerraData]
  );

  const initWearableSdk = useCallback(
    async (ignoreCheckingProviderConnection = false): Promise<boolean> => {
      if (
        Config.TERRA_DEV_ID === '-loaded-from-the-backend-' ||
        Platform.OS != 'ios' ||
        initialized ||
        !externalUserId
      ) {
        return false;
      }

      try {
        const providers: TerraProvider[] = await WearableService.getSubscriptions();

        const appleProvider = providers.find(
          (provider: TerraProvider) =>
            provider.provider_name === 'Apple' &&
            (ignoreCheckingProviderConnection ||
              (provider.disconnectedDate == undefined && provider.connectedDate != null))
        );
        if (!appleProvider) {
          return false;
        }

        const initResult: SuccessMessage = await initTerra(Config.TERRA_DEV_ID, externalUserId);

        // const user = await Auth.currentAuthenticatedUser();
        // const initResult: SuccessMessage = await initTerra(Config.TERRA_DEV_ID, user.attributes.sub);

        addTelemetryLog(`initTerra: ${JSON.stringify(initResult)}`);

        if (initResult.success) {
          initConnectionWhitLib(appleProvider, true);
          setWearableSdkInitialized(true);
          return true;
        }
      } catch (error) {
        addTelemetryLog(`Error: ${JSON.stringify(error)}`);
      }

      return false;
    },
    [addTelemetryLog, initConnectionWhitLib, initialized, setWearableSdkInitialized, externalUserId]
  );

  const initConnection = async (appleProvider: TerraProvider): Promise<boolean> => {
    if (initialized) {
      return initConnectionWhitLib(appleProvider, true);
    } else {
      return initWearableSdk(true);
    }
  };

  return { getUserAuth, isUserAuth, requestSyncTerraData, initConnection, initWearableSdk };
};

export const useWearableInitializer = () => {
  const isAppInit = useAppStore((state: ApplicationState) => state.isAppInitComplete);
  const { initWearableSdk } = useWearableSdk();

  useEffect(() => {
    if (isAppInit) {
      initWearableSdk();
    }
  }, [isAppInit, initWearableSdk]);
};
