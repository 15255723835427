import { StyleSheet } from 'react-native';
import { Layout } from '@ui-kitten/components';
import React from 'react';
import MenuCustomisationContainer from 'src/features/menuCustomisation/MenuCustomisationContainer';

//styles
const styleContainer = StyleSheet.create({
  screenContainer: { flex: 1 },
  safeAreaView: { flex: 1 },
  scrollView: {
    flex: 1
  }
});

const CustomisationScreen = () => {
  //view
  return (
    <Layout level="2" style={styleContainer.screenContainer}>
      <MenuCustomisationContainer />
    </Layout>
  );
};

export default CustomisationScreen;
