import React from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';
import { UIHelper as uh } from '../../../core';
import { Spinner, Card, Text, Button, Icon, useTheme } from '@ui-kitten/components';
import MultiScanModule from 'src/core/modules/MultiScanModule';

interface IDownloadResourceModalProps extends ViewProps {
  caption: string;
  btnCancelClick: () => void;
  onDownloadComplete: () => void;
}

const DownloadResourceModal = (props: IDownloadResourceModalProps) => {
  const th = useTheme();
  const styles = StyleSheet.create({
    container: {
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'center',
      borderRadius: 16,
      padding: 16,
      maxWidth: uh.currentViewPort() - 32
    },
    btnView: { width: 160, marginTop: 40 },
    btnDone: { width: 80, marginTop: 8 },
    backdrop: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    }
  });

  /**
   * Check the size of the AHI resources that require downloading.
   */
  function checkAHIResourcesDownloadSize() {
    // MultiScanModule.checkAHIResourcesDownloadSize().then((size: any) => {
    //   // console.log(
    //   //   'AHI INFO: Size of download is ' + Number(size) / 1024 / 1024,
    //   // );
    // });
  }

  /**
   * Download scan resources.
   *
   * We recommend only calling this function once per session to prevent duplicate background resource calls.
   */
  // function downloadAHIResources() {
  //   MultiScanModule.downloadAHIResources();
  // }

  /**
   * Check if the AHI resources are downloaded.
   *
   * We have remote resources that exceed 100MB that enable our scans to work.
   * You are required to download them in order to obtain a body scan.
   */
  const areAHIResourcesAvailable = React.useCallback(async () => {
    MultiScanModule.areAHIResourcesAvailable().then((areAvailable: boolean) => {
      if (!areAvailable) {
        // console.log('AHI INFO: Resources are not downloaded');
        // start download.
        //downloadAHIResources();
        checkAHIResourcesDownloadSize();
        setTimeout(() => areAHIResourcesAvailable(), 30000);
      } else {
        // console.log('AHI: Resources ready');
        props.onDownloadComplete();
      }
    });
  }, [props]);

  React.useEffect(() => {
    areAHIResourcesAvailable();
  }, [areAHIResourcesAvailable]);

  return (
    <View
      style={{
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
        height: 500,
        marginTop: 50
      }}>
      <Card style={[styles.container]}>
        <View
          style={{
            flexDirection: 'column',
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'center'
          }}>
          <Icon name="checkmark-circle-outline" style={{ width: 90, height: 90 }} fill={th['color-primary-500']}></Icon>
        </View>
        <Text category="s1" style={{ textAlign: 'center', marginBottom: 20, marginTop: 30 }}>
          {props.caption}
        </Text>
        <View
          style={{
            flexDirection: 'column',
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'center'
          }}>
          <Spinner size="giant" />
        </View>
        <View
          style={{
            flexDirection: 'column',
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'center'
          }}>
          <Button
            size="small"
            style={styles.btnView}
            appearance="filled"
            status="primary"
            onPress={props.btnCancelClick}>
            Cancel
          </Button>
        </View>
      </Card>
    </View>
  );
};

export default DownloadResourceModal;
