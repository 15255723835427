import { StyleSheet, View } from 'react-native';

import DynamicCard from './DynamicCard';
import { DynamicCardBody } from 'src/core/types/DynamicCard';
import React from 'react';
import { Spinner } from '@ui-kitten/components';
import { UIHelper as uh } from 'src/core';

interface InsightCardsListProps {
  insightDynamicCards: DynamicCardBody[];
  isLoading?: boolean;
  onClickItem: (card: DynamicCardBody) => void;
}

const InsightCardsList = ({
  insightDynamicCards,
  onClickItem,
  isLoading = false
}: InsightCardsListProps): JSX.Element => {
  const styleContainer = StyleSheet.create({
    loadingContainer: {
      padding: uh.h2DP(16),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  });

  if (isLoading) {
    return (
      <View style={styleContainer.loadingContainer}>
        <Spinner />
      </View>
    );
  }
  if (insightDynamicCards.length === 0) {
    return <></>;
  }
  return (
    <>
      {insightDynamicCards.map((dynamicCard: DynamicCardBody) => {
        return <DynamicCard key={dynamicCard.id} card={dynamicCard} onClick={onClickItem} />;
      })}
    </>
  );
};

export default InsightCardsList;
