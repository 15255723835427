import React from 'react';
import { StyleSheet } from 'react-native';
import { Icon, useTheme, Text } from '@ui-kitten/components';
import { useNavigation } from '@react-navigation/native';
import { useAppStore } from 'src/core/store';
import { UIHelper as uh } from '../../../core';
import Avatar from './Avatar';
import HeaderLeftWrapper from './HeaderLeftHeaderLeftWrapper';

// styles
const styleContainer = StyleSheet.create({
  iconContainer: { flexDirection: 'row', alignItems: 'center' },
  homeBackNavContainer: { flexDirection: 'row', alignItems: 'center', rowGap: 8, marginLeft: 16 },
  backNavContainer: { flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginLeft: 16 },
  backIcon: { width: 24, height: 24 },
  name: { marginLeft: 8 }
});

const HeaderLeft = () => {
  const th = useTheme();
  const navigation = useNavigation();
  const { routes } = navigation.getState();
  const lastRoute = routes[routes.length - 1];
  const isHomeScreen = lastRoute.name === 'Home';

  const name = useAppStore((state) => state.userAttributes.name);
  // styles
  const theme = useAppStore((state) => state.theme);
  const condColors = {
    header: uh.getHex(th, theme, 'color-basic-100', 'color-basic-1100'),
    headerLeftTint: uh.getHex(th, theme, 'color-basic-900', 'color-basic-100'),
    iconTint: th['text-basic-color']
  };

  if (isHomeScreen) {
    return (
      <HeaderLeftWrapper
        onPress={() => {
          navigation.navigate('ProfileSettings');
        }}>
        <Avatar />
        <Text category="s1" style={styleContainer.name}>
          {name}
        </Text>
      </HeaderLeftWrapper>
    );
  }

  return (
    <HeaderLeftWrapper
      onPress={() => {
        if (!navigation.canGoBack()) {
          navigation.navigate('Home');
        } else {
          if (lastRoute.name === 'Chat') {
            navigation.navigate('Chat', { screen: 'Conversations' });
          } else {
            navigation.goBack();
          }
        }
      }}>
      <Icon name="arrow-ios-back-outline" fill={condColors.iconTint} style={styleContainer.backIcon} />
    </HeaderLeftWrapper>
  );
};

export default HeaderLeft;
