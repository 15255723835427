import React from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';
import { UIHelper as uh } from '../../../../core';
import { Card, Text, Button, Icon, useTheme } from '@ui-kitten/components';

interface IDoneModalProps extends ViewProps {
  caption: string;
  description: string;
  btnDoneClick: () => void;
  btnHyperlinkText: string;
  btnHyperlinkClick: () => void;
}

const DoneModal = (props: IDoneModalProps) => {
  const th = useTheme();
  const styles = StyleSheet.create({
    container: {
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'center',
      borderRadius: 16,
      padding: 16,
      maxWidth: uh.currentViewPort() - 32
    },
    btnView: { justifyContent: 'center', marginBottom: 20 },
    btnDone: { padding: 20 },
    btnHyperlink: { padding: 20 },
    btnContainer: {
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'center',
      marginTop: 16
    },
    backdrop: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    }
  });

  return (
    <View
      style={{
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
        height: 600,
        marginTop: 50
      }}>
      <Card style={[styles.container]}>
        <View
          style={{
            flexDirection: 'column',
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'center'
          }}>
          <Icon name="checkmark-circle-outline" style={{ width: 90, height: 90 }} fill={th['color-primary-500']}></Icon>
        </View>
        <Text category="s1" style={{ textAlign: 'center', marginBottom: 20, marginTop: 30 }}>
          {props.caption}
        </Text>
        <Text style={{ textAlign: 'center' }}>{props.description}</Text>
        <View style={styles.btnContainer}>
          <Button size="medium" style={styles.btnDone} appearance="ghost" status="basic" onPress={props.btnDoneClick}>
            OK, thanks!
          </Button>
          <Button size="medium" style={styles.btnHyperlink} appearance="ghost" onPress={props.btnHyperlinkClick}>
            {props.btnHyperlinkText}
          </Button>
        </View>
      </Card>
    </View>
  );
};

export default DoneModal;
