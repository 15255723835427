import { Linking, Platform, RefreshControl, SafeAreaView, ScrollView, StyleSheet } from 'react-native';
import React, { useCallback, useEffect, useState } from 'react';
import { useGetDataSharing, useGetDataSharingModal, useSetDataSharing } from 'src/core/hooks/useDataSharing';
import { useFocusEffect, useLinkTo, useNavigation } from '@react-navigation/native';

import DynamicCard from 'src/features/home/DynamicCard';
import { DynamicCardBody } from 'src/core/types/DynamicCard';
import { ErrorModal } from 'src/components/shared/ErrorModal';
import InsightCardsList from 'src/features/home/InsightCardsList';
import { InvitationTenant } from 'src/components/tenants/modals/InvitationTenant';
import { Layout } from '@ui-kitten/components';
import { OutdatedTenant } from 'src/components/tenants/modals/OutdatedTenant';
import ScoreDialContainer from 'src/features/home/ScoreDialContainer';
import { getWebPlatformUrl } from 'src/core/helpers/URLHelper';
import { useDynamicCardsEffect } from 'src/core/hooks/useDynamicCards';
import { useScoreCategoriesEffect } from 'src/core/hooks/useScoreCategories';

export interface PillarDynamicCard {
  displayName: string;
  externalKey: string;
  score: number;
  accuracy: number;
  default?: boolean;
}

const styleContainer = StyleSheet.create({
  layout: { flex: 1 },
  safeAreaView: { flex: 1 }
});

const HomeScreen = (): JSX.Element => {
  const navigation = useNavigation();
  const linkTo = useLinkTo();
  const [selectedPillar, setSelectedPillar] = useState<PillarDynamicCard>({
    displayName: '',
    externalKey: '',
    score: 0,
    accuracy: 0
  });
  const [dynamicCardRefreshing, setDynamicCardRefreshing] = useState<boolean>(false);
  const [scoreRefreshing, setScoreRefreshing] = useState<boolean>(false);
  const [visibleInvitation, setVisibleInvitation] = useState<boolean>(false);

  const setDataSharing = useSetDataSharing();
  const dataSharing = useGetDataSharing();
  const dataSharingModal = useGetDataSharingModal();

  const [dynamicCards, isDynamicCardLoading, dynamicCardError] = useDynamicCardsEffect({
    refreshing: dynamicCardRefreshing,
    setRefreshing: setDynamicCardRefreshing
  });
  const [scoreCategories, isScoreCategoryLoading, scoreCategoryError] = useScoreCategoriesEffect({
    refreshing: scoreRefreshing,
    setRefreshing: setScoreRefreshing
  });

  const clickCard = (card: DynamicCardBody) => {
    if (card.url?.length > 0) {
      if (Platform.OS === 'web') {
        linkTo(getWebPlatformUrl(card.url));
      } else {
        Linking.openURL(card.url);
      }
    } else {
      const data = scoreCategories.find((item) => card.category.toLocaleLowerCase().includes(item.externalKey));
      if (data) {
        navigation.navigate('Pillar' as any, {
          ...data,
          name: data?.displayName,
          type: data?.externalKey,
          accuracy: data?.nominalTotalAccuracy,
          alertText: card.text
        });
      }
    }
  };

  const refreshScoreDial = useCallback(() => {
    setScoreRefreshing(true);
  }, []);

  useFocusEffect(refreshScoreDial);

  useEffect(() => {
    setVisibleInvitation(dataSharing);
  }, [dataSharing, dataSharingModal]);

  return (
    <Layout level="1" style={styleContainer.layout}>
      <SafeAreaView style={{ flex: 1 }}>
        <ScrollView
          style={styleContainer.safeAreaView}
          alwaysBounceVertical={true}
          refreshControl={
            <RefreshControl
              refreshing={scoreRefreshing && dynamicCardRefreshing}
              onRefresh={() => {
                setDynamicCardRefreshing(true);
                setScoreRefreshing(true);
              }}
            />
          }>
          {(dynamicCards.length > 0 || isDynamicCardLoading) && (
            <DynamicCard card={{ ...dynamicCards[0], isLoading: isDynamicCardLoading }} onClick={clickCard} />
          )}
          <ScoreDialContainer
            scoreCategories={scoreCategories}
            isLoading={isScoreCategoryLoading}
            selectedPillar={selectedPillar}
            setSelectedPillar={setSelectedPillar}
          />
          {(dynamicCards.length > 1 || isDynamicCardLoading) && (
            <InsightCardsList
              insightDynamicCards={dynamicCards.slice(1)}
              isLoading={isDynamicCardLoading}
              onClickItem={clickCard}
            />
          )}
        </ScrollView>
        <ErrorModal
          visible={dynamicCardError !== undefined || scoreCategoryError !== undefined}
          message={{ ...dynamicCardError, ...scoreCategoryError }}
        />
        {dataSharingModal.status ? (
          <InvitationTenant
            visible={visibleInvitation}
            onFinalButtonClick={() => {
              setDataSharing(false);
              setVisibleInvitation(false);
            }}
            proUserName={dataSharingModal.proUserName}
            tenantName={dataSharingModal.tenantName}
          />
        ) : (
          <OutdatedTenant
            visible={visibleInvitation}
            onFinalButtonClick={() => {
              setDataSharing(false);
              setVisibleInvitation(false);
            }}
            onSecondaryButton={() => {
              setDataSharing(false);
              setVisibleInvitation(false);
              navigation.navigate('DataSharing');
            }}
            proUserName={dataSharingModal.proUserName}
            tenantName={dataSharingModal.tenantName}
          />
        )}
      </SafeAreaView>
    </Layout>
  );
};

export default HomeScreen;
