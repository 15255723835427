import { FlatList, ViewProps } from 'react-native';
import React, { useEffect, useMemo } from 'react';

import { AssessmentBiomarker } from '../profileMeAssessment';
import { BackendApi } from 'src/api/shared';
import { NewHealthMarker } from 'src/core/types/HealthMarkerReport';
import Spinner from 'react-native-loading-spinner-overlay';
import moment from 'moment';
import { useAnalytics } from 'src/core/hooks/useAnalytics';
import { useRefreshMarkersCounter } from 'src/core/hooks/useGlobalVar';

//props
interface IBiomarkerListProps extends ViewProps {
  btnDetailAssessmentClick: (assessmentId: string) => void;
  refreshing: boolean;
  finishRefreshing: () => void;
  isLoadMore: boolean;
  markerName: string;
  sortedType: number;
  searchText: string;
  finishLoadMore: (canLoadMore: boolean) => void;
  header: JSX.Element;
}

const BiomarkerList = (props: IBiomarkerListProps) => {
  const { addAnalyticsLog } = useAnalytics('BiomarkerList.tsx');
  const needRefresh = useRefreshMarkersCounter();

  const [isLoading, setIsLoading] = React.useState(false);
  const [biomarkers, setBiomarkers] = React.useState<NewHealthMarker[]>(new Array<NewHealthMarker>());
  const [allBiomarkers, setAllBiomarkers] = React.useState<NewHealthMarker[]>(new Array<NewHealthMarker>());
  const [isFirstLoad, setIsFirstLoad] = React.useState(true);
  const [pageNumber, setPageNumber] = React.useState(1);
  const RECORDS_PER_PAGE = 10;

  useEffect(() => {
    if (needRefresh > 0) {
      setIsFirstLoad(true);
    }
  }, [needRefresh]);

  // filter data
  const filterData = React.useCallback(
    (data: Array<NewHealthMarker>): Array<NewHealthMarker> => {
      let displayData = data;
      displayData = displayData.map(function (element) {
        return {
          ...element,
          pillar: element.category,
          displayValue: String(element.latestValue),
          id: element.healthMarkerId,
          key: element.externalKey
        };
      });
      // displayData = displayData.map

      if (props.markerName != ' All ') {
        displayData = displayData.filter((item: NewHealthMarker) => item.pillar == props.markerName);
      }

      // filter by searchText
      if (props.searchText.length > 0) {
        displayData = displayData.filter((item: NewHealthMarker) =>
          item.name.toLowerCase().includes(props.searchText.toLowerCase())
        );
      }

      // filter by sortedType
      if (props.sortedType == 0) {
        // most recent
        displayData = displayData.sort((a, b) => {
          return moment(b.measuredDate).valueOf() - moment(a.measuredDate).valueOf();
        });
      } else if (props.sortedType == 1) {
        // alphabetical
        displayData = displayData.sort((a, b) => {
          return a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? 1 : -1;
        });
      } else if (props.sortedType == 2) {
        // oldest
        displayData = displayData.sort((a, b) => {
          return moment(a.measuredDate).valueOf() - moment(b.measuredDate).valueOf();
        });
      }

      return displayData;
    },
    [props.markerName, props.searchText, props.sortedType]
  );

  const tempProps = props;
  const getData = React.useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await BackendApi.get('health-markers/data/latest-health-markers');
      if (response.status >= 200 && response.status <= 399) {
        const allData: Array<NewHealthMarker> = response.data.filter(
          (item: NewHealthMarker) => item.categories != undefined && item.categories.length > 0 && item.category != null
        );
        let displayData: Array<NewHealthMarker> = allData.slice(0, pageNumber * RECORDS_PER_PAGE);
        displayData = filterData(displayData);
        setAllBiomarkers(allData);
        setBiomarkers(displayData);
        setIsLoading(false);
        tempProps.finishRefreshing();
        tempProps.finishLoadMore(allData.length > pageNumber * RECORDS_PER_PAGE);
      } else {
        addAnalyticsLog({ function: 'getData', data: response, logType: 'error' });
        setIsLoading(false);
        tempProps.finishRefreshing();
        tempProps.finishLoadMore(false);
      }
    } catch (error) {
      addAnalyticsLog({ function: 'getData', data: error, logType: 'error' });
      setIsLoading(false);
      tempProps.finishRefreshing();
      tempProps.finishLoadMore(false);
    }
  }, [tempProps, pageNumber, filterData, addAnalyticsLog]);

  React.useEffect(() => {
    if (isFirstLoad == true) {
      setIsFirstLoad(false);
      setTimeout(() => {
        getData();
      }, 500);
    }
  }, [getData, isFirstLoad]);

  React.useEffect(() => {
    if (isFirstLoad == false) {
      const filteredData = filterData(allBiomarkers);
      const numberOfItems = (pageNumber + 1) * RECORDS_PER_PAGE;

      if (tempProps.isLoadMore == true) {
        setBiomarkers(filteredData.slice(0, numberOfItems));
        setPageNumber(pageNumber + 1);
        props.finishLoadMore(filteredData.length > numberOfItems);
      } else {
        setBiomarkers(filteredData.slice(0, pageNumber * RECORDS_PER_PAGE));
        props.finishLoadMore(filteredData.length > numberOfItems);
      }
    }
  }, [props.isLoadMore, isFirstLoad, filterData, allBiomarkers, pageNumber, tempProps, props]);

  React.useEffect(() => {
    if (props.refreshing == true) {
      getData();
    }
  }, [props.refreshing, getData]);

  const renderBiomarker = ({ item, index }: any) => {
    return (
      <AssessmentBiomarker
        key={`biomarker-item-${index}`}
        biomarker={item}
        style={{ borderRadius: 8, marginTop: 16 }}
        accessibilityLabel={`Biomarker ${index}`}
        testID={`biomarker-${index}`}
      />
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedBiomarker = useMemo(() => renderBiomarker, [biomarkers]);

  if (isLoading && props.refreshing == false && props.isLoadMore == false) {
    return <Spinner visible={true} />;
  }

  // view
  return (
    <>
      {props.header}
      <FlatList
        style={props.style}
        data={biomarkers}
        renderItem={memoizedBiomarker}
        onEndReached={() => {
          setPageNumber((page) => page + 1);
        }}
        onEndReachedThreshold={0}></FlatList>
    </>
  );
};

export default React.memo(BiomarkerList);
