import React from 'react';
import { useInitAppTheme } from 'src/core/hooks/useAppTheme';

const ThemeInit = () => {
  useInitAppTheme();

  return <></>;
};

export default ThemeInit;
