import Svg, { G, Path, Rect } from 'react-native-svg';

import React from 'react';

const UpwardIcon = ({ size = 24, color = '#FFFFFF' }) => (
  <Svg width={`${size}px`} height={`${size}px`} viewBox="0 0 24 24">
    <G data-name="Layer 2">
      <G data-name="arrow-upward">
        <Rect width="24" height="24" transform="rotate(180 12 12)" opacity="0" />
        <Path
          fill={color}
          d="M18 15a1 1 0 0 1-.64-.23L12 10.29l-5.37 4.32a1 1 0 0 1-1.41-.15 1 1 0 0 1 .15-1.41l6-4.83a1 1 0 0 1 1.27 0l6 5a1 1 0 0 1 .13 1.41A1 1 0 0 1 18 15z"
        />
      </G>
    </G>
  </Svg>
);

export default UpwardIcon;
