import * as moment from 'moment-timezone';

import { AVPlaybackStatusSuccess, Audio } from 'expo-av';
import { Icon, Text, useTheme } from '@ui-kitten/components';
import React, { useCallback, useEffect, useState } from 'react';
import { Recording, Sound } from 'expo-av/build/Audio';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import ProgressBar from './ProgressBar';
import { getDocumentAsync } from 'expo-document-picker';
import { UIHelper as uh } from 'src/core';
import { useAnalytics } from 'src/core/hooks/useAnalytics';

interface ChatAudioInputProps {
  setIsAudioVisible: (isAudioVisible: boolean) => void;
  onSend: (url: string) => void;
}

const DURATION_LIMIT = 10_000; // in miliseconds

const ChatAudioInput = ({ setIsAudioVisible, onSend }: ChatAudioInputProps) => {
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [duration, setDuration] = useState<number>(0);

  const [audioRecording, setAudioRecording] = useState<Recording>();
  const [sound, setSound] = useState<Sound>();
  const [soundURI, setSoundURI] = useState<string>('');

  const [playbackDuration, setPlaybackDuration] = useState<number>(0);
  const [playbackMaxDuration, setPlaybackMaxDuration] = useState<number>(0);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  const th = useTheme();
  const { addAnalyticsLog } = useAnalytics('ChatAudioInput.tsx');

  const styleContainer = StyleSheet.create({
    container: {
      height: uh.h2DP(56),
      marginTop: uh.h2DP(8),
      borderWidth: 1,
      borderBottomWidth: 0,
      borderTopRightRadius: 8,
      borderTopLeftRadius: 8,
      borderColor: th['border-basic-color-4'],
      width: '95%',
      alignSelf: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingHorizontal: uh.h2DP(8)
    },
    icon: {
      paddingHorizontal: uh.h2DP(4)
    },
    mediaButtons: {
      flexDirection: 'row'
    },
    recordButtonContainer: {
      height: uh.h2DP(36),
      width: uh.h2DP(36),
      borderWidth: 1,
      borderColor: 'red',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: uh.height()
    },
    recordButton: {
      height: uh.h2DP(18),
      width: uh.h2DP(18),
      borderRadius: isRecording ? 0 : uh.height(),
      backgroundColor: 'red'
    }
  });

  const audioPicker = async () => {
    const file = await getDocumentAsync({ type: 'audio/*' });
    if (file.type === 'success') {
      const audioSound = await Audio.Sound.createAsync({ uri: file.uri });
      setSoundURI(file.uri);
      setSound(audioSound.sound);
    }
  };

  const startRecording = async () => {
    try {
      await Audio.requestPermissionsAsync();
      await Audio.setAudioModeAsync({
        allowsRecordingIOS: true,
        playsInSilentModeIOS: true
      });

      const { recording } = await Audio.Recording.createAsync(Audio.RecordingOptionsPresets.HIGH_QUALITY);
      recording.setOnRecordingStatusUpdate((status) => {
        setDuration(status.durationMillis);
      });
      setAudioRecording(recording);
    } catch (err) {
      addAnalyticsLog({ function: 'startRecording', data: err, logType: 'error' });
    }
  };

  const stopRecording = useCallback(async () => {
    if (audioRecording) {
      await audioRecording.stopAndUnloadAsync();
      await Audio.setAudioModeAsync({
        allowsRecordingIOS: false
      });
      const uri = audioRecording.getURI();
      if (uri) {
        const audioSound = await Audio.Sound.createAsync({ uri: uri });
        setSoundURI(uri);
        setSound(audioSound.sound);
      }
    }

    setDuration(0);
    setIsRecording(false);
  }, [audioRecording]);

  const handleSend = () => {
    onSend(soundURI);
    setSound(undefined);
    setSoundURI('');
    setIsAudioVisible(false);
  };

  useEffect(() => {
    if (duration >= DURATION_LIMIT) {
      stopRecording();
      return;
    }
  }, [duration, stopRecording]);

  useEffect(() => {
    if (sound) {
      sound.setOnPlaybackStatusUpdate(async (status) => {
        setPlaybackMaxDuration((status as AVPlaybackStatusSuccess).durationMillis ?? 0);
        setPlaybackDuration((status as AVPlaybackStatusSuccess).positionMillis);
        setIsPlaying((status as AVPlaybackStatusSuccess).isPlaying);

        if ((status as AVPlaybackStatusSuccess).didJustFinish) {
          await sound.stopAsync();
          setPlaybackDuration(0);
          await sound.setPositionAsync(0);
        }
      });
      return () => {
        sound.unloadAsync();
        setSound(undefined);
        setSoundURI('');
      };
    }
  }, [sound]);

  if (sound) {
    return (
      <View style={styleContainer.container}>
        <View style={{ flexDirection: 'row' }}>
          <TouchableOpacity onPress={() => (isPlaying ? sound.pauseAsync() : sound.playAsync())}>
            <Icon
              height={uh.h2DP(36)}
              width={uh.h2DP(36)}
              fill={th['color-primary-500']}
              name={isPlaying ? 'pause-circle-outline' : 'play-circle-outline'}
            />
          </TouchableOpacity>
          <ProgressBar
            progress={playbackDuration}
            maxProgress={playbackMaxDuration}
            setProgress={setPlaybackDuration}
            sound={sound}
            shouldPlay={isPlaying}>
            <Text style={{ position: 'absolute', left: uh.h2DP(8), color: th['color-basic-600'] }}>
              {moment.utc(playbackDuration).format('mm:ss')} / {moment.utc(playbackMaxDuration).format('mm:ss')}
            </Text>
          </ProgressBar>
        </View>
        <View style={{ flexDirection: 'row', gap: uh.h2DP(4) }}>
          <TouchableOpacity onPress={() => setSound(undefined)}>
            <Icon height={uh.h2DP(32)} width={uh.h2DP(32)} fill={th['color-danger-500']} name="close-circle-outline" />
          </TouchableOpacity>
          <TouchableOpacity onPress={handleSend}>
            <Icon height={uh.h2DP(32)} width={uh.h2DP(32)} fill={th['color-primary-500']} name="paper-plane-outline" />
          </TouchableOpacity>
        </View>
      </View>
    );
  }

  return (
    <View style={styleContainer.container}>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <TouchableOpacity
          style={styleContainer.icon}
          onPress={() => {
            if (isRecording) {
              stopRecording();
            } else {
              startRecording();
            }
            setIsRecording((prev) => !prev);
          }}>
          <View style={styleContainer.recordButtonContainer}>
            <View style={styleContainer.recordButton}></View>
          </View>
        </TouchableOpacity>
        <ProgressBar
          containerStyle={{ alignItems: 'center' }}
          progress={duration}
          maxProgress={DURATION_LIMIT}
          setProgress={setDuration}>
          <Text style={{ color: th['color-basic-600'] }}>{moment.utc(duration).format('mm:ss')}</Text>
        </ProgressBar>
      </View>
      <View style={styleContainer.mediaButtons}>
        <TouchableOpacity style={styleContainer.icon} onPress={audioPicker}>
          <Icon height={uh.h2DP(24)} width={uh.h2DP(24)} fill={th['color-basic-600']} name="music-outline" />
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default ChatAudioInput;
