import { Icon, Text, Tooltip, useTheme } from '@ui-kitten/components';
import React, { useState } from 'react';

import { TouchableOpacity } from 'react-native';

const GenderInfoTooltip = (): JSX.Element => {
  const th = useTheme();

  const GENDER_INFO_TEXT = `Your sex at birth is used to assess a range of 
        biochemical, physiological, and morphological characteristics, 
        and is particularly important for blood markers. 
        We respect that your identifying gender might be different.`;

  const [visible, setVisible] = useState<boolean>(false);

  const renderAnchorn = () => {
    return (
      <TouchableOpacity
        style={{ height: 20, width: 20, alignSelf: 'center', marginHorizontal: 5 }}
        onPress={() => setVisible(true)}>
        <Icon fill={th['color-primary-500']} name={'alert-circle-outline'} />
      </TouchableOpacity>
    );
  };

  return (
    <Tooltip
      anchor={renderAnchorn}
      visible={visible}
      onBackdropPress={() => setVisible(false)}
      placement={'top start'}
      style={{ backgroundColor: th['color-basic-900'] }}>
      {(props) => (
        <Text
          {...props}
          style={{ width: 235, color: th['color-primary-500'], fontSize: 12, textAlign: 'center' }}
          category={'label'}>
          {GENDER_INFO_TEXT}
        </Text>
      )}
    </Tooltip>
  );
};

export default GenderInfoTooltip;
