import { CheckBox, Layout, Text } from '@ui-kitten/components';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { ProtocolDetailsAction, ProtocolItem } from 'src/core/types/Protocols';
import React, { useState } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import ForwardIcon from '../../home/components/ForwardIcon';
import { PillarContainer } from 'src/components/home';
import { RootStackActionParamList } from 'src/core/types/RootNavStackParamList';
import { UIHelper as uh } from 'src/core';
import { useIsLightColorScheme } from 'src/core/hooks/useIsLightColorScheme';

interface ProtocolListItemProps {
  protocol: ProtocolItem;
  setProtocolIds: (prev: (prev: number[]) => number[]) => void;
}

const ProtocolListItem = ({ protocol, setProtocolIds }: ProtocolListItemProps) => {
  const styleContainer = StyleSheet.create({
    layout: {
      borderRadius: 8,
      marginVertical: uh.h2DP(6),
      padding: uh.h2DP(12),
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-between'
    },
    content: {
      flex: 2,
      paddingHorizontal: uh.h2DP(18),
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      gap: uh.h2DP(6),
      marginBottom: uh.h2DP(12)
    },
    pillarContainer: {
      flexDirection: 'row',
      gap: uh.h2DP(4)
    }
  });

  const [checked, setChecked] = useState<boolean>(false);

  const navigation: NavigationProp<RootStackActionParamList> = useNavigation();
  const isLightTheme = useIsLightColorScheme();

  const handleProtocol = () => {
    navigation.navigate('NewProtocol', { id: String(protocol.id) });
  };

  const protocolPillars = protocol.actions.reduce((prev: string[], curr: ProtocolDetailsAction) => {
    if (!prev.includes(curr.action.pillar)) {
      prev.push(curr.action.pillar);
    }
    return prev;
  }, []);

  return (
    <Layout level="1" style={styleContainer.layout}>
      <CheckBox
        checked={checked}
        style={{ paddingTop: uh.h2DP(2) }}
        onChange={(isChecked) => {
          setChecked(isChecked);
          if (isChecked) {
            setProtocolIds((prev) => [...prev, protocol.id]);
          } else {
            setProtocolIds((prev) => prev.filter((protocolId) => protocolId !== protocol.id));
          }
        }}
      />
      <TouchableOpacity style={{ flex: 1, flexDirection: 'row' }} onPress={handleProtocol}>
        <View style={styleContainer.content}>
          <Text category="h6">{protocol.name}</Text>
          <View style={styleContainer.pillarContainer}>
            {protocolPillars.map((pillar: any) => (
              <PillarContainer key={pillar} type={pillar.toLowerCase()} name={pillar} size={'small'} />
            ))}
          </View>
          <Text category="c1">{`By ${protocol.authorName}`}</Text>
        </View>
        <ForwardIcon color={isLightTheme ? 'black' : 'white'} />
      </TouchableOpacity>
    </Layout>
  );
};

export default ProtocolListItem;
