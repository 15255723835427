import { useCallback } from 'react';

type TranslationFunc = (key: string, params?: any) => string;

export const useTranslationFunc = (data: any): TranslationFunc => {
  return useCallback(
    (key: string, params: any): string => {
      let result = data[key] ?? key;
      if (params) {
        Object.keys(params).forEach((paramKey) => {
          result = result.replaceAll(`{{${paramKey}}}`, params[paramKey]);
        });
      }
      return result;
    },
    [data]
  );
};
