import { UIHelper as uh } from 'src/core';

const MAX_BUBBLE_HEIGHT = uh.h2DP(256);
const MAX_BUBBLE_WIDTH = uh.h2DP(256);

export const calculateScale = (
  width: number,
  height: number,
  max_width = MAX_BUBBLE_WIDTH,
  max_height = MAX_BUBBLE_HEIGHT
) => {
  let scale = 1;
  if (width > max_width || height > max_height) {
    const widthScale = max_width / width;
    const heightScale = max_height / height;

    scale = Math.min(widthScale, heightScale);
  }

  return scale;
};
