import React, { useState } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { Text, useTheme } from '@ui-kitten/components';

import { UIHelper as uh } from 'src/core';
import { useIsLightColorScheme } from 'src/core/hooks/useIsLightColorScheme';

interface CounterProps {
  counter: number;
  setCounter: (counter: (prev: number) => number) => void;
  changeBy: number;
  maxCounter: number;
  minCounter: number;
  format?: () => string;
}

const Counter = ({ counter, setCounter, changeBy, maxCounter, minCounter, format }: CounterProps) => {
  const th = useTheme();
  const isLightTheme = useIsLightColorScheme();

  const [timeoutId, setTimeoutId] = useState<any>();

  const initialDelay = 500;

  const onPress = (delay: number, increase = true) => {
    if (increase) {
      setCounter((prev) => Math.min(prev === maxCounter ? prev : prev + changeBy, maxCounter));
    } else {
      setCounter((prev) => Math.max(prev === minCounter ? prev : prev - changeBy, minCounter));
    }
    setTimeoutId(
      setTimeout(() => {
        onPress(delay > 10 ? delay / 1.25 : 10, increase);
      }, delay)
    );
  };

  const styleContainer = StyleSheet.create({
    container: {
      flexDirection: 'row',
      width: '100%',
      height: uh.h2DP(64),
      borderWidth: 1,
      borderRadius: 8,
      borderColor: th[`color-basic-${isLightTheme ? 500 : 700}`],
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    text: {
      fontSize: uh.h2DP(24)
    },
    buttons: {
      height: '100%',
      width: uh.h2DP(64),
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: th[`color-basic-${isLightTheme ? 400 : 1100}`],
      borderColor: th[`color-basic-${isLightTheme ? 500 : 700}`]
    }
  });

  return (
    <View style={styleContainer.container}>
      <TouchableOpacity
        disabled={counter === minCounter}
        onPressIn={() => onPress(initialDelay, false)}
        onPressOut={() => {
          clearTimeout(timeoutId);
        }}
        style={[
          styleContainer.buttons,
          {
            opacity: counter === minCounter ? 0.5 : 1,
            borderRightWidth: 1,
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8
          }
        ]}>
        <Text style={styleContainer.text}>&mdash;</Text>
      </TouchableOpacity>
      <Text category="label" style={[styleContainer.text, { flex: 1, textAlign: 'center' }]}>
        {format ? format() : counter}
      </Text>
      <TouchableOpacity
        disabled={counter === maxCounter}
        onPressIn={() => onPress(initialDelay)}
        onPressOut={() => {
          clearTimeout(timeoutId);
        }}
        style={[
          styleContainer.buttons,
          {
            opacity: counter === maxCounter ? 0.5 : 1,
            borderLeftWidth: 1,
            borderBottomRightRadius: 8,
            borderTopRightRadius: 8
          }
        ]}>
        <Text style={styleContainer.text}>+</Text>
      </TouchableOpacity>
    </View>
  );
};

export default Counter;
