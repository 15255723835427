import { Layout, Text } from '@ui-kitten/components';
import { StyleSheet, TouchableWithoutFeedback, View, ViewProps } from 'react-native';

import React from 'react';
import { UIHelper as uh } from '../../../core';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';

//props
interface MenuSpecialItemProps extends ViewProps {
  leftIcon: any;
  rightIcon: any;
  title: string;
  count: number;
  onPress: () => void;
  id?: string;
}

// styles
const styleContainer = StyleSheet.create({
  menu: {
    borderRadius: 8,
    padding: 8,
    cursor: 'pointer'
  },
  container: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center'
  },
  leftContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderRadius: 8
  },
  title: { marginLeft: uh.w2DP(16) },
  newText: { color: '#FF3D71' }
});

const MenuSpecialItem = ({ leftIcon, rightIcon, title, count, onPress, id }: MenuSpecialItemProps) => {
  const transMessage = {
    newNumber: ' {{count}} new'
  };
  const t = useTranslationFunc(transMessage);
  // view
  return (
    <Layout level="1" style={styleContainer.menu}>
      <TouchableWithoutFeedback onPress={onPress} testID={id}>
        <View style={styleContainer.container}>
          <View style={[styleContainer.leftContainer]}>
            {leftIcon}
            <Text category="s1" style={styleContainer.title}>
              {title}
              <>
                {count > 0 && (
                  <Text category="c1" style={styleContainer.newText}>
                    {t('newNumber', { count: count })}
                  </Text>
                )}
              </>
            </Text>
          </View>
          {rightIcon}
        </View>
      </TouchableWithoutFeedback>
    </Layout>
  );
};

export default MenuSpecialItem;
