import { Layout, Spinner } from '@ui-kitten/components';
import React, { memo } from 'react';

const Loading = () => {
  return (
    <Layout style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <Spinner size="large" status="primary" />
    </Layout>
  );
};

export default memo(Loading);
