import Svg, { Circle, G, Path } from 'react-native-svg';

import React from 'react';
import { SimpleIconProps } from 'src/core';

const ActionIcon = ({ size = 40, color = '#46D7CB', backgroundColor = '#DCFBED' }: SimpleIconProps) => (
  <Svg width={`${size}px`} height={`${size}px`} viewBox="0 0 40 40">
    <G data-name="Layer 2">
      <G data-name="arrow-ios-forward">
        <Circle cx="20" cy="20" r="20" fill={backgroundColor} />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.7046 16.3945L18.9206 21.3635L17.2876 19.2775C16.9476 18.8415 16.3176 18.7655 15.8836 19.1065C15.4486 19.4465 15.3716 20.0755 15.7126 20.5095L18.1436 23.6165C18.3336 23.8585 18.6236 23.9995 18.9316 23.9995H18.9386C19.2476 23.9985 19.5396 23.8525 19.7266 23.6055L24.2956 17.6055C24.6306 17.1655 24.5456 16.5395 24.1056 16.2045C23.6646 15.8695 23.0386 15.9555 22.7046 16.3945M20 28C15.589 28 12 24.411 12 20C12 15.589 15.589 12 20 12C24.411 12 28 15.589 28 20C28 24.411 24.411 28 20 28M20 10C14.477 10 10 14.478 10 20C10 25.522 14.477 30 20 30C25.523 30 30 25.522 30 20C30 14.478 25.523 10 20 10"
          fill={color}
        />
      </G>
    </G>
  </Svg>
);

export default ActionIcon;
