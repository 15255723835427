import { Path, Svg } from 'react-native-svg';

import React from 'react';
import { SvgIconProp } from 'src/core/types/ScanIcons';

export const RestIcon = ({ color = '#2F53B3', height = 20, width = 20, strokeWidth = 0 }: SvgIconProp) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 20 20" stroke={color} strokeWidth={strokeWidth} fill={color}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.02743 3.79092C5.0484 5.14159 3.75002 7.41503 3.75002 9.99041C3.75002 14.1325 7.10788 17.4904 11.25 17.4904C12.5094 17.4904 13.6956 17.1805 14.7373 16.6323C9.99196 16.2846 6.25002 12.3245 6.25002 7.49041C6.25002 6.1746 6.52765 4.92271 7.02743 3.79092ZM7.78646 2.40872C4.91302 3.72354 2.91669 6.62384 2.91669 9.99041C2.91669 14.5928 6.64765 18.3237 11.25 18.3237C13.1518 18.3237 14.9048 17.6867 16.3073 16.6143C16.371 16.5655 16.4341 16.5159 16.4964 16.4653C16.6128 16.3709 16.7266 16.2734 16.8377 16.1729C16.8768 16.1376 16.9156 16.1018 16.954 16.0657C17.1039 15.9249 16.9664 15.6825 16.7634 15.7155C16.7232 15.722 16.683 15.7283 16.6426 15.7342C16.4957 15.7559 16.3475 15.7737 16.1981 15.7876C16.1284 15.7941 16.0585 15.7997 15.9883 15.8044C15.7994 15.8172 15.6088 15.8237 15.4167 15.8237C10.8143 15.8237 7.08335 12.0928 7.08335 7.49041C7.08335 5.86715 7.54748 4.35229 8.35026 3.0713C8.38814 3.01085 8.42678 2.95092 8.46616 2.89152C8.54896 2.76663 8.63503 2.64411 8.72426 2.52406C8.7484 2.49159 8.77276 2.4593 8.79736 2.4272C8.92245 2.26391 8.7738 2.02847 8.57895 2.09436C8.52925 2.11117 8.47976 2.12843 8.43048 2.14614C8.28865 2.19713 8.14859 2.25186 8.01044 2.3102C7.93521 2.34198 7.86054 2.37482 7.78646 2.40872Z"
        fill={color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0109 6.05464L16.0418 2.91634L15.0726 6.05464L13.1977 6.94747L15.0809 7.84427L16.0418 10.5897L17.0027 7.84427L18.8859 6.94747L17.0109 6.05464ZM15.7609 6.64985L16.0418 5.74052L16.3226 6.64985L16.9477 6.94747L16.3309 7.24118L16.0418 8.06714L15.7527 7.24118L15.1359 6.94747L15.7609 6.64985Z"
        fill={color}
      />
      <Path
        d="M12.0834 11.2497L12.5 9.58301L12.9167 11.2497L14.1667 11.6663L12.9167 12.083L12.5 13.7497L12.0834 12.083L10.8334 11.6663L12.0834 11.2497Z"
        fill={color}
      />
      <Path
        d="M10 4.99967L10.4167 3.33301L10.8334 4.99967L12.0834 5.41634L10.8334 5.83301L10.4167 7.49967L10 5.83301L8.75002 5.41634L10 4.99967Z"
        fill={color}
      />
    </Svg>
  );
};
