import React, { PropsWithChildren } from 'react';
import { SafeAreaView, StyleProp, StyleSheet, ViewStyle } from 'react-native';

import { Layout } from '@ui-kitten/components';

interface ChatLayoutProps extends PropsWithChildren {
  style?: StyleProp<ViewStyle>;
}

const styleContainer = StyleSheet.create({
  layout: {
    flex: 1
  },
  safeAreaView: {
    flex: 1
  }
});

const ChatLayout = ({ children, style }: ChatLayoutProps): JSX.Element => {
  return (
    <Layout level="1" style={[styleContainer.layout, style]}>
      <SafeAreaView style={styleContainer.safeAreaView}>{children}</SafeAreaView>
    </Layout>
  );
};

export default ChatLayout;
