import * as Font from 'expo-font';
import * as React from 'react';
import * as SplashScreen from 'expo-splash-screen';

import { ApplicationState, useAppStore } from '../store/index';

import { AnalyticsLogsData } from '../store/slices/analytics';
import Brands from 'brands.json';
import { InitErrorCode } from '../types/InitError';
import { KalibraFont } from '../constants/KalibraTheme';
import { Platform } from 'react-native';
import { getValue } from 'src/api/storage';
import { useAnalytics } from './useAnalytics';
import { useHandshake } from 'src/core/hooks/useHandshake';
import { useSetFirstRun } from './useGlobalVar';

const initializeResources = async (
  setIsFirstLoad: (firstRun: boolean) => void,
  preAuth: () => Promise<object>,
  addAnalyticsLog: (log: AnalyticsLogsData) => void,
  setBrands: (brands: any) => void
): Promise<InitErrorCode> => {
  try {
    await Font.loadAsync(KalibraFont);
  } catch (error) {
    addAnalyticsLog({ function: 'initializeResources', data: error, logType: 'error' });
    // TODO save data to store if fail nad use default font
  }

  try {
    const brandsResponse = await preAuth();
    setBrands({ ...brandsResponse, ...Brands });
  } catch (error) {
    addAnalyticsLog({ function: 'initializeResources', data: error, logType: 'error' });
    return InitErrorCode.PRE_AUTH;
  }

  try {
    //TODO move to store
    // load isFirstLoad
    const isFirstLoad = await getValue('isFirstLoad');
    setIsFirstLoad(isFirstLoad == '' || isFirstLoad == 'true');
  } catch (error) {
    addAnalyticsLog({ function: 'initializeResources', data: error, logType: 'error' });
  }

  return InitErrorCode.OK;
};

export function useAppInitializer() {
  const [isInitComplete, setIsInitComplete] = React.useState<boolean>(false);
  const [initErrorCode, setInitErrorCode] = React.useState<InitErrorCode>(InitErrorCode.OK);
  const setAppInit = useAppStore((state: ApplicationState) => state.setAppPreInit);
  const isAppInit = useAppStore((state: ApplicationState) => state.isAppPreInit);
  const isVersionChecked = useAppStore((state: ApplicationState) => state.isVersionChecked);
  const setViewPortWidth = useAppStore((state: ApplicationState) => state.setViewPortWidth);
  const { preAuth } = useHandshake();
  const { addAnalyticsLog } = useAnalytics('useAppInitializer.ts');
  const setBrands = useAppStore((state) => state.setBrands);

  const setFirstLoad = useSetFirstRun();

  //TODO Refactor view to not use width
  React.useEffect(() => {
    setViewPortWidth();

    const windowResizer = () => {
      setViewPortWidth();
    };

    if (Platform.OS === 'web') {
      window.addEventListener('resize', windowResizer);
      return () => window.removeEventListener('resize', windowResizer);
    }
  }, [setViewPortWidth]);

  // Load any resources or data that we need prior to rendering the app
  React.useEffect(() => {
    if (isAppInit || !isVersionChecked) {
      return;
    }

    try {
      SplashScreen.preventAutoHideAsync();
    } catch (error) {
      //Not important
    }

    initializeResources(setFirstLoad, preAuth, addAnalyticsLog, setBrands)
      .then((code) => {
        setInitErrorCode(code);
        setIsInitComplete(true);
        setAppInit();
      })
      .catch(() => {
        setInitErrorCode(InitErrorCode.UNKNOWN);
      });
  }, [setAppInit, isAppInit, setFirstLoad, preAuth, addAnalyticsLog, isVersionChecked, setBrands]);

  return {
    initErrorCode: initErrorCode,
    isInitComplete: isInitComplete
  };
}
