import Svg, { G, Path } from 'react-native-svg';
import React from 'react';
import { SimpleIconProps } from 'src/core';

const RestIcon = ({ size = 56, color = '#5F93F9' }: SimpleIconProps) => (
  <Svg width={`${size}px`} height={`${size}px`} viewBox="0 0 32 32">
    <G data-name="Layer 2">
      {/* <Path fillRule="evenodd" clipRule="evenodd" d="M7.24381 3.06615C4.07737 5.22723 1.99996 8.86473 1.99996 12.9853C1.99996 19.6128 7.37254 24.9853 14 24.9853C16.0149 24.9853 17.9129 24.4894 19.5796 23.6123C11.9871 23.0561 5.99996 16.7199 5.99996 8.98534C5.99996 6.88005 6.44418 4.87701 7.24381 3.06615ZM8.45826 0.854638C3.86075 2.95834 0.666626 7.59882 0.666626 12.9853C0.666626 20.3491 6.63616 26.3187 14 26.3187C17.0428 26.3187 19.8476 25.2994 22.0915 23.5836C22.1936 23.5056 22.2944 23.4261 22.3941 23.3452C22.5804 23.1941 22.7625 23.0381 22.9402 22.8774C23.0028 22.8208 23.0648 22.7636 23.1263 22.7058C23.3662 22.4805 23.1461 22.0927 22.8213 22.1455C22.7571 22.1559 22.6927 22.1659 22.6281 22.1754C22.393 22.2101 22.1559 22.2386 21.917 22.2608C21.8054 22.2712 21.6935 22.2802 21.5812 22.2878C21.279 22.3083 20.974 22.3187 20.6666 22.3187C13.3028 22.3187 7.33329 16.3491 7.33329 8.98534C7.33329 6.38812 8.07589 3.96435 9.36034 1.91476C9.42096 1.81804 9.48278 1.72215 9.54579 1.62711C9.67826 1.4273 9.81598 1.23125 9.95875 1.03919C9.99736 0.987232 10.0364 0.935569 10.0757 0.884199C10.2758 0.622947 10.038 0.246234 9.72625 0.351659C9.64673 0.37855 9.56754 0.406171 9.4887 0.434514C9.26176 0.516095 9.03768 0.603658 8.81664 0.697009C8.69627 0.747845 8.5768 0.800399 8.45826 0.854638Z" fill={color} />
      <Path fillRule="evenodd" clipRule="evenodd" d="M23.2174 6.68811L21.6668 1.66683L20.1161 6.68811L17.1162 8.11664L20.1293 9.55151L21.6668 13.9442L23.2042 9.55151L26.2174 8.11664L23.2174 6.68811ZM21.2174 7.64044L21.6668 6.18551L22.1161 7.64044L23.1162 8.11664L22.1293 8.58658L21.6668 9.90811L21.2042 8.58658L20.2174 8.11664L21.2174 7.64044Z" fill={color} />
      <Path d="M15.3333 15.0002L16 12.3335L16.6666 15.0002L18.6666 15.6668L16.6666 16.3335L16 19.0002L15.3333 16.3335L13.3333 15.6668L15.3333 15.0002Z" fill={color} />
      <Path d="M12 5.00016L12.6666 2.3335L13.3333 5.00016L15.3333 5.66683L13.3333 6.3335L12.6666 9.00016L12 6.3335L9.99996 5.66683L12 5.00016Z" fill={color} /> */}
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.62185 3.03283C4.03863 4.11337 2.99992 5.93212 2.99992 7.99242C2.99992 11.3061 5.68621 13.9924 8.99992 13.9924C10.0074 13.9924 10.9564 13.7445 11.7898 13.3059C7.99347 13.0278 4.99992 9.85971 4.99992 5.99242C4.99992 4.93978 5.22203 3.93826 5.62185 3.03283ZM6.22907 1.92707C3.93032 2.97893 2.33325 5.29917 2.33325 7.99242C2.33325 11.6743 5.31802 14.6591 8.99992 14.6591C10.5214 14.6591 11.9238 14.1494 13.0457 13.2915C13.0967 13.2525 13.1472 13.2128 13.197 13.1724C13.2901 13.0968 13.3812 13.0188 13.47 12.9384C13.5013 12.9101 13.5324 12.8816 13.5631 12.8527C13.683 12.74 13.573 12.5461 13.4106 12.5725C13.3785 12.5777 13.3463 12.5827 13.314 12.5875C13.1964 12.6048 13.0779 12.6191 12.9584 12.6302C12.9026 12.6354 12.8467 12.6398 12.7905 12.6437C12.6394 12.6539 12.4869 12.6591 12.3333 12.6591C8.65135 12.6591 5.66659 9.67432 5.66659 5.99242C5.66659 4.69382 6.03788 3.48193 6.68011 2.45714C6.71042 2.40877 6.74133 2.36083 6.77283 2.31331C6.83907 2.2134 6.90793 2.11538 6.97931 2.01935C6.99862 1.99337 7.01811 1.96754 7.03779 1.94186C7.13786 1.81123 7.01894 1.62287 6.86306 1.67559C6.8233 1.68903 6.78371 1.70284 6.74429 1.71701C6.63082 1.7578 6.51878 1.80158 6.40826 1.84826C6.34807 1.87368 6.28834 1.89996 6.22907 1.92707Z"
        fill={color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6087 4.84381L12.8333 2.33317L12.058 4.84381L10.558 5.55808L12.0646 6.27551L12.8333 8.47188L13.6021 6.27551L15.1087 5.55808L13.6087 4.84381ZM12.6087 5.31998L12.8333 4.59251L13.058 5.31998L13.558 5.55808L13.0646 5.79304L12.8333 6.45381L12.6021 5.79304L12.1087 5.55808L12.6087 5.31998Z"
        fill={color}
      />
      <Path
        d="M9.66659 8.99984L9.99992 7.6665L10.3333 8.99984L11.3333 9.33317L10.3333 9.6665L9.99992 10.9998L9.66659 9.6665L8.66659 9.33317L9.66659 8.99984Z"
        fill={color}
      />
      <Path
        d="M7.99992 3.99984L8.33325 2.6665L8.66659 3.99984L9.66659 4.33317L8.66659 4.6665L8.33325 5.99984L7.99992 4.6665L6.99992 4.33317L7.99992 3.99984Z"
        fill={color}
      />
    </G>
  </Svg>
);

export default RestIcon;
