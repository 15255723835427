import { CheckBox, Divider, Layout, Spinner, Text, useTheme } from '@ui-kitten/components';
import { GuidePager, GuideScreen } from 'src/components/scan/GuidePager';
import { Modal, Platform, StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native';
import { RootStackScreenProps, UIHelper as uh } from 'src/core';

import { HRVAvatar } from 'src/core/icons/scan/HRVAvatar';
import { HRVPhoneAvatar } from 'src/core/icons/scan/HRVPhoneAvatar';
import React from 'react';
import { ScanGuideDescription } from 'src/components/scan/ScanGuideDescription';
import { StackActions } from '@react-navigation/native';
import { useAnalytics } from 'src/core/hooks/useAnalytics';
import { useIsLightColorScheme } from 'src/core/hooks/useIsLightColorScheme';
import { useScanGuideSkip } from 'src/core/hooks/useScanGuideSkip';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';

const styles = StyleSheet.create({
  guideScreenContainer: { height: '100%', justifyContent: 'space-between', paddingTop: 24 },
  imageStyle: {
    position: 'absolute',
    height: uh.height() - 70,
    width: uh.currentViewPort(),
    resizeMode: 'cover'
  },
  imageContainer: { alignItems: 'center', marginTop: 24 },
  imageStyle2: {
    alignItems: 'center',
    marginTop: Platform.OS === 'ios' ? 24 : 0,
    paddingLeft: 64,
    height: '100%'
  },
  imageStyle3: {
    alignItems: 'center'
  },
  guideContentContainer: {
    height: uh.height() - 155,
    width: uh.currentViewPort(),
    justifyContent: 'flex-start'
  },
  subtitle: {
    fontSize: 22,
    textAlign: 'center',
    width: '60%',
    alignSelf: 'center',
    marginTop: 11
  },
  loading: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    height: uh.height(),
    zIndex: 999,
    top: 0,
    left: 0,
    width: uh.currentViewPort(),
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  text: {
    paddingVertical: Platform.OS === 'ios' ? 12 : 8,
    textAlign: 'center'
  },
  bottomButton: {
    bottom: 30,
    marginBottom: 11,
    marginLeft: 16,
    marginRight: 16
  },
  backdropStyle: { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
  divider: {
    width: '50%',
    alignSelf: 'center'
  }
});

const HRVGuideScreen = ({ navigation }: RootStackScreenProps<'HRVScanGuide'>) => {
  const { addAnalyticsLog } = useAnalytics('HRVScanGuideScreen.tsx');
  const { setScanGuideSkip } = useScanGuideSkip();
  const isLightTheme = useIsLightColorScheme();
  const th = useTheme();
  const [isLoading, setIsLoading] = React.useState(false);
  const [checkBox, setCheckBox] = React.useState(false);
  const dividerStyle: StyleProp<ViewStyle> = { ...styles.divider, backgroundColor: th['color-basic-500'] };
  const transMessage = {
    scanType: 'HRV',
    introText1: 'Measure your HRV with your phone camera',
    introText2: 'Place your fingertip over the rear-facing camera lens',
    introText3: 'Hold your hand steady and apply light pressure with your finger',
    introText4: 'Do the scan regularly to get Readiness and ANS Balance markers',
    dontShowAgain: "Don't show again",
    startScan: 'Start Scan'
  };
  const t = useTranslationFunc(transMessage);

  const HRVGuidePage1: GuideScreen = {
    renderGuideScreen: () => {
      return (
        <Layout level="2" style={styles.guideScreenContainer}>
          <ScanGuideDescription scanType={'HRV'} />
          <View style={styles.imageStyle2}>
            <HRVAvatar color={isLightTheme ? '#2E3A59' : 'white'} height={290} width={225} />
          </View>
        </Layout>
      );
    }
  };
  const HRVGuidePage2: GuideScreen = {
    renderGuideScreen: () => {
      return (
        <Layout level="2" style={styles.guideScreenContainer}>
          <View style={{ marginHorizontal: 36, paddingTop: 12 }}>
            <Text category="h6" style={{ textAlign: 'center' }}>
              {t('introText1')}
            </Text>

            <Text category="p1" style={styles.text}>
              {t('introText2')}
            </Text>
            <Divider style={dividerStyle} />

            <Text category="p1" style={styles.text}>
              {t('introText3')}
            </Text>
            <Divider style={dividerStyle} />

            <Text category="p1" style={styles.text}>
              {t('introText4')}
            </Text>
          </View>
          <View style={styles.imageStyle3}>
            <HRVPhoneAvatar color={isLightTheme ? '#2E3A59' : 'white'} />
          </View>
          <TouchableOpacity
            onPress={() => setCheckBox((checked) => !checked)}
            activeOpacity={1}
            style={[
              {
                flexDirection: 'row',
                alignSelf: 'flex-start',
                marginLeft: 24,
                marginBottom: 16
              }
            ]}>
            <CheckBox checked={checkBox} onChange={(checked) => setCheckBox(checked)} style={{ marginRight: 8 }} />
            <Text>{t('dontShowAgain')}</Text>
          </TouchableOpacity>
        </Layout>
      );
    }
  };

  const scan = async () => {
    setIsLoading(true);
    setScanGuideSkip(checkBox, 'HRV');

    // log Start HrvScan event
    addAnalyticsLog({ logType: 'SPRENHrvScanStart' });
    navigation.dispatch(StackActions.replace('HRVScan', {}));
    setIsLoading(false);
  };

  return (
    <Layout level="1" style={{ flex: 1 }}>
      {isLoading && (
        <Modal transparent={true}>
          <View style={styles.loading}>
            <Spinner size="giant" />
          </View>
        </Modal>
      )}
      <GuidePager
        guides={[HRVGuidePage1, HRVGuidePage2]}
        onLastBackPress={() => {
          navigation.goBack();
        }}
        onLastNextPress={async () => {
          scan();
        }}
        lastButtonTitle={t('startScan')}
      />
    </Layout>
  );
};

export default HRVGuideScreen;
