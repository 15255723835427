import { useCallback, useEffect, useState } from 'react';
import { useAnalytics } from './useAnalytics';
import { useAppStore } from '../store';
import { useIsUserLogged } from './useUser';
import { ApiError } from '../types/ApiResponse';
import { NotificationMessage } from '../types/Notification';
import NotificationMessangeService from 'src/api/notificationMessage';

export const useGetNottificationMessages = () => {
  const notitficationMessages = useAppStore((state) => state.notitficationMessages);
  return notitficationMessages;
};

interface UseNotitficationMessageProps {
  refreshing: boolean;
  setRefreshing: (refreshing: boolean) => void;
}

export const useNotitficationMessageEffect = ({ refreshing, setRefreshing }: UseNotitficationMessageProps) => {
  const setNotitficationMessages = useAppStore((state) => state.setNotitficationMessages);
  const { addAnalyticsLog } = useAnalytics('useNotificationMessage.ts');
  const [istNotitficationMessagesLoading, settNotitficationMessagesLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>();
  const isUserLogged = useIsUserLogged();

  const loadNotificationMessages = useCallback(
    (promise: Promise<NotificationMessage[]>) => {
      promise
        .then((messages) => {
          setNotitficationMessages([...messages]);
        })
        .catch((err: ApiError) => {
          if (!err.isAborted) {
            setError(err.error);
          }
          addAnalyticsLog({ function: 'loadNotificationMessages', data: err, logType: 'error' });
        })
        .finally(() => {
          settNotitficationMessagesLoading(false);
          setRefreshing(false);
        });
    },
    [addAnalyticsLog, setRefreshing, setNotitficationMessages]
  );

  useEffect(() => {
    if (isUserLogged) {
      const { promise, abort } = NotificationMessangeService.getNotificationMessages();
      loadNotificationMessages(promise);
      return abort;
    }
  }, [isUserLogged, loadNotificationMessages]);

  useEffect(() => {
    if (refreshing) {
      const { promise, abort } = NotificationMessangeService.getNotificationMessages();
      loadNotificationMessages(promise);
      return abort;
    }
  }, [refreshing, loadNotificationMessages]);

  return [istNotitficationMessagesLoading, error];
};

export const useNotitficationMessage = () => {
  const setNotitficationMessages = useAppStore((state) => state.setNotitficationMessages);
  const { addAnalyticsLog } = useAnalytics('useNotificationMessage.ts');
  const isUserLogged = useIsUserLogged();

  const loadNotificationMessages = useCallback(
    (promise: Promise<NotificationMessage[]>) => {
      promise
        .then((messages) => {
          setNotitficationMessages([...messages]);
        })
        .catch((err: ApiError) => {
          addAnalyticsLog({ function: 'loadNotificationMessages', data: err, logType: 'error' });
        });
    },
    [addAnalyticsLog, setNotitficationMessages]
  );

  useEffect(() => {
    if (isUserLogged) {
      const { promise, abort } = NotificationMessangeService.getNotificationMessages();
      loadNotificationMessages(promise);
      return abort;
    }
  }, [isUserLogged, loadNotificationMessages]);
};

export const useMarkAsReadNotification = () => {
  const { addAnalyticsLog } = useAnalytics('useNotificationMessage.ts');
  const [error, setError] = useState<any>();
  const [isMarkAsReadLoading, setIsMarkAsReadLoading] = useState<boolean>(false);
  const markAsRead = (notificationId: string) => {
    setIsMarkAsReadLoading(true);
    NotificationMessangeService.markAsRead(notificationId)
      .catch((err: ApiError) => {
        setError(err);
        addAnalyticsLog({ function: 'markAsRead', data: err, logType: 'error' });
      })
      .finally(() => {
        setIsMarkAsReadLoading(false);
      });
  };

  return [markAsRead, isMarkAsReadLoading, error];
};

export const useDeleteNotificationMessage = () => {
  const { addAnalyticsLog } = useAnalytics('useNotificationMessage.ts');
  const [error, setError] = useState<any>();
  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
  const deleteNotification = (notificationId: string) => {
    setIsDeleteLoading(true);
    NotificationMessangeService.deleteNotification(notificationId)
      .catch((err: ApiError) => {
        setError(err);
        addAnalyticsLog({ function: 'deleteNotification', data: err, logType: 'error' });
      })
      .finally(() => {
        setIsDeleteLoading(false);
      });
  };

  return [deleteNotification, isDeleteLoading, error];
};
