import { BackendApi } from 'src/api/shared';
import { ApiResponse } from 'src/core/types/ApiResponse';
import { HealthMarkerHistoryResponse } from 'src/core/types/HealthMarkerHistory';
import { ScoreService } from '../model';

export const WebScoreService: ScoreService = {
  getScoreSummary: () => {
    const abortController = new AbortController();
    return {
      promise: BackendApi.get('scores/score-summary', { signal: abortController.signal })
        .then((response) => {
          return response.data;
        })
        .catch((error) => Promise.reject({ error: error, isAborted: abortController.signal.aborted })),
      abort: () => {
        abortController.abort();
      }
    };
  },
  getPillarScoreHistories: (id: string) => {
    const abortController = new AbortController();
    return {
      promise: BackendApi.get(
        String(id) == '0' ? 'scores/score-total/history' : `scores/score-category/history/${id}`,
        {
          signal: abortController.signal
        }
      )
        .then((response) => {
          return response.data;
        })
        .catch((error) => Promise.reject({ error: error, isAborted: abortController.signal.aborted })),
      abort: () => {
        abortController.abort();
      }
    };
  },
  getPillarScoreDrivers: (id: string) => {
    const abortController = new AbortController();
    return {
      promise: BackendApi.get(`scores/subcategory/${id}`, { signal: abortController.signal })
        .then((response) => {
          return response.data;
        })
        .catch((error) => Promise.reject({ error: error, isAborted: abortController.signal.aborted })),
      abort: () => {
        abortController.abort();
      }
    };
  },
  getPillarActionTips: (id: string, isInsight: boolean) => {
    const abortController = new AbortController();
    return {
      promise: BackendApi.get(
        isInsight ? `action-item/recommended/category/${id}/tips` : `action-item/recommended/category/${id}`,
        { signal: abortController.signal }
      )
        .then((response) => {
          return response.data;
        })
        .catch((error) => Promise.reject({ error: error, isAborted: abortController.signal.aborted })),
      abort: () => {
        abortController.abort();
      }
    };
  },
  getMarkerScoreHistories: (
    key: string,
    startDate: string,
    endDate: string
  ): ApiResponse<HealthMarkerHistoryResponse> => {
    const abortController = new AbortController();
    return {
      promise: BackendApi.get('/health-markers/history', {
        params: {
          startDate: startDate,
          endDate: endDate,
          keys: key
        }
      })
        .then((response) => {
          return response.data;
        })
        .catch((error) => Promise.reject({ error: error, isAborted: abortController.signal.aborted })),
      abort: () => {
        abortController.abort();
      }
    };
  }
};
