import { getValue, setValue } from 'src/api/storage';
import { useCallback, useEffect } from 'react';

import { Appearance } from 'react-native';
import _ from 'lodash';
import { useAppStore } from '../store';
import Brands from 'brands.json';

export enum AppTheme {
  SYSTEM = 0,
  LIGHT,
  DARK
}

type ColorScheme = 'dark' | 'light';

const STORAGE_THEME_INDEX = 'themeIndex';
const DEFAULT_COLOR_SCHEME = 'light';

interface UseColorScheme {
  getColorScheme: () => ColorScheme;
  setColorScheme: (colorScheme: ColorScheme) => void;
}

const useColorScheme = (): UseColorScheme => {
  const theme = useAppStore((state) => state.theme);
  const setTheme = useAppStore((state) => state.setTheme);

  const setColorScheme = (colorScheme: ColorScheme): void => {
    if (theme !== colorScheme) {
      setTheme(colorScheme);
    }
  };

  const getColorScheme = (): ColorScheme => {
    return theme as ColorScheme;
  };

  return { setColorScheme, getColorScheme };
};

interface UseAppTheme {
  changeTheme: (theme: AppTheme) => void;
  getTheme: () => Promise<AppTheme>;
  getColorSchemeFromAppTheme: (theme: AppTheme) => ColorScheme;
}

export const useAppTheme = (): UseAppTheme => {
  const { setColorScheme } = useColorScheme();

  const getColorSchemeFromAppTheme = (theme: AppTheme): ColorScheme => {
    let themeColor: ColorScheme;
    if (theme === AppTheme.SYSTEM) {
      themeColor = (Appearance.getColorScheme()?.toString() as ColorScheme) ?? DEFAULT_COLOR_SCHEME;
    } else {
      themeColor = AppTheme[theme].toLowerCase() as ColorScheme;
    }
    return themeColor;
  };

  const changeTheme = (theme: AppTheme) => {
    setValue(STORAGE_THEME_INDEX, theme.toString());
    const themeColor = getColorSchemeFromAppTheme(theme);
    setColorScheme(themeColor);
  };

  const getTheme = async (): Promise<AppTheme> => {
    const themeIndex = await getValue(STORAGE_THEME_INDEX);
    let theme: AppTheme = AppTheme.SYSTEM;
    if (themeIndex) {
      const index = Number(themeIndex);
      if (index == AppTheme.LIGHT || index == AppTheme.DARK) {
        theme = index;
      }
    }
    return theme;
  };

  return { changeTheme, getTheme, getColorSchemeFromAppTheme };
};

export type InitThemeLegacyCallback = (shame: ColorScheme) => void;
type UseAppThemeLegacy = (actions: InitThemeLegacyCallback) => void;

// TODO remove and use useInitAppTheme
export const useInitAppThemeLegacy = (): UseAppThemeLegacy => {
  const { changeTheme } = useAppTheme();
  const getColorSchemeFromAppTheme = (theme: AppTheme): ColorScheme => {
    let themeColor: ColorScheme;
    if (theme === AppTheme.SYSTEM) {
      themeColor = (Appearance.getColorScheme()?.toString() as ColorScheme) ?? DEFAULT_COLOR_SCHEME;
    } else {
      themeColor = AppTheme[theme].toLowerCase() as ColorScheme;
    }
    return themeColor;
  };

  const getTheme = async (): Promise<AppTheme> => {
    const themeIndex = await getValue(STORAGE_THEME_INDEX);
    let theme: AppTheme = AppTheme.SYSTEM;
    if (themeIndex) {
      const index = Number(themeIndex);
      if (index == AppTheme.LIGHT || index == AppTheme.DARK) {
        theme = index;
      }
    }
    return theme;
  };

  const initThemeLegacy = useCallback(
    async (setTheme: InitThemeLegacyCallback) => {
      if (Brands.disabled_dark_mode) {
        setTheme('light');
        changeTheme(AppTheme.LIGHT);
        return;
      }
      const theme: AppTheme = await getTheme();
      const themeColor = getColorSchemeFromAppTheme(theme);
      setTheme(themeColor);
    },
    [changeTheme]
  );

  return initThemeLegacy;
};

export const useInitAppTheme = (): void => {
  const { getTheme } = useAppTheme();
  const { setColorScheme } = useColorScheme();

  useEffect(() => {
    const handleColorModeChange: Appearance.AppearanceListener = async (
      preferences: Appearance.AppearancePreferences
    ) => {
      if (!preferences.colorScheme) {
        return;
      }
      const theme = await getTheme();
      if (theme != AppTheme.SYSTEM) {
        return;
      }
      setColorScheme(preferences.colorScheme);
    };

    const listener = Appearance.addChangeListener(
      _.throttle(handleColorModeChange, 1000, {
        leading: false,
        trailing: true
      })
    );
    return () => listener.remove();
  }, [setColorScheme, getTheme]);
};
