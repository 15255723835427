import { Path, Svg } from 'react-native-svg';

import React from 'react';
import { SvgIconProp } from 'src/core/types/ScanIcons';

export const BloodworkGlassAvatar = ({ color, height = 200, width = 150 }: SvgIconProp) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 151 200" fill="none">
      <Path
        d="M109.5 107.5L97.5 88L85 107.5L79.5 117.5L80.5 126L85 132.5L92.5 137.5H102L109.5 132.5L114.5 126V117.5L109.5 107.5Z"
        fill="#46D7CB"
      />
      <Path
        d="M76.3088 29.629C77.6125 29.629 78.6706 28.5708 78.6706 27.2672L78.6721 2.36181C78.6721 1.0581 77.614 0 76.3103 0H2.45495C1.15124 0 0.0931471 1.05814 0.0931471 2.36181C0.926896 23.8366 -1.76843 29.629 2.45495 29.629H8.28973V168.88C8.28973 186.092 22.1907 200 39.3741 200C56.5346 200 70.4918 186.038 70.4918 168.88V157.636C78.6034 162.497 87.825 165.059 97.4268 165.059C126.437 165.059 150.039 141.459 150.039 112.447C150.039 83.4203 126.439 59.8045 97.4268 59.8045C87.8599 59.8045 78.6384 62.3695 70.4918 67.237V29.6282L76.3088 29.629ZM73.947 24.9037C73.6241 24.9037 63.2414 24.8977 63.9008 24.8977L63.9023 4.72377H73.9485L73.947 24.9037ZM49.1312 24.8977V4.72377H59.1774V24.8977H49.1312ZM44.4075 24.8977H34.3582V4.72377H44.4075V24.8977ZM29.6349 24.8977H19.5887V4.72377H29.6349V24.8977ZM4.81921 4.72377H14.8654V24.8977C14.5425 24.8977 4.15986 24.9037 4.81921 24.9037V4.72377ZM145.315 112.447C145.315 138.853 123.835 160.332 97.4304 160.332C70.6467 160.332 49.5453 138.509 49.5453 112.447C49.5453 86 70.9731 64.5267 97.4304 64.5267C123.835 64.5283 145.315 86.0244 145.315 112.447ZM65.767 70.4023C52.7089 80.2498 44.8185 95.9581 44.8185 112.447C44.8185 128.676 52.5572 144.501 65.767 154.464V168.88C65.767 183.435 53.9275 195.278 39.373 195.278C24.99 195.278 13.0139 183.662 13.0139 168.88V29.6299H65.7655L65.767 70.4023Z"
        fill={color}
      />
      <Path
        d="M97.4321 140.174C112.381 140.174 121.702 123.97 114.171 111.043H114.168L99.4878 85.8644C99.0664 85.1382 98.2887 84.6926 97.4489 84.6926C96.6091 84.6926 95.8344 85.1382 95.41 85.8613L80.7037 111.027C73.1602 123.939 82.4594 140.174 97.4321 140.174ZM84.7815 113.41L97.4441 91.7383L110.085 113.422C115.778 123.177 108.748 135.449 97.4321 135.449C86.1247 135.449 79.0713 123.186 84.7815 113.41Z"
        fill={color}
      />
    </Svg>
  );
};
