import { ScoreItem, StringHelper as sh, UIHelper as uh } from '../../../core';
import { Spinner, Text, useTheme } from '@ui-kitten/components';
import { StyleSheet, View, ViewProps, processColor, Platform } from 'react-native';

import { LineData } from 'react-native-charts-wrapper';
import React, { lazy, Suspense } from 'react';
import moment from 'moment';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';

//props
interface LineChartProps extends ViewProps {
  type: string;
  score: number;
  color: string;
  graphData: Array<ScoreItem>;
  isLoading: boolean;
}

// styles
const graphHeight = 180;
const styles = StyleSheet.create({
  container: {
    height: graphHeight
  },
  noGraphContainer: {
    height: 60
  },
  loading: {
    height: graphHeight,
    alignContent: 'center',
    alignSelf: 'center',
    padding: uh.h2DP(16)
  },
  areaChart: { height: 98 },
  xAxis: { marginHorizontal: -15, height: 20 },
  score: {
    marginRight: uh.w2DP(16)
  },
  scoreName: {
    marginRight: uh.w2DP(16),
    marginTop: uh.h2DP(4)
  },
  webText: {
    textAlign: 'center',
    fontSize: 20
  }
});

const LineChart = ({ type, score, color, graphData, isLoading }: LineChartProps) => {
  const th = useTheme();
  const transMessage = {
    noGraphData: 'No graph data',
    pillarChartsAreDisabled: 'Pillar charts are disabled on the web app',
    pillarScore: '{{type}} score',
    scorePercent: '{{score}}%'
  };
  const t = useTranslationFunc(transMessage);

  if (isLoading) {
    return (
      <View style={styles.loading}>
        <Spinner status="primary" />
      </View>
    );
  }

  if (graphData.length == 0) {
    return (
      <View>
        <Text category="c1" appearance="hint" style={styles.scoreName}>
          {t('pillarScore', { type: sh.capitalize(type) })}
        </Text>
        <Text category="h6" style={styles.score}>
          {t('scorePercent', { score: score })}
        </Text>
        <View style={styles.noGraphContainer}>
          <Text appearance="hint" style={{ textAlign: 'center' }}>
            {t('noGraphData')}
          </Text>
        </View>
      </View>
    );
  }

  if (Platform.OS === 'android' && graphData.length === 1)
    graphData.push({
      date: moment(graphData[0].date).subtract(135, 'seconds').toDate(),
      value: graphData[0].value
    });

  // sort chart data's date by asc
  const chartData = graphData.sort(function (a, b) {
    return moment(a.date).diff(b.date, 'seconds');
  });

  // group chart data by date
  chartData.reduce((prev: ScoreItem[], curr: ScoreItem): ScoreItem[] => {
    if (prev[prev.length - 1]?.date != curr.date) {
      return [...prev, { date: curr.date, value: curr.value }];
    }
    if (curr.value > prev[prev.length - 1].value) {
      prev[prev.length - 1].value = curr.value;
    }
    return prev;
  }, []);

  const minValue = chartData.map((x) => x.value).sort((a, b) => a - b)[0];
  const maxValue = chartData.map((x) => x.value).sort((a, b) => b - a)[0];
  const range = maxValue - minValue;

  const lineData: LineData = {
    dataSets: [
      {
        values: chartData.map((item) => {
          return {
            x: moment(item.date).toDate().getTime(),
            y: item.value
          };
        }),
        label: '',
        config: {
          mode: 'LINEAR',
          drawValues: false,
          lineWidth: 2,
          drawCircles: true,
          circleColor: processColor(color),
          drawCircleHole: true,
          circleHoleColor: processColor('white'),
          circleRadius: 6,
          highlightEnabled: Platform.OS !== 'android',
          drawVerticalHighlightIndicator: range !== 0,
          highlightColor: processColor(color),
          color: processColor(color),
          drawFilled: true,
          fillGradient: {
            orientation: 'BOTTOM_TOP',
            colors: [processColor(th['background-basic-color-1']), processColor(color)],
            positions: [0, 0.7]
          },
          fillAlpha: 1000
        }
      }
    ]
  };

  if (Platform.OS === 'web') {
    return (
      <View>
        <Text category="c1" appearance="hint" style={styles.scoreName}>
          {t('pillarScore', { type: sh.capitalize(type) })}
        </Text>
        <Text category="h6" style={styles.score}>
          {t('scorePercent', { score: score })}
        </Text>
        <View style={styles.container}>
          <Text style={{ ...styles.webText, ...{ color: th['text-basic-color'] } }}>
            {t('pillarChartsAreDisabled')}
          </Text>
        </View>
      </View>
    );
  } else {
    const PillarLineChart = lazy(() => import('../../../components/shared/PillarLineChart'));
    return (
      <View>
        <View style={styles.container}>
          <Suspense fallback={<Spinner status="primary" />}>
            <PillarLineChart
              style={{ flex: 1, height: 98 }}
              data={lineData}
              animation={{
                durationX: 500,
                easingX: 'Linear'
              }}
              legend={false}
              marker={{
                enabled: false
              }}
              pinchZoom={false}
              highlights={range === 0 ? [{ x: 0 }] : undefined}
              touchEnabled={false}
              xAxis={{
                enabled: true,
                drawLabels: true,
                position: 'BOTTOM',
                drawGridLines: false,
                textSize: 10,
                textColor: processColor(th['text-hint-color']),
                valueFormatter: 'date',
                timeUnit: 'MILLISECONDS',
                valueFormatterPattern: 'MMM d',
                labelCount: chartData.length <= 5 ? chartData.length : 5
              }}
              yAxis={{
                left: { enabled: false },
                right: { enabled: false }
              }}
            />
          </Suspense>
        </View>
      </View>
    );
  }
};

export default LineChart;
