import { Circle, Path, Svg } from 'react-native-svg';

import React from 'react';
import { SvgIconProp } from 'src/core/types/ScanIcons';

export const SmileIcon = ({ color = 'green', height = 20, width = 20, strokeWidth = 0 }: SvgIconProp) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 24 24" stroke={color} strokeWidth={strokeWidth} fill={'none'}>
      <Circle cx="12" cy="12" r="9" stroke={color} strokeWidth="2" />
      <Circle cx="9.0835" cy="9.9165" r="1.25" fill={color} />
      <Circle cx="14.9165" cy="9.9165" r="1.25" fill={color} />
      <Path
        d="M15.3332 14.5C15.1519 14.9377 14.8861 15.3355 14.5511 15.6705C14.216 16.0055 13.8183 16.2713 13.3805 16.4526C12.9428 16.6339 12.4736 16.7273 11.9998 16.7273C11.526 16.7273 11.0569 16.6339 10.6191 16.4526C10.1814 16.2713 9.78365 16.0055 9.44861 15.6705C9.11358 15.3355 8.84782 14.9377 8.6665 14.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </Svg>
  );
};
