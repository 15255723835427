import { CheckBox, Layout, Text } from '@ui-kitten/components';
import { StyleSheet, View, ViewProps } from 'react-native';

import { NewHealthMarker } from 'src/core/types/HealthMarkerReport';
import { PillarContainer } from 'src/components/home';
import React from 'react';
import { UIHelper as uh } from '../../../core';

//props
interface AddFavoriteItemProps extends ViewProps {
  biomarker: NewHealthMarker;
  accessibilityLabel?: string;
  testID?: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  style?: any;
}

// styles
const styleContainer = StyleSheet.create({
  container: {
    padding: uh.h2DP(16),
    flexDirection: 'column'
  },
  content: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  checkboxName: { marginLeft: 8 }
});

const AddFavoriteItem = ({ biomarker, accessibilityLabel, testID, checked, onChange, style }: AddFavoriteItemProps) => {
  // view
  return (
    <Layout
      style={[styleContainer.container, style]}
      accessible={true}
      accessibilityLabel={accessibilityLabel}
      testID={testID}>
      <View style={styleContainer.content}>
        <CheckBox checked={checked} status="basic" onChange={onChange}>
          {(evaProps) => (
            <Text {...evaProps} style={styleContainer.checkboxName}>
              {biomarker.name}
            </Text>
          )}
        </CheckBox>
        {biomarker.pillar != null && biomarker.pillar.length > 0 && (
          <PillarContainer type={biomarker.pillar.toLocaleLowerCase()} size="large" />
        )}
      </View>
    </Layout>
  );
};

export default AddFavoriteItem;
