export enum InitErrorCode {
  OK = 0,
  UNKNOWN = 1,
  PRE_AUTH = 2,
  AMPLIFY = 3,
  POST_AUTH = 4,
  TENANT = 5,
  INVALID_TOKEN = 6,
  USER_SETTINGS = 7
}
