import Svg, { G, Path, Rect } from 'react-native-svg';
import React from 'react';
import { SimpleIconProps } from 'src/core';

const AddIcon = ({ size = 48, color = '#46D7CB' }: SimpleIconProps) => (
  <Svg width={`${size}px`} height={`${size}px`} viewBox={'0 0 48 48'}>
    <G data-name="Layer 2">
      <G data-name="add-icon">
        <Rect width="48" height="48" rx="8" fill={color} />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31 23H25V17C25 16.447 24.552 16 24 16C23.448 16 23 16.447 23 17V23H17C16.448 23 16 23.447 16 24C16 24.553 16.448 25 17 25H23V31C23 31.553 23.448 32 24 32C24.552 32 25 31.553 25 31V25H31C31.552 25 32 24.553 32 24C32 23.447 31.552 23 31 23Z"
          fill="white"
        />
      </G>
    </G>
  </Svg>
);

export default AddIcon;
