import { Button, Divider, Input, Text, useTheme } from '@ui-kitten/components';
import { Config, UIHelper as uh } from '../../../core';
import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { getValue, setValue } from 'src/api/storage';

import BackendApi from 'src/api/shared/BackendApi';
import Brands from 'brands.json';
import { TenantDropdownList } from 'src/components/settings';
import { useAppStore } from 'src/core/store';

const ConnectedBackend = () => {
  const th = useTheme();
  const theme = useAppStore((state) => state.theme);

  const styleContainer = StyleSheet.create({
    backendView: { flex: 1, justifyContent: 'space-between', minHeight: 400 },
    input: { backgroundColor: uh.getHex(th, theme, 'color-basic-100', 'color-basic-1100') },
    caption: { marginBottom: uh.h2DP(4) },
    text: {
      maxHeight: '80%',
      textAlignVertical: 'top',
      marginLeft: uh.w2DP(14)
    },
    refreshButton: {
      marginTop: uh.h2DP(10),
      marginBottom: uh.h2DP(10)
    }
  });

  const [backendUrl, setBackendUrl] = useState<string>('');
  const [selectedServerIndex, setSelectedServerIndex] = useState<number>(0);
  const [customURL, setCustomURL] = useState<string>('');
  const [currentURL, setCurrentURL] = useState<string | undefined>(BackendApi.defaults.baseURL);

  const servers = ['stage', 'preprod', 'dbbench', 'production'];

  const changeServer = (index: number) => {
    setSelectedServerIndex(index);
    setCustomURL('');
  };

  const handleSave = () => {
    setValue('backend_url', backendUrl);
    setValue('backend_server_idx', selectedServerIndex.toString());
    BackendApi.defaults.baseURL = backendUrl;

    if (customURL === '') setValue('backend_custom_url', '');
    else setValue('backend_custom_url', customURL);
    setCurrentURL(backendUrl);
  };

  useEffect(() => {
    // Setting initial values for dropdowns

    const baseUrl = BackendApi.defaults.baseURL ?? '';

    if (baseUrl.includes('stage')) {
      setSelectedServerIndex(0);
    } else if (baseUrl.includes('preprod')) {
      setSelectedServerIndex(1);
    } else if (baseUrl.includes('dbbench')) {
      setSelectedServerIndex(2);
    } else {
      setSelectedServerIndex(3);
    }

    (async () => {
      const customIdx = await getValue('backend_custom_url');

      if (customIdx) setCustomURL(customIdx);
    })();
  }, []);

  useEffect(() => {
    if (customURL === '') {
      if (selectedServerIndex === 3) setBackendUrl(Config.BACKEND_API_ENDPOINT);
      else setBackendUrl(`https://${servers[selectedServerIndex]}.dev.${Brands.app_prefix}.ai/api/`);
    } else setBackendUrl(customURL);

    // eslint-disable-next-line
  }, [changeServer, customURL]);

  return (
    <View style={styleContainer.backendView}>
      <View>
        <Text
          category={'s1'}
          accessible={true}
          accessibilityLabel="Connected backend label"
          testID="connected_backend_label">
          Connected backend
        </Text>
        <Divider
          appearance="default"
          style={{ marginTop: 5, marginBottom: 15, backgroundColor: th['color-primary-500'] }}
        />
      </View>
      <TenantDropdownList
        caption="Server"
        data={servers}
        selectedIndex={selectedServerIndex}
        selectItem={changeServer}></TenantDropdownList>
      <Input
        size="large"
        style={[styleContainer.input]}
        label="Custom URL"
        value={customURL}
        onChangeText={(text: string) => setCustomURL(text)}
        accessible={true}
        accessibilityLabel="Custom URL input"
        testID="custom_url_input"
      />
      <Text style={styleContainer.caption} category="label" appearance="hint">
        Current backend URL
      </Text>
      <Text
        style={styleContainer.text}
        accessible={true}
        accessibilityLabel="Current URL label"
        testID="curr_url_label">
        {currentURL}
      </Text>
      <Button
        status="primary"
        onPress={() => handleSave()}
        style={styleContainer.refreshButton}
        accessible={true}
        accessibilityLabel="Save developer settings button"
        testID="save_dev_btn">
        Save backend URL
      </Button>
    </View>
  );
};

export default ConnectedBackend;
