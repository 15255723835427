import ChatService from 'src/api/chat';
import { useCallback } from 'react';

interface UseChat {
  uploadMedia: (file: string) => Promise<void>;
  getMedia: (mediaId: string) => Promise<string>;
}

export const useChat = (): UseChat => {
  const uploadMedia = useCallback((file: string) => {
    return ChatService.postMedia(file).promise;
  }, []);
  const getMedia = useCallback((mediaId: string): Promise<string> => {
    return ChatService.getMedia(mediaId).promise;
  }, []);

  return { uploadMedia, getMedia };
};
