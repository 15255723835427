import Svg, { G, Path } from 'react-native-svg';
import React from 'react';
import { SimpleIconProps } from 'src/core';

const DeleteIcon = ({ size = 20, color = '#8F9BB3' }: SimpleIconProps) => (
  <Svg width={`${size}px`} height={`${size}px`} viewBox={'0 0 20 20'}>
    <G data-name="Layer 2">
      <G data-name="delete-icon">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.33341 13.3332C8.33341 13.7915 7.95841 14.1665 7.50008 14.1665C7.04175 14.1665 6.66675 13.7915 6.66675 13.3332V9.99984C6.66675 9.5415 7.04175 9.1665 7.50008 9.1665C7.95841 9.1665 8.33341 9.5415 8.33341 9.99984V13.3332ZM13.3334 13.3332C13.3334 13.7915 12.9584 14.1665 12.5001 14.1665C12.0417 14.1665 11.6667 13.7915 11.6667 13.3332V9.99984C11.6667 9.5415 12.0417 9.1665 12.5001 9.1665C12.9584 9.1665 13.3334 9.5415 13.3334 9.99984V13.3332ZM15.0001 15.8332C15.0001 16.2923 14.6267 16.6665 14.1667 16.6665H5.83342C5.37341 16.6665 5.00008 16.2923 5.00008 15.8332V6.6665H15.0001V15.8332ZM8.33341 3.6065C8.33341 3.47734 8.51175 3.33317 8.75008 3.33317H11.2501C11.4884 3.33317 11.6667 3.47734 11.6667 3.6065V4.99984H8.33341V3.6065ZM17.5001 4.99984H16.6667H13.3334V3.6065C13.3334 2.5365 12.3992 1.6665 11.2501 1.6665H8.75008C7.60091 1.6665 6.66675 2.5365 6.66675 3.6065V4.99984H3.33341H2.50008C2.04175 4.99984 1.66675 5.37484 1.66675 5.83317C1.66675 6.2915 2.04175 6.6665 2.50008 6.6665H3.33341V15.8332C3.33341 17.2115 4.45508 18.3332 5.83342 18.3332H14.1667C15.5451 18.3332 16.6667 17.2115 16.6667 15.8332V6.6665H17.5001C17.9584 6.6665 18.3334 6.2915 18.3334 5.83317C18.3334 5.37484 17.9584 4.99984 17.5001 4.99984Z"
          fill={color}
        />
      </G>
    </G>
  </Svg>
);

export default DeleteIcon;
