import { APP_BRANCH, BACKEND_API_ENDPOINT, DEBUG_MODE, DEVELOPMENT_MODE, GIT_COMMIT, HANDSHAKE_API_KEY } from '@env';

export type ConfigType = {
  BACKEND_API_ENDPOINT: string;
  COGNITO_IDENTITY_POOL_ID: string;
  AWS_REGION: string;
  COGNITO_USER_POOL_ID: string;
  COGNITO_USER_POOL_WEB_CLIENT_ID: string;
  USER_FILES_S3_BUCKET: string;
  ONESIGNAL_APP_ID: string;
  SLACK_WEB_HOOK_URL: string;
  AMPLITUDE_API_KEY: string;
  DEBUG_MODE: boolean;
  TERRA_DEV_ID: string;
  TERRA_API_KEY: string;
  AHI_DEV_MULTISCAN_TOKEN: string;
  AHI_DEV_MULTISCAN_AUTHZ_SALT: string;
  SPREN_API_URL: string;
  SPREN_API_KEY: string;
  DEVELOPMENT_MODE: boolean;
  GIT_COMMIT: string;
  APP_BRANCH: string;
  HANDSHAKE_API_KEY: string;
  COGNITO_DOMAIN: string;
  COGNITO_REDIRECT_SIGNIN: string;
  COGNITO_REDIRECT_SIGNOUT: string;
  COGNITO_REDIRECT_SIGNIN_WEB: string;
  COGNITO_REDIRECT_SIGNOUT_WEB: string;
};

export const Config: ConfigType = {
  BACKEND_API_ENDPOINT: BACKEND_API_ENDPOINT,
  COGNITO_IDENTITY_POOL_ID: '-loaded-from-the-backend-',
  AWS_REGION: '-loaded-from-the-backend-',
  COGNITO_USER_POOL_ID: '-loaded-from-the-backend-',
  COGNITO_USER_POOL_WEB_CLIENT_ID: '-loaded-from-the-backend-',
  USER_FILES_S3_BUCKET: '-loaded-from-the-backend-',
  ONESIGNAL_APP_ID: '-loaded-from-the-backend-',
  SLACK_WEB_HOOK_URL: '-loaded-from-the-backend-',
  AMPLITUDE_API_KEY: '-loaded-from-the-backend-',
  DEBUG_MODE: DEBUG_MODE === 'true' ? true : false,
  TERRA_DEV_ID: '-loaded-from-the-backend-',
  TERRA_API_KEY: '-loaded-from-the-backend-',
  AHI_DEV_MULTISCAN_TOKEN: '-loaded-from-the-backend-',
  AHI_DEV_MULTISCAN_AUTHZ_SALT: '-loaded-from-the-backend-',
  SPREN_API_URL: '-loaded-from-the-backend-',
  SPREN_API_KEY: '-loaded-from-the-backend-',
  DEVELOPMENT_MODE: DEVELOPMENT_MODE === 'true' ? true : false,
  GIT_COMMIT: GIT_COMMIT || 'unknown',
  APP_BRANCH: APP_BRANCH || 'unknown',
  HANDSHAKE_API_KEY: HANDSHAKE_API_KEY || '',
  COGNITO_DOMAIN: '-loaded-from-the-backend-',
  COGNITO_REDIRECT_SIGNIN: '-loaded-from-the-backend-',
  COGNITO_REDIRECT_SIGNOUT: '-loaded-from-the-backend-',
  COGNITO_REDIRECT_SIGNIN_WEB: '-loaded-from-the-backend-',
  COGNITO_REDIRECT_SIGNOUT_WEB: '-loaded-from-the-backend-'
};
