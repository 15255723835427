import { Spinner, Text, useTheme } from '@ui-kitten/components';
import { StyleSheet, View } from 'react-native';

import { DynamicCardBody } from 'src/core/types/DynamicCard';
import DynamicCardIcons from './components/DynamicCardIcons';
import DynamicCardLayout from './DynamicCardLayout';
import ForwardIcon from './components/ForwardIcon';
import { MarkdownText } from 'src/components/kalichat';
import React from 'react';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { UIHelper as uh } from 'src/core';
import { useIsLightColorScheme } from 'src/core/hooks/useIsLightColorScheme';

interface DynamicCardProps {
  card: DynamicCardBody;
  onClick: (card: DynamicCardBody) => void;
}

const DynamicCard = ({ card, onClick }: DynamicCardProps): JSX.Element => {
  const th = useTheme();
  const isLightTheme = useIsLightColorScheme();

  const styleContainer = StyleSheet.create({
    loadingContainer: {
      padding: uh.h2DP(16),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    cardText: { color: isLightTheme ? th['color-basic-900'] : th['color-basic-100'], fontWeight: 'bold' }
  });

  if (card.isLoading) {
    return (
      <View style={styleContainer.loadingContainer}>
        <Spinner />
      </View>
    );
  }

  return (
    <TouchableOpacity
      onPress={() => {
        onClick(card);
      }}>
      <DynamicCardLayout
        layoutLevel="2"
        style={{ paddingVertical: 16 }}
        accessibilityLabel="dynamic card"
        testID="dynamic-card">
        <View style={{ flexDirection: 'row', width: '90%' }}>
          <DynamicCardIcons
            category={card.category}
            height={36}
            width={36}
            fill={th['color-primary-500']}
            isLightTheme={isLightTheme}
            th={th}
          />
          <View style={{ justifyContent: 'center', marginTop: -10, maxWidth: '85%' }}>
            <MarkdownText
              key={String(isLightTheme)}
              styles={{
                text: styleContainer.cardText,
                em: styleContainer.cardText
              }}>
              {card.text}
            </MarkdownText>
            {card.label && (
              <Text category="label" appearance="hint" style={{ paddingTop: 6, marginTop: 0 }}>
                {card.label}
              </Text>
            )}
          </View>
        </View>
        <ForwardIcon color={isLightTheme ? 'black' : 'white'} />
      </DynamicCardLayout>
    </TouchableOpacity>
  );
};

export default DynamicCard;
