import { StyleSheet, View } from 'react-native';

import React from 'react';
import { Text } from '@ui-kitten/components';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';

const ConversationsEmptyList = () => {
  const styles = StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center'
    }
  });

  const transMessages = {
    emptyMessage: 'No active conversations'
  };

  const t = useTranslationFunc(transMessages);

  return (
    <View style={styles.container}>
      <Text category="p1">{t('emptyMessage')}</Text>
    </View>
  );
};

export default ConversationsEmptyList;
