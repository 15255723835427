import Action, { BasicActionProps } from './Action';
import React, { PropsWithChildren } from 'react';
import { StyleSheet, View } from 'react-native';

import { ActionItem } from 'src/core/types/ActionItem';
import { Text } from '@ui-kitten/components';
import moment from 'moment';

interface ActionListProps extends BasicActionProps {
  headerText?: string;
  simpleList?: boolean;
  actions: ActionItem[] | ActionItem[][];
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    gap: 15
  }
});

interface ActionListLayoutProps {
  headerText?: string;
}

const ActionListLayout = ({ children, headerText }: PropsWithChildren<ActionListLayoutProps>) => {
  return (
    <View style={styles.container}>
      {headerText && <Text category="h6">{headerText}</Text>}
      {children}
    </View>
  );
};

const ActionList = ({ simpleList = true, actions, headerText, ...rest }: ActionListProps) => {
  if (!actions?.length) {
    return <></>;
  }

  if (simpleList) {
    const actionsToShow: ActionItem[] = (actions as ActionItem[]).sort((a, b) => moment(a.when).diff(b.when, 'second'));
    return (
      <ActionListLayout headerText={headerText}>
        {actionsToShow.map((action) => (
          <Action key={action.id} data={[action]} {...rest} />
        ))}
      </ActionListLayout>
    );
  }

  const actionsToShow: ActionItem[][] = actions as ActionItem[][];

  return (
    <ActionListLayout headerText={headerText}>
      {actionsToShow.map((action) => (
        <Action key={action[0].id} data={action} {...rest} />
      ))}
    </ActionListLayout>
  );
};

export default ActionList;
