import Svg, { G, Path } from 'react-native-svg';
import React from 'react';
import { SimpleIconProps } from 'src/core';

const NourishIcon = ({ size = 48, color = '#A83440' }: SimpleIconProps) => (
  <Svg width={`${size}px`} height={`${size}px`} viewBox="0 0 32 32">
    <G data-name="Layer 2">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.09464 5.00172C2.15718 6.12301 2.47633 6.81714 2.9437 7.24489C2.7155 7.41485 2.5832 7.70014 2.63178 8.0086C2.87026 9.52301 3.46649 12.7263 4.68872 16.3371C4.93163 17.0548 5.90184 17.0548 6.14476 16.3371C7.36697 12.7263 7.96322 9.52301 8.20172 8.0086C8.25026 7.70014 8.11797 7.41485 7.88976 7.24485C8.35713 6.81714 8.67626 6.12301 8.7388 5.00172C8.75163 4.77197 8.56288 4.58272 8.3328 4.58601C7.65443 4.59576 7.11563 4.63697 6.6953 4.77826C6.5218 4.3206 6.17151 3.8885 5.64434 3.49404C5.50968 3.39326 5.32376 3.39326 5.18909 3.49404C4.66622 3.88529 4.3173 4.31355 4.14239 4.7671C3.7342 4.64672 3.19969 4.59897 2.50173 4.58676C2.27164 4.58272 2.08182 4.77197 2.09464 5.00172ZM6.20043 5.82376L5.91609 5.07364C5.83734 4.86597 5.68347 4.63243 5.41672 4.3861C5.15276 4.62985 4.99934 4.86105 4.91988 5.06701L4.64347 5.78368L3.90666 5.56639C3.6893 5.5023 3.39251 5.45951 2.97554 5.43672C3.09942 6.2401 3.39719 6.57964 3.66864 6.75522C4.05677 7.00622 4.6223 7.0826 5.40426 7.08339H5.42918C6.21118 7.0826 6.77668 7.00622 7.1648 6.75522C7.43651 6.57947 7.73459 6.23947 7.85826 5.43443C7.45418 5.4556 7.17051 5.49768 6.96084 5.56814L6.20043 5.82376ZM3.46093 7.91672H7.37255C7.25318 8.66981 7.04593 9.83306 6.71847 11.2501H5.00001V12.0834H6.51876C6.23097 13.2427 5.86855 14.5344 5.41672 15.8876C5.26913 15.4455 5.13105 15.0099 5.00201 14.5834H5.41668V13.7501H4.75776C4.3588 12.3442 4.06031 11.0617 3.84212 10.0001H5.00001V9.16672H3.67812C3.586 8.67664 3.51458 8.25526 3.46093 7.91672Z"
        fill={color}
      />
      <Path
        d="M15.9344 11.679C16.1576 11.7349 16.2934 11.9611 16.2375 12.1843C15.9919 13.167 15.2503 13.9085 14.2677 14.1542C14.0445 14.21 13.8182 14.0742 13.7625 13.851C13.7066 13.6277 13.8424 13.4015 14.0656 13.3457C14.7497 13.1747 15.2581 12.6662 15.4291 11.9822C15.4849 11.759 15.7111 11.6232 15.9344 11.679Z"
        fill={color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6045 8.76144C13.1425 7.68107 13.2226 6.53336 14.0446 5.71132L13.4554 5.12207C12.4848 6.09261 12.3162 7.36265 12.6418 8.52953C10.7352 7.66695 8.75 9.25324 8.75 11.6667C8.75 13.9679 10.802 15.8334 13.3333 15.8334C15.8646 15.8334 17.9167 13.9679 17.9167 11.6667C17.9167 9.07495 15.6273 7.43711 13.6045 8.76144ZM13.3333 10.0255L12.8127 9.60907C12.0386 8.98974 11.2759 9.02415 10.6983 9.3809C10.0877 9.75807 9.58333 10.5657 9.58333 11.6667C9.58333 13.4347 11.1857 15 13.3333 15C15.4809 15 17.0833 13.4347 17.0833 11.6667C17.0833 10.5657 16.579 9.75807 15.9683 9.3809C15.3907 9.02415 14.628 8.98974 13.8539 9.60907L13.3333 10.0255Z"
        fill={color}
      />
      <Path
        d="M14.1667 7.49984C15.4167 7.49984 16.25 6.6665 16.25 5.4165C15 5.4165 14.1667 6.24984 14.1667 7.49984Z"
        fill={color}
      />
    </G>
  </Svg>
);

export default NourishIcon;
