import { useTheme } from '@ui-kitten/components';
import React from 'react';
import { ViewProps } from 'react-native';
import ConnectIcon from './icons/ConnectIcon';
import DietIcon from './icons/DietIcon';
import GrowIcon from './icons/GrowIcon';
import LifeStyleIcon from './icons/LifeStyleIcon';
import MoveIcon from './icons/MoveIcon';
import NourishIcon from './icons/NourishIcon';
import ReflectIcon from './icons/ReflectIcon';
import RestIcon from './icons/RestIcon';
import SupplementIcon from './icons/SupplementIcon';

//props
interface ActionTipIconProps extends ViewProps {
  pillar: string;
  actionType: string;
}

const ActionTipIcon = ({ pillar, actionType }: ActionTipIconProps) => {
  const th = useTheme();
  const color = th['color-' + pillar.toLowerCase() + '-600'];

  if (actionType === 'Diet') {
    return <DietIcon color={color} />;
  } else if (actionType === 'Supplement') {
    return <SupplementIcon color={color} />;
  } else if (actionType === 'LifeStyle') {
    return <LifeStyleIcon color={color} />;
  }

  if (pillar === 'Rest') {
    return <RestIcon color={color} />;
  } else if (pillar === 'Nourish') {
    return <NourishIcon color={color} />;
  } else if (pillar === 'Move') {
    return <MoveIcon color={color} />;
  } else if (pillar === 'Connect') {
    return <ConnectIcon color={color} />;
  } else if (pillar === 'Grow') {
    return <GrowIcon color={color} />;
  } else if (pillar === 'Reflect') {
    return <ReflectIcon color={color} />;
  }

  return <></>;
};

export default ActionTipIcon;
