import React from 'react';
import { SafeAreaView, StyleSheet } from 'react-native';
import { Layout } from '@ui-kitten/components';
import NotificationContainer from 'src/features/notification/NotificationContainer';

//styles
const styleContainer = StyleSheet.create({
  screenContainer: { flex: 1 },
  safeAreaView: { flex: 1 }
});

const NotificationScreen = () => {
  //view
  return (
    <Layout level="1" style={[styleContainer.screenContainer]}>
      <SafeAreaView style={styleContainer.screenContainer}>
        <NotificationContainer />
      </SafeAreaView>
    </Layout>
  );
};

export default NotificationScreen;
