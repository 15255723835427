import { Text, useTheme } from '@ui-kitten/components';
import { createStackNavigator, StackNavigationOptions } from '@react-navigation/stack';
import React from 'react';
import { RootStackChatParamList } from 'src/core/types/ChatNavigationParamList';
import ChatConversations from '../chat/ChatConversations';
import ChatScreen from '../chat/ChatScreen';
import HeaderLeft from './components/HeaderLeft';
import HeaderRight from './components/HeaderRight';
import { UIHelper as uh } from '../../core';
import { useAppStore } from 'src/core/store';

const ChatNavigation = (): JSX.Element => {
  const th = useTheme();
  const theme = useAppStore((state) => state.theme);
  const condColors = {
    header: uh.getHex(th, theme, 'color-basic-100', 'color-basic-1100'),
    headerLeftTint: uh.getHex(th, theme, 'color-basic-900', 'color-basic-100'),
    iconTint: th['color-primary-500']
  };
  const ChatStack = createStackNavigator<RootStackChatParamList>();
  const headerOptions = (title: string): StackNavigationOptions => {
    return {
      headerTitle: () => (
        <Text status="basic" category="s2">
          {title}
        </Text>
      ),
      headerRight: () => <HeaderRight />,
      headerLeft: () => <HeaderLeft />,
      headerTitleAlign: 'center',
      headerStyle: {
        backgroundColor: condColors.header
      }
    };
  };

  return (
    <ChatStack.Navigator>
      <ChatStack.Screen name="Conversations" options={{ ...headerOptions('Chat') }} component={ChatConversations} />
      <ChatStack.Screen
        name="ChatScreen"
        options={({ route }) => ({ ...headerOptions(route.params.name) })}
        component={ChatScreen}
      />
    </ChatStack.Navigator>
  );
};

export default ChatNavigation;
