import { Divider, Icon, Layout, Text, useTheme } from '@ui-kitten/components';
import { HealthMarkerGroup, NewHealthMarker } from 'src/core/types/HealthMarkerReport';
import { LayoutAnimation, ScrollView, StyleSheet, TouchableOpacity, View, ViewProps } from 'react-native';
import React, { RefObject, useState } from 'react';

import AssessmentBiomarker from './AssessmentBiomarker';
import { CoachInfo } from 'src/core/types/CoachInfo';
import CoachMessage from './CoachMessage';
import { UIHelper as uh } from '../../../core';

type GroupedHealthMarkers = { group: NewHealthMarker[]; experimentalGroup: NewHealthMarker[] };
interface AssessmentListSectionItemProps extends ViewProps {
  section: HealthMarkerGroup;
  btnDetailAssessmentClick: (assessmentId: string) => void;
  coach: CoachInfo;
  scrollRef: RefObject<ScrollView>;
}

const AssessmentListSectionItem = ({ section, coach, scrollRef }: AssessmentListSectionItemProps) => {
  const [visibleExperimentalMarkers, setVisibleExperimentalMarkers] = useState<boolean>(false);
  const [positionY, setPositionY] = useState<number>(0);

  const th = useTheme();

  const styleContainer = StyleSheet.create({
    contain: {
      marginTop: uh.h2DP(24),
      borderRadius: 8
    },
    name: {
      marginLeft: uh.w2DP(16),
      marginRight: uh.w2DP(16),
      marginTop: uh.h2DP(16)
    },
    summary: {
      marginLeft: uh.w2DP(16),
      marginRight: uh.w2DP(16),
      marginTop: uh.h2DP(10)
    },
    coachMessage: {
      marginTop: uh.h2DP(40),
      marginLeft: uh.w2DP(16),
      marginRight: uh.w2DP(16),
      marginBottom: uh.h2DP(16)
    },
    icon: {
      width: 20,
      height: 20
    },
    divider: { marginTop: uh.h2DP(24) },
    bioDivider: { marginTop: uh.h2DP(10) }
  });

  const renderBiomarkers = () => {
    const regex = /.*risk.*/i;
    const group = section.data.reduce(
      (prev: GroupedHealthMarkers, curr: NewHealthMarker) => {
        if (curr.key.match(regex)) {
          prev.experimentalGroup.push(curr);
          return prev;
        }
        prev.group.push(curr);
        return prev;
      },
      { group: [], experimentalGroup: [] }
    );

    return (
      <>
        {group.group.map((biomarker: NewHealthMarker, index: number) => (
          <View key={`biomarker-item-${index}`} style={{ display: biomarker.name === 'Body Fat' ? 'none' : 'flex' }}>
            <AssessmentBiomarker biomarker={biomarker} />
            <Divider style={styleContainer.bioDivider} />
          </View>
        ))}
        {group.experimentalGroup.length > 0 && (
          <>
            <TouchableOpacity
              onPress={() => {
                setVisibleExperimentalMarkers((prev) => !prev);
                LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
                if (!visibleExperimentalMarkers) {
                  setTimeout(() => {
                    scrollRef.current?.scrollTo({ y: positionY + uh.h2DP(32), animated: true });
                  }, 100);
                }
              }}
              onLayout={(event) => setPositionY(event.nativeEvent.layout.y)}
              style={[
                styleContainer.name,
                {
                  flexDirection: 'row',
                  justifyContent: 'space-between'
                }
              ]}>
              <Text category="h6" style={{ width: '90%' }}>
                Show experimental risk scores
              </Text>
              <Icon
                name={`arrow-ios-${visibleExperimentalMarkers ? 'downward' : 'upward'}-outline`}
                height={uh.h2DP(24)}
                width={uh.h2DP(24)}
                fill={th['color-basic-500']}
              />
            </TouchableOpacity>
            <Divider style={styleContainer.divider} />

            {visibleExperimentalMarkers &&
              group.experimentalGroup.map((biomarker: NewHealthMarker, index: number) => (
                <View
                  key={`biomarker-item-${index}`}
                  style={{ display: biomarker.name === 'Body Fat' ? 'none' : 'flex' }}>
                  <AssessmentBiomarker biomarker={biomarker} />
                  <Divider style={styleContainer.bioDivider} />
                </View>
              ))}
          </>
        )}
      </>
    );
  };

  // view
  return (
    <Layout style={styleContainer.contain}>
      <Text category="h6" style={styleContainer.name}>
        {section.groupName}
      </Text>
      <Divider style={styleContainer.divider} />
      {renderBiomarkers()}
      {section.comment?.comment && (
        <CoachMessage
          style={styleContainer.coachMessage}
          messages={[section.comment?.comment as string]}
          coach={coach}
        />
      )}
    </Layout>
  );
};

export default AssessmentListSectionItem;
