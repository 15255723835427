import { Button, Text } from '@ui-kitten/components';
import React, { RefObject } from 'react';

import ActionBottomSheet from './components/ActionBottomSheet';
import { ActionItem } from 'src/core/types/ActionItem';
import { BottomSheetModalMethods } from '@gorhom/bottom-sheet/lib/typescript/types';
import { StyleSheet } from 'react-native';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';

const styles = StyleSheet.create({
  content: {
    textAlign: 'center'
  },
  saveBtn: {
    marginTop: 5
  }
});

interface DeactivateBottomSheetProps {
  sheetRef: RefObject<BottomSheetModalMethods>;
  action?: ActionItem;
  onChange: (id: number) => void;
}

const DeactivateBottomSheet = ({ sheetRef, onChange, action }: DeactivateBottomSheetProps) => {
  const transMessage = {
    deactivateBottomSheetTitle: 'Deactivate',
    deactivateBottomSheetDesc: 'Are you sure you want do deactivate this action?',
    deactivateBottomSheetDeactivateBtn: 'Deactivate'
  };

  const t = useTranslationFunc(transMessage);

  const handleDeactivate = () => {
    if (action) {
      onChange(action.id);
    }
    sheetRef.current?.dismiss();
  };

  return (
    <ActionBottomSheet sheetRef={sheetRef} title={t('deactivateBottomSheetTitle')}>
      <Text style={styles.content}>{t('deactivateBottomSheetDesc')}</Text>
      <Button
        style={styles.saveBtn}
        status="primary"
        size="large"
        accessible={true}
        accessibilityLabel="Next action"
        testID="button_save_recurrence"
        onPress={handleDeactivate}>
        {t('deactivateBottomSheetDeactivateBtn')}
      </Button>
    </ActionBottomSheet>
  );
};

export default DeactivateBottomSheet;
