import { DynamicCardBody } from 'src/core/types/DynamicCard';
import { DataSharingModal } from 'src/core/types/DataSharing';
import { HealthMarker } from 'src/core/types/Bloodwork';
import { PillarCategory } from 'src/core/types/Pillar';
import { StateCreator } from 'zustand';
import { ThemeType } from '@ui-kitten/components';
import { UIHelper } from 'src/core/helpers/UIHelper';

export interface GeneralSlice {
  isVersionChecked: boolean;
  isAppPreInit: boolean;
  isAppInitComplete: boolean;
  isFirstRun: boolean;
  needRefreshMarkers: number;
  theme: string;
  themeType: ThemeType;
  viewPortWidth: number;
  dataSharing: boolean;
  dataSharingModal: DataSharingModal;
  pendingDataSharing: string;
  healthMarkers: Array<HealthMarker>;
  activePillars: string[];
  pillarCategories: PillarCategory[];
  dynamicCards: DynamicCardBody[];
  setRefreshMarkers: () => void;
  setAppPreInit: () => void;
  setAppInitComplete: (isAppInitComplete: boolean) => void;
  setFirstRun: (isFirstRun: boolean) => void;
  setTheme: (theme: string) => void;
  setViewPortWidth: () => void;
  setDataSharing: (dataSharing: boolean) => void;
  setDataSharingModal: (dataSharingModal: DataSharingModal) => void;
  setPendingDataSharing: (pendingDataSharing: string) => void;
  setHealthMarkers: (healthMarkers: Array<HealthMarker>) => void;
  setVersionChecked: () => void;
  setActivePillars: (activePillars: string[]) => void;
  setPillarCategories: (pillarCategories: PillarCategory[]) => void;
  setDynamicCards: (dynamicCards: DynamicCardBody[]) => void;
}

export const createGeneralSlice: StateCreator<GeneralSlice, [], [], GeneralSlice> = (set) => ({
  isVersionChecked: false,
  isAppPreInit: false,
  isFirstRun: false,
  isAppInitComplete: false,
  needRefreshMarkers: 0,
  theme: '',
  themeType: {},
  viewPortWidth: 450,
  dataSharing: false,
  dataSharingModal: {
    proUserName: '',
    tenantName: '',
    status: false,
    proUserId: '',
    tenantKey: ''
  },
  pendingDataSharing: '',
  healthMarkers: [],
  activePillars: [],
  pillarCategories: [],
  dynamicCards: [],
  setAppPreInit: () => set(() => ({ isAppPreInit: true })),
  setAppInitComplete: (isAppInitComplete: boolean) => set(() => ({ isAppInitComplete })),
  setFirstRun: (isFirstRun: boolean) => set(() => ({ isFirstRun })),
  setRefreshMarkers: () => set((state) => ({ needRefreshMarkers: state.needRefreshMarkers + 1 })),
  setTheme: (theme: string) => {
    const currentTheme = UIHelper.currentTheme(theme);
    return set(() => ({ theme: currentTheme.theme, themeType: currentTheme.package }));
  },
  setViewPortWidth: () => set(() => ({ viewPortWidth: UIHelper.currentViewPort() })),
  setDataSharing: (dataSharing: boolean) => set(() => ({ dataSharing })),
  setDataSharingModal: (dataSharingModal: DataSharingModal) => set(() => ({ dataSharingModal })),
  setPendingDataSharing: (pendingDataSharing: string) => set(() => ({ pendingDataSharing })),
  setHealthMarkers: (healthMarkers: Array<HealthMarker>) => set(() => ({ healthMarkers })),
  setVersionChecked: () => set(() => ({ isVersionChecked: true })),
  setActivePillars: (activePillars: string[]) => set(() => ({ activePillars })),
  setPillarCategories: (pillarCategories: PillarCategory[]) => set(() => ({ pillarCategories })),
  setDynamicCards: (dynamicCards: DynamicCardBody[]) => set(() => ({ dynamicCards }))
});
