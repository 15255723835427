import { StateCreator } from 'zustand';

export type LogType = string;

export type AnalyticsLogsData = {
  function?: string;
  data?: any;
  filename?: string;
  logType: LogType;
};

export interface AnalyticsSlice {
  analyticsLogs: AnalyticsLogsData[];
  analytics: boolean;
  addAnalyticsLog: (
    log: AnalyticsLogsData,
    cb: (analytics: boolean, analyticsLogs: AnalyticsLogsData[]) => void
  ) => void;
  setAnalytics: (analytics: boolean) => void;
  resetAnalytics: (cb: (analyticsLogs: AnalyticsLogsData[]) => void) => void;
}

export const createAnalyticsSlice: StateCreator<AnalyticsSlice, [], [], AnalyticsSlice> = (set) => ({
  analyticsLogs: [],
  analytics: true,
  addAnalyticsLog: (log, cb) => {
    set((state) => {
      cb(state.analytics, state.analyticsLogs);
      return { analyticsLogs: state.analytics ? [...state.analyticsLogs, log] : [] };
    });
  },
  setAnalytics: (analytics: boolean) => set(() => ({ analytics })),
  resetAnalytics: (cb: (analyticsLogs: AnalyticsLogsData[]) => void) => {
    set((state) => {
      cb(state.analyticsLogs);
      return { analyticsLogs: [] };
    });
  }
});
