import { Image, StyleSheet, View } from 'react-native';
import { PillarIcon, PillarNames } from 'src/core/icons/pillars';

import { BodyScanIcon } from 'src/core/icons/scan/BodyScanIcon';
import Brands from 'brands.json';
import { FaceScanIcon } from 'src/core/icons/scan/FaceScanIcon';
import { HRVScanIcon } from 'src/core/icons/scan/HRVScanIcon';
import { Icon } from '@ui-kitten/components';
import React from 'react';
import { UIHelper as uh } from 'src/core';

const scoreCategories = ['rest', 'nourish', 'move', 'connect', 'grow', 'reflect'];

interface DynamicCardIconsProps {
  category: string | PillarNames;
  height: number;
  width: number;
  fill: string;
  isLightTheme: boolean;
  th: Record<string, string>;
}

const DynamicCardIcons = ({ category, height, width, fill, isLightTheme, th }: DynamicCardIconsProps) => {
  const pillar = category.replace('ScoreDelta', '').toLowerCase();

  const styleContainer = StyleSheet.create({
    iconContainer: {
      height: uh.h2DP(height),
      width: uh.h2DP(width),
      marginRight: uh.h2DP(8),
      justifyContent: 'flex-start',
      alignItems: 'center'
    },
    pillarContainer: {
      borderRadius: 999,
      backgroundColor: th[`color-${pillar}-300-transparent`],
      justifyContent: 'center'
    }
  });

  const getIcon = () => {
    switch (category) {
      case Brands.chatbot_name.toLowerCase():
        return (
          <Image
            style={{ height: uh.h2DP(height), width: uh.h2DP(width) }}
            source={require('../../../../assets/images/brand/chatbot-avatar.png')}
          />
        );
      case 'biomarkerOfTheDay':
        return (
          <Icon name={'star-outline'} fill={fill} style={{ height: uh.h2DP(height), width: uh.h2DP(width), top: 0 }} />
        );
      case 'latestAssessment':
        return (
          <Icon
            name={'file-text-outline'}
            fill={fill}
            style={{ height: uh.h2DP(height), width: uh.h2DP(width), top: 0 }}
          />
        );
      case 'nextAction':
        return (
          <Icon
            name={'checkmark-circle-2-outline'}
            fill={fill}
            style={{ height: uh.h2DP(height), width: uh.h2DP(width), top: 0 }}
          />
        );
      case 'HRVScan':
        return <HRVScanIcon color={th['color-primary-500']} height={uh.h2DP(height)} width={uh.h2DP(width)} />;
      case 'FaceScan':
        return <FaceScanIcon color={th['color-primary-500']} height={uh.h2DP(height)} width={uh.h2DP(width)} />;
      case 'BodyScan':
        return <BodyScanIcon color={th['color-primary-500']} height={uh.h2DP(height)} width={uh.h2DP(width)} />;
      default:
        return <></>;
    }
  };
  if (scoreCategories.includes(pillar)) {
    return (
      <View style={[styleContainer.iconContainer, styleContainer.pillarContainer]}>
        <PillarIcon
          name={pillar as PillarNames}
          height={uh.h2DP(height * 0.65)}
          width={uh.h2DP(width * 0.65)}
          color={th[`color-${pillar}-${isLightTheme ? '700' : '900'}`]}
        />
      </View>
    );
  }
  return <View style={styleContainer.iconContainer}>{getIcon()}</View>;
};

export default DynamicCardIcons;
