import { StyleSheet, View } from 'react-native';

import ActionHeader from './ActionHeader';
import { ActionItem } from 'src/core/types/ActionItem';
import ActionList from './ActionList';
import { BasicActionProps } from './Action';
import React from 'react';
import { Text } from '@ui-kitten/components';

interface ActionSectionItem {
  headerText?: string;
  simpleList?: boolean;
  actions: ActionItem[] | ActionItem[][];
}

interface ActionScheduleListProps {
  headerText: string;
  onLinkLabelClick?: () => void;
  linkLabel?: string;
  commonProps: BasicActionProps;
  sections: ActionSectionItem[];
}

export const ActionScheduleList = ({
  headerText,
  linkLabel,
  commonProps,
  sections,
  onLinkLabelClick
}: ActionScheduleListProps) => {
  const styles = StyleSheet.create({
    container: {
      display: 'flex',
      gap: 20
    },
    header: {
      marginBottom: 20
    }
  });

  return (
    <>
      <ActionHeader text={headerText} linkLabel={linkLabel} onLinkTap={onLinkLabelClick} />
      <View style={styles.container}>
        {sections.every((sectionItem) => sectionItem.actions?.length === 0) ? (
          <Text style={{ marginBottom: 16 }} category="p1" appearance="hint">
            No actions for now
          </Text>
        ) : (
          <>
            {sections.map((section, index) => (
              <ActionList key={index} {...commonProps} {...section} />
            ))}
          </>
        )}
      </View>
    </>
  );
};
