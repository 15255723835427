import { MultiScanResult, MultiScanResultStatus, useMultiScan } from '../useMultiScan';
import { StackActions, useNavigation } from '@react-navigation/native';

import HealthMarkerService from 'src/api/healthMarkers';
import { checkCameraPermission } from '../../helpers/CheckScanCameraPermission';
import { scan } from 'src/core/brands';
import { showUploadBloodworkModal } from './helper';
import { useAhiSubscriptions } from '../useAhiSubscriptions';
import { useAnalytics } from '../useAnalytics';
import { useAppStore } from 'src/core/store';
import { useGetAppName } from '../useBrands';
import { useSetRefreshMarkers } from '../useGlobalVar';
import { useTelemetry } from '../useTelemetry';
import { useTranslationFunc } from '../useTranslationFunc';

export const useScan = () => {
  const { addTelemetryLog } = useTelemetry();
  const { addAnalyticsLog } = useAnalytics('useScan.ts');
  const { startBodyScan, startFaceScan } = useMultiScan();
  const setRefreshMarkers = useSetRefreshMarkers();
  const scanGuideSkip = useAppStore((state) => state.scanGuideSkip);
  const navigation = useNavigation();
  const { requestPayment } = useAhiSubscriptions();

  const t = useTranslationFunc(scan);
  const appName = useGetAppName();

  const bodyScan = async (bodyScanInputs: any, scanRetry = 2): Promise<MultiScanResult> => {
    if (!scanRetry) {
      return Promise.reject('Ahi body scan request returned false');
    }
    const hasPermission = await checkCameraPermission();
    addTelemetryLog(`Body scan camera permission: ${hasPermission.status}`);
    if (!hasPermission.granted) {
      return Promise.reject({ type: 'permission', message: t('permissionMessage', { app_name: appName }) });
    }

    // log start bodyScan event
    addAnalyticsLog({ logType: 'AHIBodyScanStart' });

    return startBodyScan(bodyScanInputs)
      .then((result) => {
        if (result.status === MultiScanResultStatus.SUCCESS) {
          HealthMarkerService.sendBodyScan(result.data)
            .then((res) => {
              setRefreshMarkers();
              navigation.dispatch(StackActions.replace('AssessmentDetail', { assessmentId: res.id }));
            })
            .catch((err) => {
              addAnalyticsLog({ logType: 'error', function: 'scan', data: err });
              navigation.navigate('Main', { screen: 'ScanGettingStarted' });
            });
          return Promise.resolve(result);
        } else if (result.status === MultiScanResultStatus.USER_OUT_OF_CREDIT) {
          return requestPayment().then(() => bodyScan(bodyScanInputs, scanRetry - 1));
        } else {
          return Promise.resolve({ status: MultiScanResultStatus.CANCEL, data: undefined });
        }
      })
      .catch((err) => {
        addAnalyticsLog({ logType: 'error', function: 'scan', data: err });
        return Promise.reject(err);
        // navigation.navigate('Main', { screen: 'ScanGettingStarted' });
      });
  };

  const faceScan = async (faceScanInputs: any, scanRetry = 2): Promise<MultiScanResult> => {
    if (!scanRetry) {
      return Promise.reject('Ahi face scan request returned false');
    }

    const hasPermission = await checkCameraPermission();
    addTelemetryLog(`Face scan camera permission: ${hasPermission.status}`);
    if (!hasPermission.granted) {
      return Promise.reject({ type: 'permission', message: t('permissionMessage', { app_name: appName }) });
    }

    // log start faceScan event
    addAnalyticsLog({ logType: 'AHIFaceScanStart' });

    return startFaceScan(faceScanInputs)
      .then((result) => {
        if (result.status === MultiScanResultStatus.SUCCESS) {
          HealthMarkerService.sendFaceScan(result.data)
            .then((res) => {
              setRefreshMarkers();
              navigation.dispatch(StackActions.replace('AssessmentDetail', { assessmentId: res.id }));
            })
            .catch((err) => {
              addAnalyticsLog({ logType: 'error', function: 'scan', data: err });
              navigation.navigate('Main', { screen: 'ScanGettingStarted' });
            });
          return Promise.resolve(result);
        } else if (result.status === MultiScanResultStatus.USER_OUT_OF_CREDIT) {
          return requestPayment().then(() => faceScan(faceScanInputs, scanRetry - 1));
        } else {
          return Promise.resolve({ status: MultiScanResultStatus.CANCEL, data: undefined });
        }
      })
      .catch((err) => {
        addAnalyticsLog({ logType: 'error', function: 'scan', data: err });
        return Promise.reject(err);
      });
  };

  const bloodworkScan = () => {
    if (!scanGuideSkip.get('bloodwork')) {
      navigation.navigate('BloodworkGuide');
    } else {
      showUploadBloodworkModal(navigation, false);
    }
  };

  return { bodyScan, faceScan, bloodworkScan };
};
