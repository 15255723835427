import { ActionItem } from 'src/core/types/ActionItem';
import { AgendaItem } from 'src/core/types/AgendaItem';
import { ProtocolItem } from 'src/core/types/Protocols';
import { StateCreator } from 'zustand';

export interface ActionSlice {
  actions: ActionItem[];
  actionItems: Array<AgendaItem>;
  refreshActionItemsFlag: boolean;
  protocols: ProtocolItem[];
  setActions: (actions: ActionItem[]) => void;
  setActionItems: (actionItems: Array<AgendaItem>) => void;
  updateActionItem: (actionItem: AgendaItem) => void;
  setRefreshActionItemsFlag: (refresh: boolean) => void;
  setProtocols: (protocols: ProtocolItem[]) => void;
}

export const createActionSlice: StateCreator<ActionSlice, [], [], ActionSlice> = (set) => ({
  actions: [],
  actionItems: [],
  refreshActionItemsFlag: false,
  protocols: [],
  setActions: (actions: ActionItem[]) => set(() => ({ actions })),
  setActionItems: (actionItems: Array<AgendaItem>) => set(() => ({ actionItems })),
  setRefreshActionItemsFlag: (refreshActionItemsFlag: boolean) => set(() => ({ refreshActionItemsFlag })),
  updateActionItem: (actionItem: AgendaItem): void => {
    set((state) => ({
      actionItems: state.actionItems.map((action) => (action.id === actionItem.id ? actionItem : action))
    }));
  },
  setProtocols: (protocols: any[]) => set(() => ({ protocols }))
});
