import { useCallback, useState } from 'react';

import ChatService from 'src/api/chat';
import { ConversationsDto } from '../types/Chat';
import { useAnalytics } from './useAnalytics';
import { useFocusEffect } from '@react-navigation/native';

interface UseConversations {
  loading: boolean;
  conversations: ConversationsDto[];
  error: any;
}
export const useConversations = (): UseConversations => {
  const [loading, setLoading] = useState<boolean>(false);
  const [conversations, setConversations] = useState<ConversationsDto[]>([]);
  const [error, setError] = useState<any>();

  const { addAnalyticsLog } = useAnalytics('useConversations.ts');

  const loadConversations = useCallback(() => {
    setLoading(true);
    ChatService.getConversations()
      .promise.then((fetchedConversations: ConversationsDto[]) => {
        setConversations(fetchedConversations);
      })
      .catch((err) => {
        addAnalyticsLog({ function: 'loadConversations', data: err, logType: 'error' });
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [addAnalyticsLog]);

  useFocusEffect(loadConversations);

  return { loading: loading, conversations: conversations, error: error };
};
