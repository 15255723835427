export type SleepStatItem = {
  type: string;
  name: string;
  key: string;
  value: number;
  unit: string;
};

export type SleepHistoriesItem = {
  value: number;
  date: Date;
};

export type SleepDurationHistoriesItem = {
  rem: number;
  deep: number;
  light: number;
  date: Date;
};

export type SleepStatInfo = {
  breathsAvgBreathsPerMin: string | null;
  breathsEndTime: string | null;
  breathsMaxBreathsPerMin: string | null;
  breathsMinBreathsPerMin: string | null;
  breathsOnDemandReading: string | null;
  breathsStartTime: string | null;
  endTime: string | null;
  heartRateAvgHrBpm: string | null;
  heartRateAvgHrVariabilityRmssd: string | null;
  heartRateAvgHrVariabilitySsdn: string | null;
  heartRateMaxHrBpm: string | null;
  heartRateMinHrBpm: string | null;
  heartRateUserHrMaxBpm: string | null;
  oxygenSaturationEndTime: string | null;
  oxygenSaturationOnDemandReading: string | null;
  oxygenSaturationStartTime: string | null;
  readiness: string | null;
  readinessRecoveryLevel: string | null;
  sleepAsleepDurationAsleepState: string | null;
  sleepAsleepDurationDeepSleepState: string | null;
  sleepAsleepDurationLightSleepState: string | null;
  sleepAsleepDurationRemSleepState: string | null;
  sleepAsleepNumRemEvents: string | null;
  sleepAwakeDurationAfterWakeup: string | null;
  sleepAwakeDurationAwakeState: string | null;
  sleepAwakeDurationBeforeSleeping: string | null;
  sleepAwakeDurationLongInterruption: string | null;
  sleepAwakeDurationShortInterruption: string | null;
  sleepAwakeNumOutOfBedEvents: string | null;
  sleepAwakeNumWakeupEvents: string | null;
  sleepAwakeWaso: string | null;
  sleepDurationInBed: string | null;
  sleepDurationPlanned: string | null;
  sleepDurationUnmeasurableSleep: string | null;
  sleepEfficiency: string | null;
  sleepId: string | null;
  snoringDataEndTime: string | null;
  snoringDataNumSnoringEvents: string | null;
  snoringDataStartTime: string | null;
  snoringDataTotalSnoringDuration: string | null;
  startTime: string | null;
  temperatureDelta: string | null;
};

export type SleepStat = {
  stats: SleepStatInfo;
  heartRateHrSamples: SleepHistoriesItem[];
  heartRateHistory: SleepHistoriesItem[];
  sleepHypnogramSamples: SleepHistoriesItem[];
  sleepHypnogramHistory: SleepDurationHistoriesItem[];
};

export type SleepStatGroup = {
  name?: string;
  chartType: 'Line' | 'Bar';
  chartData?: SleepHistoriesItem[];
  chartHistoriesData?: SleepHistoriesItem[] | SleepDurationHistoriesItem[];
  keys: string[];
};

export enum FrequencyType {
  Day = 1,
  Week,
  Month
}

export enum SleepType {
  Awake = 1,
  Sleeping,
  OutOfBed,
  Light,
  Deep,
  Rem
}
