import React, { useState } from 'react';
import { ScrollView, ViewProps } from 'react-native';
import { ErrorModal } from 'src/components/shared/ErrorModal';
import RefreshControl from 'src/components/shared/RefreshControl';
import { mainStyles } from 'src/screens/main/_mainStyles';
import SleepStatDetail from './SleepStatDetail';
import { useSleepStatEffect } from 'src/core/hooks/useSleepStat';
import { useSleepActionTipsEffect } from 'src/core/hooks/useSleepActionTips';
import { useAddAction } from 'src/core/hooks/useAddAction';
import Spinner from 'react-native-loading-spinner-overlay/lib';

//props
interface SleepStatDetailContainerProps extends ViewProps {
  sleepId: string;
}

const SleepStatDetailContainer = ({ sleepId }: SleepStatDetailContainerProps) => {
  const [sleepStatRefreshing, setSleepStatRefreshing] = useState<boolean>(false);
  const [actionTipsRefreshing, setActionTipsRefreshing] = useState<boolean>(false);
  const [sleepStat, isSleepStatLoading, sleepStatError] = useSleepStatEffect({
    refreshing: sleepStatRefreshing,
    sleepId: sleepId,
    setRefreshing: setSleepStatRefreshing
  });

  const [actionTips, isActionTipsLoading, actionTipsError] = useSleepActionTipsEffect({
    refreshing: actionTipsRefreshing,
    setRefreshing: setActionTipsRefreshing
  });

  const [addAction, isAddingAction, errorAddingAction] = useAddAction();

  const createAction = async (actionId: number) => {
    await addAction(actionId);
    setActionTipsRefreshing(true);
  };

  return (
    <ScrollView
      contentContainerStyle={[{ flexGrow: 1 }, mainStyles.mainScreenContainer]}
      alwaysBounceVertical={true}
      refreshControl={
        <RefreshControl
          refreshing={sleepStatRefreshing || actionTipsRefreshing}
          onRefresh={() => {
            setSleepStatRefreshing(true);
            setActionTipsRefreshing(true);
          }}
        />
      }>
      <SleepStatDetail
        sleepStat={sleepStat}
        actionTips={actionTips}
        isLoading={isSleepStatLoading || isActionTipsLoading}
        onCreateAction={(actionId) => {
          createAction(actionId);
        }}
      />
      <Spinner visible={isAddingAction} />
      <ErrorModal
        visible={sleepStatError !== undefined || actionTipsError !== undefined || errorAddingAction != undefined}
        message={{ ...sleepStatError, ...actionTipsError, ...errorAddingAction }}
      />
    </ScrollView>
  );
};

export default SleepStatDetailContainer;
