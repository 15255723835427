import { getCameraPermissionsAsync, requestCameraPermissionsAsync } from 'expo-camera';

export const checkCameraPermission = async () => {
  const cameraPermission = await getCameraPermissionsAsync();

  if (!cameraPermission.granted) {
    const askPermission = await requestCameraPermissionsAsync();
    return askPermission;
  } else return cameraPermission;
};
