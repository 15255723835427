import { StyleSheet, View } from 'react-native';
import { Text, useTheme } from '@ui-kitten/components';

import React from 'react';

interface ActionHeaderProps {
  text: string;
  linkLabel?: string;
  onLinkTap?: () => void;
}

const ActionHeader = ({ text, linkLabel, onLinkTap }: ActionHeaderProps) => {
  const th = useTheme();

  const styles = StyleSheet.create({
    headerContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 20
    }
  });

  return (
    <View style={styles.headerContainer}>
      <Text category="h4">{text}</Text>
      {onLinkTap && (
        <Text category="p1" style={{ color: th['color-primary-500'] }} onPress={onLinkTap}>
          {linkLabel}
        </Text>
      )}
    </View>
  );
};

export default ActionHeader;
