import { BiomarkerList, Dropdown, MarkerList } from '../../components/profileMeMarker';
import { Input, Layout, Text } from '@ui-kitten/components';
import { RootStackScreenProps, UIHelper as uh } from '../../core';
import { StyleSheet, View } from 'react-native';

import { ProfileIcons } from '../../components/profileMe/ProfileIcons';
import React from 'react';
import { mainStyles } from './_mainStyles';

const ProfileMeMarkersScreen = ({ navigation }: RootStackScreenProps<any>) => {
  // TODO change any
  //styles
  const styleContainer = StyleSheet.create({
    screenContainer: { flex: 1 },
    safeAreaView: { flex: 1 },
    learnMore: { marginTop: uh.h2DP(16) },
    searchText: { height: 40, flex: 1 },
    searchArea: { flexDirection: 'row', marginTop: uh.h2DP(12), alignItems: 'center' },
    loadMore: {
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: uh.h2DP(20),
      alignContent: 'center',
      alignSelf: 'center'
    },
    noMoreData: { marginTop: uh.h2DP(20), textAlign: 'center' }
  });

  const [searchText, setSearchText] = React.useState('');
  const [sortedType, setSortedType] = React.useState(0);
  const [markerName, setMarkerName] = React.useState(' All ');
  const [refreshing, setRefreshing] = React.useState<boolean>(false);
  const [isLoadMore, setIsLoadMore] = React.useState<boolean>(false);

  const finishRefreshing = () => {
    setRefreshing(false);
  };

  const markerClick = (name: string) => {
    setMarkerName(name);
  };

  const changeSortedType = (type: number) => {
    setSortedType(type);
  };

  // const btnTellMoreClick = () => { };

  const btnDetailAssessmentClick = (assessmentId: string) => {
    navigation.navigate('AssessmentDetail' as any, {
      assessmentId: assessmentId
    });
  };

  const listHeader = (
    <>
      <Text category="h4">My markers</Text>
      <MarkerList btnClickHandler={markerClick} markerName={markerName} />
      <View style={styleContainer.searchArea}>
        <Dropdown changeSortedType={changeSortedType} />
        <Input
          style={styleContainer.searchText}
          value={searchText}
          placeholder=""
          accessoryRight={<ProfileIcons.SearchIcon />}
          onChangeText={(nextValue) => setSearchText(nextValue)}
          accessible={true}
          accessibilityLabel={'my markers searchbar'}
          testID={'my_markers_searchbar'}
        />
      </View>
    </>
  );

  //view
  return (
    <Layout level="2" style={[styleContainer.screenContainer, mainStyles.mainScreenContainer]}>
      <BiomarkerList
        btnDetailAssessmentClick={btnDetailAssessmentClick}
        refreshing={refreshing}
        finishRefreshing={finishRefreshing}
        isLoadMore={isLoadMore}
        markerName={markerName}
        searchText={searchText}
        sortedType={sortedType}
        finishLoadMore={() => {
          setIsLoadMore(false);
        }}
        header={listHeader}
      />
    </Layout>
  );
};

export default ProfileMeMarkersScreen;
