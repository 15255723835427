import { NavigationProp, useNavigation } from '@react-navigation/native';

import { ActionItem } from 'src/core/types/ActionItem';
import ActionListItem from '../components/AddActionListItem';
import { FlatList } from 'react-native';
import React from 'react';
import { RootStackActionParamList } from 'src/core/types/RootNavStackParamList';
import { UIHelper as uh } from 'src/core';

interface AddActionListProps {
  actions: ActionItem[];
  handleEndReached?: () => void;
}

const AddActionList = ({ actions, handleEndReached }: AddActionListProps) => {
  const navigation: NavigationProp<RootStackActionParamList> = useNavigation();
  const renderListItem = ({ item }: { item: ActionItem }) => {
    return (
      <ActionListItem
        action={item}
        onPress={() => {
          navigation.navigate('NewAction', { action: item });
        }}
      />
    );
  };

  return (
    <FlatList
      onEndReached={handleEndReached}
      style={{ marginVertical: uh.h2DP(8) }}
      renderItem={renderListItem}
      data={actions}
    />
  );
};

export default AddActionList;
