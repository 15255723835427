import { StateCreator } from 'zustand';

export interface WearableSdkSlice {
  wearableSdkInitialized: boolean;
  setWearableSdkInitialized: (initialized: boolean) => void;
}

export const createWearableSdkSlice: StateCreator<WearableSdkSlice, [], [], WearableSdkSlice> = (set) => ({
  wearableSdkInitialized: false,
  setWearableSdkInitialized: (initialized: boolean) => set(() => ({ wearableSdkInitialized: initialized }))
});
