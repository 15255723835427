import { StateCreator } from 'zustand';
import { NewHealthMarker } from 'src/core/types/HealthMarkerReport';

export interface MarkerSlice {
  latestMarkers: Array<NewHealthMarker>;
  favoriteMarkers: Array<NewHealthMarker>;
  setLatestMarkers: (allMarkers: Array<NewHealthMarker>) => void;
  setFavoriteMarkers: (favoriteMarkers: Array<NewHealthMarker>) => void;
}

export const createMarkerSlice: StateCreator<MarkerSlice, [], [], MarkerSlice> = (set) => ({
  latestMarkers: [],
  favoriteMarkers: [],
  setLatestMarkers: (latestMarkers: Array<NewHealthMarker>) => set(() => ({ latestMarkers })),
  setFavoriteMarkers: (favoriteMarkers: Array<NewHealthMarker>) => set(() => ({ favoriteMarkers }))
});
