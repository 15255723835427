import { G, Mask, Path, Svg } from 'react-native-svg';

import React from 'react';
import { SvgIconProp } from 'src/core/types/ScanIcons';

export const BloodworkScanIcon = ({
  color = '#46D7CB',
  backgroundColor = 'transparent',
  height = 64,
  width = 64
}: SvgIconProp) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 64 64" fill={backgroundColor}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.4979 22.3724L27.0673 27.8252C24.0451 30.8571 24.0048 35.8238 26.9729 38.8974C28.402 40.3793 30.3104 41.2043 32.3437 41.221H32.3465C34.3812 41.2363 36.3034 40.4432 37.7562 38.9849C40.7756 35.9585 40.8159 30.9932 37.8465 27.9168L32.4979 22.3724ZM32.3215 44C29.534 43.9764 26.9257 42.85 24.9743 40.8278C20.9618 36.6723 21.016 29.9625 25.0951 25.8695L31.5299 19.4098C31.7938 19.1459 32.1521 18.9973 32.5257 19C32.8993 19.0042 33.2549 19.1584 33.5132 19.425L39.8465 25.9903C43.8576 30.1473 43.8035 36.8584 39.7243 40.9473C37.7396 42.9389 35.1118 44.0223 32.3243 44H32.3215Z"
        fill={color}
      />
      <Mask id="mask0_2_5329" maskUnits="userSpaceOnUse" x="22" y="19" width="21" height="26">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.4979 22.3724L27.0673 27.8252C24.0451 30.8571 24.0048 35.8238 26.9729 38.8974C28.402 40.3793 30.3104 41.2043 32.3437 41.221H32.3465C34.3812 41.2363 36.3034 40.4432 37.7562 38.9849C40.7756 35.9585 40.8159 30.9932 37.8465 27.9168L32.4979 22.3724ZM32.3215 44C29.534 43.9764 26.9257 42.85 24.9743 40.8278C20.9618 36.6723 21.016 29.9625 25.0951 25.8695L31.5299 19.4098C31.7938 19.1459 32.1521 18.9973 32.5257 19C32.8993 19.0042 33.2549 19.1584 33.5132 19.425L39.8465 25.9903C43.8576 30.1473 43.8035 36.8584 39.7243 40.9473C37.7396 42.9389 35.1118 44.0223 32.3243 44H32.3215Z"
          fill="white"
        />
      </Mask>
      <G mask="url(#mask0_2_5329)"></G>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.6667 8H16C11.5817 8 8 11.5817 8 16V26.6667H10.8V16C10.8 13.1281 13.1281 10.8 16 10.8H26.6667V8ZM8 37.3333V48C8 52.4183 11.5817 56 16 56H26.6667V53.2H16C13.1281 53.2 10.8 50.8719 10.8 48V37.3333H8ZM37.3333 8V10.8H48C50.8719 10.8 53.2 13.1281 53.2 16V26.6667H56V16C56 11.5817 52.4183 8 48 8H37.3333ZM56 37.3333H53.2V48C53.2 50.8719 50.8719 53.2 48 53.2H37.3333V56H48C52.4183 56 56 52.4183 56 48V37.3333Z"
        fill={color}
      />
    </Svg>
  );
};
