import { Platform, StyleSheet, View } from 'react-native';
import React, { useEffect } from 'react';

import { PillarDynamicCard } from 'src/screens/main/HomeScreen';
import { ScoreCategory } from 'src/core/types/Scores';
import ScoreDial from './components/ScoreDial';
import ScoreDynamicCard from './ScoreDynamicCard';
import { Spinner } from '@ui-kitten/components';
import { UIHelper as uh } from 'src/core';
import { useNavigation } from '@react-navigation/native';

interface ScoreDialContainerProps {
  scoreCategories: ScoreCategory[];
  isLoading: boolean;
  selectedPillar: PillarDynamicCard;
  setSelectedPillar: (selectedPillar: PillarDynamicCard) => void;
}

const styleContainer = StyleSheet.create({
  loadingContainer: {
    padding: uh.h2DP(16),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});

const ScoreDialContainer = ({
  scoreCategories,
  isLoading,
  selectedPillar,
  setSelectedPillar
}: ScoreDialContainerProps): JSX.Element => {
  const navigation = useNavigation();

  useEffect(() => {
    if (!isLoading) {
      setSelectedPillar({
        displayName: scoreCategories[0]?.displayName,
        externalKey: scoreCategories[0]?.externalKey,
        accuracy: scoreCategories[0]?.nominalTotalAccuracy,
        score: scoreCategories[0]?.score,
        default: true
      });
    }
  }, [isLoading, scoreCategories, setSelectedPillar]);
  if (isLoading) {
    return (
      <View style={styleContainer.loadingContainer}>
        <Spinner />
      </View>
    );
  }

  if (scoreCategories.length === 0) {
    return <></>;
  }

  const kalibaPillar: PillarDynamicCard = {
    accuracy: scoreCategories[0].nominalTotalAccuracy,
    displayName: scoreCategories[0].displayName,
    externalKey: scoreCategories[0].externalKey,
    score: scoreCategories[0].score,
    default: true
  };

  const clickPillar = (externalKey: string) => {
    const data = scoreCategories.find((item) => item.externalKey == externalKey);
    navigation.navigate('Pillar' as any, {
      ...data,
      name: data?.displayName,
      type: data?.externalKey,
      accuracy: data?.nominalTotalAccuracy
    });
  };

  return (
    <>
      <ScoreDial
        pillarScores={scoreCategories}
        btnClickHandler={() => {}}
        circumference={uh.h2DP(72)}
        baseRadius={uh.h2DP(Platform.OS === 'ios' ? 52 : 60)}
        chartHeight={uh.h2DP(Platform.OS === 'ios' ? 256 : 268)}
        chartWidth={uh.h2DP(Platform.OS === 'ios' ? 256 : 268)}
        selectedPillar={selectedPillar}
        setSelectedPillar={setSelectedPillar}
      />
      <ScoreDynamicCard
        onClick={clickPillar}
        selectedPillar={!selectedPillar.externalKey ? kalibaPillar : selectedPillar}
        iconHeight={uh.h2DP(36)}
        iconWidth={uh.h2DP(36)}
      />
    </>
  );
};

export default ScoreDialContainer;
