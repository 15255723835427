import { Layout } from '@ui-kitten/components';
import { UIHelper as uh } from '../../core';
import { StyleSheet } from 'react-native';
import React, { useState } from 'react';
import FavoriteList from './FavoriteList';
import AddFavoriteList from './AddFavoriteList';
import { ErrorModal } from 'src/components/shared/ErrorModal';
import Spinner from 'react-native-loading-spinner-overlay/lib';
import {
  useLatestMarkersEffect,
  useFavoriteMarkersEffect,
  useRemoveFvoriteMarker,
  useAddFavoriteMarkers
} from 'src/core/hooks/useMyMarkers';

const styleContainer = StyleSheet.create({
  screenContainer: {
    flex: 1,
    paddingBottom: uh.h2DP(20)
  }
});

const FavoriteListContainer = () => {
  const [isAdding, setIsAdding] = React.useState<boolean>(false);
  const [favoriteMarkersRefreshing, setFavoriteMarkersRefreshing] = useState<boolean>(false);
  const [latestMarkersRefreshing, setLatestMarkersRefreshing] = useState<boolean>(false);
  const [favoriteMarkers, isFavoriteMarkersLoading, favoriteMarkersError] = useFavoriteMarkersEffect({
    refreshing: favoriteMarkersRefreshing,
    setRefreshing: setFavoriteMarkersRefreshing
  });

  const [latestMarkers, isLastestMarkersLoading, lastestMarkersError] = useLatestMarkersEffect({
    refreshing: latestMarkersRefreshing,
    setRefreshing: setLatestMarkersRefreshing
  });

  const [removeFavoriteMarker, isRemovingFavoriteMarker, removeError] = useRemoveFvoriteMarker();
  const [addFavoriteMarker, isAddingFavoriteMarker, addError] = useAddFavoriteMarkers();

  if (isLastestMarkersLoading || isFavoriteMarkersLoading) {
    return <Spinner visible={true} />;
  }

  //view
  return (
    <Layout level="2" style={styleContainer.screenContainer}>
      {isAdding === true ? (
        <AddFavoriteList
          onAddMoreFavoriteMarkers={async (keys) => {
            await addFavoriteMarker(keys);
            setFavoriteMarkersRefreshing(true);
          }}
          markers={latestMarkers.filter(
            (item) => !favoriteMarkers.find((marker) => marker.externalKey == item.externalKey)
          )}
          onCloseBtnClick={() => {
            setIsAdding(false);
          }}
          currentPageNumber={0}
        />
      ) : (
        <FavoriteList
          favoriteMarkers={favoriteMarkers}
          onDeleteFavoriteMarker={async (key) => {
            await removeFavoriteMarker([key]);
            setFavoriteMarkersRefreshing(true);
          }}
          onAddFavoriteBtnClick={() => {
            setIsAdding(true);
          }}
          currentPageNumber={0}
        />
      )}

      <Spinner visible={isAddingFavoriteMarker || isRemovingFavoriteMarker} />
      <ErrorModal
        visible={
          favoriteMarkersError != undefined ||
          lastestMarkersError != undefined ||
          removeError != undefined ||
          addError != undefined
        }
        message={{ ...favoriteMarkersError, ...lastestMarkersError, ...removeError, ...addError }}
      />
    </Layout>
  );
};

export default FavoriteListContainer;
