import { BackendApi } from '../../shared';
import { Platform } from 'react-native';
import { TerraProvider } from 'src/core/types/TerraProvider';
import { WearableService } from '../model';
import moment from 'moment';

const MIN_DAYS_SYNC = 2;

export const WebWearableService: WearableService = {
  fixSubscription: (terraId: string): Promise<boolean> => {
    return BackendApi.post('/terra/fix-apple-subscription', {
      terraId
    });
  },
  checkIfSyncNeeded: (): Promise<boolean> => {
    return BackendApi.get('/terra/apple-sync-date')
      .then((response) => {
        if (response.status === 204) {
          // Never sync
          return true;
        }
        const lastSyncDate = moment(response.data);
        const today = moment(new Date());
        const daysDiff = today.diff(lastSyncDate, 'days');

        return daysDiff >= MIN_DAYS_SYNC;
      })
      .catch(() => {
        return Promise.resolve(false);
      });
  },
  getSubscriptions: (): Promise<TerraProvider[]> => {
    return BackendApi.post('/terra/subscriptions').then((response) => {
      if (response.data != undefined) {
        // filter provider based on platform
        const providers = response.data.providers.filter((item: TerraProvider) => {
          if (Platform.OS == 'ios') {
            return item.onApple == 1;
          } else if (Platform.OS == 'android') {
            return item.onAndroid == 1;
          } else if (Platform.OS == 'web') {
            return item.onWeb == 1;
          }
        }) as Array<TerraProvider>;

        const sortedProviders = providers.sort((a, b) => a.provider_name.localeCompare(b.provider_name));
        return Promise.resolve(sortedProviders);
      }
      return Promise.reject();
    });
  },
  getToken: (providerId: number, resource: string): Promise<string> => {
    return BackendApi.post('/terra/connect', {
      resource: resource,
      providerId: String(providerId)
    }).then((res) => res.data.token);
  },
  connect: (providerId: number, resource: string): Promise<any> => {
    return BackendApi.post('/terra/connect', {
      resource: resource,
      providerId: String(providerId)
    }).then((res) => res.data);
  },
  disconnect: (terraUserId): Promise<any> => {
    return BackendApi.post('/terra/disconnect', {
      terra_user_id: terraUserId
    }).then((response) => response.data);
  }
};
