import { SimpleTelemetryData } from 'src/api/telemetry/model';
import { StateCreator } from 'zustand';

export type AddTelemetryDataFunc = (log: SimpleTelemetryData) => void;
export type EnableTelemetryFunc = (telemetry: boolean) => void;
export type ClearTelemetryCallbackFunc = (logs: SimpleTelemetryData[]) => void;

export interface TelemetryLogSlice {
  telemetryLogs: SimpleTelemetryData[];
  telemetry: boolean;
  terraId: string;
  setTelemetryEnabled: EnableTelemetryFunc;
  setTerraId: (terraId: string) => void;
  addTelemetryLog: AddTelemetryDataFunc;
  clearTelemetry: (callback: (logs: SimpleTelemetryData[]) => void) => void;
}

export const createTelemetryLogSlice: StateCreator<TelemetryLogSlice, [], [], TelemetryLogSlice> = (set) => ({
  telemetryLogs: [],
  telemetry: false,
  terraId: '',
  addTelemetryLog: (log: SimpleTelemetryData) =>
    set((state) => ({ telemetryLogs: state.telemetry ? [...state.telemetryLogs, log] : [] })),
  setTelemetryEnabled: (telemetry: boolean) => set(() => ({ telemetry })),
  setTerraId: (terraId: string) => set(() => ({ terraId })),
  clearTelemetry: (callback: ClearTelemetryCallbackFunc) =>
    set((state) => {
      callback(state.telemetryLogs);
      return { telemetryLogs: [] };
    })
});
