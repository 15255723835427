import React, { useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';

import { ErrorModal } from 'src/components/shared/ErrorModal';
import LevelMenuContainer from './LevelMenuContainer';
import NotificationsMenuContainer from './NotificationsMenuContainer';
import RefreshControl from 'src/components/shared/RefreshControl';
import Spinner from 'react-native-loading-spinner-overlay/lib';
import ThemeMenuContainer from './ThemeMenuContainer';
import UnitsMenuContainer from './UnitsMenuContainer';
import { UIHelper as uh } from 'src/core';
import { useUserSettingsEffect } from 'src/core/hooks/useUserSettings';

//styles
const styleContainer = StyleSheet.create({
  container: {
    flex: 1,
    paddingLeft: uh.w2DP(16),
    paddingRight: uh.w2DP(16),
    paddingTop: uh.h2DP(16),
    paddingBottom: uh.h2DP(20),
    rowGap: 16
  }
});

const MenuCustomisationContainer = () => {
  const [userSettingsRefreshing, setUserSettingRefreshing] = useState<boolean>(false);
  const [isUserSettingsLoading, userSettingsError] = useUserSettingsEffect({
    refreshing: userSettingsRefreshing,
    setRefreshing: setUserSettingRefreshing
  });

  return (
    <ScrollView
      alwaysBounceVertical={true}
      refreshControl={
        <RefreshControl
          refreshing={userSettingsRefreshing}
          onRefresh={() => {
            setUserSettingRefreshing(true);
          }}
        />
      }>
      <View style={styleContainer.container}>
        <ThemeMenuContainer />
        <NotificationsMenuContainer />
        <UnitsMenuContainer />
        <LevelMenuContainer />
      </View>
      <ErrorModal visible={userSettingsError != undefined} message={{ ...userSettingsError }} />
      <Spinner visible={isUserSettingsLoading} />
    </ScrollView>
  );
};

export default MenuCustomisationContainer;
