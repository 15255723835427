import { BackHandler, Platform, SafeAreaView, ScrollView, StyleSheet, View } from 'react-native';
import { Layout, ModalService, Text, useTheme } from '@ui-kitten/components';
import React, { useState } from 'react';
import { RootStackScreenProps, TenantFeature, NumberHelper as nh, UIHelper as uh } from 'src/core';
import { useGetTenantFeatures, useGetUserAttributes } from 'src/core/hooks/useUser';
import { useHasMarkersInitError, useIsMarkersInitLoading } from 'src/core/hooks/useMarkers';
import {
  useHasMultiScanInitError,
  useHasMultiScanUserError,
  useIsMultiScanInitLoading,
  useIsMultiScanUserLoading
} from 'src/core/hooks/useMultiScan';

import DisableTenantFeature from 'src/components/shared/DisableTenantFeature';
import DownloadResourceModal from 'src/components/scan/modal/DownloadResourceModal';
import { InfoModal } from 'src/components/shared/InfoModal';
import MultiScanModule from 'src/core/modules/MultiScanModule';
import { ScanIcon } from 'src/core/icons/scan/ScanIcons';
import { ScanTypeCard } from 'src/components/scan/ScanTypeCard';
import { mainStyles } from './_mainStyles';
import { scanGettingStartedScreen } from 'src/core/brands';
import { useAnalytics } from 'src/core/hooks/useAnalytics';
import { useAppStore } from 'src/core/store';
import { useGetAppName } from 'src/core/hooks/useBrands';
import { useIsFocused } from '@react-navigation/native';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';

let modalID = '';
const ScanGettingStartedScreen = ({ navigation }: RootStackScreenProps<'ScanGettingStarted'>) => {
  const [resourcesDownloaded, setResourcesDownloaded] = useState(false);
  const [visibleMembershipModal, setVisibleMembershipModal] = React.useState<boolean>(false);
  const [visibleDisabledScanModal, setVisibleDisabledScanModal] = React.useState<boolean>(false);
  const [isActionScreenEnable, setIsActionScreenEnable] = React.useState(true);

  const scanFeatures = [
    'AHIBodyScanFeature',
    'AHIFaceScanFeature',
    'SprenHRVScanFeature',
    'FunctionalBiomarkersAssessmentFeature'
  ];
  const tenantFeatures: TenantFeature[] = useGetTenantFeatures();
  const diabeticTypes = ['none', 'type1', 'type2'];
  const userAttributes = useGetUserAttributes();
  const scanGuideSkip = useAppStore((state) => state.scanGuideSkip);
  const isMarkersInitLoading = useIsMarkersInitLoading();
  const hasMarkersInitError = useHasMarkersInitError();
  const isMultiScanInitLoading = useIsMultiScanInitLoading();
  const hasMultiScanInitError = useHasMultiScanInitError();
  const isMultiScanUserLoading = useIsMultiScanUserLoading();
  const hasMultiScanUserError = useHasMultiScanUserError();

  const isInitializing = isMarkersInitLoading || isMultiScanInitLoading || isMultiScanUserLoading;
  const hasError = hasMarkersInitError || hasMultiScanInitError || hasMultiScanUserError;

  const th = useTheme();

  const { addAnalyticsLog } = useAnalytics('ScanGettingStartedScreen.tsx');
  const t = useTranslationFunc(scanGettingStartedScreen);
  const appName = useGetAppName();

  // check if the user has all scans disabled
  const allScansDisabled = !scanFeatures.some((elem) => tenantFeatures.map((x) => x.key).includes(elem));

  const styles = StyleSheet.create({
    screenContainer: { flex: 1 },
    safeAreaView: { flex: 1 },
    container: {
      flex: 1,
      alignItems: 'center'
    },
    cardContainer: {
      alignSelf: 'stretch',
      backgroundColor: 'transparent'
    },
    backdropStyle: { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
    loading: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      height: '100%',
      zIndex: 999,
      top: 0,
      left: 0,
      width: uh.currentViewPort(),
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    }
  });

  const isFocused = useIsFocused();

  React.useEffect(() => {
    if (tenantFeatures != undefined) {
      //TODO
      setIsActionScreenEnable(
        tenantFeatures.find((item: TenantFeature) => item.key == 'ChatAndAgendaFeature') != undefined
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused]);

  /**
   * Check if the AHI resources are downloaded.
   *
   * We have remote resources that exceed 100MB that enable our scans to work.
   * You are required to download them in order to obtain a body scan.
   */
  const areAHIResourcesAvailable = React.useCallback(async () => {
    MultiScanModule.areAHIResourcesAvailable().then((areAvailable: boolean) => {
      if (!areAvailable) {
        setResourcesDownloaded(false);
      } else {
        // control view state
        setResourcesDownloaded(true);
      }
    });
  }, []);

  React.useEffect(() => {
    if (isFocused && allScansDisabled) {
      setVisibleMembershipModal(true);
    }
  }, [isFocused, allScansDisabled]);

  React.useEffect(() => {
    const backAction = () => {
      if (modalID != undefined && modalID.length > 0) {
        modalID = ModalService.hide(modalID);
        return true;
      }

      return false;
    };

    const backHandler = BackHandler.addEventListener('hardwareBackPress', backAction);

    return () => backHandler.remove();
  }, []);

  React.useEffect(() => {
    if (Platform.OS != 'web') {
      areAHIResourcesAvailable();
    }
  }, [areAHIResourcesAvailable]);

  const onFaceScanPress = async () => {
    const userFaceScanInput = {
      enum_ent_sex: userAttributes.gender,
      cm_ent_height: userAttributes.height,
      kg_ent_weight: userAttributes.weight,
      yr_ent_age: nh.getAge(String(userAttributes.birthdate)),
      bool_ent_smoker: userAttributes.smoker == 1,
      bool_ent_hypertension: userAttributes.hypertension == 1,
      bool_ent_bloodPressureMedication: userAttributes.bloodpressureMedication == 1,
      enum_ent_diabetic: diabeticTypes[userAttributes.diabetic ?? 0]
    };

    if (scanGuideSkip.get('face')) {
      navigation.navigate('ScanLoadingScreen', {
        scanInputs: userFaceScanInput,
        scanType: 'face'
      });
    } else {
      navigation.navigate('FaceScanGuide' as any, {
        faceScanInputs: userFaceScanInput
      });
    }
  };

  const onHRVScanPress = () => {
    if (scanGuideSkip.get('HRV')) {
      // log Start HrvScan event
      addAnalyticsLog({ logType: 'SPRENHrvScanStart' });
      navigation.navigate('HRVScan');
    } else {
      navigation.navigate('HRVScanGuide' as any);
    }
  };

  const gotoBodyScanGuide = () => {
    const userBodyScanInput = {
      enum_ent_sex: userAttributes.gender,
      cm_ent_height: userAttributes.height,
      kg_ent_weight: userAttributes.weight,
      yr_ent_age: nh.getAge(String(userAttributes.birthdate))
    };

    if (scanGuideSkip.get('body')) {
      navigation.navigate('ScanLoadingScreen', {
        scanInputs: userBodyScanInput,
        scanType: 'body'
      });
    } else {
      navigation.navigate('BodyScanGuide' as any, {
        bodyScanInputs: userBodyScanInput
      });
    }
  };

  const renderDownloadResourceContentElement = () => {
    return (
      <DownloadResourceModal
        caption="We are downloading the required files to support a Body Scan, please try again in a few minutes"
        btnCancelClick={() => {
          ModalService.hide(modalID);
        }}
        onDownloadComplete={() => {
          ModalService.hide(modalID);
          setResourcesDownloaded(true);
          if (isFocused == true) {
            gotoBodyScanGuide();
          }
        }}
      />
    );
  };

  const showDownloadResourceModal = () => {
    const contentElement = renderDownloadResourceContentElement();
    modalID = ModalService.show(contentElement, {
      backdropStyle: styles.backdropStyle
    });
  };

  const onBodyScanPress = async () => {
    if (resourcesDownloaded == true) {
      gotoBodyScanGuide();
    } else {
      showDownloadResourceModal();
    }
  };

  // const onUploadBloodworkPress = () => {
  //   bloodworkScan();
  // };

  const getBodyScanCardView = () => {
    return (
      <ScanTypeCard
        CardImage={ScanIcon(th['color-primary-500']).BodyScan}
        scanDescription={'What is your body fat percentage?'}
        scanTime={'60s'}
        buttonText={'Body Scan'}
        onPressButton={onBodyScanPress}
        membership={tenantFeatures.map((x) => x.key).includes('AHIBodyScanFeature')}
        onInvalidButton={() => {
          setVisibleMembershipModal(true);
        }}
        hasError={hasError}
        isLoading={isInitializing}
      />
    );
  };

  const getFaceScanCardView = () => {
    return (
      <ScanTypeCard
        CardImage={ScanIcon(th['color-primary-500']).FaceScan}
        scanDescription={'What are your vital signs?'}
        scanTime={'30s'}
        buttonText={'Face Scan'}
        onPressButton={onFaceScanPress}
        membership={tenantFeatures.map((x) => x.key).includes('AHIFaceScanFeature')}
        onInvalidButton={() => {
          setVisibleMembershipModal(true);
        }}
        hasError={hasError}
        isLoading={isInitializing}
      />
    );
  };

  const getHRVView = () => {
    return (
      <ScanTypeCard
        CardImage={ScanIcon(th['color-primary-500']).HRVScan}
        scanDescription={'What is your readiness score?'}
        scanTime={'60s'}
        buttonText={'HRV Scan'}
        onPressButton={onHRVScanPress}
        membership={tenantFeatures.map((x) => x.key).includes('SprenHRVScanFeature')}
        onInvalidButton={() => setVisibleMembershipModal(true)}
        hasError={hasError}
        isLoading={isInitializing}
      />
    );
  };

  // Disable the bloodwork scan tile for now until further notice
  // const getUploadBloodworkView = () => {
  //   return (
  //     <ScanTypeCard
  //       CardImage={ScanIcon(th['color-primary-500']).BloodworkScan}
  //       scanDescription={'Understand your blood test results'}
  //       scanTime={'15s'}
  //       buttonText={'Bloodwork'}
  //       onPressButton={onUploadBloodworkPress}
  //       membership={tenantFeatures.map((x) => x.key).includes('FunctionalBiomarkersAssessmentFeature')}
  //       onInvalidButton={() => setVisibleMembershipModal(true)}
  //       hasError={hasError}
  //       isLoading={isInitializing}
  //     />
  //   );
  // };

  const membershipMessage = (): JSX.Element => {
    return (
      <View style={{ flex: 1, justifyContent: 'space-between', alignItems: 'center' }}>
        <Text style={{ textAlign: 'center' }}>{t('membershipInfoMessage', { app_name: appName })}</Text>
      </View>
    );
  };

  const disabledScanMessage = `We're sorry, but your device doesn't meet the minimum requirements to use this feature. We need ${
    Platform.OS === 'android' ? 'Android 8.0' : 'iOS 13.4'
  } or higher.`;

  return (
    <Layout level="2" style={[styles.screenContainer, !isActionScreenEnable ? mainStyles.mainScreenContainer : null]}>
      <SafeAreaView style={[styles.screenContainer]}>
        {isActionScreenEnable ? (
          <>
            {Platform.OS !== 'web' && (
              <ScrollView style={[{ flexGrow: 1 }, mainStyles.mainScreenContainer]}>
                <View style={{ flexDirection: 'row' }}>
                  {getFaceScanCardView()}
                  {getHRVView()}
                </View>
                <View style={{ flexDirection: 'row' }}>
                  {getBodyScanCardView()}
                  {/* {getUploadBloodworkView()} */}
                </View>
              </ScrollView>
            )}
            <InfoModal
              visible={visibleMembershipModal}
              message={membershipMessage()}
              closeBtnClick={() => {
                if (allScansDisabled) {
                  if (navigation.canGoBack()) navigation.goBack();
                  else navigation.navigate('Main', { screen: 'Home' });
                }
                setVisibleMembershipModal(false);
              }}
            />
            <InfoModal
              visible={visibleDisabledScanModal}
              message={disabledScanMessage}
              closeBtnClick={() => {
                setVisibleDisabledScanModal(false);
              }}
            />
          </>
        ) : (
          <DisableTenantFeature />
        )}
      </SafeAreaView>
    </Layout>
  );
};

export default ScanGettingStartedScreen;
