import * as ActionScreens from '../../screens/main/actions';

import { ActionScreenNames, UIHelper as uh } from 'src/core';
import { StackNavigationOptions, createStackNavigator } from '@react-navigation/stack';
import { Text, useTheme } from '@ui-kitten/components';

import ActionHeaderLeft from './components/ActionHeaderLeft';
import HeaderRight from './components/HeaderRight';
import React from 'react';
import { RootStackActionParamList } from 'src/core/types/RootNavStackParamList';
import { useAppStore } from 'src/core/store';

const ActionNavigation = () => {
  const ActionStack = createStackNavigator<RootStackActionParamList>();
  const th = useTheme();
  const theme = useAppStore((state) => state.theme);

  const condColors = {
    header: uh.getHex(th, theme, 'color-basic-100', 'color-basic-1100'),
    headerTintColor: uh.getHex(th, theme, 'color-basic-900', 'color-basic-100')
  };

  const stackHeaderOptions = (title: string, backScreenName?: ActionScreenNames): StackNavigationOptions => {
    const tabScreenOptions: StackNavigationOptions = {
      headerLeft: () => <ActionHeaderLeft backScreenName={backScreenName as ActionScreenNames} />,
      headerRight: () => <HeaderRight />,
      headerShown: true,
      headerTitle: () => (
        <Text status="basic" category="s2" style={{ paddingTop: 2 }}>
          {title}
        </Text>
      ),
      headerTitleAlign: 'center',
      headerTintColor: condColors.headerTintColor,
      headerStyle: {
        backgroundColor: condColors.header
      }
    };
    return tabScreenOptions;
  };
  return (
    <ActionStack.Navigator>
      <ActionStack.Screen
        name="TodayActions"
        options={{ ...stackHeaderOptions('Actions') }}
        component={ActionScreens.TodayActionsScreen}
      />
      <ActionStack.Screen
        name="ActionsScheduleActive"
        options={{ ...stackHeaderOptions('Schedule', 'TodayActions') }}
        component={ActionScreens.ScheduledActiveActionsScreen}
      />
      <ActionStack.Screen
        name="ActionsScheduleInactive"
        options={{ ...stackHeaderOptions('Schedule', 'ActionsScheduleActive') }}
        component={ActionScreens.ScheduledInactiveActionsScreen}
      />
      <ActionStack.Screen
        name="ActionsAdd"
        options={{ ...stackHeaderOptions('Add Actions', 'TodayActions') }}
        component={ActionScreens.ActionsAdd}
      />
      <ActionStack.Screen
        name="NewProtocol"
        options={{ ...stackHeaderOptions('Add Protocol', 'ActionsAdd') }}
        component={ActionScreens.NewProtocol}
      />
      <ActionStack.Screen
        name="NewAction"
        options={{ ...stackHeaderOptions('Add Action', 'ActionsAdd') }}
        component={ActionScreens.NewAction}
      />
    </ActionStack.Navigator>
  );
};

export default ActionNavigation;
