import { Text, useTheme } from '@ui-kitten/components';
import React from 'react';
import { ViewProps, StyleSheet, View } from 'react-native';
import { SLEEP_KEY_MAP, SleepStatHelper } from 'src/core/helpers/SleepStatHelper';
import {
  SleepDurationHistoriesItem,
  SleepHistoriesItem,
  SleepStatGroup,
  SleepStatInfo
} from 'src/core/types/SleepStat';
import { UIHelper as uh } from '../../core';
import SleepBarChart from './conponents/SleepBarChart';
import SleepLineChart from './conponents/SleepLineChart';

//props
interface SleepGroupProps extends ViewProps {
  group: SleepStatGroup;
  sleepStatInfo: SleepStatInfo;
}

// styles
const styleContainer = StyleSheet.create({
  container: {
    borderRadius: 8
  },
  groupName: { color: '#5F93F9' },
  sleepItemContainer: {
    flex: 1,
    marginTop: uh.h2DP(8),
    flexWrap: 'wrap',
    flexDirection: 'row',
    columnGap: uh.h2DP(16),
    rowGap: uh.w2DP(16)
  },
  sleepItem: {
    flexBasis: '30%'
  }
});

const SleepGroup = ({ group, sleepStatInfo }: SleepGroupProps) => {
  const th = useTheme();
  // view
  return (
    <View style={styleContainer.container}>
      {group.name && (
        <Text category="c2" style={styleContainer.groupName}>
          {group.name.toUpperCase()}
        </Text>
      )}
      {group.chartData && (
        <>
          {group.chartType == 'Line' ? (
            <SleepLineChart
              isLoading={false}
              graphData={group.chartData}
              graphHistoriesData={group.chartHistoriesData as SleepDurationHistoriesItem[]}
            />
          ) : (
            <SleepBarChart
              isLoading={false}
              graphData={group.chartData}
              graphHistoriesData={group.chartHistoriesData as SleepHistoriesItem[]}
            />
          )}
        </>
      )}

      <View style={styleContainer.sleepItemContainer}>
        {group.keys.map((key) => {
          const item = (SLEEP_KEY_MAP as any)[key];
          if ((sleepStatInfo as any)[key] == undefined) {
            return <></>;
          }
          return (
            <View style={styleContainer.sleepItem} key={key}>
              <Text category="c1" style={{ color: th['text-hint-color'] }}>
                {item.name}
              </Text>
              <Text category="s1">{SleepStatHelper.showValueAndUnit((sleepStatInfo as any)[key], item.unit)}</Text>
            </View>
          );
        })}
      </View>
    </View>
  );
};

export default SleepGroup;
