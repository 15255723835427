import { Platform, StyleSheet, Text, View, ViewProps, processColor } from 'react-native';
import React, { Suspense, lazy } from 'react';

import AssessmentScore from 'src/components/profileMeAssessment/assessmentDetail/AssessmentScore';
import { HealthMarkerHistoryValue } from '../../../core/types/HealthMarkerHistory';
import { LineData } from 'react-native-charts-wrapper';
import Spinner from 'react-native-loading-spinner-overlay/lib';
import moment from 'moment';
import { toPreciseNumber } from 'src/core/helpers/NumberHelper';
import { UIHelper as uh } from 'src/core';
import { useTheme } from '@ui-kitten/components';

//props
interface IScoreChartProps extends ViewProps {
  currentDate: Date;
  startDate: Date;
  endDate: Date;
  currentValue: number;
  scoreChanges: HealthMarkerHistoryValue[];
  filter: string;
  unit: string;
}

const ScoreChart = ({ currentValue, scoreChanges, filter, unit, style }: IScoreChartProps): JSX.Element => {
  const th = useTheme();

  // styles
  const styles = StyleSheet.create({
    container: { flexDirection: 'column', height: 300 },
    areaChart: { height: 98 },
    xAxis: {
      height: 20,
      paddingRight: 6,
      paddingLeft: 6,
      borderTopWidth: 1,
      borderTopColor: th['border-basic-color-4']
    },
    yAxis: {
      width: 20,
      paddingRight: 5,
      marginBottom: 20,
      borderRightWidth: 1,
      borderRightColor: th['border-basic-color-4']
    },
    divider: { backgroundColor: th['border-basic-color-4'] },
    webText: {
      textAlign: 'center',
      fontSize: 20,
      color: th['text-basic-color']
    }
  });

  if (scoreChanges?.length == 0) {
    return <></>;
  }

  type ChartValue = {
    value: number;
    date: Date;
  };

  let chartData = scoreChanges.map((x) => {
    return {
      value: x.value,
      date: x.date
    };
  });

  if (Platform.OS === 'android' && scoreChanges.length === 1)
    chartData.push({
      date: moment(moment(scoreChanges[0].date).valueOf() - 1000000).toDate(),
      value: scoreChanges[0].value
    });

  chartData.sort(function (a, b) {
    return moment(a.date).valueOf() - moment(b.date).valueOf();
  });

  const calculateAverageValue = (data: ChartValue[]): number => {
    const totalValue = data.reduce((sum, entry) => sum + entry.value, 0);
    return totalValue / data.length;
  };

  // Leave only the latest value for the current day
  const reducedChartData = chartData.reduce((prev: ChartValue[], curr: ChartValue): ChartValue[] => {
    const lastEntry = prev[prev.length - 1];
    if (lastEntry && moment(lastEntry.date).format('MMM D') === moment(curr.date).format('MMM D')) {
      prev[prev.length - 1] = moment(lastEntry.date).valueOf() > moment(curr.date).valueOf() ? lastEntry : curr;
      return prev;
    } else return [...prev, curr];
  }, []);

  // Filter values based on chosen time interval
  if (filter === '6M' || filter === 'Y') {
    const timeInterval = 'week';
    const maxEntries = filter === '6M' ? 25 : 52;

    const groupedChartData = reducedChartData.reduce((prev: { [key: string]: ChartValue[] }, curr: ChartValue) => {
      const interval = moment(curr.date).startOf(timeInterval);
      const intervalName = interval.format('w');
      if (!prev[intervalName]) {
        prev[intervalName] = [];
      }
      prev[intervalName].push(curr);
      return prev;
    }, {});

    chartData = Object.values(groupedChartData).reduce(
      (prev: ChartValue[], curr: ChartValue[], index: number): ChartValue[] => {
        if (prev.length < maxEntries) {
          const averageValue = calculateAverageValue(curr);
          const intervalLabel = `${timeInterval.charAt(0).toUpperCase()}${index + 1}`;
          const averageEntry = {
            date: moment(curr[0].date).toDate(),
            value: averageValue,
            label: `${intervalLabel}`
          };
          return [...prev, averageEntry];
        }
        return prev;
      },
      []
    );
  } else chartData = reducedChartData;
  chartData.sort((a, b) => (moment(b.date).isBefore(moment(a.date)) ? 1 : -1));
  const minValue = chartData.map((x) => x.value).sort((a, b) => a - b)[0];
  const maxValue = chartData.map((x) => x.value).sort((a, b) => b - a)[0];
  const range = maxValue - minValue;
  const graphData: LineData = {
    dataSets: [
      {
        values: chartData.map((item) => {
          return {
            y: item.value,
            x: moment(item.date).toDate().getTime(),
            marker: `${item.value.toFixed(2)} ${unit}\n${moment(item.date).utc().format('MMM D')}`
          };
        }),
        label: '',
        config: {
          color: processColor(th['color-primary-500']),
          valueTextColor: processColor(th['text-hint-color']),
          valueTextSize: 10,
          mode: 'LINEAR',
          drawCircles: true,
          circleColor: processColor(th['color-primary-500']),
          drawCircleHole: false,
          circleRadius: 3,
          drawValues: false,
          drawFilled: true,
          highlightEnabled: true,
          drawVerticalHighlightIndicator: range !== 0,
          highlightColor: processColor(th['color-primary-500']),
          fillGradient: {
            orientation: 'BOTTOM_TOP',
            colors: [processColor(th['background-basic-color-1']), processColor(th['color-primary-500'])],
            positions: [0, 0.7]
          },
          fillAlpha: 1000
        }
      }
    ]
  };

  if (Platform.OS === 'web') {
    return (
      <View>
        <View style={[styles.container, style]}>
          <Text style={styles.webText}>Pillar charts are disabled on the web app</Text>
        </View>
      </View>
    );
  } else {
    const PillarLineChart = lazy(() => import('../../shared/PillarLineChart'));
    return (
      <>
        <View>
          <AssessmentScore
            score={`${toPreciseNumber(currentValue)} ${unit}`}
            caption={
              moment(chartData[0].date).format('D MMM YYYY') +
              ' - ' +
              moment(chartData[chartData.length - 1].date).format('D MMM YYYY')
            }
            textCategory="h6"
            style={{ marginTop: uh.h2DP(8), marginLeft: uh.h2DP(16), marginRight: uh.h2DP(16) }}
          />
          <View style={[styles.container, style]}>
            <Text>{graphData.dataSets?.[0]?.label}</Text>
            <Suspense fallback={<Spinner visible={true} />}>
              <PillarLineChart
                data={graphData}
                style={{ flex: 1 }}
                animation={{
                  durationX: 500,
                  easingX: 'Linear'
                }}
                pinchZoom={true}
                highlights={range === 0 && Platform.OS !== 'android' ? [{ x: 0 }] : undefined}
                touchEnabled={chartData.length > 2}
                marker={
                  {
                    enabled: true,
                    markerColor: processColor('transparent'),
                    textColor: processColor(th['text-basic-color']),
                    textAlign: 'center'
                  } as any
                }
                xAxis={{
                  enabled: true,
                  drawGridLines: false,
                  position: 'BOTTOM',
                  textSize: 10,
                  textColor: processColor(th['text-hint-color']),
                  valueFormatter: 'date',
                  timeUnit: 'MILLISECONDS',
                  valueFormatterPattern: 'MMM dd',
                  labelCount: Math.min(chartData.length + 1, 6),
                  labelCountForce: true,
                  centerAxisLabels: true
                }}
                yAxis={{
                  left: {
                    enabled: true,
                    drawGridLines: false,
                    textSize: 10,
                    textColor: processColor(th['text-hint-color']),
                    axisMinimum: range !== 0 ? minValue - 0.2 * range : minValue - 0.2 * minValue,
                    axisMaximum: range !== 0 ? maxValue + 0.2 * range : maxValue + 0.2 * maxValue
                  },
                  right: { enabled: false }
                }}
                legend={false}
              />
            </Suspense>
          </View>
        </View>
      </>
    );
  }
};

export default ScoreChart;
