import { Animated, Image, TouchableOpacity, View } from 'react-native';
import {
  GestureEvent,
  GestureHandlerRootView,
  PanGestureHandler,
  PanGestureHandlerEventPayload,
  PinchGestureHandler,
  PinchGestureHandlerEventPayload,
  State
} from 'react-native-gesture-handler';
import { Icon, Modal, Spinner, Text, useTheme } from '@ui-kitten/components';
import React, { createRef, useEffect, useRef, useState } from 'react';

import { ChatMessageProps } from 'src/core/types/ChatMessage';
import { calculateScale } from './utils/calculateScale';
import { UIHelper as uh } from 'src/core';
import { useAnalytics } from 'src/core/hooks/useAnalytics';
import { useChat } from 'src/core/hooks/useChat';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';

const ChatImageMessage = ({ message, onLongPress }: ChatMessageProps) => {
  const [imageSrc, setImageSrc] = useState<string>('');
  const [scaleValue, setScaleValue] = useState<number>(1);
  const [imageSize, setImageSize] = useState({ height: 0, width: 0 });
  const [hasError, setHasError] = useState<boolean>(false);

  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
  const { addAnalyticsLog } = useAnalytics('ChatImageMessage.tsx');

  const scale = useRef(new Animated.Value(1)).current;
  const translateX = useRef(new Animated.Value(0)).current;
  const translateY = useRef(new Animated.Value(0)).current;

  const th = useTheme();

  const { getMedia } = useChat();

  const transMessages = {
    errorMessage: "Couldn't load image"
  };

  const t = useTranslationFunc(transMessages);

  const pinchRef = createRef();
  const panRef = createRef();

  const onPinchEvent = ({ nativeEvent }: GestureEvent<PinchGestureHandlerEventPayload>) => {
    scale.setValue(nativeEvent.scale);
  };

  scale.addListener((state) => {
    setScaleValue(state.value);
  });

  const onPanEvent = ({ nativeEvent }: GestureEvent<PanGestureHandlerEventPayload>) => {
    translateX.setValue(nativeEvent.translationX / scaleValue);
    translateY.setValue(nativeEvent.translationY / scaleValue);
  };

  const handlePinchStateChange = ({ nativeEvent }: GestureEvent<PinchGestureHandlerEventPayload>) => {
    const nScale = nativeEvent.scale;
    if (nativeEvent.state === State.END) {
      if (nScale < 1) {
        Animated.spring(scale, {
          toValue: 1,
          useNativeDriver: true
        }).start();
        Animated.spring(translateX, {
          toValue: 0,
          useNativeDriver: true
        }).start();
        translateX.setOffset(0);

        Animated.spring(translateY, {
          toValue: 0,
          useNativeDriver: true
        }).start();
        translateY.setOffset(0);
      }
    }
  };

  const handlePanStateChange = ({ nativeEvent }: GestureEvent<PanGestureHandlerEventPayload>) => {
    if (nativeEvent.state === 5) {
      translateX.stopAnimation();
      Animated.spring(translateX, {
        toValue: 0,
        useNativeDriver: true
      }).start();
      translateX.setOffset(0);

      translateY.stopAnimation();
      Animated.spring(translateY, {
        toValue: 0,
        useNativeDriver: true
      }).start();
      translateY.setOffset(0);
    } else if (scaleValue > 1) {
      translateX.extractOffset();
      translateY.extractOffset();
    }
  };

  useEffect(() => {
    if (imageSrc) {
      Image.getSize(imageSrc, (width, height) => {
        const imageScale = calculateScale(width, height);
        setImageSize({ width: width * imageScale, height: height * imageScale });
      });
    }
  }, [imageSrc]);

  useEffect(() => {
    if (message.isSender) {
      setImageSrc(message.image ?? '');
    } else {
      if (message.image) {
        getMedia(message.image)
          .then((imgSrc) => setImageSrc(imgSrc))
          .catch((err) => {
            addAnalyticsLog({ function: 'getMedia()', data: err, logType: 'error' });
            setHasError(true);
          });
      }
    }
  }, [addAnalyticsLog, getMedia, message.image, message.isSender]);

  if (hasError) {
    return (
      <View
        style={{
          height: uh.h2DP(128),
          width: uh.h2DP(128),
          borderWidth: 1,
          borderRadius: 8,
          alignContent: 'center',
          justifyContent: 'center',
          borderColor: 'red'
        }}>
        <Text style={{ textAlign: 'center' }} status="danger">
          {t('errorMessage')}
        </Text>
      </View>
    );
  }

  if (!imageSrc) {
    return (
      <View style={{ height: uh.h2DP(108), width: uh.h2DP(108) }}>
        <Spinner size="giant" />
      </View>
    );
  }
  return (
    <>
      <TouchableOpacity
        onLongPress={() => onLongPress && onLongPress(message._id.toString())}
        onPress={() => setIsFullscreen(true)}>
        <Image
          source={{ uri: imageSrc }}
          style={{
            height: imageSize.height,
            width: imageSize.width,
            borderRadius: 8,
            resizeMode: 'contain'
          }}
        />
      </TouchableOpacity>
      {isFullscreen && (
        <Modal
          visible={isFullscreen}
          onBackdropPress={() => {
            setIsFullscreen(false);
            translateX.setOffset(0);
            translateY.setOffset(0);
            scale.setValue(1);
          }}
          style={{
            width: '100%',
            height: '100%',
            justifyContent: 'center'
          }}
          backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.9)' }}>
          <TouchableOpacity
            onPress={() => {
              setIsFullscreen(false);
              translateX.setOffset(0);
              translateY.setOffset(0);
              scale.setValue(1);
            }}
            style={{
              position: 'absolute',
              top: uh.h2DP(42),
              left: uh.h2DP(24),
              zIndex: 999
            }}>
            <Icon height={uh.h2DP(32)} width={uh.h2DP(32)} fill={th['color-basic-100']} name="close-outline" />
          </TouchableOpacity>
          <GestureHandlerRootView
            style={{
              width: uh.currentViewPort(),
              height: (uh.currentViewPort() * imageSize.height) / imageSize.width
            }}>
            <PanGestureHandler
              ref={panRef}
              onGestureEvent={onPanEvent}
              simultaneousHandlers={[pinchRef]}
              onHandlerStateChange={handlePanStateChange}>
              <Animated.View style={{}}>
                <PinchGestureHandler
                  ref={pinchRef}
                  simultaneousHandlers={[panRef]}
                  onGestureEvent={onPinchEvent}
                  onHandlerStateChange={handlePinchStateChange}>
                  <Animated.Image
                    source={{ uri: imageSrc }}
                    style={{
                      width: '100%',
                      height: '100%',
                      transform: [{ scale }, { translateX }, { translateY }]
                    }}
                    resizeMode="contain"
                  />
                </PinchGestureHandler>
              </Animated.View>
            </PanGestureHandler>
          </GestureHandlerRootView>
        </Modal>
      )}
    </>
  );
};

export default ChatImageMessage;
