import { Platform, RefreshControl as ReactNativeRefreshControl } from 'react-native';
import React, { memo } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { RefreshControl as WebRefreshControl } from 'react-native-web-refresh-control';
import { useAppStore } from 'src/core/store';
import { useTheme } from '@ui-kitten/components';

type Props = React.ComponentProps<typeof ReactNativeRefreshControl>;

const RefreshControl = ({ refreshing, onRefresh, ...props }: Props) => {
  const th = useTheme();
  const theme = useAppStore((state) => state.theme);

  if (Platform.OS === 'web') {
    return (
      <WebRefreshControl
        refreshing={refreshing}
        onRefresh={onRefresh}
        colors={theme == 'light' ? [th['color-primary-900']] : ['white']}
        tintColor={theme == 'light' ? [th['color-primary-900']] : ['white']}
        {...props}
      />
    );
  }
  // Else use default native solution (iOS/Android)
  return (
    <ReactNativeRefreshControl
      refreshing={refreshing}
      onRefresh={onRefresh}
      colors={theme == 'light' ? [th['color-primary-900']] : ['white']}
      tintColor={theme == 'light' ? th['color-primary-900'] : 'white'}
      {...props}
    />
  );
};

export default memo(RefreshControl);
