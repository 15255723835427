import { Text } from '@ui-kitten/components';
import React from 'react';
import { ViewProps, StyleSheet, View } from 'react-native';
// import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';
import { UIHelper as uh, PillarScore } from '../../core';
import WarningIcon from './conponents/icons/WarningIcon';

//props
interface ScoreAlertProps extends ViewProps {
  pillarScore: PillarScore;
}

const SCORE_SCUCCESS_VALUE = 79.9;

// styles
const styleContainer = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    backgroundColor: '#FFFDF2',
    borderWidth: 1,
    borderColor: '#FFF1C2',
    borderRadius: 8,
    padding: 16,
    columnGap: uh.w2DP(8)
  }
});

const ScoreAlert = ({ pillarScore }: ScoreAlertProps) => {
  //const percentChanged = Number((Math.abs(pillarScore.scoreChanged) * 100) / pillarScore.score).toFixed(0);
  // const transMessage = {
  //   scoreBadUp: 'Your {{name}} Score needs attention. It has increased by {{percent}}% this week.',
  //   scoreBadDown: 'Your {{name}} Score needs attention. It has slipped by {{percent}}% this week.',
  //   scoreGoodUp: 'Kudos! Your {{name}} score has gone down by {{percent}}% in a week.',
  //   scoreGoodDown: 'Kudos! Your {{name}} score has gone up by {{percent}}% in a week.'
  // };
  // const t = useTranslationFunc(transMessage);
  // const msg = `score${pillarScore.score >= SCORE_SCUCCESS_VALUE ? 'Good' : 'Bad'}${
  //   pillarScore.scoreChanged >= 0 ? 'Up' : 'Down'
  // }`;

  // view
  return (
    <View>
      <View style={styleContainer.container}>
        {pillarScore.score >= SCORE_SCUCCESS_VALUE ? <Text>🎉</Text> : <WarningIcon />}
        <Text category="s2" style={{ color: '#222B45' }}>
          {pillarScore.alertText}
          {/* {t(msg, { name: pillarScore.name, percent: percentChanged })} */}
        </Text>
      </View>
    </View>
  );
};

export default ScoreAlert;
