import Brands from 'brands.json';
import { useAppStore } from '../store';

export const useGetAppName = () => {
  return useAppStore((state) => state.app_name) || Brands.app_name;
};

export const useGetChatbotName = () => {
  return useAppStore((state) => state.chatbot_name);
};

export const useGetAppWebsite = () => {
  return useAppStore((state) => state.app_website);
};

export const useGetTermsOfService = () => {
  return useAppStore((state) => state.terms_of_service);
};

export const useGetPrivacyPolicy = () => {
  return useAppStore((state) => state.privacy_policy);
};

export const useGetDataHandlingWebsite = () => {
  return useAppStore((state) => state.data_handling_website);
};

export const useGetDefaultSubscription = () => {
  return useAppStore((state) => state.default_subscription);
};

export const useGetSupportWebsite = () => {
  return useAppStore((state) => state.support_website);
};

export const useGetWebSocketUrl = () => {
  return useAppStore((state) => state.chat_websocket_url);
};
