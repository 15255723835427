import { useCallback, useEffect, useState } from 'react';

import { HealthMarker } from '../types/Bloodwork';
import { HealthMarkerReportListItem } from '../types/HealthMarkerReportList';
import HealthMarkerService from 'src/api/healthMarkers';
import { useAnalytics } from './useAnalytics';
import { useAppStore } from '../store';

type UseAssessments = (
  refreshing: boolean,
  finishRefreshing: () => void
) => [Map<string, HealthMarkerReportListItem[]>, string[], number, boolean, any];

export const useAssessments: UseAssessments = (refreshing, finishRefreshing) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [assessmentsGroup, setAssessmentsGroup] = useState<Map<string, HealthMarkerReportListItem[]>>(new Map());
  const [groupNames, setGroupNames] = useState<string[]>([]);
  const [totalAssessments, setTotalAssessments] = useState<number>(0);
  const [error, setError] = useState<any>();

  const { addAnalyticsLog } = useAnalytics('useAssessments.ts');

  const getAssessments = useCallback(
    (promise: Promise<HealthMarkerReportListItem[]>) => {
      setLoading(true);
      promise
        .then((healthMarkerList) => {
          const group = new Map<string, HealthMarkerReportListItem[]>();
          for (const item of healthMarkerList) {
            if (group.has(String(item.assessmentId))) {
              group.get(String(item.assessmentId))?.push(item);
              continue;
            }
            group.set(String(item.assessmentId), [item]);
          }

          setAssessmentsGroup(group);
          setGroupNames([...group.keys()]);
          setTotalAssessments(
            [...group.values()].reduce((prev, curr) => {
              prev += curr.length;
              return prev;
            }, 0)
          );
        })
        .catch((err) => {
          addAnalyticsLog({ data: err, function: 'useEffect', logType: 'error' });
          setError(err);
        })
        .finally(() => {
          setLoading(false);
          finishRefreshing();
        });
    },
    [addAnalyticsLog, finishRefreshing]
  );

  useEffect(() => {
    if (assessmentsGroup.size === 0) {
      const { promise, abort } = HealthMarkerService.getUserAssessments();
      getAssessments(promise);
      return abort;
    }
  }, [assessmentsGroup.size, getAssessments]);

  useEffect(() => {
    if (refreshing) {
      const { promise, abort } = HealthMarkerService.getUserAssessments();
      getAssessments(promise);
      return abort;
    }
  }, [getAssessments, refreshing]);

  return [assessmentsGroup, groupNames, totalAssessments, loading, error];
};

export const useGetPendingBloodwork = (refreshing: boolean) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  const [documentId, setDocumentId] = useState<string>();

  const { addAnalyticsLog } = useAnalytics('useAssessments.ts');

  const getPendingBloodwork = useCallback(
    (promise: Promise<string>) => {
      setLoading(true);
      promise
        .then((bloodworkId: string) => {
          setDocumentId(bloodworkId);
        })
        .catch((err) => {
          addAnalyticsLog({ function: 'getPendingBloodWork', data: err, logType: 'error' });
          setError(err);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [addAnalyticsLog]
  );

  useEffect(() => {
    if (!documentId && documentId !== '') {
      const { promise, abort } = HealthMarkerService.getPendingBloodwork();
      getPendingBloodwork(promise);
      return abort;
    }
  }, [documentId, getPendingBloodwork]);

  useEffect(() => {
    if (refreshing) {
      const { promise, abort } = HealthMarkerService.getPendingBloodwork();
      getPendingBloodwork(promise);
      return abort;
    }
  }, [getPendingBloodwork, refreshing]);

  return [documentId, loading, error];
};

export const useLoadHealthMarkerUnits = () => {
  const { addAnalyticsLog } = useAnalytics('useAssessments.ts');

  const healthMarkers = useAppStore((state) => state.healthMarkers);
  const setHealthMarkers = useAppStore((state) => state.setHealthMarkers);

  const loadHealthMarkers = useCallback(
    (promise: Promise<HealthMarker[]>) => {
      promise
        .then((units) => {
          setHealthMarkers(units.filter((unit: HealthMarker) => unit.healthMarkerName));
        })
        .catch((err) => {
          addAnalyticsLog({ function: 'getHealthMarkersAndUnits', data: err, logType: 'error' });
        });
    },
    [addAnalyticsLog, setHealthMarkers]
  );

  useEffect(() => {
    if (healthMarkers.length === 0) {
      const { promise, abort } = HealthMarkerService.getUnitDetails();
      loadHealthMarkers(promise);
      return abort;
    }
  }, [healthMarkers.length, loadHealthMarkers]);
};
