import { Icon, IconProps } from '@ui-kitten/components';

import Brands from 'brands.json';
import React from 'react';

export const ProfileIcons = {
  AppleWatchIcon: (props: IconProps) => {
    return <Icon {...props} name="apple-watch" pack={Brands.icon_pack} />;
  },
  HeadspaceIcon: (props: IconProps) => {
    return <Icon {...props} name="headspace" pack={Brands.icon_pack} />;
  },
  DownIcon: (props: IconProps) => {
    return <Icon {...props} name="chevron-down-outline" />;
  },
  UpIcon: (props: IconProps) => {
    return <Icon {...props} name="chevron-up-outline" />;
  },
  ForwardIcon: (props: IconProps) => {
    return <Icon {...props} name="chevron-right-outline" />;
  },
  SearchIcon: (props: IconProps) => {
    return <Icon {...props} name="search-outline" />;
  }
};
