import { Text } from '@ui-kitten/components';
import React from 'react';
import { ViewProps, StyleSheet, View, TouchableWithoutFeedback } from 'react-native';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';
import AddIcon from './icons/AddIcon';

//props
interface CreateActionButtonProps extends ViewProps {
  onClick?: () => void;
}

// styles
const styleContainer = StyleSheet.create({
  container: {
    flexDirection: 'row',
    columnGap: 8,
    marginTop: 8,
    flex: 1
  },
  createAction: {
    color: '#46D7CB'
  }
});

const CreateActionButton = ({ onClick }: CreateActionButtonProps) => {
  const transMessage = {
    createAction: 'Create Action'
  };
  const t = useTranslationFunc(transMessage);

  // view
  return (
    <TouchableWithoutFeedback onPress={onClick}>
      <View style={styleContainer.container}>
        <AddIcon />
        <Text category="s2" style={styleContainer.createAction}>
          {t('createAction')}
        </Text>
      </View>
    </TouchableWithoutFeedback>
  );
};

export default CreateActionButton;
