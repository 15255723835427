import Animated, { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';
import { Layout, Text, useTheme } from '@ui-kitten/components';
import React, { useState } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import { ActionItem } from 'src/core/types/ActionItem';
import DownwardIcon from './DownwardIcon';
import ForwardIcon from '../../home/components/ForwardIcon';
import { PillarContainer } from 'src/components/home';
import UpwardIcon from './UpwardIcon';
import { UIHelper as uh } from 'src/core';
import { useIsLightColorScheme } from 'src/core/hooks/useIsLightColorScheme';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';

interface ActionListItemProps {
  action: ActionItem;
  onPress?: () => void;
  duration?: number;
  alertTime?: string;
  expandable?: boolean;
  startDay?: number;
  endDay?: number;
  frequency?: number;
}
const ActionListItem = ({
  action,
  onPress,
  duration,
  alertTime,
  expandable,
  startDay,
  endDay,
  frequency
}: ActionListItemProps) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [height, setHeight] = useState<number>(0);
  const animatedHeight = useSharedValue(0);

  const styleContainer = StyleSheet.create({
    layout: {
      borderRadius: 8,
      marginVertical: uh.h2DP(6),
      padding: uh.h2DP(12)
    },
    container: {
      flexDirection: 'row',
      justifyContent: 'center'
    },
    text: {
      fontSize: uh.h2DP(12)
    },
    textContainer: {
      position: 'absolute',
      gap: uh.h2DP(12),
      paddingTop: uh.h2DP(12),
      width: '100%'
    },
    row: { width: '75%', flexDirection: 'row', justifyContent: 'space-between' },
    rowItem: { gap: uh.h2DP(4), width: '40%' }
  });

  const transMessage = {
    timeLabel: 'START TIME',
    durationLabel: 'DURATION',
    startDayLabel: 'START DAY',
    endDayLabel: 'END DAY',
    frequencyLabel: 'FREQUENCY'
  };

  const t = useTranslationFunc(transMessage);

  const isLightTheme = useIsLightColorScheme();
  const th = useTheme();

  const collapsableStyle = useAnimatedStyle(() => {
    animatedHeight.value = expanded ? withTiming(height) : withTiming(0);

    return {
      height: animatedHeight.value
    };
  }, [expanded, height]);

  return (
    <Layout level="1" style={styleContainer.layout}>
      <View style={styleContainer.container}>
        <PillarContainer type={action.pillar.toLowerCase()} size={'large'} />
        <View style={{ flex: 1, marginHorizontal: uh.h2DP(12), paddingVertical: uh.h2DP(5) }}>
          <Text category="h6">{action.name}</Text>
          <Animated.View style={[collapsableStyle, { overflow: 'hidden' }]}>
            <View style={styleContainer.textContainer} onLayout={(e) => setHeight(e.nativeEvent.layout.height)}>
              <Text category="p2">{action.text}</Text>
              <View style={styleContainer.row}>
                <View style={styleContainer.rowItem}>
                  <Text category="label" style={[styleContainer.text, { color: th['color-basic-600'] }]}>
                    {t('timeLabel')}
                  </Text>
                  <Text category="s1" style={styleContainer.text}>
                    {alertTime ?? action.startTimes?.[0]}
                  </Text>
                </View>
                <View style={styleContainer.rowItem}>
                  <Text category="label" style={[styleContainer.text, { color: th['color-basic-600'] }]}>
                    {t('durationLabel')}
                  </Text>
                  <Text category="s1" style={styleContainer.text}>
                    {`${duration ?? action.durationDays} DAYS`}
                  </Text>
                </View>
              </View>
              {startDay && endDay && (
                <View style={styleContainer.row}>
                  <View style={styleContainer.rowItem}>
                    <Text category="label" style={[styleContainer.text, { color: th['color-basic-600'] }]}>
                      {t('startDayLabel')}
                    </Text>
                    <Text category="s1" style={styleContainer.text}>
                      {startDay}
                    </Text>
                  </View>
                  <View style={styleContainer.rowItem}>
                    <Text category="label" style={[styleContainer.text, { color: th['color-basic-600'] }]}>
                      {t('endDayLabel')}
                    </Text>
                    <Text category="s1" style={styleContainer.text}>
                      {endDay}
                    </Text>
                  </View>
                </View>
              )}
              {frequency && (
                <View style={styleContainer.row}>
                  <View style={{ gap: uh.h2DP(4), width: '40%' }}>
                    <Text category="label" style={[styleContainer.text, { color: th['color-basic-600'] }]}>
                      {t('frequencyLabel')}
                    </Text>
                    <Text category="s1" style={styleContainer.text}>
                      {frequency}
                    </Text>
                  </View>
                </View>
              )}
            </View>
          </Animated.View>
        </View>
        {!expandable ? (
          <TouchableOpacity style={{ alignSelf: 'center' }} onPress={onPress}>
            <ForwardIcon color={isLightTheme ? 'black' : 'white'} />
          </TouchableOpacity>
        ) : (
          <TouchableOpacity onPress={() => setExpanded((prev) => !prev)} style={{ paddingTop: uh.h2DP(5) }}>
            {expanded ? (
              <UpwardIcon color={isLightTheme ? 'black' : 'white'} />
            ) : (
              <DownwardIcon color={isLightTheme ? 'black' : 'white'} />
            )}
          </TouchableOpacity>
        )}
      </View>
    </Layout>
  );
};

export default ActionListItem;
