import { ActionTip, PillarScore, ScoreDriver, ScoreItem, UIHelper as uh } from 'src/core';
import { StyleSheet, View, ViewProps } from 'react-native';

import { Layout } from '@ui-kitten/components';
import React from 'react';
import ScoreAlert from './ScoreAlert';
import ScoreDrivers from './ScoreDrivers';
import ScoreHistory from './ScoreHistory';
import ScoreSummary from './ScoreSummary';
import ScoreTips from './ScoreTips';
import Spinner from 'react-native-loading-spinner-overlay/lib';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';

//props
interface PillarDetailProps extends ViewProps {
  pillarScore: PillarScore;
  scoreHistories: ScoreItem[];
  actionTips: ActionTip[];
  scoreDrivers: ScoreDriver[];
  isLoading: boolean;
  onCreateAction: (actionId: number) => void;
}

//styles
const styleContainer = StyleSheet.create({
  contentContainer: {
    rowGap: uh.h2DP(16)
  },
  loadingContainer: {
    padding: uh.h2DP(16),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});

const PillarDetail = ({
  pillarScore,
  scoreHistories,
  actionTips,
  scoreDrivers,
  isLoading,
  onCreateAction
}: PillarDetailProps) => {
  const transMessage = {
    insights: 'INSIGHTS',
    tipsToImprove: 'TIPS TO IMPROVE FURTHER'
  };
  const t = useTranslationFunc(transMessage);

  if (isLoading) {
    return (
      <View style={styleContainer.loadingContainer}>
        <Spinner visible={true} />
      </View>
    );
  }

  return (
    <Layout level="2" style={[styleContainer.contentContainer]}>
      {pillarScore.alertText?.length > 0 && <ScoreAlert pillarScore={pillarScore as PillarScore} />}
      <ScoreSummary pillarScore={pillarScore as PillarScore} />
      <ScoreHistory isLoading={false} pillarScore={pillarScore as PillarScore} scoreItems={scoreHistories ?? []} />
      {pillarScore?.id !== '0' && <ScoreDrivers pillar={pillarScore?.type} scoreDrivers={scoreDrivers} />}
      <ScoreTips
        caption={pillarScore.alertText?.length ? t('tipsToImprove') : t('insights')}
        actionTips={actionTips}
        pillar={pillarScore.name}
        onCreateAction={onCreateAction}
      />
    </Layout>
  );
};

export default PillarDetail;
