import { Text } from '@ui-kitten/components';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';

// styles
const styleContainer = StyleSheet.create({
  container: {
    height: 60
  }
});

const NoGraphData = () => {
  const transMessage = {
    noGraphData: 'No graph data'
  };
  const t = useTranslationFunc(transMessage);

  // view
  return (
    <View>
      <View style={styleContainer.container}>
        <Text appearance="hint" style={{ textAlign: 'center' }}>
          {t('noGraphData')}
        </Text>
      </View>
    </View>
  );
};

export default NoGraphData;
