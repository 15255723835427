import { Card, Icon, Input, Modal, Text, useTheme } from '@ui-kitten/components';
import React, { memo, useState } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import { WheelPicker, WheelPickerItemProps } from 'react-native-ui-lib';

import { UIHelper as uh } from 'src/core';
import { useIsLightColorScheme } from 'src/core/hooks/useIsLightColorScheme';

interface WheelPickerInputProps {
  items: WheelPickerItemProps[];
  initialValue: string | number;
  onChange: (item: string | number, index: number) => void;

  backdropStyle?: StyleProp<ViewStyle>;

  value: string | number;

  label?: string;
  labelStyle?: StyleProp<ViewStyle>;

  inputStyle?: StyleProp<ViewStyle>;

  onLayout?: (event: any) => void;

  accessibilityLabel?: string;
  testID?: string;
}

const WheelPickerInput = ({
  items,
  initialValue,
  onChange,
  backdropStyle,
  label,
  labelStyle,
  inputStyle,
  value,
  onLayout,
  accessibilityLabel,
  testID
}: WheelPickerInputProps) => {
  const [visibleWheel, setVisibleWheel] = useState<boolean>(false);
  const th = useTheme();
  const isLightScheme = useIsLightColorScheme();
  return (
    <>
      <Modal visible={visibleWheel} backdropStyle={backdropStyle} onBackdropPress={() => setVisibleWheel(false)}>
        <Card>
          {/* TODO: Fix initial press input range */}
          <WheelPicker
            items={items}
            initialValue={initialValue}
            onChange={onChange}
            style={{ width: uh.h2DP(200), backgroundColor: 'transparent' }}
            inactiveTextColor={th[`color-basic-${isLightScheme ? 600 : 300}`]}
            activeTextColor={th['color-primary-500']}
            faderProps={{ tintColor: 'transparent' }}
            numberOfVisibleRows={5}
          />
        </Card>
      </Modal>
      <View onLayout={onLayout}>
        {label && (
          <Text category="label" appearance="hint" style={labelStyle}>
            {label}
          </Text>
        )}
        <Input
          size="large"
          style={inputStyle}
          disabled
          onPressOut={() => setVisibleWheel(true)}
          maxLength={36}
          status={'basic'}
          accessible={true}
          accessibilityLabel={accessibilityLabel}
          testID={testID}
          value={value.toString()}
          accessoryRight={<Icon name={'chevron-down-outline'} onPress={() => setVisibleWheel(true)} />}
        />
      </View>
    </>
  );
};

export default memo(WheelPickerInput);
