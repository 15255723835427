import { StyleSheet, View, ViewProps } from 'react-native';
import React from 'react';
import { UIHelper as uh } from '../../core';
import PillarCheckboxListItem from './components/PillarCheckboxListItem';

//props
interface PillarCheckboxListProps extends ViewProps {
  pillars: Array<any>;
  onChange: (name: string, checked: boolean) => void;
  style?: any;
}

// styles
const styleContainer = StyleSheet.create({
  container: { flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap' },
  item: { marginTop: uh.h2DP(16), marginRight: uh.w2DP(16) }
});

const PillarCheckboxList = ({ pillars, onChange, style }: PillarCheckboxListProps) => {
  const renderItems = () => {
    return pillars.map((item, index) => {
      return (
        <PillarCheckboxListItem
          style={styleContainer.item}
          key={`item-index-${index}`}
          disabled={item.disabled}
          name={item.name}
          type={item.type}
          checked={item.checked}
          onChange={(checked) => {
            onChange(item.name, checked);
          }}
        />
      );
    });
  };

  // view
  return (
    <View style={[styleContainer.container, style]}>
      <View style={styleContainer.container}>{renderItems()}</View>
    </View>
  );
};

export default PillarCheckboxList;
