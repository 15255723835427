import { FlatList, StyleSheet, TouchableOpacity, View, ViewProps } from 'react-native';
import { Input, useTheme } from '@ui-kitten/components';
import React, { useRef } from 'react';

import AddIcon from './components/icons/AddIcon';
import { AssessmentBiomarker } from 'src/components/profileMeAssessment';
import DeleteFavoriteBottomSheet from './components/DeleteFavoriteBottomSheet';
import { Dropdown } from 'src/components/profileMeMarker';
import { NewHealthMarker } from 'src/core/types/HealthMarkerReport';
import RightIcon from './components/RightIcon';
import { SortedType } from 'src/core/types/Marker';
import { useFilterMarkers } from 'src/core/hooks/useFilterMarkers';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';

//props
interface FavoriteListProps extends ViewProps {
  favoriteMarkers: NewHealthMarker[];
  currentPageNumber: number;
  onAddFavoriteBtnClick: () => void;
  onDeleteFavoriteMarker: (key: string) => void;
  style?: any;
}

const styleContainer = StyleSheet.create({
  searchText: { height: 40, flex: 1 },
  searchArea: {
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 8,
    marginLeft: 16,
    marginRight: 16,
    marginTop: 16
  },
  markerItem: { borderRadius: 8, marginTop: 16, marginLeft: 16, marginRight: 16 }
});

const FavoriteList = ({
  favoriteMarkers,
  currentPageNumber,
  onAddFavoriteBtnClick,
  onDeleteFavoriteMarker,
  style
}: FavoriteListProps) => {
  const { filterData } = useFilterMarkers();
  const transMessage = {
    searchPlaceHorder: 'Search'
  };
  const th = useTheme();
  const t = useTranslationFunc(transMessage);
  const deactivateSheetRef = useRef<any>(null);
  const [deleledMarker, setDeletedMarker] = React.useState<NewHealthMarker>();
  const [pageNumber, setPageNumber] = React.useState<number>(currentPageNumber);
  const [searchText, setSearchText] = React.useState<string>('');
  const [sortedType, setSortedType] = React.useState<SortedType>(SortedType.MOST_RECENT);
  const RECORDS_PER_PAGE = 10;

  const renderBiomarker = ({ item, index }: any) => {
    return (
      <AssessmentBiomarker
        key={`biomarker-item-${index}`}
        biomarker={item}
        style={styleContainer.markerItem}
        accessibilityLabel={`Biomarker ${index}`}
        testID={`biomarker-${index}`}
        showDeleteButton={true}
        onBtnDeleteClick={() => {
          setDeletedMarker(item);
          if (!deactivateSheetRef.current) {
            return;
          }
          deactivateSheetRef.current.snapToIndex(0);
          deactivateSheetRef.current.present();
        }}
      />
    );
  };

  const changeSortedType = (type: SortedType) => {
    setSortedType(type);
  };

  const numberOfItems = (pageNumber + 1) * RECORDS_PER_PAGE;
  const filteredMarkers = filterData(favoriteMarkers, searchText, sortedType).slice(0, numberOfItems);

  const listHeader = () => {
    return (
      <>
        <View style={styleContainer.searchArea}>
          <Dropdown changeSortedType={changeSortedType} />
          <Input
            style={styleContainer.searchText}
            value={searchText}
            placeholder={t('searchPlaceHorder')}
            accessoryRight={
              <RightIcon
                isSearching={searchText.length > 0 ? true : false}
                onClick={() => {
                  setSearchText('');
                }}
              />
            }
            onChangeText={(nextValue) => setSearchText(nextValue)}
            accessible={true}
            accessibilityLabel={'my markers searchbar'}
            testID={'my_markers_searchbar'}
          />
          <TouchableOpacity onPress={onAddFavoriteBtnClick} testID={'my_markers_add'}>
            <AddIcon color={th['color-primary-500']} size={36} />
          </TouchableOpacity>
        </View>
      </>
    );
  };

  // view
  return (
    <>
      {listHeader()}
      <FlatList
        style={style}
        data={filteredMarkers}
        renderItem={renderBiomarker}
        onEndReached={() => {
          setPageNumber((page) => page + 1);
        }}
        onEndReachedThreshold={0}></FlatList>
      <DeleteFavoriteBottomSheet
        sheetRef={deactivateSheetRef}
        biomarker={deleledMarker}
        onBtnClick={(key) => {
          onDeleteFavoriteMarker(key);
        }}
      />
    </>
  );
};

export default FavoriteList;
