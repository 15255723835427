import { FrequencyType, SleepDurationHistoriesItem, SleepHistoriesItem } from 'src/core/types/SleepStat';
import moment from 'moment';
import { ScoreItem } from 'src/core';
import { Platform } from 'react-native';

const getHistoriesData = (
  originChartHistoriesData: (SleepHistoriesItem | SleepDurationHistoriesItem)[],
  frequencyType: FrequencyType
) => {
  if (originChartHistoriesData == undefined) {
    return [];
  }
  const startDate = moment().subtract(frequencyType === FrequencyType.Week ? 7 : 30, 'd');

  // sort chart data's date by asc
  const newchartHistoriesData = originChartHistoriesData.sort(function (a, b) {
    return moment(a.date).diff(b.date, 'seconds');
  });
  return newchartHistoriesData?.filter((item) => moment(item.date).isSameOrAfter(startDate));
};

const getChartDataAndRange = (originChartData: SleepHistoriesItem[]) => {
  if (Platform.OS === 'android' && originChartData.length === 1) {
    originChartData.push({
      date: moment(originChartData[0].date).subtract(135, 'seconds').toDate(),
      value: originChartData[0].value
    });
  }

  // sort chart data's date by asc
  const chartData = originChartData.sort(function (a, b) {
    return moment(a.date).diff(b.date, 'seconds');
  });

  // group chart data by date
  chartData.reduce((prev: ScoreItem[], curr: ScoreItem): ScoreItem[] => {
    if (prev.length === 0) {
      return [{ date: curr.date, value: curr.value }];
    }

    if (prev[prev.length - 1]?.date != curr.date) {
      return [...prev, { date: curr.date, value: curr.value }];
    }
    if (curr.value > prev[prev.length - 1].value) {
      prev[prev.length - 1].value = curr.value;
    }
    return prev;
  }, []);

  const values = chartData.map((x) => x.value);
  const minValue = Math.min(...values);
  const maxValue = Math.max(...values);
  const range = maxValue - minValue;

  return { chartData, range };
};

export const ChartHelper = {
  getHistoriesData: (
    chartHistoriesData: (SleepHistoriesItem | SleepDurationHistoriesItem)[],
    frequencyType: FrequencyType
  ) => {
    return getHistoriesData(chartHistoriesData, frequencyType);
  },
  getChartDataAndRange: (originChartData: SleepHistoriesItem[]) => {
    return getChartDataAndRange(originChartData);
  }
};
