import * as IAP from 'react-native-iap';

import { Button, Card, Input, Layout, List, Text, useTheme } from '@ui-kitten/components';
import { Linking, ListRenderItemInfo, Platform, SafeAreaView, StyleSheet, View } from 'react-native';
import React, { useEffect, useRef, useState } from 'react';

import { ErrorModal } from 'src/components/shared/ErrorModal';
import { RequestSubscriptionResult } from 'src/core/types/SubscriptionInfo';
import Spinner from 'react-native-loading-spinner-overlay';
import UserService from '../../api/user';
import { settingStyles } from './_settingStyles';
import { UIHelper as uh } from 'src/core';
import { useAnalytics } from 'src/core/hooks/useAnalytics';
import { useAppStore } from 'src/core/store';
import { useNavigation } from '@react-navigation/native';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';

const SubscriptionsScreen = () => {
  const [products, setProducts] = useState([]);
  const [userSubs, setUserSubs] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isConnected, setIsConnected] = useState(false);
  const [isPremiumSubscription, setIsPremiumSubscription] = useState(false);
  const [visibleErrorModal, setVisibleErrorModal] = useState(false);
  const [error, setError] = useState<any>({});
  let subscriptionName: string;

  const navigation = useNavigation();
  const th = useTheme();
  const theme = useAppStore((state) => state.theme);
  const { addAnalyticsLog } = useAnalytics('SubscriptionScreen.tsx');
  const userSubscriptionPlan = useAppStore((state) => state.userSubscriptionPlan);
  const tenantFeatures = useAppStore((state) => state.tenantFeatures);

  const isSubscriptionsFetched = useRef(false);
  const featureKeys = tenantFeatures.map((tenantFeature) => tenantFeature.key);
  const scanFeatureKeys = ['AHIBodyScanFeature', 'SprenHRVScanFeature', 'SprenHRVScanFeature'];

  const transMessage = {
    subscribe: 'Subscribe',
    cancelSubscription: 'Manage on app store',
    checkConnection: 'Cannot connect to the app store...',
    premiumSubscription: 'You have an active subscription.'
  };

  const t = useTranslationFunc(transMessage);

  const subscriptionSkus = Platform.select({
    ios: [
      'kstage_2_99_pln_6m',
      'stage_monthly_enhanced_plan_19.99_usd',
      'preprod_monthly_enhanced_plan_19.99_usd',
      'prod_monthly_enhanced_plan_19.99_usd'
    ],
    android: [
      'kstage_2_99_pln_6m',
      'stage_monthly_enhanced_plan_19.99_usd',
      'preprod_monthly_enhanced_plan_19.99_usd',
      'prod_monthly_enhanced_plan_19.99_usd'
    ]
  });

  const styleContainer = StyleSheet.create({
    screenContainer: { flex: 1 },
    safeAreaView: { flex: 1 },
    listContainer: { justifyContent: 'center', alignContent: 'center', alignItems: 'center', marginTop: -16 },
    input: { backgroundColor: uh.getHex(th, theme, 'color-basic-100', 'color-basic-1100'), marginTop: 16 },
    button: { width: '100%' },
    container: { maxHeight: '100%', width: '100%' },
    contentContainer: { paddingHorizontal: 0, paddingVertical: 4 },
    item: { marginVertical: 4, borderWidth: 2 },
    annotation: {
      fontStyle: 'italic',
      fontSize: 10,
      color: th['color-basic-500'],
      textAlign: 'center',
      marginBottom: 10
    },
    text: { marginTop: 16 }
  });

  useEffect(() => {
    setUserSubs(userSubscriptionPlan);
    const fetchData = () => {
      if (Platform.OS !== 'web') {
        IAP.initConnection()
          .then(() => {
            if (products.length === 0 && !isSubscriptionsFetched.current) {
              IAP.getSubscriptions({ skus: subscriptionSkus as string[] })
                .then((res) => {
                  if (res && res.length > 0) {
                    setProducts(res as []);
                    setIsConnected(true);
                    setIsLoading(false);
                  } else {
                    addAnalyticsLog({
                      function: 'fetchData',
                      data: 'No subscriptions found.',
                      logType: 'error'
                    });
                    setIsLoading(false);
                    setVisibleErrorModal(true);
                  }
                  isSubscriptionsFetched.current = true;
                })
                .catch((err) => {
                  addAnalyticsLog({
                    function: 'fetchData',
                    data: err,
                    logType: 'error'
                  });
                });
            }
          })
          .catch((err) => {
            addAnalyticsLog({
              function: 'fetchData',
              data: err,
              logType: 'error'
            });
          });
        setIsConnected(false);
      } else {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [subscriptionSkus, products, userSubscriptionPlan, addAnalyticsLog]);

  const handleRequestSubscription = async (info: any) => {
    setIsLoading(true);
    let result: RequestSubscriptionResult = null;
    try {
      if (Platform.OS === 'ios') {
        result = await IAP.requestSubscription({ sku: info.item.productId });
        subscriptionName = info.item.title;
      } else if (Platform.OS === 'android' && info.item.subscriptionOfferDetails?.length > 0) {
        const offerToken = info.item.subscriptionOfferDetails[0].offerToken;
        result = await IAP.requestSubscription({
          sku: info.item.productId,
          subscriptionOffers: [{ sku: info.item.productId, offerToken: offerToken }]
        });
        subscriptionName = info.item.name;
      }

      const platform = Platform.OS;
      const userTransactionData = {
        platform: platform,
        notificationType: 'INIT',
        data: result
      };

      const response = UserService.setUserSubscription(userTransactionData);
      const data = await response.promise;

      if (data.status == 'ok') {
        setIsLoading(false);
        navigation.navigate('AfterPurchaseSubscriptionScreen', {
          subscriptionName: subscriptionName,
          platform: platform
        });
      } else {
        setIsLoading(false);
        addAnalyticsLog({
          function: 'handleRequestSubscription',
          data: data,
          logType: 'error'
        });
        setError(data);
        setVisibleErrorModal(true);
      }
    } catch (err: any) {
      if (err.code && err.code === 'E_USER_CANCELLED') {
        setIsLoading(false);
      } else {
        addAnalyticsLog({
          function: 'handleRequestSubscription',
          data: err,
          logType: 'error'
        });
        setIsLoading(false);
        setError(err);
        setVisibleErrorModal(true);
      }
    }
  };

  const handleCancelSubscription = () => {
    const url =
      Platform.OS === 'ios'
        ? 'https://apps.apple.com/account/subscriptions'
        : 'https://play.google.com/store/account/subscriptions';

    Linking.canOpenURL(url).then((supported) => {
      if (supported) {
        Linking.openURL(url);
      } else {
        addAnalyticsLog({
          function: 'handleCancelSubscription',
          data: 'Error handling subscription cancel url.',
          logType: 'error'
        });
      }
    });
  };

  const renderItemHeader = (headerProps: any, info: ListRenderItemInfo<{ title: string; name: string }>) => {
    const itemHeader = Platform.select({
      ios: info.item.title,
      android: info.item.name
    });
    return (
      <View {...headerProps}>
        <Text category="h6">{`${itemHeader}`}</Text>
      </View>
    );
  };

  const renderItem = (info: any) => {
    const subscriptionPeriod = Platform.select({
      ios: `${info.item.subscriptionPeriodNumberIOS} ${info.item.subscriptionPeriodUnitIOS}`,
      android: info.item.subscriptionOfferDetails
        ? `${info.item.subscriptionOfferDetails[0]?.pricingPhases?.pricingPhaseList[0]?.billingPeriod}`
        : 'N/A'
    });

    const subscriptionPricing = Platform.select({
      ios: `${info.item.currency} ${info.item.price}`,
      android: info.item.subscriptionOfferDetails
        ? `${info.item.subscriptionOfferDetails[0]?.pricingPhases?.pricingPhaseList[0]?.formattedPrice}`
        : 'N/A'
    });

    // The user can buy a subscription when his tenant features don’t have all scans permissions
    const allowAllScanTypes =
      scanFeatureKeys.filter((scanFeatureKey) => featureKeys.includes(scanFeatureKey)).length == scanFeatureKeys.length;
    setIsPremiumSubscription(allowAllScanTypes);

    return (
      <Card style={styleContainer.item} status="" header={(headerProps) => renderItemHeader(headerProps, info)}>
        <Text>{`${subscriptionPricing}/${subscriptionPeriod}`}</Text>
        <Button
          style={[styleContainer.button, { marginTop: 10 }]}
          size="medium"
          status="primary"
          disabled={isPremiumSubscription}
          onPress={() => handleRequestSubscription(info)}>
          <Text status="primary" category="s2">
            {t('subscribe')}
          </Text>
        </Button>
      </Card>
    );
  };

  return (
    <Layout level="2" style={[styleContainer.screenContainer, settingStyles.settingScreenContainer]}>
      {isLoading && <Spinner visible={true} size="large" />}
      <SafeAreaView style={styleContainer.screenContainer}>
        <View style={styleContainer.listContainer}>
          <Input
            size="large"
            style={styleContainer.input}
            label="Your current plan"
            value={userSubs}
            disabled={true}
            accessible={true}
            accessibilityLabel="User subscription label"
            testID="current-subscription"
          />
        </View>
        {isConnected ? (
          <View style={styleContainer.text}>
            <Text status="primary" category="s2">
              {t('checkConnection')}
            </Text>
          </View>
        ) : isPremiumSubscription ? (
          <View style={styleContainer.text}>
            {userSubs !== 'Kalibra Free' && (
              <>
                <Text status="primary" category="h6">
                  {t('premiumSubscription')}
                </Text>

                <Button
                  style={[styleContainer.button, { marginTop: 10 }]}
                  size="medium"
                  status="primary"
                  onPress={() => handleCancelSubscription()}>
                  <Text status="primary" category="s2">
                    {t('cancelSubscription')}
                  </Text>
                </Button>
              </>
            )}
          </View>
        ) : (
          <View>
            <List
              style={styleContainer.container}
              contentContainerStyle={styleContainer.contentContainer}
              data={products}
              renderItem={renderItem}
              accessibilityLabel="User subscription tile"
              testID="subscription-tile"
            />
          </View>
        )}
      </SafeAreaView>
      <ErrorModal
        visible={visibleErrorModal}
        closeBtnClick={() => {
          navigation.goBack();
        }}
        buttonTitle={'Go back'}
        message={error}
      />
    </Layout>
  );
};

export default SubscriptionsScreen;
