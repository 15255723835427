import { AgendaItem, ChatMessage, ChatResponseDto } from 'src/core';

import { BackendApi } from '../../shared';
import { PillarCategory } from 'src/core/types/Pillar';
import { SurveysService } from '../model';

export const WebSurveysService: SurveysService = {
  addReactionToMessage: (messageId: string, reactionTypeId: number): Promise<void> => {
    return BackendApi.put(`/surveys/feedback/${messageId}`, {
      reaction: reactionTypeId
    });
  },
  getMessagesBeforeMessage: (messageId: string | number): Promise<ChatMessage[]> => {
    return BackendApi.get(`/messages/history/${messageId}`).then((response) => response.data);
  },
  getStartingMessages: (biomarker): Promise<ChatResponseDto> => {
    return BackendApi.get('/surveys/start/', { params: { biomarker } }).then((response) => {
      const dto: ChatResponseDto = response.data;
      return dto;
    });
  },
  updateActionItem: (id: string, completed: boolean): Promise<void> => {
    return BackendApi.put(`/surveys/action-item/${id}`, {
      completed: Boolean(completed)
    });
  },
  getActionItem: (id: string): Promise<AgendaItem> => {
    return BackendApi.get(`/surveys/action-item/${id}`).then((response) => response.data);
  },
  addAnswer: (messageId: string, messageText: string, payload: any): Promise<ChatResponseDto> => {
    return BackendApi.post('/surveys/answer/', {
      messageId: messageId,
      messageText: messageText,
      payload: payload
    }).then((response) => response.data);
  },
  changeTopic: (pillarId: number, lastSeenMessageId?: string): Promise<ChatResponseDto> => {
    return BackendApi.post('/surveys/change-topic', { pillarId: pillarId, lastSeenMessageId: lastSeenMessageId }).then(
      (response) => {
        const dto: ChatResponseDto = response.data;
        return dto;
      }
    );
  },
  getPillarCategories: (): Promise<PillarCategory[]> => {
    return BackendApi.get('/surveys/pillars').then((response) => {
      return response.data;
    });
  }
};
