import { Button, Text, useTheme } from '@ui-kitten/components';
import { StyleSheet, View, ViewProps } from 'react-native';

import Logo from 'src/core/constants/Logo';
import React from 'react';
import { bottomMenu } from 'src/core/brands';
import { UIHelper as uh } from '../../core';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';

//props
interface BottomMenuProps extends ViewProps {
  onAboutKalibra: () => void;
  onFeedback: () => void;
}

// styles
const styleContainer = StyleSheet.create({
  container: {
    flexDirection: 'row',
    marginTop: uh.h2DP(16),
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    marginBottom: uh.h2DP(48)
  },
  button: {
    paddingLeft: 10,
    paddingRight: 10
  },
  text: { alignContent: 'center' },
  line: { height: uh.h2DP(32), width: 1, marginLeft: uh.w2DP(2), marginRight: uh.w2DP(2) },
  iconContainer: {},
  image: { height: uh.h2DP(48), width: uh.h2DP(48) }
});

const BottomMenu = ({ onAboutKalibra, onFeedback }: BottomMenuProps) => {
  const th = useTheme();

  const t = useTranslationFunc(bottomMenu);

  // view
  return (
    <View style={[styleContainer.container]}>
      <View style={styleContainer.iconContainer}>
        <Logo style={styleContainer.image} resizeMode="contain" />
      </View>
      <Button
        style={styleContainer.button}
        appearance="ghost"
        status="control"
        onPress={onAboutKalibra}
        testID={'menu-item-about'}>
        {(evaProps) => (
          <Text category="p2" {...evaProps} style={styleContainer.text}>
            {t('about')}
          </Text>
        )}
      </Button>
      <View style={{ ...styleContainer.line, ...{ backgroundColor: th['color-basic-active-border'] } }} />
      <Button
        style={styleContainer.button}
        appearance="ghost"
        status="control"
        onPress={onFeedback}
        testID={'menu-item-feedback'}>
        {(evaProps) => (
          <Text category="p2" {...evaProps} style={styleContainer.text}>
            {t('feedback')}
          </Text>
        )}
      </Button>
    </View>
  );
};

export default BottomMenu;
