import { Auth } from 'aws-amplify';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { addRawAnalyticsLog } from './AddRawAnalyticsLog';

export const signOutUser = (global = false): Promise<any> => {
  return Auth.signOut({ global: global });
};

export const getTokenRaw = async (): Promise<string> => {
  const result = await Auth.currentSession();
  if (result.isValid()) {
    return result.getIdToken().getJwtToken();
  }

  return new Promise((resolve) => {
    Auth.currentAuthenticatedUser().then((cognitoUser) => {
      const currentSession = cognitoUser.signInUserSession;
      cognitoUser.refreshSession(currentSession.refreshToken, (err: any, session: CognitoUserSession) => {
        if (err) {
          addRawAnalyticsLog('Error', { file: 'useUser.tsx', function: 'getToken', data: err });
          signOutUser();
        } else {
          resolve(session.getIdToken().getJwtToken());
        }
      });
    });
  });
};
