import { ApiResponse } from 'src/core/types/ApiResponse';
import { BackendApi } from 'src/api/shared';
import { DynamicCardBody } from 'src/core/types/DynamicCard';
import { DynamicCardsService } from '../model';

export const WebDynamicCardsService: DynamicCardsService = {
  getDynamicCards: (): ApiResponse<DynamicCardBody[]> => {
    const abortController = new AbortController();
    return {
      promise: BackendApi.get('cards', { signal: abortController.signal })
        .then((response) => {
          return response.data;
        })
        .catch((error) => Promise.reject({ error: error, isAborted: abortController.signal.aborted })),
      abort: () => {
        abortController.abort();
      }
    };
  }
};
