import { BubbleProps, GiftedChat, IMessage, User } from 'react-native-gifted-chat';
import { ChatMessage, UIHelper as uh } from 'src/core';
import { Keyboard, Platform, StyleSheet, View } from 'react-native';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useGetSocket, useWebSockets } from 'src/core/hooks/useWebsockets';
import { useGetUserAttributes, useGetUserId } from 'src/core/hooks/useUser';

import ChatBubble from './components/ChatBubble';
import ChatInput from './components/ChatInput';
import ChatLayout from './ChatLayout';
import ChatService from 'src/api/chat';
import ChatTakePicture from './components/ChatTakePicture';
import { ConversationMessage } from 'src/core/types/Chat';
import { ErrorModal } from 'src/components/shared/ErrorModal';
import { RootStackChatProps } from 'src/core/types/ChatNavigationParamList';
import { Spinner } from '@ui-kitten/components';
import { useAnalytics } from 'src/core/hooks/useAnalytics';
import { useIsFocused } from '@react-navigation/native';
import { useIsLightColorScheme } from 'src/core/hooks/useIsLightColorScheme';

const styleContainer = StyleSheet.create({
  loading: {
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    transform: [{ translateY: -50 }]
  },
  avatarContainer: {
    height: uh.h2DP(24),
    width: uh.h2DP(24),
    backgroundColor: 'transparent'
  }
});

const ChatScreen = ({ route }: RootStackChatProps<'ChatScreen'>) => {
  const [messages, setMessages] = useState<IMessage[]>([]);

  const [showCamera, setShowCamera] = useState<boolean>(false);
  const [isAudioVisible, setIsAudioVisible] = useState<boolean>(false);

  const [inputHeight, setInputHeight] = useState<number>(uh.h2DP(48));
  const [pressedMessageId, setPressedMessageId] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [socketError, setSocketError] = useState<any>();
  const [hasSocketError, setHasSocketError] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [isLoadingEarlier, setIsLoadingEarlier] = useState<boolean>(false);
  const [isLoadEarlierEnabled, setIsLoadEarlierEnabled] = useState<boolean>(false);

  const [rerenderKey, setRerenderKey] = useState<number>(0);
  const isFocused = useIsFocused();
  const appTheme = useIsLightColorScheme();

  const { addAnalyticsLog } = useAnalytics('ChatScreen.tsx');
  const { emitMessage } = useWebSockets();

  const userId = useGetUserId() ?? 0;
  const userName = useGetUserAttributes().name ?? 'user';

  const user: User = useMemo(() => ({ _id: userId, name: userName }), [userId, userName]);
  const socket = useGetSocket();

  const conversationId = route.params.id;

  const sendMessage = useCallback(
    (newMessages: ChatMessage[] = []) => {
      const shouldUseHttp = Boolean(newMessages[0].image || newMessages[0].video || newMessages[0].audio);
      emitMessage({ conversationId: conversationId, ...newMessages[0] }, shouldUseHttp, (error) => {
        if (error) {
          setSocketError(error);
          setHasSocketError(true);
        }
      });
    },
    [emitMessage, conversationId]
  );

  const createSocketListeners = useCallback(() => {
    if (!socket) {
      setSocketError("Weboscket isn't available");
      setHasSocketError(true);
      return;
    }

    socket.on(`${conversationId}/message`, (data: any) => {
      setMessages((previousMessages) => GiftedChat.append(previousMessages, data));
    });
  }, [conversationId, socket]);

  const getMessageHistory = useCallback(
    (offset: number, cb?: () => any, onFinally?: () => any) => {
      ChatService.getMessageHistory(conversationId, offset)
        .promise.then((history: ConversationMessage[]) => {
          setIsLoadEarlierEnabled(history.length === 10);
          setMessages((prev) =>
            GiftedChat.prepend(
              prev,
              history.map((currentMessage: ConversationMessage) => {
                const message: IMessage = {
                  _id: currentMessage.id,
                  createdAt: currentMessage.createdOn,
                  image: currentMessage.image,
                  video: currentMessage.video,
                  audio: currentMessage.audio,
                  text: currentMessage.text,
                  user: { _id: currentMessage.userId }
                };

                if (currentMessage.userId !== user._id) {
                  return {
                    ...message,
                    user: { ...message.user, avatar: route.params.avatar, name: route.params.name }
                  };
                }
                return message;
              })
            )
          );
          cb?.();
        })
        .catch((error: any) => {
          addAnalyticsLog({ function: 'initialize', data: error, logType: 'error' });
        })
        .finally(onFinally);
    },
    [addAnalyticsLog, conversationId, route.params.avatar, route.params.name, user._id]
  );

  const renderBubble = ({ currentMessage, position, nextMessage, previousMessage }: BubbleProps<ChatMessage>) => {
    if (currentMessage) {
      return (
        <ChatBubble
          message={currentMessage}
          position={position}
          nextMessage={nextMessage}
          previousMessage={previousMessage}
          showAvatar={position === 'left'}
          pressedMessageId={pressedMessageId}
          setPressedMessageId={setPressedMessageId}
        />
      );
    }
    return <></>;
  };

  const loadEarlier = () => {
    setIsLoadingEarlier(true);
    getMessageHistory(page + 1, undefined, () => setIsLoadingEarlier(false));
    setPage((prev) => prev + 1);
  };
  useEffect(() => {
    if (isFocused) {
      setRerenderKey((prev) => prev + 1);
    }
  }, [isFocused, appTheme]);

  useEffect(() => {
    setIsLoading(true);
    getMessageHistory(1, createSocketListeners, () => setIsLoading(false));
  }, [createSocketListeners, getMessageHistory]);

  if (isLoading) {
    return (
      <View style={styleContainer.loading}>
        <Spinner size="giant" />
      </View>
    );
  }

  if (showCamera) {
    return (
      <ChatTakePicture showCamera={showCamera} setShowCamera={setShowCamera} sendMessage={sendMessage} user={user} />
    );
  }

  return (
    <ChatLayout>
      <GiftedChat
        key={rerenderKey}
        messages={messages}
        user={user}
        inverted={true}
        renderAvatar={null}
        scrollToBottom
        renderBubble={renderBubble}
        messagesContainerStyle={{ transform: [{ translateY: isAudioVisible ? -uh.h2DP(64) : 0 }] }}
        renderInputToolbar={(props) => (
          <ChatInput
            {...props}
            user={user}
            setShowCamera={setShowCamera}
            sendMessage={sendMessage}
            setInputHeight={setInputHeight}
            isAudioVisible={isAudioVisible}
            setIsAudioVisible={setIsAudioVisible}
          />
        )}
        onPress={() => {
          Keyboard.dismiss();
        }}
        minInputToolbarHeight={inputHeight}
        listViewProps={{
          scrollsToTop: true,
          onEndReachedThreshold: 0.3,
          onEndReached: (e: { distanceFromEnd: number }) => {
            if (Platform.OS !== 'web' && e.distanceFromEnd > -200 && e.distanceFromEnd < 200 && isLoadEarlierEnabled)
              loadEarlier();
          }
        }}
        onLoadEarlier={Platform.OS === 'web' ? loadEarlier : () => {}}
        loadEarlier={isLoadEarlierEnabled && Platform.OS === 'web'}
        isLoadingEarlier={isLoadingEarlier}
      />
      <ErrorModal visible={hasSocketError} message={socketError} />
    </ChatLayout>
  );
};

export default ChatScreen;
