import { Button, Input, Layout, Text } from '@ui-kitten/components';
import { FlatList, StyleSheet, TouchableOpacity, View, ViewProps } from 'react-native';

import AddFavoriteItem from './components/AddFavoriteItem';
import CloseIcon from './components/icons/CloseIcon';
import { NewHealthMarker } from 'src/core/types/HealthMarkerReport';
import { PILLARS } from 'src/core/constants/Pillars';
import PillarCheckboxList from './PillarCheckboxList';
import React from 'react';
import RightIcon from './components/RightIcon';
import { SortedType } from 'src/core/types/Marker';
import { useFilterMarkers } from 'src/core/hooks/useFilterMarkers';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';

//props
interface AddFavoriteListProps extends ViewProps {
  markers: NewHealthMarker[];
  onCloseBtnClick: () => void;
  onAddMoreFavoriteMarkers: (keys: string[]) => void;
  currentPageNumber: number;
  style?: any;
}

const styleContainer = StyleSheet.create({
  container: { paddingLeft: 16, paddingRight: 16 },
  searchText: { height: 40, flex: 1 },
  searchArea: { flexDirection: 'row', alignItems: 'center', columnGap: 8, marginTop: 16 },
  addButton: { marginTop: 16, marginLeft: 16, marginRight: 16 },
  addButtonText: { marginLeft: 8, color: 'white' },
  addFavoriteItem: { borderRadius: 8, marginLeft: 16, marginRight: 16 },
  itemSeparator: { height: 8 },
  content: { marginTop: 16 }
});

const AddFavoriteList = ({
  markers,
  onCloseBtnClick,
  onAddMoreFavoriteMarkers,
  currentPageNumber,
  style
}: AddFavoriteListProps) => {
  const { filterData, filterPillar } = useFilterMarkers();
  const transMessage = {
    addToFavorites: 'Add To Favorites',
    searchPlaceHorder: 'Search marker to add'
  };
  const t = useTranslationFunc(transMessage);

  const [seletedMarkers, setSelectedMarkers] = React.useState<string[]>([]);
  const [pageNumber, setPageNumber] = React.useState(currentPageNumber);
  const [searchText, setSearchText] = React.useState('');
  const [selectedPillars, setSelectedPillars] = React.useState<Array<string>>([]);
  const RECORDS_PER_PAGE = 10;
  const numberOfItems = (pageNumber + 1) * RECORDS_PER_PAGE;
  let filteredMarkers = filterData(markers, searchText, SortedType.MOST_RECENT);
  const activePillars = [...new Set(filteredMarkers.map((marker) => marker.category))];
  filteredMarkers = filterPillar(filteredMarkers, selectedPillars);
  filteredMarkers = filteredMarkers.slice(0, numberOfItems);
  const finalPillars = PILLARS.map((pillar) => {
    return {
      ...pillar,
      checked: selectedPillars.includes(pillar.name),
      disabled: !activePillars.includes(pillar.name)
    };
  });

  const onMarkerChange = (externalKey: string, checked: boolean) => {
    if (seletedMarkers.includes(externalKey) && checked === false) {
      setSelectedMarkers(seletedMarkers.filter((key) => key !== externalKey));
    } else {
      setSelectedMarkers([...seletedMarkers, externalKey]);
    }
  };

  const onPillarChange = (name: string, checked: boolean) => {
    if (selectedPillars.includes(name) && checked === false) {
      setSelectedPillars(selectedPillars.filter((itemName) => name !== itemName));
    } else {
      setSelectedPillars([...selectedPillars, name]);
    }
  };

  const renderBiomarker = ({ item, index }: any) => {
    return (
      <AddFavoriteItem
        key={`biomarker-item-${index}`}
        biomarker={item}
        checked={seletedMarkers.includes(item.externalKey)}
        onChange={(checked) => {
          onMarkerChange(item.externalKey, checked);
        }}
        style={{ borderRadius: 8, marginLeft: 16, marginRight: 16 }}
        accessibilityLabel={`Biomarker ${index}`}
        testID={`biomarker-${index}`}
      />
    );
  };

  const listHeader = () => {
    return (
      <Layout level="1" style={styleContainer.container}>
        <View style={styleContainer.searchArea}>
          <Input
            style={styleContainer.searchText}
            value={searchText}
            placeholder={t('searchPlaceHorder')}
            accessoryRight={
              <RightIcon
                isSearching={searchText.length > 0 ? true : false}
                onClick={() => {
                  setSearchText('');
                }}
              />
            }
            onChangeText={(nextValue) => setSearchText(nextValue)}
            accessible={true}
            accessibilityLabel={'my markers searchbar'}
            testID={'my_markers_searchbar'}
          />
          <TouchableOpacity
            onPress={() => {
              onCloseBtnClick();
            }}>
            <CloseIcon />
          </TouchableOpacity>
        </View>
        <PillarCheckboxList
          pillars={finalPillars}
          onChange={onPillarChange}
          style={{ marginTop: 8, marginBottom: 8 }}
        />
      </Layout>
    );
  };

  // view
  return (
    <>
      {listHeader()}
      <FlatList
        style={style}
        data={filteredMarkers}
        renderItem={renderBiomarker}
        ItemSeparatorComponent={() => <View style={styleContainer.itemSeparator} />}
        onEndReached={() => {
          setPageNumber((page) => page + 1);
        }}
        contentContainerStyle={styleContainer.content}
        onEndReachedThreshold={0}></FlatList>
      <Button
        status="primary"
        disabled={seletedMarkers.length === 0}
        style={styleContainer.addButton}
        onPress={() => {
          onAddMoreFavoriteMarkers(seletedMarkers);
        }}>
        {(evaProps) => (
          <Text {...evaProps} category="s1" style={styleContainer.addButtonText}>
            {t('addToFavorites')}
          </Text>
        )}
      </Button>
    </>
  );
};

export default AddFavoriteList;
