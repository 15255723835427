import { useCallback, useEffect, useState } from 'react';
import { useAnalytics } from './useAnalytics';
import { useAppStore } from '../store';
import { useIsUserLogged } from './useUser';
import { ApiError } from '../types/ApiResponse';
import ScoreService from 'src/api/scores';
import { ActionTip } from '../types/Action';

export const useGetPillarActionTips = () => {
  const pillarActionTips = useAppStore((state) => state.pillarActionTips);
  return pillarActionTips;
};

interface UsePillarActionTipsProps {
  key: string;
  refreshing: boolean;
  isInsight: boolean;
  setRefreshing: (refreshing: boolean) => void;
}

export const usePillarActionTipsEffect = ({ key, refreshing, isInsight, setRefreshing }: UsePillarActionTipsProps) => {
  const setPillarActionTips = useAppStore((state) => state.setPillarActionTips);
  const pillarActionTips = useAppStore((state) => state.pillarActionTips);
  const { addAnalyticsLog } = useAnalytics('usePillarActionTips.ts');
  const [isActionTipsLoading, setIsActionTipsLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>();
  const isUserLogged = useIsUserLogged();

  const loadPillarActionTips = useCallback(
    (promise: Promise<ActionTip[]>) => {
      promise
        .then((actionItems) => {
          setPillarActionTips(actionItems);
        })
        .catch((err: ApiError) => {
          if (!err.isAborted) {
            setError(err.error);
          }
          addAnalyticsLog({ function: 'loadPillarActionTips', data: err, logType: 'error' });
        })
        .finally(() => {
          setRefreshing(false);
          setIsActionTipsLoading(false);
        });
    },
    [addAnalyticsLog, setRefreshing, setPillarActionTips]
  );

  useEffect(() => {
    if (isUserLogged) {
      const { promise, abort } = ScoreService.getPillarActionTips(key, isInsight);
      loadPillarActionTips(promise);
      return abort;
    }
  }, [isUserLogged, loadPillarActionTips, key, isInsight]);

  useEffect(() => {
    if (refreshing) {
      const { promise, abort } = ScoreService.getPillarActionTips(key, isInsight);
      loadPillarActionTips(promise);
      return abort;
    }
  }, [refreshing, loadPillarActionTips, key, isInsight]);

  return [pillarActionTips, isActionTipsLoading, error];
};
