import { BackendApi } from 'src/api/shared';
import { MarkerService } from '../model';

export const WebMarkerService: MarkerService = {
  getLatestMarkers: () => {
    const abortController = new AbortController();
    return {
      promise: BackendApi.get('health-markers/data/latest-health-markers', { signal: abortController.signal })
        .then((response) => {
          return response.data;
        })
        .catch((error) => Promise.reject({ error: error, isAborted: abortController.signal.aborted })),
      abort: () => {
        abortController.abort();
      }
    };
  },
  getFavoriteMarkers: () => {
    const abortController = new AbortController();
    return {
      promise: BackendApi.get('/health-markers/favorite', { signal: abortController.signal })
        .then((response) => {
          return response.data;
        })
        .catch((error) => Promise.reject({ error: error, isAborted: abortController.signal.aborted })),
      abort: () => {
        abortController.abort();
      }
    };
  },
  addFavoriteMarkers: (externalKeys: string[]): Promise<void> => {
    return BackendApi.post('/health-markers/favorite', { keys: externalKeys }).then((response) => response.data);
  },
  removeFavoriteMarker: (externalKeys: string[]): Promise<void> => {
    return BackendApi.delete('/health-markers/favorite', { data: { keys: externalKeys } }).then(
      (response) => response.data
    );
  }
};
