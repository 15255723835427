import * as DeviceInfo from 'react-native-device-info';

import Auth from '@aws-amplify/auth';
import BackendApi from './BackendApi';
import { Config } from '../../core';
import { Platform } from 'react-native';
import { getValue } from '../storage';
import moment from 'moment-timezone';
import platform from 'platform';

export const sendToKalibra = async (error: any, userAttributes: any) => {
  const uniqueErrors = error.error ? { customError: JSON.stringify(error) } : { axiosError: error };

  let accessTokenData = {};
  let idTokenData = {};
  let refreshTokenData = 'no data';

  try {
    const currentSession = await Auth.currentSession();
    const accessToken = currentSession.getAccessToken();
    const idToken = currentSession.getIdToken();
    const refreshToken = currentSession.getRefreshToken();

    const accessTokenPayload = accessToken.decodePayload();
    const idTokenPayload = idToken.decodePayload();

    accessTokenData = {
      jwtToken: accessToken.getJwtToken(),
      clientId: accessTokenPayload.client_id,
      iss: accessTokenPayload.iss,
      tokenUse: accessTokenPayload.token_use,
      scope: accessTokenPayload.scope,
      username: accessTokenPayload.username,
      expiration: accessToken.getExpiration()
    };
    refreshTokenData = refreshToken.getToken();
    idTokenData = {
      jwtToken: idToken.getJwtToken(),
      cognitoUsername: idTokenPayload['cognito:username'],
      iss: idTokenPayload.iss,
      expiration: idToken.getExpiration()
    };
  } catch (err) {
    console.error('Token error: ', err);
  }

  const errorData = {
    ...uniqueErrors,
    userInfo: {
      tenantKey: await getValue('tenantKey'),
      backend_url: await getValue('backend_url'),
      cognitoClientId: userAttributes.sub,
      email: userAttributes.email,
      accessToken: accessTokenData,
      idToken: idTokenData,
      refreshToken: refreshTokenData,
      userLocalTime: moment().format(),
      amplifySigninWithHostedUI: await getValue('amplify-signin-with-hostedUI'),
      expoConstantsInstallationID: await getValue('EXPO_CONSTANTS_INSTALLATION_ID')
    },
    appState: {
      appCommitId: Config.GIT_COMMIT,
      appBranch: Config.APP_BRANCH
    },
    clientDeviceInfo:
      Platform.OS !== 'web'
        ? {
            deviceId: await DeviceInfo.getUniqueId(),
            deviceManufacturer: await DeviceInfo.getManufacturer(),
            deviceModel: DeviceInfo.getModel(),
            deviceSystemVersion: DeviceInfo.getSystemVersion(),
            deviceBuildNumber: DeviceInfo.getBuildNumber(),
            version: DeviceInfo.getVersion(),
            deviceName: await DeviceInfo.getDeviceName()
          }
        : {
            device: platform.name,
            deviceVersion: platform.version,
            os: {
              architecture: platform.os?.architecture,
              family: platform.os?.family,
              version: platform.os?.version
            }
          },
    clientPlatformInfo: {
      os: Platform.OS,
      version: Platform.OS !== 'web' ? Platform.Version.toString() : null
    }
  };

  // Send the error data to the backend
  const response = await BackendApi.post('/errors/send-to-kalibra', errorData);
  return response;
};
