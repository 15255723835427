import { Units, UserSettings } from 'src/core';

import { ErrorModal } from 'src/components/shared/ErrorModal';
import { NotiSegmentItem } from 'src/core/types/Notification';
import React from 'react';
import SegmentMenu from './components/SegmentMenu';
import Spinner from 'react-native-loading-spinner-overlay/lib';
import { useAppStore } from 'src/core/store';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';
import { useUpdateUserSettings } from 'src/core/hooks/useUserSettings';

const UnitsMenuContainer = () => {
  const units = useAppStore((state) => state.units);
  const setUnits = useAppStore((state) => state.setUnits);
  const [updateUserSettings, isUpdatingUserSettings, error] = useUpdateUserSettings();
  const transMessage = {
    name: 'Units',
    description: 'Select your preference for units.',
    unitMetric: 'Metric',
    unitImperial: 'Imperial',
    unitMetricInfo: 'Kilograms, Litres, Metres',
    unitImperialInfo: 'Pounds, Ounces, Feet'
  };
  const t = useTranslationFunc(transMessage);
  const segments: NotiSegmentItem[] = [
    {
      name: t('unitMetric'),
      value: Units.Metric,
      info: t('unitMetricInfo')
    },
    {
      name: t('unitImperial'),
      value: Units.Imperial,
      info: t('unitImperialInfo')
    }
  ];

  //view
  return (
    <>
      <SegmentMenu
        name={t('name')}
        description={t('description')}
        segments={segments}
        onSelect={(value) => {
          setUnits(value as Units);
          updateUserSettings({ units: value } as UserSettings);
        }}
        selectedValue={units}
      />
      <ErrorModal visible={error != undefined} message={error} />
      <Spinner visible={isUpdatingUserSettings} />
    </>
  );
};

export default UnitsMenuContainer;
