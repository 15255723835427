import { GestureResponderEvent, StyleSheet, View, ViewStyle } from 'react-native';
import React, { PropsWithChildren, useState } from 'react';

import { Sound } from 'expo-av/build/Audio';
import { UIHelper as uh } from 'src/core';
import { useTheme } from '@ui-kitten/components';

interface ProgressBarProps extends PropsWithChildren {
  progress: number;
  maxProgress: number;
  setProgress: (progress: number) => void;
  progressBarWidth?: number;
  shouldPlay?: boolean;
  sound?: Sound;
  containerStyle?: ViewStyle;
  progressBarStyle?: ViewStyle;
}

const ProgressBar = ({
  progress,
  maxProgress,
  setProgress,
  progressBarWidth = uh.h2DP(128),
  shouldPlay = false,
  sound,
  containerStyle,
  progressBarStyle,
  children
}: ProgressBarProps) => {
  const [resumeAfterPause, setResumeAfterPause] = useState<boolean>(false);
  const th = useTheme();

  const styleContainer = StyleSheet.create({
    container: {
      borderRadius: uh.height(),
      borderWidth: 1,
      borderColor: th['border-basic-color-5'],
      justifyContent: 'center',
      height: uh.h2DP(36),
      overflow: 'hidden',
      marginLeft: uh.h2DP(4)
    },
    progressBar: {
      height: '100%',
      width: `${(progress * 100) / maxProgress}%`,
      backgroundColor: th['color-primary-500'],
      position: 'absolute',
      left: 0
    }
  });

  const calculatePosition = (locationX: number) => {
    let touchPosition = 0;
    if (locationX > progressBarWidth) {
      touchPosition = maxProgress;
    } else if (locationX >= 0 && locationX <= progressBarWidth) {
      const percentage = (locationX * 100) / progressBarWidth;
      touchPosition = (maxProgress * percentage) / 100;
    }
    return touchPosition;
  };

  const handleTouchStart = async () => {
    if (sound) {
      setResumeAfterPause(shouldPlay);
      await sound.pauseAsync();
    }
  };

  const handleTouchMove = async ({ nativeEvent: { locationX } }: GestureResponderEvent) => {
    if (sound) {
      await sound.pauseAsync();
      setProgress(calculatePosition(locationX));
    }
  };

  const handleTouchEnd = async ({ nativeEvent: { locationX } }: GestureResponderEvent) => {
    if (sound) {
      await sound.setPositionAsync(calculatePosition(locationX));
      if (resumeAfterPause) {
        await sound.playAsync();
      }
    }
  };

  return (
    <View
      style={[styleContainer.container, containerStyle, { width: progressBarWidth }]}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}>
      <View style={[styleContainer.progressBar, progressBarStyle]} />
      {children}
    </View>
  );
};

export default ProgressBar;
