import { useCallback, useEffect, useState } from 'react';
import { useAnalytics } from './useAnalytics';
import { useAppStore } from '../store';
import { useIsUserLogged } from './useUser';
import UserService from 'src/api/user';
import { ApiError } from '../types/ApiResponse';
import { LatestDataCountType } from '../types/UserAttributes';

interface UseLatestDataCountPropsProps {
  refreshing: boolean;
  setRefreshing: (refreshing: boolean) => void;
}

export const useLatestDataCount = ({ refreshing, setRefreshing }: UseLatestDataCountPropsProps) => {
  const setNewActionCount = useAppStore((state) => state.setNewActionCount);
  const setNewAssessmentCount = useAppStore((state) => state.setNewAssessmentCount);
  const setNewMessageCount = useAppStore((state) => state.setNewMessageCount);
  const [isLatestDataCountLoading, setIsLatestDataCountLoading] = useState<boolean>(true);
  const isUserLogged = useIsUserLogged();
  const [error, setError] = useState<any>();
  const { addAnalyticsLog } = useAnalytics('useLatestDataCount.ts');

  const getLatestDataCount = useCallback(() => {
    UserService.getLatestDataCount()
      .then((data) => {
        setNewActionCount(data.newActions);
        setNewAssessmentCount(data.newAssessments);
        setNewMessageCount(data.unreadMessages);
      })
      .catch((err: ApiError) => {
        if (!err.isAborted) {
          setError(err.error);
        }
        addAnalyticsLog({ logType: 'error', function: 'getLatestDataCount', data: JSON.stringify(err) });
      })
      .finally(() => {
        setIsLatestDataCountLoading(false);
        setRefreshing(false);
      });
  }, [addAnalyticsLog, setNewActionCount, setNewMessageCount, setNewAssessmentCount, setRefreshing]);

  useEffect(() => {
    if (isUserLogged) {
      getLatestDataCount();
    }
  }, [isUserLogged, getLatestDataCount]);

  useEffect(() => {
    if (refreshing) {
      getLatestDataCount();
    }
  }, [getLatestDataCount, refreshing]);

  return [isLatestDataCountLoading, error];
};

export const useUpdateLatestDataCount = () => {
  const { addAnalyticsLog } = useAnalytics('useLatestDataCount.ts');
  const [error, setError] = useState<any>();
  const updateLatestDataCount = (type: LatestDataCountType) => {
    UserService.updateLatestDataCount(type)
      .catch((err: ApiError) => {
        setError(err);
        addAnalyticsLog({ function: 'updateLatestDataCount', data: err, logType: 'error' });
      })
      .finally(() => {});
  };

  return [updateLatestDataCount, error];
};
