import 'react-native-get-random-values';

import { ChatMessage, ChatResponseDto, RootTabScreenProps, TenantFeature, UIHelper as uh } from '../../core';
import { GiftedChat, IMessage, Reply, User } from 'react-native-gifted-chat';
import { Layout, useTheme } from '@ui-kitten/components';
import { Platform, StyleSheet, View } from 'react-native';
import React, { ReactNode, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useGetTenantFeatures, useGetUserAttributes } from 'src/core/hooks/useUser';

import { AntDesign } from '@expo/vector-icons';
import ChatBubbleWithAnimation from 'src/components/kalichat/ChatBubbleWithAnimation';
import ChatFooter from 'src/components/kalichat/ChatFooter';
import ChatQuickReplies from 'src/components/kalichat/ChatQuickReplies';
import ChatTextMessage from 'src/components/kalichat/ChatTextMessage';
import DisableTenantFeature from 'src/components/shared/DisableTenantFeature';
import Spinner from 'react-native-loading-spinner-overlay';
import SurveysService from './../../api/surveys';
import TypingIndicator from 'react-native-gifted-chat/lib/TypingIndicator';
import { kaliChatScreen } from 'src/core/brands';
import { mainStyles } from './_mainStyles';
import { useAnalytics } from 'src/core/hooks/useAnalytics';
import { useAppStore } from 'src/core/store';
import { useGetAppName } from 'src/core/hooks/useBrands';
import { useIsFocused } from '@react-navigation/native';
import { useIsLightColorScheme } from 'src/core/hooks/useIsLightColorScheme';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';
import { v4 as uuidv4 } from 'uuid';

let debugEnabled = false;
let timeoutIds: NodeJS.Timeout[] = [];
const KaliChatScreen = ({ route }: RootTabScreenProps<'Kali'>) => {
  const { addAnalyticsLog } = useAnalytics('KaliChatScreen.tsx');
  const setRefreshActionItemsFlag = useAppStore((state) => state.setRefreshActionItemsFlag);

  const messageDelayMaxMs = 5000;
  const timeDelayPerCharMs = 45;
  const readingTimeDelayPerCharMs = 45;
  const th = useTheme();
  const [loading, setLoading] = useState<boolean>(false);
  const [enableAnimation, setEnableAnimation] = useState<boolean>(false);
  const [loadEarlierMessagesEnabled, setLoadEarlierMessagesEnabled] = useState<boolean>(false);
  const [, setErrorModalVisible] = useState<boolean>(false);
  const [pressedMessageId, setPressedMessageId] = useState<string>('');
  const [messageReactions, setMessageReactions] = useState<any>({});
  const [bubbleWidths, setBubbleWidths] = useState<any>({});
  const [bubbleHeights, setBubbleHeights] = useState<any>({});
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [user, setUser] = useState<User>({ _id: 0 });
  const [timeoutId, setTimeoutId] = useState<number>(0);
  const [isShowAllMsgsReaction, setIsShowAllMsgsReaction] = useState<boolean>(false);
  const biomarker: string | undefined = route.params ? (route.params as any).biomarker : undefined;
  const [isChatScreenEnable, setIsChatScreenEnable] = useState(true);
  const [loadingEarlierMessages, setLoadingEarlierMessages] = useState<boolean>(false);
  const [isLoadingEarlier, setIsLoadingEarlier] = useState<boolean>(false);
  const [lastSeenMessageId, setLastSeenMessageId] = useState<string>();
  const [activePillar, setActivePillar] = useState<number>(-1);
  const [callSurveyAfterTimeout, setCallSurveyAfterTimeout] = useState<boolean>(false);
  const [disableFooter, setDisableFooter] = useState<boolean>(false);
  const [rerenderKey, setRerenderKey] = useState<number>(0);
  const tenantFeatures: TenantFeature[] = useGetTenantFeatures();

  const isFocused = useIsFocused();
  const userInfo = useGetUserAttributes();

  const t = useTranslationFunc(kaliChatScreen);
  const appName = useGetAppName();

  const appTheme = useIsLightColorScheme();

  useEffect(() => {
    if (tenantFeatures != undefined) {
      setIsChatScreenEnable(
        tenantFeatures.find((item: TenantFeature) => item.key == 'ChatAndAgendaFeature') != undefined
      );
    }
    if (isFocused) {
      setIsLoadingEarlier(false);
      setRerenderKey((prevKey) => prevKey + 1);
    }
  }, [isFocused, tenantFeatures, appTheme]);

  const maybeFilterDebugMessages = useCallback(
    (messagesToFilter: ChatMessage[] = []): ChatMessage[] => {
      if (!debugEnabled) {
        const filterMessages: ChatMessage[] = messagesToFilter.filter(
          (message: ChatMessage) => message.user._id !== t('debugId', { app_name: appName })
        );
        return filterMessages;
      }
      return messagesToFilter;
    },
    [appName, t]
  );

  const preProcessNewMessage = useCallback(
    (newMessage: ChatMessage): ChatMessage => {
      newMessage.user.avatar = require('../../../assets/images/brand/chatbot-avatar.png');
      if (newMessage.reaction) {
        const newMessageReactions = messageReactions;
        newMessageReactions[newMessage._id] = newMessage.reaction;
        setMessageReactions(newMessageReactions);
      }
      return newMessage;
    },
    [messageReactions]
  );

  const preProcessNewMessages = (newMessages: ChatMessage[]): ChatMessage[] => {
    for (const message of newMessages) {
      preProcessNewMessage(message);
    }
    return newMessages;
  };

  const forceRedrawMessages = () => {
    setMessages((previousMessages: IMessage[]) => {
      const newMessages: IMessage[] = JSON.parse(JSON.stringify(previousMessages));
      return newMessages;
    });
  };

  const showAllMessageReactions = useCallback(() => {
    const timeout = setTimeout(() => {
      setIsShowAllMsgsReaction(true);
      forceRedrawMessages();
    }, 200);
    timeoutIds.push(timeout);
  }, []);

  const onLoadEarlierMessages = async (): Promise<void> => {
    if (messages === undefined || messages?.length === 0 || loadingEarlierMessages) {
      return;
    }
    const oldestMessage: IMessage = messages[messages.length - 1];
    setLoadingEarlierMessages(true);
    SurveysService.getMessagesBeforeMessage(oldestMessage._id)
      .then((newMessages: ChatMessage[]) => {
        if (newMessages !== undefined && newMessages?.length > 0) {
          const filteredNewMessages: ChatMessage[] = maybeFilterDebugMessages(newMessages);
          const history: ChatMessage[] = preProcessNewMessages(filteredNewMessages);

          // Are there more messages available in history
          if (newMessages.length < 10) {
            setLoadEarlierMessagesEnabled(false);
          }
          setMessages((previousMessages) => GiftedChat.prepend(previousMessages, history));
          showAllMessageReactions();
        }
      })
      .catch((error) => {
        addAnalyticsLog({
          function: 'onLoadEarlierMessages',
          data: { errorCode: error.code, errorMessage: error.message, responseData: JSON.stringify(error.response) },
          logType: 'error'
        });
      })
      .finally(() => {
        setLoadingEarlierMessages(false);
      });
  };

  const animateNewMessage = (newMessage: IMessage, stopTyping: boolean): void => {
    // Override server create date so messages are rendered in correct order
    setMessages((previousMessages) => GiftedChat.append(previousMessages, [newMessage]));
    setIsTyping(!stopTyping);
  };

  const appendNewMessages = useCallback(
    (
      newMessages: ChatMessage[] = [],
      latencyMs = 0,
      stopTypingOnLastMsg = true,
      startTypingOnFirstMsg = true
    ): void => {
      const filteredNewMessages: ChatMessage[] = maybeFilterDebugMessages(newMessages);

      // No messages received.
      if (filteredNewMessages?.length === 0) {
        setIsTyping(false);
        return;
      }

      let delayMs = 0;
      let firstProcessedMsg = true;
      for (const [i, newMessage] of filteredNewMessages.entries()) {
        const waitPeriodMs: number = Math.min(newMessage.text.length * timeDelayPerCharMs, messageDelayMaxMs);
        const readingDelayMs: number = newMessage.text.length * readingTimeDelayPerCharMs;

        preProcessNewMessage(newMessage);

        if (newMessage.quickReplies) {
          newMessage.quickReplies.keepIt = true;
        }

        if (newMessage.seen) {
          animateNewMessage(newMessage, true);
          continue;
        }

        // If 1st message, take in to account network latency
        if (firstProcessedMsg) {
          setIsTyping(startTypingOnFirstMsg);
          delayMs += Math.max(waitPeriodMs - latencyMs, 0);
          firstProcessedMsg = false;
          setIsTyping(true);
        } else {
          delayMs += waitPeriodMs;
        }

        const stopTyping: boolean = filteredNewMessages.length === i + 1 && stopTypingOnLastMsg;
        const timeOut = setTimeout(() => {
          setLastSeenMessageId(newMessage._id.toString());
          animateNewMessage(newMessage, true);
          if (i === filteredNewMessages.length - 1) {
            setDisableFooter(false);
          }
          const readingTimeOut = setTimeout(() => {
            setIsTyping(!stopTyping);
          }, readingDelayMs);
          setTimeoutId(readingTimeOut as any);
        }, delayMs);
        setTimeoutId(timeOut as any);
        timeoutIds.push(timeOut);

        delayMs += readingDelayMs;
      }
    },
    [maybeFilterDebugMessages, preProcessNewMessage]
  );

  const startSurvey = useCallback(
    async (reset = false, reload = false): Promise<void> => {
      setIsTyping(false);
      timeoutIds.map((currentTimeoutId: NodeJS.Timeout) => {
        clearTimeout(currentTimeoutId);
      });
      timeoutIds = [];
      try {
        const startTimeMs: number = new Date().getTime();
        const {
          messages: startingMessages,
          timeout: startingTimeout,
          journeyIndex
        }: ChatResponseDto = await SurveysService.getStartingMessages(reload ? undefined : biomarker);

        setActivePillar(journeyIndex ?? -1);

        const newMessages: ChatMessage[] = !reload ? startingMessages : startingMessages.slice(10);

        if (reload && !newMessages) {
          setMessages((previousMessages) => {
            const messagesIds = startingMessages.map((x) => x._id);
            const reloadMessages = previousMessages.filter((message) => messagesIds.includes(message._id));
            appendNewMessages([startingMessages[startingMessages.length - 1]], new Date().getTime() - startTimeMs);
            return reloadMessages.slice(1);
          });
        }
        const endTimeMs: number = new Date().getTime();
        const latencyMs: number = endTimeMs - startTimeMs;
        if (newMessages !== undefined && newMessages?.length > 0) {
          if (newMessages.length === 10) {
            setLoadEarlierMessagesEnabled(true);
          }

          if (reset) {
            for (const timeout of timeoutIds) {
              clearTimeout(timeout);
            }
            timeoutIds = [];
            setMessages(new Array<IMessage>());
          }
          appendNewMessages(newMessages, latencyMs);
        }
        if (startingTimeout) {
          const surveyTimeout = setTimeout(() => {
            setCallSurveyAfterTimeout(true);
          }, startingTimeout * 1000);
          timeoutIds.push(surveyTimeout);
        }
      } catch (error) {
        addAnalyticsLog({ function: 'startSurvey', data: error, logType: 'error' });
        setErrorModalVisible(true);
      } finally {
        setIsTyping(false);
      }
    },
    [addAnalyticsLog, appendNewMessages, biomarker]
  );

  useEffect(() => {
    if (isFocused && callSurveyAfterTimeout) {
      setCallSurveyAfterTimeout(false);
      startSurvey(false, true);
    }
  }, [callSurveyAfterTimeout, isFocused, startSurvey]);

  const sendMessageReaction = async (messageId: string, reactionTypeId: number): Promise<void> => {
    try {
      await SurveysService.addReactionToMessage(messageId, reactionTypeId);
    } catch (error) {
      addAnalyticsLog({ function: 'sendMessageReaction', data: error, logType: 'error' });
    }
  };

  const initialize = useCallback(async (): Promise<void> => {
    try {
      setUser({ _id: userInfo.userId ?? 0, name: userInfo.nickname });
      setEnableAnimation(false);
      setLoading(true);
      setMessages(new Array<IMessage>());
      debugEnabled = false;
      await startSurvey(true);
      showAllMessageReactions();
    } catch (error: any) {
      addAnalyticsLog({ function: 'initialize', data: error, logType: 'error' });
    } finally {
      setLoading(false);
      setIsLoadingEarlier(true);
      setTimeout(() => {
        setEnableAnimation(true);
      }, 1000);
    }
  }, [addAnalyticsLog, showAllMessageReactions, startSurvey, userInfo.nickname, userInfo.userId]);

  const send = async (messageId: string, messageText: string, payload: any): Promise<void> => {
    setIsTyping(true);

    try {
      const startTimeMs: number = new Date().getTime();
      const answerResponse: ChatResponseDto = await SurveysService.addAnswer(messageId, messageText, payload);

      if (answerResponse.journeyIndex) {
        setActivePillar(answerResponse.journeyIndex);
      }

      const newMessages: ChatMessage[] = answerResponse.messages;
      const endTimeMs: number = new Date().getTime();
      const latencyMs: number = endTimeMs - startTimeMs;

      // need to reload action items
      setRefreshActionItemsFlag(true);

      if (newMessages !== undefined && newMessages?.length > 0) {
        appendNewMessages(newMessages, latencyMs);
      } else {
        setIsTyping(false);
      }
      if (answerResponse.timeout) {
        const timeout = setTimeout(() => {
          setCallSurveyAfterTimeout(true);
        }, answerResponse.timeout * 1000);
        timeoutIds.push(timeout);
      }
    } catch (error) {
      addAnalyticsLog({ function: 'send', data: error, logType: 'error' });
      setIsTyping(false);
      setErrorModalVisible(true);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSend = async (): Promise<void> => {
    // Text Input received, but we don't support this yet!
  };

  const onQuickReply = async (replies: Reply[] = []): Promise<void> => {
    const messageId: string = uuidv4();
    const messageText: string = replies[0].title;
    const replyPayload: any = replies[0].value;
    const replyMessageId: string = replies[0].messageId;
    if (messages.length > 0 && messages[0]._id !== replyMessageId) {
      return;
    }

    const userMsgs: IMessage = {
      _id: messageId,
      text: messageText,
      createdAt: new Date(),
      user: user
    };

    const lastMessage = messages.find((message) => message._id === replyMessageId);

    setMessages((previousMessages) =>
      previousMessages.map((message) => {
        if (message._id !== lastMessage?._id) {
          return message;
        }
        return { ...lastMessage, quickReplies: undefined };
      })
    );

    // Publish users quick reply selection to chat window
    animateNewMessage(userMsgs, false);
    setLastSeenMessageId(userMsgs._id.toString());
    await send(messageId, messageText, replyPayload);
    clearTimeout(timeoutId);
  };

  useEffect(() => {
    initialize();
  }, [initialize]);

  // calculate width of bubble
  const setBubbleWidth = (messageId: string, width: number) => {
    const newBubbleWidths: any = bubbleWidths;
    newBubbleWidths[messageId] = width;
    setBubbleWidths(newBubbleWidths);
  };

  // calculate height of bubble
  const setBubbleHeight = (messageId: string, height: number) => {
    const newBubbleHeights: any = bubbleHeights;
    newBubbleHeights[messageId] = height;
    setBubbleHeights(newBubbleHeights);
  };

  const onScreenLayout = () => {
    const timeout = setTimeout(() => {
      forceRedrawMessages();
    }, 50);
    timeoutIds.push(timeout);
  };

  const renderFooter = () => {
    return (
      <View style={{ height: isTyping ? uh.h2DP(40) : 0, left: uh.w2DP(10), marginTop: 6 }}>
        <TypingIndicator isTyping={isTyping} />
      </View>
    );
  };

  const renderChatEmpty = () => {
    // Hack to trigger typing animation after Gift Chat has been initialized
    // Setting setIsTyping(true) before initialized, will not trigger the animation
    // We initialize our code on page focus, but Gifted Chat is usually completes
    // initialization after this callback.
    // File issue with library owner:
    // https://github.com/FaridSafi/react-native-gifted-chat/issues/2020
    return <TypingIndicator isTyping={false} />;
  };

  const renderLoading = () => {
    return <></>;
  };

  const pressEmoji = (emojiId: number) => {
    sendMessageReaction(pressedMessageId, emojiId);
    const newMessageReactions = messageReactions;
    if (newMessageReactions[pressedMessageId] !== emojiId) {
      newMessageReactions[pressedMessageId] = emojiId;
    } else {
      newMessageReactions[pressedMessageId] = null;
    }
    setMessageReactions(newMessageReactions);
    setPressedMessageId('');
    forceRedrawMessages();
  };

  const scrollToBottomComponent = (): ReactNode => {
    return (
      <AntDesign
        name="downcircle"
        size={30}
        color={th['color-primary-500']}
        accessible={true}
        accessibilityLabel={'Scroll to bottom icon'}
        testID={'scroll-to-bottom-icon'}
      />
    );
  };

  const styles = StyleSheet.create({
    container: {
      flex: 1
    },
    url: {
      color: 'blue',
      textDecorationLine: 'underline'
    },
    time: { height: 0 },
    quickReply: {
      fontSize: 14,
      fontWeight: '400',
      fontFamily: 'Poppins-Regular',
      justifyContent: 'center',
      alignItems: 'center',
      borderWidth: 1,
      maxWidth: 200,
      paddingVertical: uh.h2DP(5),
      paddingHorizontal: uh.w2DP(5),
      minHeight: 37,
      borderRadius: 13,
      margin: uh.h2DP(3),
      backgroundColor: th['color-primary-transparent-100']
    },
    longPressHint: {
      marginBottom: 5,
      fontStyle: 'italic',
      textAlign: 'center',
      marginTop: uh.h2DP(10),
      height: 30,
      backgroundColor: 'transparent'
    },
    avatarContainer: {
      height: 28,
      width: 36,
      backgroundColor: 'transparent'
    }
  });

  const resetPressedMessageId = () => {
    setPressedMessageId('');
    forceRedrawMessages();
  };

  const renderBubble = (props: any) => {
    return (
      <ChatBubbleWithAnimation
        pressedMessageId={pressedMessageId}
        message={props}
        bubbleWidth={bubbleWidths[props.currentMessage._id]}
        bubbleHeight={bubbleHeights[props.currentMessage._id]}
        reactionId={messageReactions[props.currentMessage._id]}
        isShowAllMsgsReaction={isShowAllMsgsReaction}
        pressEmoji={pressEmoji}
        resetPressedMessageId={resetPressedMessageId}
        enableAnimation={enableAnimation}
      />
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedBubble = useMemo(() => renderBubble, [messages]);

  const handleTopicChange = async (pillarIndex: number) => {
    if (pillarIndex === activePillar) {
      return;
    }

    const previousQuickReplies = messages[0].quickReplies;

    setActivePillar(pillarIndex);
    setDisableFooter(true);
    messages[0].quickReplies = undefined;
    setIsTyping(true);

    const startTimeMs: number = new Date().getTime();
    SurveysService.changeTopic(pillarIndex, lastSeenMessageId)
      .then((response: ChatResponseDto) => {
        const { journeyIndex, timeout } = response;
        timeoutIds.map((currentTimeoutId: NodeJS.Timeout) => {
          clearTimeout(currentTimeoutId);
        });
        const endTimeMs: number = new Date().getTime();
        if (journeyIndex) {
          setActivePillar(journeyIndex);
        }
        if (timeout) {
          const sleepTimeout = setTimeout(() => {
            setCallSurveyAfterTimeout(true);
          }, timeout * 1000);
          timeoutIds.push(sleepTimeout);
        }
        appendNewMessages(response.messages, endTimeMs - startTimeMs);
      })
      .catch((error) => {
        setActivePillar(-1);
        messages[0].quickReplies = previousQuickReplies;
        setIsTyping(false);
        addAnalyticsLog({ function: 'handleTopicChange', data: error, logType: 'error' });
      });
  };

  if (loading) {
    return (
      <Layout level="2" style={styles.container}>
        <Spinner visible={true} />
      </Layout>
    );
  }

  return (
    <Layout
      level="2"
      style={[styles.container, !isChatScreenEnable ? mainStyles.mainScreenContainer : null]}
      onLayout={() => onScreenLayout()}>
      {isChatScreenEnable ? (
        <GiftedChat
          key={rerenderKey}
          messages={messages}
          inverted={true}
          onSend={() => onSend()}
          onQuickReply={(replies: Reply[]) => onQuickReply(replies)}
          user={user}
          scrollToBottom
          renderLoading={renderLoading}
          renderBubble={memoizedBubble}
          listViewProps={{
            scrollsToTop: true,
            onEndReachedThreshold: 0.3,
            onEndReached: (e: { distanceFromEnd: number }) => {
              if (
                Platform.OS !== 'web' &&
                e.distanceFromEnd > -200 &&
                e.distanceFromEnd < 200 &&
                !loadingEarlierMessages
              )
                onLoadEarlierMessages();
            }
          }}
          renderMessageText={(props: any) => {
            return <ChatTextMessage data={props} setBubbleWidth={setBubbleWidth} setBubbleHeight={setBubbleHeight} />;
          }}
          minComposerHeight={0}
          maxComposerHeight={0}
          minInputToolbarHeight={uh.h2DP(8)}
          renderInputToolbar={() => null} // Hide the text input
          isTyping={isTyping}
          scrollToBottomComponent={scrollToBottomComponent}
          scrollToBottomStyle={{
            right: Platform.OS === 'web' ? 25 : 8,
            width: Platform.OS === 'web' ? 27 : 30,
            height: Platform.OS === 'web' ? 27 : 30,
            backgroundColor: 'transparent'
          }}
          onLongPress={(context, message) => {
            setPressedMessageId(message._id.toString());
            forceRedrawMessages();
          }}
          renderQuickReplies={(props: any) => <ChatQuickReplies data={props} screenWidth={uh.currentViewPort()} />}
          renderTime={() => <View style={styles.time} />}
          renderAvatar={() => <View style={styles.avatarContainer} />}
          renderFooter={renderFooter}
          renderChatFooter={() => (
            <ChatFooter
              handleTopicChange={handleTopicChange}
              activePillar={activePillar}
              disableFooter={disableFooter}
            />
          )}
          renderChatEmpty={renderChatEmpty}
          quickReplyStyle={styles.quickReply}
          bottomOffset={0}
          onLoadEarlier={Platform.OS === 'web' ? onLoadEarlierMessages : () => {}}
          loadEarlier={loadEarlierMessagesEnabled && isLoadingEarlier}
          isLoadingEarlier={Platform.OS !== 'web'}
        />
      ) : (
        <DisableTenantFeature />
      )}
    </Layout>
  );
};

export default memo(KaliChatScreen);
