import { Button, Layout, Text } from '@ui-kitten/components';
import React, { useEffect, useState } from 'react';
import { SafeAreaView, StyleSheet, View } from 'react-native';

import Spinner from 'react-native-loading-spinner-overlay';
import UserService from '../../api/user';
import { setValue } from 'src/api/storage';
import { settingStyles } from './_settingStyles';
import { UIHelper as uh } from 'src/core';
import { useAnalytics } from 'src/core/hooks/useAnalytics';
import { useAppStore } from 'src/core/store';
import { useDataSharing } from 'src/core/hooks/useDataSharing';
import { useNavigation } from '@react-navigation/native';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';

const AfterPurchaseSubscriptionScreen = (props: any) => {
  const [isLoading, setIsLoading] = useState(true);

  const { loadTenantFeatures } = useDataSharing();
  const { addAnalyticsLog } = useAnalytics('AfterPurchaseSubscriptionScreen.tsx');
  const setUserSubscriptionPlan = useAppStore((state) => state.setUserSubscriptionPlan);
  const navigation = useNavigation();

  const subscriptionName = props.route.params.subscriptionName;
  const platform = props.route.params.platform;

  const styleContainer = StyleSheet.create({
    container: {
      flex: 1,
      paddingLeft: uh.w2DP(16),
      paddingRight: uh.w2DP(16),
      paddingTop: uh.h2DP(16),
      paddingBottom: uh.h2DP(20),
      rowGap: 16
    },
    screenContainer: { flex: 1 },
    safeAreaView: { flex: 1 },
    listContainer: {
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
      marginTop: -16
    },
    text: { marginTop: 16 },
    button: { width: '100%' }
  });

  const transMessage = {
    subscriptionActive: 'The subscription was activated.',
    subscriptionType: 'Subscription type: {{sub}}',
    checkPurchases: 'Check for previous purchases...',
    subscribeThanks: 'Thank you for subscribing. You now have access to premium features:',
    feature1: ' - Unlimited access to scans page.',
    explore: 'Explore'
  };

  const t = useTranslationFunc(transMessage);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = UserService.checkUserSubscription(platform);
        const subscriptionData = await response.promise;
        const tenantKey = subscriptionData.tenantKey;
        const tenantName = subscriptionData.tenantName;
        await setValue('tenantKey', tenantKey);
        await setValue('tenantName', tenantName);
        loadTenantFeatures();
        setUserSubscriptionPlan(tenantName);
      } catch (error) {
        addAnalyticsLog({
          function: 'fetchData',
          data: 'Cannot check the data about active subscription.',
          logType: 'error'
        });
      }
      setIsLoading(false);
    };
    fetchData();
  }, [platform, setUserSubscriptionPlan, addAnalyticsLog, loadTenantFeatures]);

  return (
    <Layout level="2" style={[styleContainer.screenContainer, settingStyles.settingScreenContainer]}>
      {isLoading && <Spinner visible={true} size="large" />}
      <SafeAreaView style={styleContainer.screenContainer}>
        <View style={styleContainer.container}>
          <Text category="h6" style={styleContainer.text}>
            {t('subscriptionActive')}
          </Text>
          <Text status="basic" category="h5" style={styleContainer.text}>
            {t('subscriptionType', { sub: subscriptionName })}
          </Text>
          <Text style={styleContainer.text}>{t('subscribeThanks')}</Text>
          <Text style={styleContainer.text}>{t('feature1')}</Text>
          <Button
            style={styleContainer.button}
            onPress={() => navigation.navigate('Home')}
            size="large"
            status="primary"
            accessible={true}
            accessibilityLabel={'exploring_btn'}
            testID={'exploring_btn'}>
            {t('explore')}
          </Button>
        </View>
      </SafeAreaView>
    </Layout>
  );
};

export default AfterPurchaseSubscriptionScreen;
