import React, { PropsWithChildren } from 'react';
import { SafeAreaView, StyleProp, StyleSheet, ViewStyle } from 'react-native';

import { Layout } from '@ui-kitten/components';

interface ActionLayoutProps {
  style?: StyleProp<ViewStyle>;
}

const ActionLayout = ({ children, style }: PropsWithChildren<ActionLayoutProps>) => {
  //styles
  const styleContainer = StyleSheet.create({
    screenContainer: { flex: 1 },
    safeAreaView: { flex: 1 }
  });
  return (
    <Layout level="2" style={[styleContainer.screenContainer, style]}>
      <SafeAreaView style={styleContainer.safeAreaView}>{children}</SafeAreaView>
    </Layout>
  );
};

export default ActionLayout;
