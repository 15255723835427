import { Layout, Text } from '@ui-kitten/components';
import { StyleSheet, View, ViewProps } from 'react-native';

import { NotiSegmentItem } from 'src/core/types/Notification';
import React from 'react';
import Segment from './Segment';

//props
interface SegmentMenuProps extends ViewProps {
  segments: NotiSegmentItem[];
  name: string;
  description: string;
  onSelect: (value: number) => void;
  selectedValue: number;
}

//styles
const styleContainer = StyleSheet.create({
  container: { padding: 16, borderRadius: 8 },
  name: { marginBottom: 4 },
  segment: { rowGap: 16 },
  buttonGroup: { margin: 2, borderWidth: 0, columnGap: -1 },
  description: { marginTop: 8, marginBottom: 16 }
});

const SegmentMenu = ({ name, description, segments, onSelect, selectedValue }: SegmentMenuProps) => {
  //view
  return (
    <Layout level="1" style={styleContainer.container}>
      <Text category="s1" style={styleContainer.name}>
        {name}
      </Text>
      <Text category="c1" style={styleContainer.description}>
        {description}
      </Text>
      <View style={styleContainer.segment}>
        <Segment segments={segments} selectedValue={selectedValue} onSelect={onSelect} />
      </View>
    </Layout>
  );
};

export default SegmentMenu;
