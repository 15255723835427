import * as React from 'react';
import * as SplashScreen from 'expo-splash-screen';

import { ApplicationState, useAppStore } from '../store';
import { useGetExternalUserId, useIsUserLogged, useUser } from './useUser';

import { AnalyticsLogsData } from '../store/slices/analytics';
import { InitErrorCode } from '../types/InitError';
import { Units } from '../types/Units';
import UserService from 'src/api/user';
import { setValue } from '../../api/storage';
import { useAnalytics } from './useAnalytics';
import { useDataSharing } from './useDataSharing';
import { useGetDefaultSubscription } from './useBrands';
import { useHandshake } from './useHandshake';
import { useScanGuideSkip } from './useScanGuideSkip';

const initializeResources = async (
  userId: string,
  token: string,
  setTenantKey: (tenantKey: string) => void,
  postAuth: (userToken: string, userId: string) => Promise<any>,
  addAnalyticsLog: (log: AnalyticsLogsData) => void,
  setUserSubscriptionPlan: (userSubscriptionPlan: string) => void,
  defaultSubscription: string,
  loadTenantFeatures: () => Promise<void>,
  setUnits: (units: Units) => void
): Promise<InitErrorCode> => {
  if (!token) {
    return InitErrorCode.INVALID_TOKEN;
  }

  try {
    const handshakeData = await postAuth(token, userId);
    setUserSubscriptionPlan(handshakeData.subscription || defaultSubscription);
    setTenantKey(handshakeData.tenant);
    await setValue('tenantKey', handshakeData.tenant);
  } catch (e) {
    return InitErrorCode.POST_AUTH;
  }

  try {
    await loadTenantFeatures();
  } catch (error) {
    addAnalyticsLog({ function: 'setTenants', data: error, logType: 'error' });
    return InitErrorCode.TENANT;
  }

  try {
    const settings = await UserService.getUserSettings();
    setUnits(settings.units as Units);
  } catch (error) {
    addAnalyticsLog({ function: 'setUserSettings', data: error, logType: 'error' });
    return InitErrorCode.USER_SETTINGS;
  }

  return InitErrorCode.OK;
};

export const useAfterLoginInitializer = (): InitErrorCode | null => {
  const isInitComplete = useAppStore((state: ApplicationState) => state.isAppInitComplete);
  const [initErrorCode, setInitErrorCode] = React.useState<InitErrorCode>(InitErrorCode.OK);
  const isUserLogged = useIsUserLogged();
  const { getToken } = useUser();
  const userId = useGetExternalUserId() ?? '';
  const setAppInit = useAppStore((state: ApplicationState) => state.setAppInitComplete);
  const isAutoLogin = useAppStore((state: ApplicationState) => state.isAutoLogin);
  const setTenantKey = useAppStore((state: ApplicationState) => state.setTenantKey);

  const { loadTenantFeatures } = useDataSharing();

  const { postAuth } = useHandshake();
  const { addAnalyticsLog } = useAnalytics('useAfterLoginInitializer.ts');
  const setUserSubscriptionPlan = useAppStore((state) => state.setUserSubscriptionPlan);

  const setUnits = useAppStore((state) => state.setUnits);

  const { getScanGuideSkip } = useScanGuideSkip();
  const defaultSubscription = useGetDefaultSubscription();

  // Load any resources or data that we need prior to rendering the app
  React.useEffect(() => {
    if (!isUserLogged || !userId || isInitComplete) {
      return;
    }

    getToken()
      .then((token) =>
        initializeResources(
          userId,
          token,
          setTenantKey,
          postAuth,
          addAnalyticsLog,
          setUserSubscriptionPlan,
          defaultSubscription,
          loadTenantFeatures,
          setUnits
        )
      )
      .then((code) => {
        setInitErrorCode(code);
      })
      .catch(() => {
        setInitErrorCode(InitErrorCode.UNKNOWN);
      })
      .finally(() => {
        getScanGuideSkip();
        setAppInit(true);
        try {
          if (isAutoLogin) {
            SplashScreen.hideAsync();
          }
        } catch (error) {
          //Not important
        }
      });
  }, [
    isUserLogged,
    getToken,
    userId,
    setAppInit,
    isAutoLogin,
    isInitComplete,
    postAuth,
    addAnalyticsLog,
    setUserSubscriptionPlan,
    getScanGuideSkip,
    setTenantKey,
    loadTenantFeatures,
    defaultSubscription,
    setUnits
  ]);

  React.useEffect(() => {
    if (isUserLogged) {
      return;
    }
    setAppInit(false);
  }, [isUserLogged, setAppInit]);

  return isInitComplete ? initErrorCode : null;
};
