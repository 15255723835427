import { Layout, Text, Toggle } from '@ui-kitten/components';
import { StyleSheet, View } from 'react-native';

import React from 'react';
import { UIHelper as uh } from '../../../core';

interface ITenantItem {
  tenantName: string;
  isActive: boolean;
  deactivatedOn: string;
  tenantKey: string;
  proUserName?: string;
  handleToggle: () => void;
}

const TenantItem = (props: ITenantItem) => {
  const styleContainer = StyleSheet.create({
    container: {
      flex: 1,
      padding: uh.h2DP(16),
      borderRadius: 8,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignContent: 'center',
      marginBottom: 8
    },
    bottomTextContainer: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center'
    }
  });

  return (
    <Layout style={styleContainer.container}>
      <View style={[{ width: '55%' }, !props.proUserName && { flexDirection: 'row', alignItems: 'center' }]}>
        <Text category="s1">{props.tenantName}</Text>
        <View style={styleContainer.bottomTextContainer}>
          {props.proUserName && (
            <Text category="c1" appearance="hint">
              Invited by: {props.proUserName}
            </Text>
          )}
        </View>
      </View>
      {props.isActive ? (
        <Toggle status="primary" checked={props.isActive} onChange={props.handleToggle} />
      ) : (
        <Text
          category="c1"
          appearance="hint"
          style={props.proUserName ? { alignSelf: 'flex-end' } : { alignSelf: 'center' }}>
          Deactivated: {props.deactivatedOn}
        </Text>
      )}
    </Layout>
  );
};

export default TenantItem;
