import { Layout, Text, useTheme } from '@ui-kitten/components';
import { SafeAreaView, ScrollView, StyleSheet, View } from 'react-native';

import { ErrorModal } from 'src/components/shared/ErrorModal';
import { Loading } from 'src/components/kalichat';
import { PillarContainer } from 'src/components/home';
import ProtocolActionList from 'src/features/actions/addProtocol/ProtocolActionList';
import { ProtocolDetailsAction } from 'src/core/types/Protocols';
import React from 'react';
import { RootStackActionProps } from 'src/core/types/RootNavStackParamList';
import { mainStyles } from '../_mainStyles';
import { UIHelper as uh } from 'src/core';
import { useGetProtocolDetails } from 'src/core/hooks/useActions';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';

const NewProtocol = ({
  route: {
    params: { id }
  },
  navigation
}: RootStackActionProps<'NewProtocol'>) => {
  const styleContainer = StyleSheet.create({
    container: {
      gap: uh.h2DP(18)
    },
    pillarContainer: {
      flexDirection: 'row',
      gap: uh.h2DP(8)
    }
  });
  const transMessage = {
    pillarLabel: 'PILLARS',
    durationLabel: 'DURATION',
    cyclesLabel: 'NO. OF CYCLES',
    createdByLabel: 'CREATED BY',
    actionsLabel: 'ACTIONS'
  };

  const t = useTranslationFunc(transMessage);
  const th = useTheme();

  const [protocolDetails, loading, error] = useGetProtocolDetails(id);

  const protocolPillars = protocolDetails?.actions.reduce((prev: string[], curr: ProtocolDetailsAction) => {
    if (!prev.includes(curr.action.pillar)) {
      prev.push(curr.action.pillar);
    }
    return prev;
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return (
      <ErrorModal
        visible={true}
        message={error}
        closeBtnClick={() => {
          navigation.goBack();
        }}
      />
    );
  }

  return (
    <Layout level="3" style={{ flex: 1 }}>
      <SafeAreaView style={{ flex: 1 }}>
        <ScrollView contentContainerStyle={[styleContainer.container, mainStyles.mainScreenContainer]}>
          <Text category="h5">{protocolDetails.name}</Text>
          <Text category="p2">{protocolDetails.description}</Text>

          <View style={{ gap: uh.h2DP(12) }}>
            <View style={{ gap: uh.h2DP(4) }}>
              <Text category="p2" style={{ color: th['color-basic-600'] }}>
                {t('pillarLabel')}
              </Text>
              <View style={styleContainer.pillarContainer}>
                {protocolPillars.map((pillar: string) => (
                  <PillarContainer key={pillar} type={pillar.toLowerCase()} name={pillar} size={'small'} />
                ))}
              </View>
            </View>
            <View style={{ flexDirection: 'row', gap: uh.h2DP(128) }}>
              <View style={{ gap: uh.h2DP(4) }}>
                <Text category="p2" style={{ color: th['color-basic-600'] }}>
                  {t('durationLabel')}
                </Text>
                <Text category="s2">{`${protocolDetails.duration} ${
                  protocolDetails.duration === 1 ? 'DAY' : 'DAYS'
                }`}</Text>
              </View>
              <View style={{ gap: uh.h2DP(4) }}>
                <Text category="p2" style={{ color: th['color-basic-600'] }}>
                  {t('cyclesLabel')}
                </Text>
                <Text category="s2">{protocolDetails.cycles}</Text>
              </View>
            </View>
          </View>
          <View style={{ gap: uh.h2DP(4) }}>
            <Text category="p2" style={{ color: th['color-basic-600'] }}>
              {t('createdByLabel')}
            </Text>
            <Text category="s2">{protocolDetails.authorName}</Text>
          </View>
          <View>
            <Text category="p2" style={{ color: th['color-basic-600'] }}>
              {t('actionsLabel')}
            </Text>
            <ProtocolActionList
              actions={protocolDetails.actions.sort((a, b) =>
                a.startDay !== b.startDay ? a.startDay - b.startDay : a.endDay - b.endDay
              )}
            />
          </View>
        </ScrollView>
      </SafeAreaView>
    </Layout>
  );
};

export default NewProtocol;
