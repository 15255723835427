import React, { useEffect } from 'react';
import { RootStackScreenProps, UIHelper as uh } from '../../core';
import { SafeAreaView, StyleSheet, View } from 'react-native';
import { SocialLogIn, WelcomeAuth } from '../../components/auth';
import { useGetIsAuthLoading, useSetAuthLoading } from 'src/core/hooks/useUser';

import { KeyboardAvoidingView } from '../../components/shared';
import { Layout } from '@ui-kitten/components';
import Spinner from 'react-native-loading-spinner-overlay';
import { authStyles } from './_authStyles';

const LoginScreen = ({ route }: RootStackScreenProps<'Login'>) => {
  const isAuthLoading = useGetIsAuthLoading();
  const setIsAuthLoading = useSetAuthLoading();

  // styles
  const styleContainer = StyleSheet.create({
    screenContainer: { flex: 1 },
    safeAreaView: { flex: 1, justifyContent: 'space-around', marginTop: uh.h2DP(24) },
    viewContainer: {},
    socialLogin: { marginTop: uh.h2DP(72) },
    stdLogin: { marginVertical: uh.h2DP(18) },
    register: { marginTop: uh.h2DP(36) }
  });

  useEffect(() => {
    if (route.params?.code) {
      setIsAuthLoading(true);
    }
  }, [route.params, setIsAuthLoading]);
  // view
  return (
    <KeyboardAvoidingView scrollEnabled={false}>
      <Layout level="2" style={[authStyles.authScreenContainer, styleContainer.screenContainer]}>
        <SafeAreaView style={styleContainer.safeAreaView}>
          <View style={{ flex: 1 }}>
            <WelcomeAuth
              title={'Welcome'}
              welcomeCaption={'Please use the same login method to avoid creating more than one account.'}
            />
            <SocialLogIn type="Log In" message="Log in with" style={styleContainer.socialLogin} />
          </View>
        </SafeAreaView>
      </Layout>
      {isAuthLoading && <Spinner visible={true} />}
    </KeyboardAvoidingView>
  );
};

export default LoginScreen;
