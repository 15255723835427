import { Button, Card, Modal, Text } from '@ui-kitten/components';
import React, { useState } from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';

import { errorModal } from 'src/core/brands';
import { sendToKalibra } from 'src/api/shared/SendErrorReport';
import { UIHelper as uh } from '../../core';
import { useGetAppName } from 'src/core/hooks/useBrands';
import { useGetUserAttributes } from 'src/core/hooks/useUser';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';

interface ErrorModalProps extends ViewProps {
  visible: boolean;
  message: any;
  buttonTitle?: string;
  closeBtnClick?: () => void;
  noBtnClick?: () => void;
  testingAttributes?: {
    accessible: boolean;
    accessibilityLabel: string;
    testID: string;
  };
}

export const ErrorModal = ({
  visible,
  message,
  buttonTitle,
  closeBtnClick,
  noBtnClick,
  testingAttributes
}: ErrorModalProps) => {
  const [sent, setSent] = useState<boolean>(false);
  const [isShown, setIsShown] = useState<boolean>(visible);
  const [response, setResponse] = useState<string>('');
  const userAttributes = useGetUserAttributes();

  const t = useTranslationFunc(errorModal);
  const appName = useGetAppName();

  const styles = StyleSheet.create({
    backdrop: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)'
    },
    buttonStyle: {
      marginHorizontal: uh.h2DP(8),
      width: '45%',
      height: '100%',
      textAlign: 'center'
    },
    messageHeader: {
      marginTop: uh.h2DP(4)
    },
    messageContent: {
      color: 'gray',
      marginVertical: uh.h2DP(16)
    },
    message: {
      textAlign: 'center'
    },
    card: { marginHorizontal: uh.h2DP(24) },
    buttonArea: {
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
      marginBottom: uh.h2DP(4)
    },
    sentArea: {
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: uh.h2DP(20),
      maxHeight: uh.h2DP(42)
    }
  });

  React.useEffect(() => {
    if (visible === true) {
      setIsShown(true);
    }
  }, [message, visible]);

  return (
    <Modal
      visible={isShown}
      backdropStyle={styles.backdrop}
      onBackdropPress={() => noBtnClick && noBtnClick()}
      accessible={testingAttributes?.accessible || undefined}
      accessibilityLabel={testingAttributes?.accessibilityLabel || undefined}
      testID={testingAttributes?.testID || undefined}>
      <Card style={styles.card}>
        <Text category={!sent ? 'h5' : 'p1'} style={[styles.messageHeader, styles.message]}>
          {!sent ? t('errorHeader') : response}
        </Text>
        {!sent && <Text style={[styles.messageContent, styles.message]}>{t('errorBody', { app_name: appName })}</Text>}
        <View style={!sent ? styles.buttonArea : styles.sentArea}>
          <Button
            style={styles.buttonStyle}
            size="medium"
            onPress={() => {
              setSent(false);
              setResponse('');
              setIsShown(false);
              if (closeBtnClick) {
                closeBtnClick();
              }
            }}>
            {buttonTitle == undefined ? 'OK' : buttonTitle}
          </Button>
          {!sent && (
            <Button
              style={[styles.buttonStyle]}
              size="small"
              onPress={() => {
                sendToKalibra(message, userAttributes)
                  .then((res) => {
                    setResponse(res.data);
                    setSent(true);
                  })
                  .catch(() => {
                    setIsShown(false);
                    if (closeBtnClick) {
                      closeBtnClick();
                    }
                  });
              }}>
              {t('errorSendReportBtn', { app_name: appName })}
            </Button>
          )}
        </View>
      </Card>
    </Modal>
  );
};
