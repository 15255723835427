import { ApplicationState, useAppStore } from '../store';

import { DataSharingModal } from '../types/DataSharing';
import UserService from 'src/api/user';
import { useAnalytics } from './useAnalytics';

export const useSetDataSharing = (): ((dataSharing: boolean) => void) => {
  return useAppStore((state) => state.setDataSharing);
};

export const useGetDataSharing = (): boolean => {
  return useAppStore((state) => state.dataSharing);
};

export const useGetDataSharingModal = (): DataSharingModal => {
  return useAppStore((state) => state.dataSharingModal);
};

export const useDataSharing = () => {
  const { addAnalyticsLog } = useAnalytics('useDataSharing.ts');

  const setTenantFeatures = useAppStore((state: ApplicationState) => state.setTenantFeatures);

  const loadTenantFeatures = async () => {
    try {
      const tenantFeatures = await UserService.getTenantsFeatures();
      setTenantFeatures(tenantFeatures);
    } catch (e) {
      addAnalyticsLog({ function: 'loadTenantFeatures', data: e, logType: 'error' });
    }
  };

  return { loadTenantFeatures };
};
