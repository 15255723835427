import { AVPlaybackStatusSuccess, Audio } from 'expo-av';
import { Icon, Spinner, Text, useTheme } from '@ui-kitten/components';
import React, { useEffect, useState } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import { ChatMessageProps } from 'src/core/types/ChatMessage';
import ProgressBar from './ProgressBar';
import { Sound } from 'expo-av/build/Audio';
import moment from 'moment';
import { UIHelper as uh } from 'src/core';
import { useAnalytics } from 'src/core/hooks/useAnalytics';
import { useChat } from 'src/core/hooks/useChat';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';

const ChatAudioMessage = ({ message }: ChatMessageProps) => {
  const [sound, setSound] = useState<Sound>();
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [playbackDuration, setPlaybackDuration] = useState<number>(0);
  const [playbackMaxDuration, setPlaybackMaxDuration] = useState<number>(0);

  const [audio, setAudio] = useState<string>('');

  const th = useTheme();
  const { addAnalyticsLog } = useAnalytics('ChatAudioMessage.tsx');

  const { getMedia } = useChat();

  const transMessages = {
    errorMessage: "Couldn't load audio file"
  };

  const t = useTranslationFunc(transMessages);

  const styleContainer = StyleSheet.create({
    container: {
      width: uh.h2DP(256),
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderRadius: uh.height(),
      borderWidth: 1,
      borderColor: th['border-basic-color-4'],
      padding: uh.h2DP(12)
    },
    progressContainer: {
      alignItems: 'center',
      justifyContent: 'center'
    }
  });

  useEffect(() => {
    if (audio) {
      Audio.Sound.createAsync({ uri: audio })
        .then((audioSound) => {
          setSound(audioSound.sound);
        })
        .catch((err) => {
          addAnalyticsLog({ function: 'Audio.Sound.createAsync', data: err, logType: 'error' });
          setError(true);
        });
    }
  }, [addAnalyticsLog, audio]);

  useEffect(() => {
    if (sound) {
      sound.setOnPlaybackStatusUpdate(async (status) => {
        setPlaybackMaxDuration((status as AVPlaybackStatusSuccess).durationMillis ?? 0);
        setPlaybackDuration((status as AVPlaybackStatusSuccess).positionMillis);
        setIsPlaying((status as AVPlaybackStatusSuccess).isPlaying);

        if ((status as AVPlaybackStatusSuccess).didJustFinish) {
          await sound.stopAsync();
          setPlaybackDuration(0);
          await sound.setPositionAsync(0);
        }
      });
    }
    return sound
      ? () => {
          sound.unloadAsync();
          setSound(undefined);
        }
      : undefined;
  }, [sound]);

  useEffect(() => {
    if (message.isSender) {
      setAudio(message.audio ?? '');
    } else {
      if (message.audio) {
        getMedia(message.audio)
          .then((audioSrc) => setAudio(audioSrc))

          .catch((err) => {
            addAnalyticsLog({ function: 'getMedia()', data: err, logType: 'error' });
            setError(true);
          });
      }
    }
  }, [addAnalyticsLog, getMedia, message.audio, message.isSender]);

  if (error) {
    return (
      <View style={[styleContainer.container, { justifyContent: 'center' }]}>
        <Text status="danger">{t('errorMessage')}</Text>
      </View>
    );
  }

  if (sound) {
    return (
      <View style={styleContainer.container}>
        {isPlaying ? (
          <TouchableOpacity
            onPress={() => {
              sound.pauseAsync();
            }}>
            <Icon height={uh.h2DP(48)} width={uh.h2DP(48)} fill={th['color-primary-500']} name="pause-circle-outline" />
          </TouchableOpacity>
        ) : (
          <TouchableOpacity
            onPress={() => {
              sound.playAsync();
            }}>
            <Icon height={uh.h2DP(48)} width={uh.h2DP(48)} fill={th['color-primary-500']} name="play-circle-outline" />
          </TouchableOpacity>
        )}
        <ProgressBar
          containerStyle={styleContainer.progressContainer}
          progress={playbackDuration}
          maxProgress={playbackMaxDuration}
          setProgress={setPlaybackDuration}
          sound={sound}
          progressBarWidth={uh.h2DP(168)}
          shouldPlay={isPlaying}>
          <Text style={{ position: 'absolute', color: th['color-basic-600'] }}>
            {moment.utc(playbackDuration).format('mm:ss')} / {moment.utc(playbackMaxDuration).format('mm:ss')}
          </Text>
        </ProgressBar>
      </View>
    );
  }

  return (
    <View style={styleContainer.container}>
      <Spinner size={'giant'} />
    </View>
  );
};

export default ChatAudioMessage;
