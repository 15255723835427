import { BodyScanData, FaceScanData, toBodyScanData, toFaceScanData } from 'src/core/types/Scan';

import MultiScanModule from 'src/core/modules/MultiScanModule';
import { NumberHelper } from 'src/core/helpers/NumberHelper';

/**
 * Once successfully setup, you should authorize your user with our service.
 *
 * With your signed in user, you can authorize them to use the AHI service,  provided that they have agreed to a payment method.
 */
// const authorizeUser = async (userId: string, claim: string) => {
//   await MultiScanModule.authorizeUser(userId, Config.AHI_DEV_MULTISCAN_AUTHZ_SALT, [claim]);
// };

// /**
//  * Setup the MultiScan SDK
//  *
//  * This must happen before requesting a scan.
//  * We recommend doing this on successful load of your application.
//  */
// export const setupMultiScanSDK = async (userId: string, claim: string) => {
//   // try to release the actively registered SDK session
//   try {
//     await MultiScanModule.releaseMultiScanSDK();
//   } catch (error) {
//     console.error(`releaseMultiScanSDK: ${JSON.stringify(error)}`);
//   }

//   const result = await MultiScanModule.setupMultiScanSDK(Config.AHI_DEV_MULTISCAN_TOKEN);
//   if (result !== '') {
//     return;
//   }
//   await authorizeUser(userId, claim);
//   MultiScanModule.areAHIResourcesAvailable().then(async (areAvailable: boolean) => {
//     if (!areAvailable) {
//       MultiScanModule.downloadAHIResources();
//     }
//   });

// };

/**
 * All MultiScan scan configs require this information.
 *
 * BodyScan: https://docs.advancedhumanimaging.io/MultiScan%20SDK/BodyScan/Schemas/ FaceScan:
 * https://docs.advancedhumanimaging.io/MultiScan%20SDK/FaceScan/Schemas/
 */
function areSharedScanConfigOptionsValid(inputValues: Map<string, any>): boolean {
  const sex = inputValues.get('enum_ent_sex');
  const height = inputValues.get('cm_ent_height');
  const weight = inputValues.get('kg_ent_weight');
  return (
    sex != null &&
    height != null &&
    NumberHelper.isValidNumber(height) &&
    weight != null &&
    NumberHelper.isValidNumber(weight) &&
    ['male', 'female'].includes(sex)
  );
}

/**
 * FaceScan config requirements validation. Please see the Schemas for more information:
 * FaceScan: https://docs.advancedhumanimaging.io/MultiScan%20SDK/FaceScan/Schemas/
 */
function areFaceScanConfigOptionsValid(inputValues: Map<string, any>): boolean {
  if (!areSharedScanConfigOptionsValid(inputValues)) {
    return false;
  }
  const sex = inputValues.get('enum_ent_sex');
  const smoke = inputValues.get('bool_ent_smoker');
  const isDiabetic = inputValues.get('enum_ent_diabetic');
  const hypertension = inputValues.get('bool_ent_hypertension');
  const blood = inputValues.get('bool_ent_bloodPressureMedication');
  const height = inputValues.get('cm_ent_height');
  const weight = inputValues.get('kg_ent_weight');
  const age = inputValues.get('yr_ent_age');
  return (
    sex != null &&
    smoke != null &&
    isDiabetic != null &&
    hypertension != null &&
    blood != null &&
    height != null &&
    weight != null &&
    age != null &&
    height >= 25 &&
    height <= 300 &&
    weight >= 25 &&
    weight <= 300 &&
    ['none', 'type1', 'type2'].includes(isDiabetic)
  );
}

const objectToMap = (scanResult: any) => {
  const keys = Object.keys(scanResult);
  const map = new Map();
  for (let i = 0; i < keys.length; i++) {
    map.set(keys[i], scanResult[keys[i]]);
  }
  return map;
};

export const ahiStartFaceScan = async (userFaceScanInput: any): Promise<FaceScanData | undefined> => {
  if (!areFaceScanConfigOptionsValid(objectToMap(userFaceScanInput))) {
    return undefined;
  }
  const faceScanResults = await MultiScanModule.startFaceScan(userFaceScanInput);
  //console.log('AHI: SCAN RESULTS: ' + JSON.stringify(faceScanResults));
  const scanResult = toFaceScanData((faceScanResults as any).id, faceScanResults, userFaceScanInput);
  return scanResult;
};

/** Confirm results have correct set of keys. */
function areBodyScanSmoothingResultsValid(resultsList: Array<Map<string, any>>): boolean {
  /* Your token may only provide you access to a smaller subset of results. */
  /* You should modify this list based on your available config options. */
  const sdkResultSchema = [
    'enum_ent_sex',
    'cm_ent_height',
    'kg_ent_weight',
    'cm_raw_chest',
    'cm_raw_hips',
    'cm_raw_inseam',
    'cm_raw_thigh',
    'cm_raw_waist',
    'kg_raw_weightPredict',
    'percent_raw_bodyFat',
    'id',
    'date'
  ];
  let isValid = true;
  for (const result of resultsList) {
    for (const key of sdkResultSchema) {
      /* Check if keys in result contains the required keys. */
      if (!Object.prototype.hasOwnProperty.call(result, key)) {
        isValid = false;
      }
    }
  }
  return isValid;
}

/**
 * Use this function to fetch the 3D avatar mesh.
 *
 * The 3D mesh can be created and returned at any time.
 * We recommend doing this on successful completion of a body scan with the results.
 */
function getBodyScanExtras(bodyScanResults: Map<string, any>) {
  if (bodyScanResults == null) {
    // console.log('AHI ERROR: Body scan results must not be null.');
    return;
  }
  JSON.parse(JSON.stringify(bodyScanResults));
  if (!areBodyScanSmoothingResultsValid([bodyScanResults])) {
    // console.log('AHI ERROR: Body scan results not valid for extras.');
    return;
  }
  // MultiScanModule.getBodyScanExtras(results).then(() => {
  //   // console.log('AHI 3D Mesh : ' + path['meshURL']);
  //   // console.log('AHI 3D Mesh : ' + JSON.stringify(path));
  // });
}

/**
 * BodyScan config requirements validation. Please see the Schemas for more information:
 * BodyScan: https://docs.advancedhumanimaging.io/MultiScan%20SDK/BodyScan/Schemas/
 */
function areBodyScanConfigOptionsValid(inputValues: Map<string, any>): boolean {
  if (!areSharedScanConfigOptionsValid(inputValues)) {
    return false;
  }
  const sex = inputValues.get('enum_ent_sex');
  const height = inputValues.get('cm_ent_height');
  const weight = inputValues.get('kg_ent_weight');
  return (
    sex != null && height != null && weight != null && height >= 50 && height <= 255 && weight >= 16 && weight <= 300
  );
}

export const ahiStartBodyScan = async (userBodyScanInput: any): Promise<BodyScanData | undefined> => {
  if (!areBodyScanConfigOptionsValid(objectToMap(userBodyScanInput))) {
    return undefined;
  }

  const bodyScanResults = await MultiScanModule.startBodyScan(userBodyScanInput);
  // console.log('AHI: SCAN RESULTS: ' + JSON.stringify(bodyScanResults));
  getBodyScanExtras(bodyScanResults);
  const scanResult = toBodyScanData(bodyScanResults, userBodyScanInput);
  return scanResult;
};
