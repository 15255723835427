import { Circle, G, Mask, Path, Svg } from 'react-native-svg';

import React from 'react';
import { SvgIconProp } from 'src/core/types/ScanIcons';

interface FaceScanAvatarBackgroundProps extends SvgIconProp {
  backgroundColor: string;
}
export const FaceScanAvatarBackground = ({
  color,
  height = 200,
  width = 150,
  backgroundColor
}: FaceScanAvatarBackgroundProps) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 330 330" fill="none">
      <Circle
        cx="165"
        cy="165"
        r="163.5"
        fill={backgroundColor}
        stroke="#C5CEE0"
        stroke-width="3"
        stroke-dasharray="2 2"
      />
      <Mask id="mask0_241_6925" maskUnits="userSpaceOnUse" x="0" y="0" width="330" height="330">
        <Circle cx="165" cy="165" r="165" fill="#F8F9FC" />
      </Mask>
      <G mask="url(#mask0_241_6925)">
        <G opacity="0.5">
          <Path
            d="M249.679 98.2887C243.955 30.1925 175.274 29 175.274 29H154.734C154.734 29 86.0447 30.1925 80.3207 98.2887C79.3739 109.578 80.4074 120.831 82.7997 131.744C83.573 135.271 84.1512 138.841 84.4619 142.441C86.3772 164.751 90.186 213.442 96.3293 226.704C124.848 288.224 159.178 286.735 159.178 286.735H170.814C170.814 286.735 205.152 288.231 233.663 226.704C239.814 213.442 243.623 164.744 245.531 142.441C245.842 138.834 246.42 135.271 247.193 131.744C249.578 120.831 250.619 109.585 249.672 98.2887H249.679Z"
            stroke={color}
            stroke-miterlimit="10"
          />
          <Path
            d="M99.4009 233.129C99.4009 233.129 99.4009 267.322 97.9121 276.277C96.416 285.239 81.7878 303.452 81.7878 303.452"
            stroke={color}
            stroke-miterlimit="10"
          />
          <Path
            d="M230.599 233.129C230.599 233.129 230.599 267.322 232.088 276.277C233.584 285.239 248.212 303.452 248.212 303.452"
            stroke={color}
            stroke-miterlimit="10"
          />
          <Path
            d="M84.7221 145.498C83.1755 143.503 80.9639 141.573 77.9718 141.082C72.4429 140.171 67.2103 148.461 71.6912 168.474C76.0565 187.966 75.8903 200.65 90.5113 201.018C87.8516 182.748 85.9508 159.989 84.7221 145.505V145.498Z"
            stroke={color}
            stroke-miterlimit="10"
          />
          <Path
            d="M252.028 141.082C249.036 141.573 246.825 143.51 245.278 145.498C244.049 159.981 242.148 182.74 239.489 201.011C254.11 200.643 253.943 187.958 258.309 168.466C262.79 148.454 257.557 140.164 252.028 141.075V141.082Z"
            stroke={color}
            stroke-miterlimit="10"
          />
          <Path
            d="M165.004 240.35V303.459"
            stroke={color}
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <Path
            d="M165.004 142.788V203.859"
            stroke={color}
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <Path
            d="M165.004 29.2891V60.8437"
            stroke={color}
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <Path
            d="M132.748 229.537H197.252"
            stroke={color}
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <Path
            d="M147.68 152.046L135.74 156.599H123.642L112.136 150.701L123.338 144.125H135.878L136.246 144.363L147.68 152.039V152.046Z"
            stroke={color}
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <Path
            d="M147.68 152.046L154.698 167.122L90.5115 201.011L123.642 156.592"
            stroke={color}
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <Path
            d="M114.976 124.119L123.338 144.125"
            stroke={color}
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <Path
            d="M112.143 150.701L84.7224 145.498L114.976 124.119L146.336 127.4L155.442 139.051L154.698 167.122L142.903 193.104V199.674L156.042 206.323V221.775"
            stroke={color}
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <Path
            d="M156.642 198.33H148.576L152.609 195.497L165.004 203.859"
            stroke={color}
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <Path
            d="M165.004 224.016L156.042 221.775L132.3 229.537L155.442 239.54C155.442 239.54 165.596 240.588 164.997 240.588"
            stroke={color}
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <Path
            d="M142.903 193.104C142.903 193.104 127.971 209.677 127.523 209.677C127.075 209.677 113.646 256.337 113.646 256.337L155.449 239.547"
            stroke={color}
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <Path
            d="M156.042 206.323L165.004 208.564"
            stroke={color}
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <Path
            d="M165.025 142.788L155.442 139.051L164.997 118.518L148.569 85.1421L112.143 42.3489M112.143 42.3489L146.335 127.408L148.576 85.1493C148.576 85.1493 165.004 61.1834 165.025 61.1834C165.047 61.1834 112.143 42.3489 112.143 42.3489ZM112.143 42.3489L100.196 86.5514"
            stroke={color}
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <Path
            d="M114.976 124.119L129.908 86.5442H82.041L114.976 124.119Z"
            stroke={color}
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <Path
            d="M165.004 29L138.581 51.7589"
            stroke={color}
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <Path
            d="M136.925 147.362C136.925 151.164 133.847 154.243 130.038 154.243C126.229 154.243 123.15 151.164 123.15 147.362C123.15 146.199 123.439 145.093 123.96 144.125H135.885L136.253 144.363C136.694 145.274 136.933 146.293 136.933 147.355L136.925 147.362Z"
            stroke={color}
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <Path
            d="M130.038 303.459L97.6014 277.636C96.6112 277.997 113.646 256.336 113.646 256.336L130.045 303.452L165.033 286.728L136.933 246.977"
            stroke={color}
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <Path
            d="M97.6016 277.635L102.437 303.459"
            stroke={color}
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <Path
            d="M113.639 256.337L99.2058 251.798"
            stroke={color}
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <Path
            d="M70.7734 148.006L75.4062 147.261L86.5146 165.937L79.9739 174.74L88.4588 184.923"
            stroke={color}
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <Path
            d="M75.4062 147.261L72.9272 174.335"
            stroke={color}
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <Path
            d="M79.974 174.74L74.1704 180.529C74.1704 180.529 91.2631 201.047 90.5115 201.011L127.523 209.684L132.3 229.545L142.903 199.681"
            stroke={color}
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <Path
            d="M182.371 152.046L194.311 156.599H206.409L217.915 150.701L206.713 144.125H194.166L193.798 144.363L182.364 152.039L182.371 152.046Z"
            stroke={color}
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <Path
            d="M182.371 152.046L175.353 167.122L239.547 201.011L206.416 156.592"
            stroke={color}
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <Path
            d="M215.075 124.119L206.713 144.125"
            stroke={color}
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <Path
            d="M217.915 150.701L245.329 145.498L215.075 124.119L183.715 127.4L174.609 139.051L175.353 167.122L187.148 193.104V199.674L174.009 206.323V221.775"
            stroke={color}
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <Path
            d="M173.416 198.33H181.475L177.449 195.497L165.054 203.859"
            stroke={color}
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <Path
            d="M165.054 224.016L174.016 221.775L197.758 229.537L174.616 239.54C174.616 239.54 164.462 240.588 165.062 240.588"
            stroke={color}
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <Path
            d="M187.148 193.104C187.148 193.104 202.08 209.677 202.528 209.677C202.976 209.677 216.405 256.337 216.405 256.337L174.602 239.547"
            stroke={color}
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <Path
            d="M174.009 206.323L165.054 208.564"
            stroke={color}
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <Path
            d="M165.025 142.788L174.609 139.051L165.054 118.518L181.482 85.1421M181.482 85.1421L217.915 42.3417M181.482 85.1421L183.723 127.4L217.915 42.3417M181.482 85.1421C181.482 85.1421 165.054 61.1762 165.033 61.1762C165.011 61.1762 217.915 42.3417 217.915 42.3417M217.915 42.3417L229.862 86.5442"
            stroke={color}
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <Path
            d="M215.075 124.119L200.143 86.5442H248.01L215.075 124.119Z"
            stroke={color}
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <Path
            d="M165.054 29L191.47 51.7589"
            stroke={color}
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <Path
            d="M193.125 147.362C193.125 151.164 196.204 154.243 200.013 154.243C203.822 154.243 206.901 151.164 206.901 147.362C206.901 146.199 206.612 145.093 206.091 144.125H194.166L193.798 144.363C193.357 145.274 193.118 146.293 193.118 147.355L193.125 147.362Z"
            stroke={color}
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <Path
            d="M200.013 303.459L232.45 277.636C233.44 277.997 216.405 256.336 216.405 256.336L200.006 303.452L165.018 286.728L193.118 246.977"
            stroke={color}
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <Path
            d="M232.457 277.635L227.622 303.459"
            stroke={color}
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <Path
            d="M216.412 256.337L230.845 251.798"
            stroke={color}
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <Path
            d="M259.285 148.006L254.645 147.261L243.536 165.937L250.084 174.74L241.599 184.923"
            stroke={color}
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <Path
            d="M254.645 147.261L257.124 174.335"
            stroke={color}
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <Path
            d="M250.084 174.74L255.888 180.529C255.888 180.529 238.795 201.047 239.547 201.011L202.536 209.684L197.758 229.545L187.156 199.681"
            stroke={color}
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <Path
            d="M154.676 167.122H175.332"
            stroke={color}
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </G>
        <Path
          d="M232.254 279.428C233.452 279.428 234.422 278.457 234.422 277.26C234.422 276.062 233.452 275.091 232.254 275.091C231.057 275.091 230.086 276.062 230.086 277.26C230.086 278.457 231.057 279.428 232.254 279.428Z"
          fill="#46D7CB"
        />
        <Path
          d="M165.04 288.824C166.237 288.824 167.208 287.853 167.208 286.655C167.208 285.458 166.237 284.487 165.04 284.487C163.842 284.487 162.872 285.458 162.872 286.655C162.872 287.853 163.842 288.824 165.04 288.824Z"
          fill="#46D7CB"
        />
        <Path
          d="M136.853 249.073C138.05 249.073 139.021 248.102 139.021 246.905C139.021 245.707 138.05 244.737 136.853 244.737C135.656 244.737 134.685 245.707 134.685 246.905C134.685 248.102 135.656 249.073 136.853 249.073Z"
          fill="#46D7CB"
        />
        <Path
          d="M142.635 195.591C143.832 195.591 144.803 194.62 144.803 193.422C144.803 192.225 143.832 191.254 142.635 191.254C141.438 191.254 140.467 192.225 140.467 193.422C140.467 194.62 141.438 195.591 142.635 195.591Z"
          fill="#46D7CB"
        />
        <Path
          d="M154.199 169.572C155.396 169.572 156.367 168.601 156.367 167.404C156.367 166.206 155.396 165.236 154.199 165.236C153.001 165.236 152.031 166.206 152.031 167.404C152.031 168.601 153.001 169.572 154.199 169.572Z"
          fill="#46D7CB"
        />
        <Path
          d="M175.158 169.572C176.355 169.572 177.326 168.601 177.326 167.404C177.326 166.206 176.355 165.236 175.158 165.236C173.96 165.236 172.99 166.206 172.99 167.404C172.99 168.601 173.96 169.572 175.158 169.572Z"
          fill="#46D7CB"
        />
        <Path
          d="M187.445 195.591C188.642 195.591 189.613 194.62 189.613 193.422C189.613 192.225 188.642 191.254 187.445 191.254C186.247 191.254 185.276 192.225 185.276 193.422C185.276 194.62 186.247 195.591 187.445 195.591Z"
          fill="#46D7CB"
        />
        <Path
          d="M239.481 202.818C240.679 202.818 241.65 201.847 241.65 200.65C241.65 199.452 240.679 198.482 239.481 198.482C238.284 198.482 237.313 199.452 237.313 200.65C237.313 201.847 238.284 202.818 239.481 202.818Z"
          fill="#46D7CB"
        />
        <Path
          d="M90.5979 202.818C91.7954 202.818 92.7661 201.847 92.7661 200.65C92.7661 199.452 91.7954 198.482 90.5979 198.482C89.4004 198.482 88.4297 199.452 88.4297 200.65C88.4297 201.847 89.4004 202.818 90.5979 202.818Z"
          fill="#46D7CB"
        />
        <Path
          d="M79.7568 176.077C80.9543 176.077 81.925 175.106 81.925 173.909C81.925 172.711 80.9543 171.74 79.7568 171.74C78.5594 171.74 77.5886 172.711 77.5886 173.909C77.5886 175.106 78.5594 176.077 79.7568 176.077Z"
          fill="#46D7CB"
        />
        <Path
          d="M250.323 176.077C251.52 176.077 252.491 175.106 252.491 173.909C252.491 172.711 251.52 171.74 250.323 171.74C249.125 171.74 248.154 172.711 248.154 173.909C248.154 175.106 249.125 176.077 250.323 176.077Z"
          fill="#46D7CB"
        />
        <Path
          d="M84.8162 147.167C86.0136 147.167 86.9844 146.197 86.9844 144.999C86.9844 143.802 86.0136 142.831 84.8162 142.831C83.6187 142.831 82.6479 143.802 82.6479 144.999C82.6479 146.197 83.6187 147.167 84.8162 147.167Z"
          fill="#46D7CB"
        />
        <Path
          d="M114.448 126.208C115.646 126.208 116.616 125.237 116.616 124.04C116.616 122.842 115.646 121.872 114.448 121.872C113.251 121.872 112.28 122.842 112.28 124.04C112.28 125.237 113.251 126.208 114.448 126.208Z"
          fill="#46D7CB"
        />
        <Path
          d="M214.908 126.208C216.106 126.208 217.077 125.237 217.077 124.04C217.077 122.842 216.106 121.872 214.908 121.872C213.711 121.872 212.74 122.842 212.74 124.04C212.74 125.237 213.711 126.208 214.908 126.208Z"
          fill="#46D7CB"
        />
        <Path
          d="M81.925 88.6257C83.1225 88.6257 84.0933 87.655 84.0933 86.4575C84.0933 85.26 83.1225 84.2893 81.925 84.2893C80.7276 84.2893 79.7568 85.26 79.7568 86.4575C79.7568 87.655 80.7276 88.6257 81.925 88.6257Z"
          fill="#46D7CB"
        />
        <Path
          d="M248.154 88.6257C249.352 88.6257 250.322 87.655 250.322 86.4575C250.322 85.26 249.352 84.2893 248.154 84.2893C246.957 84.2893 245.986 85.26 245.986 86.4575C245.986 87.655 246.957 88.6257 248.154 88.6257Z"
          fill="#46D7CB"
        />
        <Path
          d="M245.263 147.167C246.461 147.167 247.432 146.197 247.432 144.999C247.432 143.802 246.461 142.831 245.263 142.831C244.066 142.831 243.095 143.802 243.095 144.999C243.095 146.197 244.066 147.167 245.263 147.167Z"
          fill="#46D7CB"
        />
        <Path
          d="M193.227 249.073C194.424 249.073 195.395 248.102 195.395 246.905C195.395 245.707 194.424 244.737 193.227 244.737C192.029 244.737 191.058 245.707 191.058 246.905C191.058 248.102 192.029 249.073 193.227 249.073Z"
          fill="#46D7CB"
        />
        <Path
          d="M97.8252 279.428C99.0227 279.428 99.9934 278.457 99.9934 277.26C99.9934 276.062 99.0227 275.091 97.8252 275.091C96.6277 275.091 95.657 276.062 95.657 277.26C95.657 278.457 96.6277 279.428 97.8252 279.428Z"
          fill="#46D7CB"
        />
        <Path
          d="M165.04 63.3299C166.237 63.3299 167.208 62.3592 167.208 61.1617C167.208 59.9643 166.237 58.9935 165.04 58.9935C163.842 58.9935 162.872 59.9643 162.872 61.1617C162.872 62.3592 163.842 63.3299 165.04 63.3299Z"
          fill="#46D7CB"
        />
        <Path
          d="M138.299 53.9344C139.496 53.9344 140.467 52.9637 140.467 51.7662C140.467 50.5687 139.496 49.598 138.299 49.598C137.101 49.598 136.13 50.5687 136.13 51.7662C136.13 52.9637 137.101 53.9344 138.299 53.9344Z"
          fill="#46D7CB"
        />
        <Path
          d="M129.626 88.6257C130.823 88.6257 131.794 87.655 131.794 86.4575C131.794 85.26 130.823 84.2893 129.626 84.2893C128.428 84.2893 127.458 85.26 127.458 86.4575C127.458 87.655 128.428 88.6257 129.626 88.6257Z"
          fill="#46D7CB"
        />
        <Path
          d="M148.417 87.1875C149.614 87.1875 150.585 86.2167 150.585 85.0192C150.585 83.8218 149.614 82.851 148.417 82.851C147.22 82.851 146.249 83.8218 146.249 85.0192C146.249 86.2167 147.22 87.1875 148.417 87.1875Z"
          fill="#46D7CB"
        />
        <Path
          d="M181.663 87.1875C182.86 87.1875 183.831 86.2167 183.831 85.0192C183.831 83.8218 182.86 82.851 181.663 82.851C180.465 82.851 179.494 83.8218 179.494 85.0192C179.494 86.2167 180.465 87.1875 181.663 87.1875Z"
          fill="#46D7CB"
        />
        <Path
          d="M200.454 88.6257C201.651 88.6257 202.622 87.655 202.622 86.4575C202.622 85.26 201.651 84.2893 200.454 84.2893C199.256 84.2893 198.286 85.26 198.286 86.4575C198.286 87.655 199.256 88.6257 200.454 88.6257Z"
          fill="#46D7CB"
        />
        <Path
          d="M191.058 53.9344C192.256 53.9344 193.227 52.9637 193.227 51.7662C193.227 50.5687 192.256 49.598 191.058 49.598C189.861 49.598 188.89 50.5687 188.89 51.7662C188.89 52.9637 189.861 53.9344 191.058 53.9344Z"
          fill="#46D7CB"
        />
      </G>
    </Svg>
  );
};
