import { SimpleState } from 'src/core/types/SimpleState';
import { StateCreator } from 'zustand';

export interface MultiScanSlice {
  multiScanUser: SimpleState<boolean>;
  initMultiScan: SimpleState<boolean>;
  scanGuideSkip: Map<string, boolean>;
  setMultiScanInitComplete: (isInit: boolean) => void;
  setMultiScanInitLoading: (loading: boolean) => void;
  setMultiScanInitError: (hasError: boolean) => void;
  setMultiScanUserLoading: (loading: boolean) => void;
  setMultiScanUserError: (hasError: boolean) => void;
  setMultiScanUser: (value: boolean) => void;
  setScanGuideSkip: (scanGuideSkip: Map<string, boolean>) => void;
}

export const createMultiScanSlice: StateCreator<MultiScanSlice, [], [], MultiScanSlice> = (set) => ({
  multiScanUser: {
    data: false,
    isLoading: false,
    hasError: false
  },
  initMultiScan: {
    data: false,
    isLoading: false,
    hasError: false
  },
  isMultiScanInitComplete: false,
  scanGuideSkip: new Map<string, boolean>([
    ['body', false],
    ['face', false],
    ['hrv', false],
    ['bloodwork', false]
  ]),
  setMultiScanInitComplete: (isInit: boolean) =>
    set((state) => ({ initMultiScan: { ...state.initMultiScan, data: isInit } })),
  setMultiScanInitError: (value: boolean) =>
    set((state) => ({ initMultiScan: { ...state.initMultiScan, hasError: value } })),
  setMultiScanInitLoading: (value: boolean) =>
    set((state) => ({ initMultiScan: { ...state.initMultiScan, isLoading: value } })),
  setMultiScanUserError: (value: boolean) =>
    set((state) => ({ multiScanUser: { ...state.multiScanUser, hasError: value } })),
  setMultiScanUserLoading: (value: boolean) =>
    set((state) => ({ multiScanUser: { ...state.multiScanUser, isLoading: value } })),
  setMultiScanUser: (value: boolean) => set((state) => ({ multiScanUser: { ...state.multiScanUser, data: value } })),
  setScanGuideSkip: (scanGuideSkip: Map<string, boolean>) => set(() => ({ scanGuideSkip }))
});
