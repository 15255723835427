import React from 'react';
import { ViewProps, View } from 'react-native';
import PillarCheckBox from './PillarCheckBox';

//props
interface PillarCheckboxListItemProps extends ViewProps {
  style?: any;
  type: string;
  name: string;
  onChange: (checked: boolean) => void;
  checked: boolean;
  disabled: boolean;
}

const PillarCheckboxListItem = ({ style, type, name, onChange, checked, disabled }: PillarCheckboxListItemProps) => {
  return (
    <View style={style}>
      <PillarCheckBox type={type} name={name} onChange={onChange} checked={checked} disabled={disabled} />
    </View>
  );
};

export default PillarCheckboxListItem;
