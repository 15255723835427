import { Layout, useTheme } from '@ui-kitten/components';
import React, { PropsWithChildren } from 'react';
import { StyleSheet, ViewStyle } from 'react-native';

import { UIHelper as uh } from 'src/core';

export interface DynamicCardProps extends PropsWithChildren {
  layoutLevel: string;
  style?: ViewStyle;
  accessibilityLabel?: string;
  testID?: string;
}
const DynamicCardLayout = ({
  layoutLevel,
  children,
  style,
  accessibilityLabel,
  testID
}: DynamicCardProps): JSX.Element => {
  const th = useTheme();

  const styleContainer = StyleSheet.create({
    layout: {
      // flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      borderWidth: 1,
      borderRadius: 16,
      borderColor: th['border-basic-color-4'],
      marginHorizontal: 20,
      marginVertical: uh.h2DP(4),
      padding: 18
    }
  });

  return (
    <Layout
      level={layoutLevel}
      style={[styleContainer.layout, style]}
      accessible={true}
      accessibilityLabel={accessibilityLabel}
      testID={testID}>
      {children}
    </Layout>
  );
};

export default DynamicCardLayout;
