import { ScrollView, StyleSheet, View, ViewProps } from 'react-native';

import MarkerListItem from './MarkerListItem';
import { PILLARS } from 'src/core/constants/Pillars';
import React from 'react';
import { UIHelper as uh } from '../../core';
import { useGetPillarCategories } from 'src/core/hooks/usePillarCategories';

//props
interface IMakerListProps extends ViewProps {
  btnClickHandler: (name: string) => void;
  markerName: string;
}

const MakerList = (props: IMakerListProps) => {
  // styles
  const styleContainer = StyleSheet.create({
    container: { flexDirection: 'row', alignItems: 'center' },
    item: { marginTop: uh.h2DP(8), marginRight: uh.w2DP(4) }
  });

  const activePillars = useGetPillarCategories().map((pillar) => pillar.name);
  const [selectedMarker, setSelectedMarker] = React.useState(' All ');

  const selectMarker = (name: string) => {
    setSelectedMarker(name);
    props.btnClickHandler(name);
  };

  const renderItems = () => {
    return PILLARS.map((item, index) => {
      if (activePillars.includes(item.name) || item.name === ' All ') {
        return (
          <MarkerListItem
            style={styleContainer.item}
            key={`item-index-${index}`}
            name={item.name}
            type={item.type}
            isSelected={item.name == selectedMarker ? true : false}
            btnClickHandler={() => {
              selectMarker(item.name);
            }}
          />
        );
      }
    });
  };

  // view
  return (
    <View style={[styleContainer.container, props.style]}>
      <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
        <View style={styleContainer.container}>{renderItems()}</View>
      </ScrollView>
    </View>
  );
};

export default MakerList;
