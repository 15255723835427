import moment from 'moment';
const isValidNumber = (value: number, minimumValue?: number) => {
  if (minimumValue !== undefined && minimumValue !== null) {
    return value !== null && value !== undefined && typeof value === 'number' && minimumValue > 0;
  }
  return value !== null && value !== undefined && typeof value === 'number';
};

const getAge = (dob: string) => {
  return Math.floor(moment().diff(moment(dob), 'years', true));
};

//Number Helper export functions
export const NumberHelper = {
  isValidNumber: (value: number, minimumValue?: number) => {
    return isValidNumber(value, minimumValue);
  },
  getAge: (dob: string): number => {
    return getAge(dob);
  }
};

export const toPreciseNumber = (value: number | string): string => {
  const numberValue = Number(value);
  if (Number.isInteger(numberValue)) {
    return numberValue.toString();
  }
  return numberValue.toPrecision(3).slice(0, 4);
};

export const toImperialHeight = (value: number): string => {
  const totalInches = value / 2.54;
  const feet = Math.floor(totalInches / 12);
  const inches = Math.round(totalInches % 12);

  return `${feet}'${inches}"`;
};

export const toImperialWeight = (value: number): string => {
  const pounds = Math.round(value * 2.20462);
  return `${pounds}`;
};

export const toMetricWeight = (value: number): string => {
  const kg = (value / 2.20462).toFixed(2);
  return `${kg}`;
};
