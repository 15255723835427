import React, { useState } from 'react';
import { StyleSheet, TouchableOpacity, View, ViewProps } from 'react-native';
import { Text, useTheme } from '@ui-kitten/components';

import { UIHelper as uh } from 'src/core';
import { useAppStore } from 'src/core/store';

interface IHistoryFilterProps extends ViewProps {
  setFilter: (filter: { name: string; filter: number }) => void;
}

const HistoryFilter = (props: IHistoryFilterProps) => {
  const th = useTheme();
  const theme = useAppStore((state) => state.theme);

  const style = StyleSheet.create({
    container: {
      flex: 1,
      minHeight: 48
    },
    filterContainer: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignContent: 'center',
      borderRadius: 8,
      backgroundColor: theme === 'dark' ? th['color-basic-900'] : th['color-basic-200'],
      shadowColor: theme === 'dark' ? th['color-basic-900'] : th['color-basic-200'],
      shadowRadius: 2
    },
    filterItem: {
      flex: 1,
      justifyContent: 'center',
      textAlign: 'center',
      backgroundColor: 'transparent',
      borderRadius: 8,
      margin: uh.h2DP(4)
    },
    activeItem: {
      backgroundColor: th['color-primary-500']
    }
  });

  const [active, setActive] = useState<number>(0);

  const today = new Date();
  const filters = [
    {
      name: 'W',
      filter: new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7).getTime()
    },
    {
      name: 'M',
      filter: new Date(today.getFullYear(), today.getMonth() - 1, today.getDate()).getTime()
    },
    {
      name: '6M',
      filter: new Date(today.getFullYear(), today.getMonth() - 6, today.getDate()).getTime()
    },
    {
      name: 'Y',
      filter: new Date(today.getFullYear() - 1, today.getMonth(), today.getDate()).getTime()
    },
    {
      name: 'All',
      filter: new Date('1970-01-01T00:00:01Z').getTime()
    }
  ];

  const renderFilter = () => {
    return filters.map((x, index) => {
      return (
        <TouchableOpacity
          key={index}
          style={[style.filterItem, index === active && style.activeItem]}
          onPress={() => {
            setActive(index);
            props.setFilter(filters[index]);
          }}>
          <Text style={{ textAlign: 'center' }}>{x.name}</Text>
        </TouchableOpacity>
      );
    });
  };

  return (
    <View style={[style.container, props.style]}>
      <View style={style.filterContainer}>{renderFilter()}</View>
    </View>
  );
};

export default HistoryFilter;
