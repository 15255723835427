import React, { useState } from 'react';

import { TenantFeature } from 'src/core/types/AuthTypes';
import { TenantFeatureKey } from 'src/core/types/TenantFeature';
import { useGetTenantFeatures } from '../useUser';
import { useIsFocused } from '@react-navigation/native';

export const useCheckTenantFeature = (key: TenantFeatureKey): boolean => {
  const [hasFeature, setHasFeature] = useState(false);
  const tenantFeatures: TenantFeature[] = useGetTenantFeatures();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    if (isFocused) {
      setHasFeature(tenantFeatures?.find((item: TenantFeature) => item.key == key) != undefined);
    }
  }, [isFocused, tenantFeatures, key]);
  return hasFeature;
};
