import React, { useEffect, useState } from 'react';
import { useActionEffect, useGetActions } from 'src/core/hooks/useActions';

import AddActionLayout from '../AddActionLayout';
import AddActionList from './AddActionList';
import { ErrorModal } from 'src/components/shared/ErrorModal';
import { Input } from '@ui-kitten/components';
import { Loading } from 'src/components/kalichat';
import PillarButtonList from 'src/components/shared/PillarButtonList';
import RightIcon from '../../markers/components/RightIcon';
import { UIHelper as uh } from 'src/core';
import { useNavigation } from '@react-navigation/native';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';

const ACTIONS_PER_PAGE = 20;

const AddAction = () => {
  const transMessage = {
    allActionsLabel: 'All actions',
    searchPlaceholder: 'Search...'
  };
  const t = useTranslationFunc(transMessage);
  const [loading, error] = useActionEffect();
  const actions = useGetActions();
  const pillars = Array.from(new Set(actions.map((action) => action.pillar)));

  const [searchText, setSearchText] = useState<string>('');
  const [selectedPillar, setSelectedPillar] = useState<string>('all');
  const [page, setPage] = useState<number>(1);

  const navigation = useNavigation();

  useEffect(() => {
    return () => {
      setPage(1);
    };
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return (
      <ErrorModal
        visible={true}
        message={error}
        closeBtnClick={() => {
          navigation.goBack();
        }}
      />
    );
  }
  return (
    <AddActionLayout>
      <PillarButtonList
        defaultName={t('allActionsLabel')}
        onBtnClickHandler={(pillarName: string) => setSelectedPillar(pillarName)}
        shownPillars={pillars}
      />
      <Input
        style={{ marginTop: uh.h2DP(8) }}
        textStyle={{ paddingHorizontal: uh.h2DP(2), paddingVertical: uh.h2DP(8) }}
        placeholder={t('searchPlaceholder')}
        onChangeText={(text) => setSearchText(text)}
        value={searchText}
        accessoryRight={
          <RightIcon
            isSearching={searchText !== ''}
            onClick={() => {
              setSearchText('');
            }}
          />
        }
        accessible={true}
        accessibilityLabel={'add action searchbar'}
        testID={'add-action-searchbar'}
      />
      <AddActionList
        actions={actions
          .filter(
            (action) =>
              action.name.toLocaleLowerCase().match(searchText.toLocaleLowerCase()) &&
              (selectedPillar === 'all' ? true : action.pillar.toLocaleLowerCase() === selectedPillar)
          )
          .slice(0, page * ACTIONS_PER_PAGE)}
        handleEndReached={() => setPage((prev) => prev + 1)}
      />
    </AddActionLayout>
  );
};

export default AddAction;
